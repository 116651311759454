
import React, {useEffect, useState, useRef} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {useSelector} from 'react-redux';
import NotificationAlert from 'react-notification-alert'
import axios from 'axios'
import Cookies from 'js-cookie'

import * as constants from 'constants/index.js'


import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
} from 'reactstrap'

import DynamicTable from "../components/DynamicTable";
import styled from "styled-components";
import {FLEET_URL, formatDate} from "constants/index.js";
import {LoaderContainer, override} from "../reusable-components/styled-components";
import {ClipLoader} from "react-spinners";


export default function Drivers() {
    const {category} = useParams()
    const notificationAlert = useRef(null)

    const [token, setToken] = useState('')
    const [loading, setLoading] = useState(true)
    const [boardData, setBoardData] = useState([])
    const [driver, setDriver] = useState('')
    const [filter, setFilter] = useState({})
    const [keys, setKeys] = useState(
        [
            {
                key: 'name',
                text: 'Name'
            },
            {
                key: 'email',
                text: 'Email'
            },
            {
                key: 'phone_number',
                text: 'Phone'
            },
            {
                key: 'total_delivery',
                text: 'Total Delivery'
            },
            {
                key: 'total_collecting',
                text: 'Total Collecting'
            },


        ]
    )

    useEffect(() => {
        const grs_token = Cookies.get('grs_token')
        setToken(grs_token)
        const date = formatDate(new Date())
        const getData = async () => {
            await fetchData(grs_token, date)

        }
        getData()
    }, [])
    const fetchData = async (token, data = {}) => {
        try {
            const source = axios.CancelToken.source()

            const response = await axios({
                method: 'GET',
                url: `${constants.FLEET_URL}/job-booker/get-drivers`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: filter,
                cancelToken: source.token,
            })
            const data = response.data
            setLoading(false)
            // setImgSrc(arr => [...arr, reader.result]);
            setBoardData(data.data)
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled :: ', error)
            } else {
                console.error('error :: ', error)
            }
        }
    }

    const filterVehicle = async (data = null) => {
        const source = axios.CancelToken.source()
        await fetchData(source, 1, token, filter, true)
    }

    const searchByText = async (e) => {
        let tempFilter = filter
        tempFilter['search'] = e.target.value
        setFilter(tempFilter)
        await filterVehicle(tempFilter)
    }

    return (
        <>
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlert}/>
            </div>
            {loading ?
                <LoaderContainer>
                    <ClipLoader
                        css={override}
                        size={40}
                        color={`#7B61E4`}
                        loading={loading}
                    />
                </LoaderContainer> :
                <Container className="admin-main-body" fluid>
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <DynamicTable
                                        showAnyFilter={false}
                                        showJobBookerFilter={true}
                                        showHalf={true}
                                        onSearchChange={e => searchByText(e)}
                                        driverValue={driver ? driver : ''}
                                        tableData={boardData}
                                        keys={keys}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            }
        </>
    )
}