import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import NotificationAlert from "react-notification-alert";

import * as actions from '@redux/Payments/actions';
import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';
import TableCustom from 'views/pages/components/TableCustom';
import Filter from './CronFilter';
import useNotify from 'hooks/notify';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody
} from "reactstrap";

export default function CronStatus() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { ref, notify } = useNotify();

  const query = new URLSearchParams(useLocation().search);

  const {
    getPaymentsCronStatus,
    paymentCrons,
    filter
  } = useSelector((state) => state.paymentState, shallowEqual);

  const [items, setItems] = useState('');
  const [role, setRole] = useState('');

  const [loading, setLoading] = useState(true);
  const [delay, setDelay] = useState(false);

  const [filterOptions, setFilterOptions] = useState('');

  useEffect(() => {
    let page = query.get('page') ?? 1;

    fetchData(page, { ...filter });
  }, []);

  const fetchData = (pageNumber = 1, filter) => {
    setLoading(true);
    history.push({
      pathname: history.location.pathname,
      search: `?page=${pageNumber}`
    });
    dispatch({
      type: actions.GET_CRON_PAYMENT_LIST_REQUEST,
      data: {
        ...filter,
        page: pageNumber,
      },
    });
  };

  useEffect(() => {
    if (getPaymentsCronStatus) {
      setItems(paymentCrons);
      setTimeout(() => {
        setLoading(false);
        setDelay(true);
      }, 500);
    }
  }, [getPaymentsCronStatus]);

  const resetPayments = () => {
    setFilterOptions('')
    fetchData(1, {})
  }

  const paymentScheduleDetails = (row) => {
    history.push({
      pathname: `/admin/deals/${row.deal_id}/payment/${row.deal_payment_schedule_id}/details`,
    })
  }

  return (
    <>
      <CardsHeader name="Payments Cron Management" parentName="Payments" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={ref} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={12}>
                    <h1 className="mb-0">Cron running stats</h1>
                  </Col>
                </Row>
                <Filter fetchData={fetchData} resetLeads={resetPayments} />
              </CardHeader>
              <CardBody>

                <TableCustom
                  loading={loading}
                  delay={delay}
                  items={items.data}
                  keyField={`id`}
                  searchField={false}
                  pagination={items}
                  onChange={(pageNumber) => fetchData(pageNumber, {
                    ...filter,
                    dealer_id: filterOptions.dealer_id === '-' ? undefined : filterOptions.dealer_id
                  })}
                  columns={[
                    {
                      dataField: "deal_id",
                      text: "Deal #",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.deal_id;
                      }
                    },
                    {
                      dataField: "full_name",
                      text: "Customer Name",
                      formatter: (rowContent, row) => {
                        return row.deal.customer.full_name;
                      }
                    },
                    {
                      dataField: "amount",
                      text: "Amount",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return parseFloat(row.schedule.amount);
                      }
                    },
                    {
                      dataField: "stock_no",
                      text: "Stock no",
                      formatter: (rowContent, row) => {
                        return row.deal.stock_no;
                      }
                    },
                    {
                      dataField: "message",
                      text: "Message"
                    },
                    {
                      dataField: "created_at",
                      text: "Created at",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return constants.formatDateShortGB(row.created_at);
                      }
                    },
                    {
                      dataField: "status",
                      text: "Status",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.status ? 'Successful' : "Failed"
                      }
                    },
                    {
                      dataField: "",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                          <div>
                            <i
                              className="far fa-file-alt ml-1"
                              style={{ cursor: 'pointer', }}
                              onClick={() => paymentScheduleDetails(row)}
                            ></i>
                          </div>
                        )
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}