import React, {useContext, useEffect} from 'react';

import {DealContext, PaymentContext} from '../context';

import {
  Row,
  Col,
  Button,
} from "reactstrap";
import actions from "actions/index.js";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import usePrevious from "../../../../hooks/useprevious";

export default function Tabs({activateTab}) {
  const dealContext = useContext(DealContext);
  const context = useContext(PaymentContext);
  const {
    tab, setTab,
    goPayment
  } = dealContext;
  const dispatch = useDispatch();
  const {activeMenu} = useSelector((state) => state.getState);
  useEffect(()=>{
    if (activeMenu !== null) {
      if (activeMenu === 'payments') {
        goPayment()
      }
      setTab(activeMenu)
      activateTab(!!activeMenu);
    }
  },[activeMenu])

  useEffect(()=>{
    const tabs = [
      {
        value: 'info',
        key:'Deal Info',
      },
      {
        value: 'documents',
        key:'Documents',
      },
      {
        value: 'uploads',
        key:'Uploads',
      },
      {
        value: 'notes',
        key:'Notes',
      },
      {
        value: 'tasks',
        key:'Tasks',
      },
      {
        value: 'payments',
        key:'Payments',
        action:'goPayment'
      },
    ]
    dispatch(actions.setMenusList(tabs))
    dispatch(actions.setMenuName('Deals'))
    dispatch(actions.setActiveMenu(activeMenu ? activeMenu : 'info'))
  },[])
  return (
    <>
    </>
  );
}