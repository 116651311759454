import React, {useEffect, useState} from 'react';
import {DndContext, closestCenter, DragOverlay} from "@dnd-kit/core";
import {arrayMove, SortableContext, useSortable, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {Button, Card, Container, Input, UncontrolledPopover} from "reactstrap";
import {CSS} from '@dnd-kit/utilities';
import {
    DeliveryTableItem,
    DeliveryTableItemDetails, TaskDetailsContainer,
    Text,
    TextContainer
} from "@pages/reusable-components/styled-components";
import {useHistory} from "react-router";
import {formatDate, parseToWord} from "../../../../constants";

export default function Draggable({type, text, id = '', deliveryId = '', value = {},deleteItem}) {
    const history = useHistory()
    const [more, setMore] = useState(false)
    const {attributes, listeners, setNodeRef, transform, transition} = useSortable({id: id})
    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    }
    //
    return (
        <div className={'position-relative'}>
            <Button id={`s-${id}`} className={'button-clear'}>
                <DeliveryTableItem onClick={() => setMore(!more)} id={id} type={type} ref={setNodeRef}
                                   style={style} {...attributes} {...listeners}>
                    <div>
                        {type === 'collect' ?
                            <img height={'15px'} width={'16px'}
                                 src={require('assets/images/job/carbon_delivery.png')} alt=""/> :
                            <img height={'15px'} width={'16px'}
                                 src={require('assets/images/job/carbon_delivery-add.png')} alt=""/>
                        } <Text color='#ffffff'>{text}</Text>
                    </div>
                    <i className="ni ni-up rotate-down mr-1"/>
                </DeliveryTableItem>
            </Button>

            <UncontrolledPopover
                placement="bottom"
                target={`s-${id}`}
                trigger="focus"
                style={{padding: 10}}
            >
                <div className={'d-flex justify-content-between flex-row mb-4'}>
                    <Text size={'16px'}
                          bold>{value.task_type === 'grs_collection' ? 'Grs collection' : parseToWord(value.task_type)}</Text>
                    <img height='16px' width='16px'
                         src={require(`assets/images/xmark-solid.svg`)} alt=""/>
                </div>
                <TaskDetailsContainer noMargin noMarginBottom>
                    <TextContainer justify='space-around'>
                        {value.task_type !== 'grs_collection' && <img height='25px' width='25px'
                                                                      src={require(`assets/images/taskEdit/person.png`)}
                                                                      alt=""/>}
                        <img height='25px' width='25px'
                             src={require(`assets/images/taskEdit/priority.png`)} alt=""/>
                        <img height='25px' width='25px'
                             src={require(`assets/images/taskEdit/date_grey.png`)} alt=""/>
                        <img height='25px' width='25px'
                             src={require(`assets/images/taskEdit/person.png`)} alt=""/>
                        <img height='25px' width='25px'
                             src={require(`assets/images/taskEdit/status_grey.png`)} alt=""/>

                    </TextContainer>
                    <TextContainer justify='space-around'>
                        {value.task_type !== 'grs_collection' &&
                        <Text size={'12px'} bold>
                            {value.task_type === 'collect_from_driver' ?
                                'From driver:' : value.task_type === 'deliver_to_driver' ? 'To driver' : 'Customer:'}</Text>}
                        <Text size={'12px'} bold>Task Type:</Text>
                        <Text size={'12px'}
                              bold>{value.task_type === 'grs_collection' ? 'Grs collection time:' : 'Execution time:'}</Text>
                        <Text size={'12px'} bold>Driver:</Text>
                        <Text size={'12px'} bold>Status:</Text>
                    </TextContainer>
                    <TextContainer justify='space-around'>
                        {value.task_type !== 'grs_collection' &&
                        <Text title={`${value.full_name || value.customer_name},${value.customer_address ||
                        value.address},${value.customer_postal_code || value.postal_code}`}
                              className={'address-width'} size={'12px'}>
                            {value.full_name || value.customer_name}, {value.address || value.customer_address}</Text>}
                        <Text size={'12px'}>{parseToWord(value.task_type)}</Text>
                        <Text size={'12px'}>{formatDate(value.execution_date)}</Text>
                        <Text size={'12px'}>{value.driver_name}</Text>
                        <Text size={'12px'}>{parseToWord(value.status)}</Text>
                    </TextContainer>
                </TaskDetailsContainer>
                <TextContainer radius={'4px'} className={'align-items-center cursor-pointer'}
                               onMouseDown={() => history.push(`/admin/schedule-details?id=${deliveryId}`)}
                               marginTop={'5px'} background={'#47cc69'} justify='center'>
                    <Text>View More</Text>
                </TextContainer>
                 <TextContainer radius={'4px'} className={'align-items-center cursor-pointer'}
                               onMouseDown={() => deleteItem(deliveryId)}
                               marginTop={'5px'} background={'#f44336'} justify='center'>
                    <Text>Delete</Text>
                </TextContainer>
            </UncontrolledPopover>
        </div>

    )
}