import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';

import Chart from 'chart.js';
import { Bar } from "react-chartjs-2";
import {
  chartOptions,
  parseOptions,
} from "constants/charts.js";

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import TableCustom from 'views/pages/components/TableCustom';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
  Input,
} from 'reactstrap';

export default function Statistics() {
  const notificationAlert = useRef(null);
  const history = useHistory();

  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);

  const [dealers, setDealers] = useState([]);
  const [filter, setFilter] = useState('');
  const [stats, setStats] = useState('');
  const [statsByDealer, setStatsByDealer] = useState([]);
  const [leadStatusType, setLeadStatusType] = useState('');

  const [chartData, setChartData] = useState('');
  const [options, setOptions] = useState('');

  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    if (window.Chart) {
      parseOptions(Chart, chartOptions())
    }
    
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    fetchData(source, grs_token, '')

    /**
     * chart
     */
    const source2 = axios.CancelToken.source()

    const fetchDataChart = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/leads/statistics/timeline`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source2.token,
        })
        console.log('response :: ', response.data)
        const data = response.data

        const dataset = data.data.dataset
        const labels = data.data.labels

        const _chart_data = _get_chart_data(dataset, labels)
        const _chart_options = _get_chart_options()

        setChartData(_chart_data)
        setOptions(_chart_options)
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
        }
      }
    }

    fetchDataChart()

    return () => {
      source.cancel()
      source2.cancel()
    }
  }, [])

  const fetchData = async (source, token, filter) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/leads/statistics`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ...filter,
          from: constants.formatDate(filter.from),
          to: constants.formatDate(filter.to),
        },
        cancelToken: source.token,
      })
      console.log('response :: ', response.data)
      const data = response.data
      
      setDealers(data.data.dealers)
      setFilter({
        ...data.data.filter,
        from: data.data.filter.from ? constants.formatDate(data.data.filter.from) : '',
        to: data.data.filter.to ? constants.formatDate(data.data.filter.to) : '',
      })
      setStats(data.data.stats)
      setStatsByDealer(data.data.statsByDealer)
      setLeadStatusType(data.data.leadStatusType)

      setTimeout(() => {
        setLoading(false)
      }, 1000);
    } catch (error) {
      setLoading(false)
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
      }
    }
  }

  const filterStatistic = () => {
    setLoading(true)

    const source = axios.CancelToken.source()
    fetchData(source, token, filter)
  }

  const resetStatistic = () => {
    setLoading(true)
    setFilter('')

    const source = axios.CancelToken.source()
    fetchData(source, token, '')
  }

  const _get_chart_data = (dataset, labels) => {
    const datasets = [];
    Object.keys(dataset).forEach((dealer) => {
      datasets.push({
        label: dealer,
        backgroundColor: constants.getColorsForSources(dealer),
        data: dataset[dealer],
        maxBarThickness: 10,
      });
    });

    const chart_data = {
      labels: labels,
      datasets: datasets
    }
    return chart_data
  }

  const _get_chart_options = () => {
    const options = {
      responsive: true,
      legend: {
        display: true,
        position: 'top',
      },
      title: {
        display: true,
        text: 'Enquiries evolution' || ''
      },
      tooltips: {
        mode: 'index',
        intersect: true,
        callbacks: {
          footer: (tooltipItems, data) => {
            const total = tooltipItems.reduce((a, e) => a + parseInt(e.yLabel), 0);
            return 'Total: ' + total;
          }
        }
      },
      scales: {
        yAxes: [
          {
            type: 'linear',
            display: true,
            position: 'left',
            id: 'y-axis-1',
          },
          {
            type: 'linear',
            display: true,
            position: 'right',
            id: 'y-axis-2',
            gridLines: {
              drawOnChartArea: false
            }
          }
        ],
      }
    }
    return options
  }

  return (
    <>
      <CardsHeader name="Leads Statistics" parentName="Statistics" currentName="Show" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <div>
                    <h1 className="title">{`Statistics`}</h1>
                    <p className="text-sm mb-0">{`Short forms`}</p>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <Row className="m-2 justify-content-md-center">
                  <Col md={5} sm={12}>
                    <FormGroup>
                      <Row className="d-flex align-items-center justify-content-md-center">
                        <Col md={3}>
                          <label className="form-control-label">From</label>
                        </Col>
                        <Col md={5}>
                          <Input
                            type="date"
                            value={filter && filter.from ? filter.from : ''}
                            onChange={(e) => setFilter({
                              ...filter,
                              from: e.target.value,
                            })}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                  <Col md={5} sm={12}>
                    <FormGroup>
                      <Row className="d-flex align-items-center justify-content-md-center">
                        <Col md={3}>
                          <label className="form-control-label">To</label>
                        </Col>
                        <Col md={5}>
                          <Input
                            type="date"
                            value={filter && filter.to ? filter.to : ''}
                            onChange={(e) => setFilter({
                              ...filter,
                              to: e.target.value,
                            })}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>
                {filter.isAllowedToFilterByDealer && <Row className="m-2 justify-content-md-center">
                  <Col md={5} sm={12}>
                    <FormGroup>
                      <Row className="d-flex align-items-center justify-content-md-center">
                        <Col md={3}>
                          <label className="form-control-label">Offices</label>
                        </Col>
                        <Col md={5}>
                          <Input
                            type="select"
                            value={filter && filter.office ? filter.office : ''}
                            onChange={(e) => setFilter({
                              ...filter,
                              office: e.target.value,
                            })}
                          >
                            <option>{`All`}</option>
                            {dealers.length > 0 &&
                              dealers.map((dealer, index) => {
                                return <option value={dealer.id} key={index}>{dealer.name}</option>
                              })
                            }
                          </Input>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>}
                <Row className="m-2 justify-content-md-center">
                  <Col md={4} sm={6} style={{margin: 5}}>
                    <Button className="w-100" color="success" type="button" onClick={() => filterStatistic()}>Filter</Button>
                  </Col>
                  <Col md={4} sm={6} style={{margin: 5}}>
                    <Button className="w-100" color="warning" type="button" onClick={() => resetStatistic()}>Reset</Button>
                  </Col>
                </Row>
                <CardHeader>
                  <h6 className="surtitle">Leads</h6>
                  <h1 className="mb-0">General leads statistic</h1>
                </CardHeader>
                <CardBody>
                  <div className="d-flex flex-row justify-content-between flex-wrap">
                    <div className="p-2">
                      <h3 className="mb-0">{`TOTAL`}</h3>
                      <h3 className="mb-0">{stats['total']}</h3>
                    </div>
                    <div className="p-2">
                      <h3 className="mb-0">{`WON`}</h3>
                      <h3 className="mb-0">{stats.data ? stats.data[leadStatusType.won] : 0}</h3>
                    </div>
                    <div className="p-2">
                      <h3 className="mb-0">{`LOST`}</h3>
                      <h3 className="mb-0">{stats.data ? stats.data[leadStatusType.lost] : 0}</h3>
                    </div>
                    <div className="p-2">
                      <h3 className="mb-0">{`NEW`}</h3>
                      <h3 className="mb-0">{stats.data ? stats.data[leadStatusType.new] : 0}</h3>
                    </div>
                  </div>
                </CardBody>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <TableCustom 
                  loading={loading}
                  items={statsByDealer}
                  keyField={`dealer_id`}
                  searchField={false}
                  columns={[
                    {
                      dataField: "name",
                      text: "Dealer",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.dealer && row.dealer.name ? row.dealer.name : '-'
                      }
                    },
                    {
                      dataField: "won",
                      text: "Won",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row[leadStatusType.won]
                      }
                    },
                    {
                      dataField: "lost",
                      text: "Lost",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row[leadStatusType.lost]
                      }
                    },
                    {
                      dataField: "new",
                      text: "Pending(new)",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row[leadStatusType.new]
                      }
                    },
                    {
                      dataField: "total",
                      text: "Total",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.total_count
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <h6 className="surtitle">Leads</h6>
                <h1 className="mb-0">Last 12 months evolution</h1>
              </CardHeader>
              <CardBody>
                {chartData && options && <div className="chart">
                  <Bar
                    data={chartData}
                    options={options}
                    className="chart-canvas"
                    id="chart-bars"
                  />
                </div>}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}