import React, {useEffect, useState, useRef} from 'react'
import {useHistory, useLocation} from 'react-router-dom'

import axios from 'axios'
import Cookies from 'js-cookie'
import * as constants from 'constants/index.js'
import TasksList from "./components/TasksList";
import CreateNewTask from "./components/CreateNewTask";
import PdiChecklist from "../../components/PdiChecklist";
import {
    ReportContainer,
    TaskDetailsContainer,
    Text,
    TextContainer,
    RangeContainer, LoaderContainer, override, ButtonContainer
} from "../../reusable-components/styled-components";
import {FLEET_URL, formatDate, parseToWord, propertiesSumInArray} from "constants/index.js";
import {Button, Col, Label, Row} from "reactstrap";
import {useSelector} from "react-redux";
import VehicleInfo from "@pages/vehicles/VehicleInfo";
import {ClipLoader} from "react-spinners";
import DynamicTable from "@pages/components/DynamicTable";

export default function VehicleDetails() {
    const location = useLocation();
    const history = useHistory();
    const [bodyShop, setBodyShop] = useState(1);
    const [valeting, setValeting] = useState(1);
    const [token, setToken] = useState('');
    const [loading, setLoading] = useState(true);
    const [showCreateNew, setShowCreateNew] = useState(false);
    const [disableSaving, setDisableSaving] = useState(false);
    const [showChecklist, setShowChecklist] = useState(false);
    const roles = useSelector(state => state.getState).roles;
    const isAdmin = roles.includes('Fleet Manager') || roles.includes('dealer_admin')
    const [details, setDetails] = useState({});
    const [filter, setFilter] = useState({})
    const [departments, setDepartments] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [adminTableData, setAdminTableData] = useState([]);
    const [prices, setPrices] = useState([]);
    const [keys] = useState([
        {
            key: 'title',
            text: 'Task Name',
        },
        {
            key: 'make',
            text: 'Make',
        },
        {
            key: 'model',
            text: 'Model',
        },
        {
            key: 'stock_no',
            text: 'Reg.No.',
        },
        {
            key: 'status',
            text: 'Status',
        },
        {
            key: 'price',
            text: 'Cost of job',
            input: true
        },
        {
            key: 'worker_name',
            text: 'Worker',
        },
        {
            key: 'toDetails',
            text: 'Action',
        }

    ])
    const [priorities, setPriorities] = useState([
        {
            label: 'High',
            color: 'red',
            icon: 'ep_d-arrow-up-double.png'
        },
        {
            label: 'Medium',
            color: 'green',
            icon: 'fluent_line-equal.png'
        },
        {
            label: 'Low',
            color: 'Blue',
            icon: 'ep_arrow-down.png'
        }
    ]);
    const [statuses] = useState([
        {
            value: 'to_do',
            text: 'To do'
        },
        {
            value: 'in_progress',
            text: 'In progress'
        },
        {
            value: 'in_review',
            text: 'In review'
        },
        {
            value: 'completed',
            text: 'Completed'
        }
    ]);
    const [jobCategories, setJobCategories] = useState([]);
    const [workers, setWorkers] = useState([]);
    const [checklistData, setChecklistData] = useState([]);
    const [disabled, setDisabled] = useState(true);
    const [id, setId] = useState('');

    const [createTaskData, setCreateTaskData] = useState({});

    useEffect(() => {
        if (roles.length) {
            let id = location.search
            let numb = id.match(/\d/g);
            id = numb.join("");
            const grs_token = Cookies.get('grs_token')
            setId(id)
            setToken(grs_token)

            const source = axios.CancelToken.source()

            const fetchData = async () => {
                await getData(source, grs_token, id)
            }
            fetchData()
            return () => {
                source.cancel()
            }
        }
    }, [roles])
    const getData = async (source, grs_token, id) => {
        try {
            const response = await axios({
                method: 'GET',
                url: `${constants.FLEET_URL}/fleet-management/fleet/${id}`,
                headers: {
                    Authorization: `Bearer ${grs_token}`,
                },
                cancelToken: source.token,
            })
            const data = response.data?.data
            // url: `${constants.FLEET_URL}/fleet-worker/tasks?id=${data.item.id}`,
            setDetails(data.item)
            setChecklistData(data.item.options)

            await getTasks(source, grs_token, id)
            const availableWorkersRequest = await axios({
                method: 'GET',
                url: `${constants.FLEET_URL}/fleet-management/worker`,
                headers: {
                    Authorization: `Bearer ${grs_token}`,
                },
                cancelToken: source.token,
            })
            let departments = []
            availableWorkersRequest.data.data.workers.map((item, index) => {
                if (!departments.find(val => val?.value === item.department)) {
                    departments.push({
                        value: item.department,
                        text: parseToWord(item.department)
                    })
                }

            })
            setWorkers(availableWorkersRequest.data.data.workers)
            setDepartments(departments)
            setLoading(false)
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled :: ', error)
            } else {
                console.error('error :: ', error)
            }
        }
    }

    const getTasks = async (source, token, id, filter = null) => {
        const fleetUrl = isAdmin ?
            `${constants.FLEET_URL}/fleet-management/fleet/task?fleetId=${id}` :
            `${constants.FLEET_URL}/fleet-worker/tasks?fleetId=${id}`
        const fleetResponse = await axios({
            method: 'GET',
            url: fleetUrl,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: filter,
            cancelToken: source.token,
        })
        const tempTasks = fleetResponse.data.data.tasks
        let categories = []
        tempTasks.map((item, index) => {
            if (!categories.find(val => val?.value === item.job_category)) {
                categories.push({
                    value: item.job_category,
                    text: parseToWord(item.job_category)
                })
            }

        })
        if (isAdmin) {
            let tableData = []
            let tempPrices = []
            tempTasks.map((item, index)=>{
                tempPrices.push({
                    id:item.id,
                    price:  item.price,
                })
                item.workers.length ? item.workers.map((worker, idx)=>{
                    tableData.push({
                        title: item.title,
                        id:item.id,
                        status: item.status,
                        make: item.vehicle.make?.name,
                        stock_no: item.vehicle.stock_no,
                        model: item.vehicle.model.name,
                        price:  item.price,
                        worker_name: worker?.name
                    })
                }) :  tableData.push({
                    title: item.title,
                    id:item.id,
                    status: item.status,
                    make: item.vehicle.make?.name,
                    stock_no: item.vehicle.stock_no,
                    model: item.vehicle.model.name,
                    price:  item.price,
                    worker_name: '-'
                })
            })
            setPrices(tempPrices)
            setAdminTableData(tableData)
        }
        setTasks(tempTasks)
        setJobCategories(categories)
    }
    const save = () => {
        let id = location.search
        let numb = id.match(/\d/g);
        id = numb.join("");
        let formData = new FormData();

        formData.append('description', createTaskData.description);
        formData.append('status', createTaskData.status);
        formData.append('job_category', createTaskData.department);
        formData.append('title', createTaskData.title);
        for (let i = 0; i < createTaskData.assignees.length; i++) {
            formData.append('workers[]', createTaskData.assignees[i].id);
        }
        for (let i = 0; i < createTaskData.images.length; i++) {
            formData.append('documents[]', createTaskData.images[i]);
        }
        formData.append('priority', createTaskData?.priority?.toLowerCase());
        axios({
            method: 'POST',
            url: `${constants.FLEET_URL}/fleet-management/fleet/${id}/task`,
            data: formData,
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then(() => {
            setShowCreateNew(false)
        })
    }

    const checkData = (data) => {
        setCreateTaskData(data)
        let disabled = []
        let tempData = []
        Object.keys(data).map(item => {
            if (item !== 'images' && item !== 'assignees') {
                tempData.push(data[item])
            }
        })
        disabled = tempData.filter(item => {
            return (Array.isArray(item) && item.length === 0) || item === ''
        })
        setDisabled(!!disabled.length)
    }

    const filterVehicle = async () => {
        const source = await axios.CancelToken.source()
        getTasks(source, token, id, filter)
    }
    const searchByText = async (e) => {
        let tempFilter = filter
        tempFilter['search'] = e
        setFilter(tempFilter)
        await filterVehicle(tempFilter)
    }

    const setPriorityFilter = async (e) => {
        let tempFilter = filter
        tempFilter.priority = e.label
        setFilter(tempFilter)
        await filterVehicle(tempFilter)
    }
    const setData = (data) => {
          let tempPrices = [...prices];
          let foundIndex = tempPrices.findIndex(x => x.id === data.id && x.price !== data.price);
          if (foundIndex > -1) {
              tempPrices[foundIndex] = data;
              setPrices(tempPrices)
          }
        let tempData = [...adminTableData];
        tempData.map((item,index)=>{
            if (item.id === data.id) {
                tempData[index].price = data.price
            }
        })
        setAdminTableData([...tempData])
    }
    const setDepartmentFilter = async (e) => {
        let tempFilter = filter
        tempFilter.department = e.label
        setFilter(tempFilter)
        await filterVehicle(tempFilter)
    }
    const saveData = async () => {
        setLoading(true)
        let tempData = {
            work_complexity_body_shop: bodyShop,
            work_complexity_valeting: valeting,
            mileage_in: details.mileage_in,
            pdi_checklist: []
        }
        checklistData.map(item => {
            tempData.pdi_checklist.push(...item.options)
        })
        const response = await axios({
            method: 'PUT',
            url: `${constants.FLEET_URL}/fleet-management/fleet/${id}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: tempData
        })
        if (response.status === 200) {
            const source = axios.CancelToken.source()
            await getData(source, token, id)
            setShowChecklist(false)
            setLoading(false)
        }
    }
    const setChecklist = (data, index) => {
        let tempData = [...checklistData]
        tempData[index].options = data
        setChecklistData(tempData)
    }
    const saveCosts = async () => {
        const response = await axios({
            method: 'POST',
            url: `${constants.FLEET_URL}/fleet-management/fleet/edit`,
            headers: {
                Authorization: 'Bearer ' + token
            },
            data: prices,
        })
        if (response.status === 200) {
            setDisableSaving(true)
        }
    }
    return (
        <>

            {

                loading ? <LoaderContainer>
                    <ClipLoader
                        css={override}
                        size={40}
                        color={`#7B61E4`}
                        loading={loading}
                    />
                </LoaderContainer> : showCreateNew ?
                    <CreateNewTask
                        priorities={priorities}
                        vehicle={details.vehicle}
                        assignees={workers}
                        departments={departments}
                        saveData={save}
                        setData={checkData}
                        isDisabled={disabled}
                        setShowCreateNew={setShowCreateNew}
                    />
                    : showChecklist ?
                        <>
                            <ReportContainer>
                                <img width={'219px'} height={'165px'}
                                     src={details.vehicle.image}
                                     alt=""/>
                                <TaskDetailsContainer>
                                    <TextContainer>
                                        <Text>No.</Text>
                                        <img height='25px' width='25px'
                                             src={require(`assets/images/taskEdit/road_grey.png`)}
                                             alt=""/>
                                        <img height='25px' width='25px'
                                             src={require(`assets/images/taskEdit/date_grey.png`)}
                                             alt=""/>
                                    </TextContainer>
                                    <TextContainer>
                                        <Text bold>Reg. Num.:</Text>
                                        <Text bold>Mileage:</Text>
                                        <Text bold>Created Date:</Text>
                                    </TextContainer>
                                    <TextContainer>
                                        <Text>{details.vehicle.stock_no}</Text>
                                        <Text>{details.vehicle.current_mileage}</Text>
                                        <Text>{formatDate(details.created_at)}</Text>
                                    </TextContainer>

                                </TaskDetailsContainer>
                            </ReportContainer>
                            {
                                details?.vehicle && !loading &&
                                <VehicleInfo mileage={details.vehicle.current_mileage}
                                             vehicle={details.vehicle}/>
                            }
                            <Row>

                                {
                                    checklistData.map((option, index) => {
                                        return <Col md={6}>
                                            <PdiChecklist
                                                option={option}
                                                setChecklist={(data) => setChecklist(data, index)}
                                            />
                                        </Col>
                                    })
                                }
                            </Row>
                            <Col className='form-group' sm={12}>
                                <Row>
                                    <Col sm={1}>
                                        <Label
                                            for="bodyShop"
                                        >
                                            Body Shop
                                        </Label>
                                    </Col>
                                    <Col sm={2}>
                                        <input
                                            id="bodyShop"
                                            min={1}
                                            max={10}
                                            step={1}
                                            name="bodyShop"
                                            value={bodyShop}
                                            onChange={event => setBodyShop(event.target.value)}
                                            type="range"
                                        />
                                    </Col>
                                    <Col sm={1}>
                                        <RangeContainer>
                                            {bodyShop}
                                        </RangeContainer>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={12}>
                                <Row>
                                    <Col sm={1}>
                                        <Label
                                            for="valeting"
                                        >
                                            Valeting
                                        </Label>
                                    </Col>
                                    <Col sm={2}>
                                        <input
                                            min={1}
                                            max={10}
                                            step={1}
                                            id="valeting"
                                            name="valeting"
                                            type="range"
                                            value={valeting}
                                            onChange={event => setValeting(event.target.value)}
                                        />
                                    </Col>
                                    <Col sm={1}>
                                        <RangeContainer>
                                            {valeting}
                                        </RangeContainer>
                                    </Col>
                                </Row>

                            </Col>
                            <Button outline={true}
                                    style={{color: '#ec6409', border: '1px solid #ec6409', float: 'right'}}
                                    type="button" onClick={() => setShowChecklist(false)}>Cancel</Button>
                            <Button outline={true} style={{
                                backgroundColor: '#ec6409',
                                color: 'white',
                                border: '1px solid #ec6409',
                                float: 'right',
                                marginRight: '15px'
                            }} onClick={() => (saveData())}>Save</Button>
                        </>
                        :
                        <>
                            <TasksList departments={departments}
                                       showChecklist={setShowChecklist}
                                       setData={setData}
                                       setShowCreateNew={setShowCreateNew}
                                       selectDepartment={setDepartmentFilter}
                                       disableSaving={disableSaving}
                                       selectPriority={setPriorityFilter}
                                       setSearchByName={searchByText}
                                       isAdmin={isAdmin}
                                       keys={keys}
                                       saveCosts={saveCosts}
                                       adminTableData={adminTableData}
                                       tasks={tasks}
                                       date={details.created_at}
                                       vehicle={details.vehicle}
                                       priorities={priorities}
                                       statuses={statuses}/>

                        </>

            }
        </>
    )
}