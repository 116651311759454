import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import TableCustom from 'views/pages/components/TableCustom';
import InputCustom from 'views/pages/components/InputCustom';
import {
  InputArraySelect,
} from 'views/pages/components/InputSelect'

import {
  update,
  deletee,
  hasPermission
} from 'views/policies/VehicleMakePolicy';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
} from 'reactstrap';
import {ButtonContainer} from "@pages/reusable-components/styled-components";

export default function VehicleMake() {
  const notificationAlert = useRef(null);
  const history = useHistory();

  const permissions = useSelector(state => state.getState).permissions;
  
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [delay, setDelay] = useState(true);
  const [makes, setMakes] = useState([]);

  const [make, setMake] = useState('');
  const [makeModal, setMakeModal] = useState({
    show: false,
    header: '',
    division: ''
  });

  const [deleteModal, setDeleteModal] = useState({
    show: false,
    header: '',
    body: '',
    onClick: null,
  })

  const [errors, setErrors] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/vehicle-make`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data
        
        setMakes(data.data.items)

        setTimeout(() => {
          setDelay(true)
          setLoading(false)
        }, 500);
      } catch (error) {
        setLoading(false)
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const addMake = () => {
    axios({
      method: 'POST',
      url: `${constants.API_URL}/vehicle-make`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: make
    })
      .then(response => {
        setMakeModal({
          ...makeModal,
          show: false
        })
        setMake('')
        setErrors('')
        console.log('res :: ', response.data)
        const data = response.data
        setMakes(data.data.items)
        notify('success', 'Success', data.message)
      })
      .catch(error => {
        setErrors('')
        console.error('error :: ', error)
        if (error.response && error.response.status == 422) {
          setErrors(error.response.data.errors)
          return
        }
        setMakeModal({
          ...makeModal,
          show: false
        })
        notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred!')
      })
  }

  const updateMake = () => {
    axios({
      method: 'PUT',
      url: `${constants.API_URL}/vehicle-make/${make.id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: make
    })
      .then(response => {
        setMakeModal({
          ...makeModal,
          show: false
        })
        setMake('')
        setErrors('')
        console.log('res :: ', response.data)
        const data = response.data
        setMakes(data.data.items)
        notify('success', 'Success', data.message)
      })
      .catch(error => {
        setErrors('')
        console.error('error :: ', error)
        if (error.response && error.response.status == 422) {
          setErrors(error.response.data.errors)
          return
        }
        setMakeModal({
          ...makeModal,
          show: false
        })
        notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred!')
      })
  }

  const deleteMake = (makeId) => {
    axios({
      method: 'DELETE',
      url: `${constants.API_URL}/vehicle-make/${makeId}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: make
    })
      .then(response => {
        setDeleteModal({
          ...deleteModal,
          show: false
        })
        console.log('res :: ', response.data)
        const data = response.data
        setMakes(data.data.items)
        notify('success', 'Success', data.message)
      })
      .catch(error => {
        console.error('error :: ', error)
        setDeleteModal({
          ...deleteModal,
          show: false
        })
        notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred!')
      })
  }

  return (
    <>
      <CardsHeader name="Vehicles" parentName="Vehicle make Management" currentName="Create" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Vehicle makes</h1>
                    <p className="text-sm mb-0">You can manage Vehicle makes.</p>
                  </Col>
                  <Col xs={4} className="text-right">
                    <ButtonContainer onClick={() => {
                      setMake('')
                      setMakeModal({
                        ...makeModal,
                        show: true,
                        header: 'Add make',
                        division: 'create'
                      })
                    }}>
                      <i className="fa fa-plus" aria-hidden="true"></i>
                      Add vehicle make</ButtonContainer>
                  </Col>
                </Row>
              </CardHeader>			  
              <CardBody>
                <TableCustom 
                  loading={loading}
                  delay={delay}
                  items={makes}
                  columns={[
                    {
                      dataField: "name",
                      text: "Name",
                      sort: true
                    },
                    {
                      dataField: "",
                      text: "Action",
                      style: {
                        textAlign: 'right'
                      },
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            {(hasPermission(permissions, 'update-vehicle-make') || hasPermission(permissions, 'delete-vehicle-make')) &&
                            <>

                              {update(permissions, row)  &&

                              <img className={'cursor-pointer'}
                                   onClick={() => {
                                     setMake(row)
                                     setMakeModal({
                                       ...makeModal,
                                       show: true,
                                       header: 'Edit make',
                                       division: 'update'
                                     })
                                   }}
                                   src={require(`assets/images/tableIcons/edit.svg`)}
                                   alt=""/>
                              }

                              {deletee(permissions, row) &&

                              <img className={'cursor-pointer'}
                                   onClick={() => {
                                     setDeleteModal({
                                       show: true,
                                       header: 'Delete make',
                                       body: 'Are you sure you want to delete this make?',
                                       onClick: () => deleteMake(row.id)
                                     })
                                   }}
                                   src={require(`assets/images/tableIcons/delete_outline.svg`)}
                                   alt=""/>
                              }
                            </>
                            }
                          </>
                        )
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={makeModal.show}
          toggle={() => setMakeModal({
            ...makeModal,
            show: false,
          })}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title main-color">{makeModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setMakeModal({
                ...makeModal,
                show: false,
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <InputCustom 
              newLabel={`Name`}
              value={make && make.name? make.name: ''}
              onChange={e => setMake({
                ...make,
                name: e.target.value,
              })}
              invalid={errors && errors.name? true: false}
              errorMessage={errors.name}
            />
          </div>
          <div className="modal-footer justify-content-end">
            <ButtonContainer
              onClick={() => setMakeModal({
                ...makeModal,
                show: false,
              })}
            >
			        {`Close`}
            </ButtonContainer>
            <ButtonContainer
                reverse
                onClick={() => makeModal.division == 'create'? addMake(): updateMake()}
            >
              {`Save`}
            </ButtonContainer>
          </div>
        </Modal>
        <Modal
          isOpen={deleteModal.show}
          toggle={() => setDeleteModal({
            show: false
          })}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title main-color">{deleteModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setDeleteModal({
                show: false
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {deleteModal.body}
          </div>

          <div className="modal-footer justify-content-end">
            <ButtonContainer
                onClick={() => setDeleteModal({
                  show: false
                })}
            >
              {`Close`}
            </ButtonContainer>
            <ButtonContainer
              reverse
              onClick={deleteModal.onClick}
            >
              {`Delete`}
            </ButtonContainer>

          </div>
        </Modal>
      </Container>
    </>
  )
}