const viewAny = (roles) => {
  return hasRole(roles, 'superadministrator')
}

const index = (roles) => {
  return hasRole(roles, 'superadministrator')
}

const create = (roles) => {
  return hasRole(roles, 'superadministrator')
}

const update = (roles, permission) => {
  return hasRole(roles, 'superadministrator')
}

const hasRole = (roles, role) => {
  return roles.includes(role)
}

export {
  viewAny,
  index,
  create,
  update,
  hasRole,
};