import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';

import Chart from 'chart.js';
import { Pie } from "react-chartjs-2";
import {
  chartOptions,
  parseOptions,
} from "constants/charts.js";

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import TableCustom from 'views/pages/components/TableCustom';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
  Input,
} from 'reactstrap';

export default function UsersStatistics() {
  const notificationAlert = useRef(null);
  const history = useHistory();
  
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);

  const [stats, setStats] = useState('');
  const [statsArray, setStatsArray] = useState([]);
  const [dealers, setDealers] = useState([]);
  const [filter, setFilter] = useState('');
  const [leadStatusType, setLeadStatusType] = useState('');
  const [authUserTeam, setAuthUserTeam] = useState('');

  const [chartData, setChartData] = useState('');
  const [options, setOptions] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }

    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    fetchData(source, grs_token, '')

    return () => {
      source.cancel()
    }
  }, [])

  const fetchData = async (source, token, filter) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/leads/statistics/users`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ...filter,
          from: constants.formatDate(filter.from),
          to: constants.formatDate(filter.to),
        },
        cancelToken: source.token,
      })
      console.log('response :: ', response.data)
      const data = response.data
      
      setDealers(data.data.dealers)
      setFilter({
        ...data.data.filter,
        from: data.data.filter.from ? constants.formatDate(data.data.filter.from) : '',
        to: data.data.filter.to ? constants.formatDate(data.data.filter.to) : '',
      })
      setLeadStatusType(data.data.leadStatusType)
      setAuthUserTeam(data.data.authUserTeam)

      let _stats = data.data.stats
      setStats(_stats)

      let _stats_array = [];
      Object.keys(_stats.data).map(key => {
        _stats_array.push(_stats.data[key])
      })
      setStatsArray(_stats_array)

      let _stats_data = _stats.data
      let _chart_data = _get_chart_data(_stats_data)
      let _chart_options = _get_chart_options()

      setChartData(_chart_data)
      setOptions(_chart_options)

      setTimeout(() => {
        setLoading(false)
      }, 1000)
    } catch (error) {
      setLoading(false)
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
      }
    }
  }

  const filterStatistic = () => {
    setLoading(true)

    const source = axios.CancelToken.source()
    fetchData(source, token, filter)
  }

  const resetStatistic = () => {
    setLoading(true)
    setFilter('')

    const source = axios.CancelToken.source()
    fetchData(source, token, '')
  }

  const _get_chart_data = (_stats_data) => {
    let labels = []
    let datasets = []
    let backgroundColor = []

    Object.keys(_stats_data).forEach((dealer) => {
      labels.push(dealer)
      datasets.push(_stats_data[dealer].total_count);
      backgroundColor.push(constants.getColorsForSources(dealer));
    });

    let chart_data = {
      labels: labels,
      datasets: [
        {
          data: datasets,
          backgroundColor,
        }
      ],
    }
    return chart_data
  }

  const _get_chart_options = () => {
    let options = {
      responsive: true,
      legend: {
        display: true,
        position: 'top',
      },
      animation: {
        animateScale: true,
        animateRotate: true
      }
    }
    return options
  }

  return (
    <>
      <CardsHeader name="Leads Statistics" parentName="Statistics" currentName="Users statistics" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <div>
                    <h1 className="title">{`Leads assignments and processing statistics`}</h1>
                    <p className="text-sm mb-0">{`Short forms`}</p>
                  </div>
                </div>
              </CardHeader>			  
              <CardBody>
                <Row className="m-2 justify-content-md-center">
                  <Col md={5} sm={12}>
                    <FormGroup>
                      <Row className="d-flex align-items-center justify-content-md-center">
                        <Col md={3}>
                          <label className="form-control-label">From</label>
                        </Col>
                        <Col md={5}>
                          <Input
                            type="date"
                            value={filter ? filter.from : ''}
                            onChange={(e) => setFilter({
                              ...filter,
                              from: e.target.value,
                            })}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                  <Col md={5} sm={12}>
                    <FormGroup>
                      <Row className="d-flex align-items-center justify-content-md-center">
                        <Col md={3}>
                          <label className="form-control-label">To</label>
                        </Col>
                        <Col md={5}>
                          <Input
                            type="date"
                            value={filter ? filter.to : ''}
                            onChange={(e) => setFilter({
                              ...filter,
                              to: e.target.value,
                            })}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>
                {filter.isAllowedToFilterByDealer && <Row className="m-2 justify-content-md-center">
                  <Col md={5} sm={12}>
                    <FormGroup>
                      <Row className="d-flex align-items-center justify-content-md-center">
                        <Col md={3}>
                          <label className="form-control-label">Offices</label>
                        </Col>
                        <Col md={5}>
                          <Input
                            type="select"
                            value={filter && filter.office ? filter.office : ''}
                            onChange={(e) => setFilter({
                              ...filter,
                              office: e.target.value,
                            })}
                          >
                            <option>{`All`}</option>
                            {dealers.length > 0 &&
                              dealers.map((dealer, index) => {
                                return <option value={dealer.id} key={index}>{dealer.name}</option>
                              })
                            }
                          </Input>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>}
                <Row className="m-2 justify-content-md-center">
                  <Col md={4} sm={6} style={{margin: 5}}>
                    <Button className="w-100" color="success" type="button" onClick={() => filterStatistic()}>Filter</Button>
                  </Col>
                  <Col md={4} sm={6} style={{margin: 5}}>
                    <Button className="w-100" color="warning" type="button" onClick={() => resetStatistic()}>Reset</Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <TableCustom 
                  loading={loading}
                  items={statsArray}
                  keyField={`dealer_id`}
                  searchField={false}
                  columns={[
                    {
                      dataField: "name",
                      text: !authUserTeam? "Dealer": "",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return !authUserTeam && row.dealer && row.dealer.name ? row.dealer.name : ''
                      }
                    },
                    {
                      dataField: "assignee",
                      text: "Assignee",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.assigned_to? row.assigned_to.name: ''
                      }
                    },
                    {
                      dataField: "won",
                      text: "Won",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row[leadStatusType.won]
                      }
                    },
                    {
                      dataField: "lost",
                      text: "Lost",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row[leadStatusType.lost]
                      }
                    },
                    {
                      dataField: "new",
                      text: "Pending(new)",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row[leadStatusType.new]
                      }
                    },
                    {
                      dataField: "quoted",
                      text: "Assigned",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row[leadStatusType.quoted]
                      }
                    },
                    {
                      dataField: "total",
                      text: "Total",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.total_count
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <h6 className="surtitle">Leads assignment percentage</h6>
                <h1 className="mb-0">Leads processing in the interval selected</h1>
              </CardHeader>
              <CardBody>
                {chartData && options && <div className="chart">
                  <Pie
                    data={chartData}
                    options={options}
                    className="chart-canvas"
                    id="chart-pie"
                  />
                </div>}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}