import React from 'react';
import { useHistory } from 'react-router-dom';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from 'reactstrap';
import {ButtonContainer} from "@pages/reusable-components/styled-components";

export default function Personalisation() {
  const history = useHistory();

  const gotoSettings = () => {
    history.push({
      pathname: `/admin/settings/modules/kashflow`
    })
  }

  const gotoXeroSettings = () => {
    history.push({
      pathname: `/admin/settings/modules/xero`
    })
  }

  return (
    <>
      <CardsHeader name="Settings" parentName="News Management" currentName="List" />
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          {/* <Col xl="4" md={6}>
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <div>
                    <h1 className="title mb-0">Kashflow</h1>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <p className="card-text mb-4">Go to Kashflow configuration.</p>
                <Button className="w-100" color="success" type="button" onClick={() => gotoSettings()}>Setup</Button>
              </CardBody>
            </Card>
          </Col> */}
          <Col xl="4" md={6}>
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <div>
                    <h1 className="title mb-0 main-color">Xero</h1>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <p className="card-text mb-4">Go to XERO accounting sofware configuration.</p>
                <ButtonContainer reverse onClick={() => gotoXeroSettings()}>Setup</ButtonContainer>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}