import React from "react";
import { Container, Row, Col } from "reactstrap";

export default function AuthFooter() {
  
  return (
    <>
      <footer className="py-4 pb-8 bg-default" id="footer-main" style={{height: "30vh"}}>
        <Container>
          <Row className="align-items-center justify-content-xl-between">
            <Col xl="12">
              <div className="copyright text-center text-xl-center text-muted">
                © {new Date().getFullYear()}{" "}
                <a
                  className="font-weight-bold ml-1"
                  href="http://grsmotorgroup.co.uk/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GRS Motor Group
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}