import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import * as constants from '../../../constants';
import InputCustom from '../components/InputCustom';
import * as taskActions from '../../../redux/Task/actions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

function HistoryAndComments({ task }) {
  const [tab, setTab] = useState('history');
  const [comment, setComment] = useState('');
  const [viewButtons, setViewButtons] = useState(false);
  const [selectedComment, setSelectedComment] = useState('');
  const [comments, setComments] = useState(task.task_comments);

  const { createCommentSuccess, updateCommentSuccess } = useSelector(
    (state) => state.taskState,
    shallowEqual
  );

  const dispatch = useDispatch();

  const ButtonStyle = {
    padding: 5,
    lineHeight: 0,
    margin: 1,
  };

  useEffect(() => {
    if (createCommentSuccess) {
      setComment('');
    }
  }, [createCommentSuccess]);

  useEffect(() => {
    if (updateCommentSuccess) {
      setSelectedComment('');
    }
  }, [updateCommentSuccess]);

  useEffect(() => {
    const comments = task.task_comments?.map((comment) => ({
      ...comment,
      editMode: false,
    }));
    setComments(comments);
  }, [task]);

  const newComment = (comment, id) => {
    dispatch({
      type: taskActions.CREATE_COMMENT_REQUEST,
      comment,
      id,
    });
  };

  const deleteComment = (commentId, taskId) => {
    dispatch({
      type: taskActions.DELETE_COMMENT_REQUEST,
      commentId,
      taskId,
    });
  };

  const updateComment = (commentId) => {
    dispatch({
      type: taskActions.UPDATE_COMMENT_REQUEST,
      id: commentId,
      comment: selectedComment,
      taskId: task.id,
    });
  };

  const editComment = ({ comment }, index) => {
    setSelectedComment(comment);

    const commentsCopy = [...comments];
    commentsCopy[index].editMode = true;
    setComments(commentsCopy);
  };

  return (
    <div>
      <Row>
        <Col md={12} sm={12}>
          <Button
            className={`col-md-2 col-sm-12 deal-tab ${
              tab === 'history' ? 'main-color' : 'releasedSelectedButton'
            }`}
            style={{
              margin: 5,
            }}
            onClick={() => setTab('history')}
          >
            History
          </Button>
          <Button
            className={`col-md-2 col-sm-12 deal-tab ${
              tab === 'comments' ? 'main-color' : 'releasedSelectedButton'
            }`}
            style={{
              margin: 5,
            }}
            onClick={() => setTab('comments')}
          >
            Comments
          </Button>
        </Col>
      </Row>
      {tab === 'history' && (
        <>
          <Row style={{ overflowY: 'scroll', maxHeight: '350px' }}>
            <Col md={12}>
              {task?.logs?.length > 0 &&
                task.logs.map((log, index) => {
                  return log?.type ? (
                    <div key={index} style={{ marginTop: 20 }}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <span className="avatar avatar-sm rounded-circle">
                          {log?.owner?.picture ? (
                            <img
                              alt={
                                log?.owner?.name
                                  ? log?.owner?.name.slice(0, 2)
                                  : ''
                              }
                              src={constants.STORAGE_URL + log?.owner?.picture}
                            />
                          ) : log?.owner?.name ? (
                            log?.owner?.name.slice(0, 2)
                          ) : (
                            ''
                          )}
                        </span>
                        <span
                          style={{
                            marginLeft: 10,
                            fontWeight: 'bold',
                          }}
                        >
                          {log?.owner?.name}
                        </span>
                        {log?.type === 'comment' ? (
                          <span
                            style={{
                              marginLeft: 10,
                            }}
                          >
                            {'commented on '}
                          </span>
                        ) : (
                          <span
                            style={{
                              marginLeft: 10,
                            }}
                          >
                            {'updated the '}
                            <span
                              style={{
                                fontWeight: 'bold',
                              }}
                            >
                              {log?.type}
                            </span>
                          </span>
                        )}
                        <span
                          style={{
                            marginLeft: 10,
                          }}
                        >
                          {constants.formatDateFullGB(log?.updated_at)}
                        </span>
                      </div>
                      {log?.type === 'comment' ? (
                        <div style={{ marginLeft: 45 }}>{log?.new_value}</div>
                      ) : (
                        <div style={{ marginLeft: 45 }}>
                          {log?.old_value + ' -> ' + log?.new_value}
                        </div>
                      )}
                    </div>
                  ) : null;
                })}
            </Col>
          </Row>
        </>
      )}
      {tab === 'comments' && (
        <>
          <Row>
            <Col md={10}>
              <InputCustom
                placeholder={'New Comment'}
                style={{ marginBottom: 0 }}
                onChange={(e) => setComment(e.target.value)}
                value={comment}
                onFocus={() => setViewButtons(true)}
              />
              {viewButtons && (
                <div style={{ float: 'right' }}>
                  <Button
                    style={ButtonStyle}
                    color="secondary"
                    onClick={() => {
                      newComment(comment, task.id);
                      setViewButtons(false);
                    }}
                  >
                    <i className="ni ni-check-bold" />
                  </Button>
                  <Button
                    color="secondary"
                    style={ButtonStyle}
                    onClick={() => {
                      setComment('');
                      setViewButtons(false);
                    }}
                  >
                    <i className="ni ni-fat-remove" />
                  </Button>
                </div>
              )}
            </Col>
          </Row>
          <Row style={{ overflowY: 'scroll', maxHeight: '350px' }}>
            <Col md={12}>
              {comments?.length > 0 &&
                comments.map((comment, index) => {
                  return (
                    <div key={index} style={{ marginTop: 20 }}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <span className="avatar avatar-sm rounded-circle">
                          {comment?.owner?.picture ? (
                            <img
                              alt={
                                comment?.owner?.name
                                  ? comment?.owner?.name.slice(0, 2)
                                  : ''
                              }
                              src={
                                constants.STORAGE_URL + comment?.owner?.picture
                              }
                            />
                          ) : comment?.owner?.name ? (
                            comment?.owner?.name.slice(0, 2)
                          ) : (
                            ''
                          )}
                        </span>
                        <span
                          style={{
                            marginLeft: 10,
                            fontWeight: 'bold',
                          }}
                        >
                          {comment?.owner?.name}
                        </span>
                        <span
                          style={{
                            marginLeft: 10,
                          }}
                        >
                          {constants.formatDateFullGB(comment?.updated_at)}
                        </span>
                      </div>
                      {comment.editMode ? (
                        <InputCustom
                          value={selectedComment || ''}
                          style={{ marginLeft: 45, width: '78%' }}
                          onChange={(e) => setSelectedComment(e.target.value)}
                          onBlur={() => {
                            updateComment(comment.id);
                          }}
                        />
                      ) : (
                        <div style={{ marginLeft: 45 }}>{comment.comment}</div>
                      )}
                      <div>
                        <span
                          className="edit-comment"
                          style={{ marginLeft: 45 }}
                          onClick={() => editComment(comment, index)}
                        >
                          Edit
                        </span>
                        <span
                          className="edit-comment"
                          style={{ marginLeft: 10 }}
                          onClick={() => {
                            deleteComment(comment.id, task.id);
                          }}
                        >
                          Delete
                        </span>
                      </div>
                    </div>
                  );
                })}
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}
export default React.memo(HistoryAndComments);

HistoryAndComments.propTypes = {
  task: PropTypes.object,
};
