import React, {useEffect, useRef, useState} from 'react'
import {formatDate} from "../../../../../constants";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "react-modern-calendar-datepicker";
import moment from 'moment'
import {
    ButtonContainer,
    CalendarInput,
    FilterContainer
} from "@pages/reusable-components/styled-components";
import {SelectWithoutInput} from "@pages/components/InputSelect";
import styled from "styled-components";
import {Col} from "reactstrap";
import CustomSearch from "@pages/components/CustomSearch";
import CalendarFilter from "@pages/components/CalendarFilter";

const Container = styled.div`
     display: flex;
     width: 100%;
     justify-content: space-between;
`
export default function FilterComponent({
                                            selectDate,
                                            makeValue = null,
                                            modelValue = null,
                                            driverValue = null,
                                            selectMake,
                                            loading = false,
                                            showHalf = false,
                                            selectModel,
                                            selectDriver,
                                            taskType,
                                            selectTaskType,
                                            makes = [],
                                            types = [],
                                            models = [],
                                            drivers = [],
                                            searchData,
                                            showCreateNew,
                                            isVehicles = false
                                        }
) {
    const [selectedDay, setSelectedDay] = useState(null);
    const renderCustomInput = ({ref}) => (
        <CalendarInput
            readOnly
            ref={ref} // necessary
            placeholder="Select date"
            value={selectedDay ? moment({...selectedDay, month: selectedDay.month - 1,}).format('DD/MMM/YYYY') : ''}
        />
    )


    return (

        !loading &&
        <Container>
            <FilterContainer column gap={isVehicles && '40px'}>
                {
                    !isVehicles ? <DatePicker
                            value={selectedDay}
                            onChange={(e) => {
                                selectDate(moment({...e, month: e.month - 1,}).format('YYYY-MM-DD'))
                                setSelectedDay(e)
                            }}
                            renderInput={renderCustomInput}
                            shouldHighlightWeekends
                        /> :
                        <Col sm={!showHalf && 3}>
                            <CustomSearch
                                onChange={searchData}
                                placeholder={'Search'}
                            />
                        </Col>
                }

                {
                    !showHalf &&
             <>
                    <div className='align-self-center select-mobile-width'>
                        <SelectWithoutInput
                            label={makeValue ? makeValue : `Make`}
                            onSelect={e => {
                                selectMake(e)
                            }}
                            options={makes}
                            defaultOption={true}
                            valueKey={'id'}
                            labelKey={'name'}
                            imageLink={'directions_car_grey.png'}
                        />
                    </div>
                    <div className='align-self-center select-mobile-width'>
                    <SelectWithoutInput
                    label={modelValue ? modelValue : `Model`}
                    onSelect={e => {
                    selectModel(e)
                }}
                    options={models}
                    defaultOption={true}
                    valueKey={'vehicle_model_id'}
                    labelKey={'vehicle_model'}
                    imageLink={'loyalty.png'}
                    />
                    </div>
                    <div className='align-self-center select-mobile-width'>
                    <SelectWithoutInput
                    label={driverValue ? driverValue : `Driver`}
                    onSelect={e => {
                    selectDriver(e)
                }}
                    options={drivers}
                    defaultOption={true}
                    valueKey={'id'}
                    labelKey={'name'}
                    imageLink={'loyalty.png'}
                    />
                    </div>
                    <div className='align-self-center select-mobile-width'>
                    <SelectWithoutInput
                    label={taskType ? taskType : `Task Type`}
                    onSelect={e => {
                    selectTaskType(e)
                }}
                    options={types}
                    defaultOption={true}
                    valueKey={'value'}
                    labelKey={'label'}
                    imageLink={'loyalty.png'}
                    />
                    </div>
             </>
                }
            </FilterContainer>
            {!isVehicles && <ButtonContainer onClick={() => showCreateNew(true)}>
                <i className="fa fa-plus" aria-hidden="true"></i>
                New Task
            </ButtonContainer>}
        </Container>
    )
}