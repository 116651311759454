import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import NotificationAlert from 'react-notification-alert'
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
  Input,
} from 'reactstrap';
import TableCustom from 'views/pages/components/TableCustom';
import InputToggle from 'views/pages/components/InputToggle';
import {
  InputKeySelect
} from 'views/pages/components/InputSelect';
import CardsHeader from 'layouts/Headers/CardsHeader.js';
import useNotify from 'hooks/notify';
import { cleanReferer, fetchLiveVehicle, fetchVehicleJobs, updateVehicle } from '@redux/Vehicle/actions';
import * as constants from 'constants/index.js';

function guidGenerator() {
  var S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
}

export default function LiveVehicleEdit() {
  const { ref, notify } = useNotify();
  const history = useHistory();
  const [values, setValues] = useState({
    dealStatus: '',
    soldType: '',
    customerName: '',
    dealDate: '',
    work: []
  });

  const [work, setWork] = useState('');
  const [isFleet, setIsFleet] = useState(false);
  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch();
  const { id } = useParams();
  const { liveVehicleEdit, dealStatuses, vehicleFleet, liveVehicleMessage, soldTypes, refererUrl } = useSelector((state) => state.vehicle);

  const goList = () => {
    let urlPath = refererUrl ?? "master";

    history.push({
      pathname: `/admin/live-vehicle/${urlPath}`
    })
  }

  useEffect(() => {
    dispatch(fetchLiveVehicle(id));
  }, [id]);

  useEffect(() => {
    if (vehicleFleet.statusTypes) {
      setLoading(false);
    }
  }, [vehicleFleet]);

  useEffect(() => {
    if (liveVehicleMessage) {
      notify(liveVehicleMessage, "Success", "success");
    }
  }, [liveVehicleMessage]);

  useEffect(() => {
    if (window.location.href.includes("fleet")) {
      setIsFleet(true);
    }

    return () => {
      dispatch(cleanReferer());
    }
  }, []);

  const populateValues = () => {
    if (['rental', 'renewal'].includes(liveVehicleEdit.deal_type)) {
      return {
        firstName: liveVehicleEdit.deal?.first_name ?? '',
        lastName: liveVehicleEdit.deal?.last_name ?? '',
        dealDate: liveVehicleEdit.deal?.deal_date ? moment(liveVehicleEdit.deal.deal_date).toDate() : '',
        dealStatus: liveVehicleEdit.deal?.deal_status ?? '',
      }
    }
    else if (liveVehicleEdit.deal_type === 'sale') {
      return {
        soldType: liveVehicleEdit.sold_type ?? '',
        soldAmount: liveVehicleEdit?.vehicle.sold_amount ?? '',
        soldTo: liveVehicleEdit?.vehicle.sold_to ?? '',
        soldDate: liveVehicleEdit?.vehicle.sold_at ? moment(liveVehicleEdit.vehicle.sold_at).toDate() : '',
        settlementCost: liveVehicleEdit?.vehicle.settlement_cost ?? ''
      }
    } else {
      return {};
    }
  }

  useEffect(() => {
    if (liveVehicleEdit && liveVehicleEdit.id) {
      setValues(populateValues());
      setSaving(false);
    }
  }, [liveVehicleEdit]);

  const renderRentalForm = () => {
    return (
      <CardBody>
        <Row>
          <Col lg={3} md={6} sm={6} xs={12}>
            <InputKeySelect
              label={"Deal Status"}
              options={dealStatuses}
              readOnly={true}
              value={values.dealStatus ?? ''}
              // onChange={(e) => {
              //   setValues({
              //     ...values,
              //     dealStatus: e.target.value
              //   })
              // }}
              defaultOption={true}
            // invalid={errors && errors.job_category ? true : false}
            // errorMessage={errors.job_category}
            />
          </Col>
          <Col lg={3} md={6} sm={6} xs={12}>
            <FormGroup>
              <label className="form-control-label">Deal Date</label>
              <DatePicker
                dateFormat="yyyy/MM/dd"
                readOnly={true}
                selected={values.dealDate ?? null}
                // onChange={(date) => {
                //   setValues({
                //     ...values,
                //     dealDate: date
                //   });
                // }}
              />
            </FormGroup>
          </Col>
          <Col lg={3} md={6} sm={6} xs={12}>
            <FormGroup>
              <label className="form-control-label">First Name</label>
              <Input
                placeholder="First Name"
                type="text"
                readOnly={true}
                value={values.firstName}
                // onChange={(e) => setValues({
                //   ...values,
                //   firstName: e.target.value
                // })}
              />
              <div className="invalid-feedback">
                Please provide the first name.
              </div>
            </FormGroup>
          </Col>
          <Col lg={3} md={6} sm={6} xs={12}>
            <FormGroup>
              <label className="form-control-label">Last Name</label>
              <Input
                placeholder="Last Name"
                type="text"
                readOnly={true}
                value={values.lastName}
                // onChange={(e) => setValues({
                //   ...values,
                //   lastName: e.target.value
                // })}
              />
              <div className="invalid-feedback">
                Please provide the last name.
              </div>
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    )
  }

  const renderSaleForm = () => {
    return (
      <CardBody>
        <Row>
          <Col md={3} sm={6} xs={12}>
            <InputKeySelect
              label={"Sold Type"}
              options={soldTypes}
              value={values.soldType ?? ''}
              onChange={(e) => {
                setValues({
                  ...values,
                  soldType: e.target.value
                })
              }}
              defaultOption={true}
            // invalid={errors && errors.job_category ? true : false}
            // errorMessage={errors.job_category}
            />
          </Col>
          <Col md={3} sm={6} xs={12}>
            <FormGroup>
              <label className="form-control-label">Sold To</label>
              <Input
                placeholder="Sold To"
                type="text"
                value={values.soldTo}
                onChange={(e) => setValues({
                  ...values,
                  soldTo: e.target.value
                })}
              />
            </FormGroup>
          </Col>
          <Col md={3} sm={6} xs={12}>
            <FormGroup>
              <label className="form-control-label">Sold Amount</label>
              <Input
                placeholder="Sold To"
                type="number"
                value={values.soldAmount ?? ''}
                onChange={(e) => setValues({
                  ...values,
                  soldAmount: e.target.value
                })}
              />
            </FormGroup>
          </Col>
          <Col md={3} sm={6} xs={12}>
            <FormGroup>
              <label className="form-control-label">Settlement Cost</label>
              <Input
                placeholder="Settlement Cost"
                type="number"
                readOnly={true}
                value={values.settlementCost ?? ''}
                // onChange={(e) => setValues({
                //   ...values,
                //   settlementCost: e.target.value
                // })}
              />
            </FormGroup>
          </Col>
          <Col md={3} sm={6} xs={12}>
            <FormGroup>
              <label className="form-control-label">Date Sold</label>
              <DatePicker
                dateFormat="yyyy/MM/dd"
                selected={values.soldDate ?? null}
                onChange={(date) => {
                  setValues({
                    ...values,
                    soldDate: date
                  });
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    )
  }

  return (
    <>
      <CardsHeader name="Fleets" parentName="Job Management" currentName="Create" />
      <div className="rna-wrapper">
        <NotificationAlert ref={ref} />
      </div>
      <Container className="mt--5 admin-main-body live-vehicle-edit" fluid >
        <Row>
          <Col xl="12">
            {!liveVehicleEdit ?
              <Card>
                <CardHeader>
                  <Row>
                    <Col xs={8}>
                      <h1 className="mb-0">Fetching vehicle details...</h1>
                    </Col>
                    <Col xs={4} className="text-right">
                      <Button className="w-100" color="success" type="button" onClick={() => goList()}>Back to list</Button>
                    </Col>
                  </Row>
                </CardHeader>
              </Card> :
              <Card>
                <CardHeader>
                  <Row>
                    <Col xs={8}>
                      <h1 className="mb-0">Editing vehicle</h1>
                    </Col>
                    <Col xs={4} className="text-right">
                      <Button className="w-100" color="success" type="button" onClick={() => goList()}>Back to list</Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={3} sm={6} xs={12}>
                      <h3>Stock no</h3>
                      <p>{liveVehicleEdit.vehicle.stock_no}</p>
                    </Col>
                    <Col md={3} sm={6} xs={12}>
                      <h3>Vehicle</h3>
                      <p>{liveVehicleEdit.vehicle.vehicle_make.name} {liveVehicleEdit.vehicle.vehicle_model.name}</p>
                    </Col>
                    <Col md={3} sm={6} xs={12}>
                      <h3>Colour</h3>
                      <p>{liveVehicleEdit.vehicle.colour}</p>
                    </Col>
                    <Col md={3} sm={6} xs={12}>
                      <h3>Mileage</h3>
                      <p>{liveVehicleEdit.vehicle.current_mileage}</p>
                    </Col>
                  </Row>
                </CardBody>
                {liveVehicleEdit.deal_id && ['rental', 'renewal'].includes(liveVehicleEdit.deal_type) && isFleet === false && renderRentalForm()}
                {!liveVehicleEdit.deal_id && ['rental', 'renewal'].includes(liveVehicleEdit.deal_type) && <CardBody>
                  <div className='row'>
                    <div className='col-12'>
                      <p>Currently you have no deal set up for {liveVehicleEdit.vehicle.stock_no}</p>
                    </div>
                  </div>
                </CardBody>
                }
                {liveVehicleEdit.deal_type === 'sale' && isFleet === false && renderSaleForm()}
                <CardBody>
                  <Row>
                    <Col>
                      <h2>Jobs Status</h2>
                    </Col>
                  </Row>
                  <TableCustom
                    loading={false}
                    items={liveVehicleEdit?.vehicle?.fleets?.length > 0 ? liveVehicleEdit.vehicle.fleets[0]?.tasks : []}
                    columns={[
                      {
                        dataField: "title",
                        text: "title",
                      },
                      {
                        dataField: "description",
                        text: "Work needed"
                      },
                      {
                        dataField: "status",
                        text: "Status",
                        formatter: (rowContent, row) => {
                          let label = '';
                          if (row.status === 'completed') {
                            label = `Completed ${row.completed_at  ? `at ${constants.formatDateShortGB(row.completed_at)}` : ''}`
                          } else if (row.status === "in_review") {
                            label = "Job in review";
                          } else if (row.status === "in_progress") {
                            label = "Job in progress";
                          } else {
                            label = "To do";
                          }

                          return <a href={`/admin/task-details?id=${row.id}`} target='_blank'>{label}</a>;
                        }
                      }
                    ]}
                  />
                </CardBody>
                {isFleet === false && <CardBody>
                  <Row>
                    <Col xs={12}>
                      <Button disabled={saving} className="w-100" color="success" type="button" onClick={() => {
                        setSaving(true);
                        dispatch(updateVehicle(id, values));
                      }}>Save</Button>
                    </Col>
                  </Row>
                </CardBody>
                }
              </Card>
            }
          </Col>
        </Row>
      </Container>
    </>
  )
}