const viewAny = (permissions) => {
  return permissions.includes('read-news')
}

const create = (permissions) => {
  return hasPermission(permissions, 'create-news')
}

const update = (permissions, news) => {
  return hasPermission(permissions, 'update-news')
}

const deletee = (permissions, news) => {
  return hasPermission(permissions, 'delete-news')
}

const hasPermission = (permissions, permission) => {
  return permissions.includes(permission)
}

export {
  viewAny,
  create,
  update,
  deletee,
  hasPermission,
};