import React, { useState, useEffect } from "react";
import { Button, Modal } from "reactstrap";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import * as actions from '@redux/Deal/actions';
import InputCustom from './../../../components/InputCustom';

export const AddNote = (props) => {
    const dispatch = useDispatch();
    const dealState = useSelector((state) => state.dealState, shallowEqual);

    const { isNoteModalVisible, toggleModal, notify } = props;
    const [note, setNote] = useState('');
    const [deal, setDeal] = useState(null);
    const [selectedPayment, setSelectedPayment] = useState(null);

    useEffect(() => {
        if (dealState.paymentDetails) {
            let data = dealState.paymentDetails;
            setDeal(data.deal);
            setSelectedPayment(data.item);
        }
    }, [dealState])

    useEffect(() => {
        if (dealState.paymentNotedCreateSuccessfully === true) {
            notify('success', 'Success', "Note succesfully added!");
            toggleModal();
        } else if (dealState.paymentNotedCreateSuccessfully === false) {
            notify('warning', 'Warning', "Error saving the note!");
        }
    }, [dealState.paymentNotedCreateSuccessfully]);

    const addNote = () => {
        dispatch({
            type: actions.CREATE_INVOICE_NOTE_REQUEST,
            payload: {
                note,
                dealId: deal.id,
                paymentId: selectedPayment.id
            }
        });
    }

    return (
        <Modal
            isOpen={isNoteModalVisible}
            toggle={() => toggleModal(false)}
            className="modal-dialog-centered modal-lg"
        >
            <div className="modal-header">
                <h2>{`Add note`}</h2>
            </div>
            <div className="modal-body">
                <InputCustom
                    type={`text`}
                    label={`Note`}
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                />
            </div>
            <div className="modal-footer mb-4">
                <Button
                    className="new-event--add"
                    color="primary"
                    type="button"
                    onClick={() => addNote()}
                >
                    {`Save`}
                </Button>
                <Button
                    className="ml-auto"
                    color="danger"
                    type="button"
                    onClick={() => {
                        toggleModal(false)
                    }}
                >
                    {`Close`}
                </Button>
            </div>
        </Modal>
    );
}