import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// import usePrevious from '../../../hooks/useprevious';
import * as emailTemplateActions from '../../../../redux/Email/actions';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody, CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Row,
} from 'reactstrap';
import InputCustom from '../../components/InputCustom';
import { Editor } from '@tinymce/tinymce-react';
import * as constants from '../../../../constants';
import CardsHeader from '../../../../layouts/Headers/CardsHeader';
import usePrevious from '../../../../hooks/useprevious';
import useNotify from '../../../../hooks/notify';
import NotificationAlert from 'react-notification-alert';
import { useParams } from 'react-router';
import { isTemplateMiddle } from 'typescript';
import Shortcodes from '@pages/components/shortcodes/Index';
import {ButtonContainer} from "@pages/reusable-components/styled-components";

function CreateForm() {
  const requireFields = {
    name: 'Name field is required',
    content: 'Content field is required',
  };

  /*
   * redux variables
   * */
  const emailTemplateState = useSelector(
    (state) => state.emailTemplateState,
    shallowEqual
  );
  const dispatch = useDispatch();

  const templateCreatedPrev = usePrevious(emailTemplateState.templateCreated);
  const templateUpdatedPrev = usePrevious(emailTemplateState.templateUpdated);
  const history = useHistory();
  const { ref, notify } = useNotify();
  let { id } = useParams();
  const isEdit = !!id;

  const [emailTemplateData, setEmailTemplateData] = useState({});
  const [emailTemplateErrors, setEmailTemplateErrors] = useState([]);
  const dealerRoles = useSelector((state) => emailTemplateState.roles, shallowEqual);
  const shortcodes = useSelector((state) => state.emailTemplateState.shortcodes, shallowEqual);

  useEffect(() => {
    if (isEdit) {
      const template = emailTemplateState.templates.find(
        (template) => template.id === id
      );

      if (template) {
        setEmailTemplateData(template);
      } else {
        // TODO maybe we will need to get template by id in this case
        history.push('/admin/settings/email-templates');
      }
    }
  }, []);

  useEffect(() => {
    dispatch({
      type: emailTemplateActions.GET_ROLES_REQUEST
    });
  }, []);

  useEffect(() => {
    dispatch({
      type: emailTemplateActions.GET_SHORTCODES_REQUEST
    });
  }, []);

  useEffect(() => {
    if (
      emailTemplateState.templateCreated !== templateCreatedPrev &&
      emailTemplateState.templateCreated === true &&
      templateCreatedPrev !== undefined
    ) {
      // notify('Email Template Created Successfully');
      history.push('/admin/settings/email-templates');
    }
  }, [emailTemplateState.templateCreated]);

  useEffect(() => {
    if (
      emailTemplateState.getRolesSuccess === true
    ) {
    }
  }, [emailTemplateState.getRolesSuccess]);

  useEffect(() => {
    if (
      emailTemplateState.templateUpdated !== templateUpdatedPrev &&
      emailTemplateState.templateUpdated === true &&
      templateUpdatedPrev !== undefined
    ) {
      // notify('Email Template Updated Successfully');
      history.push('/admin/settings/email-templates');
    }
  }, [emailTemplateState.templateUpdated]);

  function handleInputChange(field, value) {
    setEmailTemplateData({ ...emailTemplateData, [field]: value });
  }

  function validateInputs() {
    let errorFree = true;
    const emailTemplateErrorsCopy = [...emailTemplateErrors];
    Object.keys(requireFields).map((field) => {
      if (!emailTemplateData[field] || emailTemplateData[field]?.length === 0) {
        errorFree = false;

        emailTemplateErrorsCopy[field] = requireFields[field];
      }
    });

    setEmailTemplateErrors(emailTemplateErrorsCopy);

    return errorFree;
  }

  function createTask() {
    if (validateInputs()) {
      if (isEdit) {
        dispatch({
          type: emailTemplateActions.UPDATE_EMAIL_TEMPLATE_REQUEST,
          data: emailTemplateData,
          id,
        });
      } else {
        dispatch({
          type: emailTemplateActions.CREATE_EMAIL_TEMPLATE_REQUEST,
          data: emailTemplateData,
        });
      }
    }
  }

  return (
    <>
      <CardsHeader
        name="Settings"
        parentName="Email Templates"
        currentName={isEdit ? 'Update' : `Create`}
      />
      <Container className="mt--5 admin-main-body" fluid>
        <NotificationAlert ref={ref} />
        <Card>
          <CardHeader>
            <Row>
              <Col xs="auto" className="text-right">

              </Col>
            </Row>
            <Row>
              <Col xs={8}>
                <h1 className="mb-0">
                  {isEdit ? 'Update' : `Create`} Email Template
                </h1>
              </Col>
            </Row>
          </CardHeader>

          <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
            <Shortcodes items={shortcodes}/>
          </CardBody>
          <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
            <InputCustom
              label={`Template Name`}
              value={emailTemplateData?.name || ''}
              onChange={(e) => handleInputChange('name', e.target.value)}
              invalid={!!(emailTemplateErrors && emailTemplateErrors.name)}
              errorMessage={
                emailTemplateErrors?.name ? emailTemplateErrors.name : ''
              }
            />

            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="example3cols2Input"
              >
                Content
              </label>
              <Editor
                initialValue={emailTemplateData?.content || ''}
                apiKey={constants.TINYMCE_KEY}
                init={{
                  height: 500,
                  menubar: true,
                  plugins: ['code', 'preview', 'pagebreak', 'image'],
                  toolbar:
                    'undo redo | formatselect | bold italic | \
                        alignleft aligncenter alignright alignjustify | \
                        outdent indent',
                }}
                onEditorChange={(content) =>
                  handleInputChange('content', content)
                }
              />
              <div
                className="invalid-feedback"
                style={{
                  display: emailTemplateErrors.content ? 'block' : 'none',
                }}
              >
                {emailTemplateErrors.content}
              </div>
            </FormGroup>
            <FormGroup>
              <label className="form-control-label">Assign to</label>
              <Select
                onChange={(v) => handleInputChange('assignee', v)}
                options={dealerRoles.map((item) => {
                  return {
                    value: item.id,
                    label: item.name,
                  }
                })}
                value={emailTemplateData?.assignee}
                closeMenuOnSelect={false}
                isMulti
              />
              {emailTemplateErrors && emailTemplateErrors.assignee && (
                <div className="invalid-feedback" style={{ display: 'block' }}>
                  {emailTemplateErrors.assignee}
                </div>
              )}
            </FormGroup>

          </CardBody>
          <CardFooter className={'justify-content-end'}>
            <ButtonContainer
                reverse
                onClick={createTask}
            >
              {isEdit ? 'Update' : `Save`}
            </ButtonContainer>
            <ButtonContainer
                onClick={() =>
                    history.push('/admin/settings/email-templates')
                }
            >
              Back to list
            </ButtonContainer>

          </CardFooter>
        </Card>
      </Container>
    </>
  );
}
export default CreateForm;
