import React, { useContext } from 'react';

import { DealersContext } from '../context';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";

export default function ModulesTab() {
  const dealersContext = useContext(DealersContext);
  const {
    assignedModules,
    modules,
    AllTag,
    updateModules
  } = dealersContext;
  return (
    <Card>
      <CardHeader>
        <h2 className="title">Dealer modules</h2>
      </CardHeader>
      <CardBody>
        {assignedModules && modules && AllTag(modules)}
        <div className="d-flex flex-row justify-content-center">
          <Button className="w-100" color="success" onClick={() => updateModules()}>Save</Button>
        </div>
      </CardBody>
    </Card>
  )
}