import React, { PureComponent } from "react"
import { Document, Page, pdfjs } from "react-pdf"

class PdfComponent extends PureComponent {

    constructor(props) {
        super(props)
        pdfjs.GlobalWorkerOptions.workerSrc =
            `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
        this.state = {
            width: null,
            numPages: null,
            pageNumber: 1
        }

        this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
    }

    componentDidMount() {

    }

    onDocumentLoadSuccess({ numPages }) {
        this.setState({ numPages: numPages });
    }

    render() {
        return (
            <div>
                <Document
                    file={this.props.file}
                    onLoadError={(error) => console.log("Inside Error", error)}
                    onLoadSuccess={this.onDocumentLoadSuccess}>
                    {Array.from(new Array(this.state.numPages), (el, index) => (
                        <Page key={`page_${index + 1}`}  width={this.props.wrapperDivSize} pageNumber={index + 1} />
                    ))}
                </Document>
            </div>
        )
    }
}

export default PdfComponent