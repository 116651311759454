import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from "js-cookie";

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';


import InputCustom from 'views/pages/components/InputCustom';
import {
    InputKeySelect
} from 'views/pages/components/InputSelect';
import TableCustom from 'views/pages/components/TableCustom';

import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Button,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
} from "reactstrap";

import {
    create,
    update,
    deletee,
} from 'views/policies/DealPolicy';
import DynamicTable from "@pages/components/DynamicTable";

export default function Deal() {
    const notificationAlert = useRef(null);
    const history = useHistory();

    const profilePermissions = useSelector((state) => state.getState).permissions;

    const [token, setToken] = useState('');
    const [deals, setDeals] = useState([]);
    const [dealStatus, setDealStatus] = useState('');
    const [dealerUsers, setDealerUsers] = useState('');
    const [items, setItems] = useState('');

    const [role, setRole] = useState('');

    const [loading, setLoading] = useState(true);
    const [delay, setDelay] = useState(false);

    const [filterOptions, setFilterOptions] = useState('');

    const [confirmModal, setConfirmModal] = useState(false);
    const [selectedDealId, setSelectedDealId] = useState('');
    // full_name mobile_number created_by deal_status
    const [keys, setKeys] = useState(
        [
            {
                key: 'full_name',
                text: 'Customer Name'
            },
            {
                key: 'mobile_number',
                text: 'Phone Number'
            },
            {
                key: 'owner',
                text: 'Created By'
            },
            {
                key: 'deal_status',
                text: 'Deal Status'
            },
            {
                key: 'stock_no',
                text: 'Deal Status'
            },
            {
                key: 'actions',
                text: 'Actions'
            },
        ]
    )
    const notify = (type, header, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
              {header}
          </span>
                    <span data-notify="message">
            <div dangerouslySetInnerHTML={{__html: message}}/>
          </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5
        };
        notificationAlert.current.notificationAlert(options);
    };

    useEffect(() => {
        let grs_token = Cookies.get('grs_token')
        setToken(grs_token)

        let savedPageNumber = 1
        let savedFilter = ''
        if (localStorage.getItem('grms_deals_search_filter')) {
            savedFilter = JSON.parse(localStorage.getItem('grms_deals_search_filter'))
            setFilterOptions(savedFilter)
        }
        const source = axios.CancelToken.source()

        fetchData(source, savedPageNumber, grs_token, savedFilter)

        return () => {
            source.cancel()
        }
    }, [])

    const fetchData = async (source, pageNumber = 1, token, filter) => {

        try {
            localStorage.setItem('grms_deals_search_filter', JSON.stringify(filter))
            const response = await axios({
                method: 'GET',
                url: `${constants.API_URL}/deal`,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    ...filter,
                    page: pageNumber,
                },
                cancelToken: source.token,
            })
            console.log('response :: ', response.data)
            const data = response.data
            setDeals(data.data.items.data)
            setRole(data.data.role)
            setDealStatus(data.data.dealStatus)
            setDealerUsers(data.data.dealerUsers ? data.data.dealerUsers : '')
            setItems(data.data.items)

            setTimeout(() => {
                setLoading(false)
                setDelay(true)
            }, 500);
        } catch (error) {
            setLoading(false);
            if (axios.isCancel(error)) {
                console.log('Request canceled :: ', error)
            } else {
                console.error('error :: ', error)
                notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
            }
        }
    }

    const editDeal = (id) => {
        history.push({
            pathname: '/admin/deals/' + id + '/edit'
        })
    }

    const deleteDeal = () => {
        axios({
            method: 'DELETE',
            url: constants.API_URL + '/deal/' + selectedDealId,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then(response => {
                setConfirmModal(false);
                if (response.status === 200) {
                    let data = response.data;
                    setDeals(data.data.data);
                    notify('success', 'Success', data.message);

                    setTimeout(() => {
                        setDelay(true)
                    }, 1000);
                }
            })
            .catch(error => {
                setConfirmModal(false);
                console.error('error :: ', error);
                notify('warning', 'Warning', error.response.statusText);
            })
    }

    const filterPayments = () => {
        const source = axios.CancelToken.source()
        fetchData(source, 1, token, {
            ...filterOptions,
            search: filterOptions.searchValue,
            dealer_id: filterOptions.dealer_id === '-' ? undefined : filterOptions.dealer_id,
            deal_status: filterOptions.deal_status === '-' ? undefined : filterOptions.deal_status,
            created_by: filterOptions.created_by === '-' ? undefined : filterOptions.created_by,
        })
    }

    function getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key] === value);
    }

    const goAdd = () => {
        history.push({
            pathname: '/admin/deals/create'
        })
    }
    const paginate = (page) => {
        const source = axios.CancelToken.source()
        fetchData(source, page, token, {
            ...filterOptions,
            search: filterOptions.searchValue,
            dealer_id: filterOptions.dealer_id === '-' ? undefined : filterOptions.dealer_id,
            deal_status: filterOptions.deal_status === '-' ? undefined : filterOptions.deal_status,
            created_by: filterOptions.created_by === '-' ? undefined : filterOptions.created_by,
        })
    }
    const clearFilters = () => {
        const source = axios.CancelToken.source()
        setFilterOptions({})
        fetchData(source,1,token,{})
    }
    useEffect(() => {
        if (Object.values(filterOptions).length > 0) {
            filterPayments()
        }
    }, [filterOptions])
    return (
        <>
            <CardsHeader name="Deal Management" parentName="Deals Management" currentName="List"/>
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlert}/>
            </div>
            <Container className="mt--5 admin-main-body" fluid>
                <Row>
                    <Col xl="12">
                        <Card>
                            {/*<CardHeader>*/}
                            {/*    <Row>*/}
                            {/*        <Col xs={8}>*/}
                            {/*            <h1 className="mb-0">Deals</h1>*/}
                            {/*        </Col>*/}
                            {/*        <Col xs={4} className="text-right">*/}
                            {/*            {create(profilePermissions, role.auth_user_team) &&*/}
                            {/*            <Button className="w-100" color="success" type="button" onClick={() => goAdd()}>Add*/}
                            {/*                deal</Button>}*/}
                            {/*        </Col>*/}
                            {/*    </Row>*/}
                            {/*</CardHeader>*/}
                            <CardBody>
                                <DynamicTable
                                    showClearButton
                                    clearFilers={clearFilters}
                                    tableData={deals}
                                    showButton={create(profilePermissions, role.auth_user_team)}
                                    addButtonText={'Add Deal'}
                                    addNew={goAdd}
                                    showClear={true}
                                    paginate={paginate}
                                    showStatus
                                    editEvent={(id)=>{editDeal(id)}}
                                    deleteEvent={(id)=>{
                                        setSelectedDealId(id)
                                        setConfirmModal(true)
                                    }}
                                    selectedStatus={dealStatus[filterOptions?.deal_status]}
                                    creatorName={dealerUsers[filterOptions?.dealer_id]}
                                    onSearchChange={(e) => {
                                        setFilterOptions({
                                            ...filterOptions,
                                            searchValue: e.target.value,
                                        })
                                    }}
                                    showCreatedByFilter
                                    selectCreator={e => {
                                        setFilterOptions({
                                            ...filterOptions,
                                            dealer_id: getKeyByValue(dealerUsers, e),
                                        })
                                    }}
                                    selectStatus={e => {
                                        setFilterOptions({
                                            ...filterOptions,
                                            deal_status: getKeyByValue(dealStatus, e),
                                        })
                                    }}
                                    creators={Object.values(dealerUsers)}
                                    statuses={Object.values(dealStatus)}
                                    noKeyForFilter={true}
                                    pagination={items}
                                    showCarFilter={false}
                                    noPriority={false}
                                    keys={keys}/>
                                {/*<TableCustom*/}
                                {/*  loading={loading}*/}
                                {/*  delay={delay}*/}
                                {/*  items={deals}*/}
                                {/*  keyField={`id`}*/}
                                {/*  searchField={false}*/}
                                {/*  pagination={items}*/}
                                {/*  onChange={(pageNumber) => fetchData(axios.CancelToken.source(), pageNumber, token, {*/}
                                {/*    ...filterOptions,*/}
                                {/*    search: filterOptions.searchValue,*/}
                                {/*    dealer_id: filterOptions.dealer_id === '-'? undefined: filterOptions.dealer_id,*/}
                                {/*    deal_status: filterOptions.deal_status === '-'? undefined: filterOptions.deal_status,*/}
                                {/*    created_by: filterOptions.created_by === '-'? undefined: filterOptions.created_by,*/}
                                {/*  })}*/}
                                {/*  columns={[*/}
                                {/*    {*/}
                                {/*      dataField: "full_name",*/}
                                {/*      text: "Name",*/}
                                {/*      sort: true*/}
                                {/*    },*/}
                                {/*    {*/}
                                {/*      dataField: "mobile_number",*/}
                                {/*      text: "Phone",*/}
                                {/*      sort: true*/}
                                {/*    },*/}
                                {/*    {*/}
                                {/*      dataField: "created_by",*/}
                                {/*      text: "Created By",*/}
                                {/*      sort: true,*/}
                                {/*      formatter: (rowContent, row) => {*/}
                                {/*        return (*/}
                                {/*          <>*/}
                                {/*            {!role.auth_user_team && (row.dealer.name? row.dealer.name: '-')}*/}
                                {/*            {!role.superadministrator && (row.owner.name? row.owner.name: '-')}*/}
                                {/*          </>*/}
                                {/*        )*/}
                                {/*      }*/}
                                {/*    },*/}
                                {/*    {*/}
                                {/*      dataField: "deal_status",*/}
                                {/*      text: "Deal Status",*/}
                                {/*      sort: true,*/}
                                {/*      formatter: (rowContent, row) => {*/}
                                {/*        return row.last_action? dealStatus[row.last_action]: dealStatus[row.deal_status]*/}
                                {/*      }*/}
                                {/*    },*/}
                                {/*    {*/}
                                {/*      dataField: "stock_no",*/}
                                {/*      text: "Stock No",*/}
                                {/*      sort: true*/}
                                {/*    },*/}
                                {/*    {*/}
                                {/*      dataField: "",*/}
                                {/*      text: "",*/}
                                {/*      formatter: (rowContent, row) => {*/}
                                {/*        return (*/}
                                {/*          <>*/}
                                {/*            {(update(profilePermissions, role.auth_user_team, row) || deletee(profilePermissions, role.auth_user_team, row, siteCode)) && */}
                                {/*            <UncontrolledDropdown>*/}
                                {/*              <DropdownToggle*/}
                                {/*                className="btn-icon-only text-light"*/}
                                {/*                color=""*/}
                                {/*                role="button"*/}
                                {/*                size="sm"*/}
                                {/*              >*/}
                                {/*                <i className="fas fa-ellipsis-v" />*/}
                                {/*              </DropdownToggle>*/}
                                {/*              <DropdownMenu className="dropdown-menu-arrow" right>*/}
                                {/*                {update(profilePermissions, role.auth_user_team, row) && */}
                                {/*                <DropdownItem*/}
                                {/*                  onClick={e => editDeal(row)}*/}
                                {/*                >*/}
                                {/*                  Edit*/}
                                {/*                </DropdownItem>}*/}
                                {/*                {deletee(profilePermissions, role.auth_user_team, row, siteCode) && */}
                                {/*                <DropdownItem*/}
                                {/*                  onClick={e => {*/}
                                {/*                    setSelectedDealId(row.id)*/}
                                {/*                    setConfirmModal(true)*/}
                                {/*                  }}*/}
                                {/*                >*/}
                                {/*                  Delete*/}
                                {/*                </DropdownItem>}*/}
                                {/*              </DropdownMenu>*/}
                                {/*            </UncontrolledDropdown>}*/}
                                {/*          </>*/}
                                {/*        )*/}
                                {/*      }*/}
                                {/*    }*/}
                                {/*  ]}*/}
                                {/*/>*/}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {/* Delete Modal */}
                <Modal
                    isOpen={confirmModal}
                    toggle={() => setConfirmModal(false)}
                    className="modal-dialog-centered modal-secondary"
                >
                    <div className="modal-content align-items-center pd-20">

                    <div >
                        Are you sure ?
                    </div>
                    <div className="modal-body">
                       You are going to delete the deal
                    </div>
                    <div className="modal-footer">
                        <Button
                            className="ml-auto"
                            color="link"
                            type="button"
                            onClick={() => setConfirmModal(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="new-event--add"
                            color="warning"
                            type="button"
                            onClick={() => deleteDeal()}
                        >
                            {`Delete`}
                        </Button>
                    </div>
                    </div>
                </Modal>
            </Container>
        </>
    )
}