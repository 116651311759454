import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import NotificationAlert from 'react-notification-alert'
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { fetchIntervalStats, saveIntervalStats } from '@redux/Vehicle/actions';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
  Input,
} from 'reactstrap';


import CardsHeader from 'layouts/Headers/CardsHeader.js';
import useNotify from 'hooks/notify';
import FilterComponent from "@pages/fleet/pages/components/FilterComponent";
import CalendarFilter from "@pages/components/CalendarFilter";
import {ButtonContainer} from "@pages/reusable-components/styled-components";


export default function DailyStatistics() {
  const { ref, notify } = useNotify();
  const history = useHistory();
  const dispatch = useDispatch();

  const [dailyStats, setDailyStats] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const { rows, stats, hasSuccess } = useSelector((state) => state.vehicle);

  useEffect(() => {
    let startDate = moment().startOf('isoWeek');
    let endDate = moment().endOf('isoWeek');
    setStartDate(startDate);
    setEndDate(endDate);
    dispatch(fetchIntervalStats(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')));

    return () => {
    }
  }, []);

  useEffect(() => {
    setDailyStats({ ...stats });
  }, [stats]);

  useEffect(() => {
    if(hasSuccess) {
      notify("Successfully updated!", "Success", "success")
    }
  }, [hasSuccess]);

  const renderDateInterval = () => {
    return Object.keys(dailyStats).map((item, key) => {
      return (
      <th scope="col" key={key}>{dailyStats[item].day_formatted}</th>
    )});
  }

  const renderInputValueInterval = (column, disabled = false) => {
    return Object.keys(dailyStats).map((item, key) => (
      <td key={key} scope="row" >
        <Input
          disabled={disabled}
          type="number"
          min={0}
          data-date={item}
          value={dailyStats && dailyStats[item] && dailyStats[item][column] ? dailyStats[item][column] : ''}
          onChange={(e) => {
            setDailyStats({
              ...dailyStats,
              [e.target.getAttribute("data-date")]: {
                ...dailyStats[e.target.getAttribute("data-date")],
                [column]: e.target.value
              }
            })
          }}
        />
      </td>
    ));
  }
  return (
    <>
      <CardsHeader name="Fleets" parentName="Live vehicles" currentName="Statistics" />
      <div className="rna-wrapper">
        <NotificationAlert ref={ref} />
      </div>
      <Container className="mt--5 admin-main-body" id="liveVehiclesSection" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Daily statistics</h1>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Container>
                  <Row className={'align-items-center'}>
                      <CalendarFilter initial={true} filterByDate={(data)=>{
                        setStartDate(moment(data.from));
                        setEndDate(moment(data.to));
                      }}/>
                    <ButtonContainer reverse onClick={() => {
                      setDailyStats({});
                      dispatch(fetchIntervalStats(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')));
                    }}>Fetch statistics</ButtonContainer>
                  </Row>

                  {rows && startDate && endDate &&
                    <>
                      <Row>
                        <Col xs={12}>
                          <table className="table table-responsive">
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                {renderDateInterval()}
                              </tr>
                            </thead>
                            <tbody>
                              {rows.map((row, index) => (
                                <React.Fragment key={index}>
                                  <tr>
                                    <td colSpan={endDate.diff(startDate, 'days')} scope="row" ><span className='font-weight-bold'>{row.group}</span></td>
                                  </tr>
                                  {row.items.map((subGroup, number) => (
                                    <tr key={number + index * 10}>
                                      <td scope="row" >{subGroup.label}</td>
                                      {renderInputValueInterval(subGroup.column, subGroup.disabled)}
                                    </tr>
                                  ))
                                  }
                                </React.Fragment>
                              ))
                              }
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <ButtonContainer reverse onClick={() => {
                            dispatch(saveIntervalStats(dailyStats));
                          }}>Save</ButtonContainer>
                        </Col>
                      </Row>
                    </>}
                </Container>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}