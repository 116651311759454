import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import TableCustom from 'views/pages/components/TableCustom';
import InputCustom from 'views/pages/components/InputCustom';
import {
  InputKeySelect,
  InputArraySelect,
} from 'views/pages/components/InputSelect';
import InputToggle from 'views/pages/components/InputToggle';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from 'reactstrap';

export default function AutomationTasksForm() {
  const { id } = useParams();
  const notificationAlert = useRef(null);
  const history = useHistory();

  const [token, setToken] = useState('');
  const [beforeOrAfter, setBeforeOrAfter] = useState('');
  const [intervals, setIntervals] = useState('');
  const [taskTypes, setTaskTypes] = useState('');
  const [task, setTask] = useState([]);

  const [errors, setErrors] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const url = id ? `${constants.API_URL}/automation-task/${id}/edit` : `${constants.API_URL}/automation-task/create`

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: url,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data
        
        setBeforeOrAfter(data.data.beforeOrAfter)
        setIntervals(data.data.intervals)
        setTaskTypes(data.data.taskTypes)
        setTask(data.data.task)
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const goList = () => {
    history.push({
      pathname: `/admin/settings/automation-task`
    })
  }

  const addTask = () => {
    axios({
      method: 'POST',
      url: `${constants.API_URL}/automation-task/`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: task
    })
      .then(response => {
        setErrors('')
        setTask('')
        console.log('res :: ', response.data)
        let data = response.data
        notify('success', 'Success', data.message)
        setTimeout(() => {
          history.push({
            pathname: '/admin/settings/automation-task'
          })
        }, 1500);
      })
      .catch(error => {
        setErrors('')
        console.error('error :: ', error)
        if (error.response && error.response.status == 422) {
          setErrors(error.response.data.errors)
        }
        notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
      })
  }

  const updateTask = () => {
    axios({
      method: 'PUT',
      url: `${constants.API_URL}/automation-task/${task.id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: task
    })
      .then(response => {
        setErrors('')
        setTask('')
        console.log('res :: ', response.data)
        let data = response.data
        notify('success', 'Success', data.message)
        setTimeout(() => {
          history.push({
            pathname: '/admin/settings/automation-task'
          })
        }, 1500);
      })
      .catch(error => {
        setErrors('')
        console.error('error :: ', error)
        if (error.response && error.response.status == 422) {
          setErrors(error.response.data.errors)
        }
        notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
      })
  }

  return (
    <>
      <CardsHeader name="Settings" parentName="Automated Task Management" currentName="Create" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Task Management</h1>
                  </Col>
                  <Col xs={4} className="text-right">
                    <Button className="w-100" color="success" type="button" onClick={() => goList()}>Back to list</Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={6} sm={12}>
                    <Row>
                      <Col md={6} sm={12}>
                        <InputCustom 
                          label={`Title`}
                          value={task && task.title? task.title: ''}
                          onChange={e => setTask({
                            ...task,
                            title: e.target.value
                          })}
                          invalid={errors && errors.title? true: false}
                          errorMessage={errors.title}
                        />
                      </Col>
                      <Col md={6} sm={12}>
                        <InputKeySelect 
                          label={`Type to applied`}
                          options={taskTypes}
                          value={task && task.apply_to? task.apply_to: ''}
                          onChange={e => setTask({
                            ...task,
                            apply_to: e.target.value
                          })}
                          defaultOption={true}
                          invalid={errors && errors.apply_to? true: false}
                          errorMessage={errors.apply_to}
                        />
                      </Col>
                    </Row>
                    <InputCustom
                      label={`Description`}
                      value={task && task.description ? task.description : ''}
                      onChange={e => setTask({
                        ...task,
                        description: e.target.value
                      })}
                      invalid={errors && errors.description ? true : false}
                      errorMessage={errors.description}
                    />
                    <Row>
                      <Col md={6} sm={12}>
                        <InputToggle 
                          label={`Is Active`}
                          checked={task && task.is_active == 1 ? true : false}
                          onChange={() => setTask({
                            ...task,
                            is_active: task.is_active == 1? 0: 1
                          })}
                          side={true}
                          invalid={errors && errors.is_active? true: false}
                          errorMessage={errors.is_active}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} sm={12}>
                        <InputToggle 
                          label={`Is Recurrent`}
                          checked={task && task.is_recurrent == 1 ? true : false}
                          onChange={() => setTask({
                            ...task,
                            is_recurrent: task.is_recurrent == 1? 0: 1
                          })}
                          side={true}
                          invalid={errors && errors.is_recurrent? true: false}
                          errorMessage={errors.is_recurrent}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6} sm={12}>
                    <Row>
                      <Col md={6} sm={12}>
                        <InputCustom 
                          type={`number`}
                          label={`Apply after`}
                          value={task && task.interval_to_apply_after ? task.interval_to_apply_after : ''}
                          onChange={e => setTask({
                            ...task,
                            interval_to_apply_after: e.target.value
                          })}
                          invalid={errors && errors.interval_to_apply_after ? true : false}
                          errorMessage={errors.interval_to_apply_after}
                        />
                      </Col>
                      <Col md={6} sm={12}>
                        <InputKeySelect 
                          label={`(months, years)`}
                          options={intervals}
                          value={task && task.period_to_apply_after? task.period_to_apply_after: ''}
                          onChange={e => setTask({
                            ...task,
                            period_to_apply_after: e.target.value
                          })}
                          defaultOption={true}
                          invalid={errors && errors.period_to_apply_after? true: false}
                          errorMessage={errors.period_to_apply_after}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={7} sm={12}>
                        <InputCustom 
                          type={`number`}
                          label={`Extra days before/after due date`}
                          value={task && task.extra_days_before_or_after_interval ? task.extra_days_before_or_after_interval : ''}
                          onChange={e => setTask({
                            ...task,
                            extra_days_before_or_after_interval: e.target.value
                          })}
                          invalid={errors && errors.extra_days_before_or_after_interval ? true : false}
                          errorMessage={errors.extra_days_before_or_after_interval}
                        />
                      </Col>
                      <Col md={5} sm={12}>
                        <InputKeySelect 
                          label={`(before or after)`}
                          options={beforeOrAfter}
                          value={task && task.extra_days_before_or_after_period? task.extra_days_before_or_after_period: ''}
                          onChange={e => setTask({
                            ...task,
                            extra_days_before_or_after_period: e.target.value
                          })}
                          defaultOption={true}
                          invalid={errors && errors.extra_days_before_or_after_period? true: false}
                          errorMessage={errors.extra_days_before_or_after_period}
                        />
                      </Col>
                    </Row>
                    <h2 className="text-center mb-3">The interval below is going to be added to the Due Date interval, based on your selection</h2>
                    <Row>
                      <Col md={6} sm={12}>
                        <InputCustom 
                          type={`number`}
                          label={`Add delay to end date`}
                          value={task && task.recurrent_interval_to_apply_after ? task.recurrent_interval_to_apply_after : ''}
                          onChange={e => setTask({
                            ...task,
                            recurrent_interval_to_apply_after: e.target.value
                          })}
                          invalid={errors && errors.recurrent_interval_to_apply_after ? true : false}
                          errorMessage={errors.recurrent_interval_to_apply_after}
                        />
                      </Col>
                      <Col md={6} sm={12}>
                        <InputKeySelect 
                          label={`(months, years)`}
                          options={intervals}
                          value={task && task.recurrent_period_to_apply_after? task.recurrent_period_to_apply_after: ''}
                          onChange={e => setTask({
                            ...task,
                            recurrent_period_to_apply_after: e.target.value
                          })}
                          defaultOption={true}
                          invalid={errors && errors.recurrent_period_to_apply_after? true: false}
                          errorMessage={errors.recurrent_period_to_apply_after}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div className="d-flex flex-row justify-content-center">
                  <Button className="w-100" color="success" onClick={() => id? updateTask(): addTask()}>Save</Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}