import React, {useEffect, useState, useRef} from 'react'
import {NavLink, useHistory, useLocation} from 'react-router-dom'

import axios from 'axios'
import Cookies from 'js-cookie'
import * as constants from 'constants/index.js'
import styled from "styled-components";

import ReportsTable from "./components/ReportsTable";
import {LoaderContainer, override} from "../../reusable-components/styled-components";
import {ClipLoader} from "react-spinners";

const List = styled.ul`
  display: flex;
  list-style-type: none;
  float: right;
  flex-direction: row;
  border-bottom: 1px solid lightgray;
  width: 100%;
  cursor: pointer;
`;

const ListItems = styled.li`
  margin-right: 6%;
  color: #9095a4;

  ${(props) =>
    props.activeTab &&
    `
      color: #ec6409;
      ::after {
        content: "";
        position: relative;
        height: 2px;
        margin-top: 0.5rem;
        border-radius: 5px 5px 0 0;
        background-color: #ec6409;
        display: block;
      }
    `}
`;

const Div = styled.div`
  display: flex;
  margin-left: 6%;
`;

export default function Reports() {
    const location = useLocation();
    const history = useHistory();
    const [token, setToken] = useState('');
    const [makes, setMakes] = useState([]);
    const [models, setModels] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [workerData, setWorkerData] = useState([]);
    const [filters, setFilters] = useState({});
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('tasks');
    const [workerKeys] = useState([
        {
            key: 'name',
            label: 'Full Name'
        },
        {
            key: 'completed',
            label: 'Completed Tasks / Total hours'
        },
        {
            key: 'failed',
            label: 'Failed Tasks / Total hours'
        },
        {
            key: 'job_category',
            label: 'Department'
        },
        {
            key: 'role_type',
            label: 'Role'
        },
    ])

    const [reportKeys] = useState([
        {
            key: 'title',
            label: 'Task title / Execution time'
        },
        {
            key: 'workers',
            label: 'Workers'
        },
        {
            key: 'time',
            label: 'Working Time'
        },
        {
            key: 'job_category',
            label: 'Department'
        },
        {
            key: 'make',
            label: 'Make'
        },
        {
            key: 'model',
            label: 'Model'
        },
        {
            key: 'stock_no',
            label: 'Reg No'
        }
    ])
    useEffect(() => {
        const grs_token = Cookies.get('grs_token')
        setToken(grs_token)
        let savedFilters = {}
        savedFilters = JSON.parse(localStorage.getItem('grms_fleet_reports'))
        setFilters(savedFilters)
        fetchFilters(grs_token)
        fetchData(grs_token,savedFilters)
    }, [])
    const fetchData = async (grs_token, filterData = {}, tabName = '') => {
        if (tabName === 'tasks' || tabName === '') {
            const data = await axios({
                method: 'GET',
                url: constants.FLEET_URL + `/fleet-management/reports/tasks`,
                headers: {
                    Authorization: 'Bearer ' + grs_token
                },
                params: filterData
            })
            setTableData(data.data.data)
        }
        if (tabName === 'workers' || tabName === '') {

            const workerData = await axios({
                method: 'GET',
                url: constants.FLEET_URL + `/fleet-management/reports/worker`,
                headers: {
                    Authorization: 'Bearer ' + grs_token
                },
                params: filterData
            })
            setWorkerData(workerData.data.data.workers)
        }

    }
    const fetchFilters = async (grs_token) => {
        const makeRequest = await axios({
            method: 'GET',
            url: constants.FLEET_URL + '/fleet-management/vehicle/makes',
            headers: {
                Authorization: 'Bearer ' + grs_token
            }
        })
        setMakes(makeRequest.data.data.makes)
        const modelRequest = await axios({
            method: 'GET',
            url: constants.FLEET_URL + '/fleet-management/vehicle/models',
            headers: {
                Authorization: 'Bearer ' + grs_token
            }
        })
        setModels(modelRequest.data.data.models)
        const getDepartments = await axios({
            method: 'GET',
            url: constants.FLEET_URL + '/fleet-management/fleet/tasks/create/options',
            headers: {
                Authorization: 'Bearer ' + grs_token
            }
        })
        let tempCategories = []
        const tempDepartments = getDepartments.data.data.jobCategories
        Object.keys(tempDepartments).map(key => {
            tempCategories.push({
                value: tempDepartments[key],
                key: key
            })
        })
        setDepartments(tempCategories)
        setLoading(false)
    }
    const getData = (data) => {
        let tempFilter = {...filters, ...data}
        localStorage.setItem('grms_fleet_reports', JSON.stringify(tempFilter))
        setFilters(tempFilter)
        fetchData(token, tempFilter, activeTab)
    }
    const clearData = (data) => {
        setFilters({})
        localStorage.removeItem('grms_fleet_reports')
        fetchData(token, {}, activeTab)
    }
    const searchData = (data) => {
        let tempFilter = filters
        tempFilter['search'] = data
        localStorage.setItem('grms_fleet_reports', JSON.stringify(tempFilter))
        fetchData(token, tempFilter, activeTab)
        setFilters(tempFilter)
    }
    return (
        <>

            {
                loading ?
                    <LoaderContainer>
                        <ClipLoader
                            css={override}
                            size={40}
                            color={`#7B61E4`}
                            loading={loading}
                        />
                    </LoaderContainer> :
                    <>
                        <div className='mt-2'>
                            <List>
                                <ListItems activeTab={activeTab === 'tasks'}>
                                    <div onClick={() => {
                                        setActiveTab('tasks')
                                        setFilters({})
                                        localStorage.setItem('grms_fleet_reports',null)
                                    }}>Tasks</div>

                                </ListItems>
                                <ListItems activeTab={activeTab === 'workers'}>
                                    <div onClick={() => {
                                        setActiveTab('workers')
                                        setFilters({})
                                        localStorage.setItem('grms_fleet_reports',null)
                                    }}>Workers</div>
                                </ListItems>
                            </List>
                        </div>
                        <ReportsTable
                            tableData={tableData}
                            makes={makes}
                            initialFilters={filters}
                            tabName={activeTab}
                            models={models}
                            reportKeys={reportKeys}
                            workerData={workerData}
                            workerKeys={workerKeys}
                            apply={(data) => getData(data)}
                            clearData={() => clearData()}
                            search={(data) => searchData(data)}
                            departments={departments}
                        />
                    </>
            }
        </>
    )
}