import {
    CalendarInput, DeliveryTableItem,
    LoaderContainer, override,
    TaskDetailsContainer,
    Text,
    TextContainer
} from "@pages/reusable-components/styled-components";
import {Button, Input} from "reactstrap";
import React, {useEffect, useState} from "react";
import Select from "react-select";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "react-modern-calendar-datepicker";
import moment from "moment";
import {object} from "prop-types";
import se from "react-datepicker";
import {formatDate, parseToWord} from "../../../constants";
import cloneDeep from 'lodash/cloneDeep';
import {ClipLoader} from "react-spinners";
import styled from "styled-components";
import CustomIcon from "react-bootstrap-sweetalert/dist/components/CustomIcon";

const ItemsContainer = styled.div`
    max-width: 160px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
`
export default function CreateEditDelivery({
                                               setShowCreateNew,
                                               jobData = {},
                                               checkData,
                                               drivers = [],
                                               allTasks = [],
                                               selectItem,
                                               index = 0,
                                               creating = true,
                                               updateCreated = false,
                                               disabled = false,
                                               save,
                                               exit,
                                               loading,
                                               addToCollection,
                                               updateCollectionData,
                                           }) {
    const [selectedDay, setSelectedDay] = useState(null);
    const [isGrsCollection, setIsGrsCollection] = useState(false);
    const [isDelivery, setIsDelivery] = useState(false);
    const [isCollect, setIsCollect] = useState(false);
    const [isSmall,setIsSmall] = useState(window.innerWidth < 833)
    const [currentIndex, setCurrentIndex] = useState(null);
    const [isDriverDelivery, setIsDriverDelivery] = useState(false);
    const [isDriverCollect, setIsDriverCollect] = useState(false);
    const [isDriverWork, setIsDriverWork] = useState(false);
    const [selectedCollectionDay, setSelectedCollectionDay] = useState(null);
    const [sendData, setSendData] = useState({
        colour: '',
        current_mileage: '',
        customer_name: '',
        customer_address: '',
        customer_postal_code: '',
        customer_phone: '',
        task_type: '',
        execution_date: '',
        grs_collection_date: '',
        driver_id: '',
        description: '',
        customer_id: null,
        driver_getter_id: null,
        driver_getter_name: null,
        driver_getter_address: null,
        driver_getter_phone: null,

    });

    const [keys, setKeys] = useState(
        [
            {
                key: 'task_type',
                text: 'Task Type'
            },
            {
                key: 'execution_date',
                text: 'Execution Date'
            },
            {
                key: 'driver',
                text: 'Driver'
            },
            {
                key: 'customer_address',
                text: 'Collect From'
            },
            {
                key: 'customer_name',
                text: 'Deliver To'
            },
            {
                key: 'customer_phone',
                text: 'Phone Number'
            },
            {
                key: 'customer_postal_code',
                text: 'Post code'
            },


        ]
    )
    const [usedTaskTypes, setUsedTaskTypes] = useState([])
    const [typesOrigin] = useState([
        {
            label: 'Delivery',
            value: 'delivery',
        },
        {
            label: 'Collect',
            value: 'collect',
        },
        {
            label: 'Collection from GRS',
            value: 'grs_collection',
        },
        {
            label: 'Collection from Driver',
            value: 'collect_from_driver',
        },
        {
            label: 'Deliver to Driver',
            value: 'deliver_to_driver',
        },
    ])
    const [taskTypes, setTypes] = useState([
        {
            label: 'Delivery',
            value: 'delivery',
        },
        {
            label: 'Collect',
            value: 'collect',
        },
        {
            label: 'Collection from GRS',
            value: 'grs_collection',
        },
        {
            label: 'Collection from Driver',
            value: 'collect_from_driver',
        },
        {
            label: 'Deliver to Driver',
            value: 'deliver_to_driver',
        },


    ])
    const renderCustomInput = ({ref}) => (
        <CalendarInput
            width='100%'
            border={'1px solid #e6e6e7'}
            padding='6px'
            readOnly
            ref={ref} // necessary
            placeholder="Select date"
            value={selectedDay ? moment({...selectedDay, month: selectedDay.month - 1,}).format('DD/MMM/YYYY') : ''}
        />
    )

    const generateCustomItem = (task, key) => {

        if (key === 'execution_date') {
            if (task.task_type === 'grs_collection') {
                return <td>{task.grs_collection_date}</td>
            }
        }
        if (key === 'customer_address') {
            if (task.task_type === 'grs_collection') {
                return <td>Unit 12 & 13, Milford Haven SA73 2DA</td>
            } else if (task.task_type !== 'delivery' && task.task_type !== 'collect') {
                return <td>{task.driver_getter_address}</td>
            }
        }
        if (key === 'customer_name') {
            if (task.task_type === 'grs_collection' || task.task_type === 'collect_from_driver') {
                return <td>-</td>
            } else if (task.task_type === 'deliver_to_driver') {
                return <td>{task.driver_getter_id ? drivers.find(driver => driver.id === task.driver_getter_id).name : '-'}</td>
            }
        }
        if (key === 'customer_postal_code' && task.task_type !== 'delivery' && task.task_type !== 'collect') {
            return '-'
        }
        if (key === 'customer_phone' && (task.task_type === 'grs_collection' ||
            task.task_type === 'collect_from_driver'
            || task.task_type === 'deliver_to_driver')) {

            return <td>{task.driver_getter_phone}</td>
        }
        if (key === 'driver') {
            return <td>{task.driver_id ? drivers.find(driver => driver.id === task.driver_id).name : '-'}</td>
        }
        if (key === 'task_type') {
            return <td>{parseToWord(task[key])}</td>
        }
        return <td>{task[key] ? task[key] : '-'}</td>
    }
    const renderGrsCustomInput = ({ref}) => (
        <CalendarInput
            width='100%'
            border={'1px solid #e6e6e7'}
            padding='6px'
            readOnly
            ref={ref} // necessary
            placeholder="Select date"
            value={selectedCollectionDay ? moment({
                ...selectedCollectionDay,
                month: selectedCollectionDay.month - 1,
            }).format('DD/MMM/YYYY') : ''}
        />
    )
    const initData = () => {
        if (Object.values(jobData).length) {
            setSelectedDay(null)
            let tempData = cloneDeep(sendData)
            tempData.driver_id = null
            tempData.execution_date = null
            Object.keys(tempData).map(key => {
                tempData[key] = jobData[key]
            })
            if (jobData.task_type === 'deliver_to_driver' || jobData.task_type === 'collect_from_driver') {
                tempData['driver_getter_id'] = tempData.customer_id
                tempData['driver_getter_name'] = tempData.customer_name
                tempData['driver_getter_address'] = tempData.customer_address
                tempData['driver_getter_phone'] = tempData.customer_phone
            }
            if (creating) {
                tempData['task_type'] = Object.values(taskTypes)[0].value
            }
            setSendData({...sendData, ...tempData})
            if (!creating) {
                if (jobData.task_type === 'deliver_to_driver' ||
                    jobData.task_type === 'collect_from_driver' ||
                    jobData.task_type === 'grs_collection') {
                    setTypes(typesOrigin.filter(item => item.value !== 'delivery' && item.value !== 'collect'))
                }
                const tempDate = formatDate(new Date(jobData.execution_date)).split('-')
                setSelectedDay({
                    day: +tempDate[2],
                    year: +tempDate[0],
                    month: +tempDate[1],
                })
                const tempSelDate = formatDate(new Date(jobData?.grs_collection_date)).split('-')
                setSelectedCollectionDay({
                    day: +tempSelDate[2],
                    year: +tempSelDate[0],
                    month: +tempSelDate[1],
                })

            }
        }
    }
    useEffect(()=>{
        setIsSmall(window.innerWidth < 833)
    },[window.innerWidth])
    useEffect(() => {
        initData()
    }, [jobData])
    useEffect(() => {
        if (sendData.task_type === 'grs_collection') {
            setIsGrsCollection(true)
            setIsDriverWork(true)
            setIsCollect(false)
            setIsDelivery(false)
            setIsDriverCollect(false)
            setIsDriverDelivery(false)
        } else if (sendData.task_type === 'collect_from_driver') {
            setIsDriverCollect(true)
            setIsDriverWork(true)
            setIsCollect(false)
            setIsDelivery(false)
            setIsGrsCollection(false)
            setIsDriverDelivery(false)
        } else if (sendData.task_type === 'deliver_to_driver') {
            setIsDriverDelivery(true)
            setIsDriverWork(true)
            setIsCollect(false)
            setIsDelivery(false)
            setIsGrsCollection(false)
            setIsDriverCollect(false)
        } else if (sendData.task_type === 'delivery') {
            setIsDelivery(true)
            setIsDriverWork(false)
            setIsDriverDelivery(false)
            setIsCollect(false)
            setIsDriverCollect(false)
            setIsGrsCollection(false)
        } else if (sendData.task_type === 'collect') {
            setIsCollect(true)
            setIsDelivery(false)
            setIsDriverWork(false)
            setIsDriverDelivery(false)
            setIsDriverCollect(false)
            setIsGrsCollection(false)
        }
        checkData(sendData)
    }, [sendData])
    const selectSendingDriver = (e) => {
        setSendData({
            ...sendData,
            driver_getter_id: e.id,
            driver_getter_name: e.name,
            driver_getter_address: e.address,
            driver_getter_phone: e.phone_number ?? ''
        })
    }

    const selectTaskType = (e) => {
        if (!isDriverWork) {
            initData()
        }
        if (e.value === 'grs_collection') {
            setSendData({
                ...sendData,
                task_type: e.value,
                driver_getter_address: 'Unit 12 & 13, Milford Haven SA73 2DA',
            })
        } else {
            setSendData({...sendData, task_type: e.value})
        }
    }
    const selectDriver = (e) => {
        if (isGrsCollection) {
            setSendData({
                ...sendData,
                driver_getter_id: e.id,
                driver_id: e.id,
                driver_getter_name: e.name,
                driver_getter_address: e.address,
                driver_getter_phone: e.phone_number ?? ''
            })
        } else {
            setSendData({...sendData, driver_id: e.id})
        }
    }
    const updateCollection = () => {
        if (updateCreated) {
            updateCollectionData(currentIndex)
            let tempTypes = usedTaskTypes
            tempTypes[currentIndex].type = sendData.task_type
            setUsedTaskTypes(tempTypes)
        } else {
            setUsedTaskTypes([...usedTaskTypes, {item: index, type: sendData.task_type}])
            addToCollection()
        }

        initData()
    }

     //todo can be useful
    // const setItem = (index) => {
    //     setCurrentIndex(index)
    //     setSendData(selectItem(index))
    // }
    return (
        loading ? <LoaderContainer>
                <ClipLoader
                    css={override}
                    size={40}
                    color={`#7B61E4`}
                    loading={loading}
                />
            </LoaderContainer> :
            <>

                <TaskDetailsContainer column gap='80px' marginTop={!creating && '60px'}>
                    <TaskDetailsContainer noMarginBottom marginLeft={'30px'}>
                        <img height='180px' width='188px' src={jobData.featured_image} alt=""/>
                    </TaskDetailsContainer>
                    <TaskDetailsContainer noMarginBottom>
                        <TextContainer justify='space-around'>
                            <img height='25px' width='25px'
                                 src={require(`assets/images/directions_car_grey.png`)} alt=""/>
                            <img height='25px' width='25px'
                                 src={require(`assets/images/waves.png`)} alt=""/>
                            <img height='25px' width='25px'
                                 src={require(`assets/images/add_road.png`)} alt=""/>
                        </TextContainer>
                        <TextContainer justify='space-around'>
                            <Text bold>Vehicle:</Text>
                            <Text bold>Color:</Text>
                            <Text bold>Mileage:</Text>
                        </TextContainer>
                        <TextContainer justify='space-around'>
                            <Input disabled value={`${jobData.vehicle_make}, ${jobData.vehicle_model}`}/>
                            <Input
                                value={sendData.colour}
                                onChange={(e) => setSendData({...sendData, colour: e.target.value})}
                            />
                            <Input
                                value={sendData.current_mileage}
                                onChange={(e) => setSendData({...sendData, current_mileage: e.target.value})}
                            />

                        </TextContainer>
                    </TaskDetailsContainer>

                </TaskDetailsContainer>


                <TaskDetailsContainer>
                    <Text bold size='18px'>
                        Create Task
                    </Text>
                </TaskDetailsContainer>

                <TaskDetailsContainer column marginTop='20px'>
                    <TaskDetailsContainer width={isSmall ? "100%" : "30%"}>
                        <img height='25px' width='25px'
                             src={require(`assets/images/add_road.png`)} alt=""/>
                        <TextContainer width='100%'>
                            <Text bold>Task Type:</Text>
                            <Select

                                styles={{ menu: provided => ({ ...provided, zIndex: 9999 })}}
                                options={taskTypes}
                                value={taskTypes.find(item => item.value === sendData.task_type)}
                                onChange={(e) => selectTaskType(e)}
                            />
                        </TextContainer>
                    </TaskDetailsContainer>
                    {!isGrsCollection ?
                        <TaskDetailsContainer width={isSmall ? "100%" : "30%"}>
                            <img height='25px' width='25px'
                                 src={require(`assets/images/date_range.png`)} alt=""/>
                            <TextContainer width='100%'>
                                <Text bold>Date of execution:</Text>
                                <DatePicker
                                    value={selectedDay}
                                    onChange={(e) => {
                                        setSendData({
                                            ...sendData,
                                            execution_date: moment({...e, month: e.month - 1,}).format('YYYY-MM-DD')
                                        })
                                        setSelectedDay(e)
                                    }}
                                    renderInput={renderCustomInput}
                                    shouldHighlightWeekends
                                />
                            </TextContainer>
                        </TaskDetailsContainer> :
                        <TaskDetailsContainer width={isSmall ? "100%" : "30%"}>
                            <img height='25px' width='25px'
                                 src={require(`assets/images/date_range.png`)} alt=""/>
                            <TextContainer width='100%'>
                                <Text bold>GRS collection date:</Text>
                                <DatePicker
                                    value={selectedCollectionDay}
                                    onChange={(e) => {
                                        setSendData({
                                            ...sendData,
                                            grs_collection_date: moment({
                                                ...e,
                                                month: e.month - 1,
                                            }).format('YYYY-MM-DD')
                                        })
                                        setSelectedCollectionDay(e)
                                    }}
                                    renderInput={renderGrsCustomInput}
                                    shouldHighlightWeekends
                                />
                            </TextContainer>
                        </TaskDetailsContainer>
                    }
                    <TaskDetailsContainer width={isSmall ? "100%" : "30%"}>
                        <img height='25px' width='25px'
                             src={require(`assets/images/taskEdit/person.png`)} alt=""/>
                        <TextContainer width='100%'>
                            <Text bold>Driver:</Text>
                            <Select
                                options={drivers}
                                value={drivers.find(item => item.id === sendData.driver_id) || null}
                                getOptionLabel={option => option.name}
                                onChange={(e) => selectDriver(e)}
                                getOptionValue={option => option.id}
                            />
                        </TextContainer>
                    </TaskDetailsContainer>

                </TaskDetailsContainer>
                <TaskDetailsContainer>
                    <Text bold size='18px'>
                        {(isDriverDelivery || isDelivery) ? 'Deliver to:' : 'Collect from:'}
                    </Text>
                </TaskDetailsContainer>
                {!isGrsCollection && <TaskDetailsContainer column>
                    <TaskDetailsContainer width={isSmall ? '100%' : '25%'} marginLeft={'30px'}>
                        <img height='25px' width='25px'
                             src={require(`assets/images/taskEdit/person.png`)} alt=""/>
                        <TextContainer width={(isDriverDelivery || isDriverCollect) && '70%'}>

                            {isDriverDelivery ?
                                <Text bold>To Driver:</Text> :
                                isDriverCollect ?
                                    <Text bold>From Driver: </Text>
                                    : !isGrsCollection && <Text bold>Customer:</Text>
                            }
                            {(isDriverDelivery || isDriverCollect) ?
                                <Select
                                    options={drivers}
                                    value={drivers.find(item => item.id === sendData.driver_getter_id) || null}
                                    getOptionLabel={option => option.name}
                                    onChange={(e) => selectSendingDriver(e)}
                                    getOptionValue={option => option.id}
                                />
                                : !isGrsCollection &&
                                <Input
                                    value={sendData.customer_name}
                                    disabled={jobData.hasOwnProperty('customer_id') && jobData.customer_id !== null}
                                    onChange={(e) => setSendData({...sendData, customer_name: e.target.value})}
                                />
                            }
                        </TextContainer>
                    </TaskDetailsContainer>
                    <TaskDetailsContainer width={isSmall ? '100%' : '25%'}  marginLeft={'30px'}>
                        <img height='25px' width='25px'
                             src={require(`assets/images/call_grey.png`)} alt=""/>
                        <TextContainer>

                            <Text bold>Phone:</Text>

                            {(!isDriverWork) ?
                                <Input
                                    value={sendData.customer_phone}
                                    onChange={(e) => setSendData({...sendData, customer_phone: e.target.value})}
                                /> : isDriverDelivery || isDriverCollect ?
                                    <Input
                                        value={sendData.driver_getter_phone}
                                        onChange={(e) => setSendData({
                                            ...sendData,
                                            driver_getter_phone: e.target.value
                                        })}
                                    /> : null

                            }
                        </TextContainer>
                    </TaskDetailsContainer>


                </TaskDetailsContainer>}
                {isDriverWork ?
                    <TaskDetailsContainer column marginLeft={'30px'} width={isSmall ? '100%' : '50%'}>
                        {isGrsCollection ?
                            <img height='25px' width='25px'
                                 src={require(`assets/images/location_on_grey.png`)} alt=""/>
                            : <img height='25px' width='25px'
                                   src={require(`assets/images/person_card.png`)} alt=""/>}
                        <TextContainer width='100%'>
                            <Text bold>{sendData.task_type === 'grs_collection' ? 'Grs Location:' : 'Address:'}</Text>
                            {isGrsCollection ?
                                <Input
                                    value={'Unit 12 & 13, Milford Haven SA73 2DA'}
                                    disabled={true}
                                />
                                :
                                <Input
                                    value={sendData.driver_getter_address}
                                    onChange={(e) =>
                                        setSendData({
                                            ...sendData,
                                            driver_getter_address: e.target.value
                                        })}
                                />
                            }
                        </TextContainer>
                    </TaskDetailsContainer>
                    :
                    <TaskDetailsContainer column>
                        <TaskDetailsContainer  marginLeft={'30px'} width={isSmall ? '100%' : '50%'}>
                            <img height='25px' width='25px'
                                 src={require(`assets/images/person_card.png`)} alt=""/>
                            <TextContainer width='100%'>
                                <Text bold>{'Address:'}</Text>
                                <Input
                                    value={sendData.customer_address}
                                    onChange={(e) =>
                                        setSendData({
                                            ...sendData,
                                            customer_address: e.target.value
                                        })}
                                />
                            </TextContainer>
                        </TaskDetailsContainer>
                        {(!isDriverWork) && <TaskDetailsContainer width={isSmall ? '100%' : '25%'} marginLeft={'30px'}>
                            <img height='25px' width='25px'
                                 src={require(`assets/images/post-office.png`)} alt=""/>
                            <TextContainer>

                                <Text bold>{'Postal code:'}</Text>
                                <Input
                                    value={sendData.customer_postal_code}
                                    onChange={(e) => setSendData({...sendData, customer_postal_code: e.target.value})}
                                />
                            </TextContainer>
                        </TaskDetailsContainer>
                        }
                    </TaskDetailsContainer>}


                <TaskDetailsContainer marginLeft={'30px'}>
                    <TaskDetailsContainer width={isSmall ? '100%' : '40%'}>
                        <img height='25px' width='25px'
                             src={require(`assets/images/taskEdit/comment.png`)} alt=""/>
                        <TextContainer width='100%'>
                            <Text bold>Description:</Text>
                            <Input
                                type='textarea'
                                value={sendData.description}
                                onChange={(e) => setSendData({...sendData, description: e.target.value})}
                            />
                        </TextContainer>
                    </TaskDetailsContainer>
                </TaskDetailsContainer>
                {creating &&
                <>
                    <TaskDetailsContainer direction={'column'}>
                        <Button style={{
                            backgroundColor: "#ec6409",
                            color: 'white',
                            height: '50px',
                            width: '200px',
                            cursor: 'pointer',
                        }} onClick={updateCollection}>{updateCreated ? 'Update Created' : 'Add new task'}</Button>

                        <table className="table mobile-table">
                            <thead>
                            <tr>
                                {
                                    keys.map((column, index) => {
                                        return <th key={index + ''} tabIndex="0">{column?.text}</th>
                                    })

                                }
                            </tr>
                            </thead>
                            <tbody>
                            {
                                allTasks && allTasks.length > 0 &&
                                allTasks.map((task, index) => {
                                    return <tr key={index}>
                                        {
                                            keys.map((item) => {
                                                return generateCustomItem(task, item.key)
                                            })
                                        }

                                    </tr>
                                })
                            }
                            </tbody>
                        </table>
                    </TaskDetailsContainer>

                </>
                }

                <TaskDetailsContainer  float='right' width={!isSmall && '40%'}>
                    <Button
                        style={{
                            backgroundColor: "white",
                            color: '#ec6409',
                            border: '1px solid #ec6409',
                            marginTop: '25px'
                        }}
                        type="button" onClick={() => {
                        creating ?
                            setShowCreateNew(false) : exit()
                    }}>Cancel</Button>
                    {
                        creating ? <Button
                                style={{
                                    backgroundColor: "#ec6409",
                                    color: 'white',

                                    marginTop: '25px'
                                }}
                                disabled={allTasks?.length < 1}
                                type="button" onClick={() => {
                                save()
                            }}>Create Task</Button> :
                            <Button style={{
                                backgroundColor: "#ec6409",
                                color: 'white',
                                marginTop: '25px'
                            }} type="button" onClick={() => {
                                save()
                            }}>Update</Button>
                    }
                </TaskDetailsContainer>
            </>
    )
}