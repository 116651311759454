import React, {useContext, useState} from 'react';

import { DealContext } from '../context';

import InputCustom from 'views/pages/components/InputCustom';

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
  UncontrolledAlert,
  FormGroup,
  Input,
} from "reactstrap";
import InputToggle from 'views/pages/components/InputToggle';
import TableCustom from "@pages/components/TableCustom";

export default function DocumentTab() {
  const dealContext = useContext(DealContext);
  const {
    allowEdit,
    inspectionDocuments,
    downloadDocument,
    dealerProfile,
    documents,
    hasAccessToDocumentModule,
    documentModal, setDocumentModal,
    document, setDocument,
    getDocument,
    viewSigning,
    checkSignedDocument,
    sendDocument,
  } = dealContext;

  return (
    <CardBody>
      <Card>
        <CardHeader>
          <h2>Generate Documents</h2>
        </CardHeader>
        <CardBody>
          {!dealerProfile && <UncontrolledAlert color="info" fade={false}>
            <span className="alert-inner--icon">
              <i className="ni ni-like-2" />
            </span>{" "}
            <span className="alert-inner--text">
              {`Make sure your dealer profile is complete`}<a href="">{`Check profile`}</a>
            </span>
          </UncontrolledAlert>}
          <Row>
            {Object.keys(documents).map(key => {
              return (
                <Col md={6} sm={12} key={key} className="border-right border-top border-default p-4">
                  <Row>
                    <Col md={12} sm={12}>
                      <p className="form-control-label">{documents[key].document_name}</p>
                    </Col>
                    <Col md={6} sm={12}>
                      <Button className="w-100" color="success" onClick={() => viewSigning(documents[key].id)}>{`VIEW`}</Button>
                    </Col>
                    <Col md={6} sm={12}>
                      <Button className="w-100" color="success" onClick={() => getDocument(documents[key].id)}>{`DOWNLOAD`}</Button>
                    </Col>
                  </Row>
                  {
                    documents[key].docs && documents[key].docs?.length > 0 &&
                    documents[key].docs.map((doc, index1) => {
                      return (
                        <Row key={index1}>
                          <Col md={12} sm={12}>
                            <p>{`Created At: `}<b>{doc.created_at}</b></p>
                            {doc.finalized_at && <p>{`Finalized At: `}<b>{doc.finalized_at}</b></p>}
                          </Col>
                          <Col md={12} sm={12}>
                            {doc.finalized_at && <a target="_blank" onClick={() => checkSignedDocument(doc.id)}> {`Check Signed Document`} </a>}
                          </Col>
                        </Row>
                      )
                    })
                  }
                </Col>
              )
            })
            }
          </Row>
          {hasAccessToDocumentModule ?
            allowEdit && <div className="d-flex flex-row justify-content-left mt-3">
                <Button className="w-25" color="warning" onClick={() => setDocumentModal(true)}>Send document for singing</Button>
            </div>
            :
            <h2 className="mt-3">{`You need to pass the Signable API key`}</h2>}
        </CardBody>
      </Card>
      <Modal
        isOpen={documentModal}
        toggle={() => setDocumentModal(false)}
        className="modal-dialog-centered"
      >
        <div className="modal-header">
          <h6 className="modal-title">{`Sign document`}</h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setDocumentModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <FormGroup>
            <label className="form-control-label">{`Document type`}</label>
            <Input
              type="select"
              value={document && document.adobe_document_type ? document.adobe_document_type : ''}
              onChange={(e) => setDocument({
                ...document,
                adobe_document_type: e.target.value,
              })}
            >
              <option>-</option>
              {
                Object.keys(documents).map(key => {
                  return <option value={documents[key].id} key={key}>{documents[key].document_name}</option>
                })
              }
            </Input>
          </FormGroup>
          <InputCustom
            label={`Document name`}
            value={document && document.adobe_document_name ? document.adobe_document_name : ''}
            onChange={(e) => setDocument({
              ...document,
              adobe_document_name: e.target.value,
            })}
          />
          <InputCustom
            type={`textarea`}
            label={`Email template`}
            rows={5}
            value={document && document.adobe_email_template ? document.adobe_email_template : ''}
            onChange={(e) => setDocument({
              ...document,
              adobe_email_template: e.target.value,
            })}
          />
          <div className='col-12 col-md-12 col-sm-12'>
            <InputToggle
              label={`Is local signing ?`}
              checked={document && document.is_local_signing ? document.is_local_signing : ''}
              onChange={e => {
                setDocument({
                  ...document,
                  is_local_signing: e.target.value == 'on' ? 1 : 0
                })
              }}
            />
          </div>
        </div>
        <div className="modal-footer">
          <Button
            className="w-100 text-center"
            color="success"
            type="button"
            onClick={() => sendDocument()}
          >
            {`Send`}
          </Button>
        </div>
      </Modal>
      {inspectionDocuments?.length &&
      <>
        <h2 className="text-center mt-5">Uploaded pdfs</h2>
        <TableCustom
            loading={false}
            items={inspectionDocuments}
            keyField={`name`}
            searchField={true}
            columns={[
              {
                dataField: "name",
                text: "Name",
                sort: true,
                formatter: (rowContent, row) => {
                  return `Document - ${row.document.split('/').pop()}`
                }
              },
              {
                dataField: "",
                text: "Action",
                formatter: (rowContent, row) => {
                  return (
                      <>
                        <img className={'cursor-pointer'}
                             height={'25px'}
                             width={'25px'}
                             onClick={e => downloadDocument(row.document)}
                             src={require(`assets/images/tableIcons/visibility.svg`)}
                             alt=""/>
                      </>
                  )
                }
              }
            ]}
        />
      </>
      }
    </CardBody>
  );
}