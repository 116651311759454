import  Dashboard from './re_dashboard.svg'
import Admin from './re_admin.svg'
import Deal from './re_deal.svg'
import Email from './re_email.svg'
import FleetManagement from './re_fleet_management.svg'
import Leads from './re_leads.svg'
import LiveVehicles from './re_live_vehicle.svg'
import Vehicles from './re_vehicles.svg'
import Reports from './re_reports.svg'
import Payments from './re_payments.svg'
import Settings from './re_settings.svg'
import Tasks from './re_tasks.svg'
import Website from './re_website.svg'
import Tracking from './re_tracking.svg'
import Customers from './re_customers.svg'

export default {
    Dashboard,
    Admin,
    Deal,
    Email,
    FleetManagement,
    Leads,
    LiveVehicles,
    Vehicles,
    Reports,
    Payments,
    Settings,
    Tasks,
    Website,
    Tracking,
    Customers,
}