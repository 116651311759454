export const GET_VALIDITY_REQUEST = 'GET_VALIDITY_REQUEST';
export const GET_VALIDITY_SUCCESS = 'GET_VALIDITY_SUCCESS';
export const GET_VALIDITY_ERROR = 'GET_VALIDITY_ERROR';


export const SIGN_DOCUMENT_REQUEST = 'SIGN_DOCUMENT_REQUEST';
export const SIGN_DOCUMENT_SUCCESS = 'SIGN_DOCUMENT_SUCCESS';
export const SIGN_DOCUMENT_ERROR = 'SIGN_DOCUMENT_ERROR';


export const SET_SIGNATURE_URL = 'SET_SIGNATURE_URL';
export const CLEAN_SIGNATURE_URL = 'CLEAN_SIGNATURE_URL';