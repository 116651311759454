import React, { useEffect, useState, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import NotificationAlert from 'react-notification-alert'
import moment from 'moment';
import styled from 'styled-components';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button
} from 'reactstrap';

import { fetchMonthSummary } from '@redux/Vehicle/actions';
import {
  InputArraySelect,
} from 'views/pages/components/InputSelect'

import CardsHeader from 'layouts/Headers/CardsHeader.js';
import useNotify from 'hooks/notify';
import {ButtonContainer} from "@pages/reusable-components/styled-components";

const TableWrapper = styled.div`
  margin: 1rem 0;
  h2 {
    font-size: 1rem;
  }

  table {
    &.table {
      td {
        font-size: 0.95rem !important;
      }
    }
  }
`;

const months = [
  { id: 1, name: "January" },
  { id: 2, name: "February" },
  { id: 3, name: "March" },
  { id: 4, name: "April" },
  { id: 5, name: "May" },
  { id: 6, name: "June" },
  { id: 7, name: "July" },
  { id: 8, name: "August" },
  { id: 9, name: "September" },
  { id: 10, name: "October" },
  { id: 11, name: "November" },
  { id: 12, name: "December" },
]

export default function VehicleReport() {
  const { ref, notify } = useNotify();
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const { weekly_stats, summary } = useSelector((state) => state.vehicle);

  const yearsOptions = useMemo(() => {
    let options = [];
    let year = moment().toDate().getFullYear();
    if (year) {
      for (let i = year - 2; i <= year; i++) {
        options.push({
          id: i,
          name: i
        })
      }
    }

    return options;
  }, []);

  useEffect(() => {
    let month = moment().toDate().getMonth() + 1;
    setSelectedMonth(month);
    let year = moment().toDate().getFullYear();
    setSelectedYear(year);
    dispatch(fetchMonthSummary(month, year));
  }, []);


  return (
    <>
      <CardsHeader name="Fleets" parentName="Live vehicles" currentName="Statistics" />
      <div className="rna-wrapper">
        <NotificationAlert ref={ref} />
      </div>
      <Container className="mt--5 admin-main-body" id="liveVehiclesSection" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">View vehicle report {selectedMonth ? `${months.find((month) => month.id === selectedMonth)?.name}` : ''} {selectedYear ?? ''}</h1>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Container>
                  <Row>
                    <Col xs={12} sm={6}>
                      <InputArraySelect
                        newLabel={`Target Month`}
                        options={months}
                        value={selectedMonth}
                        onChange={(e) => setSelectedMonth(e.target.value)}
                        valueKey={`id`}
                        labelKey={`name`}
                      />
                    </Col>
                    <Col xs={12} sm={6}>
                      <InputArraySelect
                          newLabel={`Target Year`}
                        options={yearsOptions}
                        value={selectedYear}
                        onChange={(e) => setSelectedYear(e.target.value)}
                        valueKey={`id`}
                        labelKey={`name`}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-5">
                    <Col xs={12}>
                      <ButtonContainer reverse onClick={() => {
                        dispatch(fetchMonthSummary(selectedMonth, selectedYear));
                      }}>Fetch</ButtonContainer>
                    </Col>
                  </Row>
                </Container>
              </CardBody>
              <CardBody>
                <Container>
                  {summary && (
                    <Row>
                      <Col xs={12}>
                        <h2 className=''>Sales summary {selectedMonth ? `${months.find((month) => month.id === selectedMonth)?.name}` : ''} {selectedYear ?? ''}</h2>
                      </Col>
                      <Col xs={12}>
                        <TableWrapper>
                          <h2 className=''>Rentals</h2>
                          <table className="table table-responsive custom-left-th">
                            <thead>
                              <tr>
                                <th scope="col">Rental</th>
                                <th scope="col">Total</th>
                                <th scope="col">Monthly Target</th>
                                <th scope="col">% of Target</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td scope="col">Number of Deals</td>
                                <td scope="col">{summary.rental.total}</td>
                                <td scope="col">{summary.rental.target}</td>
                                <td scope="col">{summary.rental.achieved}</td>
                              </tr>
                              <tr>
                                <td scope="col">New Deals Payments</td>
                                <td scope="col">£{summary.rentals_amount.total}</td>
                                <td scope="col">£{summary.rentals_amount.target}</td>
                                <td scope="col">{summary.rentals_amount.achieved}</td>
                              </tr>
                            </tbody>
                          </table>
                        </TableWrapper>
                      </Col>
                      <Col xs={12}>
                        <TableWrapper>
                          <h2 className=''>Used Sales</h2>
                          <table className="table table-responsive custom-left-th">
                            <thead>
                              <tr>
                                <th scope="col">Used Sales</th>
                                <th scope="col">Total</th>
                                <th scope="col">Monthly Target</th>
                                <th scope="col">% of Target</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td scope="col">Trade Sales</td>
                                <td scope="col">{summary.trade_sale.total}</td>
                                <td scope="col">{summary.trade_sale.target}</td>
                                <td scope="col">{summary.trade_sale.achieved}</td>
                              </tr>
                              <tr>
                                <td scope="col">Retail Sales</td>
                                <td scope="col">{summary.retail_sale.total}</td>
                                <td scope="col">{summary.retail_sale.target}</td>
                                <td scope="col">{summary.retail_sale.achieved}</td>
                              </tr>
                              <tr>
                                <td scope="col">Auction Sales</td>
                                <td scope="col">{summary.auction_sale.total}</td>
                                <td scope="col">{summary.auction_sale.target}</td>
                                <td scope="col">{summary.auction_sale.achieved}</td>
                              </tr>
                              <tr>
                                <td scope="col">Sales</td>
                                <td scope="col">£{summary.sales_amount.total}</td>
                                <td scope="col">£{summary.sales_amount.target}</td>
                                <td scope="col">{summary.sales_amount.achieved}</td>
                              </tr>
                            </tbody>
                          </table>
                        </TableWrapper>
                      </Col>
                    </Row>
                  )}
                  {weekly_stats &&
                    <Row className="mt-3">
                      <Col xs={12}>
                        <h2 className=''>Weekly reports</h2>
                      </Col>
                      {weekly_stats.map((interval, key) => {
                        return (<Col xs={12} key={key} className="mt-2">
                          <TableWrapper>
                            <table className="table table-responsive w-100 table-statistics">
                              <thead>
                                <tr>
                                  <th scope="col" colSpan={4}><h2 className='text-center'>{interval.start} - {interval.end}</h2></th>
                                </tr>
                                <tr>
                                  <th scope="col"></th>
                                  <th scope="col">Target</th>
                                  <th scope="col">Actual</th>
                                  <th scope="col">Achieved</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td scope="col">New Deals</td>
                                  <td scope="col">{interval.stats.new_deals_target}</td>
                                  <td scope="col">{interval.stats.newDealsRentals}</td>
                                  <td scope="col">{interval.stats.new_deals_achieved}%</td>
                                </tr>
                                <tr>
                                  <td scope="col">Deals</td>
                                  <td scope="col">{interval.stats.rental_weekly_target}</td>
                                  <td scope="col">{interval.stats.totalInitialPayment}</td>
                                  <td scope="col">{interval.stats.rental_weekly_achieved}%</td>
                                </tr>
                                <tr rowSpan={2}><td colSpan={weekly_stats ? weekly_stats.length * 3 : 0}></td></tr>
                                <tr>
                                  <td scope="col">Auction Sales</td>
                                  <td scope="col">{interval.stats.auction_sale_target}</td>
                                  <td scope="col">{interval.stats.totalSoldAtAuctionVehicles ?? 0}</td>
                                  <td scope="col">{interval.stats.auction_sale_achieved}%</td>
                                </tr>
                                <tr>
                                  <td scope="col">Trade Sales</td>
                                  <td scope="col">{interval.stats.trade_sale_target}</td>
                                  <td scope="col">{interval.stats.totalSoldTradeVehicles ?? 0}</td>
                                  <td scope="col">{interval.stats.trade_sale_achieved}%</td>
                                </tr>
                                <tr>
                                  <td scope="col">Retail Sales</td>
                                  <td scope="col">{interval.stats.retail_sale_target}</td>
                                  <td scope="col">{interval.stats.totalSoldRetailVehicles ?? 0}</td>
                                  <td scope="col">{interval.stats.retail_sale_achieved}%</td>
                                </tr>
                                <tr>
                                  <td scope="col">Used Sales Amount</td>
                                  <td scope="col">{interval.stats.sales_weekly_target}</td>
                                  <td scope="col">{interval.stats.sales_weekly_actual}</td>
                                  <td scope="col">{interval.stats.sales_weekly_achieved}%</td>
                                </tr>
                              </tbody>
                            </table>
                          </TableWrapper>
                        </Col>)
                      })}
                    </Row>
                  }
                </Container>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}