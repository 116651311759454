import * as actionTypes from './actions';
import produce from 'immer';

const initialState = {
  downloadReportError: false,
  downloadReportSuccess: false,
  downloadReportMessage: '',
};

const reportState = produce((draft = initialState, action) => {
  switch (action.type) {
    case actionTypes.DOWNLOAD_REPORT_REQUEST:
      draft.downloadReportError = false;
      draft.downloadReportSuccess = false;
      draft.downloadReportMessage = '';
      break;
    case actionTypes.DOWNLOAD_REPORT_SUCCESS:
      draft.downloadReportError = false;
      draft.downloadReportSuccess = true;
      draft.downloadReportMessage = '';
      break;
    case actionTypes.DOWNLOAD_REPORT_ERROR:
      draft.downloadReportError = true;
      draft.downloadReportSuccess = false;
      draft.downloadReportMessage = action.errorMessage;
      break;
    default:
      return draft;
  }
});

export default reportState;
