import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import { connect } from 'react-redux';
import axios from 'axios';
import Cookies from "js-cookie";

import * as constants from 'constants/index.js';

import classnames from "classnames";
import { PropTypes } from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";

import SettingsResources from 'views/pages/settings/resources/Index';

import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav
} from "reactstrap";

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      resourcesRoutes: [],
      token: '',
      ...this.getCollapseStates(props.routes)
    };
  }
  componentDidMount() {
    let grs_token = Cookies.get('grs_token');
    this.setState({
      token: grs_token
    });
    axios({
      method: 'GET',
      url: constants.API_URL + '/resource-routes',
      headers: {
        Authorization: 'Bearer ' + grs_token
      }
    })
      .then(response => {
        if (response.status == 200) {
          let data = response.data
          let resourcesRoutes = data.data.resourcesRoutes
          this.setState({
            resourcesRoutes: resourcesRoutes
          })
          let views = []
          Object.keys(resourcesRoutes).map(key => {
            let resourcesRoute = resourcesRoutes[key]
            let _views = []

            if (resourcesRoute.categories && resourcesRoute.categories.length > 0) {
              resourcesRoute.categories.map((category, index) => {
                let _category = {
                  path: `/resource-categories/${category.slug}/docs`,
                  name: category.name,
                  miniName: "SR",
                  component: SettingsResources,
                  layout: "/admin"
                }
                _views.push(_category)
              })

              if (_views.length > 0) {
                let item = {
                  collapse: true,
                  name: resourcesRoute.name,
                  state: `${key.replaceAll('-', '')}Collapse`,
                  miniName: "R",
                  permission: [
                    'read-resource'
                  ],
                  views: _views
                }
                views.push(item)
              }
            }
          })
          this.props.routes.map((prop, index) => {
            if (prop.name === 'Resources') {
              prop.views = views
            }
          })
        }
      })
      .catch(error => {
        console.error('error :: ', error)
      })
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // makes the sidenav normal on hover (actually when mouse enters on it)
  onMouseEnterSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-show");
    }
  };
  // makes the sidenav mini on hover (actually when mouse leaves from it)
  onMouseLeaveSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
    }
  };
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false
    });
  };
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = routes => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // this is used on mobile devices, when a user navigates
  // the sidebar will autoclose
  closeSidenav = () => {
    if (window.innerWidth < 1200) {
      this.props.toggleSidenav();
    }
  };
  // check has permission
  hasPermission = (permissions, _permissions) => {
    return _permissions.some(permission => {
      return permissions.includes(permission)
    });
  }
  // check has can
  hasCan = (routeCan, can) => {
    return routeCan[can]
  }
  // check has role
  hasRole = (roles, _roles) => {
    return _roles.some(role => {
      return roles.includes(role)
    });
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = routes => {
    return routes.map((prop, key) => {
      if (this.props.permissions && this.props.routeCan && this.props.roles) {
        if (prop.permission) {
          if (!this.hasPermission(this.props.permissions, prop.permission)) {
            return null
          }
        }

        if (prop.role) {
          if (!this.hasRole(this.props.roles, prop.role)) {
            return null
          }
        }
  
        if (prop.can) {
          if (!this.hasCan(this.props.routeCan, prop.can)) {
            return null
          }
        }

        if (prop.authUserTeam === true) {
          if (!this.props.authUserTeam) {
            return null
          }
        } else if (prop.authUserTeam === false) {
          if (this.props.authUserTeam) {
            return null
          }
        }
  
        if (prop.name != 'NavHidden') {
          if (prop.redirect) {
            return null;
          }
          if (prop.collapse) {
            var st = {};
            st[prop["state"]] = !this.state[prop.state];
            return (
              <NavItem key={key}>
                <NavLink
                  href=""
                  data-toggle="collapse"
                  aria-expanded={this.state[prop.state]}
                  className={classnames({
                    active: this.getCollapseInitialState(prop.views)
                  })}
                  onClick={e => {
                    e.preventDefault();
                    this.setState(st);
                  }}
                >
                  {prop.icon ? (
                    <>
                      <i className={prop.icon} />
                      <span className="nav-link-text">{prop.name}</span>
                    </>
                  ) : prop.miniName ? (
                    <>
                      <span className="sidenav-mini-icon"> {prop.miniName} </span>
                      <span className="sidenav-normal"> {prop.name} </span>
                    </>
                  ) : null}
                </NavLink>
                <Collapse isOpen={this.state[prop.state]}>
                  <Nav className="nav-sm flex-column">
                    {this.createLinks(prop.views)}
                  </Nav>
                </Collapse>
              </NavItem>
            );
          }
          return (
            <NavItem
              className={this.activeRoute(prop.layout + prop.path)}
              key={key}
            >
              <NavLink
                to={prop.layout + prop.path}
                activeClassName=""
                onClick={this.closeSidenav}
                tag={NavLinkRRD}
              >
                {prop.icon !== undefined ? (
                  <>
                    <i className={prop.icon} />
                    <span className="nav-link-text">{prop.name}</span>
                  </>
                ) : prop.miniName !== undefined ? (
                  <>
                    <span className="sidenav-mini-icon"> {prop.miniName} </span>
                    <span className="sidenav-normal"> {prop.name} </span>
                  </>
                ) : (
                      prop.name
                    )}
              </NavLink>
            </NavItem>
          );
        }
      }
    });
  };
  render() {
    const { routes, logo } = this.props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: "_blank"
      };
    }
    const scrollBarInner = (
      <div className="scrollbar-inner">
        <div className="sidenav-header d-flex align-items-center">
          {logo ? (
            <NavbarBrand {...navbarBrandProps}>
              <img
                alt={logo.imgAlt}
                className="navbar-brand-img"
                src={logo.imgSrc}
              />
            </NavbarBrand>
          ) : null}
          <div className="ml-auto">
            <div
              className={classnames("sidenav-toggler d-none d-xl-block", {
                active: this.props.sidenavOpen
              })}
              onClick={this.props.toggleSidenav}
            >
              <div className="sidenav-toggler-inner">
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
              </div>
            </div>
          </div>
        </div>
        <div className="navbar-inner">
          <Collapse navbar isOpen={true}>
            <Nav navbar>{this.createLinks(routes)}</Nav>
            <hr className="my-3" />
          </Collapse>
        </div>
      </div>
    );
    return (
      <Navbar
        className={
          "sidenav navbar-vertical navbar-expand-xs navbar-light bg-white " +
          (this.props.rtlActive ? "" : "fixed-left")
        }
        onMouseEnter={this.onMouseEnterSidenav}
        onMouseLeave={this.onMouseLeaveSidenav}
      >
        {navigator.platform.indexOf("Win") > -1 ? (
          <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
        ) : (
          scrollBarInner
        )}
      </Navbar>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}],
  toggleSidenav: () => {},
  sidenavOpen: false,
  rtlActive: false
};

Sidebar.propTypes = {
  // function used to make sidenav mini or normal
  toggleSidenav: PropTypes.func,
  // prop to know if the sidenav is mini or normal
  sidenavOpen: PropTypes.bool,
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  // logo
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired
  }),
  // rtl active, this will make the sidebar to stay on the right side
  rtlActive: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    permissions: state.getState.permissions,
    authUserTeam: state.getState.authUserTeam,
    routeCan: state.getState.routeCan,
    roles: state.getState.roles,
  }
}

export default connect(
  mapStateToProps
)(Sidebar)