export const SAVE_TASK_PERSONALISATION_REQUEST = 'SAVE_TASK_PERSONALISATION_REQUEST';
export const SAVE_TASK_PERSONALISATION_SUCCESS = 'SAVE_TASK_PERSONALISATION_SUCCESS';
export const SAVE_TASK_PERSONALISATION_ERROR = 'SAVE_TASK_PERSONALISATION_ERROR';

export const TASK_PERSONALISATION_LIST_REQUEST = 'TASK_PERSONALISATION_LIST_REQUEST';
export const TASK_PERSONALISATION_LIST_SUCCESS = 'TASK_PERSONALISATION_LIST_SUCCESS';
export const TASK_PERSONALISATION_LIST_ERROR = 'TASK_PERSONALISATION_LIST_ERROR';

export const TASK_PERSONALISATION_EDIT_ROLE_REQUEST = 'TASK_PERSONALISATION_EDIT_ROLE_REQUEST';
export const TASK_PERSONALISATION_EDIT_ROLE_SUCCESS = 'TASK_PERSONALISATION_EDIT_ROLE_SUCCESS';
export const TASK_PERSONALISATION_EDIT_ROLE_ERROR = 'TASK_PERSONALISATION_EDIT_ROLE_ERROR';
