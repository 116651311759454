import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button, CardBody, Col, Row } from 'reactstrap';
import InputCustom from '@pages/components/InputCustom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import * as taskActions from '@redux/Task/actions';

function Filter({ setLoading, isSearched }) {
  const [searchValue, setSearchValue] = useState('');
  const [assignee, setAssignee] = useState([]);
  const dispatch = useDispatch();
  const [init, setInit] = useState(false);
  useEffect(() => {
    if (init) {
      const id = setTimeout(() => {
        setLoading(true);
        dispatch({
          type: taskActions.SEARCH_TASK_REQUEST,
          searchValue,
          assignee: JSON.stringify(assignee),
        });
      }, 300);
      return () => {
        clearTimeout(id);
      };
    }
    setInit(true);
  }, [searchValue, assignee, isSearched]);

  // const checkAssignee = (member) => {
  //   let index = assignee.indexOf(member.id);
  //   if (index === -1) {
  //     setAssignee([...assignee, member.id]);
  //   } else {
  //     assignee.splice(index, 1);
  //     setAssignee([...assignee]);
  //   }
  // };

  return (
    <CardBody>
      <Row style={{ alignItems: 'center' }}>
        <Col lg={2} md={4}>
          <InputCustom
            style={{ marginBottom: 0 }}
            placeholder="Search..."
            value={searchValue || ''}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />
        </Col>
        {/*{dealerAdmin ? (*/}
        {/*  <Col>*/}
        {/*    {dealerMembers.map((member, index) => {*/}
        {/*      let checked = {};*/}
        {/*      if (assignee.indexOf(member.id) !== -1) {*/}
        {/*        checked = { backgroundColor: 'grey' };*/}
        {/*      }*/}
        {/*      return (*/}
        {/*        <span*/}
        {/*          key={index}*/}
        {/*          className="avatar avatar-sm rounded-circle"*/}
        {/*          style={checked}*/}
        {/*          onClick={() => {*/}
        {/*            checkAssignee(member);*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          {member?.picture ? (*/}
        {/*            <img*/}
        {/*              alt={member?.name ? member?.name.slice(0, 2) : ''}*/}
        {/*              src={constants.STORAGE_URL + member?.picture}*/}
        {/*            />*/}
        {/*          ) : member?.name ? (*/}
        {/*            member?.name.slice(0, 2)*/}
        {/*          ) : (*/}
        {/*            ''*/}
        {/*          )}*/}
        {/*        </span>*/}
        {/*      );*/}
        {/*    })}*/}
        {/*  </Col>*/}
        {/*) : null}*/}
        {searchValue || assignee.length ? (
          <Col md={2}>
            <Button
              className="w-50"
              color="grey"
              onClick={() => {
                setSearchValue('');
                setAssignee([]);
              }}
            >
              Clear All
            </Button>
          </Col>
        ) : null}
      </Row>
    </CardBody>
  );
}
export default React.memo(Filter);

Filter.propTypes = {
  dealerMembers: PropTypes.array,
  dealerAdmin: PropTypes.bool,
  setLoading: PropTypes.func,
  isSearched: PropTypes.bool,
};
