import React, { useRef, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import NotificationAlert from "react-notification-alert";
import Cookies from 'js-cookie';
import axios from 'axios';

import CardsHeader from 'layouts/Headers/CardsHeader.js';
import * as constants from 'constants/index.js';

import InputCustom from 'views/pages/components/InputCustom';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "reactstrap";

export default function MessageEdit() {
  const { id } = useParams();
  const notificationAlert = useRef(null);
  const history = useHistory();

  const [token, setToken] = useState('');

  const [message, setMessage] = useState({
    title: '',
    message: '',
    sort: '',
    active_from: '',
    active_to: '',
  });

  const [errors, setErrors] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/settings/messages/${id}/edit`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data
        setMessage({
          ...data.data,
          active_from: data.data.active_from? constants.formatDate(data.data.active_from): '',
          active_to: data.data.active_to? constants.formatDate(data.data.active_to): '',
        });
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const updateMessage = () => {

    axios({
      method: 'PUT',
      url: constants.API_URL + '/settings/messages/' + id,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: {
        ...message,
        sort: message.sort? message.sort: 0,
      }
    })
      .then(response => {
        if (response.status == 200) {
          setErrors('');
          let data = response.data;
          console.log('response data :: ', data);
          notify('success', 'Success', data.message);
          setMessage({
            ...data.data,
            active_from: data.data.active_from? constants.formatDate(data.data.active_from): '',
            active_to: data.data.active_to? constants.formatDate(data.data.active_to): '',
          });
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        if (error.response.status == 422) {
          let errors = error.response.data.errors;
          setErrors(errors);
          return
        }
        notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred.');
      })
  }

  const goBack = () => {
    history.push('/admin/message');
  }

  return (
    <>
      <CardsHeader name="Admin" parentName="Messages Management" currentName="Edit" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--6 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Message Management</h1>
                  </Col>
                  <Col xs={4} className="text-right">
                    <Button className="w-100" color="success" type="button" onClick={() => goBack()}>Back to list</Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Card>
                  <CardHeader>
                    <h2 className="surtitle">Message details</h2>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={6} sm={12}>
                        <InputCustom
                          label={`Title`}
                          placeholder={`Title`}
                          value={message && message.title ? message.title : ''}
                          onChange={(e) => setMessage({
                            ...message,
                            title: e.target.value
                          })}
                          invalid={errors && errors.title ? true : false}
                          errorMessage={errors.title}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} sm={12}>
                        <InputCustom
                          type={`textarea`}
                          label={`Message`}
                          placeholder={`Message`}
                          rows={8}
                          value={message && message.message ? message.message : ''}
                          onChange={(e) => setMessage({
                            ...message,
                            message: e.target.value
                          })}
                          invalid={errors && errors.message ? true : false}
                          errorMessage={errors.message}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} sm={12}>
                        <InputCustom
                          type={`number`}
                          label={`Sort`}
                          rows={8}
                          value={message && message.sort ? message.sort : ''}
                          onChange={(e) => setMessage({
                            ...message,
                            sort: e.target.value
                          })}
                          invalid={errors && errors.sort ? true : false}
                          errorMessage={errors.sort}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3} sm={12}>
                        <InputCustom
                          type={`date`}
                          label={`Active From`}
                          value={message && message.active_from ? message.active_from : ''}
                          onChange={(e) => setMessage({
                            ...message,
                            active_from: e.target.value
                          })}
                          invalid={errors && errors.active_from ? true : false}
                          errorMessage={errors.active_from}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3} sm={12}>
                        <InputCustom
                          type={`date`}
                          label={`Active To(blank for no expiration)`}
                          value={message && message.active_to ? message.active_to : ''}
                          onChange={(e) => setMessage({
                            ...message,
                            active_to: e.target.value
                          })}
                          invalid={errors && errors.active_to ? true : false}
                          errorMessage={errors.active_to}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3} sm={12}>
                        <Button className="w-100" color="success" onClick={() => updateMessage()}>Save</Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}