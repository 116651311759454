import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import Select2 from "react-select2-wrapper";
import axios from 'axios';
import Cookies from "js-cookie";

import actions from 'actions/index.js';
import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import TableCustom from 'views/pages/components/TableCustom';
import {
  InputArraySelect
} from 'views/pages/components/InputSelect';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  FormGroup,
  Input,
} from "reactstrap";
import {ButtonContainer} from "@pages/reusable-components/styled-components";

export default function Task() {
  const { customerId } = useParams();
  const location = useLocation();
  const parentPath = {
    pathname: '/admin/customer/' + customerId + '/edit',
    state: {
      filter: location.state && location.state.filter? location.state.filter: ''
    }
  };
  const history = useHistory();
  const notificationAlert = useRef(null);
  const dispatch = useDispatch();

  const [token, setToken] = useState('');
  const [tasks, setTasks] = useState([]);
  const [dealerMembers, setDealerMembers] = useState([]);
  const [statuses, setStatuses] = useState([]);

  const [loading, setLoading] = useState(true);

  const [addModal, setAddModal] = useState(false);
  const [editing, setEditing] = useState(false);
  const [confirmShow, setConfirmShow] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState('');

  const [task, setTask] = useState('');
  const [editableTask, setEditTask] = useState({});
  const [errors, setErrors] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };
  const updateTask = () => {
    axios({
      method: 'PUT',
      url: constants.API_URL + '/customer/tasks/' + task.uuid,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: {
        ...task,
        due_date: setDate(task.due_date)
      },
    })
        .then(response => {
          setErrors('');
          if (response.status == 200) {
            notify('success', 'Success', response.data.message);
            dispatch(actions.getNotifications(token));
            setAddModal(false)
            setEditing(false)
            setTask('')
          }
        })
        .catch(error => {
          setErrors('');
          console.error('error :: ', error);
          if (error.response && error.response.status == 422) {
            let errors = error.response.data.errors;
            setErrors(errors);
          }
          notify('warning', 'Warning', error.response.statusText);
        })
  }
   const getCustomerTaskData = async (id)=>{
     setEditing(true)
     const response = await axios({
       method: 'GET',
       url: `${constants.API_URL}/customer/${customerId}/tasks/${id}/edit`,
       headers: {
         Authorization: `Bearer ${token}`,
       },
     })
     const data = response.data
     let item = data.data.item;
     item.task_assignee = item.task_assignee.map(item=>item.id)
     const date = setDate(item.due_date)
     setTask({
      ...item,
      due_date: item?.due_date? date  : '',
     });
   }
  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/customer/${customerId}/tasks`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        const data = response.data
        setTasks(data.data.tasks)

        let members = data.data.dealerMembers
        members.map(member => {
          member.text = member.name
          delete member.name
        });
        setDealerMembers(members)
        setStatuses(data.data.statuses)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const deleteTask = () => {
    axios({
      method: 'DELETE',
      url: constants.API_URL + '/customer/' + customerId + '/tasks/' + selectedTaskId,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(response => {
        setConfirmShow(false);
        if (response.status === 200) {
          let data = response.data;
          console.log('deleted data :: ', data);
          setTasks(data.data);
          notify('success', 'Success', response.data.message);
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        setConfirmShow(false);
        notify('warning', 'Warning', error.response.statusText);
      })
  }

  const editTask = (row) => {
    getCustomerTaskData(row.uuid)
    setAddModal(true)
    // history.push({
    //   pathname: '/admin/customer/' + customerId + '/tasks/' + row.uuid + '/edit',
    //   state: {
    //     filter: location.state && location.state.filter? location.state.filter: ''
    //   }
    // })
  }

  const addTask = () => {
    axios({
      method: 'POST',
      url: constants.API_URL + '/customer/' + customerId + '/tasks',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: { ...task, task_category: 'customer-manually' },
    })
      .then(response => {
        setAddModal(false);
        setErrors('');
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setTasks(data.data);
          dispatch(actions.getNotifications(token));
          notify('success', 'Success', response.data.message);
        }
      })
      .catch(error => {
        setErrors('');
        console.error('error :: ', error);
        if (error.response && error.response.status === 422) {
          let errors = error.response.data.errors;
          setErrors(errors);
          return;
        }
        setAddModal(false);
        notify('warning', 'Warning', error.response.statusText);
      })
  }
  console.log('task',task)
  const handleAssigneeChange = (e) => {
    console.log('e',e)
    let value = Array.from(e.target.selectedOptions, option => option.value);
    setTask({
      ...task,
      task_assignee: value
    })
  }

  const formatDate = (d) => {
    if (d) {
      let date = new Date(d);
      let option = {
        year: 'numeric', month: '2-digit', day: '2-digit',
        hour: 'numeric', minute: 'numeric', second: '2-digit',
        hour12: false,
      }
      let formatedDate = new Intl.DateTimeFormat('en-GB', option).format(date);
  
      return formatedDate;
    }
  }
  const setDate = (d) => {
    if (d) {
      let date = new Date(d);
      let ye = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(date);
      let mo = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(date);
      let da = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(date);

      return `${ye}-${mo}-${da}`;
    }
  }

  return (
    <>
      <CardsHeader name="Customers" parentName="Customer Management" currentName="Task List" parentPath={parentPath} />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Tasks</h1>
                  </Col>
                  <Col xs={4} className="text-right">
                    <ButtonContainer onClick={() => {
                      setTask('')
                      setEditing(false)
                      setAddModal(true)
                    }}>
                      <i className="fa fa-plus" aria-hidden="true"></i>
                      Add Task
                    </ButtonContainer>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <TableCustom 
                  loading={loading}
                  items={tasks}
                  keyField={`title`}
                  searchField={true}
                  columns={[
                    {
                      dataField: "title",
                      text: "Title",
                      sort: true
                    },
                    {
                      dataField: "status",
                      text: "Status",
                      sort: true
                    },
                    {
                      dataField: "created_at",
                      text: "Created at",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return formatDate(row.created_at)
                      }
                    },
                    {
                      dataField: "updated_at",
                      text: "Updated at",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return formatDate(row.updated_at)
                      }
                    },
                    {
                      dataField: "",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                          <>
                              <img className={'cursor-pointer'} onClick={e => editTask(row)}
                                   src={require(`assets/images/tableIcons/edit.svg`)} alt=""/>
                              <img className={'cursor-pointer'}
                                   onClick={e => {
                                     setConfirmShow(true);
                                     setSelectedTaskId(row.uuid);
                                   }}
                                   src={require(`assets/images/tableIcons/delete_outline.svg`)} alt=""/>
                          </>
                        )
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={addModal}
          toggle={() => setAddModal(false)}
          size="lg"
          className="modal-dialog-centered modal-secondary"
        >
          <div className="modal-body">
            <form className="new-event--form">
              <FormGroup className={'d-flex align-items-center'}>
                <label className="form-control-label w-50">Title</label>
                <Input
                  className="form-control-alternative new-event--title"
                  placeholder="Title"
                  type="text"
                  onChange={(e) => setTask({
                    ...task,
                    title: e.target.value,
                  })}
                  value={task?.title}
                  invalid={errors && errors.title? true: false}
                />
                <div className="invalid-feedback">
                  {errors.title}
                </div>
              </FormGroup>
              <FormGroup className={'d-flex align-items-center'}>
                <label className="form-control-label w-50">Description</label>
                <Input
                  className="form-control-alternative edit-event--title"
                  placeholder="Description"
                  type="textarea"
                  rows="8"
                  onChange={(e) => setTask({
                    ...task,
                    comments: e.target.value,
                  })}
                  value={task?.comments}
                  invalid={errors && errors.comments? true: false}
                />
                <div className="invalid-feedback">
                  {errors.comments}
                </div>
              </FormGroup>
              <Row>
                <Col sm={12}>
                  <FormGroup className={'d-flex align-items-center'}>
                    <label className="form-control-label w-50">Due</label>
                    <Input
                      className="form-control-alternative new-event--title"
                      type="date"
                      onChange={(e) => setTask({
                        ...task,
                        due_date: e.target.value,
                      })}
                      value={task && task.due_date? task.due_date: ''}
                      invalid={errors && errors.due_date? true: false}
                    />
                    <div className="invalid-feedback">
                      {errors.due_date}
                    </div>
                  </FormGroup>
                  <FormGroup className={'d-flex align-items-center'}>
                    <label className="form-control-label w-50">Status</label>
                    <Input
                        className="form-control-alternative new-event--title"
                        type="select"
                        onChange={(e) => setTask({
                          ...task,
                          status: e.target.value,
                        })}
                        value={task.status}
                        invalid={errors && errors.status? true: false}
                    >
                      <option>-</option>
                      { Object.keys(statuses).map((key, index) => {
                        return <option value={key} key={key}>{statuses[key]}</option>
                      }) }
                    </Input>
                    <div className="invalid-feedback">
                      {errors.status}
                    </div>
                  </FormGroup>
                  <FormGroup className={'d-flex align-items-center'}>
                    <label className="form-control-label w-50">Assign to</label>
                    <Select2
                        className="form-control"
                        multiple
                        data={dealerMembers}
                        value={task ? task?.task_assignee : null}
                        onSelect={(e) => handleAssigneeChange(e)}
                    />
                    {errors && errors.task_assignee && <div className="invalid-feedback" style={{display: 'block'}}>
                      {errors.task_assignee}
                    </div>}
                  </FormGroup>
                </Col>

              </Row>
            </form>
          </div>
          <div className="modal-footer justify-content-end">
            <Button
              reverse
              onClick={() => {
                if (editing) {
                  setTask('')
                  setEditing(false)
                }
                setAddModal(false)
              }}
            >
              Cancel
            </Button>
            {editing ?
                <ButtonContainer
                    reverse
                    onClick={() => updateTask()}
                >
                  Update
                </ButtonContainer>
                :
            <ButtonContainer
                reverse
                onClick={() => addTask()}
            >
              Save
            </ButtonContainer>
            }
          </div>
        </Modal>
        <Modal
          isOpen={confirmShow}
          toggle={() => setConfirmShow(false)}
          className="modal-dialog-centered modal-secondary"
        >
          <div className="modal-body justify-content-center">
            {`Are you sure you want to delete this task?`}
          </div>
          <div className="modal-footer justify-content-end">
            <ButtonContainer
              onClick={() => deleteTask()}
            >
              {`Delete`}
            </ButtonContainer>
            <ButtonContainer
              reverse
              onClick={() => setConfirmShow(false)}
            >
              Cancel
            </ButtonContainer>
          </div>
        </Modal>
      </Container>
    </>
  )
}