import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';

import * as constants from 'constants/index.js';

import {
  Container,
  Row,
  Col,
} from 'reactstrap';

export default function MandateInfo() {
  const { dealerSlug, customerId } = useParams();
  const notificationAlert = useRef(null);

  const [info, setInfo] = useState('');
  const [customer, setCustomer] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    let grs_token = Cookies.get('grs_token')

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        let response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/payments/${dealerSlug}/customers/${customerId}`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        let data = response.data
        setInfo(data.data.info)
        setCustomer(data.data.customer)
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    };
  }, [])

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <h1>{customer.full_name}</h1>
            <Row className="py-3 align-items-center">
              <Col sm={3}>
                <h3 className="mb-0">{`Mandate`}</h3>
              </Col>
              <Col sm={9}>
                <h3 className="heading-title text-warning mb-0">{info.mandateId}</h3>
              </Col>
            </Row>
            <Row className="py-3 align-items-center">
              <Col sm={3}>
                <h3 className="mb-0">{`Created At`}</h3>
              </Col>
              <Col sm={9}>
                <h3 className="heading-title text-warning mb-0">{info.createdAt}</h3>
              </Col>
            </Row>
            <Row className="py-3 align-items-center">
              <Col sm={3}>
                <h3 className="mb-0">{`Current Gateway Status`}</h3>
              </Col>
              <Col sm={9}>
                <h3 className="heading-title text-warning mb-0">{info.status}</h3>
              </Col>
            </Row>
            <Row className="py-3 align-items-center">
              <Col sm={3}>
                <h3 className="mb-0">{`Next possible charge date`}</h3>
              </Col>
              <Col sm={9}>
                <h3 className="heading-title text-warning mb-0">{info.nextPossibleChargeDate}</h3>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}