import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import * as constants from 'constants/index.js';
import Cookies from "js-cookie";
import NotificationAlert from "react-notification-alert";

import CardsHeader from 'layouts/Headers/CardsHeader.js';
import InputCustom from 'views/pages/components/InputCustom';

import {
  Button,
  Container,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col
} from "reactstrap";
import {ButtonContainer, Line} from "@pages/reusable-components/styled-components";

export default function RoleEdit() {
  const {id} = useParams();
  const location = useLocation();
  const notificationAlert = useRef(null);
  const history = useHistory();
  
  const [token, setToken] = useState('');
  const [permissions, setPermissions] = useState([]);
  const [hasPermissions, setHasPermissions] = useState([]);
  const [divide, setDivide] = useState('details');

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const [errors, setErrors] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    let role = location.state.role;
    setName(role.name);
    setDescription(role.description);
    
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/role/${id}/edit`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data
        setPermissions(data.data.permissions)
        setHasPermissions(data.data.hasPermissions)
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const updateRole = () => {
    let data = {
      name: name,
      description: description
    };
    axios({
      method: 'PUT',
      url: constants.API_URL + '/role/' + id,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: data
    })
      .then(response => {
        if (response.status == 200) {
          notify('success', 'Success', 'Updated role successfully.');
        }
      })
      .catch(error => {
        setErrors('');
        console.error('error :: ', error);
        if (error.response && error.response.status == 422) {
          setErrors(error.response.data.errors);
          return
        }
        notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred.');
      })
  }

  const handleToggle = (value) => {
    const currentIndex = hasPermissions.indexOf(value);
    const newHasPermissions = [...hasPermissions];

    if (currentIndex === -1) {
      newHasPermissions.push(value);
    } else {
      newHasPermissions.splice(currentIndex, 1);
    }
    setHasPermissions(newHasPermissions);
  }

  const updatePermission = () => {
    let data = {
      permissions: hasPermissions
    }

    axios({
      method: 'POST',
      url: constants.API_URL + '/role/' + id + '/permissions',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: data
    })
      .then(response => {
        if (response.status == 200) {
          notify('success', 'Success', 'Updated role successfully.');
        }
      })
      .catch(error => {
        console.error('error :: ', error);
      })
  }

  const goList = () => {
    history.push({
      pathname: '/admin/role'
    })
  }

  return (
    <>
      <CardsHeader name="Admin" parentName="Role Management" currentName="Edit Role" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--6 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h2 className="mb-0">Role Management</h2>
                  </Col>

                </Row>
              </CardHeader>
              <CardBody>
                <Row  className="d-flex mb-3">
                  <div className={'navbar-nav cursor-pointer flex-column mr-2'}>
                       <span
                           style={{color: divide === 'details' && '#ec6409', margin: 5}}
                           onClick={() => setDivide('details')}>
                         Role Detail
                       </span>
                       {divide === 'details' &&
                           <Line color={'#ec6409'}/>
                       }
                  </div>
                  <div className={'navbar-nav cursor-pointer flex-column'}>
                      <span
                          style={{color: divide === 'permissions' && '#ec6409', margin: 5}}
                          onClick={() => setDivide('permissions')}>
                                Role Detail
                              </span>
                      {divide === 'permissions' &&
                      <Line color={'#ec6409'}/>
                           }
                  </div>
                </Row>
                <Row>
                  <Col md={9}>
                    {divide == 'details' && <Card>
                      <CardHeader>
                        <h2 className="surtitle">Role Information</h2>
                      </CardHeader>
                      <CardBody>
                        <InputCustom 
                          newLabel={`Name`}
                          placeholder={`Name`}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          invalid={errors && errors.name? true: false}
                          errorMessage={errors.name}
                        />
                        <InputCustom 
                          type={`textarea`}
                          newLabel={`Description`}
                          placeholder={`Description`}
                          rows={5}
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          invalid={errors && errors.description? true: false}
                          errorMessage={errors.description}
                        />
                        <div className="d-flex flex-row justify-content-end">
                            <ButtonContainer  onClick={() => goList()}>Back to list</ButtonContainer>
                            <ButtonContainer reverse onClick={() => updateRole()}>Save</ButtonContainer>
                        </div>
                      </CardBody>
                    </Card>}
                    {divide == 'permissions' && <Card>
                      <CardHeader>
                        <h2 className="surtitle">Permissions</h2>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          {permissions.length > 0 && permissions.map((permission, index) => {
                            return (
                              <div className="custom-control custom-checkbox mb-3 col-md-3 col-sm-6" key={index}>
                                <input
                                  className="custom-control-input"
                                  id="customCheck2"
                                  type="checkbox"
                                  id={`permission${permission.id}`}
                                  checked={hasPermissions.includes(permission.id)}
                                  onChange={() => handleToggle(permission.id)}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={`permission${permission.id}`}
                                >
                                  {permission.display_name}
                                </label>
                              </div>
                            )
                          })}
                        </Row>
                        <div className="d-flex flex-row justify-content-center">
                          <ButtonContainer  onClick={() => goList()}>Back to list</ButtonContainer>
                          <ButtonContainer reverse onClick={() => updatePermission()}>Save</ButtonContainer>
                        </div>
                      </CardBody>
                    </Card>}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}