import { put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from './actions';
import axios from '../../config/axios';
import * as constants from '../../constants';

function* createTask(action) {
  try {
    const response = yield axios.post(`${constants.API_URL}/task`, {
      ...action.data,
    });
    if (response?.status === 200) {
      const { task } = response;
      yield put({ type: actionTypes.CREATE_TASK_SUCCESS, task });
    }
  } catch (error) {
    console.log(error, 'createTask');
    let payload = error.response.data;
    yield put({ type: actionTypes.CREATE_TASK_ERROR, payload });
  }
}

function* updateTask(action) {
  try {
    const response = yield axios.put(
      `${constants.API_URL}/task/${action.data.id}`,
      {
        ...action.data,
      }
    );
    if (response?.status === 200) {
      const { tasks } = response.data;
      yield put({ type: actionTypes.UPDATE_TASK_SUCCESS, tasks });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.UPDATE_TASK_ERROR, payload });
  }
}

function* getTaskCategories() {
  try {
    const response = yield axios.get(`${constants.API_URL}/task-categories`);

    if (response?.status === 200) {
      const {
        data: { data },
      } = response;
      const { categories } = data;
      yield put({ type: actionTypes.GET_TASK_CATEGORIES_SUCCESS, categories });
    }
  } catch (error) {
    yield put({ type: actionTypes.GET_TASK_CATEGORIES_ERROR, error });
  }
}

function* getTasks() {
  try {
    const response = yield axios.get(`${constants.API_URL}/tasks`);

    if (response?.status === 200) {
      const {
        data: { data },
      } = response;
      const {
        taskCategories,
        resources,
        tasks,
        dealerMembers,
        defaultStatuses,
        dealerAdmin,
      } = data;

      yield put({
        type: actionTypes.GET_TASKS_SUCCESS,
        categories: taskCategories,
        resources,
        tasks,
        dealerMembers,
        defaultStatuses,
        dealerAdmin,
      });
    }
  } catch (error) {
    yield put({ type: actionTypes.GET_TASKS_ERROR, error });
  }
}

function* getTaskResources() {
  try {
    const response = yield axios.get(`${constants.API_URL}/task-resources`);

    if (response?.status === 200) {
      const {
        data: { data },
      } = response;
      const { resources } = data;
      yield put({ type: actionTypes.GET_TASK_RESOURCES_SUCCESS, resources });
    }
  } catch (error) {
    console.log(error, 'getTaskResources error');
    yield put({ type: actionTypes.GET_TASK_RESOURCES_ERROR, error });
  }
}

function* getDeals(action) {
  try {
    // TODO maybe this saga should be moved
    // to another saga

    const { searchValue } = action;
    const response = yield axios.get(
      `${constants.API_URL}/deal?deal_status=order_signed&created_by=&searchValue=${searchValue}&search=${searchValue}&page=1`
    );

    if (response?.status === 200) {
      const {
        data: { data },
      } = response;
      const { items } = data;
      yield put({ type: actionTypes.GET_DEALS_SUCCESS, deals: items });
    }
  } catch (error) {
    console.log(error, 'getDeals');
    yield put({ type: actionTypes.GET_DEALS_ERROR, error });
  }
}

function* getCustomers(action) {
  try {
    // TODO maybe this saga should be moved
    // to another saga

    const { searchValue } = action;
    const response = yield axios.get(
      `${constants.API_URL}/customer?searchValue=${searchValue}&search=${searchValue}&page=1`
    );

    if (response?.status === 200) {
      const {
        data: {
          data: { customers },
        },
      } = response;
      // const { items } = data;
      yield put({ type: actionTypes.GET_CUSTOMERS_SUCCESS, customers });
    }
  } catch (error) {
    console.log(error, 'getCustomers');
    yield put({ type: actionTypes.GET_DEALS_ERROR, error });
  }
}

function* getVehicles(action) {
  try {
    // TODO maybe this saga should be moved
    // to another saga

    const { searchValue } = action;
    const response = yield axios.get(
      `${constants.API_URL}/vehicle?page=1&searchValue=${searchValue}&search=${searchValue}`
    );

    if (response?.status === 200) {
      const {
        data: { data },
      } = response;
      const { items } = data;
      yield put({ type: actionTypes.GET_VEHICLES_SUCCESS, vehicles: items });
    }
  } catch (error) {
    console.log(error, 'getVehicles');
    yield put({ type: actionTypes.GET_VEHICLES_ERROR, error });
  }
}

function* createComment(action) {
  try {
    const response = yield axios.post(
      `${constants.API_URL}/tasks/comment/${action.id}`,
      {
        comment: action.comment,
      }
    );
    if (response?.status === 200) {
      const { task } = response.data;
      yield put({ type: actionTypes.CREATE_COMMENT_SUCCESS, task });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.CREATE_COMMENT_ERROR, payload });
  }
}

function* deleteComment(action) {
  try {
    const response = yield axios.delete(
      `${constants.API_URL}/tasks/comment/${action.commentId}`
    );
    if (response?.status === 200) {
      yield put({
        type: actionTypes.DELETE_COMMENT_SUCCESS,
        commentId: action.commentId,
        taskId: action.taskId,
      });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.DELETE_COMMENT_ERROR, payload });
  }
}

function* updateComment(action) {
  try {
    const response = yield axios.put(
      `${constants.API_URL}/tasks/comment/${action.id}`,
      {
        comment: action.comment,
        taskId: action.taskId,
      }
    );
    if (response?.status === 200) {
      const { comment } = response.data;
      yield put({
        type: actionTypes.UPDATE_COMMENT_SUCCESS,
        comment,
        taskId: action.taskId,
      });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.UPDATE_COMMENT_ERROR, payload });
  }
}

function* searchTask(action) {
  try {
    const response = yield axios.get(
      `${constants.API_URL}/tasks/filter?searchText=${action.searchValue}&assignee=${action.assignee}`
    );
    if (response?.status === 200) {
      const { tasks } = response.data;
      yield put({
        type: actionTypes.SEARCH_TASK_SUCCESS,
        tasks,
      });
    }
  } catch (error) {
    console.log('error', error);
    let payload = error.response.data;
    yield put({ type: actionTypes.SEARCH_TASK_ERROR, payload });
  }
}

export default function* watcherSaga() {
  yield takeLatest(actionTypes.CREATE_TASK_REQUEST, createTask);
  yield takeLatest(actionTypes.GET_TASK_CATEGORIES_REQUEST, getTaskCategories);
  yield takeLatest(actionTypes.GET_TASK_RESOURCES_REQUEST, getTaskResources);
  yield takeLatest(actionTypes.GET_DEALS_REQUEST, getDeals);
  yield takeLatest(actionTypes.GET_CUSTOMERS_REQUEST, getCustomers);
  yield takeLatest(actionTypes.GET_VEHICLES_REQUEST, getVehicles);
  yield takeLatest(actionTypes.GET_TASKS_REQUEST, getTasks);
  yield takeLatest(actionTypes.UPDATE_TASK_REQUEST, updateTask);
  yield takeLatest(actionTypes.CREATE_COMMENT_REQUEST, createComment);
  yield takeLatest(actionTypes.DELETE_COMMENT_REQUEST, deleteComment);
  yield takeLatest(actionTypes.UPDATE_COMMENT_REQUEST, updateComment);
  yield takeLatest(actionTypes.SEARCH_TASK_REQUEST, searchTask);
}
