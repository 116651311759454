import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'actions/index.js';
import NotificationAlert from "react-notification-alert";
import InputCustom from 'views/pages/components/InputCustom';
import { InputArraySelect } from 'views/pages/components/InputSelect';
import { updateKashflowConfiguration, getKashflowConfiguration, updateKashflowSalesTypeMapping } from 'api/dealer_modules.api';
import TableCustom from 'views/pages/components/TableCustom'
import {
  Button,
  Container,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
} from "reactstrap";

import CardsHeader from 'layouts/Headers/CardsHeader.js';

function KashflowModule() {
  const notificationAlert = useRef(null);
  const profileData = useSelector((state) => state.getState).profileData;
  const dispatch = useDispatch();

  const [isActive, setIsActive] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [products, setProducts] = useState([]);
  const [localDefinedPayments, setLocalDefinedPayments] = useState([]);
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [detailErrors, setDetailErrors] = useState('');
  const [mapping, setMapping] = useState({});

  const data = profileData ? profileData : { data: { name: '', email: '' } };

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  const pullAsyncData = () => {
    getKashflowConfiguration().then(response => {
      let data = response.data;
      console.log('response data :: ', data);
      setUsername(data.modules?.username)
      setIsActive(data.modules?.is_active)
      setProducts(data.modules?.products ?? []);

      if (data.modules?.mappedProducts) {
          let object = {};
          console.log(data.modules.mappedProducts)
          Object.keys(data.modules.mappedProducts).map(key => {
            object[key] = data.modules.mappedProducts[key].kashflow_value;
          })

          setMapping(object)
      }

      let paymentTypes = data.modules?.localDefinedPayments ?? {};
      setLocalDefinedPayments(Object.keys(paymentTypes).map(key => {
        return {
          key,
          value: paymentTypes[key]
        }
      }));

      setTimeout(() => {
        setLoading(false)
      }, 300);
    })
      .catch(error => {
      })
  }

  useEffect(() => {
    pullAsyncData()
  }, [])

  useEffect(() => {
    setUsername(username);
  }, [username])

  const saveUserInformation = async () => {
    updateKashflowConfiguration(username, password).then(response => {
      if (response.success == true) {
        let data = response.data;
        console.log('response data :: ', data);
        notify('success', 'Success', response.message);
      }
    })
      .catch(error => {
        notify('warning', 'Warning', error.response.data.message ?? "Account saving error" );
      })

  }

  const updateProductConfigurations = async () => {
    updateKashflowSalesTypeMapping(mapping).then(response => {
      if (response.success == true) {
        let data = response.data;
        console.log('response data :: ', data);
        notify('success', 'Success', response.message);
      }
    })
      .catch(error => {
        console.log(error.response)
        notify('warning', 'Warning', error.response.data.message ?? "Sales code configuration saving error" );
      })
  }

  return (
    <>
      <CardsHeader name="Admin" parentName="Profile" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--6 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <h1 className="title mb-0">Kashflow</h1>
              </CardHeader>
              <CardBody>
                {!isActive ?
                  <Form>
                    {username ?
                      <h2 className="surtitle mb-4">Connect using your kashflow credentials</h2> :
                      <h2 className="surtitle mb-4">Check your credentials! We couldn't establish a connection with Kashflow</h2>
                    }
                    <Row>
                      <Col md="12">
                        <InputCustom
                          label={`Username/Email`}
                          // type={`email`}
                          value={username}
                          placeholder={`Username/Email`}
                          onChange={(e) => setUsername(e.target.value)}
                          invalid={detailErrors && detailErrors.name ? true : false}
                          errorMessage={detailErrors.name}
                        />
                      </Col>
                      <Col md="12">
                        <InputCustom
                          type={`password`}
                          label={`Password`}
                          placeholder={`Password`}
                          onChange={(e) => setPassword(e.target.value)}
                          invalid={detailErrors && detailErrors.email ? true : false}
                          errorMessage={detailErrors.email}
                        />
                      </Col>
                    </Row>
                    <Button block color="success" size="lg" type="button" onClick={() => saveUserInformation()}>
                      Save
                    </Button>
                  </Form> :
                  <>
                    <h2 className="surtitle mb-4">Check out your configuration mapping</h2>
                    {
                      localDefinedPayments && localDefinedPayments.length > 0 ?
                        <TableCustom
                          loading={loading}
                          items={localDefinedPayments}
                          columns={[
                            {
                              dataField: "value",
                              text: "Name",
                              sort: true,
                              formatter: (rowContent, row) => {
                                return (
                                  <Row className="justify-content-center align-items-stretch">
                                    <Col md={12}>
                                      {row.value}
                                    </Col>
                                  </Row>
                                );
                              },
                            },
                            {
                              dataField: "is_notification_enabled",
                              text: "Map to Kashflow sales code",
                              sort: false,
                              formatter: (rowContent, row) => {
                                return (
                                  <>
                                    <InputArraySelect
                                      options={products}
                                      value={mapping && mapping[row.key] ? mapping[row.key] : null}
                                      valueKey={"ProductID"}
                                      labelKey={"ProductName"}
                                      onChange={(e) => setMapping({
                                        ...mapping,
                                        [row.key]: e.target.value
                                      })}
                                      defaultOption={true}
                                    />
                                  </>
                                );
                              },
                            },
                          ]}
                        /> : null
                    }
                    <Button className="w-100" color="success" onClick={() => updateProductConfigurations()}>Save</Button>
                  </>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default KashflowModule;