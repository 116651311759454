import React, { useEffect, useState, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import NotificationAlert from 'react-notification-alert'
import moment from 'moment';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button
} from 'reactstrap';

import * as constants from 'constants/index.js';
import Chart from 'chart.js';
import { Line, Pie, Bar } from "react-chartjs-2";
import TableCustom from 'views/pages/components/TableCustom';

import { fetchMonthlyTargets, fetchMonthSummary, fetchVehiclesReport } from '@redux/Vehicle/actions';
import {
  InputArraySelect,
} from 'views/pages/components/InputSelect'

import CardsHeader from 'layouts/Headers/CardsHeader.js';
import useNotify from 'hooks/notify';
import {ButtonContainer} from "@pages/reusable-components/styled-components";

const months = [
  { id: 1, name: "January" },
  { id: 2, name: "February" },
  { id: 3, name: "March" },
  { id: 4, name: "April" },
  { id: 5, name: "May" },
  { id: 6, name: "June" },
  { id: 7, name: "July" },
  { id: 8, name: "August" },
  { id: 9, name: "September" },
  { id: 10, name: "October" },
  { id: 11, name: "November" },
  { id: 12, name: "December" },
]

export default function ViewStatistics() {
  const { ref, notify } = useNotify();
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');

  const [salesReportData, setSalesReportData] = useState(null);
  const [salesReportOptions, setSalesReportOptions] = useState(null);
  const [rentalReportData, setRentalReportData] = useState(null);
  const [rentalReportOptions, setRentalReportOptions] = useState(null);

  const { weekly_stats, summary, rentalReport, saleReport } = useSelector((state) => state.vehicle);

  const goList = () => {
    history.push({
      pathname: '/admin/live-vehicle/master'
    })
  }

  const yearsOptions = useMemo(() => {
    let options = [];
    let year = moment().toDate().getFullYear();
    if (year) {
      for (let i = year - 2; i <= year; i++) {
        options.push({
          id: i,
          name: i
        })
      }
    }

    return options;
  }, []);

  const parseDataSet = (data, title) => {
    let options = {
      labels: [],
      datasets: [
        {
          label: title,
          data: [],
          backgroundColor: [
          ],
          borderColor: [
          ],
          borderWidth: 1,
        },
      ],
    };

    data.forEach(item => {
      options.labels.push(item.vehicle_name);
      options.datasets[0].data.push(item.count_model);
      options.datasets[0].backgroundColor.push(constants.getColorsForSources(`${item.vehicle_name}_background`));
      options.datasets[0].borderColor.push(constants.getColorsForSources(`${item.vehicle_name}_border`));
    });

    return options
  }

  useEffect(() => {
    if (rentalReport && saleReport) {
      // setRentalReportOptions(parseOptions("Rental vehicle report summary"));
      setRentalReportData(parseDataSet(rentalReport, "Rental vehicle report summary"));
      // setSalesReportOptions(parseOptions("Sales vehicle report summary"));
      setSalesReportData(parseDataSet(saleReport, "Sales vehicle report summary"));
    } else {
      setSalesReportData(null);
      setRentalReportData(null);
    }
  }, [rentalReport, saleReport]);

  useEffect(() => {
    let month = moment().toDate().getMonth() + 1;
    setSelectedMonth(month);
    let year = moment().toDate().getFullYear();
    setSelectedYear(year);
    dispatch(fetchMonthSummary(month, year));
    dispatch(fetchVehiclesReport(month, year));
  }, []);

  return (<>
    <CardsHeader name="Fleets" parentName="Live vehicles" currentName="Statistics" />
    <div className="rna-wrapper">
      <NotificationAlert ref={ref} />
    </div>
    <Container className="mt--5 admin-main-body" id="liveVehiclesSection" fluid >
      <Row>
        <Col xl="12">
          <Card>
            <CardHeader>
              <Row>
                <Col xs={12}>
                  <h1 className="mb-0">View detailed statistics {selectedMonth ? `${months.find((month) => month.id === selectedMonth)?.name}` : ''} {selectedYear ?? ''}</h1>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Container>
                <Row>
                  <Col xs={12} sm={6}>
                    <InputArraySelect
                      newLabel={`Target Month`}
                      options={months}
                      value={selectedMonth}
                      onChange={(e) => setSelectedMonth(e.target.value)}
                      valueKey={`id`}
                      labelKey={`name`}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <InputArraySelect
                        newLabel={`Target Year`}
                      options={yearsOptions}
                      value={selectedYear}
                      onChange={(e) => setSelectedYear(e.target.value)}
                      valueKey={`id`}
                      labelKey={`name`}
                    />
                  </Col>
                </Row>
                <Row className="mb-5">
                  <Col xs={12}>
                    <ButtonContainer reverse onClick={() => {
                      dispatch(fetchMonthSummary(selectedMonth, selectedYear));
                      dispatch(fetchVehiclesReport(selectedMonth, selectedYear));
                    }}>Fetch</ButtonContainer>
                  </Col>
                </Row>
              </Container>
            </CardBody>
            <CardBody>
              <Container>
                {salesReportData && saleReport && <Row>
                  <Col xs={12}>
                    <h2>Sales Report</h2>
                    <Pie data={salesReportData} />
                  </Col>
                  <Col xs={12}>
                    <TableCustom
                      loading={false}
                      items={saleReport}
                      columns={[
                        {
                          dataField: "vehicle_name",
                          text: "Vehicles",
                          sort: true
                        },
                        {
                          dataField: "count_model",
                          text: "Sold",
                          sort: true
                        }
                      ]}
                    />
                  </Col>
                </Row>
                }
                {rentalReportData && rentalReport && <Row>
                  <Col xs={12}>
                    <h2 className='text-center'>Rental Report</h2>
                    <Pie data={rentalReportData} />
                  </Col>
                  <Col xs={12}>
                    <TableCustom
                      loading={false}
                      items={rentalReport}
                      columns={[
                        {
                          dataField: "vehicle_name",
                          text: "Vehicles",
                          sort: true
                        },
                        {
                          dataField: "count_model",
                          text: "Rented",
                          sort: true
                        }
                      ]}
                    />
                  </Col>
                </Row>
                }
              </Container>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  </>
  )
}