import axios from 'axios';
import { API_URL } from 'constants/index.js';
import Cookies from 'js-cookie';

axios.defaults.baseURL = API_URL;

axios.interceptors.request.use((request) => {
  let token = Cookies.get('grs_token');
  request.headers['Authorization'] = `Bearer ${token}`;
  return request;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    try {
      if (error?.response?.status === 401) {
        Cookies.remove('grs_token');
        window.location.reload();
      }
      return Promise.reject(error);
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export default axios;
