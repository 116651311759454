const viewAny = (permissions) => {
  return permissions.includes('read-acl')
}

const index = (permissions) => {
  return permissions.includes('read-acl')
}

const create = (permissions) => {
  return hasPermission(permissions, 'create-acl')
}

const view = (permissions, role) => {
  return hasPermission(permissions, 'read-acl')
}

const update = (permissions, role, superadmin_role, user_roles, auth_user_team) => {
  if (role.name == superadmin_role && !user_roles.includes(superadmin_role)) {
    return false
  }
  return hasPermission(permissions, 'update-acl') && (!auth_user_team || (role.dealer && (auth_user_team.id == role.dealer.id)))
}

const hasPermission = (permissions, permission) => {
  return permissions.includes(permission)
}

export {
  viewAny,
  index,
  create,
  view,
  update,
  hasPermission,
};