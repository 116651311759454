import React, { useEffect, useState, useRef } from 'react';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';
import { Editor } from '@tinymce/tinymce-react';

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
  Input
} from 'reactstrap';

export default function DealerProfile() {
  const notificationAlert = useRef(null);

  const [token, setToken] = useState('');
  const [dealerLanding, setDealerLanding] = useState('');

  const [frontendErrors, setFrontendErrors] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    let unmounted = false;

    if (Cookies.get('grs_token')) {
      let grs_token = Cookies.get('grs_token')
      setToken(grs_token)

      axios({
        method: 'GET',
        url: constants.API_URL + '/dealer/profile-frontend',
        headers: {
          Authorization: 'Bearer ' + grs_token
        },
      })
        .then(response => {
          console.log('res :: ', response.data)
          let data = response.data
          setDealerLanding(data.data.dealerLanding)
        })
        .catch(error => {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
        })
    }

    return () => { unmounted = true };
  }, [])

  const updateFront = () => {
    axios({
      method: 'PUT',
      url: constants.API_URL + '/dealer/landing',
      headers: {
        Authorization: 'Bearer ' + token,
      },
      data: dealerLanding
    })
      .then(response => {
        if (response.status == 200) {
          let data = response.data;
          console.log('response financial data :: ', data);
          setDealerLanding(data.data);
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        if (error.response.status == 422) {
          let errors = error.response.data.errors;
          setFrontendErrors(errors);
          return
        }
        notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred.');
      })
  }

  return (
    <>
      <CardsHeader name="Dealer Admin" parentName="" currentName="" breadcrumb={false} />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <div>
                    <h1 className="title">Edit Profile</h1>
                    <p className="text-small mb-0">You can create and edit your company's profile. This is informations are also going to be used in the contracts.</p>
                  </div>
                </div>
              </CardHeader>
              <CardHeader>
                <h1 className="title">Frontend Layout Management</h1>
              </CardHeader>
              <CardBody>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    About us description
                          </label>
                  <Editor
                    value={dealerLanding.description ? dealerLanding.description : ''}
                    apiKey={constants.TINYMCE_KEY}
                    init={{
                      height: 500,
                      menubar: true,
                      plugins: ['code', 'preview', 'pagebreak', 'image'],
                      toolbar:
                        'undo redo | formatselect | bold italic | \
                                alignleft aligncenter alignright alignjustify | \
                                outdent indent'
                    }}
                    onEditorChange={(content, editor) => setDealerLanding({
                      ...dealerLanding,
                      description: content
                    })}
                  />
                  <div className="invalid-feedback" style={{display: frontendErrors && frontendErrors.description? 'block': 'none'}}>
                    {frontendErrors.description}
                  </div>
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    <h2>Testimonials</h2>
                    <span>Please write every testimonial link comma separated</span>
                  </label>
                  <Input
                    id="example-text-input"
                    type="textarea"
                    rows="4"
                    value={dealerLanding.testimonials ? dealerLanding.testimonials : ''}
                    onChange={(e) => setDealerLanding({
                      ...dealerLanding,
                      testimonials: e.target.value
                    })}
                    invalid={frontendErrors && frontendErrors.testimonials? true: false}
                  />
                  <div className="invalid-feedback">
                    {frontendErrors.testimonials}
                  </div>
                </FormGroup>
                <div className="d-flex flex-row justify-content-center">
                  <Button className="w-25" color="success" onClick={() => updateFront()}>Save</Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}