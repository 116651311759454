import * as constants from 'constants/index.js';
import Dashboard from 'views/pages/Dashboard.js';
import PaulAuDashboard from 'views/paulAu/Dashboard.js';
import Icons from '../src/assets/images/HeaderMenuIcons/index'

/**
 * Admin
 */
import Profile from 'views/pages/admin/Profile.js';
import RentalCalculator from 'views/pages/admin/RentalCalculator.js';
import Role from 'views/pages/admin/role/Index.js';
import RoleEdit from 'views/pages/admin/role/Edit.js';
import RoleShow from 'views/pages/admin/role/Show.js';
import Dealer from 'views/pages/admin/dealer/Index.js';
import DealerEdit from 'views/pages/admin/dealer/Edit.js';
import Supplier from 'views/pages/admin/supplier/Index';
import Permission from 'views/pages/admin/permission/Index';
import PermissionEdit from 'views/pages/admin/permission/Edit';
import User from 'views/pages/admin/user/Index';
import UserEdit from 'views/pages/admin/user/Edit';
import Message from 'views/pages/admin/message/Index';
import MessageEdit from 'views/pages/admin/message/Edit';
import DealerProfile from 'views/pages/admin/dealerProfile/Index';
import DealerModules from 'views/pages/admin/dealerProfile/Modules';
import KashflowModule from 'views/pages/admin/dealerProfile/Modules/KashflowModule';
import Integration from 'views/pages/admin/3rdPartyIntegration/Index';
import Holiday from 'views/pages/admin/holiday/Index';
import UserHoliday from 'views/pages/admin/holiday/IndexUser';

/**
 * Customer
 */
import Customer from 'views/pages/customers/customer/Index';
import CustomerCreate from 'views/pages/customers/customer/Create';
import CustomerEdit from 'views/pages/customers/customer/Edit';
import CustomerTask from 'views/pages/customers/customer/task/Index';
import CustomerTaskEdit from 'views/pages/customers/customer/task/Edit';
import CustomerNote from 'views/pages/customers/customer/note/Index';
import CustomerNoteEdit from 'views/pages/customers/customer/note/Edit';
import Defaulters from 'views/pages/customers/defaulters/Index';
import Payments from 'views/pages/customers/payments/Index';
import Notifications from 'views/pages/customers/notifications/Index';

/**
 * Deal
 */
import Deal from 'views/pages/deals/Index';
import DealCreate from 'views/pages/deals/Create';
import DealEdit from 'views/pages/deals/Edit';
import DealTaskEdit from 'views/pages/deals/task/Edit';
import DealPayment from 'views/pages/deals/payment/Index';
import PaymentDetails from 'views/pages/deals/payment/PaymentDetails';
import DealNotifications from 'views/pages/deals/Notifications';

/**
 *
 */
import PaymentsList from 'views/pages/payments/Index';
/**
 * Fleet
 */
import Fleet from 'views/pages/fleet/Dashboard';
import VehicleInStock from 'views/pages/fleet/VehicleInStock';
import WorkerDetails from 'views/pages/fleet/pages/WorkerDetails';
import JobCreate from 'views/pages/fleet/JobCreate';
import VehicleInspect from 'views/pages/fleet/VehicleInspect';
import TypeInspect from 'views/pages/fleet/TypeInspect';
import PartsByDate from 'views/pages/fleet/PartsByDate';
import Workers from 'views/pages/fleet/Workers';

/**
 * Lead
 */
import Lead from 'views/pages/lead/Index';
import LeadEdit from 'views/pages/lead/Edit';
import LeadCreate from 'views/pages/lead/Create';
import Statistics from 'views/pages/lead/Statistics';
import UsersStatistics from 'views/pages/lead/UsersStatistics';
import LeadNotifications from 'views/pages/lead/Notifications';

/**
 * Tracking
 */
import AccountIntegration from 'views/pages/tracking/AccountIntegration';
import AccountIntegrationCallBack from 'views/pages/tracking/AccountIntegrationCallBack';
import Tracking from 'views/pages/tracking/Index';
import TrackingEdit from 'views/pages/tracking/Edit';
import AlertsAndNotification from 'views/pages/tracking/AlertsAndNotification';
import ActivateTracker from 'views/pages/tracking/ActivateTracker';
import GeneralOverview from 'views/pages/tracking/GeneralOverview';
import AssigningFunder from 'views/pages/tracking/AssigningFunder';
import TrackingNotifications from 'views/pages/tracking/Notifications';

/**
 * Vehicle
 */
import Vehicle from 'views/pages/vehicles/Index';
import VehicleEdit from 'views/pages/vehicles/Edit';
import VehicleCreate from 'views/pages/vehicles/Create';
import VehicleDuplicate from 'views/pages/vehicles/Duplicate';
import VehicleCost from 'views/pages/vehicles/VehicleCost';
import VehicleFinancialDetails from 'views/pages/vehicles/FinancialDetails';
import VehicleFinancialHistory from 'views/pages/vehicles/FinancialHistory';
import VehicleMake from 'views/pages/vehicles/make/Index';
import VehicleModel from 'views/pages/vehicles/model/Index';
import VehicleNotifications from 'views/pages/vehicles/Notifications';

/**
 * Live Vehicle
 */
import LiveVehicleCreate from 'views/pages/liveVehicles/LiveVehicleCreate';
import LiveVehicleEdit from 'views/pages/liveVehicles/LiveVehicleEdit';
import LiveVehicleIndex from 'views/pages/liveVehicles/LiveVehicleIndex';
import DailyStatistics from 'views/pages/liveVehicles/Statistics';
import StatTargets from 'views/pages/liveVehicles/Targets';
import ViewStatistics from 'views/pages/liveVehicles/ViewStatistics';
import VehicleReport from 'views/pages/liveVehicles/VehicleReport';
/**
 * Reports
 */
import BusinessActivity from 'views/pages/reports/BusinessActivity';
import FleetReport from 'views/pages/reports/FleetReport';
import VehiclesCounty from 'views/pages/reports/VehiclesCounty';
import BusinessTypeCounty from 'views/pages/reports/BusinessTypeCounty';

/**
 * Settings
 */
import SettingsDocuments from 'views/pages/settings/documents/Index';
import SettingsDocumentsForm from 'views/pages/settings/documents/Form';
import SettingsBusinessActivities from 'views/pages/settings/businessActivities/Index';
import SettingsCompanyTypes from 'views/pages/settings/companyTypes/Index';
import SettingsCounties from 'views/pages/settings/counties/Index';
import SettingsAutomationTasks from 'views/pages/settings/automationTasks/Index';
import SettingsAutomationTasksForm from 'views/pages/settings/automationTasks/Form';
import SettingsReminders from 'views/pages/settings/reminders/Index';
import SettingsReminderEdit from 'views/pages/settings/reminders/Edit';
import SettingsPersonalisation from 'views/pages/settings/personalisation/Index';
import SettingsAutomatedReminders from 'views/pages/settings/personalisation/AutomatedReminders';
import SettingsNotifications from 'views/pages/settings/personalisation/Notifications';
import SettingsNotificationsEdit from 'views/pages/settings/personalisation/NotificationsEdit';
import SettingsResourceTasks from 'views/pages/settings/tasks/Index';
import Emails from 'views/pages/settings/emails/Index';
import EmailTemplateForm from 'views/pages/settings/emails/Form';
import SendForm from 'views/pages/settings/emails/SendForm';
import EmailsSent from 'views/pages/settings/emailsSent/Index';

import SettingsAutomatedTasks from '@pages/settings/personalisation/AutomatedTasks';
import SettingsAutomatedTasksEdit from '@pages/settings/personalisation/AutomatedTasksEdit';
/**
 * Website
 */
import Applications from 'views/pages/website/applications/Index';
import ApplicationShow from 'views/pages/website/applications/Show';
import EmailTemplates from 'views/pages/website/emailTemplates/Index';
import LeadApplicationNotifications from 'views/pages/website/Notifications';

/**
 * Types
 */
import LeadSources from 'views/pages/types/sources/Index';
import Countries from 'views/pages/types/countries/Index';
import Regions from 'views/pages/types/regions/Index';


/**
 * Tasks
 */
import Tasks from 'views/pages/tasks/Tasks';
import TaskCategoriesManagement from 'views/pages/tasks/Categories';
import TaskResourcesManagement from 'views/pages/tasks/Resources';
import TaskCreate from './views/pages/tasks/Create';
import XeroModule from './views/pages/admin/dealerProfile/Modules/XeroModule';
import {XeroCallback} from './views/pages/admin/dealerProfile/Modules/Integration/XeroCallback';
import NewPayment from './views/pages/deals/payment/NewPayment';
import EditPayment from './views/pages/deals/payment/EditPayment';
import CronStatus from './views/pages/payments/CronStatus';
import Targets from './views/pages/liveVehicles/Targets';
import Reports from "./views/pages/fleet/pages/Reports";
import EditTask from "./views/pages/fleet/pages/EditTask";
import VehicleDetails from "./views/pages/fleet/pages/VehicleDetails";
import FleetTasks from "./views/pages/fleet/pages/Tasks"
import FleetReports from "./views/pages/fleet/pages/Reports";
import Schedule from "@pages/job-booker/Schedule";
import UpdateTask from "@pages/job-booker/UpdateTask";
import DeliveryDetails from "@pages/fleet/pages/components/DeliveryDetails";
import Vehicles from "@pages/job-booker/Vehicles";
import Drivers from "@pages/job-booker/Drivers";
import Receipts from "@pages/job-booker/Receipts";

const DashboardComponents = {
    Dashboard,
    PaulAuDashboard,
};

const routes = [
    {
        path: "/worker-details",
        name: "Worker Details",
        component: WorkerDetails,
        layout: "/admin",
        permission: 'hidden'

    },
    {
        path: "/task-details",
        name: "Task details",
        component: EditTask,
        layout: "/admin",
        permission: 'hidden'
    },
    {
        path: "/vehicle-details",
        name: "Vehicle details",
        component: VehicleDetails,
        layout: "/admin",
        permission: 'hidden'
    },


    {
        path: '/dashboard',
        name: 'Dashboard',
        miniName: 'D',
        icon: Icons.Dashboard,
        component:
            constants.SITE_CODE != 'Uk'
                ? DashboardComponents[constants.SITE_CODE + 'Dashboard']
                : DashboardComponents['Dashboard'],
        layout: '/admin',
    },
    {
        collapse: true,
        name: 'Admin',
        icon: Icons.Admin,
        state: 'adminCollapse',
        views: [
            {
                path: '/profile',
                name: 'Profile',
                miniName: 'P',
                component: Profile,
                layout: '/admin',
            },
            {
                path: '/rental-calculator',
                name: 'Rental Calculator',
                miniName: 'R',
                hide: ['Fleet'],
                component: RentalCalculator,
                layout: '/admin',
            },
            {
                path: '/dealer/profile',
                name: 'Dealer profile',
                miniName: 'DP',
                component: DealerProfile,
                layout: '/admin',
                authUserTeam: true,
                hide: ['Fleet'],
                permission: [
                    'read-dealer-profile',
                    'update-dealer-profile',
                    'create-dealer-profile',
                ],
            },
            {
                path: '/dealer/modules',
                name: 'Modules',
                miniName: 'DM',
                component: DealerModules,
                layout: '/admin',
                authUserTeam: true,
                hide: ['Fleet'],
                permission: [
                    'read-dealer-profile',
                    'update-dealer-profile',
                    'create-dealer-profile',
                ],
            },
            {
                path: '/settings/modules/kashflow',
                name: 'NavHidden',
                miniName: 'DE',
                hide: ['Fleet'],
                component: KashflowModule,
                layout: '/admin',
            },
            {
                path: '/settings/modules/xero',
                name: 'NavHidden',
                miniName: 'DE',
                hide: ['Fleet'],
                component: XeroModule,
                layout: '/admin',
            },
            {
                path: '/settings/xero-integration-callback',
                name: 'NavHidden',
                miniName: 'IC',
                hide: ['Fleet'],
                component: XeroCallback,
                layout: '/admin',
            },
            {
                path: '/dealer/integration',
                name: '3rd Party Integration',
                miniName: '3PI',
                component: Integration,
                layout: '/admin',
                hide: ['Fleet'],
                authUserTeam: true,
                permission: [
                    'read-dealer-profile',
                    'update-dealer-profile',
                    'create-dealer-profile',
                ],
            },
            {
                path: '/role',
                name: 'Role Management',
                miniName: 'RM',
                hide: ['Fleet'],
                component: Role,
                layout: '/admin',
                permission: ['read-acl'],
            },
            {
                path: '/role/:id/edit',
                name: 'NavHidden',
                miniName: 'RE',
                component: RoleEdit,
                layout: '/admin',
            },
            {
                path: '/role/:id/show',
                name: 'NavHidden',
                miniName: 'RS',
                component: RoleShow,
                layout: '/admin',
            },
            {
                path: '/dealer',
                name: 'Dealers Management',
                miniName: 'D',
                hide: ['Fleet'],
                component: Dealer,
                layout: '/admin',
                permission: ['read-dealer-dealers'],
            },
            {
                path: '/dealer/:id/edit',
                name: 'NavHidden',
                miniName: 'DE',
                hide: ['Fleet'],
                component: DealerEdit,
                layout: '/admin',
            },
            {
                path: '/supplier',
                name: 'Suppliers Management',
                hide: ['Fleet'],
                miniName: 'S',
                component: Supplier,
                layout: '/admin',
                permission: ['read-supplier-dealers'],
            },
            {
                path: '/permission',
                name: 'Permission Management',
                miniName: 'PL',
                component: Permission,
                hide: ['Fleet'],
                layout: '/admin',
                permission: ['read-acl'],
                role: ['superadministrator'],
            },
            {
                path: '/permission/:id/edit',
                name: 'NavHidden',
                miniName: 'PE',
                hide: ['Fleet'],
                component: PermissionEdit,
                layout: '/admin',
            },
            {
                path: '/user',
                name: 'User Management',
                miniName: 'UL',
                hide: ['Fleet'],
                component: User,
                layout: '/admin',
                permission: ['read-users'],
            },
            {
                path: '/user/:id/edit',
                name: 'NavHidden',
                hide: ['Fleet'],
                miniName: 'UE',
                component: UserEdit,
                layout: '/admin',
            },
            {
                path: '/message',
                name: 'Messages',
                hide: ['Fleet'],
                miniName: 'M',
                component: Message,
                layout: '/admin',
                permission: ['create-messages'],
            },
            {
                path: '/message/:id/edit',
                name: 'NavHidden',
                hide: ['Fleet'],
                miniName: 'ME',
                component: MessageEdit,
                layout: '/admin',
            },
            {
                path: '/all-notifications',
                name: 'NavHidden',
                hide: ['Fleet'],
                miniName: 'AN',
                component: Notifications,
                layout: '/admin',
            },
            {
                path: '/holiday',
                name: 'Holidays',
                hide: ['Fleet'],
                miniName: 'H',
                component: Holiday,
                layout: '/admin',
            },
            {
                path: '/holiday/:id/user',
                name: 'NavHidden',
                hide: ['Fleet'],
                miniName: 'UH',
                component: UserHoliday,
                layout: '/admin',
            },
        ],
    },
    {
        collapse: true,
        name: 'Customers',
        icon:  Icons.Customers,
        state: 'customersCollapse',
        permission: ['read-customers'],
        views: [
            {
                path: '/customer',
                name: 'Customer',
                miniName: 'C',
                component: Customer,
                layout: '/admin',
                permission: ['read-customers'],
            },
            {
                path: '/customer/create',
                name: 'NavHidden',
                miniName: 'CC',
                component: CustomerCreate,
                layout: '/admin',
            },
            {
                path: '/customer/:id/edit',
                name: 'NavHidden',
                miniName: 'CE',
                component: CustomerEdit,
                layout: '/admin',
            },
            {
                path: '/customer/:customerId/tasks',
                name: 'NavHidden',
                miniName: 'CT',
                component: CustomerTask,
                layout: '/admin',
            },
            {
                path: '/customer/:customerId/tasks/:taskId/edit',
                name: 'NavHidden',
                miniName: 'CTE',
                component: CustomerTaskEdit,
                layout: '/admin',
            },
            {
                path: '/customer/:customerId/notes',
                name: 'NavHidden',
                miniName: 'CN',
                component: CustomerNote,
                layout: '/admin',
            },
            {
                path: '/customer/:customerId/notes/:noteId/edit',
                name: 'NavHidden',
                miniName: 'CNE',
                component: CustomerNoteEdit,
                layout: '/admin',
            },
            {
                path: '/defaulters',
                name: 'Defaulters',
                miniName: 'D',
                component: Defaulters,
                layout: '/admin',
                permission: ['read-customers'],
            },
            {
                path: '/payments',
                name: 'Payments',
                miniName: 'P',
                component: Payments,
                layout: '/admin',
                can: 'canAccessPaymentsDetails',
            },
        ],
    },
    {
        collapse: true,
        name: 'Deals',
        icon: Icons.Deal,
        state: 'dealsCollapse',
        permission: ['read-deals', 'create-award-deal', 'read-unawarded-deals'],
        views: [
            {
                path: '/deals',
                name: 'All Deals',
                miniName: 'AD',
                component: Deal,
                layout: '/admin',
                permission: ['read-deals'],
            },
            {
                path: '/deals/create',
                name: 'NavHidden',
                miniName: 'ND',
                component: DealCreate,
                layout: '/admin',
                can: 'canDealCreate',
            },
            {
                path: '/deals/notifications',
                name: 'NavHidden',
                miniName: 'DN',
                component: DealNotifications,
                layout: '/admin',
            },
            {
                path: '/deals/:id/edit',
                name: 'NavHidden',
                miniName: 'DE',
                component: DealEdit,
                layout: '/admin',
            },
            {
                path: '/deals/:dealId/task/:taskId',
                name: 'NavHidden',
                miniName: 'DTE',
                component: DealTaskEdit,
                layout: '/admin',
            },
            {
                path: '/deals/:id/payment',
                name: 'NavHidden',
                miniName: 'DP',
                component: DealPayment,
                layout: '/admin',
            },
            {
                path: "/deals/:id/payment/:scheduleId/details",
                name: "NavHidden",
                miniName: "DP",
                component: PaymentDetails,
                layout: "/admin"
            },
            {
                path: "/deals/:id/payment/new",
                name: "NavHidden",
                miniName: "NP",
                component: NewPayment,
                layout: "/admin"
            },
            {
                path: "/deals/:id/payment/:scheduleId/edit",
                name: "NavHidden",
                miniName: "NP",
                component: EditPayment,
                layout: "/admin"
            },
            {
                path: '/applications',
                name: 'Applications',
                miniName: 'A',
                component: Applications,
                layout: '/admin',
                permission: ['read-applications'],
            },
            {
                path: '/applications/:id',
                name: 'NavHidden',
                miniName: 'AS',
                component: ApplicationShow,
                layout: '/admin',
            },
            {
                path: '/settings/notifications',
                name: 'NavHidden',
                miniName: 'LAN',
                component: LeadApplicationNotifications,
                layout: '/admin',
            }
        ]
    },
    {
        collapse: true,
        name: 'Payments',
        icon: Icons.Payments,
        state: 'paymentsCollapse',
        permission: ['read-deals'],
        views: [
            {
                path: '/payments/list',
                name: 'All Payments',
                miniName: 'AD',
                component: PaymentsList,
                layout: '/admin',
                permission: ['read-deals'],
            },
            {
                path: '/payments/status',
                name: 'Cron Status',
                miniName: 'CS',
                component: CronStatus,
                layout: '/admin',
                permission: ['read-deals'],
            }
        ]
    },
    {
        collapse: true,
        name: 'Fleet Management',
        icon: Icons.FleetManagement,
        state: 'fleetCollapse',
        permission: [
            'read-fleet-management'
        ],
        views: [
            {
                path: "/fleet-management",
                name: "Home",
                icon: "fas fa-home",
                miniName: "H",
                subMenu: true,
                component: Fleet,
                role: [
                    'Fleet Manager',
                    'Fleet',
                    'dealer_admin'
                ],
                layout: '/admin',
                permission: ['read-fleet-management'],
            },
            {
                path: "/fleet-vehicles",
                name: "Vehicles",
                icon: "fas fa-car",
                subMenu: true,
                miniName: "V",
                component: VehicleInStock,
                layout: "/admin",
                role: [
                    'Fleet Manager',
                    'Fleet',
                    'dealer_admin'
                ],
                permission: [
                    'read-fleet-management'
                ]
            },
            // {
            //   path: "/book-in-page",
            //   name: "Book In Page",
            //   miniName: "BIP",
            //   component: BookInPage,
            //   layout: "/admin",
            //   permission: [
            //     'read-fleet-management'
            //   ]
            // },
            {
                path: "/fleet-workers",
                name: "Workers",
                miniName: "ABD",
                icon: "fas fa-users",
                subMenu: true,
                component: Workers,
                layout: "/admin",
                role: [
                    'Fleet Manager',
                    'super_admin',
                    'dealer_admin'
                ],
                permission: [
                    'read-fleet-management'
                ]
            },
            // 'dealer_admin',
            // {
            //   path: "/fleet-tasks",
            //   icon: "fas fa-wrench",
            //   name: "Tasks",
            //   subMenu: true,
            //   miniName: "Ts",
            //   component: FleetTasks,
            //   layout: "/admin",
            //   role: [
            //     'Fleet Manager',
            //     'Fleet',
            //     'dealer_admin'
            //   ],
            //   permission: [
            //     'read-fleet-management'
            //   ]
            // },

            // {
            //   path: "/jobs-by-date",
            //   name: "Jobs By Date",
            //   miniName: "JBD",
            //   component: JobsByDate,
            //   layout: "/admin",
            //   // permission: [
            //   //   'read-fleet-management'
            //   // ]
            // },
            {
                path: "/fleet-reports",
                name: "Reports",
                miniName: "R",
                subMenu: true,
                icon: "fas fa-tasks",
                component: FleetReports,
                role: [
                    'Fleet Manager',
                    'super_admin',
                    'dealer_admin'
                ],
                permission: [
                    'read-fleet-management'
                ],
                layout: "/admin",
            },
            {
                path: '/fleet-job/create',
                name: 'NavHidden',
                miniName: 'FJC',
                component: JobCreate,
                layout: '/admin',
            },
            {
                path: '/fleet-job/:id/inspect',
                name: 'NavHidden',
                miniName: 'FJI',
                component: VehicleInspect,
                layout: '/admin',
            },
            // {
            //   path: '/live-vehicle/fleet-management',
            //   name: 'Fleet management Vehicles',
            //   miniName: 'VF',
            //   component: LiveVehicleIndex,
            //   layout: '/admin',
            //   permission: ['read-vehicles'],
            //   role: ['dealer_admin', 'Fleet'],
            // },
            // {
            //   path: "/fleet-job/dashboard/body_shop",
            //   name: "Bodyshop Dashboard",
            //   miniName: "FBSD",
            //   component: CategoryDashBoard,
            //   layout: "/admin",
            //   permission: [
            //     'read-body-shop'
            //   ],
            // },
            // {
            //   path: "/fleet-job/inspect/body_shop",
            //   name: "Bodyshop Vehicles",
            //   miniName: "FBSI",
            //   component: VehicleInStock,
            //   layout: "/admin",
            //   permission: [
            //     'read-body-shop'
            //   ],
            // },
            // {
            //   path: "/fleet-job/dashboard/valeting",
            //   name: "Valeting Dashboard",
            //   miniName: "FVD",
            //   component: CategoryDashBoard,
            //   layout: "/admin",
            //   permission: [
            //     'read-valeting'
            //   ],
            // },
            // {
            //   path: "/fleet-job/inspect/valeting",
            //   name: "Valeting Vehicles",
            //   miniName: "FBSI",
            //   component: VehicleInStock,
            //   layout: "/admin",
            //   permission: [
            //     'read-valeting'
            //   ],
            // },
            // {
            //   path: "/fleet-job/dashboard/mechanical",
            //   name: "Mechanical Dashboard",
            //   miniName: "FMD",
            //   component: CategoryDashBoard,
            //   layout: "/admin",
            //   permission: [
            //     'read-mechanical'
            //   ],
            // },
            // {
            //   path: "/fleet-job/inspect/mechanical",
            //   name: "Mechanical Vehicles",
            //   miniName: "MV",
            //   component: VehicleInStock,
            //   layout: "/admin",
            //   permission: [
            //     'read-mechanical'
            //   ],
            // },
            {
                path: '/fleet-job/type-inspect/:type',
                name: 'NavHidden',
                miniName: 'FMD',
                component: TypeInspect,
                layout: '/admin',
            },
            {
                path: '/live-vehicle/fleet-management',
                name: 'Vehicles being collected',
                miniName: 'VB',
                icon: "fas fa-tasks",
                subMenu: true,
                component: LiveVehicleIndex,
                layout: '/admin',
                permission: ['read-fleet-management'],
                role: [
                    'Fleet Manager',
                    'Fleet',
                    'dealer_admin'
                ],
            },
        ],
    },
    {
        name: "Job Booker",
        collapse: true,
        icon: Icons.Website,
        state: "bookerCollapse",
        role: ['dealer_admin', 'AdminMain', 'Account Manager'],
        views: [
            {
                path: "/job-booker/schedule",
                name: "Home",
                icon: "fas fa-home",
                miniName: "H",
                subMenu: true,
                component: Schedule,
                layout: "/admin",
                role: ['dealer_admin', 'AdminMain', 'Account Manager'],
            },
            {
                path: "/schedule-details",
                component: UpdateTask,
                layout: "/admin",
                permission: 'hidden'
            },

            {

                path: "/delivery-task-details",
                component: DeliveryDetails,
                layout: "/admin",
                permission: 'hidden'
            },
            {
                path: "/job-booker/vehicles",
                name: "Vehicles",
                icon: "fas fa-car",
                miniName: "V",
                subMenu: true,
                component: Vehicles,
                layout: "/admin",

                role: ['dealer_admin', 'AdminMain', 'Account Manager'],
            },
                        {
                path: "/job-booker/drivers",
                name: "Drivers",
                miniName: "D",
                icon: "fas fa-users",
                subMenu: true,
                component: Drivers,
                layout: "/admin",
                role: ['dealer_admin', 'AdminMain', 'Account Manager'],

            },
            {
                path: "/job-booker/receipts",
                name: "Receipts",
                miniName: "D",
                icon: "fas fa-users",
                subMenu: true,
                component: Receipts,
                layout: "/admin",
                role: ['dealer_admin', 'AdminMain', 'Account Manager'],
            },

        ]
    },
    {
        collapse: true,
        name: 'Leads CRM',
        icon:  Icons.Leads,
        state: 'leadCollapse',
        permission: ['read-leads'],
        views: [
            {
                path: '/leads',
                name: 'View leads',
                miniName: 'VL',
                component: Lead,
                layout: '/admin',
                permission: ['read-leads'],
            },
            {
                path: '/leads/:id/edit',
                name: 'NavHidden',
                miniName: 'LE',
                component: LeadEdit,
                layout: '/admin',
            },
            {
                path: '/leads/create',
                name: 'Create Lead',
                miniName: 'CL',
                component: LeadCreate,
                layout: '/admin',
                permission: ['create-leads'],
            },
            {
                path: '/leads/statistics',
                name: 'Statistics',
                miniName: 'S',
                component: Statistics,
                layout: '/admin',
                permission: ['create-leads'],
            },
            {
                path: '/leads/statistics/users',
                name: 'Users Stats',
                miniName: 'US',
                component: UsersStatistics,
                layout: '/admin',
                permission: ['create-leads'],
            },
            {
                path: '/leads/notifications',
                name: 'Notifications',
                miniName: 'LN',
                component: LeadNotifications,
                layout: '/admin',
                permission: ['create-leads'],
            },
        ],
    },
    {
        collapse: true,
        name: 'Tracking',
        icon:  Icons.Tracking,
        state: 'trackingCollapse',
        hide: ['Fleet'],
        views: [
            {
                path: '/tracking/account-integration',
                name: 'Integration',
                miniName: 'I',
                component: AccountIntegration,
                layout: '/admin',
                can: 'cannotAccessToTracking',
            },
            {
                path: '/tracking/account-integration-callback',
                name: 'NavHidden',
                miniName: 'IC',
                component: AccountIntegrationCallBack,
                layout: '/admin',
            },
            {
                path: '/tracking/index',
                name: 'Tracking',
                miniName: 'T',
                component: Tracking,
                layout: '/admin',
                can: 'canAccessToTracking',
            },
            // {
            //   path: "/tracking/:id/edit",
            //   name: "NavHidden",
            //   miniName: "TE",
            //   component: TrackingEdit,
            //   layout: "/admin"
            // },
            {
                path: '/tracking/general',
                name: 'General Overview',
                miniName: 'TD',
                component: GeneralOverview,
                layout: '/admin',
                can: 'canAccessToTracking',
                permission: ['create-tracking-device'],
            },
            // {
            //   path: "/tracking/dashboard",
            //   name: "Dashboard",
            //   miniName: "TD",
            //   component: TrackingDashboard,
            //   layout: "/admin",
            //   can: 'canAccessToTracking',
            // },
            {
                path: '/tracking/alerts-and-notification',
                name: 'Alert & Notifications',
                miniName: 'TA',
                component: AlertsAndNotification,
                layout: '/admin',
                can: 'canAccessToTracking',
                permission: ['create-tracking-device'],
            },
            // {
            //   path: "/tracking/order-trackers",
            //   name: "Order Trackers",
            //   miniName: "TA",
            //   component: OrderTrackers,
            //   layout: "/admin"
            // },
            {
                path: '/tracking/activate-tracker',
                name: 'Activate Tracker',
                miniName: 'TAT',
                component: ActivateTracker,
                layout: '/admin',
                can: 'canAccessToTracking',
                permission: ['create-tracking-device'],
            },
            {
                path: '/tracking/device/:uuid',
                name: 'NavHidden',
                miniName: 'DS',
                component: TrackingEdit,
                layout: '/admin',
                can: 'canAccessToTracking',
            },
            {
                path: '/tracking/assigning-funder',
                name: 'Assigning Funder',
                miniName: 'AF',
                component: AssigningFunder,
                layout: '/admin',
                can: 'canAccessToTracking',
                permission: ['create-tracking-device'],
            },
            {
                path: '/tracking/notificaitons',
                name: 'NavHidden',
                miniName: 'TN',
                component: TrackingNotifications,
                layout: '/admin',
                can: 'canAccessToTracking',
            },
        ],
    },
    {
        collapse: true,
        name: 'Vehicles',
        icon: Icons.Vehicles,
        state: 'vehicleCollapse',
        hide: ['Fleet'],
        permission: ['read-vehicles', 'read-vehicle-make', 'read-vehicle-model'],
        views: [
            {
                path: '/vehicle',
                name: 'Vehicles',
                miniName: 'V',
                component: Vehicle,
                layout: '/admin',
                permission: ['read-vehicles'],
            },
            {
                path: '/vehicle/:id/edit',
                name: 'NavHidden',
                miniName: 'VE',
                component: VehicleEdit,
                layout: '/admin',
            },
            {
                path: '/vehicle/create',
                name: 'NavHidden',
                miniName: 'VC',
                component: VehicleCreate,
                layout: '/admin',
            },
            {
                path: '/vehicle/:id/duplicate',
                name: 'NavHidden',
                miniName: 'VD',
                component: VehicleDuplicate,
                layout: '/admin',
            },
            {
                path: '/vehicle/:id/cost',
                name: 'NavHidden',
                miniName: 'VC',
                component: VehicleCost,
                layout: '/admin',
            },
            {
                path: '/vehicle/:id/financial',
                name: 'NavHidden',
                miniName: 'VFD',
                component: VehicleFinancialDetails,
                layout: '/admin',
            },
            {
                path: '/vehicle/:id/financial-history',
                name: 'NavHidden',
                miniName: 'VFH',
                component: VehicleFinancialHistory,
                layout: '/admin',
            },
            {
                path: '/vehicle-make',
                name: 'Vehicle Makes',
                miniName: 'VMA',
                component: VehicleMake,
                layout: '/admin',
                permission: ['read-vehicle-make'],
            },
            {
                path: '/vehicle-model',
                name: 'Vehicle Models',
                miniName: 'VMO',
                component: VehicleModel,
                layout: '/admin',
                permission: ['read-vehicle-model'],
            },
            {
                path: '/vehicle/notifications',
                name: 'NavHidden',
                miniName: 'VN',
                component: VehicleNotifications,
                layout: '/admin',
            },
        ],
    },
    {
        collapse: true,
        name: 'Live Vehicles',
        icon: Icons.Vehicles,
        state: 'liveVehicleCollapse',
        permission: ['read-vehicles', 'read-vehicle-make', 'read-vehicle-model'],
        hide: ['Fleet'],
        views: [
            {
                path: '/live-vehicle/master',
                name: 'Master Vehicles',
                miniName: 'VM',
                component: LiveVehicleIndex,
                layout: '/admin',
                permission: ['read-vehicles'],
                role: ['dealer_admin', 'Sales Director'],
            },
            {
                path: '/live-vehicle/for-sale',
                name: 'Vehicles for sale',
                miniName: 'VS',
                component: LiveVehicleIndex,
                layout: '/admin',
                permission: ['read-vehicles'],
                role: ['dealer_admin', 'Sales'],
            },
            {
                path: '/live-vehicle/for-rent',
                name: 'Vehicles for Rent',
                miniName: 'VR',
                component: LiveVehicleIndex,
                layout: '/admin',
                permission: ['read-vehicles'],
                role: ['dealer_admin', 'Sales'],
            },
            // {
            //   path: '/live-vehicle/r-2-b',
            //   name: 'R2B',
            //   miniName: 'R',
            //   component: LiveVehicleIndex,
            //   layout: '/admin',
            //   permission: ['read-vehicles'],
            //   role: ['dealer_admin', 'Sales'],
            // },
            {
                path: '/live-vehicle/:id/edit',
                name: 'NavHidden',
                component: LiveVehicleEdit,
                layout: '/admin',
                permission: ['read-vehicles'],
                role: ['dealer_admin'],
            },
            {
                path: '/live-vehicle/:id/edit/fleet',
                name: 'NavHidden',
                component: LiveVehicleEdit,
                layout: '/admin',
                permission: ['read-vehicles'],
                role: ['dealer_admin'],
            },
            {
                path: '/live-vehicle-statistics/daily',
                miniName: 'DS',
                name: 'Daily stats',
                component: DailyStatistics,
                layout: '/admin',
                permission: ['read-vehicles'],
                role: ['dealer_admin', 'AdminMain', 'Account Manager', 'Sales'],
            },
            {
                path: '/live-vehicle-statistics/targets',
                miniName: 'DS',
                name: 'Targets',
                component: StatTargets,
                layout: '/admin',
                permission: ['read-vehicles'],
                role: ['dealer_admin'],
            },
            {
                path: '/live-vehicle-statistics/month-statistics',
                miniName: 'VS',
                name: 'Vehicle Report',
                component: ViewStatistics,
                layout: '/admin',
                permission: ['read-vehicles'],
                role: ['dealer_admin'],
            },
            {
                path: '/live-vehicle-statistics/statistics',
                miniName: 'MS',
                name: 'Month Summary',
                component: VehicleReport,
                layout: '/admin',
                permission: ['read-vehicles'],
                role: ['dealer_admin'],
            },
        ],
    },
    {
        collapse: true,
        name: 'Tasks',
        icon: Icons.Tasks,
        state: 'tasksCollapse',
        hide: ['Fleet'],
        views: [
            {
                path: '/tasks/create',
                name: 'New Task',
                miniName: 'NT',
                component: TaskCreate,
                layout: '/admin',
            },
            {
                path: '/tasks',
                name: 'All Tasks',
                miniName: 'AT',
                component: Tasks,
                layout: '/admin',
            },
            {
                path: '/tasks/:task_category_slug',
                name: 'NavHidden',
                miniName: 'T',
                component: Tasks,
                layout: '/admin',
            },
            // {
            //   path: '/task-categories-management',
            //   name: 'Task Categories',
            //   miniName: 'TC',
            //   component: TaskCategoriesManagement,
            //   layout: '/admin',
            // },
            // {
            //   path: '/task-resources-management',
            //   name: 'Task Resources',
            //   miniName: 'TR',
            //   component: TaskResourcesManagement,
            //   layout: '/admin',
            // },
        ],
    },
    {
        collapse: true,
        name: 'Reports',
        icon: Icons.Reports,
        state: 'reportsCollapse',
        permission: ['read-admin-reports'],
        views: [
            {
                path: '/report/deals/business-activity',
                name: 'Business Activities Report',
                miniName: 'BAR',
                component: BusinessActivity,
                layout: '/admin',
            },
            {
                path: '/report/fleet-management/report',
                name: 'Fleet Report',
                miniName: 'FR',
                component: FleetReport,
                layout: '/admin',
            },
            {
                path: '/report/vhicles/county',
                name: 'Vehicles by County',
                miniName: 'VBC',
                component: VehiclesCounty,
                layout: '/admin',
                authUserTeam: true,
            },
            {
                path: '/report/business-type/county',
                name: 'Business types by County',
                miniName: 'BTC',
                component: BusinessTypeCounty,
                layout: '/admin',
                authUserTeam: true,
            },
        ],
    },
    {
        path: '/send-email',
        name: 'NavHidden',
        miniName: 'SE',
        component: SendForm,
        layout: '/admin',
    },
    {
        collapse: true,
        name: 'Settings',
        icon: Icons.Settings,
        state: 'settingsCollapse',
        permission: [
            'read-business-activities',
            'read-company-types',
            'read-counties',
            'read-automation-task',
            'read-automated-reminders',
        ],
        views: [
            {
                path: '/settings/email-templates/create',
                name: 'NavHidden',
                miniName: 'EMS',
                component: EmailTemplateForm,
                layout: '/admin',
                permission: ['update-email-templates', 'create-email-templates'],
            },
            {
                path: '/settings/email-templates/:id/edit',
                name: 'NavHidden',
                miniName: 'EMS',
                component: EmailTemplateForm,
                layout: '/admin',
                permission: ['update-email-templates', 'create-email-templates'],
            },
            {
                path: '/settings/email-templates',
                name: 'Templates',
                miniName: 'TP',
                component: Emails,
                layout: '/admin',
                permission: ['read-email-templates'],
            },
            {
                path: '/settings/documents',
                name: 'Documents',
                miniName: 'SD',
                component: SettingsDocuments,
                layout: '/admin',
                permission: ['read-dealer-documents'],
            },
            {
                path: '/settings/documents/create',
                name: 'NavHidden',
                miniName: 'SD',
                component: SettingsDocumentsForm,
                layout: '/admin',
            },
            {
                path: '/settings/documents/:id/edit',
                name: 'NavHidden',
                miniName: 'SD',
                component: SettingsDocumentsForm,
                layout: '/admin',
            },
            {
                path: '/settings/business-activity',
                name: 'Business Activities',
                miniName: 'BA',
                component: SettingsBusinessActivities,
                layout: '/admin',
                permission: ['read-business-activities'],
            },
            {
                path: '/settings/company-type',
                name: 'Company Types',
                miniName: 'CT',
                component: SettingsCompanyTypes,
                layout: '/admin',
                permission: ['read-company-types'],
            },
            {
                path: '/settings/county',
                name: 'Counties',
                miniName: 'C',
                component: SettingsCounties,
                layout: '/admin',
                permission: ['read-counties'],
            },
            {
                path: '/settings/automation-task',
                name: 'Automation Tasks',
                miniName: 'AT',
                component: SettingsAutomationTasks,
                layout: '/admin',
                role: ['superadministrator'],
                permission: ['read-automation-task'],
            },
            {
                path: '/settings/automation-task/create',
                name: 'NavHidden',
                miniName: 'ATC',
                component: SettingsAutomationTasksForm,
                layout: '/admin',
            },
            {
                path: '/settings/automation-task/:id/edit',
                name: 'NavHidden',
                miniName: 'ATE',
                component: SettingsAutomationTasksForm,
                layout: '/admin',
            },
            {
                path: '/settings/automated-reminders',
                name: 'Reminders',
                miniName: 'AR',
                component: SettingsReminders,
                layout: '/admin',
                permission: ['read-automated-reminders'],
            },
            {
                path: '/settings/automated-reminders/:id/edit',
                name: 'NavHidden',
                miniName: 'RE',
                component: SettingsReminderEdit,
                layout: '/admin',
            },
            {
                path: '/settings/personalisation',
                name: 'Personalisation',
                miniName: 'AR',
                component: SettingsPersonalisation,
                layout: '/admin',
                permission: ['read-notifications'],
                authUserTeam: true,
            },
            {
                path: '/settings/personalisation/automated-reminders',
                name: 'NavHidden',
                miniName: 'PAR',
                component: SettingsAutomatedReminders,
                layout: '/admin',
            },
            {
                path: '/settings/personalisation/notifications',
                name: 'NavHidden',
                miniName: 'PAR',
                component: SettingsNotifications,
                layout: '/admin',
            },
            {
                path: '/settings/personalisation/notifications/:role/edit',
                name: 'NavHidden',
                miniName: 'PAR',
                component: SettingsNotificationsEdit,
                layout: '/admin',
            },
            {
                path: '/settings/personalisation/tasks',
                name: 'NavHidden',
                miniName: 'AUT',
                component: SettingsAutomatedTasks,
                layout: '/admin',
            },
            {
                path: '/settings/personalisation/tasks/:role/edit',
                name: 'NavHidden',
                miniName: 'AUT',
                component: SettingsAutomatedTasksEdit,
                layout: '/admin',
            },
            {
                path: '/resources/tasks',
                name: 'NavHidden',
                miniName: 'SRT',
                component: SettingsResourceTasks,
                layout: '/admin',
            },
        ],
    },
    {
        collapse: true,
        name: 'Types',
        icon: 'fas fa-sitemap text-default',
        state: 'typesCollapse',
        permission: ['read-lead-sources', 'read-countries', 'read-regions'],
        views: [
            {
                path: '/lead-sources',
                name: 'Lead Sources',
                miniName: 'LS',
                component: LeadSources,
                layout: '/admin',
                permission: ['read-lead-sources'],
            },
            {
                path: '/settings/countries',
                name: 'Countries',
                miniName: 'C',
                component: Countries,
                layout: '/admin',
                permission: ['read-countries'],
            },
            {
                path: '/types/regions',
                name: 'Regions',
                miniName: 'R',
                component: Regions,
                layout: '/admin',
                permission: ['read-regions'],
            },
        ],
    },
];

export default routes;
