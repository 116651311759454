import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";
import CardsHeader from 'layouts/Headers/CardsHeader.js';


import { PaymentContextProvider } from '../context';

import {
  Container,
} from "reactstrap";

import EditInvoicePaymentContent from './EditInvoicePaymentContent';

export default function EditPayment() {
  const { id, scheduleId } = useParams();

  const notificationAlert = useRef(null);
  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };
  return (
    <PaymentContextProvider id={id} paymentScheduleId={scheduleId} notify={notify}>
      <CardsHeader name="Deal" parentName="Payment management" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <EditInvoicePaymentContent/>
      </Container>
    </PaymentContextProvider>
  )
}