import React, {useEffect, useState} from "react";
import {Button, Collapse, Input, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink} from "reactstrap";
import {NavLink as NavLinkRRD, useHistory, useLocation} from "react-router-dom";
import actions from "actions/index.js";
import classnames from "classnames";
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Line} from "@pages/reusable-components/styled-components";
import usePrevious from "../hooks/useprevious";
import AdminNavbar from "./Navbars/AdminNavbar";
import routes from "../routes";

export default function TopMenu({routes,menu,route,isSmall = false,show,activeRoute}) {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const authState = useSelector(state => state.getState, shallowEqual);
    const [activeR,serActiveR] = useState(activeRoute)
    const prevActiveR = usePrevious(activeR)
    const [isOpen, setOpen] = useState(false);
    const prevTab = usePrevious(authState.activeMenu)
    const hasPermission = (permissions, _permissions) => {
        return _permissions.some(permission => {
            return permissions.includes(permission)
        });
    }

    const findId = (url) => {
       return url.split('/')
    };

    useEffect(()=>{
        if (prevTab === 'payments') {
           let id = findId(location.pathname)[3]
            history.push({
                pathname: '/admin/deals/' + id + '/edit',
            })
        }
        if (authState?.menuName === 'Customers' && (prevTab === 'tasks' || prevTab === 'notes')) {
            let id = findId(location.pathname)[3]
            if (authState.activeMenu === 'tasks' || authState.activeMenu === 'notes') {
                goSub(authState.activeMenu,id)
            } else {
                history.push({
                    pathname: '/admin/customer/' + id + '/edit',
                })
            }
        }
    },[authState.activeMenu])

    const goSub = (subject,id) => {
        switch (subject) {
            case 'tasks':
                history.push({
                    pathname: '/admin/customer/' + id + '/tasks',

                });
                break;

            case 'notes':
                history.push({
                    pathname: '/admin/customer/' + id + '/notes',

                });
                break;

            default:
                break;
        }
    }

    useEffect(()=>{
        if (authState.menuName && (menu?.name !== authState.menuName)) {
            dispatch(actions.setMenusList([]))
            dispatch(actions.setMenuName(null))
            dispatch(actions.setActiveMenu(null))
        }
    },[authState.menuName,menu?.name])

   const getNavbarTheme = () => {
        return location.pathname.indexOf(
            "admin/alternative-dashboard"
        ) === -1
            ? "dark"
            : "light";
    };
   const getBrandText = path => {
        for (let i = 0; i < routes.length; i++) {
            if (
                location.pathname.indexOf(
                    routes[i].layout + routes[i].path
                ) !== -1
            ) {
                return routes[i].name;
            }
        }
        return "Brand";
    };
    const hasCan = (routeCan, can) => {
        return routeCan[can]
    }
    // check has role
    const hasRole = (roles, _roles) => {
        return _roles.some(role => {
            return roles.includes(role)
        });
    }
    const createSubmenus = menus => {
         return menus.map((prop, key) => {
            return <NavItem className={'d-flex flex-column cursor-pointer'} key={`${prop.key}${prop.value}`}>
                 <NavLink  active={true}  onClick={()=>setActiveTab(prop.value)}
                             className={'header-nav-text-style'}><span style={{color: 'white',fontSize: isSmall ? '14px' :'16px'}}>{prop.key}</span></NavLink>
                 {authState.activeMenu === prop.value && <Line/> }
             </NavItem>
         });
     }
     const setActiveTab = (val) => {
         dispatch(actions.setActiveMenu(val))
     }
    const createLinks = routes => {
        return routes.map((prop, key) => {
            if (prop.hide) {
                if (hasRole(authState.roles, prop.hide)) {
                    return null
                }
            }
            if (authState.permissions && authState.routeCan && authState.roles) {
                if (prop.permission === 'hidden') {
                    return null
                }
                if (prop.permission) {
                    if (!hasPermission(authState.permissions, prop.permission)) {
                        return null
                    }
                }


                if (prop.role) {
                    if (!hasRole(authState.roles, prop.role)) {
                        return null
                    }
                }

                if (prop.can) {
                    if (!hasCan(authState.routeCan, prop.can)) {
                        return null
                    }
                }

                if (prop.authUserTeam === true) {
                    if (!authState.authUserTeam) {
                        return null
                    }
                } else if (prop.authUserTeam === false) {
                    if (authState.authUserTeam) {
                        return null
                    }
                }

                if (prop.name !== 'NavHidden') {
                    if (prop.redirect) {
                        return null;
                    }
                    return (
                        <NavItem className={'d-flex flex-column cursor-pointer'} key={`${prop.layout}${prop.path}`}>
                            <NavLink  active={true}  to={prop.layout + prop.path}
                                      tag={NavLinkRRD}  className={'header-nav-text-style'}><span style={{color: 'white',fontSize: '16px'}}>{prop.name}</span></NavLink>
                            {window.location.pathname === `${prop.layout}${prop.path}` && <Line/> }
                        </NavItem>
                    );
                }
            }
        });
    };
    const toggle = () => (setOpen(!isOpen))

    return (
        <>
            {isSmall ?
                <>
                    {
                        authState.activeSubmenusList.length ?
                        <div style={{backgroundColor: '#ec6409'}}>
                            <Navbar color="#ec6409" expand="md">
                                <Collapse isOpen={true} navbar className={'justify-content-between'}>
                                    <Nav>
                                        {createSubmenus(authState.activeSubmenusList)}
                                    </Nav>
                                </Collapse>
                            </Navbar>
                        </div> : <></>
                    }
                </>
                :
                show ?
                        <div style={{backgroundColor: '#ec6409'}}>
                            <Navbar className={'pb-0 pt-0'} color="#ec6409" expand="md">
                                <NavbarToggler onClick={toggle}/>
                                <Collapse isOpen={true} navbar className={'justify-content-between'}>
                                    {authState.activeSubmenusList.length ?
                                        <Nav>
                                            {createSubmenus(authState.activeSubmenusList)}

                                        </Nav>
                                        :
                                        <Nav className={'justify-content-around cursor-pointer'}>
                                            {createLinks(routes)}
                                        </Nav>
                                    }
                                    <div>
                                        <AdminNavbar
                                            theme={getNavbarTheme}
                                            sidenavOpen={true}
                                            brandText={getBrandText(location.pathname)}
                                        />
                                    </div>
                                </Collapse>
                            </Navbar>
                        </div> : <></>
            }
        </>
    )
}