import * as actionTypes from './actions';
import produce from 'immer';

const initialState = {
  authUserTeam: {},
  filter: {},
  leads: {},
  leadsStatuses: {},
  leadsTypes: {},
  getLeadError: false,
  getLeadSuccess: false,
  getLeadErrorMessage: '',
  deleteLeadError: false,
  deleteLeadSuccess: false,
  deleteLeadErrorMessage: '',
  wonLeadError: false,
  wonLeadSuccess: false,
  wonLeadErrorMessage: '',
  lostLeadError: false,
  lostLeadSuccess: false,
  lostLeadErrorMessage: '',
};

const leadState = produce((draft = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_LEADS_REQUEST:
      draft.getLeadError = false;
      draft.getLeadSuccess = false;
      draft.getLeadErrorMessage = '';
      break;
    case actionTypes.GET_LEADS_SUCCESS:
      draft.getLeadError = false;
      draft.getLeadSuccess = true;
      draft.getLeadErrorMessage = '';
      draft.authUserTeam = action.authUserTeam;
      draft.filter = action.filter;
      draft.leads = action.leads;
      draft.leadsStatuses = action.leadsStatuses;
      draft.leadsTypes = action.leadsTypes;
      break;
    case actionTypes.GET_LEADS_ERROR:
      draft.getLeadError = true;
      draft.getLeadSuccess = false;
      draft.getLeadErrorMessage = action.payload.message;
      break;
    case actionTypes.DELETE_LEAD_REQUEST:
      draft.deleteLeadError = false;
      draft.deleteLeadSuccess = false;
      draft.deleteLeadErrorMessage = '';
      break;
    case actionTypes.DELETE_LEAD_SUCCESS:
      draft.deleteLeadError = false;
      draft.deleteLeadSuccess = true;
      draft.deleteLeadErrorMessage = '';
      draft.leads = { ...action.data };
      break;
    case actionTypes.DELETE_LEAD_ERROR:
      draft.deleteLeadError = true;
      draft.deleteLeadSuccess = false;
      draft.deleteLeadErrorMessage = action.payload.message;
      break;
    case actionTypes.WON_LEAD_REQUEST:
      draft.wonLeadError = false;
      draft.wonLeadSuccess = false;
      draft.wonLeadErrorMessage = '';
      break;
    case actionTypes.WON_LEAD_SUCCESS:
      draft.wonLeadError = false;
      draft.wonLeadSuccess = true;
      draft.wonLeadErrorMessage = '';
      draft.leads = { ...action.data.leads };
      break;
    case actionTypes.WON_LEAD_ERROR:
      draft.wonLeadError = true;
      draft.wonLeadSuccess = false;
      draft.wonLeadErrorMessage = action.payload.message;
      break;
    case actionTypes.LOST_LEAD_REQUEST:
      draft.lostLeadError = false;
      draft.lostLeadSuccess = false;
      draft.lostLeadErrorMessage = '';
      break;
    case actionTypes.LOST_LEAD_SUCCESS:
      draft.lostLeadError = false;
      draft.lostLeadSuccess = true;
      draft.lostLeadErrorMessage = '';
      draft.leads = { ...action.data.leads };
      break;
    case actionTypes.LOST_LEAD_ERROR:
      draft.lostLeadError = true;
      draft.lostLeadSuccess = false;
      draft.lostLeadErrorMessage = action.payload.message;
      break;
    default:
      return draft;
  }
});

export default leadState;
