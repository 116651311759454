import React, { useContext } from 'react';
import * as constants from 'constants/index.js';
import moment from 'moment';

import InputCustom from 'views/pages/components/InputCustom';
import { InputKeySelect } from 'views/pages/components/InputSelect';
import TableCustom from 'views/pages/components/TableCustom';
import { PaymentContext } from '../context';

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
} from "reactstrap";
import {ButtonContainer} from "@pages/reusable-components/styled-components";

export default function Payment() {

  const context = useContext(PaymentContext);

  const {
    history,
    deal, setDeal,
    hasBeenGenerated, setHasBeenGenerated,
    isPaymentActive, setIsPaymentActive,
    payments, setPayments,
    minDate, setMinDate,
    settings,
    hasXeroCustomer,
    paymentDescriptors,
    paymentOnlineProcessLink,
    paymentMethods,
    paymentIntervals,
    paymentProcessTypes,
    paymentStatuses,
    totalCollected,
    isAbleToCreateDeals,
    paymentChangesHistory,
    addCustomInvoicePayment,
    customPaymentElement, setCustomPaymentElement,
    preferences,
    loading,
    delay,
    saveButtonDisable, setSaveButtonDisable,
    confirmRescheduleModal, setConfirmRescheduleModal,
    flag, setFlag,
    errors,
    message,
    changeMinDate,
    viewChangeDetail,
    paymentScheduleDetails,
    regeneratePaymentSchedule,
    getHistory,
    goDeal,
    resetPaymentSchedule,
    paymentShow,
    removePayment,
    showInvoice,
  } = context;

  const createNewPayment = () => {
    history.push(`/admin/deals/${deal.id}/payment/new`)
  }

  return (
    <>
      <Row>
        <Col xl="12">
          <Card>
            <CardHeader>
              <div className="d-flex justify-content-between">
                <div>
                  <h2 className="title mb-0 main-color">{`All payments associated to current deal`}</h2>
                </div>
                <ButtonContainer className="h-50"  type="button" onClick={() => goDeal()}>Back to deal</ButtonContainer>
              </div>
            </CardHeader>
            <CardBody>
              <Row className="py-3">
                <Col md={3}>
                  <h2>Contract Interval</h2>
                </Col>
                <Col md={9}>
                  <h3 className="text-danger">
                    {
                      deal.delivery_date ?
                        `${constants.formatDateShortGB(deal.delivery_date)} - 
                         ${constants.formatDateShortGB(moment(deal.delivery_date).add(deal.term, deal.interval + 's'))}`
                        :
                        ''
                    }
                  </h3>
                </Col>
              </Row>
              <Row className="py-3">
                <Col md={3}>
                  <small className="text-uppercase font-weight-bold">Term</small>
                </Col>
                <Col md={9}>
                  <h3 className="heading">
                    {deal.term && deal.interval && `${deal.term} ${deal.interval}s`}
                  </h3>
                </Col>
              </Row>
              <Row className="py-3">
                <Col md={3}>
                  <small className="text-uppercase font-weight-bold">Payment Start Date</small>
                </Col>
                <Col md={9}>
                  <h3 className="heading">
                    {deal.delivery_date && constants.formatDateShortGB(deal.delivery_date)}
                  </h3>
                </Col>
              </Row>
              <Row className="py-3">
                <Col md={12} sm={12}>
                  <h3 className="text-uppercase font-weight-bold" style={{ cursor: 'pointer' }} onClick={() => getHistory()}>View history</h3>
                </Col>
                <Col md={12} sm={12}>
                  <TableCustom
                    loading={false}
                    items={paymentChangesHistory}
                    keyField={`payment_amount`}
                    searchField={false}
                    columns={[
                      {
                        dataField: "payment_amount",
                        text: "Amount",
                        sort: true
                      },
                      {
                        dataField: "payment_term",
                        text: "Term",
                        sort: true,
                        formatter: (rowContent, row) => {
                          return `${row.payment_term} ${row.payment_term_interval}`;
                        }
                      },
                      {
                        dataField: "payment_frequency",
                        text: "Frequency",
                        sort: true,
                        formatter: (rowContent, row) => {
                          return `${row.payment_frequency} ${row.payment_frequency_interval}`;
                        }
                      },
                      {
                        dataField: "payment_preference",
                        text: "Preference",
                        sort: true,
                        formatter: (rowContent, row) => {
                          return row.payment_preference ? preferences[row.payment_preference] : '-';
                        }
                      },
                      {
                        dataField: "owner",
                        text: "Created By",
                        sort: true,
                        formatter: (rowContent, row) => {
                          return row.owner.name ? row.owner.name : '-';
                        }
                      },
                      {
                        dataField: "applying_from",
                        text: "Apply From(Next Payment)",
                        sort: true,
                        formatter: (rowContent, row) => {
                          return row.applying_from ? constants.formatDateShortGB(row.applying_from) : '';
                        }
                      },
                      {
                        dataField: "created_at",
                        text: "Created At",
                        sort: true,
                        formatter: (rowContent, row) => {
                          return row.created_at ? constants.formatDateShortGB(row.created_at) : '';
                        }
                      }
                    ]}
                  />
                </Col>
              </Row>
            </CardBody>
            {deal && deal.deal_status == 'delivered' &&
              <CardBody>
                <Row>
                  <Col md={6} sm={6}>
                    <InputCustom
                      type={`number`}
                      label={`Payment frequency`}
                      value={deal && deal.payment_frequency ? deal.payment_frequency : ''}
                      onChange={(e) => {
                        setDeal({
                          ...deal,
                          payment_frequency: e.target.value,
                        })
                        setFlag(true)
                      }}
                    />
                  </Col>
                  <Col md={6} sm={6}>
                    <InputKeySelect
                      label={`Payment Interval`}
                      value={deal && deal.payment_frequency_interval ? deal.payment_frequency_interval : ''}
                      options={paymentIntervals}
                      onChange={(e) => {
                        setDeal({
                          ...deal,
                          payment_frequency_interval: e.target.value,
                        })
                        setFlag(true)
                      }}
                      defaultOption={true}
                    />
                  </Col>
                  <Col md={6} sm={6}>
                    <InputCustom
                      type={`number`}
                      label={`Deal Term`}
                      value={deal && deal.term ? deal.term : ''}
                      onChange={(e) => {
                        setDeal({
                          ...deal,
                          term: e.target.value,
                        })
                        setFlag(true)
                      }}
                    />
                  </Col>
                  <Col md={6} sm={6}>
                    <InputKeySelect
                      label={`Term Interval`}
                      value={deal && deal.interval ? deal.interval : ''}
                      options={{ week: 'Week(s)', month: 'Month(s)' }}
                      onChange={(e) => {
                        setDeal({
                          ...deal,
                          interval: e.target.value,
                        })
                        setFlag(true)
                      }}
                      defaultOption={true}
                    />
                  </Col>
                  <Col md={12} className="mt-2">
                    <Row>
                      <Col md={3}>
                        <h4 className="text-uppercase font-weight-bold">No of collected rentals</h4>
                      </Col>
                      <Col md={9}>
                        <h3 className="heading">
                          {payments && payments.filter(item => item.invoice?.id && item.payment_descriptor === 'deal').length}
                        </h3>
                      </Col>
                      <hr />
                    </Row>
                  </Col>
                  <Col md={4} sm={6}>
                    <InputCustom
                      type={`number`}
                      label={`Unit payment`}
                      value={deal && deal.monthly_payment ? deal.monthly_payment : ''}
                      onChange={(e) => {
                        setDeal({
                          ...deal,
                          monthly_payment: e.target.value,
                        })
                        setFlag(true)
                      }}
                    />
                  </Col>
                  <Col md={4} sm={6}>
                    <InputCustom
                      type={`date`}
                      label={`Next payment`}
                      value={deal && deal.next_payment ? deal.next_payment : ''}
                      min={minDate ? constants.formatDate(minDate) : ''}
                      onChange={(e) => {
                        setDeal({
                          ...deal,
                          next_payment: e.target.value,
                        })
                        setFlag(true)
                      }}
                      invalid={errors && errors.next_payment ? true : false}
                      errorMessage={errors && errors.next_payment ? errors.next_payment : ''}
                    />
                  </Col>
                  <Col md={4} sm={6}>
                    <InputCustom
                      type={`number`}
                      label={`No of rentals you want to generate`}
                      value={deal && deal.no_of_rental_payments ? deal.no_of_rental_payments : ''}
                      onChange={(e) => {
                        setDeal({
                          ...deal,
                          no_of_rental_payments: parseInt(e.target.value),
                        })
                        setFlag(true)
                      }}
                    />
                  </Col>
                </Row>
                {isAbleToCreateDeals && <>
                  {viewChangeDetail && <Row>
                    <Col md={12}>
                      {message && <Row className="py-3">
                        <Col md={3}>
                          <small className="text-uppercase font-weight-bold">Notice</small>
                        </Col>
                        <Col md={9}>
                          <h3 className="text-warning heading mb-0">{message}</h3>
                        </Col>
                      </Row>}
                      <Row className="py-3">
                        <Col md={12}>
                          <label className="form-control-label" htmlFor="consent">
                            {`I understand and want to proceed further with payments regeneration!`}
                          </label>
                          <div>
                            <label className="custom-toggle">
                              <input
                                type="checkbox"
                                id="consent"
                                checked={deal.consent == 1 ? true : false}
                                onChange={() =>
                                  setDeal({
                                    ...deal,
                                    consent: deal.consent == 1 ? undefined : 1
                                  })
                                }
                              />
                              <span
                                className="custom-toggle-slider rounded-circle"
                                data-label-off="No"
                                data-label-on="Yes"
                              />
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>}
                  <Row>
                    <Col md={12}>
                      <h3 className="text-info">Click save to regenerate the payment schedule</h3>
                      <div>
                        <Button
                          className="w-100"
                          color="success"
                          type="button"
                          disabled={saveButtonDisable}
                          onClick={() => regeneratePaymentSchedule()}
                        >Save</Button>
                      </div>
                      <div className="mt-3">
                        <Button className="w-100" color="white" type="button" onClick={() => resetPaymentSchedule()}>Reset</Button>
                      </div>
                    </Col>
                  </Row></>}
              </CardBody>}
            {isPaymentActive && hasBeenGenerated && <CardBody>
              <Row>
                <Col md={3} sm={4}>
                  <h3>Total Collected</h3>
                </Col>
                <Col md={9} sm={8}>
                  <h4>{totalCollected}</h4>
                </Col>
              </Row>
            </CardBody>}
            {payments &&
                <CardBody>
                  <Row>
                    <Col md={3} sm={4}>
                      <h3 className="font-weight-bold">Remained payments</h3>
                    </Col>
                    <Col md={9} sm={8}>
                      <h4>{payments.filter(item => !item.invoice && item.payment_descriptor == 'deal').length}</h4>
                    </Col>
                  </Row>
                </CardBody>
              }
            <CardBody>
              <Row>
                <Col md={2} sm={4}>
                  <Button
                    className="w-100"
                    color="warning"
                    disabled={Object.entries(settings).length === 0 || !hasXeroCustomer}
                    type="button"
                    onClick={() => createNewPayment()}
                  >+ Payment</Button>
                </Col>
              </Row>
            </CardBody>
            {!hasBeenGenerated && <Row>
              <Col md={12}>
                <div className="alert main-background alert-dismissible fade show">
                  <span style={{color: '#ffffff'}}>{`No payment recorded yet`}!</span>
                </div>
              </Col>
            </Row>}
            <CardBody>
              <TableCustom
                loading={loading}
                delay={delay}
                items={payments}
                keyField={`payment_no`}
                searchField={false}
                columns={[
                  {
                    dataField: "amount",
                    text: "Amount",
                    sort: true,
                    formatter: (rowContent, row) => {
                      return parseFloat(row.payment_reference ? row.payment_reference.amount : row.amount);
                    }
                  },
                  {
                    dataField: "amount",
                    text: "total",
                    sort: true,
                    formatter: (rowContent, row) => {
                      return row.payment_reference?.total_amount ? row.payment_reference.total_amount : (parseFloat(row.amount) + parseFloat(row.amount * (row.payment_descriptor !== 'reservation_fee' ? 0.2 : 0)));
                    }
                  },
                  {
                    dataField: "collected",
                    text: "collected",
                    sort: true,
                    formatter: (rowContent, row) => {
                      return row.payment_reference?.amount_collected ?? 0;
                    }
                  },
                  {
                    dataField: "due",
                    text: "due",
                    sort: true,
                    formatter: (rowContent, row) => {
                      return row.payment_reference ? (parseFloat(row.payment_reference.total_amount ?? (parseFloat(row.amount) + parseFloat(row.amount * (row.payment_descriptor !== 'reservation_fee' ? 0.2 : 0)))) - parseFloat(row.payment_reference.amount_collected ?? 0)) : (parseFloat(row.amount) + parseFloat(row.amount * (row.payment_descriptor !== 'reservation_fee' ? 0.2 : 0)));
                    }
                  },
                  {
                    dataField: "charge_at",
                    text: "Payment Due",
                    sort: true,
                    formatter: (rowContent, row) => {
                      return row.payment_reference ? constants.formatDateShortGB(row.payment_reference.charge_date) : constants.formatDateShortGB(row.scheduled_at);
                    }
                  },
                  {
                    dataField: "payment_descriptor",
                    text: "Payment descriptor",
                    sort: true,
                    formatter: (rowContent, row) => {
                      return paymentDescriptors[row.payment_descriptor];
                    }
                  },
                  {
                    dataField: "invoice",
                    text: "Invoice",
                    sort: true,
                    formatter: (rowContent, row) => {
                      return (
                        <>
                          {row.invoice && row.invoice.id ? <i
                            className="fas fa-file-download"
                            style={{ cursor: 'pointer', marginLeft: "10px" }}
                            onClick={() => showInvoice(row)}
                          ></i>
                            : "No invoice"
                          }
                        </>
                      )
                    }
                  },
                  {
                    dataField: "",
                    text: "Action",
                    formatter: (rowContent, row) => {
                      return (
                        <div>
                          {row.id && row.scheduled_at &&
                            <>
                              <i
                                className="far fa-trash-alt ml-1"
                                style={{ cursor: 'pointer', }}
                                onClick={() => removePayment(row)}
                              ></i>
                              <i
                                className="far fa-file-alt ml-1"
                                style={{ cursor: 'pointer', }}
                                onClick={() => paymentScheduleDetails(row.id)}
                              ></i>
                            </>
                          }
                        </div>
                      )
                    }
                  }
                ]}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal
        isOpen={confirmRescheduleModal}
        toggle={() => setConfirmRescheduleModal(false)}
        className="modal-dialog-centered"
      >
        <div className="modal-body my-2">
          <h1 className="text-center my-5">{`Payment Schedule Change`}</h1>
          <h4 className="text-center text-info">Check the table below to see how are the changes applying in real time</h4>
        </div>
        <div className="text-center my-4">
          <Button
            className="w-25"
            color="primary"
            type="button"
            onClick={() => {
              setConfirmRescheduleModal(false)
            }}
          >
            {`OK!`}
          </Button>
        </div>
      </Modal>
    </>
  )
}