import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import { useDropzone } from "react-dropzone";
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';
import classnames from 'classnames';
import actions from 'actions';
import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import InputCustom from 'views/pages/components/InputCustom';
import {
  InputKeySelect
} from 'views/pages/components/InputSelect';
import TableCustom from 'views/pages/components/TableCustom';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
} from "reactstrap";
import {ButtonContainer} from "@pages/reusable-components/styled-components";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out"
};

const activeStyle = {
  borderColor: "#2196f3"
};

const acceptStyle = {
  borderColor: "#00e676"
};

const rejectStyle = {
  borderColor: "#ff1744"
};

export default function CustomerEdit() {
  const { id } = useParams();
  const location = useLocation();
  const notificationAlert = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const {activeMenu} = useSelector((state) => state.getState);
  const [tab, setTab] = useState('info');
  const [token, setToken] = useState('');
  const [businessTypes, setBusinessTypes] = useState([]);
  const [lookupAddresses, setLookupAddresses] = useState([]);
  const [companyTypes, setCompanyTypes] = useState([]);
  const [counties, setCounties] = useState([]);
  const [timeAtAddressType, setTimeAtAddressType] = useState('');
  const [titles, setTitles] = useState('');
  const [showBackButton,setShowBackButton] = useState(false)
  const [documents, setDocuments] = useState([]);
  const [availableDocumentsUploadTypes, setAvailableDocumentsUploadTypes] = useState('');

  const [fileLists, setFileLists] = useState([]);
  const [fileTypes, setFileTypes] = useState([]);

  const [confirmShow, setConfirmShow] = useState(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState('');

  const [filter, setFilter] = useState('');

  const onDrop = useCallback(acceptedFiles => {
    let newFileTypes = fileTypes;
    acceptedFiles.map((item, index) => {
      newFileTypes[item.name] = "photo_id";
    })
    setFileTypes(newFileTypes);
    setFileLists(prev => [...prev, ...acceptedFiles]);
  })

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });

  const [errors, setErrors] = useState('');

  const [customer, setCustomer] = useState('');

  const [loadingDocuments, setLoadingDocuments] = useState(true);

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/customer/${id}/edit`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        const data = response.data
        setCustomer({
          ...data.data.item,
          dob: data.data.item.dob ? constants.formatDate(data.data.item.dob) : ''
        });
        setBusinessTypes(data.data.businessTypes);
        setCompanyTypes(data.data.companyTypes);
        setCounties(data.data.counties.data);
        setTitles(data.data.titles);
        setTimeAtAddressType(data.data.timeAtAddressType);
        dispatch(actions.getNotifications(grs_token));
      } catch (error) {
        if (axios.isCancel(error)) {
        } else {
          notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
        }
      }
    }
    const tabs = [
      {
        value: 'info',
        key:'Customer Info',
      },
      {
        value: 'documents',
        key:'Documents Management',
      },
      {
        value: 'tasks',
        key:'Tasks',
        action: true
      },
      {
        value: 'notes',
        key:'Notes',
        action: true
      },
    ]
    dispatch(actions.setMenusList(tabs))
    dispatch(actions.setMenuName('Customers'))
    dispatch(actions.setActiveMenu('info'))
    fetchData()

    if (location.state && location.state.filter) {
      setFilter(location.state.filter);
    }

    return () => {
      source.cancel()
    }
  }, [])

  useEffect(() => {
    setShowBackButton(!!tab)
    if (tab == 'documents') {
      axios({
        method: 'GET',
        url: constants.API_URL + '/customer/' + id + '/documents',
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
        .then(response => {
          if (response.status == 200) {
            let data = response.data;
            setDocuments(data.data.documents);
            setFileLists([]);
            setFileTypes([]);
            setAvailableDocumentsUploadTypes(data.data.availableDocumentsUploadTypes);
            setTimeout(() => {
              setLoadingDocuments(false);
            }, 1000);
          }
        })
        .catch(error => {
          setLoadingDocuments(false);
          notify('warning', 'Warning', error.response.statusText);
        })
    }
  }, [tab])
  const sendPasswordLink = () => {
    axios({
      method: 'GET',
      url: constants.API_URL + '/customer/get-link/' + id,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: {
        email: customer.company_name,
        id: customer.id,
      }
    })
  }
  const updateCustomer = () => {
    axios({
      method: 'PUT',
      url: constants.API_URL + '/customer/' + id,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: {
        ...customer,
        business_activity_id: customer.business_activity_id ? customer.business_activity_id : customer.company.business_activity_id,
        company_type_id: customer.company_type_id ? customer.company_type_id : customer.company.company_type_id,
        company_name: customer.company_name ? customer.company_name : customer.company.name,
        business_address: customer.business_address ? customer.business_address : customer.company.address,
        business_phone_number: customer.business_phone_number ? customer.business_phone_number : customer.company.phone_number,
        postal_code: customer.postal_code ? customer.postal_code : undefined
      }
    })
      .then(response => {
        if (response.status == 200) {
          let data = response.data;
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setErrors('');
        if (error.response.status == 422) {
          let errors = error.response.data.errors;
          setErrors(errors);
        }
        notify('warning', 'Warning', error.response.statusText);
      })
  }

  const goList = () => {
    dispatch(actions.setMenusList([]))
    dispatch(actions.setMenuName(null))
    dispatch(actions.setActiveMenu(null))
    history.push({
      pathname: '/admin/customer',
      state: {
        filter: filter
      }
    });
  }

  const setSendType = (e, index) => {
    let newFileTypes = fileTypes;
    let key = fileLists[index].name;
    newFileTypes[key] = e.target.value;
    setFileTypes(newFileTypes);
  };

  const uploadDocument = () => {
    let formData = new FormData();
    for (let index = 0; index < fileLists.length; index++) {
      const element = fileLists[index];
      formData.append(`file[${index}]`, element);
    }
    Object.keys(fileTypes).map(key => {
      formData.append(`type[${key}]`, fileTypes[key]);
    })

    axios({
      method: 'POST',
      url: constants.API_URL + '/customer/' + id + '/documents',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: formData
    })
      .then(response => {
        if (response.status == 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setDocuments(data.data);
          setFileLists([]);
          setFileTypes([]);
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setErrors('');
        console.error('error :: ', error);
        if (error.response && error.response.status == 422) {
          let errors = error.response.data.errors;
          setErrors(errors);
        }
        notify('warning', 'Warning', error.response && error.response.statusText ? error.response.statusText : 'Error occur.');
      })
  }

  const deleteDocument = () => {
    axios({
      method: 'GET',
      url: constants.API_URL + '/customer/' + id + '/remove/' + selectedDocumentId,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(response => {
        setConfirmShow(false);
        if (response.status == 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setDocuments(data.data);
          setSelectedDocumentId('');
          setFileLists([]);
          setFileTypes([]);
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setConfirmShow(false);
        console.error('error :: ', error);
        notify('warning', 'Warning', error.response && error.response.statusText ? error.response.statusText : 'Error occur.');
      })
  }

  const viewDocument = (row) => {
    axios({
      method: 'GET',
      url: constants.API_URL + '/customer/' + id + '/view/' + row.id,
      headers: {
        Authorization: 'Bearer ' + token
      },
      responseType: 'blob'
    })
      .then(response => {
        if (response.status == 200) {
          let data = response.data;
          const disposition = response.request.getResponseHeader('Content-Disposition');
          let fileName = "";
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '');
          }
          const blob = new Blob([data])
          const downloadUrl = URL.createObjectURL(blob)
          let a = document.createElement("a");
          a.href = downloadUrl;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        notify('warning', 'Warning', error.response && error.response.statusText ? error.response.statusText : 'Error occur.');
      })
  }

  const goSub = (subject) => {
    switch (subject) {
      case 'tasks':
        history.push({
          pathname: '/admin/customer/' + id + '/tasks',
          state: {
            filter: filter
          }
        });
        break;

      case 'notes':
        history.push({
          pathname: '/admin/customer/' + id + '/notes',
          state: {
            filter: filter
          }
        });
        break;

      default:
        break;
    }
  }

  const setSelectedAddress = (address) => {
    const a = address.FormattedAddressLines
    const addressParts = address.ComponentParts;

    if (a) {
      const addr = `${a.Street ? a.Street : ''}${a.Premises ? `, ${a.Premises}` : ''}${a.PostTown ? `, ${a.PostTown}` : ''}${a.Organisation ? `, ${a.Organisation}` : ''}${a.Locality ? `, ${a.Locality}` : ''}${a.County ? `, ${a.County}` : ''}`

      setCustomer({
        ...customer,
        address: addr,
        street: addressParts.Street,
        city: a.PostTown,
        building: `${addressParts.BuildingName ? addressParts.BuildingName + (addressParts.BuildingNumber ? ',' : '') : ''}${addressParts.BuildingNumber ? ' ' + addressParts.BuildingNumber : ''}${addressParts.SubBuildingName ? ', ' + addressParts.SubBuildingName : ''}`
      })
    }
  }

  const getAddressByPostcode = (postcode) => {
    axios({
      method: 'GET',
      url: `${constants.UK_VEHICLE_DATA_API_URL}/PostcodeLookup?v=2&api_nullitems=1&auth_apikey=${constants.UK_VEHICLE_DATA_API_KEY}&key_POSTCODE=${postcode}`,
    })
      .then(response => {
        const data = response.data
        console.log('response data :: ', data)

        if (data.Response) {

          if (data.Response.StatusCode === 'Success') {

            const addressList = data.Response.DataItems.AddressDetails.AddressList

            if (addressList.length == 1) {
              setCustomer({
                ...customer,
                postal_code: postcode
              });

              setErrors({
                ...errors,
                postal_code: ''
              })

              setSelectedAddress(addressList[0]);
            } else if (addressList.length > 1) {
              setCustomer({
                ...customer,
                postal_code: postcode
              })

              setErrors({
                ...errors,
                postal_code: ''
              })

              setLookupAddresses(addressList);
            }
          } else {
            setErrors({
              ...errors,
              postal_code: data.Response.StatusMessage
            })

            setCustomer({
              ...customer,
              postal_code: postcode,
              address: ''
            })
          }
        }
      })
      .catch(error => {
        console.error('error :: ', error)
      })
  }
   useEffect(()=>{
     if (activeMenu === 'tasks' || activeMenu === 'notes') {
       goSub(activeMenu)
     }
     setTab(activeMenu)
   },[activeMenu])
  return (
    <>
      <CardsHeader name="Customers" parentName="Customer Management" showBackButton={showBackButton} backButtonText={'Back to Customers'} redirect={goList} currentName="Edit customer" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">

            <Card>
              {tab == 'info' && <CardBody>
                <Card>
                  <CardHeader>
                    <Row className={'align-items-center justify-content-between'}>
                      <Col>
                        <h1 className="mb-0">Customer management</h1>
                      </Col>
                  <Col>
                    <Row className={'align-items-center justify-content-end'}>
                      <label className="form-control-label mr-5">Send password link</label>
                      <ButtonContainer reverse onClick={() => sendPasswordLink()}  >
                        Send
                      </ButtonContainer>
                    </Row>
                  </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className={'mb-4'} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                      <div>
                          <h2>Personal information</h2>
                      </div>
                    </div>
                    <Row className={'phone-container'}>
                      <Col md={4} sm={6}>
                        <InputKeySelect
                            newLabel={`Title`}
                            options={titles}
                            value={customer && customer.title ? customer.title : ''}
                            onChange={e => setCustomer({
                              ...customer,
                              title: e.target.value,
                              attention_to: `${e.target.value ? `${titles[e.target.value]} ` : ''}${customer.first_name ? `${customer.first_name}` : ''}${customer.last_name ? ` ${customer.last_name}` : ''}`
                            })}
                            defaultOption={true}
                            invalid={errors && errors.title ? true : false}
                            errorMessage={errors.title}
                        />

                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-50">First Name</label>
                          <Input
                              placeholder="First Name"
                              type="text"
                              value={customer ? customer.first_name : ''}
                              onChange={(e) => setCustomer({
                                ...customer,
                                first_name: e.target.value,
                                full_name: `${e.target.value ? ` ${e.target.value}` : ''}${customer.last_name ? ` ${customer.last_name}` : ''}`,
                                attention_to: `${customer.title ? `${titles[customer.title]} ` : ''}${e.target.value ? `${e.target.value}` : ''}${customer.last_name ? ` ${customer.last_name}` : ''}`
                              })}
                              invalid={errors && errors.first_name ? true : false}
                          />
                          <div className="invalid-feedback">
                            Please provide the first name.
                          </div>
                        </FormGroup>
                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-50">Last Name</label>
                          <Input
                              placeholder="Last Name"
                              type="text"
                              value={customer ? customer.last_name : ''}
                              onChange={(e) => setCustomer({
                                ...customer,
                                last_name: e.target.value,
                                full_name: `${customer.first_name ? ` ${customer.first_name}` : ''}${e.target.value ? ` ${e.target.value}` : ''}`,
                                attention_to: `${customer.title ? `${titles[customer.title]} ` : ''}${customer.first_name ? `${customer.first_name}` : ''}${e.target.value ? ` ${e.target.value}` : ''}`
                              })}
                              invalid={errors && errors.last_name ? true : false}
                          />
                          <div className="invalid-feedback">
                            Please provide a full name.
                          </div>
                        </FormGroup>
                        <InputCustom
                            type={`date`}
                            newLabel={`Date of Birth`}
                            value={customer && customer.dob ? customer.dob : ''}
                            onChange={e => setCustomer({
                              ...customer,
                              dob: e.target.value
                            })}
                            invalid={errors && errors.dob ? true : false}
                            errorMessage={errors.dob}
                        />
                      </Col>
                      <Col md={4} sm={6}>
                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-50">Attention To</label>
                          <Input
                            placeholder="Attention To"
                            type="text"
                            value={customer ? customer.attention_to : ''}
                            onChange={(e) => setCustomer({
                              ...customer,
                              attention_to: e.target.value
                            })}
                            invalid={errors && errors.attention_to ? true : false}
                          />
                          <div className="invalid-feedback">
                            Please provide a full name.
                          </div>
                        </FormGroup>
                        <FormGroup className={'d-flex align-items-center'}>
                            <label className="form-control-label w-50">Email Address</label>
                            <Input
                                placeholder="Email Address"
                                type="text"
                                value={customer ? customer.email : ''}
                                onChange={(e) => setCustomer({
                                  ...customer,
                                  email: e.target.value
                                })}
                                invalid={errors && errors.email ? true : false}
                            />
                            <div className="invalid-feedback">
                              Please provide a email.
                            </div>
                          </FormGroup>
                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-50">Phone Number</label>
                          <Input
                              placeholder="Phone Number"
                              type="text"
                              value={customer ? customer.mobile_number : ''}
                              onChange={(e) => setCustomer({
                                ...customer,
                                mobile_number: e.target.value
                              })}
                              invalid={errors && errors.mobile_number ? true : false}
                          />
                          <div className="invalid-feedback">
                            Please provide a phone number.
                          </div>
                        </FormGroup>
                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-50">Account Number</label>
                          <Input
                              placeholder="Account Number"
                              type="text"
                              value={customer ? customer.account_number : ''}
                              onChange={(e) => setCustomer({
                                ...customer,
                                account_number: e.target.value
                              })}
                              invalid={errors && errors.account_number ? true : false}
                          />
                          <div className="invalid-feedback">
                            Please The account number.
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={4} sm={6}>
                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-50">Full Name</label>
                          <Input
                              placeholder="Full Name"
                              type="text"
                              value={customer ? customer.full_name : ''}
                              disabled={true}
                              onChange={(e) => setCustomer({
                                ...customer,
                                full_name: e.target.value
                              })}
                              invalid={errors && errors.full_name ? true : false}
                          />
                          <div className="invalid-feedback">
                            Please provide the full name.
                          </div>
                        </FormGroup>
                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-50">N.I Number</label>
                          <Input
                            placeholder="N.I Number"
                            type="text"
                            value={customer && customer.national_insurance_number ? customer.national_insurance_number : ''}
                            onChange={(e) => setCustomer({
                              ...customer,
                              national_insurance_number: e.target.value
                            })}
                          />
                        </FormGroup>
                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-50">D/L Number</label>
                          <Input
                              placeholder="D/L Number"
                              type="text"
                              value={customer ? customer.driver_license_number : ''}
                              onChange={(e) => setCustomer({
                                ...customer,
                                driver_license_number: e.target.value
                              })}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm={12} className="mt-3 mb-4">
                        <h2>Address details</h2>
                      </Col>
                      <Col md={4} sm={6}>
                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-50">Postal Code</label>
                          <Input
                            placeholder="Postal Code"
                            type="text"
                            value={customer && customer.postal_code ? customer.postal_code : ''}
                            onChange={(e) => {
                              getAddressByPostcode(e.target.value)
                            }}
                            invalid={errors && errors.postal_code ? true : false}
                          />
                          <div className="invalid-feedback">
                            {errors.postal_code}
                          </div>
                        </FormGroup>
                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-50">Address</label>
                          <Input
                              placeholder="Address"
                              type="text"
                              value={customer ? customer.address : ''}
                              onChange={(e) => setCustomer({
                                ...customer,
                                address: e.target.value
                              })}
                          />
                        </FormGroup>
                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-50">Street</label>
                          <Input
                              placeholder="Street"
                              type="text"
                              value={customer ? customer.street : ''}
                              onChange={(e) => setCustomer({
                                ...customer,
                                street: e.target.value
                              })}
                          />
                        </FormGroup>
                        <InputCustom
                            newLabel={`Previous Address`}
                            value={customer && customer.previous_address ? customer.previous_address : ''}
                            onChange={e => setCustomer({
                              ...customer,
                              previous_address: e.target.value
                            })}
                            invalid={errors && errors.previous_address ? true : false}
                            errorMessage={errors.previous_address}
                        />
                      </Col>
                      <Col md={4} sm={6}>
                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-50">Coresspondent address</label>
                          <Input
                            placeholder="Select the coresspondent address"
                            type="select"
                            onChange={(e) => {
                              let selectedAddress = lookupAddresses.find(item => item.Udprn == e.target.value);
                              setSelectedAddress(selectedAddress);
                            }}
                          >
                            {lookupAddresses.length == 0 && <option>No lookup address to select from</option>}
                            {lookupAddresses.length > 0 &&
                              <>
                                <option>-</option>
                                {lookupAddresses.map((item, index) => {
                                  return <option value={item.Udprn} key={index}>{item.SummaryAddress}</option>;
                                })}
                              </>
                            }
                          </Input>
                        </FormGroup>
                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-50">Alternate Number</label>
                          <Input
                              placeholder="Alternate Number"
                              type="text"
                              value={customer && customer.land_line_number ? customer.land_line_number : ''}
                              onChange={(e) => setCustomer({
                                ...customer,
                                land_line_number: e.target.value
                              })}
                          />
                        </FormGroup>
                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-50">City</label>
                          <Input
                              placeholder="City"
                              type="text"
                              value={customer ? customer.city : ''}
                              onChange={(e) => setCustomer({
                                ...customer,
                                city: e.target.value
                              })}
                          />
                        </FormGroup>
                        <InputKeySelect
                            newLabel={`Time at Address`}
                            options={timeAtAddressType}
                            value={customer && customer.time_at_address ? customer.time_at_address : ''}
                            onChange={e => setCustomer({
                              ...customer,
                              time_at_address: e.target.value
                            })}
                            defaultOption={true}
                            invalid={errors && errors.time_at_address ? true : false}
                            errorMessage={errors.time_at_address}
                        />
                      </Col>
                      <Col md={4} sm={6}>
                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-50"
                                 htmlFor="detail_is_active"
                          >
                            Is active
                          </label>
                          <Row>
                            <Col>
                              <label className="custom-toggle">
                                <input
                                    type="checkbox"
                                    checked={customer && customer.is_active == 1 ? true : false}
                                    onChange={() =>
                                        setCustomer({
                                          ...customer,
                                          is_active: customer.is_active == 1 ? 0 : 1
                                        })
                                    }
                                />
                                <span
                                    className="custom-toggle-slider rounded-circle"
                                    data-label-off="No"
                                    data-label-on="Yes"
                                />
                              </label>
                            </Col>
                          </Row>
                        </FormGroup>
                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-50">Building</label>
                          <Input
                            placeholder="Building"
                            type="text"
                            value={customer ? customer.building : ''}
                            onChange={(e) => setCustomer({
                              ...customer,
                              building: e.target.value
                            })}
                          />
                        </FormGroup>
                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-50">County</label>
                          <Input
                              placeholder="D/L Number"
                              type="select"
                              value={customer ? customer.county_id : ''}
                              onChange={(e) => setCustomer({
                                ...customer,
                                county_id: e.target.value
                              })}
                              invalid={errors && errors.county_id ? true : false}
                          >
                            <option>-</option>
                            {counties.length > 0 && counties.map((item, index) => {
                              return <option value={item.id} key={index}>{item.name}</option>
                            })}
                          </Input>
                          <div className="invalid-feedback">
                            Please provide a county.
                          </div>
                        </FormGroup>
                      </Col>

                      <Col md={4} sm={6}>

                      </Col>
                    </Row>

                  </CardBody>
                </Card>
                <Card>
                  <CardHeader>
                    <h1 className="mb-0">Financial Information</h1>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={4} sm={12}>
                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-50">Bank name</label>
                          <Input
                            placeholder="Bank Name"
                            type="text"
                            value={customer && customer.bank_name ? customer.bank_name : ''}
                            onChange={(e) => setCustomer({
                              ...customer,
                              bank_name: e.target.value
                            })}
                          />
                        </FormGroup>
                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-50">Bank sort code</label>
                          <Input
                              placeholder="Bank sort code"
                              type="text"
                              value={customer && customer.bank_sort_code ? customer.bank_sort_code : ''}
                              onChange={(e) => setCustomer({
                                ...customer,
                                bank_sort_code: e.target.value
                              })}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={4} sm={12}>
                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-50">Bank account name</label>
                          <Input
                            placeholder="Bank account name"
                            type="text"
                            value={customer && customer.bank_account_name ? customer.bank_account_name : ''}
                            onChange={(e) => setCustomer({
                              ...customer,
                              bank_account_name: e.target.value
                            })}
                          />
                        </FormGroup>
                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-50">Bank account number</label>
                          <Input
                              placeholder="Bank account number"
                              type="text"
                              value={customer && customer.bank_account_number ? customer.bank_account_number : ''}
                              onChange={(e) => setCustomer({
                                ...customer,
                                bank_account_number: e.target.value
                              })}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader>
                    <h1 className="mb-0">Company Information</h1>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={4} sm={12}>
                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-50">Business type</label>
                          <Input
                            placeholder="Full Name"
                            type="select"
                            value={customer ? customer.company.business_activity_id : ''}
                            onChange={(e) => setCustomer({
                              ...customer,
                              business_activity_id: e.target.value
                            })}
                          >
                            <option>-</option>
                            {businessTypes.length > 0 && businessTypes.map((item, index) => {
                              return <option value={item.id} key={index}>{item.name}</option>;
                            })}
                          </Input>
                        </FormGroup>
                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-50">Business address</label>
                          <Input
                              placeholder="Business address"
                              type="text"
                              value={customer && customer.company && customer.company.address ? customer.company.address : ''}
                              onChange={(e) => setCustomer({
                                ...customer,
                                business_address: e.target.value,
                                company: {
                                  ...customer.company,
                                  address: e.target.value
                                }
                              })}
                              invalid={errors && errors.business_address ? true : false}
                          />
                          <div className="invalid-feedback">
                            Please provide a business address.
                          </div>
                        </FormGroup>
                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-50">Business phone number</label>
                          <Input
                              placeholder="Business phone number"
                              type="text"
                              value={customer && customer.company && customer.company.phone_number ? customer.company.phone_number : ''}
                              onChange={(e) => setCustomer({
                                ...customer,
                                business_phone_number: e.target.value,
                                company: {
                                  ...customer.company,
                                  phone_number: e.target.value
                                }
                              })}
                              invalid={errors && errors.business_phone_number ? true : false}
                          />
                          <div className="invalid-feedback">
                            Please provide a business phone number.
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={4} sm={12}>
                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-50">Company type</label>
                          <Input
                            placeholder="Full Name"
                            type="select"
                            value={customer ? customer.company.company_type_id : ''}
                            onChange={(e) => setCustomer({
                              ...customer,
                              company_type_id: e.target.value
                            })}
                          >
                            <option>-</option>
                            {companyTypes.length > 0 && companyTypes.map((item, index) => {
                              return <option value={item.id} key={index}>{item.name}</option>;
                            })}
                          </Input>
                        </FormGroup>
                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-50">Company name</label>
                          <Input
                              placeholder="Company name"
                              type="text"
                              value={customer && customer.company && customer.company.name ? customer.company.name : ''}
                              onChange={(e) => setCustomer({
                                ...customer,
                                company_name: e.target.value,
                                company: {
                                  ...customer.company,
                                  name: e.target.value
                                }
                              })}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={4} sm={12}>
                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-50">Insurance company</label>
                          <Input
                            placeholder="Insurance company"
                            type="text"
                            value={customer && customer.insurance_company ? customer.insurance_company : ''}
                            onChange={(e) => setCustomer({
                              ...customer,
                              insurance_company: e.target.value
                            })}
                            invalid={errors && errors.insurance_company ? true : false}
                          />
                          <div className="invalid-feedback">
                            Please provide a insurance company.
                          </div>
                        </FormGroup>
                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-50">Insurance excess</label>
                          <Input
                              placeholder="Insurance excess"
                              type="text"
                              value={customer && customer.insurance_excess ? customer.insurance_excess : ''}
                              onChange={(e) => setCustomer({
                                ...customer,
                                insurance_excess: e.target.value
                              })}
                              invalid={errors && errors.insurance_excess ? true : false}
                          />
                          <div className="invalid-feedback">
                            Please provide a insurance company.
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <div className="d-flex flex-row justify-content-end">
                  <ButtonContainer onClick={() => goList(true)}>Cancel</ButtonContainer>
                  <ButtonContainer reverse onClick={() => updateCustomer()}>Save</ButtonContainer>
                </div>
              </CardBody>}
              {tab == 'documents' && <CardBody>
                <div className="container">
                  <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    <p>Select or drop the documents, then match each of them to the right type</p>
                  </div>
                  <aside>
                    {fileLists.map((item, index) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: '1px solid #CCC',
                            alignItems: 'center',
                            padding: 20,
                          }}
                          key={index}
                        >
                          <Col md={3}>
                            <span>{item.name}</span>
                          </Col>
                          <Col md={3}>
                            <span>{`${item.size} bytes`}</span>
                          </Col>
                          <Col md={2}>
                            {`Document type`}
                          </Col>
                          <Col md={4}>
                            <Input
                              type='select'
                              id={`select_${index}`}
                              onChange={(e) => setSendType(e, index)}
                            >
                              {Object.keys(availableDocumentsUploadTypes).map((key, index) => {
                                return (
                                  <option value={key} key={key}>
                                    {availableDocumentsUploadTypes[key]}
                                  </option>
                                );
                              })}
                            </Input>
                          </Col>
                        </div>
                      )
                    })}
                  </aside>
                </div>
                <div className="d-flex flex-row justify-content-end mt-3">
                  <ButtonContainer onClick={() => goList(true)}>Cancel</ButtonContainer>
                  <ButtonContainer reverse onClick={() => uploadDocument()}>Save</ButtonContainer>
                </div>
                <TableCustom
                  loading={loadingDocuments}
                  items={documents}
                  keyField={`id`}
                  searchField={true}
                  columns={[
                    {
                      dataField: "document_name",
                      text: "Name",
                      sort: true
                    },
                    {
                      dataField: "document_type",
                      text: "Type",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return availableDocumentsUploadTypes[row.category];
                      }
                    },
                    {
                      dataField: "",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            <img className={'cursor-pointer'} onClick={e => {
                              setConfirmShow(true);
                              setSelectedDocumentId(row.id);
                            }}
                            src={require(`assets/images/tableIcons/delete_outline.svg`)} alt=""/>
                            <img className={'cursor-pointer'}
                                 onClick={e => viewDocument(row)}
                                 src={require(`assets/images/tableIcons/visibility.svg`)} alt=""/>
                          </>
                        )
                      }
                    }
                  ]}
                />
                <Modal
                  isOpen={confirmShow}
                  toggle={() => setConfirmShow(false)}
                  className="modal-dialog-centered modal-secondary"
                >
                  <div className="modal-body">
                    {`Are you sure you want to delete this document?`}
                  </div>
                  <div className="modal-footer">
                    <Button
                      className="new-event--add"
                      color="danger"
                      type="button"
                      onClick={() => deleteDocument()}
                    >
                      {`Delete`}
                    </Button>
                    <Button
                      className="ml-auto"
                      color="link"
                      type="button"
                      onClick={() => setConfirmShow(false)}
                    >
                      Cancel
                    </Button>
                  </div>
                </Modal>
              </CardBody>}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}