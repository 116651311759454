import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useDropzone } from "react-dropzone";
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';
import classnames from 'classnames';

import actions from 'actions';
import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';


import InputCustom from 'views/pages/components/InputCustom';
import {
  InputArraySelect,
} from 'views/pages/components/InputSelect';
import InputToggle from 'views/pages/components/InputToggle';
import TableCustom from 'views/pages/components/TableCustom';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
} from "reactstrap";
import {ButtonContainer} from "@pages/reusable-components/styled-components";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out"
};

const activeStyle = {
  borderColor: "#2196f3"
};

const acceptStyle = {
  borderColor: "#00e676"
};

const rejectStyle = {
  borderColor: "#ff1744"
};

export default function CustomerEdit() {
  const { id } = useParams();
  const notificationAlert = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const [tab, setTab] = useState('info');
  const [token, setToken] = useState('');
  const [businessTypes, setBusinessTypes] = useState([]);
  const [companyTypes, setCompanyTypes] = useState([]);
  const [counties, setCounties] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [leadSources, setLeadSources] = useState([]);
  const [regions, setRegions] = useState([]);

  const [documents, setDocuments] = useState([]);
  const [availableDocumentsUploadTypes, setAvailableDocumentsUploadTypes] = useState('');

  const [fileLists, setFileLists] = useState([]);
  const [fileTypes, setFileTypes] = useState([]);

  const [confirmShow, setConfirmShow] = useState(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState('');

  const onDrop = useCallback(acceptedFiles => {
    let newFileTypes = fileTypes;
    acceptedFiles.map((item, index) => {
      newFileTypes[item.name] = "photo_id";
    })
    setFileTypes(newFileTypes);
    setFileLists(prev => [...prev, ...acceptedFiles]);
  })
  
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({onDrop});

  const [errors, setErrors] = useState('');

  const [customer, setCustomer] = useState('');
  
  const [loadingDocuments, setLoadingDocuments] = useState(true);

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  useEffect(() => {
    if (Cookies.get('grs_token')) {
      let grs_token = Cookies.get('grs_token');
      setToken(grs_token);

      axios({
        method: 'GET',
        url: constants.API_URL + '/customer/' + id + '/edit',
        headers: {
          Authorization: 'Bearer ' + grs_token
        }
      })
        .then(response => {
          if (response.status == 200) {
            let data = response.data;
            console.log('response data :: ', data);
            setCustomer({
              ...data.data.item,
              cb_company_id: null,
              dob: data.data.item.dob? constants.formatDate(data.data.item.dob): '',
              cb_dob: data.data.item.cb_dob? constants.formatDate(data.data.item.cb_dob): '',
            });
            setBusinessTypes(data.data.businessTypes);
            setCompanyTypes(data.data.companyTypes);
            setCounties(data.data.counties.data);
            setCompanies(data.data.companies);
            setLeadSources(data.data.leadSources);
            setRegions(data.data.regions);
            dispatch(actions.getNotifications(grs_token));
          }
        })
        .catch(error => {
          console.error('error :: ', error);
          notify('warning', 'Warning', error.response.statusText);
        })
    }
  }, [])

  useEffect(() => {
    if (tab == 'documents') {
      axios({
        method: 'GET',
        url: constants.API_URL + '/customer/' + id + '/documents',
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
        .then(response => {
          if (response.status == 200) {
            let data = response.data;
            console.log('documents response data :: ', data);
            setDocuments(data.data.documents);            
            setFileLists([]);
            setFileTypes([]);
            setAvailableDocumentsUploadTypes(data.data.availableDocumentsUploadTypes);
            setTimeout(() => {
              setLoadingDocuments(false);
            }, 1000);
          }
        })
        .catch(error => {
          setLoadingDocuments(false);
          console.error('error :: ', error);
          notify('warning', 'Warning', error.response.statusText);
        })
    }
  }, [tab])

  const updateCustomer = () => {
    axios({
      method: 'PUT',
      url: constants.API_URL + '/customer/' + id,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: {
        ...customer,
        company_name: customer.company && customer.company.name? customer.company.name: null,
        business_phone_number: customer.company && customer.company.phone_number? customer.company.phone_number: null,
        cb_company_name: customer.cb_company && customer.cb_company.name? customer.cb_company.name: null,
        cb_business_phone_number: customer.cb_company && customer.cb_company.phone_number? customer.cb_company.phone_number: null
      }
    })
      .then(response => {
        if (response.status == 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setCustomer({
            ...data.data,
            cb_company_id: null,
            dob: data.data.dob? constants.formatDate(data.data.dob): '',
            cb_dob: data.data.cb_dob? constants.formatDate(data.data.cb_dob): '',
          })
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setErrors('');
        console.error('error :: ', error);
        if (error.response.status == 422) {
          let errors = error.response.data.errors;
          setErrors(errors);
          return
        }
        notify('warning', 'Warning', error.response.statusText);
      })
  }

  const goList = () => {
    history.push('/admin/customer');
  }

  const setSendType = (e, index) => {
    let newFileTypes = fileTypes;
    let key = fileLists[index].name;
    newFileTypes[key] = e.target.value;
    setFileTypes(newFileTypes);
  };

  const uploadDocument = () => {
    let formData = new FormData();
    for (let index = 0; index < fileLists.length; index++) {
      const element = fileLists[index];
      formData.append(`file[${index}]`, element);
    }
    Object.keys(fileTypes).map(key => {
      formData.append(`type[${key}]`, fileTypes[key]);
    })

    axios({
      method: 'POST',
      url: constants.API_URL + '/customer/' + id + '/documents',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: formData
    })
      .then(response => {
        if (response.status == 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setDocuments(data.data);
          setFileLists([]);
          setFileTypes([]);
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setErrors('');
        console.error('error :: ', error);
        if (error.response && error.response.status == 422) {
          let errors = error.response.data.errors;
          setErrors(errors);
        }
        notify('error', 'Error', error.response && error.response.statusText? error.response.statusText: 'Error occur.');
      })
  }

  const deleteDocument = () => {
    axios({
      method: 'GET',
      url: constants.API_URL + '/customer/' + id + '/remove/' + selectedDocumentId,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(response => {
        setConfirmShow(false);
        if (response.status == 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setDocuments(data.data);
          setSelectedDocumentId('');
          setFileLists([]);
          setFileTypes([]);
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setConfirmShow(false);
        console.error('error :: ', error);
        notify('error', 'Error', error.response && error.response.statusText? error.response.statusText: 'Error occur.');
      })
  }

  const viewDocument = (row) => {
    axios({
      method: 'GET',
      url: constants.API_URL + '/customer/' + id + '/view/' + row.id,
      headers: {
        Authorization: 'Bearer ' + token
      },
      responseType: 'blob'
    })
      .then(response => {
        if (response.status == 200) {
          let data = response.data;
          const disposition = response.request.getResponseHeader('Content-Disposition');
          let fileName = "";
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '');
          }
          const blob = new Blob([data])
          const downloadUrl = URL.createObjectURL(blob)
          let a = document.createElement("a");
          a.href = downloadUrl;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        notify('error', 'Error', error.response && error.response.statusText? error.response.statusText: 'Error occur.');
      })
  }

  const goSub = (subject) => {
    switch (subject) {
      case 'tasks':
        history.push('/admin/customer/' + id + '/tasks');
        break;

      case 'notes':
        history.push('/admin/customer/' + id + '/notes');
        break;
    
      default:
        break;
    }
  }

  return (
    <>
      <CardsHeader name="Customers" parentName="Customer Management" currentName="Edit customer" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <div className="w-75 d-flex justify-content-between">
                    <Button
                      className={classnames(
                        "w-25", 
                        { "selectedButton": tab === 'info' }, 
                        { "releasedSelectedButton": tab !== 'info' }
                      )}
                      onClick={() => setTab('info')}
                    >
                      Customer info
                    </Button>
                    <Button
                      className={classnames(
                        "w-25", 
                        { "selectedButton": tab === 'documents' }, 
                        { "releasedSelectedButton": tab !== 'documents' }
                      )}
                      onClick={() => setTab('documents')}
                    >
                      Documents Management
                    </Button>
                    <Button
                      className={classnames(
                        "w-25", 
                        { "selectedButton": tab === 'tasks' }, 
                        { "releasedSelectedButton": tab !== 'tasks' }
                      )}
                      onClick={() => goSub('tasks')}
                    >
                      Tasks
                    </Button>
                    <Button
                      className={classnames(
                        "w-25", 
                        { "selectedButton": tab === 'notes' }, 
                        { "releasedSelectedButton": tab !== 'notes' }
                      )}
                      onClick={() => goSub('notes')}
                    >
                      Notes
                    </Button>
                  </div>
                  <Button className="h-50" color="success" type="button" onClick={() => goList(true)}>Back to list</Button>
                </div>
              </CardHeader>
            </Card>            
            {tab == 'info' && 
            <Row>
              <Col xl={6}>
                <Card>
                  <CardHeader>
                    <h1 className="title mb-0">Customer Management</h1>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={6} sm={12}>
                        <InputCustom 
                          label={`First Name`}
                          value={customer && customer.first_name? customer.first_name: ''}
                          onChange={e => setCustomer({
                            ...customer,
                            first_name: e.target.value
                          })}
                          invalid={errors && errors.first_name? true: false}
                          errorMessage={errors.first_name}
                        />
                      </Col>
                      <Col md={6} sm={12}>
                        <InputCustom 
                          label={`Last Name`}
                          value={customer && customer.full_name? customer.full_name: ''}
                          onChange={e => setCustomer({
                            ...customer,
                            full_name: e.target.value
                          })}
                          invalid={errors && errors.full_name? true: false}
                          errorMessage={errors.full_name}
                        />
                      </Col>
                    </Row>
                    <InputCustom 
                      type={`email`}
                      label={`Email Address`}
                      value={customer && customer.email? customer.email: ''}
                      onChange={e => setCustomer({
                        ...customer,
                        email: e.target.value
                      })}
                      invalid={errors && errors.email? true: false}
                      errorMessage={errors.email}
                    />
                    <Row>
                      <Col md={6} sm={12}>
                        <InputCustom 
                          label={`Phone Number`}
                          value={customer && customer.mobile_number? customer.mobile_number: ''}
                          onChange={e => setCustomer({
                            ...customer,
                            mobile_number: e.target.value
                          })}
                          invalid={errors && errors.mobile_number? true: false}
                          errorMessage={errors.mobile_number}
                        />
                      </Col>
                      <Col md={6} sm={12}>
                        <InputCustom 
                          label={`Alternate Number`}
                          value={customer && customer.land_line_number? customer.land_line_number: ''}
                          onChange={e => setCustomer({
                            ...customer,
                            land_line_number: e.target.value
                          })}
                          invalid={errors && errors.land_line_number? true: false}
                          errorMessage={errors.land_line_number}
                        />
                      </Col>
                    </Row>
                    <InputCustom 
                      label={`Address`}
                      value={customer && customer.address? customer.address: ''}
                      onChange={e => setCustomer({
                        ...customer,
                        address: e.target.value
                      })}
                      invalid={errors && errors.address? true: false}
                      errorMessage={errors.address}
                    />
                    <Row>
                      <Col md={6} sm={12}>
                        <InputCustom 
                          label={`City`}
                          value={customer && customer.city? customer.city: ''}
                          onChange={e => setCustomer({
                            ...customer,
                            city: e.target.value
                          })}
                          invalid={errors && errors.city? true: false}
                          errorMessage={errors.city}
                        />
                      </Col>
                      <Col md={6} sm={12}>
                        <InputArraySelect 
                          label={`State`}
                          options={regions}
                          value={customer && customer.region_id? customer.region_id: ''}
                          onChange={e => setCustomer({
                            ...customer,
                            region_id: e.target.value
                          })}
                          valueKey={`id`}
                          labelKey={`name`}
                          defaultOption={true}
                          invalid={errors && errors.region_id? true: false}
                          errorMessage={errors.region_id}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} sm={12}>
                        <InputCustom 
                          label={`Postal Code`}
                          value={customer && customer.postal_code? customer.postal_code: ''}
                          onChange={e => setCustomer({
                            ...customer,
                            postal_code: e.target.value
                          })}
                          invalid={errors && errors.postal_code? true: false}
                          errorMessage={errors.postal_code}
                        />
                      </Col>
                      <Col md={6} sm={12}>
                        <InputCustom 
                          type={`date`}
                          label={`Date of Birth`}
                          value={customer && customer.dob? customer.dob: ''}
                          onChange={e => setCustomer({
                            ...customer,
                            dob: e.target.value
                          })}
                          invalid={errors && errors.dob? true: false}
                          errorMessage={errors.dob}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} sm={12}>
                        <InputCustom 
                          label={`D/L Number`}
                          value={customer && customer.driver_license_number? customer.driver_license_number: ''}
                          onChange={e => setCustomer({
                            ...customer,
                            driver_license_number: e.target.value
                          })}
                          invalid={errors && errors.driver_license_number? true: false}
                          errorMessage={errors.driver_license_number}
                        />
                      </Col>
                      <Col md={6} sm={12}>
                        <InputArraySelect 
                          label={`Lead Source`}
                          options={leadSources}
                          value={customer && customer.lead_source_id? customer.lead_source_id: ''}
                          onChange={e => setCustomer({
                            ...customer,
                            lead_source_id: e.target.value
                          })}
                          valueKey={`id`}
                          labelKey={`name`}
                          defaultOption={true}
                          invalid={errors && errors.lead_source_id? true: false}
                          errorMessage={errors.lead_source_id}
                        />
                      </Col>
                    </Row>
                    <InputToggle 
                      label={`Is Active`}
                      checked={customer && customer.is_active === 1? true: false}
                      onChange={() => setCustomer({
                        ...customer,
                        is_active: customer.is_active === 1? 0: 1
                      })}
                      invalid={errors && errors.is_active? true: false}
                      errorMessage={errors.is_active}
                    />
                  </CardBody>
                  <CardHeader>
                    <h1 className="title mb-0">Company Information</h1>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <p className="col-sm-12 text-center">Add a new company</p>
                      <Col md={6} sm={12}>
                        <InputCustom
                          label={`Company Name`}
                          value={customer && customer.company && customer.company.name ? customer.company.name : ''}
                          onChange={e => setCustomer({
                            ...customer,
                            company: {
                              ...customer.company,
                              name: e.target.value
                            }
                          })}
                          invalid={errors && errors.company_name ? true : false}
                          errorMessage={errors.company_name}
                        />
                      </Col>
                      <Col md={6} sm={12}>
                        <InputCustom
                          label={`Phone Number`}
                          value={customer && customer.company && customer.company.phone_number ? customer.company.phone_number : ''}
                          onChange={e => setCustomer({
                            ...customer,
                            company: {
                              ...customer.company,
                              phone_number: e.target.value
                            }
                          })}
                          invalid={errors && errors.business_phone_number ? true : false}
                          errorMessage={errors.business_phone_number}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <p className="col-sm-12 text-center">Or select an existent company</p>
                      <Col md={12}>
                        <InputArraySelect
                          label={`Company`}
                          options={companies}
                          value={customer && customer.company_id ? customer.company_id : ''}
                          onChange={e => setCustomer({
                            ...customer,
                            company_id: e.target.value
                          })}
                          valueKey={`id`}
                          labelKey={`name`}
                          defaultOption={true}
                          invalid={errors && errors.company_id ? true : false}
                          errorMessage={errors.company_id}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                  <div className="d-flex flex-row justify-content-center mb-4">
                    <Button className="w-50" color="success" onClick={() => updateCustomer()}>Save</Button>
                  </div>
                </Card>
              </Col>
              <Col xl={6}>
                <Card>
                  <CardHeader>
                    <h1 className="title mb-0">Co-Borrower Details</h1>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={6} sm={12}>
                        <InputCustom
                          label={`Full Name`}
                          value={customer && customer.cb_full_name ? customer.cb_full_name : ''}
                          onChange={e => setCustomer({
                            ...customer,
                            cb_full_name: e.target.value
                          })}
                          invalid={errors && errors.cb_full_name ? true : false}
                          errorMessage={errors.cb_full_name}
                        />
                      </Col>
                      <Col md={6} sm={12}>
                        <InputCustom
                          type={`date`}
                          label={`Date of Birth`}
                          value={customer && customer.cb_dob ? customer.cb_dob : ''}
                          onChange={e => setCustomer({
                            ...customer,
                            cb_dob: e.target.value
                          })}
                          invalid={errors && errors.cb_dob ? true : false}
                          errorMessage={errors.cb_dob}
                        />
                      </Col>
                    </Row>
                    <InputCustom 
                      type={`email`}
                      label={`Email Address`}
                      value={customer && customer.cb_email ? customer.cb_email : ''}
                      onChange={e => setCustomer({
                        ...customer,
                        cb_email: e.target.value
                      })}
                      invalid={errors && errors.cb_email ? true : false}
                      errorMessage={errors.cb_email}
                    />
                    <Row>
                      <Col md={6} sm={12}>
                        <InputCustom
                          label={`Phone Number`}
                          value={customer && customer.cb_mobile_number ? customer.cb_mobile_number : ''}
                          onChange={e => setCustomer({
                            ...customer,
                            cb_mobile_number: e.target.value
                          })}
                          invalid={errors && errors.cb_mobile_number ? true : false}
                          errorMessage={errors.cb_mobile_number}
                        />
                      </Col>
                      <Col md={6} sm={12}>
                        <InputCustom
                          label={`Land Line Number`}
                          value={customer && customer.cb_land_line_number ? customer.cb_land_line_number : ''}
                          onChange={e => setCustomer({
                            ...customer,
                            cb_land_line_number: e.target.value
                          })}
                          invalid={errors && errors.cb_land_line_number ? true : false}
                          errorMessage={errors.cb_land_line_number}
                        />
                      </Col>
                    </Row>
                    <InputCustom
                      label={`Address`}
                      value={customer && customer.cb_address ? customer.cb_address : ''}
                      onChange={e => setCustomer({
                        ...customer,
                        cb_address: e.target.value
                      })}
                      invalid={errors && errors.cb_address ? true : false}
                      errorMessage={errors.cb_address}
                    />
                    <Row>
                      <Col md={6} sm={12}>
                        <InputCustom
                          label={`City`}
                          value={customer && customer.cb_city ? customer.cb_city : ''}
                          onChange={e => setCustomer({
                            ...customer,
                            cb_city: e.target.value
                          })}
                          invalid={errors && errors.cb_city ? true : false}
                          errorMessage={errors.cb_city}
                        />
                      </Col>
                      <Col md={6} sm={12}>
                        <InputArraySelect
                          label={`State`}
                          options={regions}
                          value={customer && customer.cb_region_id ? customer.cb_region_id : ''}
                          onChange={e => setCustomer({
                            ...customer,
                            cb_region_id: e.target.value
                          })}
                          valueKey={`id`}
                          labelKey={`name`}
                          defaultOption={true}
                          invalid={errors && errors.cb_region_id ? true : false}
                          errorMessage={errors.cb_region_id}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} sm={12}>
                        <InputCustom
                          label={`Postal Code`}
                          value={customer && customer.cb_postal_code ? customer.cb_postal_code : ''}
                          onChange={e => setCustomer({
                            ...customer,
                            cb_postal_code: e.target.value
                          })}
                          invalid={errors && errors.cb_postal_code ? true : false}
                          errorMessage={errors.cb_postal_code}
                        />
                      </Col>
                      <Col md={6} sm={12}>
                        <InputCustom
                          label={`D/L Number`}
                          value={customer && customer.cb_driver_license_number ? customer.cb_driver_license_number : ''}
                          onChange={e => setCustomer({
                            ...customer,
                            cb_driver_license_number: e.target.value
                          })}
                          invalid={errors && errors.cb_driver_license_number ? true : false}
                          errorMessage={errors.cb_driver_license_number}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                  <CardHeader>
                    <h1 className="title mb-0">Company information</h1>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <p className="col-sm-12 text-center">Add a new company</p>
                      <Col md={6} sm={12}>
                        <InputCustom
                          label={`Company Name`}
                          value={customer && customer.cb_company && customer.cb_company.name ? customer.cb_company.name : ''}
                          onChange={e => setCustomer({
                            ...customer,
                            cb_company: {
                              ...customer.cb_company,
                              name: e.target.value
                            }
                          })}
                          invalid={errors && errors.cb_company_name ? true : false}
                          errorMessage={errors.cb_company_name}
                        />
                      </Col>
                      <Col md={6} sm={12}>
                        <InputCustom
                          label={`Phone Number`}
                          value={customer && customer.cb_company && customer.cb_company.phone_number ? customer.cb_company.phone_number : ''}
                          onChange={e => setCustomer({
                            ...customer,
                            cb_company: {
                              ...customer.cb_company,
                              phone_number: e.target.value
                            }
                          })}
                          invalid={errors && errors.cb_business_phone_number ? true : false}
                          errorMessage={errors.cb_business_phone_number}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <p className="col-sm-12 text-center">Or select an existent company</p>
                      <Col md={12}>
                        <InputArraySelect
                          label={`Company`}
                          options={companies}
                          value={customer && customer.cb_company_id ? customer.cb_company_id : ''}
                          onChange={e => setCustomer({
                            ...customer,
                            cb_company_id: e.target.value
                          })}
                          valueKey={`id`}
                          labelKey={`name`}
                          defaultOption={true}
                          invalid={errors && errors.cb_company_id ? true : false}
                          errorMessage={errors.cb_company_id}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>}
            <Card>
              {tab == 'documents' && <CardBody>
                <div className="container">
                  <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    <p>Select or drop the documents, then match each of them to the right type</p>
                  </div>
                  <aside>
                    {fileLists.map((item, index) => {
                      return (
                        <div
                          style={{ 
                            display: "flex", 
                            justifyContent: "space-between", 
                            borderBottom: '1px solid #CCC', 
                            alignItems: 'center',
                            padding: 20,
                          }}
                          key={index}
                        >
                          <Col md={3}>
                            <span>{item.name}</span>
                          </Col>
                          <Col md={3}>
                            <span>{`${item.size} bytes`}</span>
                          </Col>
                          <Col md={2}>
                            {`Document type`}
                          </Col>
                          <Col md={4}>
                            <Input
                              type='select'
                              id={`select_${index}`}
                              onChange={(e) => setSendType(e, index)}
                            >
                              {Object.keys(availableDocumentsUploadTypes).map((key, index) => {
                                return (
                                  <option value={key} key={key}>
                                    {availableDocumentsUploadTypes[key]}
                                  </option>
                                );
                              })}
                            </Input>
                          </Col>
                        </div>
                      )
                    })}
                  </aside>
                </div>
                <div className="d-flex flex-row justify-content-center mt-3">
                  <Button className="w-25" color="success" onClick={() => uploadDocument()}>Save</Button>
                </div>
                <TableCustom 
                  loading={loadingDocuments}
                  items={documents}
                  keyField={`id`}
                  searchField={true}
                  columns={[
                    {
                      dataField: "document_name",
                      text: "Name",
                      sort: true
                    },
                    {
                      dataField: "document_type",
                      text: "Type",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return availableDocumentsUploadTypes[row.category];
                      }
                    },
                    {
                      dataField: "",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            <img className={'cursor-pointer'}
                                 onClick={e => viewDocument(row)}
                                 src={require(`assets/images/tableIcons/visibility.svg`)} alt=""/>
                                <img className={'cursor-pointer'}
                                     onClick={e => {
                                       setConfirmShow(true);
                                       setSelectedDocumentId(row.id);
                                     }}
                                     src={require(`assets/images/tableIcons/delete_outline.svg`)} alt=""/>

                          </>
                        )
                      }
                    }
                  ]}
                />
                <Modal
                  isOpen={confirmShow}
                  toggle={() => setConfirmShow(false)}
                  className="modal-dialog-centered modal-secondary"
                >
                  <div className="modal-body">
                    {`Are you sure you want to delete this document?`}
                  </div>
                  <div className="modal-footer justify-content-end">
                    <ButtonContainer
                        onClick={() => setConfirmShow(false)}
                    >
                      Cancel
                    </ButtonContainer>
                    <ButtonContainer
                      reverse
                      onClick={() => deleteDocument()}
                    >
                      {`Delete`}
                    </ButtonContainer>

                  </div>
                </Modal>
              </CardBody>}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}