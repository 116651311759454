import React from 'react';

import {
  FormGroup,
} from "reactstrap";

export default function InputToggle({ label, checked = 0, defaultChecked, onChange, invalid, errorMessage, side }) {
  return (
    <>
      <FormGroup className={side ? `d-flex justify-content-between` : ``}>
        {side ? <label className="form-control-label mr-2">{label}</label>
          : <div>
            <label className="form-control-label">{label}</label>
          </div>}
        <label className="custom-toggle">
          <input
            type="checkbox"
            defaultChecked={defaultChecked}
            checked={checked}
            onChange={onChange}
          />
          <span
            className="custom-toggle-slider rounded-circle"
            data-label-off="No"
            data-label-on="Yes"
          />
        </label>
      </FormGroup>
      <div className="invalid-feedback" style={{ display: invalid ? 'block' : 'none' }}>
        {errorMessage}
      </div>
    </>
  )
}