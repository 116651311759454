import React, { useContext, useCallback, useMemo } from 'react';
import { useDropzone } from "react-dropzone";

import { DealContext } from '../context';

import TableCustom from 'views/pages/components/TableCustom';

import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal
} from "reactstrap";
import {ButtonContainer} from "@pages/reusable-components/styled-components";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out"
};

const activeStyle = {
  borderColor: "#2196f3"
};

const acceptStyle = {
  borderColor: "#00e676"
};

const rejectStyle = {
  borderColor: "#ff1744"
};

export default function UploadTab() {
  const dealContext = useContext(DealContext);
  const {
    deal,
    deleteModal, setDeleteModal,
    fileLists, setFileLists,
    viewDocument,
    deleteDocument,
    uploadDocument,
  } = dealContext;

  const onDrop = useCallback(acceptedFiles => {
    setFileLists(prev => [...prev, ...acceptedFiles]);
  })
  
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({onDrop});

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  )

  return (
    <CardBody>
      <Card>
        <CardHeader>
          <h2>Documents</h2>
        </CardHeader>
        <CardBody>
          <div className="container">
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <p>Select or drop the documents, then match each of them to the right type</p>
            </div>
            <aside>
              {fileLists.map((item, index) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: '1px solid #CCC',
                      alignItems: 'center',
                      padding: 20,
                    }}
                    key={index}
                  >
                    <Col md={3} sm={6}>
                      <span>{item.name}</span>
                    </Col>
                    <Col md={3} sm={6}>
                      <span>{`${item.size} bytes`}</span>
                    </Col>
                  </div>
                )
              })}
            </aside>
          </div>
          <div className="d-flex flex-row justify-content-center mt-3">
            <Button className="w-25" color="warning" onClick={() => uploadDocument()}>Save</Button>
          </div>
          <h2 className="text-center mt-5">Uploaded documents</h2>
          {deal.custom_documents &&
            <TableCustom
              loading={false}
              items={deal.custom_documents}
              keyField={`name`}
              searchField={true}
              columns={[
                {
                  dataField: "name",
                  text: "Name",
                  sort: true,
                  formatter: (rowContent, row) => {
                    return `Document - ${row.document_name}`
                  }
                },
                {
                  dataField: "",
                  text: "Action",
                  formatter: (rowContent, row) => {
                    return (
                      <>
                        <img className={'cursor-pointer'}
                             onClick={() => viewDocument(row)}
                             src={require(`assets/images/tableIcons/visibility.svg`)} alt=""/>
                        <img className={'cursor-pointer'}
                             onClick={() => {
                               setDeleteModal({
                                 ...deleteModal,
                                 show: true,
                                 header: 'Delete document',
                                 body: 'Are you sure to delete this document?',
                                 onClick: () => deleteDocument(row.id)
                               })
                             }}
                             src={require(`assets/images/tableIcons/delete_outline.svg`)} alt=""/>
                      </>
                    )
                  }
                }
              ]}
            />}
        </CardBody>
      </Card>
      <Modal
          isOpen={deleteModal.show}
          toggle={() => setDeleteModal({
            ...deleteModal,
            show: false
          })}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title main-color">{deleteModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setDeleteModal({
                ...deleteModal,
                show: false
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <span>{deleteModal.body}</span>
          </div>
          <div className="modal-footer justify-content-end">
            <ButtonContainer
              reverse
              onClick={deleteModal.onClick}
            >
              {`Delete`}
            </ButtonContainer>
          </div>
        </Modal>
    </CardBody>
  );
}