import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from "js-cookie";

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import TableCustom from 'views/pages/components/TableCustom';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  UncontrolledAlert,
  Modal,
  Button,
  FormGroup,
  Input,
} from "reactstrap";
import InputCustom from 'views/pages/components/InputCustom';

export default function Payments() {
  const { slug } = useParams();
  const location = useLocation();
  const history = useHistory();
  const notificationAlert = useRef(null);

  const authUserTeam = useSelector(state => state.getState).authUserTeam;

  const [token, setToken] = useState('');
  const [payments, setPayments] = useState([]);
  const [isAbleToGetPayments, setIsAbleToGetPayments] = useState(false);
  const [role, setRole] = useState('');
  const [can, setCan] = useState('');
  const [permission, setPermission] = useState('');
  const [dealer, setDealer] = useState('');

  const [loading, setLoading] = useState(true);
  const [delay, setDelay] = useState(false);

  const [customer, setCustomer] = useState('');
  const [sendEmail, setSendEmail] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const [paymentAuthorizationLink, setPaymentAuthorizationLink] = useState('');

  const [filter, setFilter] = useState({
    search: ''
  });

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const searchValue = location.state && location.state?.search ? location.state?.search : ''
    setFilter({
      ...filter,
      search: searchValue
    })

    const FETCH_URL = slug ?
      `${constants.API_URL}/payments/${slug}/customers` :
      `${constants.API_URL}/payments`

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: FETCH_URL,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          params: {
            search: searchValue
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data
        setPayments(data.data.payments.data);
        setIsAbleToGetPayments(data.data.isAbleToGetPayments);
        setRole(data.data.role);
        setCan(data.data.can);
        setPermission(data.data.permission);
        setDealer(data.data.dealer);

        setTimeout(() => {
          setLoading(false)
          setDelay(true)
        }, 10);
      } catch (error) {
        setLoading(false);
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const getEnrollLink = () => {
    axios({
      method: 'POST',
      url: constants.API_URL + '/payments/' + customer.id + '/payment-link',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: {
        send_email: sendEmail,
        dealer_id: null
      }
    })
      .then(response => {
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setPaymentAuthorizationLink(data.data);
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        notify('warning', 'Warning', error.response.statusText);
      })
  }

  const goEnrollLink = () => {
    window.open(paymentAuthorizationLink, '_blank');
  }

  const filterPayment = () => {
    axios({
      method: 'GET',
      url: constants.API_URL + '/payments',
      headers: {
        Authorization: 'Bearer ' + token
      },
      params: filter
    })
      .then(response => {
        setLoading(false);
        if (response.status === 200) {
          setLoading(true);
          let data = response.data;
          console.log('response data :: ', data);
          setPayments(data.data.payments.data);

          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        notify('warning', 'Warning', error.response.statusText);
      })
  }

  const resetPayment = () => {
    axios({
      method: 'GET',
      url: constants.API_URL + '/payments',
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then(response => {
        setLoading(false);
        if (response.status === 200) {
          setLoading(true);
          let data = response.data;
          console.log('response data :: ', data);
          setPayments(data.data.payments.data);
          setFilter({
            ...filter,
            search: ''
          })

          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        notify('warning', 'Warning', error.response.statusText);
      })
  }

  const goMandateInfo = (row) => {
    history.push({
      pathname: `/admin/payments/mandates/${dealer.slug}/info/${row.id}`
    })
  }

  return (
    <>
      <CardsHeader name="Customers" parentName="Manage customers payment agreements" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <h1 className="title mb-0">{`Payment mandates ${authUserTeam && dealer? ' -' + dealer.name: ''}`}</h1>
              </CardHeader>
              <CardBody>
                {isAbleToGetPayments && 
                <UncontrolledAlert color="danger" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-cart"></i>
                  </span>{" "}
                  <span className="alert-inner--text">
                    You are not able to get direct payments yet!
                  </span>
                </UncontrolledAlert>}
                <Container>
                  <Row>
                    <Col md={4} sm={12}>
                      <InputCustom
                        label={`Search`}
                        value={filter?.hasOwnProperty('search') ?  filter['search'] : ''}
                        onChange={e => setFilter({
                          ...filter,
                          search: e.target.value
                        })}
                        onKeyPress={e => {
                          if (e.charCode === 13) {
                            filterPayment()
                          }
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} sm={12} xs={12} className="mt-2">
                      <Button className="w-100" color="success" onClick={() => filterPayment()}>Filter</Button>
                    </Col>
                    <Col md={6} sm={12} xs={12} className="mt-2">
                      <Button className="w-100" color="danger" onClick={() => resetPayment()}>Reset</Button>
                    </Col>
                  </Row>
                </Container>
                <TableCustom 
                  loading={loading}
                  delay={delay}
                  keyField={`full_name`}
                  searchField={true}
                  items={payments}
                  columns={[
                    {
                      dataField: "full_name",
                      text: "Name",
                      sort: true
                    },
                    {
                      dataField: "date_of_agreement",
                      text: "Date of agreement",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.go_cardless_agreements[0]? row.go_cardless_agreements[0].created_at: '-'
                      }
                    },
                    {
                      dataField: "active",
                      text: "Active",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return (
                          <label className="custom-toggle">
                            <input
                              type="checkbox"
                              checked={row.go_cardless_agreements[0]? true : false}
                              readOnly
                            />
                            <span
                              className="custom-toggle-slider rounded-circle"
                              data-label-off="No"
                              data-label-on="Yes"
                            />
                          </label>
                        )
                      }
                    },
                    {
                      dataField: "status",
                      text: "Status",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.go_cardless_agreements[0]? row.go_cardless_agreements[0].state: ''
                      }
                    },
                    {
                      dataField: "created_by",
                      text: "Created by",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.owner.name
                      }
                    },
                    {
                      dataField: "action",
                      text: "",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            { role && role.superadministrator && row.go_cardless_agreements[0] && 
                              <i className="fas fa-cube cursor-pointer" onClick={() => goMandateInfo(row)}></i>}
                            { can && (can.update || can.delete) && permission.update && 
                              <i className="fas fa-user-edit cursor-pointer" onClick={() => {
                                setCustomer(row)
                                setEditModal(true)
                              }}></i>
                            }
                          </>
                        )
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={editModal}
          toggle={() => setEditModal(false)}
          className="modal-dialog-centered modal-secondary"
        >
          <div className="modal-body">
            <h2 className="surtitle">{customer.full_name}</h2>
            <h3>{customer.email}</h3>
            {!paymentAuthorizationLink && <div>
              <p className="text-info mt-4">You can either get the agreement URL and pass it to the customer or automatically send it through the website!</p>
              <div className="d-flex justify-direction-center">
                <label className="mr-4 text-success">Send the link through e-mail</label>
                <label className="custom-toggle">
                  <input
                    type="checkbox"
                    checked={sendEmail}
                    onChange={() => setSendEmail(!sendEmail)}
                  />
                  <span
                    className="custom-toggle-slider rounded-circle"
                    data-label-off="No"
                    data-label-on="Yes"
                  />
                </label>
              </div>
            </div>}
            {paymentAuthorizationLink && <div>
              <p className="text-info">Direct debit URLs are valid only for two hours!</p>
              <p>Once the customer completes the flow following the link below, you will be able to use Direct Payments.</p>
              <FormGroup>
                <label className="form-control-label">Link</label>
                <Input
                  type="textarea"
                  defaultValue={paymentAuthorizationLink}
                  rows={5}
                />
              </FormGroup>
            </div>}
          </div>
          <div className="modal-footer">
            {!paymentAuthorizationLink && <Button
              className="new-event--add"
              color="primary"
              type="button"
              onClick={() => getEnrollLink()}
            >
              {`Get Direct Debit enroll link`}
            </Button>}
            {paymentAuthorizationLink && <Button
              className="new-event--add"
              color="primary"
              type="button"
              onClick={() => goEnrollLink()}
            >
              {`Go to link`}
            </Button>}
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setEditModal(false)}
            >
              Close
            </Button>
          </div>
        </Modal>
      </Container>
    </>
  )
}