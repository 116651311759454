import React, { useEffect, useState, createContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios'
import Cookies from 'js-cookie'
import * as constants from 'constants/index.js'
import { cleanFilters, cleanReferer, setFilters, setReferer } from '@redux/Vehicle/actions';

export const Context = createContext({});

export const Provider = props => {
  const { notify, children } = props;
  const { category, type } = useParams()
  const history = useHistory()
  const dispatch = useDispatch();
  const permissions = useSelector(state => state.getState).permissions
  const { liveVehicleFilters } = useSelector((state) => state.vehicle);
  const [token, setToken] = useState('')
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState('')
  const [confirmShow, setConfirmShow] = useState(false);
  const [deliveryStatuses, setDeliveryStatuses] = useState('')
  const [fundingMethods, setFundingMethods] = useState([]);
  const [fundingStatuses, setFundingStatuses] = useState([]);
  const [vehicleStatuses, setVehicleStatuses] = useState([])
  const [dealStatuses, setDealStatuses] = useState([])
  const [soldTypes, setSoldTypes] = useState([]);
  const [soldProcessTypes, setSoldProcessTypes] = useState([]);
  const [filter, setFilter] = useState('')
  const [pagination, setPagination] = useState('')
  const [pageNumber, setPageNumber] = useState(1)
  const [editedItem, setEditedItem] = useState([]);
  const [editing, setEditing] = useState(false);
  const inputStyle = (width) => ({ "minWidth": `${width}px` });
  const formGroupStyle = (width) => ({ "marginBottom": 0 });

  const updateItemsValue = (element, key, value) => {
    const objIndex = items.findIndex(obj => obj.live_id === element.live_id);
    const updatedObj = { ...items[objIndex], [key]: value, is_edited: true };

    // make final new array of objects by combining updated object.
    let elements = [
      ...items.slice(0, objIndex),
      updatedObj,
      ...items.slice(objIndex + 1),
    ];

    setItems(elements)
  }

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)
    let filters = liveVehicleFilters[type] ? { ...liveVehicleFilters[type] } : {};
    setFilter(filters);
    const source = axios.CancelToken.source()
    fetchData(source, 1, grs_token, filters);

    return () => {
      source.cancel()
    }
  }, [])

  const updateData = () => {
    setLoading(true);
    const source = axios.CancelToken.source();
    fetchData(source, pageNumber, token, '')
  }

  const updateDataChanges = (vehicle) => {
    // Temp blocking refreshing the entire list at the moment, just nofify user
    return;
    if (items) {
      let vehicleToSearch = items.find(vehicleItem => vehicleItem.id == vehicle)
      if (vehicleToSearch) {
        updateData();
      }

    }
  }

  const goToVehicle = (vehicle) => {
    history.push({
      pathname: `/admin/vehicle/${vehicle.vehicle_id}/edit`
    });
  }

  const fetchData = async (source, pageNumber, token, filter) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/live-vehicles`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ...filter,
          page: pageNumber,
          type,
          category: category ? category : null,
        },
        cancelToken: source.token
      })
      console.log('response :: ', response.data)
      const data = response.data
      let items = data.data.items.map(item => {
        return {
          ...item,
          is_edited: false,
          collecting_on: item.collecting_on ? formatDate(item.collecting_on) : '',
          delivery_date: item.delivery_date ? formatDate(item.delivery_date) : '',
        }
      })
      setItems(items);
      setDeliveryStatuses(data.data.deliveryStatusTypes);
      setFundingMethods(data.data.fundingMethods);
      setFundingStatuses(data.data.fundingStatuses);
      setVehicleStatuses(data.data.vehicleStatuses);
      setDealStatuses(data.data.dealStatuses);
      setSoldTypes(data.data.soldTypes);
      setSoldProcessTypes(data.data.soldProcessTypes);
      setLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
      }
    }
  }

  const formatDate = (d) => {
    if (d) {
      let date = new Date(d);
      let ye = Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
      let mo = Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
      let da = Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

      return `${ye}-${mo}-${da}`
    }
  }

  const filterVehicle = () => {
    const source = axios.CancelToken.source();
    fetchData(source, 1, token, filter);
    dispatch(setFilters(filter, type));
  }

  const setRefererUrl = (referer) => {
    dispatch(setReferer(referer));
  }

  const cleanRefererUrl = () => {
    dispatch(cleanReferer());
  }

  const resetVehicle = () => {
    setFilter('')
    const source = axios.CancelToken.source();
    fetchData(source, 1, token, '');
    dispatch(cleanFilters(type));
  }

  const addVechile = () => {
    history.push({
      pathname: '/admin/live-vehicle/create'
    })
  }

  const editItemProcess = (item) => {
    setEditing(true);
    setEditedItem(item)
  }

  const cleanEditItem = () => {
    setEditing(false);
    setEditedItem({})
  }

  const removeItem = async () => {
    try {
      const response = await axios({
        method: 'DELETE',
        url: `${constants.API_URL}/live-vehicles/${editedItem.id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      console.log('response :: ', response.data)
      const data = response.data

      if (data.success) {
        notify('success', 'Success', data.message);
        setConfirmShow(false);
        setEditedItem({})
        const source = axios.CancelToken.source();
        updateData(source);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
      }
    }
  }

  const submitItem = async () => {
    try {
      const response = await axios({
        method: 'PUT',
        url: `${constants.API_URL}/live-vehicles/${type}/update`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: items.filter(item => item.is_edited)
      })
      console.log('response :: ', response.data)
      const source = axios.CancelToken.source();
      fetchData(source);
      setLoading(false)
    } catch (error) {
      console.log(error)
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
      }
    }
  }

  const vehicleContext = {
    notify,
    type,
    inputStyle,
    formGroupStyle,
    history,
    permissions,
    token, setToken,
    loading, setLoading,
    items, setItems,
    confirmShow, setConfirmShow,
    deliveryStatuses, setDeliveryStatuses,
    fundingMethods, setFundingMethods,
    vehicleStatuses, setVehicleStatuses,
    dealStatuses, setDealStatuses,
    soldTypes, soldProcessTypes,
    fundingStatuses,
    filter, setFilter,
    pagination, setPagination,
    pageNumber, setPageNumber,
    editedItem, setEditedItem,
    editing, setEditing,
    updateData,
    updateDataChanges,
    fetchData,
    filterVehicle,
    resetVehicle,
    addVechile,
    editItemProcess,
    cleanEditItem,
    removeItem,
    submitItem,
    updateItemsValue,
    setRefererUrl,
    cleanRefererUrl,
    goToVehicle
  }

  return <Context.Provider value={vehicleContext}>{children}</Context.Provider>
}

export const { Consumer } = Context;