export const GET_PAYMENT_DETAILS_REQUEST = 'GET_PAYMENT_DETAILS_REQUEST';
export const GET_PAYMENT_DETAILS_SUCCESS = 'GET_PAYMENT_DETAILS_SUCCESS';
export const GET_PAYMENT_DETAILS_ERROR = 'GET_PAYMENT_DETAILS_ERROR';

export const CREATE_INVOICE_NOTE_REQUEST = 'CREATE_INVOICE_NOTE_REQUEST';
export const CREATE_INVOICE_NOTE_SUCCESS = 'CREATE_INVOICE_NOTE_SUCCESS';
export const CREATE_INVOICE_NOTE_ERROR = 'CREATE_INVOICE_NOTE_ERROR';

export const SEND_PAYMENT_INVOICE_EMAIL_REQUEST = 'SEND_PAYMENT_INVOICE_EMAIL_REQUEST';
export const SEND_PAYMENT_INVOICE_EMAIL_SUCCESS = 'SEND_PAYMENT_INVOICE_EMAIL_SUCCESS';
export const SEND_PAYMENT_INVOICE_EMAIL_ERROR = 'SEND_PAYMENT_INVOICE_EMAIL_ERROR';

export const GET_SALES_ACCOUNTS_REQUEST = 'GET_SALES_ACCOUNTS_REQUEST';
export const GET_SALES_ACCOUNTS_SUCCESS = 'GET_SALES_ACCOUNTS_SUCCESS';
export const GET_SALES_ACCOUNTS_ERROR = 'GET_SALES_ACCOUNTS_ERROR';

export const GET_TAXES_REQUEST = 'GET_TAXES_REQUEST';
export const GET_TAXES_SUCCESS = 'GET_TAXES_SUCCESS';
export const GET_TAXES_ERROR = 'GET_TAXES_ERROR';


export const UPDATE_INVOICE_REQUEST = 'UPDATE_INVOICE_REQUEST';
export const UPDATE_INVOICE_SUCCESS = 'UPDATE_INVOICE_SUCCESS';
export const UPDATE_INVOICE_ERROR = 'UPDATE_INVOICE_ERROR';