import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import TableCustom from 'views/pages/components/TableCustom';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import {
  update,
  hasPermission
} from 'views/policies/AutomatedReminderPolicy';

export default function Reminders() {
  const notificationAlert = useRef(null);
  const history = useHistory();

  const permissions = useSelector(state => state.getState).permissions;
  const authUserTeam = useSelector(state => state.getState).authUserTeam;

  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [reminders, setReminders] = useState([]);
  const [statuses, setStatuses] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/automated-reminders`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data

        const filteredData = data.data.items.filter(item => item.reminderable != null)
        setReminders(filteredData)
        setStatuses(data.data.statuses)
        setTimeout(() => {
          setLoading(false)
        }, 500)
      } catch (error) {
        setLoading(false)
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const editDeal = (reminderable) => {
    history.push({
      pathname: `/admin/deals/${reminderable.id}/edit`
    })
  }

  const editVehicle = (reminderable) => {
    history.push({
      pathname: `/admin/vehicle/${reminderable.id}/edit`
    })
  }

  const editReminder = (reminderId) => {
    history.push({
      pathname: `/admin/settings/automated-reminders/${reminderId}/edit`
    })
  }

  return (
    <>
      <CardsHeader name="Settings" parentName="Reminders Management" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <div>
                    <h1 className="title mb-0">Automatic Reminders</h1>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <TableCustom 
                  loading={loading}
                  keyField={`id`}
                  searchField={false}
                  items={reminders}
                  columns={[
                    {
                      dataField: "title",
                      text: "Name",
                      sort: true
                    },
                    {
                      dataField: "status",
                      text: "Status",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return statuses[row.status]
                      }
                    },
                    {
                      dataField: "parent",
                      text: "Parent",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            <Button 
                              color="success" 
                              type="button" 
                              onClick={() => row.reminderable_entity_type == 'Deal'? editDeal(row.reminderable): editVehicle(row.reminderable)}
                            >{" "}
                              #{`${row.reminderable_entity_type} - ${row.reminderable.id} - ${row.reminderable_entity_type == 'Deal' ? row.reminderable.full_name: row.reminderable.stock_no}`}
                            </Button>
                          </>
                        )
                      }
                    },
                    {
                      dataField: "should_run_by_schedule",
                      text: "Due Date",
                      sort: true
                    },
                    {
                      dataField: "",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            {(hasPermission(permissions, 'create-automated-reminders') || hasPermission(permissions, 'delete-automated-reminders')) && 
                                update(permissions, authUserTeam, row) &&
                                <img className={'cursor-pointer'}
                                     onClick={() => editReminder(row.id)}
                                     src={require(`assets/images/tableIcons/edit.svg`)} alt=""/>
                            }
                          </>
                        )
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}