import React, {useEffect, useState, useRef} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {useSelector} from 'react-redux';
import NotificationAlert from 'react-notification-alert'
import axios from 'axios'
import Cookies from 'js-cookie'

import * as constants from 'constants/index.js'


import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
} from 'reactstrap'

import DynamicTable from "../components/DynamicTable";
import styled from "styled-components";
import {FLEET_URL, formatDate} from "constants/index.js";
import {LoaderContainer, override} from "../reusable-components/styled-components";
import {ClipLoader} from "react-spinners";
import CustomSearch from "@pages/components/CustomSearch";
import {SelectWithoutInput} from "@pages/components/InputSelect";
import FilterComponent from "@pages/fleet/pages/components/FilterComponent";
import Picker from "@pages/components/DatePicker";


export default function Receipts() {
    const {category} = useParams()
    const notificationAlert = useRef(null)
    const [filter, setFilter] = useState({
        driver_id: null,
        date: null,
        stock_no: null
    })
    const [receipts, setReceipts] = useState([])
    const [token, setToken] = useState('')
    const [loading, setLoading] = useState(true)
    const [driverValue, setDriverValue] = useState(null)
    const [drivers, setDrivers] = useState([])

    useEffect(() => {
        const grs_token = Cookies.get('grs_token')
        setToken(grs_token)
        const date = formatDate(new Date())
        const getData = async () => {
            await fetchData(grs_token, date)
            await getDrivers(grs_token)

        }
        getData()
    }, [])
    const getDrivers = async (token) => {
        const drivers = await axios({
            method: 'GET',
            url: `${constants.FLEET_URL}/job-booker/get-drivers`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        if (drivers?.data.data.length) {
            setDrivers(drivers?.data.data)
        }
    }
    const fetchData = async (token, data = {}) => {
        try {
            const source = axios.CancelToken.source()

            const response = await axios({
                method: 'GET',
                url: `${constants.FLEET_URL}/job-booker/get-receipts`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: filter,
                cancelToken: source.token,
            })
            setReceipts(response.data.data)
            setLoading(false)
            // setImgSrc(arr => [...arr, reader.result]);
        } catch (error) {
            setLoading(false)
            if (axios.isCancel(error)) {
                console.log('Request canceled :: ', error)
            } else {
                console.error('error :: ', error)
            }
        }
    }
    const filterVehicle = async (data = null) => {
        const source = axios.CancelToken.source()
        await fetchData(source, 1, token, filter, true)
    }

    const searchData = async (e) => {
        let tempFilter = filter
        tempFilter.stock_no = e.target.value
        setFilter(tempFilter)
        await filterVehicle(tempFilter)
    }
    const selectFilter = async (key,value) => {
        let tempFilter = filter
        tempFilter[key] = value
        setFilter(tempFilter)
        await filterVehicle(tempFilter)
    }

    return (
        <>
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlert}/>
            </div>
            {loading ?
                <LoaderContainer>
                    <ClipLoader
                        css={override}
                        size={40}
                        color={`#7B61E4`}
                        loading={loading}
                    />
                </LoaderContainer> :
                <Container className="admin-main-body" fluid>
                    <Row className={'align-items-center filters-distance'}>
                        <CustomSearch
                            onChange={searchData}
                            placeholder={'Search'}
                        />
                        <Picker selectDate={(e) => {
                            selectFilter('date',e)
                        }}/>
                        <SelectWithoutInput
                            label={driverValue ? driverValue : `Driver`}
                            onSelect={e => {
                                selectFilter('driver_id',e.id)
                                setDriverValue(e.name)
                            }}
                            options={drivers}
                            defaultOption={true}
                            valueKey={'id'}
                            labelKey={'name'}
                            imageLink={'loyalty.png'}
                        />

                    </Row>
                    {receipts.length > 0 && <div className={'d-flex flex-wrap justify-content-between'}>
                        {receipts.map(item => {
                            return <div className={'d-flex mb-4'}>
                                <img src={item.receipt} width={'200px'} alt=""/>
                                <div className={'d-flex'}>
                                    <div className={'d-flex flex-column'}>
                                        <span>Vehicle:</span>
                                        <span>Driver:</span>
                                        <span>Added at:</span>
                                    </div>
                                   <div className={'d-flex flex-column'}>
                                       <span>{item.vehicle.stock_no}</span>
                                       <span>{item.driver.name}</span>
                                       <span>{formatDate(item.created_at)}</span>
                                   </div>
                                </div>
                            </div>
                        })}
                    </div>}
                </Container>
            }
        </>
    )
}