import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import NotificationAlert from "react-notification-alert";
import Cookies from 'js-cookie';
import * as constants from 'constants/index.js';
import axios from 'axios';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
  Input,
} from "reactstrap";

import CardsHeader from 'layouts/Headers/CardsHeader.js';
import {
  InputKeySelect
} from 'views/pages/components/InputSelect';
import {ButtonContainer} from "@pages/reusable-components/styled-components";

export default function CustomerCreate() {
  const notificationAlert = useRef(null);
  const history = useHistory();

  const [token, setToken] = useState('');
  const [businessTypes, setBusinessTypes] = useState([]);
  const [companyTypes, setCompanyTypes] = useState([]);
  const [counties, setCounties] = useState([]);
  const [titles, setTitles] = useState('');
  const [errors, setErrors] = useState('');
  const [lookupAddresses, setLookupAddresses] = useState([]);

  const [customer, setCustomer] = useState({
    is_active: 0,
  });

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/customer/create`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data
        setBusinessTypes(data.data.businessTypes)
        setCompanyTypes(data.data.companyTypes)
        setCounties(data.data.counties.data)
        setTitles(data.data.titles);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const addCustomer = () => {
    axios({
      method: 'POST',
      url: constants.API_URL + '/customer',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: customer
    })
      .then(response => {
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setErrors('');
          notify('success', 'Success', data.message);
          setTimeout(() => {
            history.push({
              pathname: '/admin/customer/' + data.data.id + '/edit',
              state: {
                customer: data.data
              }
            })
          }, 1500);
        }
      })
      .catch(error => {
        setErrors('');
        console.error('error :: ', error);
        if (error.response.status === 422) {
          let errors = error.response.data.errors;
          setErrors(errors);
          return
        }
        notify('warning', 'Warning', error.response.statusText);
      })
  }

  const goList = () => {
    history.push('/admin/customer');
  }

  const setSelectedAddress = (address) => {
    const a = address.FormattedAddressLines
    const addressParts = address.ComponentParts;

    if (a) {
      const addr = `${a.Street ? a.Street : ''}${a.Premises ? `, ${a.Premises}` : ''}${a.PostTown ? `, ${a.PostTown}` : ''}${a.Organisation ? `, ${a.Organisation}` : ''}${a.Locality ? `, ${a.Locality}` : ''}${a.County ? `, ${a.County}` : ''}`

      setCustomer({
        ...customer,
        address: addr,
        street: addressParts.Street,
        city: a.PostTown,
        building: `${addressParts.BuildingName ? addressParts.BuildingName + (addressParts.BuildingNumber ? ',' : '') : ''}${addressParts.BuildingNumber ? ' ' + addressParts.BuildingNumber : ''}${addressParts.SubBuildingName ? ', ' + addressParts.SubBuildingName : ''}`
      })
    }
  }

  const getAddressByPostcode = (postcode) => {
    axios({
      method: 'GET',
      url: `${constants.UK_VEHICLE_DATA_API_URL}/PostcodeLookup?v=2&api_nullitems=1&auth_apikey=${constants.UK_VEHICLE_DATA_API_KEY}&key_POSTCODE=${postcode}`,
    })
      .then(response => {
        const data = response.data
        console.log('response data :: ', data)

        if (data.Response) {

          if (data.Response.StatusCode === 'Success') {

            const addressList = data.Response.DataItems.AddressDetails.AddressList

            if (addressList.length == 1) {
              setCustomer({
                ...customer,
                postal_code: postcode
              });

              setErrors({
                ...errors,
                postal_code: ''
              })

              setSelectedAddress(addressList[0]);
            } else if (addressList.length > 1) {
              setCustomer({
                ...customer,
                postal_code: postcode
              })

              setErrors({
                ...errors,
                postal_code: ''
              })

              setLookupAddresses(addressList);
            }
          } else {
            setErrors({
              ...errors,
              postal_code: data.Response.StatusMessage
            })

            setCustomer({
              ...customer,
              postal_code: postcode,
              address: ''
            })
          }
        }
      })
      .catch(error => {
        console.error('error :: ', error)
      })
  }

  return (
    <>
      <CardsHeader name="Customers" parentName="Customer Management" currentName="Create customer" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Add Customer</h1>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Card>
                  <CardHeader>
                    <h2 className="surtitle mb-0">Customer management</h2>
                  </CardHeader>
                  <CardBody>
                    <Col>
                        <h3 className={'main-color mb-3'}>Personal information</h3>
                      <Row className={'phone-container'}>
                        <Col>
                          <InputKeySelect
                              newLabel={`Title`}
                              options={titles}
                              value={customer && customer.title ? customer.title : ''}
                              onChange={e => setCustomer({
                                ...customer,
                                title: e.target.value,
                                attention_to: `${e.target.value ? `${titles[e.target.value]} ` : ''}${customer.first_name ? `${customer.first_name}` : ''}${customer.last_name ? ` ${customer.last_name}` : ''}`
                              })}
                              defaultOption={true}
                              invalid={errors && errors.title ? true : false}
                              errorMessage={errors.title}
                          />
                          <FormGroup className={'d-flex align-items-center'}>
                            <label className="form-control-label w-50">First Name</label>
                            <Input
                                placeholder="First Name"
                                type="text"
                                value={customer?.first_name ?? ''}
                                onChange={(e) => setCustomer({
                                  ...customer,
                                  first_name: e.target.value,
                                  full_name: `${e.target.value ? ` ${e.target.value}`  : ''}${customer.last_name ? ` ${customer.last_name}`  : ''}`,
                                  attention_to: `${customer.title ? `${titles[customer.title]} `  : ''}${e.target.value ? `${e.target.value}`  : ''}${customer.last_name ? ` ${customer.last_name}`  : ''}`
                                })}
                                invalid={errors && errors.first_name ? true : false}
                            />
                            <div className="invalid-feedback">
                              Please provide the first name.
                            </div>
                          </FormGroup>
                          <FormGroup className={'d-flex align-items-center'}>
                            <label className="form-control-label w-50">Last Name</label>
                            <Input
                                placeholder="Last Name"
                                type="text"
                                value={customer?.last_name ?? ''}
                                onChange={(e) => setCustomer({
                                  ...customer,
                                  last_name: e.target.value,
                                  full_name: `${customer.first_name ? ` ${customer.first_name}`  : ''}${e.target.value ? ` ${e.target.value}`  : ''}`,
                                  attention_to: `${customer.title ? `${titles[customer.title]} `  : ''}${customer.first_name ? `${customer.first_name}`  : ''}${e.target.value ? ` ${e.target.value}`  : ''}`
                                })}
                                invalid={errors && errors.last_name ? true : false}
                            />
                            <div className="invalid-feedback">
                              Please provide a full name.
                            </div>
                          </FormGroup>
                          <FormGroup className={'d-flex align-items-center'}>
                            <label className="form-control-label w-50">Business phone number</label>
                            <Input
                                placeholder="Business phone number"
                                type="text"
                                onChange={(e) => setCustomer({
                                  ...customer,
                                  business_phone_number: e.target.value
                                })}
                                invalid={errors && errors.business_phone_number ? true : false}
                            />
                            <div className="invalid-feedback">
                              Please provide a business phone number.
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup className={'d-flex align-items-center'}>
                            <label className="form-control-label w-50">Full Name</label>
                            <Input
                                placeholder="Full Name"
                                type="text"
                                value={customer?.full_name ?? ''}
                                disabled={true}
                                onChange={(e) => setCustomer({
                                  ...customer,
                                  full_name: e.target.value
                                })}
                                invalid={errors && errors.full_name ? true : false}
                            />
                            <div className="invalid-feedback">
                              Please provide the full name.
                            </div>
                          </FormGroup>
                          <FormGroup className={'d-flex align-items-center'}>
                            <label className="form-control-label w-50">Email Address</label>
                            <Input
                                placeholder="Email Address"
                                type="text"
                                onChange={(e) => setCustomer({
                                  ...customer,
                                  email: e.target.value
                                })}
                                invalid={errors && errors.email ? true : false}
                            />
                            <div className="invalid-feedback">
                              Please provide a email.
                            </div>
                          </FormGroup>
                          <FormGroup className={'d-flex align-items-center'}>
                            <label className="form-control-label w-50">Phone Number</label>
                            <Input
                                placeholder="Phone Number"
                                type="text"
                                onChange={(e) => setCustomer({
                                  ...customer,
                                  mobile_number: e.target.value
                                })}
                                invalid={errors && errors.mobile_number ? true : false}
                            />
                            <div className="invalid-feedback">
                              Please provide a phone number.
                            </div>
                          </FormGroup>
                          <FormGroup className={'d-flex align-items-center'}>
                            <label className="form-control-label w-50">Postal Code</label>
                            <Input
                                placeholder="Postal Code"
                                type="text"
                                onChange={(e) => {
                                  if (e.target.value.length > 4) {
                                    getAddressByPostcode(e.target.value)
                                  }
                                }}
                                invalid={errors && errors.postal_code ? true : false}
                            />
                            <div className="invalid-feedback">
                              {errors.postal_code}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup className={'d-flex align-items-center'}>
                            <label className="form-control-label w-50">Attention To</label>
                            <Input
                                placeholder="Attention To"
                                type="text"
                                value={customer?.attention_to ?? ''}
                                onChange={(e) => setCustomer({
                                  ...customer,
                                  attention_to: e.target.value
                                })}
                                invalid={errors && errors.attention_to ? true : false}
                            />
                            <div className="invalid-feedback">
                              Please provide a full name.
                            </div>
                          </FormGroup>
                          <FormGroup className={'d-flex align-items-center'}>
                            <label className="form-control-label w-50">N.I Number</label>
                            <Input
                                placeholder="N.I Number"
                                type="text"
                                onChange={(e) => setCustomer({
                                  ...customer,
                                  national_insurance_number: e.target.value
                                })}
                            />
                          </FormGroup>
                          <FormGroup className={'d-flex align-items-center'}>
                            <label className="form-control-label w-50">D/L Number</label>
                            <Input
                                placeholder="D/L Number"
                                type="text"
                                onChange={(e) => setCustomer({
                                  ...customer,
                                  driver_license_number: e.target.value
                                })}
                                invalid={errors && errors.driver_license_number ? true : false}
                            />
                            <div className="invalid-feedback">
                              Please provide a D/L Number.
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                        <h3 className={'main-color mb-3'}>Address details</h3>
                      <Row className={'phone-container'}>
                        <Col>
                          <FormGroup className={'d-flex align-items-center'}>
                            <label className="form-control-label w-50">Select the coresspondent address</label>
                            <Input
                                placeholder="Select the coresspondent address"
                                type="select"
                                onChange={(e) => {
                                  let selectedAddress = lookupAddresses.find(item => item.Udprn == e.target.value);
                                  setSelectedAddress(selectedAddress);
                                }}
                            >
                              {lookupAddresses.length == 0 && <option>No lookup address to select from</option>}
                              {lookupAddresses.length > 0 &&
                              <>
                                <option>-</option>
                                {lookupAddresses.map((item, index) => {
                                  return <option value={item.Udprn} key={index}>{item.SummaryAddress}</option>;
                                })}
                              </>
                              }
                            </Input>
                          </FormGroup>
                          <FormGroup className={'d-flex align-items-center'}>
                            <label className="form-control-label w-50">Address</label>
                            <Input
                                placeholder="Address"
                                type="text"
                                value={customer && customer.address ? customer.address : ''}
                                onChange={(e) => setCustomer({
                                  ...customer,
                                  address: e.target.value
                                })}
                                invalid={errors && errors.address ? true : false}
                            />
                            <div className="invalid-feedback">
                              Please provide an address.
                            </div>
                          </FormGroup>
                          <FormGroup className={'d-flex align-items-center'}>
                            <label className="form-control-label w-50">Alternate Number</label>
                            <Input
                                placeholder="Alternate Number"
                                type="text"
                                onChange={(e) => setCustomer({
                                  ...customer,
                                  land_line_number: e.target.value
                                })}
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup className={'d-flex align-items-center'}>
                            <label className="form-control-label w-50">Building</label>
                            <Input
                                placeholder="Building"
                                type="text"
                                value={customer.building ?? ''}
                                onChange={(e) => setCustomer({
                                  ...customer,
                                  building: e.target.value
                                })}
                            />
                          </FormGroup>
                          <FormGroup className={'d-flex align-items-center'}>
                            <label className="form-control-label w-50">Street</label>
                            <Input
                                placeholder="Street"
                                type="text"
                                value={customer?.street ?? ''}
                                onChange={(e) => setCustomer({
                                  ...customer,
                                  street: e.target.value
                                })}
                            />
                          </FormGroup>
                          <FormGroup className={'d-flex align-items-center'}>
                            <label className="form-control-label w-50">City</label>
                            <Input
                                placeholder="City"
                                type="text"
                                value={ customer?.city ?? ''}
                                onChange={(e) => setCustomer({
                                  ...customer,
                                  city: e.target.value
                                })}
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup className={'d-flex align-items-center'}>
                            <label className="form-control-label w-50">Country</label>
                            <Input
                                placeholder="D/L Number"
                                type="select"
                                onChange={(e) => setCustomer({
                                  ...customer,
                                  county_id: e.target.value
                                })}
                                invalid={errors && errors.county_id ? true : false}
                            >
                              <option>-</option>
                              {counties.length > 0 && counties.map((item, index) => {
                                return <option value={item.id} key={index}>{item.name}</option>
                              })}
                            </Input>
                            <div className="invalid-feedback">
                              Please provide a county.
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="detail_is_active"
                      >
                        Is active
                      </label>
                      <Row>
                        <Col>
                          <label className="custom-toggle">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setCustomer({
                                  ...customer,
                                  is_active: customer.is_active === 1 ? 0 : 1
                                })
                              }
                            />
                            <span
                              className="custom-toggle-slider rounded-circle"
                              data-label-off="No"
                              data-label-on="Yes"
                            />
                          </label>
                        </Col>
                      </Row>
                    </FormGroup>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader>
                    <h2 className="surtitle mb-0">Financial Information</h2>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={6} sm={12}>
                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-50">Bank name</label>
                          <Input
                            placeholder="Bank Name"
                            type="text"
                            onChange={(e) => setCustomer({
                              ...customer,
                              bank_name: e.target.value
                            })}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6} sm={12}>
                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-50">Bank sort code</label>
                          <Input
                            placeholder="Bank sort code"
                            type="text"
                            onChange={(e) => setCustomer({
                              ...customer,
                              bank_sort_code: e.target.value
                            })}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6} sm={12}>
                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-50">Bank account name</label>
                          <Input
                            placeholder="Bank account name"
                            type="text"
                            onChange={(e) => setCustomer({
                              ...customer,
                              bank_account_name: e.target.value
                            })}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6} sm={12}>
                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-50">Bank account number</label>
                          <Input
                            placeholder="Bank account number"
                            type="text"
                            onChange={(e) => setCustomer({
                              ...customer,
                              bank_account_number: e.target.value
                            })}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader>
                    <h2 className="surtitle mb-0">Company Information</h2>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={6} sm={12}>
                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-50">Business type</label>
                          <Input
                            placeholder="Full Name"
                            type="select"
                            onChange={(e) => setCustomer({
                              ...customer,
                              business_activity_id: e.target.value
                            })}
                            invalid={errors && errors.business_activity_id ? true : false}
                          >
                            <option>-</option>
                            {businessTypes.length > 0 && businessTypes.map((item, index) => {
                              return <option value={item.id} key={index}>{item.name}</option>;
                            })}
                          </Input>
                          <div className="invalid-feedback">
                            Please provide a business type.
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={6} sm={12}>
                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-50">Company type</label>
                          <Input
                            placeholder="Full Name"
                            type="select"
                            onChange={(e) => setCustomer({
                              ...customer,
                              company_type_id: e.target.value
                            })}
                            invalid={errors && errors.company_type_id ? true : false}
                          >
                            <option>-</option>
                            {companyTypes.length > 0 && companyTypes.map((item, index) => {
                              return <option value={item.id} key={index}>{item.name}</option>;
                            })}
                          </Input>
                          <div className="invalid-feedback">
                            Please provide a company type.
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={6} sm={12}>
                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-50">Company name</label>
                          <Input
                            placeholder="Company name"
                            type="text"
                            onChange={(e) => setCustomer({
                              ...customer,
                              company_name: e.target.value
                            })}
                            invalid={errors && errors.company_name ? true : false}
                          />
                          <div className="invalid-feedback">
                            Please provide a company name.
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={6} sm={12}>
                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-50">Business address</label>
                          <Input
                            placeholder="Business address"
                            type="text"
                            onChange={(e) => setCustomer({
                              ...customer,
                              business_address: e.target.value
                            })}
                            invalid={errors && errors.business_address ? true : false}
                          />
                          <div className="invalid-feedback">
                            Please provide a business address.
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={6} sm={12}>
                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-50">Insurance Company</label>
                          <Input
                            placeholder="Business phone number"
                            type="text"
                            onChange={(e) => setCustomer({
                              ...customer,
                              insurance_company: e.target.value
                            })}
                            invalid={errors && errors.insurance_company ? true : false}
                          />
                          <div className="invalid-feedback">
                            Please provide a Insurance company.
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={6} sm={12}>
                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-50">Insurance Excess</label>
                          <Input
                            placeholder="Insurance Excess"
                            type="text"
                            onChange={(e) => setCustomer({
                              ...customer,
                              insurance_excess: e.target.value
                            })}
                            invalid={errors && errors.insurance_excess ? true : false}
                          />
                          <div className="invalid-feedback">
                            Please provide a insurance excess value.
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <div className="d-flex flex-row justify-content-end">
                  <ButtonContainer onClick={() => goList(true)}>Back to list</ButtonContainer>
                  <ButtonContainer reverse onClick={() => addCustomer()}>Save</ButtonContainer>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}