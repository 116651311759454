import * as actionTypes from './actions';
import produce from 'immer';
import{ REQUEST_SUCCESS} from 'redux/action-types.js';

const initialState = {
  liveVehicles: [],
  liveVehicleEdit: null,
  vehicleFleet: {
    jobs: [],
    statusTypes: []
  },
  dealStatuses: [],
  soldTypes: [],
  liveVehicleMessage: null,
  liveVehicleFilters: {},
  hasSuccess: false,
  rows: null,
  stats: null,
  targets: null,
  refererUrl: null,
  summary: null,
  weekly_stats: null,
  rentalReport: null,
  saleReport: null,
  successAction: null
}

const vehicleState = produce((draft = initialState, action) => {
  switch (action.type) {
    case actionTypes.LIVE_VEHICLE_EDIT_SUCCESS:
      draft.liveVehicleEdit = action.vehicle;
      draft.dealStatuses = action.dealStatuses;
      draft.soldTypes = action.soldTypes;
      break;
    case actionTypes.FLEET_MANAGEMENT_VEHICLE_JOB_SUCCESS:
      draft.vehicleFleet.jobs = action.data.jobs.data;
      draft.vehicleFleet.statusTypes = action.data.statusTypes;
      break;
    case actionTypes.UPDATE_VEHICLE_SUCCESS:
      draft.liveVehicleEdit = action.vehicle;
      draft.liveVehicleMessage = action.message;
      break;
    case actionTypes.UPDATE_VEHICLE_REQUEST:
      draft.liveVehicleMessage = null;
      break
    case actionTypes.SET_FILTERS:
      draft.liveVehicleFilters[action.payload.type] = action.payload.filters;
      break
    case actionTypes.CLEAN_FILTERS:
      draft.liveVehicleFilters[action.payload.type] = {};
      break
    case actionTypes.LIVE_VEHICLE_STATS_SUCCESS:
      draft.rows = action.data.rows;
      draft.stats = action.data.stats;
      break;
    case actionTypes.LIVE_VEHICLE_SAVE__STATS_SUCCESS:
      draft.hasSuccess = true;
      break;
    case actionTypes.LIVE_VEHICLE_SAVE_STATS_REQUEST:
      draft.hasSuccess = false;
      break;
    case actionTypes.MONTHLY_TARGETS_SUCCESS:
      draft.targets = action.data.targets;
      break;
    case actionTypes.SAVE_MONTHLY_TARGETS_REQUEST:
      draft.successAction = null;
      break;
    case actionTypes.SET_REFERER:
      draft.refererUrl = action.payload.referer;
      break
    case actionTypes.CLEAN_REFERER:
      draft.refererUrl = null;;
      break
    case actionTypes.FETCH_MONTHLY_SUMMARY_SUCCESS:
      draft.weekly_stats = action.data.weekly_stats;
      draft.summary = action.data.summary;
      break;
    case actionTypes.FETCH_MONTHLY_SUMMARY_REQUEST:
      draft.weekly_stats = null;
      draft.summary = null;
      break;
    case actionTypes.FETCH_VEHICLE_REPORT_SUCCESS:
      draft.rentalReport = action.data.rentalsReport.sort((a,b) => b.count_model - a.count_model);
      draft.saleReport = action.data.salesReport.sort((a,b) => b.count_model - a.count_model);
      break;
    case actionTypes.FETCH_VEHICLE_REPORT_REQUEST:
      draft.rentalReport = null;
      draft.saleReport = null;
      break;
    case REQUEST_SUCCESS:
      draft.successAction = true;
      break;
    default:
      return draft;
  }
});

export default vehicleState;
