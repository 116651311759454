import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import NotificationAlert from 'react-notification-alert'
import CreatableSelect from "react-select/creatable";
import axios from 'axios'
import Cookies from 'js-cookie'
import moment from 'moment'

import * as constants from 'constants/index.js'
import CardsHeader from 'layouts/Headers/CardsHeader.js'

import InputCustom from 'views/pages/components/InputCustom'
import {
  InputArraySelect,
} from 'views/pages/components/InputSelect'
import InputToggle from 'views/pages/components/InputToggle'
import TableCustom from 'views/pages/components/TableCustom'

import BookModal from 'views/pages/components/BookModal'

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
  FormGroup,
} from 'reactstrap'

import {
  hasPermission
} from 'views/policies/FleetPolicy'

export default function VehicleInspect() {
  const { id } = useParams()
  const notificationAlert = useRef(null)
  const history = useHistory()
  const location = useLocation()
  
  const permissions = useSelector(state => state.getState).permissions
  const profileData = useSelector(state => state.getState).profileData

  const [token, setToken] = useState('')
  const [loading, setLoading] = useState(true)
  const [jobs, setJobs] = useState([])
  const [pagination, setPagination] = useState('')
  const [filter, setFilter] = useState('')
  const [statusTypes, setStatusTypes] = useState('')
  const [jobTypes, setJobTypes] = useState([])
  const [vehicle, setVehicle] = useState('')
  const [models, setModels] = useState([])
  const [bodyShopJobs, setBodyShopJobs] = useState([])
  const [valetingJobs,setValetingJobs] = useState([])
  const [mechanicalJobs, setMechanicalJobs] = useState([])
  const [jobsCategories, setJobsCategories] = useState('')
  const [dealerMembers, setDealerMembers] = useState([])

  const [bookModal, setBookModal] = useState({
    show: false,
    header: ''
  })
  const [errors, setErrors] = useState('')
  const [job, setJob ] = useState('')

  const [confirmModal, setConfirmModal] = useState({
    show: false,
    header: '',
    body: '',
    onClick: null,
  })

  const [jobModal, setJobModal] = useState({
    show: false,
    header: '',
    division: ''
  })

  const [deleteModal, setDeleteModal] = useState({
    show: false,
    header: '',
    body: '',
    onClick: null,
  })

  const [parts, setParts] = useState([])
  const [partsPagination, setPartsPagination] = useState('')
  const [partTypes, setPartTypes] = useState('')
  const [partStatuses, setPartStatuses] = useState([])

  const [part, setPart] = useState('')
  const [partModal, setPartModal] = useState({
    show: false,
    header: '',
    division: ''
  })
  const [partErrors, setPartErrors] = useState('')

  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    }
    notificationAlert.current.notificationAlert(options)
  }

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    fetchData(1, source, grs_token, '')
    fetchPartsData(1, source, grs_token)

    return () => {
      source.cancel()
    }
  }, [])

  const fetchData = async (pageNumber, source, token, filter) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/fleet-management/${id}/jobs`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ...filter,
          page: pageNumber,
          category: location.state.category? location.state.category : null
        },
        cancelToken: source.token,
      })
      console.log('response :: ', response.data)
      const data = response.data
      setJobs(data.data.jobs.data)
      setPagination(data.data.jobs)
      
      let _status_types = data.data.statusTypes
      let _statusTypes = []
      _status_types.map((type, index) => {
        _statusTypes.push({
          id: index,
          name: type
        })
      })
      setStatusTypes(_statusTypes)
      
      setJobTypes(data.data.jobTypes)
      setVehicle(data.data.vehicle)
      setModels(data.data.models)
      setBodyShopJobs(data.data.bodyShopJobs)
      setValetingJobs(data.data.valetingJobs)
      setMechanicalJobs(data.data.mechanicalJobs)
      setJobsCategories(data.data.jobsCategories)
      setDealerMembers(data.data.dealerMembers)

      setLoading(false)
    } catch (error) {
      setLoading(false)
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
      }
    }
  }

  const fetchPartsData = async (pageNumber, source, token) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/fleet-management/${id}/parts`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page: pageNumber,
        },
        cancelToken: source.token,
      })
      console.log('response :: ', response.data)
      const data = response.data
      setParts(data.data.parts.data)
      setPartsPagination(data.data.parts)
      
      let part_types = []
      let _part_types = data.data.partTypes || []
      _part_types.map(type => {
        part_types.push({
          value: type.id,
          label: type.part_type
        })
      })
      setPartTypes(part_types)

      setPartStatuses(data.data.partStatuses)

      setLoading(false)
    } catch (error) {    
      setLoading(false)
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
      }
    }
  }

  const goList = () => {
    history.push({
      pathname: location.state.category? `/admin/fleet-job/inspect/${location.state.category}` : '/admin/vehicle-in-stock'
    })
  }

  const bookJob = async (job) => {
    try {
      const response = await axios({
        method: 'PUT',
        url: `${constants.API_URL}/fleet-management/${id}/vehicle-jobs/${job.uuid}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          ...job,
          category: location.state.category? location.state.category : null,
          end_date: job.end_date ? job.end_date : job.status == 2 ? moment().format('YYYY-MM-DDTHH:mm:ss') : '',
        }
      })
      console.log('response :: ', response.data)
      const data = response.data
      setJobs(data.data.jobs.data)
      setPagination(data.data.jobs)

      notify('success', 'Success', data.message)
      setBookModal({
        ...bookModal,
        show: false
      })
      setConfirmModal({
        ...confirmModal,
        show: false
      })
    } catch (error) {
      console.error('error :: ', error)
      if (error.response && error.response.status === 422) {
        setErrors(error.response.data.errors)
        return
      }
      if (error.response && error.response.status === 430) {
        notify('info', 'Information', error.response.data.message)
        setBookModal({
          ...bookModal,
          show: false
        })
        return
      }
      setBookModal({
        ...bookModal,
        show: false
      })
      setConfirmModal({
        ...confirmModal,
        show: false
      })
      notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
    }
  }

  const editJob = (row) => {
    setJob({
      ...row,
      estimated_completion_at: row.estimated_completion_at ? moment(row.estimated_completion_at).format('YYYY-MM-DDTHH:mm:ss') : '',
      start_date: row.start_date ? moment(row.start_date).format('YYYY-MM-DDTHH:mm:ss') : '',
      end_date: row.end_date ? moment(row.end_date).format('YYYY-MM-DDTHH:mm:ss') : '',
    })
    setBookModal({
      show: true,
      header: row.booked_by ? 'Complete a job' : 'Start a job'
    })
  }

  const changeJobStatus = (row) => {
    setJob({
      ...row,
      status: 2,
      end_date: moment().format('YYYY-MM-DDTHH:mm:ss'),
    })

    setConfirmModal({
      ...confirmModal,
      show: true,
      header: 'Complete a Job',
      body: 'Are you sure you want to mark as completed this job?',
      onClick: () => bookJob({
        ...row,
        status: 2,
        end_date: moment().format('YYYY-MM-DDTHH:mm:ss'),
      })
    })
  }

  const makeHeader = (category) => {
    return `${category.slice(0, 1).toUpperCase()}${category.slice(1, category.length).replace('_', ' ').toLowerCase()}`
  }

  const createJob = async () => {    
    try {
      const response = await axios({
        method: 'POST',
        url: `${constants.API_URL}/fleet-management/${id}/vehicle-jobs`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          ...job,
          status: 0,
        },
      })
      console.log('response :: ', response.data)
      const data = response.data
      setJobs(data.data.jobs.data)
      setPagination(data.data.jobs)
      setBodyShopJobs(data.data.bodyShopJobs)
      setValetingJobs(data.data.valetingJobs)
      setMechanicalJobs(data.data.mechanicalJobs)

      notify('success', 'Success', data.message)

      setJobModal({
        ...jobModal,
        show: false
      })
    } catch (error) {
      console.error('error :: ', error)
      if (error.response && error.response.status === 422) {
        setErrors(error.response.data.errors)
        return;
      }
      setJobModal({
        ...jobModal,
        show: false
      })
      notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
    }
  }

  const deleteJob = async (job) => {
    try {
      const response = await axios({
        method: 'DELETE',
        url: `${constants.API_URL}/fleet-management/${id}/vehicle-jobs/${job.uuid}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      console.log('response :: ', response.data)
      const data = response.data
      setJobs(data.data.jobs.data)
      setPagination(data.data.jobs)
      setBodyShopJobs(data.data.bodyShopJobs)
      setValetingJobs(data.data.valetingJobs)
      setMechanicalJobs(data.data.mechanicalJobs)

      notify('success', 'Success', data.message)

      setDeleteModal({
        ...deleteModal,
        show: false
      })
    } catch (error) {
      console.error('error :: ', error)

      setDeleteModal({
        ...deleteModal,
        show: false
      })
      notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
    }
  }

  const createPart = async () => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${constants.API_URL}/fleet-management/${id}/parts`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          ...part,
          status: 0,
        },
      })
      console.log('response :: ', response.data)
      const data = response.data
      setParts(data.data.parts.data)
      setPartsPagination(data.data.parts)
      
      let part_types = []
      let _part_types = data.data.partTypes || []
      _part_types.map(type => {
        part_types.push({
          value: type.id,
          label: type.part_type
        })
      })
      setPartTypes(part_types)

      notify('success', 'Success', data.message)

      setPartModal({
        ...partModal,
        show: false
      })
    } catch (error) { 
      console.error('error :: ', error)
      if (error.response && error.response.status === 422) {
        setPartErrors(error.response.data.errors)
        return
      }
      setPartModal({
        ...partModal,
        show: false
      })
      notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
    }
  }

  const updatePart = async (part) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${constants.API_URL}/fleet-management/${id}/parts/${part.uuid}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: part,
      })
      console.log('response :: ', response.data)
      const data = response.data
      setParts(data.data.parts.data)
      setPartsPagination(data.data.parts)

      setConfirmModal({
        ...confirmModal,
        show: false
      })
      notify('success', 'Success', data.message)
    } catch (error) { 
      console.error('error :: ', error)
      setConfirmModal({
        ...confirmModal,
        show: false
      })
      notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
    }
  }

  return (
    <>
      <CardsHeader name="Leads" parentName="Leads Management" currentName="Create" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
				        <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">{location.state.category? `${makeHeader(location.state.category)} Jobs`: 'Vehicle Inspection'}</h1>
                  </Col>
                  <Col xs={4} className="text-right">
                    <Button className="w-100" color="success" type="button" onClick={() => goList()}>Back to list</Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <CardHeader>
                  <h1 className="title mb-0">General Vehicle Information</h1>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={4}>
                      <InputArraySelect
                        label={`Vehicle model`}
                        value={vehicle && vehicle.vehicle_model_id ? vehicle.vehicle_model_id : ''}
                        options={models}
                        disabled={true}
                        onChange={(e) => setVehicle({
                          ...vehicle,
                          vehicle_model_id: e.target.value,
                        })}
                        valueKey={`id`}
                        labelKey={`name`}
                        defaultOption={true}
                      />
                    </Col>
                    <Col md={4}>
                      <InputCustom
                        label={`Derivative`}
                        value={vehicle && vehicle.derivative ? vehicle.derivative : ''}
                        onChange={(e) => setVehicle({
                          ...vehicle,
                          derivative: e.target.value,
                        })}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <InputCustom
                        label={`Colour`}
                        value={vehicle && vehicle.colour ? vehicle.colour : ''}
                        onChange={(e) => setVehicle({
                          ...vehicle,
                          colour: e.target.value,
                        })}
                      />
                    </Col>
                    <Col md={4}>
                      <InputCustom
                        label={`Reg no`}
                        value={vehicle && vehicle.stock_no ? vehicle.stock_no : ''}
                        onChange={(e) => setVehicle({
                          ...vehicle,
                          stock_no: e.target.value,
                        })}
                      />
                    </Col>
                    <Col md={4}>
                      <InputCustom
                        label={`Current mileage`}
                        value={vehicle && vehicle.current_mileage ? vehicle.current_mileage : ''}
                        onChange={(e) => setVehicle({
                          ...vehicle,
                          current_mileage: e.target.value,
                        })}
                      />
                    </Col>
                  </Row>
                </CardBody>
                <CardHeader>
                  <h1 className="title mb-0">Vehicle Jobs</h1>
                </CardHeader>
                {hasPermission(permissions, 'read-fleet-management') && 
                <CardBody>
                  {!location.state.category && 
                  <Container>
                    <Row>
                      <Col md={4} sm={12} xs={12}>
                        <InputToggle
                          label={`Need Service`}
                          checked={valetingJobs && valetingJobs.length > 0 ? true : false}
                          onChange={() => { }}
                        />
                      </Col>
                      <Col md={4} sm={12} xs={12}>
                        <InputToggle
                          label={`Need Bodywork`}
                          checked={bodyShopJobs && bodyShopJobs.length > 0 ? true : false}
                          onChange={() => { }}
                        />
                      </Col>
                      <Col md={4} sm={12} xs={12}>
                        <InputToggle
                          label={`Need Mechanical`}
                          checked={mechanicalJobs && mechanicalJobs.length > 0 ? true : false}
                          onChange={() => { }}
                        />
                      </Col>
                    </Row>
                  </Container>}
                  <Row>
                    <Col xs={8}>
                    </Col>
                    <Col xs={4} className="text-right">
                      <Button className="w-100" color="success" type="button" onClick={() => {
                        setJob('')
                        setErrors('')
                        setJobModal({
                          ...jobModal,
                          show: true,
                          header: 'Create new job',
                          division: 'create'
                        })
                      }}>Add more work</Button>
                    </Col>
                  </Row>
                </CardBody>}
                <TableCustom 
                  loading={loading}
                  items={jobs}
                  pagination={pagination}
                  onChange={pageNumber => fetchData(pageNumber, axios.CancelToken.source(), token, filter)}
                  columns={[
                    {
                      dataField: "name",
                      text: "Type of work",
                    },
                    {
                      dataField: "description",
                      text: "Work needed"
                    },
                    {
                      dataField: "eta_hours",
                      text: "ETA Hours"
                    },
                    {
                      dataField: "estimated_completion_at",
                      text: "Estimated Completion Date",
                      formatter: (rowContent, row) => {
                        return row.estimated_completion_at? constants.formatDateShortGB(row.estimated_completion_at): ''
                      }
                    },
                    {
                      dataField: "status",
                      text: "Status",
                      formatter: (rowContent, row) => {
                        if (row.start_date && row.booked_by && row.status === 1) {
                          return `In progress from ${constants.formatDateShortGB(row.start_date)}`
                        } else if (row.start_date && row.booked_by && row.end_date && row.status === 2) {
                          return statusTypes? `${statusTypes[row.status].name} in ${constants.formatDateShortGB(row.end_date)}`: ''
                        } else {
                          return statusTypes? statusTypes[row.status].name: ''
                        }
                      }
                    },
                    {
                      dataField: "",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            {hasPermission(permissions, 'update-fleet-management') &&
                              row.status !== 2 &&
                              <Button className="fleet-table-button" color="success" type="button" onClick={() => {
                                setErrors('')
                                editJob(row)
                              }}>
                                {`Edit`}
                              </Button>
                            }
                            {!hasPermission(permissions, 'update-fleet-management') &&
                              (row.status !== 2 && row.booked_by && row.booked_by === profileData.id) &&
                              <Button className="fleet-table-button" color="success" type="button" onClick={() => changeJobStatus(row)}>
                                {`Mark As Completed`}
                              </Button>
                            }
                            {hasPermission(permissions, 'delete-fleet-management') && !row.booked_by && !location.state.category &&
                              <Button className="fleet-table-button" color="danger" type="button" onClick={() => setDeleteModal({
                                ...deleteModal,
                                show: true,
                                header: 'Delete job',
                                body: 'Are you sure you want to delete this job?',
                                onClick: () => deleteJob(row)
                              })}>
                                {`Delete`}
                              </Button>
                            }
                          </>
                        )
                      }
                    }
                  ]}
                />
                {hasPermission(permissions, 'create-fleet-management') &&
                  <>
                    <CardHeader>
                      <h1 className="title mb-0">Vehicle Parts</h1>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col xs={8}>
                        </Col>
                        <Col xs={4} className="text-right">
                          {hasPermission(permissions, 'create-fleet-management') &&
                            <Button className="w-100" color="success" type="button" onClick={() => {
                              setPart('')
                              setPartErrors('')
                              setPartModal({
                                ...partModal,
                                show: true,
                                header: 'Create new part',
                                division: 'create'
                              })
                            }}>Add more part</Button>}
                        </Col>
                      </Row>
                    </CardBody>
                    <TableCustom
                      loading={loading}
                      items={parts}
                      pagination={partsPagination}
                      onChange={pageNumber => fetchPartsData(pageNumber, axios.CancelToken.source(), token)}
                      columns={[
                        {
                          dataField: "part_type_id",
                          text: "Parts",
                          formatter: (rowContent, row) => {
                            return row.part_type ? row.part_type.part_type : ''
                          }
                        },
                        {
                          dataField: "status",
                          text: "Status",
                          formatter: (rowContent, row) => {
                            return partStatuses[row.status]
                          }
                        },
                        {
                          dataField: "",
                          text: "Action",
                          formatter: (rowContent, row) => {
                            return (
                              <>
                                {hasPermission(permissions, 'update-fleet-management') &&
                                  row.status == 0 &&
                                  <Button className="fleet-table-button" color="success" type="button" onClick={() => {
                                    setConfirmModal({
                                      ...confirmModal,
                                      show: true,
                                      header: 'Update part status',
                                      body: 'Are you sure you ordered this part?',
                                      onClick: () => updatePart({
                                        ...row,
                                        status: 1
                                      })
                                    })
                                  }}>
                                    {`Ordered`}
                                  </Button>
                                }
                                {hasPermission(permissions, 'update-fleet-management') &&
                                  row.status <= 1 &&
                                  <Button className="fleet-table-button" color="success" type="button" onClick={() => {
                                    setConfirmModal({
                                      ...confirmModal,
                                      show: true,
                                      header: 'Update part status',
                                      body: 'Are you sure you have this part here?',
                                      onClick: () => updatePart({
                                        ...row,
                                        status: 2
                                      })
                                    })
                                  }}>
                                    {`Here`}
                                  </Button>
                                }
                              </>
                            )
                          }
                        }
                      ]}
                    />
                  </>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
        <BookModal 
          bookModal={bookModal}
          setBookModal={setBookModal}
          jobTypes={jobTypes}
          job={job}
          setJob={setJob}
          errors={errors}
          statusTypes={statusTypes}
          dealerMembers={dealerMembers}
          bookJob={() => bookJob(job)}
        />
        <Modal
          isOpen={jobModal.show}
          toggle={() => setJobModal({
            ...jobModal,
            show: false
          })}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title">{jobModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setJobModal({
                ...jobModal,
                show: false
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <InputArraySelect 
              label={`Type Of Work`}
              options={jobTypes}
              value={job && job.job_type_id? job.job_type_id: ''}
              onChange={e => setJob({
                ...job,
                job_type_id: e.target.value
              })}
              defaultOption={true}
              valueKey={`id`}
              labelKey={`name`}
              invalid={errors && errors.job_type_id? true: false}
              errorMessage={errors.job_type_id}
            />
            <InputCustom 
              type={`textarea`}
              rows={5}
              label={`Work Needed`}
              value={job && job.description? job.description: ''}
              onChange={e => setJob({
                ...job,
                description: e.target.value
              })}
              invalid={errors && errors.description? true: false}
              errorMessage={errors.description}
            />
          </div>
          <div className="modal-footer">
            <Button
              className="new-event--add"
              color="primary"
              type="button"
              onClick={() => createJob()}
            >
              {`Save`}
            </Button>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setJobModal({
                ...jobModal,
                show: false
              })}
            >
              {`Close`}
            </Button>
          </div>
        </Modal>
        <Modal
          isOpen={confirmModal.show}
          toggle={() => setConfirmModal({
            ...confirmModal,
            show: false
          })}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title">{confirmModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setConfirmModal({
                ...confirmModal,
                show: false
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {confirmModal.body}
          </div>
          <div className="modal-footer">
            <Button
              className="new-event--add"
              color="primary"
              type="button"
              onClick={confirmModal.onClick}
            >
              {`Confirm`}
            </Button>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setConfirmModal({
                ...confirmModal,
                show: false
              })}
            >
              {`Close`}
            </Button>
          </div>
        </Modal>
        <Modal
          isOpen={deleteModal.show}
          toggle={() => setDeleteModal({
            ...deleteModal,
            show: false
          })}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title">{deleteModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setDeleteModal({
                ...deleteModal,
                show: false
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {deleteModal.body}
          </div>
          <div className="modal-footer">
            <Button
              className="new-event--add"
              color="danger"
              type="button"
              onClick={deleteModal.onClick}
            >
              {`Delete`}
            </Button>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setDeleteModal({
                ...deleteModal,
                show: false
              })}
            >
              {`Close`}
            </Button>
          </div>
        </Modal>
        <Modal
          isOpen={partModal.show}
          toggle={() => setPartModal({
            ...partModal,
            show: false,
          })}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h4 className="text-center">{partModal.header}</h4>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setPartModal({
                ...partModal,
                show: false,
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <FormGroup>
              <label className="form-control-label">Type</label>
              {partModal.division == 'update' ? 
              <CreatableSelect
                isClearable
                value={part && part.part_type_id ? (partTypes.find((element) => element.value == part.part_type_id) || '') : ''}
                onChange={(item) => {
                  setPart({
                    ...part,
                    part_type_id: item ? item.value : null,
                  })
                }}
                options={partTypes}
              /> : <CreatableSelect
                  isClearable
                  onChange={(item) => {
                    setPart({
                      ...part,
                      part_type_id: item ? item.value : null,
                    })
                  }}
                  options={partTypes}
                />}
              {partErrors && partErrors.part_type_id && <div className="invalid-feedback" style={{ display: 'block' }}>
                {partErrors.part_type_id}
              </div>}
            </FormGroup>
          </div>
          <div className="modal-footer">
            <Button
              className="new-event--add"
              color="primary"
              type="button"
              onClick={() => partModal.division == 'create' ? createPart() : updatePart()}
            >
              Save
            </Button>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setPartModal({
                ...partModal,
                show: false,
              })}
            >
              Close
            </Button>
          </div>
        </Modal>
      </Container>
    </>
  )
}