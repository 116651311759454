import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import NotificationAlert from 'react-notification-alert'
import axios from 'axios'
import Cookies from 'js-cookie'

import * as constants from 'constants/index.js'
import CardsHeader from 'layouts/Headers/CardsHeader.js'

import InputCustom from 'views/pages/components/InputCustom'
import TableCustom from 'views/pages/components/TableCustom'

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
} from 'reactstrap'
import {ButtonContainer} from "@pages/reusable-components/styled-components";

export default function Areas() {
  const notificationAlert = useRef(null)
  const history = useHistory()

  const [token, setToken] = useState('')
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([])

  const [item, setItem] = useState('')
  const [itemModal, setItemModal] = useState({
    show: false,
    header: '',
    division: ''
  })

  const [deleteModal, setDeleteModal] = useState({
    show: false,
    header: '',
    body: '',
    onClick: null,
  })
  
  const [errors, setErrors] = useState('')

  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    }
    notificationAlert.current.notificationAlert(options)
  }

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/types/areas`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data
        setItems(data.data.items)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const createItem = async () => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${constants.API_URL}/types/areas`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: item,
      })
      console.log('response :: ', response.data)
      const data = response.data
      setItems(data.data.items)
      notify('success', 'Success', data.message)
      setItemModal({
        show: false
      })
    } catch (error) {
      console.error('error :: ', error)
      if (error.response && error.response.status === 422) {
        setErrors(error.response.data.errors)
        return
      }
      setItemModal({
        show: false
      })
      notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
    }
  }

  const updateItem = async () => {
    try {
      const response = await axios({
        method: 'PUT',
        url: `${constants.API_URL}/types/areas/${item.id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: item,
      })
      console.log('response :: ', response.data)
      const data = response.data
      setItems(data.data.items)
      notify('success', 'Success', data.message)
      setItemModal({
        show: false
      })
    } catch (error) {
      console.error('error :: ', error)
      if (error.response && error.response.status === 422) {
        setErrors(error.response.data.errors)
        return
      }
      setItemModal({
        show: false
      })
      notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
    }
  }

  const deleteItem = async (id) => {
    try {
      const response = await axios({
        method: 'DELETE',
        url: `${constants.API_URL}/types/areas/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      console.log('response :: ', response.data)
      const data = response.data
      setItems(data.data.items)
      notify('success', 'Success', data.message)
      setDeleteModal({
        show: false
      })
    } catch (error) {
      console.error('error :: ', error)
      setDeleteModal({
        show: false
      })
      notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
    }
  }

  return (
    <>
      <CardsHeader name="Areas" parentName="Areas Management" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Areas</h1>
                    <p className="text-sm mb-0">Areas management</p>
                  </Col>
                  <Col xs={4} className="text-right">
                    <Button className="w-100" color="success" type="button" onClick={() => {
                      setItem('')
                      setErrors('')
                      setItemModal({
                        ...itemModal,
                        show: true,
                        header: `Area Details`.toUpperCase(),
                        division: 'create'
                      })
                    }}>Add new</Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <TableCustom 
                  loading={loading}
                  items={items}
                  columns={[
                    {
                      dataField: "name",
                      text: "Title"
                    },
                    {
                      dataField: "",
                      text: "Action",
                      style: {
                        textAlign: 'right'
                      },
                      formatter: (rowContent, row) => {
                        return (
                          <>
                                <img className={'cursor-pointer'}
                                     onClick={e => {
                                       setItem(row)
                                       setErrors('')
                                       setItemModal({
                                         ...itemModal,
                                         show: true,
                                         header: `Area details update`.toUpperCase(),
                                         division: 'update',
                                       })
                                     }}
                                     src={require(`assets/images/tableIcons/edit.svg`)} alt=""/>
                                <img className={'cursor-pointer'}
                                     onClick={e => {
                                       setDeleteModal({
                                         ...deleteModal,
                                         show: true,
                                         header: 'Delete area',
                                         body: 'Are you sure you want to delete this area?',
                                         onClick: () => deleteItem(row.id)
                                       })
                                     }}
                                     src={require(`assets/images/tableIcons/delete_outline.svg`)} alt=""/>
                          </>
                        )
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={itemModal.show}
          toggle={() => setItemModal({
            ...itemModal,
            show: false,
          })}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title main-color">{itemModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setItemModal({
                ...itemModal,
                show: false,
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <InputCustom 
              newLabel={`Name`}
              value={item && item.name? item.name: ''}
              onChange={e => setItem({
                ...item,
                name: e.target.value,
              })}
              invalid={errors && errors.name? true: false}
              errorMessage={errors.name}
            />
            <InputCustom
              newLabel={`Frontend Name`}
              value={item && item.frontend_name? item.frontend_name: ''}
              onChange={e => setItem({
                ...item,
                frontend_name: e.target.value,
              })}
              invalid={errors && errors.frontend_name? true: false}
              errorMessage={errors.frontend_name}
            />
            <InputCustom 
              type={`number`}
              newLabel={`Sort ( position )`}
              value={item && item.sort? item.sort: ''}
              onChange={e => setItem({
                ...item,
                sort: e.target.value,
              })}
              invalid={errors && errors.sort? true: false}
              errorMessage={errors.sort}
            />
          </div>
          <div className="modal-footer justify-content-end">
            <ButtonContainer
                onClick={() => setItemModal({
                  ...itemModal,
                  show: false,
                })}
            >
              {`Close`}
            </ButtonContainer>
            <ButtonContainer
              reverse
              onClick={() => itemModal.division === 'create'? createItem() : updateItem()}
            >
              {`Save`}
            </ButtonContainer>

          </div>
        </Modal>
        <Modal
          isOpen={deleteModal.show}
          toggle={() => setDeleteModal({
            ...deleteModal,
            show: false,
          })}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title main-color">{deleteModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setDeleteModal({
                ...deleteModal,
                show: false,
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {deleteModal.body}
          </div>
          <div className="modal-footer justify-content-end">
            <ButtonContainer
                onClick={() => setDeleteModal({
                  ...deleteModal,
                  show: false,
                })}
            >
              {`Close`}
            </ButtonContainer>
            <ButtonContainer
              reverse
              onClick={deleteModal.onClick}
            >
              {`Delete`}
            </ButtonContainer>

          </div>
        </Modal>
      </Container>
    </>
  )
}