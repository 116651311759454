export const BASE_URL    = process.env.REACT_APP_BASE_URL;
export const SITE_CODE   = process.env.REACT_APP_SITE_CODE;
export const CURRENCY    = process.env.REACT_APP_CURRENCY;
export const TINYMCE_KEY = process.env.REACT_APP_TINYMCE_KEY;

export const API_URL     = `${BASE_URL}/api/v1`;
export const FLEET_URL     = `${BASE_URL}/api/v2`;
export const STORAGE_URL = `${BASE_URL}/storage/`;

export const TRACKING_CLIENT_ID     = process.env.REACT_APP_TRACKING_CLIENT_ID;
export const TRACKING_CLIENT_SECRET = process.env.REACT_APP_TRACKING_CLIENT_SECRET;
export const TRACKING_URL           = process.env.REACT_APP_TRACKING_URL;

export const FLESPI_API_HOST  = process.env.REACT_APP_FLESPI_API_HOST;
export const FLESPI_API_TOKEN = process.env.REACT_APP_FLESPI_API_TOKEN;

export const PUSHER_APP_ID      = process.env.REACT_APP_PUSHER_APP_ID;
export const PUSHER_APP_KEY     = process.env.REACT_APP_PUSHER_APP_KEY;
export const PUSHER_APP_SECRET  = process.env.REACT_APP_PUSHER_APP_SECRET;
export const PUSHER_APP_CLUSTER = process.env.REACT_APP_PUSHER_APP_CLUSTER;

export const UK_VEHICLE_DATA_BASE_URL = process.env.REACT_APP_UK_VEHICLE_DATA_API_HOST;
export const UK_VEHICLE_DATA_API_URL  = `${UK_VEHICLE_DATA_BASE_URL}/api/datapackage`;
export const UK_VEHICLE_DATA_API_KEY  = process.env.REACT_APP_UK_VEHICLE_DATA_API_KEY;

export const formatDate = (d) => {
  if (d) {
    let date = new Date(d);
    let ye = Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
    let mo = Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
    let da = Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

    return `${ye}-${mo}-${da}`
  }
}

export const formatDateShortGB = (d) => {
  if (d) {
    let date = new Date(d);
    let ye = Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
    let mo = Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
    let da = Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

    return `${da}/${mo}/${ye}`
  }
}

export const formatDateFullGB = (d) => {
  if (d) {
    let date = new Date(d);

    let options = {
      year: 'numeric', month: '2-digit', day: '2-digit',
      hour: '2-digit', minute: '2-digit', second: '2-digit',
      hour12: false
    }

    let formatted = Intl.DateTimeFormat('en-BG', options).format(date);

    return formatted
  }
}

export const formatDateHoursSeconds = (d) => {
  if (d) {
    let date = new Date(d);
    let ye = Intl.DateTimeFormat( 'en', { year: 'numeric' }).format(date);
    let mo = Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
    let da = Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
    let ho = Intl.DateTimeFormat('en', { hour: '2-digit', hour12: false }).format(date);
    let mi = Intl.DateTimeFormat('en', { minute: '2-digit' }).format(date);

    return `${ye}-${mo}-${da}T${ho}:${mi}`
  }
}

export const getRandomColor = () => {
  let letters = '0123456789ABCDEF'.split('');
  let color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const getColorsForSources = (labels) => {
  let colors = localStorage.getItem('sourcesColors') !== null ? JSON.parse(localStorage.getItem('sourcesColors')) : {};

  let bgColors = [];
  let shouldUpdate = false;

  if (Array.isArray(labels)) {
    labels.forEach(element => {
      if (colors[element]) {
        bgColors.push(colors[element]);
      } else {
        let tempColor = getRandomColor();
        bgColors.push(tempColor);
        colors[element] = tempColor;
        shouldUpdate = true;
      }
    });
  } else {
    if (colors[labels]) {
      return colors[labels]
    } else {
      let tempColor = getRandomColor();
      colors[labels] = tempColor;
      window.localStorage.setItem('sourcesColors', JSON.stringify(colors));

      return tempColor;
    }
  }

  if (shouldUpdate) {
    window.localStorage.setItem('sourcesColors', JSON.stringify(colors))
  }

  return bgColors;
}

export const formatString = (str, num) => {
  return str.slice(0, num) + '..';
}

export const convertKmToMile = (val) => {
  return Math.round(val * 0.621371);
}
export const parseToWord = (name) => {
  if (name) {
    let text = name.replace(/_+/g, ' ').toLowerCase();
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
}
export const parseToKey = (name) => {
       if (name) {
         return name.replace(/\s+/g, '_').toLowerCase();
       }
    }

export const returnFirstLetter = (word) => {
  return word?.charAt(0).toUpperCase();
}
export const propertiesSumInArray= (array,key) => {
 return  array.reduce((val, object) => {
    return Number(val) + Number(object?.[key]);
  }, 0)
}