import { put, takeLatest, call } from 'redux-saga/effects';
import * as actionTypes from './actions';
import axios from '../../config/axios';
import { updatePayment } from 'api/payments.api';

function* getPaymentData(action) {
  try {

    const response = yield axios({
      method: 'GET',
      url: `/deal/${action.id}/payments/${action.scheduleId}`,
    })

    if (response?.status === 200) {
      const { data } = response.data;
      yield put({
        type: actionTypes.GET_PAYMENT_DETAILS_SUCCESS,
        data,
        scheduleId: action.scheduleId,
      });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.GET_PAYMENT_DETAILS_ERROR, payload });
  }
}

function* saveInvoiceNote(action) {
  try {
    let { payload } = action;

    const response = yield axios({
      method: 'post',
      url: `/deal/${payload.dealId}/payment/${payload.paymentId}/note`,
      data: { note: payload.note }
    })

    if (response?.status === 200) {
      const { data } = response.data;

      yield put({
        type: actionTypes.CREATE_INVOICE_NOTE_SUCCESS,
        data,
        scheduleId: action.scheduleId,
      });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.CREATE_INVOICE_NOTE_ERROR, payload });
  }
}

function* sendEmail(action) {
  try {
    let { payload } = action;

    const response = yield axios({
      method: 'post',
      url: `/deal/${payload.dealId}/payment/${payload.scheduleId}/email`
    })

    if (response?.status === 200) {
      const { data } = response.data;

      yield put({
        type: actionTypes.SEND_PAYMENT_INVOICE_EMAIL_SUCCESS,
        data,
        scheduleId: action.scheduleId,
      });
    }
  } catch (error) {
    // let payload = error.response.data;
    console.log(error)
    yield put({ type: actionTypes.SEND_PAYMENT_INVOICE_EMAIL_ERROR });
  }
}

function* getSalesAccounts(action) {
  try {
    const response = yield axios({
      method: 'get',
      url: `/dealer/xero/products`
    })

    if (response?.status === 200) {
      const { data } = response.data;

      yield put({
        type: actionTypes.GET_SALES_ACCOUNTS_SUCCESS,
        data
      });
    }
  } catch (error) {
    // let payload = error.response.data;
    console.log(error)
    yield put({ type: actionTypes.GET_SALES_ACCOUNTS_ERROR });
  }
}

function* getTaxes(action) {
  try {

    const response = yield axios({
      method: 'get',
      url: `/dealer/xero/taxes`
    })

    if (response?.status === 200) {
      const { data } = response.data;

      yield put({
        type: actionTypes.GET_TAXES_SUCCESS,
        data
      });
    }
  } catch (error) {
    // let payload = error.response.data;
    yield put({ type: actionTypes.GET_TAXES_ERROR });
  }
}

function* postUpdateInvoicePayement(action) {
  try {
    const response = yield call(updatePayment, action.payload);

    if (response?.status === 200) {
      const { data } = response.data;

      yield put({
        type: actionTypes.UPDATE_INVOICE_SUCCESS,
        data
      });
    }
  } catch (error) {
    // let payload = error.response.data;
    yield put({ type: actionTypes.UPDATE_INVOICE_ERROR, message: error.response.data?.message ?? null });
  }
}

export default function* watcherSaga() {
  yield takeLatest(actionTypes.GET_PAYMENT_DETAILS_REQUEST, getPaymentData);
  yield takeLatest(actionTypes.CREATE_INVOICE_NOTE_REQUEST, saveInvoiceNote);
  yield takeLatest(actionTypes.SEND_PAYMENT_INVOICE_EMAIL_REQUEST, sendEmail);
  yield takeLatest(actionTypes.GET_SALES_ACCOUNTS_REQUEST, getSalesAccounts);
  yield takeLatest(actionTypes.UPDATE_INVOICE_REQUEST, postUpdateInvoicePayement);
  yield takeLatest(actionTypes.GET_TAXES_REQUEST, getTaxes);
}
