import React, { useContext } from 'react';

import { DealContext } from '../context';

import {
  Card,
  CardHeader,
  CardBody,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
} from "reactstrap";

import InputCustom from 'views/pages/components/InputCustom';
import TableCustom from 'views/pages/components/TableCustom';

import { 
  deletee,
  hasPermission,
} from 'views/policies/DealPolicy';
import {ButtonContainer} from "@pages/reusable-components/styled-components";

export default function NoteTab() {
  const dealContext = useContext(DealContext);
  const {
    profilePermissions,
    siteCode,
    authUserTeam,
    allowEdit,
    deal,
    deleteModal, setDeleteModal,
    addNoteModal, setAddNoteModal,
    noteDelay,
    formatDateNote,
    deleteNote,
    note, setNote,
    addNote,
  } = dealContext;
  return (
    <CardBody>
      <Card>
        <CardHeader>
          <div className="d-flex justify-content-between">
            <div>
              <h2 className="title mb-0">Notes</h2>
            </div>
            {hasPermission(profilePermissions, 'create-deals-tasks') && allowEdit && <Button className="h-50" color="warning" type="button" onClick={() => setAddNoteModal(true)}>Add note</Button>}
          </div>
        </CardHeader>
        <CardBody>
          {<TableCustom
            loading={false}
            delay={noteDelay}
            items={deal.notes}
            keyField={`task`}
            searchField={true}
            columns={[
              {
                dataField: "task",
                text: "Note",
                sort: true
              },
              {
                dataField: "created_at",
                text: "Created at",
                sort: true,
                formatter: (rowContent, row) => {
                  return formatDateNote(row.created_at)
                }
              },
              {
                dataField: "",
                text: "Action",
                formatter: (rowContent, row) => {
                  return (
                      hasPermission(profilePermissions, 'update-deals-notes') && hasPermission(profilePermissions, 'delete-deals-notes') &&
                     <>
                          {deletee(profilePermissions, authUserTeam, deal, siteCode) &&

                                <img className={'cursor-pointer'}  onClick={e => {
                                  setDeleteModal({
                                    ...deleteModal,
                                    show: true,
                                    header: 'Delete note',
                                    body: 'Are you sure to delete this note?',
                                    onClick: () => deleteNote(row.id)
                                  })
                                }}  src={require(`assets/images/tableIcons/delete_outline.svg`)} alt=""/>
                            }
                     </>
                  )
                }
              }
            ]}
          />}
        </CardBody>
      </Card>      
      <Modal
        isOpen={deleteModal.show}
        toggle={() => setDeleteModal({
          ...deleteModal,
          show: false
        })}
        className="modal-dialog-centered"
      >
        <div className="modal-header">
          <h6 className="modal-title main-color">{deleteModal.header}</h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setDeleteModal({
              ...deleteModal,
              show: false
            })}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body text-center">
          <span>{deleteModal.body}</span>
        </div>
        <div className="modal-footer justify-content-center">

          <Button className="w-25" color="lighte" type="button" onClick={() => setDeleteModal({
            ...deleteModal,
            show: false
          })}>Cancel</Button>
          <Button className="w-25" color="warning"  onClick={deleteModal.onClick}>Delete</Button>
        </div>
      </Modal>
      <Modal
        isOpen={addNoteModal}
        toggle={() => setAddNoteModal(false)}
        className="modal-dialog-centered"
      >
        <div className="modal-header">
          <h6 style={{color: '#ec6409'}} className="modal-title">{`Add note`}</h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setAddNoteModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <InputCustom
            type={`textarea`}
            newLabel={`Note`}
            value={note}
            rows={5}
            onChange={(e) => setNote(e.target.value)}
          />
        </div>
        <div className="modal-footer justify-content-end">
            <ButtonContainer  onClick={() => {
              setAddNoteModal(false)
            }}>Cancel</ButtonContainer>
            <ButtonContainer reverse onClick={() => addNote()}>Save</ButtonContainer>
        </div>
      </Modal>
    </CardBody>
  );
}