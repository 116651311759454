import React, { useEffect, useState, useRef } from 'react';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import TableCustom from 'views/pages/components/TableCustom';

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
} from 'reactstrap';
import {ButtonContainer} from "@pages/reusable-components/styled-components";

export default function AutomatedReminders() {
  const notificationAlert = useRef(null);

  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [delay, setDelay] = useState(false);
  const [reminders, setReminders] = useState([]);
  const [roles, setRoles] = useState([]);
  const [rolesAssigned, setRolesAssigned] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/customization/automated-reminders`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data

        setReminders(data.data.items)
        setRoles(data.data.roles)
        setRolesAssigned(data.data.rolesAssigned)

        setTimeout(() => {
          setLoading(false)
          setDelay(true)
        }, 500);
      } catch (error) {
        setLoading(false)
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const updateReminders = () => {
    let formData = new FormData()
    Object.keys(rolesAssigned).map(key => {
      formData.append(`role[${key}]`, rolesAssigned[key] == '-'? 0: rolesAssigned[key])
    })
    axios({
      method: 'POST',
      url: `${constants.API_URL}/customization/automated-reminders`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: formData
    })
      .then(response => {
        console.log('res :: ', response.data)
        let data = response.data
        notify('success', 'Success', data.message)
      })
      .catch(error => {
        setLoading(false)
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
      })
  }

  const assignRole = (roleId, val) => {
    setDelay(false)
    const roleAss = {...rolesAssigned}
    roleAss[roleId] = val
    setRolesAssigned(roleAss)
    setTimeout(() => {
      setDelay(true)
    }, 10);
  }

  return (
    <>
      <CardsHeader name="Settings" parentName="News Management" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <TableCustom 
                  loading={loading}
                  delay={delay}
                  keyField={`id`}
                  searchField={false}
                  items={reminders}
                  columns={[
                    {
                      dataField: "title",
                      text: "Title",
                      sort: true
                    },
                    {
                      dataField: "description",
                      text: "Description",
                      sort: true
                    },
                    {
                      dataField: "role",
                      text: "Role",
                      sort: false,
                      formatter: (rowContent, row) => {
                        return (
                          <Input 
                            type="select"
                            value={rolesAssigned && rolesAssigned[row.id]? rolesAssigned[row.id]: ''}
                            onChange={e => assignRole(row.id, e.target.value)}
                          >
                            <option>-</option>
                            { roles && roles.length > 0 &&
                              roles.map((role, index) => {
                                return <option value={role.id} key={index}>{role.name}</option>
                              })
                            }
                          </Input>
                        )
                      }
                    }
                  ]}
                />
                <div className="d-flex flex-row justify-content-end mt-5">
                  <ButtonContainer reverse onClick={() => updateReminders()}>Save</ButtonContainer>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}