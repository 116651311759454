import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import * as constants from 'constants/index.js';

import { css } from '@emotion/core';
import { ClipLoader } from 'react-spinners';

import {
  Container,
} from 'reactstrap';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: blue;
`;

export function XeroCallback() {
  const history = useHistory();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const code = params.get('code');
  const state = params.get('state');

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')

    const base_url = window.location.origin
    axios({
      method: 'GET',
      url: `${constants.API_URL}/tracking-authorize-callback`,
      headers: {
        Authorization: `Bearer ${grs_token}`
      },
      params: {
        code: code,
        state: state,
        redirect_url: `${base_url}/admin/tracking/account-integration-callback`,
      }
    })
      .then(response => {
        document.location.href = `${base_url}/admin/tracking/index`
      })
      .catch(error => {
        console.log('error :: ', error.response)
        const message = error.response && error.response.data ? error.response.data.message : 'Error occurred.'
        history.push({
          pathname: `/admin/tracking/account-integration`,
          state: {
            message: message
          }
        })        
      })
  }, [])

  return (
    <>
      <Container className="mt--5 admin-main-body" fluid >
        <div style={{ position: 'absolute', top: '40%', right: '50%' }}>
          <ClipLoader
            css={override}
            size={40}
            color={`#7B61E4`}
            loading={true}
          />
        </div>
      </Container>
    </>
  )
}