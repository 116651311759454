import React, {useEffect, useState, useRef} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import axios from 'axios'
import Cookies from 'js-cookie'
import * as constants from 'constants/index.js'


import CreateEditTask from "./components/CreateEditTask";
import {useSelector} from "react-redux";


export default function EditTask() {
    const location = useLocation();
    const history = useHistory()
    const [token, setToken] = useState('')
    const [taskId, setTaskId] = useState(null)
    const [tasksStatus, setTaskStatus] = useState(null)
    const [taskDetails, setTaskDetails] = useState({})
    const [initialDetails, setInitialDetails] = useState({})
    const [assignees, setAssignees] = useState([])
    const [priority, setPriority] = useState({})
    const [description, setDescription] = useState('')
    const [images, setImages] = useState([])
    const [availableWorkers, setAvailableWorkers] = useState([])
    const [comment, setComment] = useState('')
    const [priorities, setPriorities] = useState([
        {
            label: 'High',
            color: 'red',
            icon: 'ep_d-arrow-up-double.png'
        },
        {
            label: 'Medium',
            color: 'green',
            icon: 'fluent_line-equal.png'
        },
        {
            label: 'Low',
            color: 'Blue',
            icon: 'ep_arrow-down.png'
        }
    ])
    const [statuses] = useState([
        {
            value: 'to_do',
            text: 'To do'
        },
        {
            value: 'in_progress',
            text: 'In progress'
        },
        {
            value: 'in_review',
            text: 'In review'
        },
        {
            value: 'completed',
            text: 'Completed'
        }
    ])
    const [workerStatuses, setWorkerStatuses] = useState([])
    const [toDoStatuses] = useState([
        {
            value: 'in_progress',
            text: 'In progress'
        },
    ])
    const [inProgressStatuses] = useState([
        {
            value: 'in_review',
            text: 'In review'
        }
    ])
    const [loading, setLoading] = useState(true)
    const [isDisabled, setIsDisabled] = useState(true)
    const [isWorker, setIsWorker] = useState(false)
    const roles = useSelector(state => state.getState).roles;

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);
    useEffect(() => {
        setIsWorker(roles.includes('Fleet'))
        let id = location.search
        let numb = id.match(/\d/g);
        id = numb.join("");
        setTaskId(id)
        const grs_token = Cookies.get('grs_token')

        const source = axios.CancelToken.source()
        if (roles.includes('Fleet')) {

            getStatus(grs_token, source, id)

        }
    }, [roles])
    const checkDataLength = () => {
        return taskDetails.status !== '' && taskDetails.description !== '' && taskDetails.priority !== ''
    }
    const checkEquality = () => {
        return (Object.keys(initialDetails).length && Object.keys(taskDetails).length &&
            (initialDetails.status !== taskDetails.status ||
                initialDetails.description !== taskDetails.description ||
                JSON.stringify(initialDetails.workers) !== JSON.stringify(assignees) ||
                initialDetails.priority !== taskDetails.priority))
    }
    useEffect(() => {
        if (!checkEquality()) {
            if (comment) {
                setIsDisabled(false)
            } else setIsDisabled(true)
            if (images.length > 0) {
                setIsDisabled(false)
            } else  setIsDisabled(true)
        } else if (checkDataLength()) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }
    }, [taskDetails, setInitialDetails, images, assignees, comment])

    useEffect(() => {
        let id = location.search
        let numb = id.match(/\d/g);
        id = numb.join("");
        const grs_token = Cookies.get('grs_token')
        setToken(grs_token)

        const source = axios.CancelToken.source()

        const fetchData = async () => {
            try {
                const response = await axios({
                    method: 'GET',
                    url: `${constants.FLEET_URL}/fleet-management/fleet/task/${id}`,
                    headers: {
                        Authorization: `Bearer ${grs_token}`,
                    },
                    cancelToken: source.token,
                })
                const workers = await axios({
                    method: 'GET',
                    url: `${constants.FLEET_URL}/fleet-management/fleet/tasks/create/options`,
                    headers: {
                        Authorization: `Bearer ${grs_token}`,
                    },
                    cancelToken: source.token,
                })
                const data = response.data?.data
                initData(data, workers.data.data.assignees)
                setLoading(false)
            } catch (error) {
                if (axios.isCancel(error)) {
                    console.log('Request canceled :: ', error)
                } else {
                    console.error('error :: ', error)
                }
            }
        }

        fetchData()
        return () => {
            source.cancel()
        }
    }, [])


    const getStatus = async (token, source, id) => {
        const checkStatus = await axios({
            method: 'GET',
            url: `${constants.FLEET_URL}/fleet-worker/worker/task/${id}/activity/status`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            cancelToken: source.token,
        })
        const eventType = checkStatus.data.data.event?.hasOwnProperty('event_type') ? checkStatus.data.data.event.event_type : null
        if (eventType !== null) {
            if (eventType === 0) {
                setTaskStatus('start')
            } else if (eventType === 1) {
                setTaskStatus('pause')
            } else if (eventType === 2) {
                setTaskStatus('end')
            }
        }
        //
        // //todo remove from there after testing
        // const startDay = await axios({
        //         method: 'GET',
        //         url: `${constants.FLEET_URL}/fleet-worker/worker/start`,
        //         headers: {
        //             Authorization: `Bearer ${token}`,
        //         },
        //         cancelToken: source.token,
        //     })
        //todo remove from there after testing
        // console.log('asdasd',startDay)

    }

    const initData = (data, available) => {
        let tempPriority = priorities.find(item => item.label.toLowerCase() === data.priority.toLowerCase())
        setPriority(tempPriority)
        setAssignees(data.workers)
        setAvailableWorkers([...data.workers, ...available])
        setDescription(data.description)
        setTaskDetails(data)
        setInitialDetails(data)
        if (data.status === 'to_do') {
            setWorkerStatuses(toDoStatuses)
        } else if (data.status === 'in_progress') {
            setWorkerStatuses(inProgressStatuses)
        } else setWorkerStatuses([])
    }

    const updateStatus = async (id, status) => {
        const response = await axios({
            method: 'POST',
            url: `${constants.FLEET_URL}/fleet-management/task/${id}/status`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {status: status},
        })
        if (status === 'to_do') {
            setWorkerStatuses(toDoStatuses)
        } else if (status === 'in_progress') {
            setWorkerStatuses(inProgressStatuses)
        } else setWorkerStatuses([])
        return response
    }

    const cancel = () => {
        history.goBack()
    }
    const updateTaskStatus = async (status) => {
        const response = await axios({
            method: 'GET',
            url: `${constants.FLEET_URL}/fleet-worker/worker/task/${taskId}/activity/${status}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        const eventType = response.data.data.event?.hasOwnProperty('event_type') ? response.data.data.event.event_type : null
        if (eventType !== null) {
            if (eventType === 0) {
                setTaskStatus('start')
            } else if (eventType === 1) {
                setTaskStatus('pause')
            } else if (eventType === 2) {
                setTaskStatus('end')
            }
        }
    }
    const saveData = () => {
        let id = location.search
        let numb = id.match(/\d/g);
        id = numb.join("");
        let formData = new FormData();
        formData.append('description', description);
        formData.append('status', taskDetails.status);

        if( assignees && assignees.length) {
            for (let i = 0; i < assignees.length; i++) {
                formData.append('workers[]', assignees[i].id);
            }
        }

        for (let i = 0; i < images.length; i++) {
            formData.append('documents[]', images[i]);
        }
        formData.append('comment', comment);
        formData.append('priority', taskDetails?.priority?.toLowerCase());
        axios({
            method: 'POST',
            url: `${constants.FLEET_URL}/fleet-management/fleet/task/${id}`,
            headers: {
                'accept': 'application/json',
                Authorization: 'Bearer ' + token,
                'Content-Type': `multipart/form-data`,
            },
            data: formData
        }).then(res => {
            history.goBack()
        })
    }
    const editImages = (files) => {
        setImages([...images,...files])
    }
    return (
        <>
            {!loading &&
            <CreateEditTask
                taskDetails={taskDetails}
                documents={taskDetails.documents}
                assignees={assignees}
                availableAssignees={availableWorkers}
                images={images}
                priorities={priorities}
                vehicle={taskDetails.vehicle}
                comment={comment}
                description={description}
                saveData={saveData}
                isWorker={isWorker}
                statuses={isWorker ? workerStatuses : statuses}
                cancel={cancel}
                initialStatus={tasksStatus}
                changeStatus={updateTaskStatus}
                updateStatus={updateStatus}
                isDisabled={isDisabled}
                priority={priority}
                setComment={setComment}
                setPriority={setPriority}
                setDescription={setDescription}
                setTaskDetails={setTaskDetails}
                setAssignees={setAssignees}
                setImages={(files) => editImages(files)}
            />
            }

        </>
    )
}