import React, {useEffect, useRef, useState} from 'react'
import {useHistory} from 'react-router-dom'
import NotificationAlert from "react-notification-alert"
import axios from 'axios'
import Cookies from "js-cookie"

import * as constants from 'constants/index.js'
import CardsHeader from 'layouts/Headers/CardsHeader.js'

import FleetCalendar from 'views/pages/components/FleetCalendar'

import {
    Container,
    ModalBody,
    ModalHeader,
    Modal, Button,
} from "reactstrap"
import styled from "styled-components";
import DynamicTable from "../components/DynamicTable";
import {formatDate, parseToWord} from "constants/index.js";
import {useSelector} from "react-redux";
import {
    LoaderContainer,
    ModalBodyContainer, override,
    ReportContainer,
    TimeStartStopContainer
} from "../reusable-components/styled-components";
import {useLocation} from "react-router";
import {css} from "@emotion/core";
import {ClipLoader} from "react-spinners";

const Card = styled.div`
 box-sizing: border-box;
 padding: 15px;
 width: 238px;
 height: 140px;
 background: #FFFFFF;
 border: 1px solid #F8F8F9;
 box-shadow: 0px 8px 36px rgba(112, 144, 176, 0.08);
 border-radius: 10px;
`
const CardContainer = styled.div`
  display: flex;
  gap: 20px;
  padding: 30px 30px 30px 0px;
  flex-direction: row;
  justify-content: start;
`
const TitleContainer = styled.div`
  display: flex;
  margin-bottom:  ${props => props.bottom ? props.bottom : '10px'}; 
  width:  ${props => props.width ? props.width : ''}; 
  flex-direction: row;
  gap: 10px;
  justify-content: center;
`
const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: ${props => props.position ? props.position : 'center'};
    width: ${props => props.width};
    gap: ${props => props.gap};
`
const Circle = styled.div`
    width: 13px;
    height: 13px;
    background-color: ${props => props.color ? props.color : '#67676E'}; 
    border-radius:50%;
    box-shadow: 0 0 0 3px transparent;
`
const Text = styled.span`
 font-weight:${props => props.wide ? props.wide : '500'};
 font-size: ${props => props.size ? props.size : '16px'};
 width: ${props => props.width};
 line-height: ${props => props.withoutHeight ? '' : '100%'};
 height: ${props => props.withoutHeight ? '' : '24px'};
`
const ButtonContainer = styled.div`
  background-color: #EC6409;
  margin-right: 20px;
  width: ${props => props.width ? props.width : '140px'}
  height: 40px;
  display:flex;
  align-items: center;
  padding: 10px 20px;
  gap: 10px;
  border-radius: 3px;
  color: white;
  float: right;
  cursor: pointer;
`
const RadioButtonWrapper = styled.div`
  border: 1px solid #e6e6e7;
  position: relative;
  border-radius: 50%;
  width: 20px;
  height: 20px;
`
const RadioButton = styled.div`
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background-color: ${props => props.selected && '#ec6409'}
`
const RadioTextContainer = styled.div`
 display: flex;
 gap: 20px;
 justify-content: flex-start;
`

export default function Fleet() {
    const notificationAlert = useRef(null)
    const [modal, setModal] = useState(false);
    const [token, setToken] = useState('');
    const [backdrop] = useState(true);
    const [keyboard] = useState(true);
    const [rSelected, setRSelected] = useState(null);
    const [taskStatus, setTaskStatus] = useState(null);
    const [initialStatus, setInitialStatus] = useState(null);
    const [timeModal, setTimeModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [models, setModels] = useState([])
    const [makes, setMakes] = useState([])
    const [makesAndModels, setMakesAndModels] = useState('')
    const [vehiclesCount, setVehiclesCount] = useState({})
    const [tasksCount, setTasksCount] = useState({})
    const [workersCount, setWorkersCount] = useState({})
    const [disabled, setDisabled] = useState(false);
    const [statuses] = useState([
        {
            value: 'to_do',
            text: 'To do'
        },
        {
            value: 'in_progress',
            text: 'In progress'
        },
        {
            value: 'in_review',
            text: 'In review'
        }
    ])
    const [keys] = useState(
        [
            {
                key: 'title',
                text: 'task'
            },
            {
                key: 'make',
                text: 'make'
            },
            {
                key: 'model',
                text: 'model'
            },
            {
                key: 'stock_no',
                text: 'reg no'
            },
            {
                key: 'status',
                text: 'status'
            },
            {
                key: 'workers',
                text: 'workers'
            },
            {
                key: 'total_worked_time_formated',
                text: 'timeline'
            },
            {
                key: 'priority',
                text: 'priority'
            },
        ]
    )
    const [workerKeys] = useState(
        [
            {
                key: 'title',
                text: 'task'
            },
            {
                key: 'make',
                text: 'make'
            },
            {
                key: 'model',
                text: 'model'
            },
            {
                key: 'stock_no',
                text: 'reg no'
            },
            {
                key: 'status',
                text: 'status'
            },
            {
                key: 'workers',
                text: 'workers'
            },
            {
                key: 'timeline',
                text: 'timeline'
            },
            {
                key: 'priority',
                text: 'priority'
            },
        ]
    )
    const [priorities] = useState([
        {
            label: 'High',
            value: 'high',
            color: 'red',
            icon: 'ep_d-arrow-up-double.png'
        },
        {
            label: 'Medium',
            value: 'medium',
            color: 'green',
            icon: 'fluent_line-equal.png'
        },
        {
            label: 'Low',
            value: 'low',
            color: 'Blue',
            icon: 'ep_arrow-down.png'
        }
    ])
    const history = useHistory();
    const location = useLocation();
    const [tableData, setTableData] = useState([])
    const [modelValue, setModelValue] = useState('')
    const [makeValue, setMakeValue] = useState('')
    const [priorityValue, setPriorityValue] = useState('')
    const [selectedStatus, setSelectedStatus] = useState('')
    const [filter, setFilter] = useState({})
    const [modalTitle, setModalTitle] = useState('');
    const [modalSubTitle, setModalSubTitle] = useState(null);
    const [statusChanged, setStatusChanged] = useState(false);
    const [tempStatus, setTempStatus] = useState({});
    const [taskUpdatingStatus, setTaskUpdatingStatus] = useState(null);
    const roles = useSelector(state => state.getState).roles;
    const isAdmin = roles.includes('Fleet Manager') || roles.includes('dealer_admin')
    const isWorker = roles.includes('Fleet')
    const notify = (type, header, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
              {header}
          </span>
                    <span data-notify="message">
            <div dangerouslySetInnerHTML={{__html: message}}/>
          </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5
        }
        notificationAlert.current.notificationAlert(options)
    }

    useEffect(() => {
        if (roles.length) {
            let savedFilter = {}
            if (localStorage.getItem('grms_fleet_search_filter')) {
                savedFilter = JSON.parse(localStorage.getItem('grms_fleet_search_filter'))
                setPriorityValue(savedFilter?.priority)
                setSelectedStatus(parseToWord(savedFilter?.status))
                setFilter(savedFilter)
            }
            let grs_token = Cookies.get('grs_token')
            setToken(grs_token)
            fetchData(grs_token, savedFilter)
        }
    }, [roles])
    const toggle = () => setModal(!modal);
   useEffect(()=>{
       if (makes.length && !makeValue && makesAndModels.length) {
           if (filter.hasOwnProperty('make') && filter.make) {
               setMakeValue(makes.find(item=>filter.make === item.id)?.name)
               const modelsTemp = makesAndModels.filter(item => item.vehicle_make_id === filter.make)
               setModels(modelsTemp)
               if (modelsTemp.length) {
                   if (filter.hasOwnProperty('model') && filter.model) {
                       setModelValue(modelsTemp.find(item=>filter.model === item.id)?.name)
                   }
               }
           }
       }

   },[makes,makesAndModels])
    const fetchData = (token, filter, searching = false) => {
        localStorage.setItem('grms_fleet_search_filter', JSON.stringify(filter))
        const hasAdminPermission = roles.includes('Fleet Manager') || roles.includes('dealer_admin')
        const hasWorkerPermission = roles.includes('Fleet')
        const url = hasAdminPermission ? '/fleet-management/dashboard' : '/fleet-worker/dashboard'
        axios({
            method: 'GET',
            url: `${constants.FLEET_URL}${url}/tasks`,
            headers: {
                Authorization: 'Bearer ' + token
            },
            params: filter
        })
            .then(async response => {
                if (response.status === 200) {
                    let key = hasAdminPermission ? 'fleet' : 'tasks'
                    let data = response.data.data
                    for (let i = 0; i < data[key].length; i++) {
                        data[key][i].make = data[key][i].vehicle.make?.name;
                        data[key][i].model = data[key][i].vehicle.model?.name;
                        data[key][i].stock_no = data[key][i].vehicle?.stock_no;
                    }
                    setTableData(data[key])
                    if (!searching) {
                        const vehiclesCount = await axios({
                            method: 'GET',
                            url: `${constants.FLEET_URL}${url}/vehicle-meta`,
                            headers: {
                                Authorization: 'Bearer ' + token
                            },
                        })
                        const tasksCount = await axios({
                            method: 'GET',
                            url: `${constants.FLEET_URL}${url}/tasks-meta`,
                            headers: {
                                Authorization: 'Bearer ' + token
                            },
                        })
                        if (hasAdminPermission) {
                            const workersCount = await axios({
                                method: 'GET',
                                url: `${constants.FLEET_URL}${url}/workers-meta`,
                                headers: {
                                    Authorization: 'Bearer ' + token
                                },
                            })
                            setWorkersCount(workersCount.data.data.meta)
                        }
                        if (hasWorkerPermission) {
                            const workingDayStatus = await axios({
                                method: 'GET',
                                url: `${constants.FLEET_URL}/fleet-worker/worker/current-status`,
                                headers: {
                                    Authorization: 'Bearer ' + token
                                },
                            })
                            const eventType = workingDayStatus.data.data.event?.hasOwnProperty('event_type') ? workingDayStatus.data.data.event.event_type : null
                            if (eventType !== null) {
                                if (eventType === 0) {
                                    setTaskStatus('start')
                                } else if (eventType === 1) {
                                    setTaskStatus('pause')
                                } else if (eventType === 2) {
                                    setTaskStatus('end')
                                }
                            }

                        }
                        const makeRequest = await axios({
                            method: 'GET',
                            url: constants.FLEET_URL + '/fleet-management/vehicle/makes',
                            headers: {
                                Authorization: 'Bearer ' + token
                            }
                        })
                        setMakes(makeRequest.data.data.makes)
                        const modelRequest = await axios({
                            method: 'GET',
                            url: constants.FLEET_URL + '/fleet-management/vehicle/models',
                            headers: {
                                Authorization: 'Bearer ' + token
                            }
                        })
                        setMakesAndModels(modelRequest.data.data.models)
                        setVehiclesCount(vehiclesCount.data.data.meta)
                        setTasksCount(tasksCount.data.data.meta)
                    }
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
                console.error('error :: ', error)
                notify('warning', 'Warning', error?.response?.statusText)
            })
    }
    const filterVehicle = async (filter) => {
        await fetchData(token, filter, true)
    }
    const updateTaskStatus = async (status) => {
        const response = await axios({
            method: 'GET',
            url: `${constants.FLEET_URL}/fleet-worker/worker/${status}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        const eventType = response.data.data.event?.hasOwnProperty('event_type') ? response.data.data.event.event_type : null
        if (eventType !== null) {
            if (eventType === 0) {
                setTaskStatus('start')
            } else if (eventType === 1) {
                setTaskStatus('pause')
            } else if (eventType === 2) {
                setTaskStatus('end')
            }
        }
    }
        const clearFilers = () =>{
            setMakeValue(null)
            setModelValue(null)
            setPriorityValue(null)
            setSelectedStatus(null)
            localStorage.removeItem('grms_fleet_search_filter')
            setFilter({})
            filterVehicle({})
        }
    const setMakeFilter = async (e) => {
        setModels(makesAndModels.filter(item => item.vehicle_make_id === e.id))
        setMakeValue(e.name)
        setModelValue('')
        setFilter({
            ...filter,
            make: e.id,
            model: null,
        })
        let tempFilter = filter
        tempFilter.make = e.id
        tempFilter.model = null
        setFilter(tempFilter)
        await filterVehicle(tempFilter)
    }
    const setModelFilter = async (e) => {
        setModelValue(e.name)
        let tempFilter = filter
        tempFilter.model = e.id
        setFilter(tempFilter)
        await filterVehicle(tempFilter)
    }
    const searchByText = async (e) => {
        let tempFilter = filter
        tempFilter['search']  = e.target.value
        setFilter(tempFilter)
        await filterVehicle(tempFilter)
    }
    const setPriorityFilter = async (e) => {
        setPriorityValue(e.label)
        let tempFilter = filter
        tempFilter.priority = e.label
        setFilter(tempFilter)
        await filterVehicle(tempFilter)
    }
    const selectStatus = async (e) => {
        setSelectedStatus(e.text)
        let tempFilter = filter
        tempFilter.status = e.value
        setFilter(tempFilter)
        await filterVehicle(tempFilter)
    }
    const updateWithModal = (status) => {
        setTaskUpdatingStatus(status)
        if (status === 'start') {
            setModalTitle('You are going to start working day')
            setModalSubTitle(<div>It means that work status will be changed to started</div>)
            if (initialStatus === 'end' || initialStatus === null) {
                setStatusChanged(true)
                setTempStatus({value: 'in_progress', id: ''})
            }
        } else if (status === 'end') {
            setModalTitle('You are going to end your working day')
            setModalSubTitle(<div>If you are ending your day, the active task will be paused automatically</div>)
        }
        toggleModal()
    }
    const toggleModal = () => setTimeModal(!timeModal);
    const updateWorkStatus = (status) => {
        setTaskUpdatingStatus(status)
        setDisabled(true)
        updateTaskStatus(status)
    }
    const pauseTask = (status) => {
        setTaskUpdatingStatus(status)
        setDisabled(true)
        updateTaskStatus(status)
    }

    const confirm = () => {
        if (statusChanged) {
        }
        setDisabled(true)
        updateTaskStatus(taskUpdatingStatus)
        toggleModal()
    }
    const closeModal = () => {
        setTaskUpdatingStatus('')
        setStatusChanged(false)
        setTempStatus({})
        setDisabled(false)
        toggleModal();
    }
    const addVehicle = () => {
        history.push({
            pathname: '/admin/fleet-job/create'
        })
    }
    const addTasks = () => {
        history.push({
            pathname: '/admin/tasks',
            state: {
                showAddTaskComponent: true,
                from: location
            }
        })
    }
    return (
        <>
            <Modal
                isOpen={modal}
                toggle={toggle}
                backdrop={backdrop}
                keyboard={keyboard}
            >
                <ModalHeader toggle={toggle}>Please select the type of document</ModalHeader>
                <ModalBody>

                    <RadioTextContainer>
                        <ContentContainer gap='5px'>
                            <img height='25px' width='25px' src={require("assets/images/directions_car.png")} alt=""/>
                            <img height='25px' width='25px' src={require("assets/images/library_books.png")} alt=""/>
                        </ContentContainer>
                        <ContentContainer gap='5px'>
                                <span>
                                       Add vehicle
                                 </span>
                            <span>
                               Add task
                              </span>
                        </ContentContainer>
                        <ContentContainer width='100px' gap='5px' position='end'>
                            <RadioButtonWrapper>
                                <RadioButton
                                    onClick={() => {
                                        setRSelected(1)
                                        addVehicle()
                                    }}
                                    selected={rSelected === 1}
                                >
                                </RadioButton>
                            </RadioButtonWrapper>
                            <RadioButtonWrapper>
                                <RadioButton
                                    onClick={() => {
                                        setRSelected(2)
                                        addTasks()
                                    }}
                                    selected={rSelected === 2}
                                >
                                </RadioButton>
                            </RadioButtonWrapper>
                        </ContentContainer>

                    </RadioTextContainer>

                </ModalBody>
            </Modal>
            <Modal
                isOpen={timeModal}
                toggle={toggleModal}
            >
                <ModalHeader toggle={toggleModal}>

                </ModalHeader>
                <ModalBodyContainer>
                    <Text color='#ec6409'>{modalTitle}</Text>
                    <Text size='14px'>{modalSubTitle}</Text>
                    <ReportContainer>
                        <Button outline={true} className="w-100"
                                style={{backgroundColor: "white", color: '#ec6409', border: '1px solid #ec6409'}}
                                type="button" onClick={() => {
                            closeModal()

                        }}>Cancel</Button>
                        <Button className="w-100" style={{backgroundColor: "#ec6409", color: 'white', border: 'none'}}
                                type="button" onClick={() => {
                            confirm()
                        }}>Confirm</Button>
                    </ReportContainer>
                </ModalBodyContainer>
            </Modal>
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlert}/>
            </div>
            {loading ?
                <LoaderContainer>
                    <ClipLoader
                        css={override}
                        size={40}
                        color={`#7B61E4`}
                        loading={loading}
                    />
                </LoaderContainer> :
                <div className='pt-3-4 admin-main-body container-fluid'>
                    {isWorker ?
                        <TimeStartStopContainer width='350px' around right>
                            {taskStatus !== 'end' && <ReportContainer>
                                <img height='16px' width='16px' src={require(`assets/images/info_outline.png`)}
                                     alt=""/>
                                {taskStatus === null ?
                                    <Text withoutHeight size='12px'>Please press the button to start working</Text> :
                                    <Text withoutHeight size='12px'><b>Pause</b> your works during breaks <b>End Day</b> after
                                        finishing
                                        work day.</Text>
                                }
                            </ReportContainer>}
                            {taskStatus === 'end' ? <span>The day workflow was end</span> : taskStatus === null ?
                                <ButtonContainer width={'200px'} onClick={() => updateWithModal('start')}>
                                    <i className="fa fa-play" aria-hidden="true"/>
                                    <Text withoutHeight size='12px'>Start working</Text>
                                </ButtonContainer> :
                                <ReportContainer>
                                    <ButtonContainer onClick={() => updateWithModal('end')}>
                                        <i className="fa fa-stop" aria-hidden="true"/>
                                        <Text withoutHeight size='12px'> End day</Text>
                                    </ButtonContainer>
                                    {
                                        taskStatus === 'pause' ?
                                            <ButtonContainer onClick={() => updateWorkStatus('start')}>
                                                <i className="fa fa-play" aria-hidden="true"/>
                                                <Text withoutHeight size='12px'>Continue</Text>
                                            </ButtonContainer> :
                                            <ButtonContainer onClick={() => pauseTask('pause')}>
                                                <i className="fa fa-pause" aria-hidden="true"/>
                                                <Text withoutHeight size='12px'> Pause</Text>
                                            </ButtonContainer>
                                    }
                                </ReportContainer>
                            }
                        </TimeStartStopContainer>
                        :
                        <ButtonContainer onClick={toggle}>
                            <i className="fa fa-plus" aria-hidden="true"></i>
                            Add New
                        </ButtonContainer>

                    }

                    <CardContainer  style={{marginTop: window.innerWidth < 833 ? '25px' : ''}}>
                        <Card>
                            <TitleContainer bottom="20px">
                                <img height='25px' width='25px' src={require("assets/images/directions_car.png")}
                                     alt=""/>
                                <Text size="26px">Vehicles</Text>
                            </TitleContainer>
                            <ContentContainer>
                                <TitleContainer>
                                    <Circle color="#EC6409"></Circle>
                                    <Text width={'90px'}>Selected</Text>
                                    <Text wide={'800'} width={'25px'}>{vehiclesCount.in_progress}</Text>
                                </TitleContainer>
                                <TitleContainer>
                                    <Circle></Circle>
                                    <Text width={'90px'}>In queue</Text>
                                    <Text wide={'800'} width={'25px'}>{vehiclesCount.to_do}</Text>
                                </TitleContainer>
                            </ContentContainer>
                        </Card>
                        {isAdmin && <Card>
                            <TitleContainer bottom="20px">
                                <img height='25px' width='25px' src={require("assets/images/group.png")} alt=""/>
                                <Text size="26px">Workers</Text>
                            </TitleContainer>
                            <ContentContainer>
                                <TitleContainer>
                                    <Circle color="#EC6409"></Circle>
                                    <Text width={'90px'}>In a work</Text>
                                    <Text wide={'800'} width={'25px'}>{workersCount.in_work}</Text>
                                </TitleContainer>
                                <TitleContainer>
                                    <Circle></Circle>
                                    <Text width={'90px'}>Available</Text>
                                    <Text wide={'800'} width={'25px'}>{workersCount.available}</Text>
                                </TitleContainer>
                            </ContentContainer>
                        </Card>}
                        <Card>
                            <TitleContainer bottom="20px">
                                <img height='25px' width='25px' src={require("assets/images/library_books.png")}
                                     alt=""/>
                                <Text size="26px">Tasks</Text>
                            </TitleContainer>
                            <ContentContainer>
                                <TitleContainer>
                                    <Circle color="#EC6409"></Circle>
                                    <Text width={'90px'}>In progress</Text>
                                    <Text wide={'800'} width={'25px'}>{tasksCount.in_progress}</Text>
                                </TitleContainer>
                                <TitleContainer>
                                    <Circle></Circle>
                                    <Text width={'90px'}>Just starts</Text>
                                    <Text wide={'800'} width={'25px'}>{tasksCount.to_do}</Text>
                                </TitleContainer>
                            </ContentContainer>
                        </Card>
                    </CardContainer>
                    <DynamicTable
                        onSearchChange={searchByText}
                        searchValue={filter?.hasOwnProperty('search') ?  filter['search'] : ''}
                        showClearButton
                        clearFilers={clearFilers}
                        make={makes}
                        model={models}
                        labelKey={`name`}
                        showStatus={true}
                        valueKey={`id`}
                        selectMake={setMakeFilter}
                        selectPriority={setPriorityFilter}
                        selectStatus={selectStatus}
                        goToDetails={(e)=>  history.push(`/admin/task-details?id=${e}`)}
                        selectModel={setModelFilter}
                        makeValue={makeValue ? makeValue : ''}
                        modelValue={modelValue ? modelValue : ''}
                        priorityValue={priorityValue ? priorityValue : ''}
                        showCarFilter={true}
                        statuses={statuses}
                        selectedStatus={selectedStatus}
                        tableData={tableData}
                        priorities={priorities}
                        keys={isAdmin ? keys : workerKeys}
                        />

                </div>}
        </>
    )
}