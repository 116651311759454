import axios from '../config/axios';

export const updateKashflowConfiguration = (username, password) => {
    return axios({
        method: 'POST',
        url: '/dealer/kashflow',
        data: {
          username, password
        }
      })
        .then(response =>  response.data);
}

export const getKashflowConfiguration = () => {
  return axios({
      method: 'GET',
      url: '/dealer/kashflow'
    })
      .then(response =>  response.data);
}

export const updateKashflowSalesTypeMapping = (mappings) => {
  console.log(mappings)
  return axios({
      method: 'POST',
      url: '/dealer/kashflow/products',
      data: {
        mappings
      }
    })
      .then(response =>  response.data);
}

export const getXeroConfiguration = () => {
  return axios({
      method: 'GET',
      url: '/dealer/xero'
    })
      .then(response =>  response.data);
}

export const updateXeroSalesTypeMapping  = (mappings) => {
  return axios({
      method: 'POST',
      url: '/dealer/xero/products',
      data: {
        mappings
      }
    })
      .then(response =>  response.data);
}