import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from "js-cookie";

import * as actions from '@redux/Payments/actions';
import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';
import TableCustom from 'views/pages/components/TableCustom';
import Filter from './Filter';
import useNotify from 'hooks/notify';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody
} from "reactstrap";

export default function PaymentsList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { ref, notify } = useNotify();

  const query = new URLSearchParams(useLocation().search);

  const {
    getTasksStatus,
    payments,
    paymentDescriptorsArray,
    filter
  } = useSelector((state) => state.paymentState, shallowEqual);

  const [items, setItems] = useState('');
  const [paymentDescriptors, setPaymentDescriptors] = useState('');
  const [role, setRole] = useState('');

  const [loading, setLoading] = useState(true);
  const [delay, setDelay] = useState(false);

  const [filterOptions, setFilterOptions] = useState('');

  useEffect(() => {
    let page = query.get('page') ?? 1;

    fetchData(page, {...filter});
  }, []);

  const fetchData = (pageNumber = 1, filter) => {
    setLoading(true);
    history.push({
      pathname: history.location.pathname,
      search: `?page=${pageNumber}`
    });
    dispatch({
      type: actions.GET_PAYMENT_LIST_REQUEST,
      data: {
        ...filter,
        page: pageNumber,
      },
    });
  };

  const editDeal = (row) => {
    history.push({
      pathname: '/admin/deals/' + row.id + '/edit'
    })
  }

  useEffect(() => {
    if (getTasksStatus) {
      // setFilter({
      //   ...leadState.filter,
      //   from: leadState.filter.from
      //     ? moment(leadState.filter.from).format('YYYY-MM-DD')
      //     : '',
      //   to: leadState.filter.to
      //     ? moment(leadState.filter.to).format('YYYY-MM-DD')
      //     : '',
      // });
      setItems(payments);
      setPaymentDescriptors(paymentDescriptorsArray);
      setTimeout(() => {
        setLoading(false);
        setDelay(true);
      }, 500);
    }
  }, [getTasksStatus]);

  const resetPayments = () => {
    setFilterOptions('')
    fetchData(1, {})
  }

  const paymentScheduleDetails = (row) => {
    history.push({
      pathname: `/admin/deals/${row.deal_id}/payment/${row.id}/details`,
    })
  }

  const showInvoice = (row) => {
    let grs_token = Cookies.get('grs_token')

    axios({
      method: 'GET',
      url: constants.API_URL + '/deal/' + row.deal_id + '/invoice/' + row.id,
      headers: {
        Authorization: 'Bearer ' + grs_token
      },
    })
      .then(response => {
        console.log(response)
        if (response.status == 200) {
          let data = response.data;
          console.log('response data :: ', data);
          let invoice = data.data.invoice;
          window.open(invoice, "_blank");
        }
      })
      .catch(error => {
        console.error('error :: ', error.response);
        notify('warning', 'Warning', error.response.statusText);
      })
  }

  return (
    <>
      <CardsHeader name="Payments Management" parentName="Payments Management" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={ref} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={12}>
                    <h1 className="mb-0">Payments list. Check out your payments, invoices</h1>
                  </Col>
                </Row>
                <Filter fetchData={fetchData}/>
              </CardHeader>
              <CardBody>

                <TableCustom
                  loading={loading}
                  delay={delay}
                  items={items.data}
                  keyField={`id`}
                  searchField={false}
                  pagination={items}
                  onChange={(pageNumber) => fetchData(pageNumber, {
                    ...filter,
                    dealer_id: filterOptions.dealer_id === '-' ? undefined : filterOptions.dealer_id
                  })}
                  columns={[
                    {
                      dataField: "deal_id",
                      text: "Deal #",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.deal_id;
                      }
                    },
                    {
                      dataField: "full_name",
                      text: "Customer"
                    },
                    {
                      dataField: "stock_no",
                      text: "Stock no"
                    },
                    {
                      dataField: "amount",
                      text: "Amount",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return parseFloat(row.payment_reference ? row.payment_reference.amount : row.amount);
                      }
                    },
                    {
                      dataField: "amount",
                      text: "total",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.payment_reference?.total_amount ? row.payment_reference.total_amount : (parseFloat(row.amount) + parseFloat(row.amount * 0.2));
                      }
                    },
                    {
                      dataField: "collected",
                      text: "collected",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.payment_reference?.amount_collected ?? 0;
                      }
                    },
                    {
                      dataField: "due",
                      text: "due",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.payment_reference ? (parseFloat(row.payment_reference.total_amount ?? (parseFloat(row.amount) + parseFloat(row.amount * 0.2))) - parseFloat(row.payment_reference.amount_collected ?? 0)) : (parseFloat(row.amount) + parseFloat(row.amount * 0.2));
                      }
                    },
                    {
                      dataField: "charge_at",
                      text: "Payment Due",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.payment_reference ? constants.formatDateShortGB(row.payment_reference.charge_date) : constants.formatDateShortGB(row.scheduled_at);
                      }
                    },
                    {
                      dataField: "payment_descriptor",
                      text: "Payment descriptor",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return paymentDescriptors[row.payment_descriptor];
                      }
                    },
                    {
                      dataField: "invoice",
                      text: "Invoice",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            {row.invoice && row.invoice.id ? <i
                              className="fas fa-file-download"
                              style={{ cursor: 'pointer', marginLeft: "10px" }}
                              onClick={() => showInvoice(row)}
                            ></i>
                              : "No invoice"
                            }
                          </>
                        )
                      }
                    },
                    {
                      dataField: "",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                          <div>
                            {row.id && row.scheduled_at &&
                              <>
                                <i
                                  className="far fa-file-alt ml-1"
                                  style={{ cursor: 'pointer', }}
                                  onClick={() => paymentScheduleDetails(row)}
                                ></i>
                              </>
                            }
                          </div>
                        )
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}