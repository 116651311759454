import React, { useRef, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import NotificationAlert from "react-notification-alert";
import Cookies from 'js-cookie';
import * as constants from 'constants/index.js';
import axios from 'axios';

import InputCustom from 'views/pages/components/InputCustom'
import InputFile from 'views/pages/components/InputFile'
import {
  InputKeySelect,
  InputArraySelect,
} from 'views/pages/components/InputSelect'
import InputToggle from 'views/pages/components/InputToggle'

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "reactstrap";

import CardsHeader from 'layouts/Headers/CardsHeader.js';
import {ButtonContainer} from "@pages/reusable-components/styled-components";

export default function UserEdit() {
  const { id } = useParams();
  const notificationAlert = useRef(null);
  const history = useHistory();

  const [token, setToken] = useState('');
  const [roles, setRoles] = useState([]);
  const [jobCategories, setJobCategories] = useState('');
  const [userCategoryRoles, setUserCategoryRoles] = useState('');

  const [user, setUser] = useState('');

  const [errors, setErrors] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    if (Cookies.get('grs_token')) {
      let grs_token = Cookies.get('grs_token');
      setToken(grs_token);

      axios({
        method: 'GET',
        url: constants.API_URL + '/user/' + id + '/edit',
        headers: {
          Authorization: 'Bearer ' + grs_token
        }
      })
        .then(response => {
          if (response.status == 200) {
            let data = response.data.data;
            console.log('response data :: ', data);
            setUser({
              ...data.user,
              role_id: data.user && data.user.roles && data.user.roles[0] ? data.user.roles[0].id : '',
            });
            setRoles(data.roles);
            setJobCategories(data.jobCategories);
            setUserCategoryRoles(data.userCategoryRoles);
          }
        })
        .catch(error => {
          console.error('error :: ', error);
          notify('warning', 'Warning', error.response.statusText);
        })
    }
  }, [])

  const updateUser = () => {
    let formData = new FormData();
    formData.append('name', user.name);
    formData.append('address', user.address);
    formData.append('postal_code', user.postal_code);
    formData.append('phone_number', user.phone_number);
    formData.append('email', user.email);
    formData.append('role_id', user.role_id ? user.role_id : user.roles && user.roles[0] ? user.roles[0].id : undefined);
    formData.append('photo', user.photo ? user.photo : '');
    formData.append('is_active', user.is_active);
    formData.append('is_driver', user.is_driver);
    formData.append('job_category', ['undefined', null, 'null', '-'].includes(user.job_category) ? '' : user.job_category);
    formData.append('role_type', ['undefined', null, 'null', '-'].includes(user.role_type) ? '' : user.role_type);
    formData.append('password', user.password);
    formData.append('password_confirmation', user.password ? user.password_confirmation : user.password);
    formData.append('_method', 'PUT');

    axios({
      method: 'POST',
      url: constants.API_URL + '/user/' + id,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: formData
    })
      .then(response => {
        if (response.status == 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setErrors('');
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        if (error.response && error.response.status == 422) {
          setErrors(error.response.data.errors)
          return
        }
        notify('warning', 'Warning', error.response && error.response.statusText);
      })
  }

  const handleChange = (files) => {
    setUser({
      ...user,
      photo: files[0]
    });
  }

  const goList = () => {
    history.push('/admin/user');
  }

  return (
    <>
      <CardsHeader name="Admin" parentName="User Management" currentName="Edit User" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--6 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0  main-color">User Management</h1>
                  </Col>

                </Row>
              </CardHeader>
              <CardBody>
                <Card>
                  <CardHeader>
                    <h2 className="surtitle mb-0">User information</h2>
                  </CardHeader>
                  <CardBody>
                      <Row className={'phone-container'}>
                          <Col>
                              <InputCustom
                                  newLabel={`Name`}
                                  placeholder={`Name`}
                                  value={user && user.name ? user.name : ''}
                                  onChange={(e) => setUser({
                                      ...user,
                                      name: e.target.value
                                  })}
                                  invalid={errors && errors.name? true: false}
                                  errorMessage={errors.name}
                              />
                              <InputCustom
                                  type={`email`}
                                  newLabel={`Email`}
                                  placeholder={`Email`}
                                  value={user && user.email ? user.email : ''}
                                  onChange={(e) => setUser({
                                      ...user,
                                      email: e.target.value
                                  })}
                                  invalid={errors && errors.email? true: false}
                                  errorMessage={errors.email}
                              />
                              <InputArraySelect
                                  newLabel={`Role`}
                                  options={roles}
                                  value={user && user.role_id ? user.role_id : ''}
                                  onChange={(e) => setUser({
                                      ...user,
                                      role_id: e.target.value
                                  })}
                                  valueKey={`id`}
                                  labelKey={`name`}
                                  defaultOption={true}
                                  invalid={errors && errors.role_id? true: false}
                                  errorMessage={errors.role_id}
                              />
                              <InputFile
                                  label={`Profile photo`}
                                  placeholder={`Select file`}
                                  onChange={(e) => handleChange(e.target.files)}
                              />
                              <InputKeySelect
                                  newLabel={`Job Category`}
                                  options={jobCategories}
                                  value={user && user.job_category? user.job_category: ''}
                                  onChange={(e) => setUser({
                                      ...user,
                                      job_category: e.target.value
                                  })}
                                  defaultOption={true}
                                  invalid={errors && errors.job_category ? true : false}
                                  errorMessage={errors.job_category}
                              />
                              <InputKeySelect
                                  newLabel={`Role Type`}
                                  options={userCategoryRoles}
                                  value={user && user.role_type? user.role_type: ''}
                                  onChange={(e) => setUser({
                                      ...user,
                                      role_type: e.target.value
                                  })}
                                  defaultOption={true}
                                  invalid={errors && errors.role_type ? true : false}
                                  errorMessage={errors.role_type}
                              />
                          </Col>
                          <Col>

                              <InputCustom
                                  newLabel={`Address`}
                                  placeholder={`Address`}
                                  value={user && user.address ? user.address : ''}
                                  onChange={(e) => setUser({
                                      ...user,
                                      address: e.target.value
                                  })}
                                  invalid={errors && errors.address? true: false}
                                  errorMessage={errors.address}
                              />
                              <InputCustom
                                  newLabel={`Postal Code`}
                                  placeholder={`Postal Code`}
                                  value={user && user.postal_code ? user.postal_code : ''}
                                  onChange={(e) => setUser({
                                      ...user,
                                      postal_code: e.target.value
                                  })}
                                  invalid={errors && errors.postal_code? true: false}
                                  errorMessage={errors.postal_code}
                              />
                              <InputCustom
                                  newLabel={`Phone number`}
                                  placeholder={`Phone number`}
                                  value={user && user.phone_number ? user.phone_number : ''}
                                  onChange={(e) => setUser({
                                      ...user,
                                      phone_number: e.target.value
                                  })}
                                  invalid={errors && errors.phone_number? true: false}
                                  errorMessage={errors.phone_number}
                              />
                              {/**/}
                              <InputCustom
                                  type={`password`}
                                  newLabel={`Password`}
                                  placeholder={`Password`}
                                  value={user && user.password ? user.password: ''}
                                  onChange={(e) => setUser({
                                      ...user,
                                      password: e.target.value
                                  })}
                                  invalid={errors && errors.password? true: false}
                                  errorMessage={errors.password}
                              />
                              <InputCustom
                                  type={`password`}
                                  newLabel={`Confirm password`}
                                  placeholder={`Confirm password`}
                                  value={user && user.password_confirmation ? user.password_confirmation: ''}
                                  onChange={(e) => setUser({
                                      ...user,
                                      password_confirmation: e.target.value
                                  })}
                                  invalid={errors && errors.password_confirmation? true: false}
                                  errorMessage={errors.password_confirmation}
                              />
                              <Row className={'justify-content-around'}>
                                  <InputToggle
                                      side
                                      label={`Is active`}
                                      checked={user && user.is_active == 1 ? true : false}
                                      onChange={() =>
                                          setUser({
                                              ...user,
                                              is_active: user.is_active == 1 ? 0 : 1
                                          })
                                      }
                                  />
                                  <InputToggle
                                      side
                                      label={`Is driver`}
                                      checked={user && user.is_driver ? true : false}
                                      onChange={() =>
                                          setUser({
                                              ...user,
                                              is_driver: user.is_driver == 1 ? 0 : 1
                                          })
                                      }
                                  />
                              </Row>
                          </Col>
                      </Row>


                    <div className="d-flex flex-row justify-content-end">
                        <ButtonContainer onClick={() => goList(true)}>Back to list</ButtonContainer>
                      <ButtonContainer reverse onClick={() => updateUser()}>Save</ButtonContainer>
                    </div>
                  </CardBody>
                </Card>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}