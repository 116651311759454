import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import usePrevious from '../../../hooks/useprevious';
import { pluck } from '../../../utils';
import * as taskActions from '../../../redux/Task/actions';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Row,
} from 'reactstrap';
import { InputArraySelect } from '../components/InputSelect';
import AsyncSelect from 'react-select/async';
import InputCustom from '../components/InputCustom';
import Select from 'react-select';
import {ButtonContainer} from "@pages/reusable-components/styled-components";

function CreateForm({ notify }) {
  const requireFields = {
    assignee: 'Task Assignee field is required',
    title: 'Task Title field is required',
    task_category: 'Task Category field is required',
    task_resource: 'Resource is required',
    taskable_id: 'Resource option is required',
  };

  const [taskData, setTaskData] = useState({});
  const [taskErrors, setTaskErrors] = useState([]);
  const [dealerMembers, setDealerMembers] = useState([]);
  const [dealsConverted, setDealSConverted] = useState([]);
  const resolver = useRef(null);

  /*
   * redux variables
   * */
  const taskState = useSelector((state) => state.taskState, shallowEqual);
  const dealerState = useSelector((state) => state.dealerState, shallowEqual);
  const dispatch = useDispatch();

  const taskCategories = taskState.categories;
  const taskResources = taskState.resources;
  let dealers = dealerState.data.dealerUsers;
  let deals = taskState.deals;
  let customers = taskState.customers;
  let vehicles = taskState.vehicles;
  let previousTaskCreated = usePrevious(taskState.taskCreatedSuccessfully);
  const history = useHistory();

  useEffect(() => {
    if (taskData.task_resource) {
      const resourceValues = getResourceValues();
      if (resourceValues?.data?.length > 0) {
        const dataFiltered = [];
        resourceValues.data.forEach((type) => {
          dataFiltered.push({
            value: type.id,
            label: type.full_name || type?.vehicle_model?.name,
          });
        });

        if (resolver.current) {
          resolver.current(dataFiltered);
          resolver.current = null;
        }
        setDealSConverted(dataFiltered);
      } else if (resourceValues?.data && resolver.current) {
        resolver.current([]);
      }
    }
  }, [deals, customers, vehicles]);

  useEffect(() => {
    if (
      taskState.taskCreatedSuccessfully !== previousTaskCreated &&
      taskState.taskCreatedSuccessfully === true
    ) {
      notify('Task Created Successfully');
      setTaskData({});
      history.push('/admin/tasks');
    }
  }, [taskState.taskCreatedSuccessfully]);

  useEffect(() => {
    if (dealers && Object.keys(dealers)?.length) {
      Object.entries(dealers).map((dealer, index, array) => {
        dealerMembers.push({ value: dealer[0], label: dealer[1] });
      });
      setDealerMembers(dealerMembers);
    }
  }, [dealers]);

  function getResourceValues() {
    if (taskData.task_resource === 'deal') {
      return deals;
    }
    if (taskData.task_resource === 'customer') {
      return customers;
    }

    return vehicles;
  }
  function handleInputChange(field, value) {
    if (field === 'assignee') {
      value = pluck(value, 'value');
    }

    if (field === 'task_resource') {
      const type = getResourceName(value);
      dispatch({ type: `GET_${type}_REQUEST`, searchValue: '' });
      setTaskData({ ...taskData, [field]: value, taskable_id: null });
      return;
    }

    setTaskData({ ...taskData, [field]: value });
  }

  function validateInputs() {
    let errorFree = true;
    const taskErrorsCopy = [...taskErrors];
    Object.keys(requireFields).map((field) => {
      if (!taskData[field] || taskData[field]?.length === 0) {
        errorFree = false;

        taskErrorsCopy[field] = requireFields[field];
      }
    });

    setTaskErrors(taskErrorsCopy);

    return errorFree;
  }

  function createTask() {
    if (validateInputs()) {
      dispatch({ type: taskActions.CREATE_TASK_REQUEST, data: taskData });
    }
  }

  function getResourceName(resource) {
    switch (resource) {
      case 'customer':
        return 'CUSTOMERS';
      case 'vehicle':
        return 'VEHICLES';
      default:
        return 'DEALS';
    }
  }

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      let type = getResourceName(taskData.task_resource);
      dispatch({ type: `GET_${type}_REQUEST`, searchValue: inputValue });
      resolver.current = resolve;
    });

  return (
    <Row>
      <Col xl="12">
        <Card>
          <CardHeader>
            <Row>
              <Col xs={8}>
                <h2 className="mb-0">Create Task</h2>
              </Col>
            </Row>
          </CardHeader>

          <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
            {taskCategories && (
              <InputArraySelect
                newLabel={`Task category`}
                options={taskCategories}
                onChange={(e) =>
                  handleInputChange('task_category', e.target.value)
                }
                defaultOption={true}
                valueKey={`slug`}
                labelKey={`name`}
                invalid={!!(taskErrors && taskErrors.task_category)}
                errorMessage={
                  taskErrors?.task_category ? taskErrors.task_category : ''
                }
              />
            )}

            {taskResources && (
              <InputArraySelect
                  newLabel={`Task Resource`}
                options={taskResources}
                onChange={(e) =>
                  handleInputChange('task_resource', e.target.value)
                }
                defaultOption={true}
                valueKey={`slug`}
                labelKey={`name`}
                invalid={!!(taskErrors && taskErrors.task_resource)}
                errorMessage={taskErrors?.task ? taskErrors.task_resource : ''}
              />
            )}
            {taskData.task_resource && (
             <Row className={'justify-content-end mb-3'}>
              <Col className={'pl-0'} md={8}>
                <AsyncSelect
                  cacheOptions
                  defaultOptions={dealsConverted}
                  loadOptions={(e) => promiseOptions(e)}
                  onChange={(e) => handleInputChange('taskable_id', e.value)}
                />
                {taskErrors && taskErrors.taskable_id && (
                  <div
                    className="invalid-feedback"
                    style={{ display: 'block' }}
                  >
                    {taskErrors.taskable_id}
                  </div>
                )}
              </Col>
             </Row>

            )}

            <InputCustom
                newLabel={`Task title`}
              onChange={(e) => handleInputChange('title', e.target.value)}
              invalid={!!(taskErrors && taskErrors.title)}
              errorMessage={taskErrors?.title ? taskErrors.title : ''}
            />
            <InputCustom
              type={`textarea`}
              newLabel={`Description`}
              rows={5}
              onChange={(e) => handleInputChange('comments', e.target.value)}
            />
              <Row className={'mb-4'}>
                <Col md={4}>
                  <label className="form-control-label">Assign to</label>
                </Col>
                <Col className={'pl-0'} md={8}>
                  <Select
                      onChange={(v) => handleInputChange('assignee', v)}
                      options={dealerMembers}
                      closeMenuOnSelect={false}
                      isMulti
                  />
                  {taskErrors && taskErrors.task_assignee && (
                      <div className="invalid-feedback" style={{ display: 'block' }}>
                        {taskErrors.task_assignee}
                      </div>
                  )}
                </Col>
              </Row>
            <InputCustom
              type={`date`}
              newLabel={`Due date`}
              onChange={(e) => handleInputChange('due_date', e.target.value)}
              invalid={!!(taskErrors && taskErrors.due_date)}
              errorMessage={taskErrors?.due_date ? taskErrors.due_date : ''}
            />
            <div className={'justify-content-end'}>
              <ButtonContainer
                  reverse
                  onClick={createTask}
              >
                {`Save`}
              </ButtonContainer>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}
export default CreateForm;
