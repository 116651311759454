import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import TableCustom from 'views/pages/components/TableCustom';
import InputCustom from 'views/pages/components/InputCustom';
import {
  InputKeySelect,
} from 'views/pages/components/InputSelect';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
} from 'reactstrap';

export default function FinancialHistory() {
  const { id } = useParams();
  const notificationAlert = useRef(null);
  const history = useHistory();
  
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [intervals, setIntervals] = useState('');
  const [histories, setHistories] = useState([]);
  const [vehicle, setVehicle] = useState('');

  const [termModal, setTermModal] = useState({
    show: false,
    header: '',
    division: '',
  });
  const [term, setTerm] = useState('');

  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedTerm, setSelectedTerm] = useState('');

  const [errors, setErrors] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/vehicle/${id}/financial-history`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data
        
        setIntervals(data.data.intervals)
        setHistories(data.data.items)
        setVehicle(data.data.vehicle)
        
        setLoading(false)
      } catch (error) {
        setLoading(false)
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])
  
  const goList = () => {
    history.push({
      pathname: `/admin/vehicle/${id}/edit`
    })
  }

  const addTerm = () => {
    axios({
      method: 'POST',
      url: `${constants.API_URL}/vehicle/${id}/financial-history`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        ...term,
        id: null,
        to: term.to? term.to: null,
        otr: term.otr? term.otr: null,
        finance_rate: term.finance_rate? term.finance_rate: null,
        total_interest: term.total_interest? term.total_interest: null,
        total_deposit: term.total_deposit? term.total_deposit: null,
        non_refundable_deposit: term.non_refundable_deposit? term.non_refundable_deposit: null,
        end_payment: term.end_payment? term.end_payment: null,
      }
    })
      .then(response => {
        setTermModal({
          ...termModal,
          show: false
        })
        setTerm('')
        setErrors('')
        console.log('res :: ', response.data)
        const data = response.data
        notify('success', 'Success', data.message)
        setHistories(data.data)
      })
      .catch(error => {
        setErrors('')
        console.error('error :: ', error)
        if (error.response && error.response.status == 422) {
          setErrors(error.response.data.errors)
          return
        }
        setTermModal({
          ...termModal,
          show: false
        })
        notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred!')
      })
  }

  const updateTerm = () => {
    axios({
      method: 'POST',
      url: `${constants.API_URL}/vehicle/${id}/financial-history`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: term
    })
      .then(response => {
        setTermModal({
          ...termModal,
          show: false
        })
        setErrors('')
        setTerm('')
        console.log('res :: ', response.data)
        const data = response.data
        notify('success', 'Success', data.message)
        setHistories(data.data)
      })
      .catch(error => {
        setErrors('')
        console.error('error :: ', error)
        if (error.response && error.response.status == 422) {
          setErrors(error.response.data.errors)
          return
        }
        setTermModal({
          ...termModal,
          show: false
        })
        notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred!')
      })
  }

  const deleteTerm = () => {
    axios({
      method: 'DELETE',
      url: `${constants.API_URL}/vehicle/${id}/financial-history/delete/${selectedTerm.id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
    })
      .then(response => {
        setDeleteModal(false)
        console.log('res :: ', response.data)
        let data = response.data
        notify('success', 'Success', data.message)
        setHistories(data.data)
      })
      .catch(error => {
        console.error('error :: ', error)
        setDeleteModal(false)
        notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred!')
      })
  }

  return (
    <>
      <CardsHeader name="Vehicles" parentName={`Vehicle - ${vehicle.vehicle_make && vehicle.vehicle_make.name}, ${vehicle.vehicle_model && vehicle.vehicle_model.name}, ${vehicle.stock_no}`} />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="title">Financial History</h1>
                  </Col>
                  <Col xs={2} className="text-right">
                    <Button className="w-100" color="success" style={{margin: 5}} onClick={() => goList()}>Back to vehicle</Button>
                  </Col>
                </Row>
              </CardHeader>
            </Card>
          </Col>
        </Row>
        
      </Container>
    </>
  )
}