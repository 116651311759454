const viewAny = (permissions) => {
  return permissions.includes('read-franchise-enquiry')
}

const update = (permissions, franchiseEnquire) => {
  return hasPermission(permissions, 'update-franchise-enquiry')
}

const deletee = (permissions, franchiseEnquire) => {
  return hasPermission(permissions, 'delete-franchise-enquiry')
}

const hasPermission = (permissions, permission) => {
  return permissions.includes(permission)
}

export {
  viewAny,
  update,
  deletee,
  hasPermission,
};