import React from 'react';

import { FormGroup, Input } from 'reactstrap';

export default function InputCustom({
  type = 'text',
  className,
  labelClassName,
  label,
  placeholder,
  defaultValue,
  value,
  min,
  max,
  rows,
  onChange,
  disabled,
  readOnly,
  invalid,
  errorMessage,
  onKeyPress,
  checked,
  onBlur,
  innerRef,
  onFocus,
  style,
  newLabel,
  name,
}) {
  return (
    <FormGroup style={style} className={newLabel && 'd-flex align-items-center'}>
      {label && !newLabel && (
        <label className={`form-control-label ${labelClassName}`}>
          {label}
        </label>
      )}
      {newLabel && <label className="form-control-label w-50">{newLabel}</label>}
      <Input
        type={type}
        className={className}
        style={style}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        min={min}
        name={name}
        max={max}
        rows={rows}
        disabled={disabled}
        readOnly={readOnly}
        onChange={onChange}
        invalid={invalid}
        onKeyPress={onKeyPress}
        checked={checked}
        onBlur={onBlur}
        innerRef={innerRef}
        onFocus={onFocus}
      />
      <div className="invalid-feedback">{errorMessage}</div>
    </FormGroup>
  );
}
