import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as signatureActions from '@redux/Signature/actions';
import { Process } from './signatureExperience';
import Loader from './../../components/Loader/Loader';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";

export function Signature() {
  const { token } = useParams();
  const dispatch = useDispatch();

  const { canSignTheDocument, linkErrorMessage, SendEmailSuccess } = useSelector(
    (state) => state.customerSignatureState,
    shallowEqual
  );

  useEffect(() => {
    if (token) {
      dispatch({
        type: signatureActions.GET_VALIDITY_REQUEST,
        payload: { token }
      });
    }
  }, [token])

  return (
    <>
      <Loader />
      <Container className="mt-5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              {canSignTheDocument !==true && <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Document signing process!</h1>
                  </Col>
                </Row>
              </CardHeader>
              }
              {canSignTheDocument && SendEmailSuccess !== true && <Process />}
              {SendEmailSuccess === true && <Row>
                <Col className="p-5 d-flex justify-content-center align-items-center" style={{height: '400px'}}>
                      <h1>Thank you for signing!</h1>
                </Col>
              </Row>}

              {!canSignTheDocument && linkErrorMessage && <CardBody>
                <div className='alert alert-danger'><h3>{linkErrorMessage}</h3></div></CardBody>
              }
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}