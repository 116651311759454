import * as actionTypes from "../action-types";
import {SET_ACTIVE_MENU, SET_MENUS_LIST} from "../action-types";

const initialState = {
  loginData: {},
  profileData: {},
  roles: [],
  permissions: [],
  authUserTeam: {},
  routeCan: {},
  actionNotifications: [],
  generalNotifications: {},
  modules: {
    types: [],
    modules: []
  },
  activeSubmenusList: [],
  menuName: null,
  activeMenu: null,
  csrfToken: '',
  categories: {}
}

const getState = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PROFILE_DATA:
      return {...state, profileData: action.user};

    case actionTypes.SET_ROLES_DATA:
      return {...state, roles: action.roles};

    case actionTypes.SET_PERMISSIONS_DATA:
      return {...state, permissions: action.permissions};

    case actionTypes.SET_AUTH_USER_TEAM:
      return {...state, authUserTeam: action.authUserTeam};

    case actionTypes.SET_ROUTE_CAN:
      return {...state, routeCan: action.routeCan};

    case actionTypes.SET_CSRF_TOKEN:
      return { ...state, csrfToken: action.csrfToken };

    case actionTypes.SET_ACTION_NOTIFICATIONS:
      return {...state, actionNotifications: action.actionNotifications};

    case actionTypes.SET_GENERAL_NOTIFICATIONS:
      return {...state, generalNotifications: action.generalNotifications};

    case actionTypes.SET_CATEGORIES:
      return {...state, categories: action.data};

    case actionTypes.SET_LOGIN_DATA:
      return {...state, loginData: action.payload};

    case actionTypes.SET_EMPTY_STATE:
      return {...state, ...initialState};

    case actionTypes.SET_IS_SUBMENU_ACTIVE:
      return {...state, menuName: action.data};
    case actionTypes.SET_MENUS_LIST:
      return {...state, activeSubmenusList: action.data};
    case actionTypes.SET_ACTIVE_MENU:
      return {...state, activeMenu: action.data};

    case actionTypes.REQUEST_SUCCEEDED:
      if(action.payload.type == actionTypes.GET_MODULES) {
        return {...state, modules: {
            types: action.payload.data.moduleType,
            modules: action.payload.data.module
          }
        };
      }

      return {...state};

    default:
      return state;
  }
}

export default getState;