import React, { useState, useMemo } from 'react';

import {
  Row,
  Col,
  Input,
  CardBody,
  FormGroup,
} from "reactstrap";


export function Checkbox({ field, handleChange, checkedValue }) {

  const values = useMemo(() => {
    let options = field.options?.options ?? '';
    if (options) {
      return options.split(',');
    }

    return [];
  }, [field.value]);

  return (
    <CardBody>
      <Row>
        <Col xs={10}>
          <h2>{field.frontend_name}</h2>
        </Col>
        {values && values.map((value, index) => {
          return (<Col xs={12} key={index}>
            <FormGroup className={`row ml-3`}>
              <div className='d-flex flex-nowrap'>
                <Input
                  type={`checkbox`}
                  id={`custom-checkbox-${index}`}
                  label={value}
                  checked={value == checkedValue}
                  value={value}
                  onChange={(e) => {
                    handleChange(value);
                  }}
                />
                <label for={`custom-checkbox-${index}`} className={`form-control-label`}>
                  {value}
                </label>
              </div>
            </FormGroup>
          </Col>)
        })}
      </Row>
    </CardBody>
  )
}