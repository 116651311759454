import React, {useEffect, useState, useRef} from 'react'
import {useHistory} from 'react-router-dom'
import NotificationAlert from 'react-notification-alert'
import styled from "styled-components";


import TasksWorkersTableFilter from "./ReportsWorkersTableFilter";
import {FailedFinishedWrapper, Text} from "../../../reusable-components/styled-components";
import {parseToWord} from "../../../../../constants";

const ReportsContainer = styled.div`
  min-height: calc(100vh - 128px);
  padding:35px;
`
export default function ReportsTable({
                                         reportKeys,
                                         workerKeys,
                                         initialFilters,
                                         clearData,
                                         makes,
                                         models,
                                         apply,
                                         search,
                                         tableData,
                                         workerData,
                                         departments,
                                         tabName
                                     }) {
    const notificationAlert = useRef(null)
    const history = useHistory()


    const [token, setToken] = useState('')
    const [loading, setLoading] = useState(true)


    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 500)

    }, [])
    const generateCustomItem = (value, key, id) => {
        if (key === 'make' || key === 'model') {
            return <th><Text>{value?.vehicle[key]?.name}</Text></th>
        } else if (key === 'workers') {
            let names = <th style={{display: "flex"}}>
                {
                    value.workers.length > 0 ? value.workers.map((item) => {
                        return <span>{item.name}</span>
                    }) : <Text>-</Text>
                }
            </th>
            return names
        } else if (key === 'time') {
            let names = <th>
                {
                    value.workers.length > 0 ? value.workers.map((item) => {
                        return <Text>{item.time}</Text>
                    }) : <Text>-</Text>
                }
            </th>
            return names
            return <th>{value.workers[0]?.time}</th>
        } else if (key === 'completed') {
            return <th> <FailedFinishedWrapper status='completed'>{value.finished_jobs}</FailedFinishedWrapper> <Text bold>/  {value.finished_jobs_time}</Text></th>
        } else if (key === 'stock_no') {
            return <th> <Text>{value?.vehicle?.stock_no}</Text></th>
        } else if (key === 'failed') {
            return <th><FailedFinishedWrapper status='failed'>{value.in_progress_jobs}</FailedFinishedWrapper> <Text bold>/  {value.in_progress_jobs_time}</Text></th>
        } else return <th><Text>{parseToWord(value[key])}</Text></th>
    }
    const searchData = (e) => {
        search(e)
    }
    return (
        <ReportsContainer>
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlert}/>
            </div>
            <TasksWorkersTableFilter showClearButton clearData={clearData} initialFilters={initialFilters} tabName={tabName} makes={makes} models={models} showDepartment={true}
                                     showCarFilter={true} apply={apply} departments={departments}
                                     onSearchChange={searchData}/>
            <table className={"table" + (window.innerWidth < 833 ? " mobile-table" : "")}>
                <thead>
                {tabName === 'tasks' ? <tr>
                        {
                            reportKeys.map((column, idx ) => {
                                return <th key={'idx'+idx + ' ' +column?.label} tabIndex="0">{column.label}</th>
                            })

                        }
                    </tr> :

                    <tr>
                        {
                            workerKeys.map((column,index) => {
                                return  <th key={'index'+index + ' ' +column?.label} tabIndex="0">{column.label}</th>
                            })

                        }
                    </tr>

                }
                </thead>
                <tbody>
                {tabName === 'tasks' ?
                    tableData && tableData.length > 0 &&
                    tableData.map((item, index) => {
                        return <tr key={index}>
                            {
                                reportKeys.map((value) => {
                                    return generateCustomItem(item, value.key, item?.id)

                                })
                            }

                        </tr>
                    }) :
                    workerData && workerData.length > 0 &&
                    workerData.map((item, index) => {
                        return <tr key={index}>
                            {
                                workerKeys.map((value) => {
                                    return generateCustomItem(item, value.key, item?.id)

                                })
                            }

                        </tr>
                    })
                }
                </tbody>
            </table>

        </ReportsContainer>
    )
}