import { put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from './actions';
import axios from '../../config/axios';

function* getPayments(action) {
  try {
    let {page, from, to, search} = action.data;

    const response = yield axios({
      method: 'GET',
      url: `/payments/list?page=${page}&start=${from ?? ''}&to=${to ?? ''}&search=${search ?? ''}`,
    })

    if (response?.status === 200) {
      const { data } = response.data;
      yield put({
        type: actionTypes.GET_PAYMENT_LIST_SUCCESS,
        data
      });
    }
  } catch (error) {
    console.log(error)
    let payload = error.response.data;
    yield put({ type: actionTypes.GET_PAYMENT_LIST_ERROR, payload });
  }
}

function* getPaymentsCron(action) {
  try {
    let {page, from, to, search} = action.data;

    const response = yield axios({
      method: 'GET',
      url: `/payments/cron/list?page=${page}&start=${from ?? ''}&to=${to ?? ''}`,
    })

    if (response?.status === 200) {
      const { data } = response.data;
      yield put({
        type: actionTypes.GET_CRON_PAYMENT_LIST_SUCCESS,
        data
      });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.GET_CRON_PAYMENT_LIST_ERROR, payload });
  }
}


export default function* watcherSaga() {
  yield takeLatest(actionTypes.GET_PAYMENT_LIST_REQUEST, getPayments);
  yield takeLatest(actionTypes.GET_CRON_PAYMENT_LIST_REQUEST, getPaymentsCron);
}
