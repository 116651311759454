import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';

import * as constants from 'constants/index';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import TableCustom from 'views/pages/components/TableCustom';
import InputCustom from 'views/pages/components/InputCustom';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
} from 'reactstrap';

import {
  update,
  deletee,
  hasPermission
} from 'views/policies/CompanyTypePolicy';
import {ButtonContainer} from "@pages/reusable-components/styled-components";

export default function CompanyType() {
  const notificationAlert = useRef(null);
  const history = useHistory();

  const permissions = useSelector(state => state.getState).permissions;

  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [types, setTypes] = useState([]);
  const [statuses, setStatuses] = useState([]);

  const [type, setType] = useState('');
  const [typeModal, setTypeModal] = useState({
    show: false,
    header: '',
    division: ''
  })

  const [deleteModal, setDeleteModal] = useState({
    show: false,
    header: '',
    body: '',
    onClick: null
  });

  const [errors, setErrors] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/company-type`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data
        
        setTypes(data.data.types)
        setStatuses(data.data.statuses)

        setTimeout(() => {
          setLoading(false)
        }, 500);
      } catch (error) {
        setLoading(false)
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const addType = () => {
    axios({
      method: 'POST',
      url: `${constants.API_URL}/company-type`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: type
    })
      .then(response => {
        setErrors('')
        setTypeModal({
          ...typeModal,
          show: false
        })
        setType('')
        console.log('res :: ', response.data)
        let data = response.data
        setTypes(data.data)
        notify('success', 'Success', data.message)
      })
      .catch(error => {
        setErrors('')
        console.error('error :: ', error)
        if (error.response && error.response.status == 422) {
          setErrors(error.response.data.errors)
          return
        }
        setTypeModal({
          ...typeModal,
          show: false
        })
        notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
      })
  }

  const updateType = () => {
    axios({
      method: 'PUT',
      url: `${constants.API_URL}/company-type/${type.id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: type
    })
      .then(response => {
        setErrors('')
        setTypeModal({
          ...typeModal,
          show: false
        })
        setType('')
        console.log('res :: ', response.data)
        let data = response.data
        setTypes(data.data)
        notify('success', 'Success', data.message)
      })
      .catch(error => {
        setErrors('')
        console.error('error :: ', error)
        if (error.response && error.response.status == 422) {
          setErrors(error.response.data.errors)
          return
        }
        setTypeModal({
          ...typeModal,
          show: false
        })
        notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
      })
  }

  const deleteType = (typeId) => {
    axios({
      method: 'DELETE',
      url: `${constants.API_URL}/company-type/${typeId}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
    })
      .then(response => {
        setDeleteModal({
          ...deleteModal,
          show: false
        })
        console.log('res :: ', response.data)
        let data = response.data
        setTypes(data.data)
        notify('success', 'Success', data.message)
      })
      .catch(error => {
        console.error('error :: ', error)
        setDeleteModal({
          ...deleteModal,
          show: false
        })
        notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
      })
  }

  return (
    <>
      <CardsHeader name="Settings" parentName="Company type Management" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Company Types</h1>
                    <p className="text-sm mb-0">You can manage company types.</p>
                  </Col>
                  <Col xs={4} className="text-right">
                    <ButtonContainer reverse onClick={() => {
                      setType('')
                      setErrors('')
                      setTypeModal({
                        ...typeModal,
                        show: true,
                        header: 'Company type information',
                        division: 'create'
                      })
                    }}>Add company type</ButtonContainer>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <TableCustom
                  loading={loading}
                  items={types}
                  columns={[
                    {
                      dataField: "name",
                      text: "Name",
                      sort: true
                    },
                    {
                      dataField: "status",
                      text: "Status",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return statuses[row.status]
                      }
                    },
                    {
                      dataField: "",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            {(hasPermission(permissions, 'create-company-types') || hasPermission(permissions, 'delete-company-types')) &&

                                 <>
                                   {update(permissions, row) &&
                                   <img className={'cursor-pointer'}
                                        onClick={e => {
                                          setType(row)
                                          setTypeModal({
                                            ...typeModal,
                                            show: true,
                                            header: 'Edit company type',
                                            division: 'udpate'
                                          })
                                        }}
                                        src={require(`assets/images/tableIcons/edit.svg`)} alt=""/>
                                   }
                                   {deletee(permissions, row) &&
                                   <img className={'cursor-pointer'}
                                        onClick={e => setDeleteModal({
                                          ...deleteModal,
                                          show: true,
                                          header: 'Delete company type',
                                          body: 'Are you sure you want to delete this company type?',
                                          onClick: () => deleteType(row.id)
                                        })}
                                        src={require(`assets/images/tableIcons/delete_outline.svg`)} alt=""/>
                                   }
                                 </>
                              }
                          </>
                        )
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={typeModal.show}
          toggle={() => setTypeModal({
            ...typeModal,
            show: false
          })}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title main-color">{typeModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setTypeModal({
                ...typeModal,
                show: false
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <InputCustom
              newLabel={`Name`}
              value={type && type.name ? type.name : ''}
              onChange={e => setType({
                ...type,
                name: e.target.value,
              })}
              invalid={errors && errors.name ? true : false}
              errorMessage={errors.name}
            />
            <FormGroup>
              <div>
                <label className="form-control-label">Status</label>
              </div>
              {
                statuses.map((status, index) => {
                  return (
                    <div className="custom-control custom-radio" key={index}>
                      <input
                        className="custom-control-input"
                        id={index}
                        name="customRadioInline1"
                        type="radio"
                        checked={index == type.status ? true : false}
                        onChange={() => setType({
                          ...type,
                          status: index
                        })}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={index}
                        onClick={() => setType({
                          ...type,
                          status: index
                        })}
                      >
                        {status}
                      </label>
                    </div>
                  )
                })
              }
            </FormGroup>
            <div className="invalid-feedback" style={{ display: errors && errors.status ? 'block' : 'none' }}>
              {errors.status}
            </div>
          </div>
          <div className="modal-footer justify-content-end">
            <ButtonContainer
                onClick={() => setTypeModal({
                  ...typeModal,
                  show: false
                })}
            >
              {`Close`}
            </ButtonContainer>
            <ButtonContainer
              reverse
              onClick={() => typeModal.division == 'create' ? addType() : updateType()}
            >
              {`Save`}
            </ButtonContainer>

          </div>
        </Modal>
        <Modal
          isOpen={deleteModal.show}
          toggle={() => setDeleteModal({
            ...deleteModal,
            show: false
          })}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title">{deleteModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setDeleteModal({
                ...deleteModal,
                show: false
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {`Are you sure you want to delete this business type?`}
          </div>
          <div className="modal-footer">
            <Button
              className="new-event--add"
              color="danger"
              type="button"
              onClick={deleteModal.onClick}
            >
              {`Delete`}
            </Button>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setDeleteModal({
                ...deleteModal,
                show: false
              })}
            >
              {`Close`}
            </Button>
          </div>
        </Modal>
      </Container>
    </>
  )
}