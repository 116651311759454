import React, {useEffect, useState, useRef} from 'react'
import {useHistory} from 'react-router-dom'
import axios from 'axios'
import Cookies from 'js-cookie'
import moment from 'moment'

import * as constants from 'constants/index.js'


import {
    Container,

} from 'reactstrap'
import DynamicTable from "../components/DynamicTable";
import {parseToWord, parseToKey} from "constants/index.js";
import {LoaderContainer, override} from "../reusable-components/styled-components";
import {ClipLoader} from "react-spinners";

export default function Workers() {
    const [loading, setLoading] = useState(true)
    const history = useHistory()
    const [tableData, setTableData] = useState([])
    const [departments, setDepartments] = useState([])
    const [department, selectDepartment] = useState('')
    const [searchValue, setSearchValue] = useState('')
    const [selectedSort, setSelectedSort] = useState(0)
    const [filters, setFilters] = useState({
        from: moment().startOf('month').format('YYYY-MM-DD'),
        to: moment().endOf('month').format('YYYY-MM-DD'),
    })
    const [sortParams, setSortParams] = useState(['desc', 'asc'])

    const [keys, setKeys] = useState(
        [
            {
                key: 'name',
                text: 'Name'
            },
            {
                key: 'department',
                text: 'Department'
            },
            {
                key: 'in_progress_tasks',
                text: 'In progress'
            },
            {
                key: 'completed_tasks',
                text: 'Completed'
            },
            {
                key: 'to_do_tasks',
                text: 'To do'
            },
            {
                key: 'task_count',
                text: 'Task'
            },
            {
                key: 'toDetails',
                text: 'Action'
            },
        ]
    )
    const [statuses] = useState([
        {
            value: 'to_do',
            text: 'To do'
        },
        {
            value: 'in_progress',
            text: 'In progress'
        },
        {
            value: 'in_review',
            text: 'In review'
        },
        {
            value: 'completed',
            text: 'Completed'
        }
    ])

    useEffect(() => {
        const grs_token = Cookies.get('grs_token')
        const source = axios.CancelToken.source()
        let savedFilter = {}
        if (localStorage.getItem('grms_fleet_workers')) {
            savedFilter = JSON.parse(localStorage.getItem('grms_fleet_workers'))
            setSelectedSort(savedFilter?.sort)
            selectDepartment(parseToWord(savedFilter?.department))
            setSearchValue(savedFilter?.search)
        }
        fetchData(source, grs_token, savedFilter, true)

        return () => {
            source.cancel()
        }
    }, [])

    const fetchData = async (source, token, filter, first = false) => {
        localStorage.setItem('grms_fleet_workers', JSON.stringify(filter))
        axios({
            method: 'GET',
            url: `${constants.FLEET_URL}/fleet-management/worker`,
            headers: {
                Authorization: 'Bearer ' + token
            },
            params: filter ? filter : ''
        })
            .then(response => {
                if (response.status === 200) {
                    const data = response.data
                    setTableData(data.data.workers)
                    if (first) {
                        let departments = []
                        data.data.workers.map(item => {
                            if (!departments.includes(parseToWord(item.department))) {
                                departments.push(parseToWord(item.department))
                            }
                        })
                        setDepartments(departments)
                    }
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const searchWorkers = async (searchText) => {
        let tempFilter = filters
        tempFilter['search'] = searchText
        setFilters(tempFilter)
        const source = axios.CancelToken.source()
        const grs_token = Cookies.get('grs_token')
        await fetchData(source, grs_token, tempFilter)
    }
    const filterByDate = async (range) => {
        let tempFilter = filters
        Object.keys(range).map(item=>{
            if (range[item] !== null || range[item] !== '') {
                tempFilter[item] = range[item]
            }
        })
        setFilters(tempFilter)
        const source = axios.CancelToken.source()
        const grs_token = Cookies.get('grs_token')
        await fetchData(source, grs_token, tempFilter)
    }
   const selectFilter = async (value,key)=> {
           let tempFilter = filters
           tempFilter[key] = value
           setFilters(tempFilter)
           const source = axios.CancelToken.source()
           const grs_token = Cookies.get('grs_token')
           await fetchData(source, grs_token, tempFilter)
   }
    const clearFilers = async () =>{
        localStorage.removeItem('grms_fleet_workers')
        setSelectedSort(0)
        setFilters({})
        const source = axios.CancelToken.source()
        const grs_token = Cookies.get('grs_token')
        await fetchData(source, grs_token, {})
    }
    return (
        <>
            {loading ?
                <LoaderContainer>
                    <ClipLoader
                        css={override}
                        size={40}
                        color={`#7B61E4`}
                        loading={loading}
                    />
                </LoaderContainer> :
                <Container className="admin-main-body">
                    <DynamicTable noPriority={false}
                                  keys={keys}
                                  searchValue={searchValue}
                                  showClearButton
                                  clearFilers={clearFilers}
                                  selectDateRange={(e)=>{
                                      filterByDate(e)
                                   }
                                  }
                                  showClear={true}
                                  showRangeFilter={true}
                                  tableData={tableData}
                                  goToDetails={(e) => history.push(`/admin/worker-details?id=${e}`)}
                                  showAlphabet={true}
                                  showDepartment={true}
                                  sortParams={sortParams}
                                  onSearchChange={(e) => {
                                      searchWorkers(e.target.value)
                                  }}
                                  sortValue={selectedSort}

                                  selectDepartment={(e) => {
                                      selectFilter(parseToKey(e),'department')
                                      selectDepartment(e)
                                  }}
                                  selectSort={(e) => {
                                      selectFilter(sortParams[e],'sort')
                                      setSelectedSort(e)
                                  }}
                                  statuses={statuses}
                                  departments={departments}
                                  showCarFilter={false}/>
                </Container>

            }
        </>
    )
}