import React, { useContext } from 'react';
import { LeadsContext } from '../context';

import TableCustom from 'views/pages/components/TableCustom';

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  Row,
  Col,
} from "reactstrap";
import {ButtonContainer} from "@pages/reusable-components/styled-components";

export default function DetailsTab() {
  const leadsContext = useContext(LeadsContext);
  const {
    constants,
    hasPermission,
    quoteModal, setQuoteModal,
    lead,
    setQuoteEmail,
    quoteEmail,
    profilePermissions,
    selectedQuote, setSelectedQuote,
    loading,
    sendMail,
    CreatableSelect,
    quoteTypes,
    quoteErrors,
    InputCustom,
    InputArraySelect,
    makes,
    models, setModels,
    makesAndModels,
    addQuote,
    updateQuote,
  } = leadsContext;
  return (
    <Card>
      <CardBody>
        <Row>
          <Col xs={8}>
            <h3 className="mb-0 main-color">Add Quotes</h3>
          </Col>
          <Col xs={4} className="text-right">
            <ButtonContainer onClick={() => setQuoteModal({
              ...quoteModal,
              show: true,
              header: 'Add a new quote',
              division: 'create',
            })}>Add quote</ButtonContainer>
          </Col>
        </Row>
        <TableCustom 
          loading={loading}
          items={lead.quotes}
          keyField={`id`}
          searchField={false}
          columns={[
            {
              dataField: "make",
              text: "Make",
              sort: true,
              formatter: (rowContent, row) => {
                return row.vehicleModel.vehicleMake.name
              }
            },
            {
              dataField: "model",
              text: "Model",
              sort: true,
              formatter: (rowContent, row) => {
                return row.vehicleModel.name
              }
            },
            {
              dataField: "term",
              text: "Term",
              sort: true
            },
            {
              dataField: "initial_payment",
              text: "Initial",
              sort: true
            },
            {
              dataField: "rental_payment",
              text: "Rental",
              sort: true
            },
            {
              dataField: "created_at",
              text: "Created",
              sort: true,
              formatter: (rowContent, row) => {
                return constants.formatDateFullGB(row.created_at)
              }
            },
            {
              dataField: "mail_delivered_on",
              text: "Sent to customer",
              sort: true,
              formatter: (rowContent, row) => {
                return constants.formatDateFullGB(row.mail_delivered_on)
              }
            },
            {
              dataField: "send_email",
              text: "Send email",
              sort: true,
              formatter: (rowContent, row) => {
                return <label className="custom-toggle">
                  <input
                    type="checkbox"
                    value={0}
                    onChange={(e) => {
                      let newQuoteEmail = quoteEmail
                      let key = row.id
                      newQuoteEmail[key] = e.target.value == 1? 0: 1
                      setQuoteEmail(newQuoteEmail)
                    }}
                  />
                  <span
                    className="custom-toggle-slider rounded-circle"
                    data-label-off="No"
                    data-label-on="Yes"
                  />
                </label>
              }
            },
            {
              dataField: "",
              text: "Action",
              formatter: (rowContent, row) => {
                return (
                  <>
                  {hasPermission(profilePermissions, 'read-leads') &&

                  <img className={'cursor-pointer'}  onClick={() => {
                      setSelectedQuote(row)
                      setSelectedQuote({
                          ...row,
                          make: row.vehicleModel && row.vehicleModel.vehicleMake ? row.vehicleModel.vehicleMake.id : undefined,
                          vehicle_model_id: row.vehicle_model_id,
                      })
                      setQuoteModal({
                          show: true,
                          header: 'Edit quote',
                          division: 'update',
                      })
                  }} src={require(`assets/images/tableIcons/edit.svg`)} alt=""/> }
                  </>
                )
              }
            }
          ]}
        />
        <Row>
            <ButtonContainer className="my-5" reverse onClick={() => sendMail()}>Send Mail</ButtonContainer>
        </Row>
      </CardBody>
      <Modal
        isOpen={quoteModal.show}
        toggle={() => setQuoteModal({
          ...quoteModal,
          show: false,
        })}
        className="modal-xl modal-dialog-centered"
      >
        <div className="modal-header">
          <h4 className="text-center main-color">{quoteModal.header}</h4>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setQuoteModal({
              ...quoteModal,
              show: false,
            })}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
           <Row>
               <Col>
                   <FormGroup className={'d-flex align-items-center'}>
                       <label className="form-control-label w-50">Quote Type</label>
                       {quoteModal.division == 'update' ? <CreatableSelect
                           isClearable
                           className={'w-50'}
                           value={selectedQuote && selectedQuote.lead_quote_type_id ? (quoteTypes.find((element) => element.value == selectedQuote.lead_quote_type_id) || '') : ''}
                           onChange={(item) => {
                               setSelectedQuote({
                                   ...selectedQuote,
                                   lead_quote_type_id: item ? item.value : null,
                               })
                           }}
                           options={quoteTypes}
                       /> : <CreatableSelect
                           isClearable
                           className={'w-50'}
                           onChange={(item) => {
                               setSelectedQuote({
                                   ...selectedQuote,
                                   lead_quote_type_id: item ? item.value : null,
                               })
                           }}
                           options={quoteTypes}
                       />}
                       {quoteErrors && quoteErrors.lead_quote_type_id && <div className="invalid-feedback" style={{ display: 'block' }}>
                           {quoteErrors.lead_quote_type_id}
                       </div>}
                   </FormGroup>
                   <InputCustom
                       type={`number`}
                       newLabel={`Term`}
                       value={selectedQuote && selectedQuote.term ? selectedQuote.term : ''}
                       onChange={(e) => setSelectedQuote({
                           ...selectedQuote,
                           term: e.target.value,
                       })}
                       invalid={quoteErrors && quoteErrors.term ? true : false}
                       errorMessage={quoteErrors.term}
                   />
                   <InputCustom
                       type={`number`}
                       newLabel={`28 day mileage allowance`}
                       value={selectedQuote && selectedQuote.annual_mileage ? selectedQuote.annual_mileage : ''}
                       onChange={(e) => setSelectedQuote({
                           ...selectedQuote,
                           annual_mileage: e.target.value,
                       })}
                       invalid={quoteErrors && quoteErrors.annual_mileage ? true : false}
                       errorMessage={quoteErrors.annual_mileage}
                   />
                   <InputArraySelect
                       newLabel={`Makes`}
                       value={selectedQuote && selectedQuote.make ? selectedQuote.make : ''}
                       options={makes}
                       onChange={(e) => {
                           setModels(e.target.value != '-' ? makesAndModels[e.target.value] : [])
                           setSelectedQuote({
                               ...selectedQuote,
                               make: e.target.value,
                           })
                       }}
                       valueKey={`id`}
                       labelKey={`name`}
                       defaultOption={true}
                   />
               </Col>
               <Col>
                   <InputArraySelect
                       newLabel={`Model`}
                       value={selectedQuote && selectedQuote.vehicle_model_id ? selectedQuote.vehicle_model_id : ''}
                       options={models}
                       onChange={(e) => setSelectedQuote({
                           ...selectedQuote,
                           vehicle_model_id: e.target.value,
                       })}
                       valueKey={`id`}
                       labelKey={`name`}
                       invalid={quoteErrors && quoteErrors.vehicle_model_id ? true : false}
                       errorMessage={quoteErrors.vehicle_model_id}
                   />
                   <InputCustom
                       newLabel={`Derivative`}
                       value={selectedQuote && selectedQuote.derivative ? selectedQuote.derivative : ''}
                       onChange={(e) => setSelectedQuote({
                           ...selectedQuote,
                           derivative: e.target.value,
                       })}
                       invalid={quoteErrors && quoteErrors.derivative ? true : false}
                       errorMessage={quoteErrors.derivative}
                   />
                   <InputCustom
                       type={`number`}
                       newLabel={`Initial Payment`}
                       value={selectedQuote && selectedQuote.initial_payment ? selectedQuote.initial_payment : ''}
                       onChange={(e) => setSelectedQuote({
                           ...selectedQuote,
                           initial_payment: e.target.value,
                       })}
                       invalid={quoteErrors && quoteErrors.initial_payment ? true : false}
                       errorMessage={quoteErrors.initial_payment}
                   />
                   <InputCustom
                       type={`number`}
                       newLabel={`Rental`}
                       value={selectedQuote && selectedQuote.rental_payment ? selectedQuote.rental_payment : ''}
                       onChange={(e) => setSelectedQuote({
                           ...selectedQuote,
                           rental_payment: e.target.value,
                       })}
                       invalid={quoteErrors && quoteErrors.rental_payment ? true : false}
                       errorMessage={quoteErrors.rental_payment}
                   />
               </Col>
           </Row>



          <InputCustom
            label={`Option To Purchase`}
            value={selectedQuote && selectedQuote.option_to_purchase ? selectedQuote.option_to_purchase : ''}
            onChange={(e) => setSelectedQuote({
              ...selectedQuote,
              option_to_purchase: e.target.value,
            })}
          />
          <InputCustom
            type={`textarea`}
            label={`Extras`}
            rows={5}
            value={selectedQuote && selectedQuote.extras ? selectedQuote.extras : ''}
            onChange={(e) => setSelectedQuote({
              ...selectedQuote,
              extras: e.target.value,
            })}
          />
        </div>
        <div className="modal-footer justify-content-end">
            <ButtonContainer
                onClick={() => setQuoteModal({
                    ...quoteModal,
                    show: false,
                })}
            >
                Close
            </ButtonContainer>
          <ButtonContainer
            reverse
            onClick={() => quoteModal.division == 'create' ? addQuote() : updateQuote()}
          >
            {`Save quote`}
          </ButtonContainer>

        </div>
      </Modal>
    </Card>
  );
}