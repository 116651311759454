import React, { useRef, useState, useEffect, useContext } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import moment from 'moment';

import InputCustom from 'views/pages/components/InputCustom';
import { InputKeySelect, InputArraySelect } from 'views/pages/components/InputSelect';
import { PaymentContext } from '../context';
import * as actions from '@redux/Deal/actions';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
} from "reactstrap";


export default function EditInvoicePaymentContent() {
  const context = useContext(PaymentContext);

  const { salesAccounts, taxRates, mappedSalesAccounts, paymentUpdateStatus, paymentUpdateMessage } = useSelector(
    (state) => state.dealState,
    shallowEqual
  );

  const {
    deal,
    history,
    paymentDescriptors,
    flag, setFlag,
    paymentScheduleId,
    dispatch,
    paymentShow,
    paymentItems, setPaymentItems,
    addNewPaymentItem,
    customPayment, setCustomPayment,
    actualInvoice,
    goDeal,
    notify,
    loading, setLoading
  } = context;

  const savePayment = () => {
    setLoading(true);

    dispatch({
      type: actions.UPDATE_INVOICE_REQUEST,
      payload: {
        scheduleId: paymentScheduleId,
        dealId: deal.id,
        body: {
          customPayment, paymentItems
        }
      }
    });
  }

  useEffect(() => {
    if (actualInvoice) {
      setCustomPayment({
        scheduled_at: moment(actualInvoice.due_date).format('YYYY-MM-DD'),
        note: actualInvoice.reference
      })

      let payments = actualInvoice.line_items.map(item => {
        return {
          id: item.line_item_id,
          qty: item.quantity,
          price: item.unit_amount,
          description: item.description,
          total_price: item.line_amount + item.tax_amount,
          vat: item.tax_value,
          sales_account: item.sales_account,
          tax_rate: item.tax_type
        }
      });

      setPaymentItems(payments);
    }
  }, [actualInvoice])

  useEffect(() => {
    if (paymentItems.length > 0) {
      let amount = paymentItems.reduce(
        (previousValue, currentValue) => previousValue + currentValue.total_price,
        0
      )

      setCustomPayment({
        ...customPayment,
        amount: amount,
      })
    }
  }, [paymentItems])

  useEffect(() => {
    if(!actualInvoice) {
      return;
    }

    if (paymentUpdateStatus === true) {
      notify('success', 'Success', "Successfully saved");
      setLoading(false);
      setTimeout(() => {
        goToDetailedInvoice();
      }, 3000)
    } else if (paymentUpdateStatus === false) {
      setLoading(false);
      notify('warning', 'Warning', paymentUpdateMessage ?? 'Error Occurred! Please try again later!')
    }
  }, [paymentUpdateStatus])

  useEffect(() => {
    paymentShow({
      id: paymentScheduleId
    })
  }, [paymentScheduleId])

  const handlePaymentItemChange = (type, value, index, item) => {
    const newPaymentItems = [...paymentItems];

    let totalprice = item.total_price;

    if (['qty', 'vat', 'price'].includes(type)) {
      let qty = type === 'qty' ? value : item.qty;
      let price = type === 'price' ? value : item.price;
      let vat = type === 'vat' ? value : item.vat;
      totalprice = (qty * price) + (qty * price) * parseFloat(vat / 100);
    }

    const paymentItem = {
      ...item,
      [type]: value,
      total_price: totalprice
    };
    newPaymentItems[index] = paymentItem;
    setPaymentItems(newPaymentItems);
  }

  const getSalesAccounts = () => {
    dispatch({
      type: actions.GET_SALES_ACCOUNTS_REQUEST
    });
  };

  const getTaxes = () => {
    dispatch({
      type: actions.GET_TAXES_REQUEST
    });
  };

  const getPaymentDescription = (type) => {
    return `${type} ${deal.vehicle.vehicle_make.name} ${deal.vehicle.vehicle_model.name} ${deal.vehicle.stock_no} ${deal.vehicle.derivative}`;
  }

  const goToDetailedInvoice = () => {
    history.push({
      pathname: `/admin/deals/${deal.id}/payment/${paymentScheduleId}/details`,
    })
  }

  useEffect(() => {
    getSalesAccounts();
    getTaxes();
  }, [deal.id])

  return (
    <>
      <Row>
        <Col xl="12">
          <Card>
            <CardHeader>
              <div className="d-flex justify-content-between">
                <div>
                  <h1 className="title mb-0">{!actualInvoice ? `Pulling data...` : `Editing ${actualInvoice.invoice_number}`}</h1>
                </div>
                <div className="h-50">
                  <Button className="h-50" color="success" type="button" onClick={() => goDeal()}>Deal</Button>
                  <Button className="h-50" color="success" type="button" onClick={() => goToDetailedInvoice()}>Invoice</Button>
                </div>
              </div>
            </CardHeader>
            {actualInvoice && <CardBody>
              <InputCustom
                type={`date`}
                label={`Invoice Due`}
                value={customPayment.scheduled_at ? customPayment.scheduled_at : ''}
                onChange={(e) => setCustomPayment({
                  ...customPayment,
                  scheduled_at: e.target.value,
                })}
              />
              <InputCustom
                type={`text`}
                label={`Invoice Reference`}
                value={customPayment && customPayment.note ? customPayment.note : ''}
                onChange={(e) => setCustomPayment({
                  ...customPayment,
                  note: e.target.value,
                })}
              />
              <InputCustom
                type={`number`}
                label={`Total amount`}
                value={customPayment && customPayment.amount ? customPayment.amount : ''}
                disabled={true}
              />
              {paymentItems.length == 0 ? <Row className="mt-2 mb-2">
                <Col xl="6">
                  <Button
                    color="primary"
                    type="button"
                    onClick={() => addNewPaymentItem({})}
                  >Add more payment items</Button>
                </Col>
              </Row> : null}
              {paymentItems.length > 0 ? paymentItems.map((item, index) => {
                return (<Row className="align-items-end" key={index}>
                  <Col md={2} sm={4}>
                    <InputCustom
                      type={`number`}
                      label={`Qty`}
                      placeholder={`Qty`}
                      value={item.qty}
                      onChange={(e) => {
                        handlePaymentItemChange('qty', e.target.value, index, item);
                      }}
                    />
                  </Col>
                  <Col md={3} sm={4}>
                    <InputCustom
                      type={`text`}
                      label={`Description`}
                      placeholder={`Description`}
                      value={item.description}
                      onChange={(e) => {
                        handlePaymentItemChange('description', e.target.value, index, item);
                      }}
                    />
                  </Col>
                  <Col md={2} sm={4}>
                    <InputCustom
                      type={`number`}
                      label={`Unit Price`}
                      placeholder={`Price`}
                      value={item.price}
                      onChange={(e) => {
                        handlePaymentItemChange('price', e.target.value, index, item);
                      }}
                    />
                  </Col>
                  <Col md={2} sm={4}>
                    <InputCustom
                      type={`number`}
                      label={`Vat (%)`}
                      placeholder={`Vat`}
                      value={item.vat}
                      disabled={true}
                      min={0}
                      max={100}
                    />
                  </Col>
                  <Col md={2}>
                    <InputArraySelect
                      options={taxRates}
                      value={item.tax_rate ?? ''}
                      valueKey={"tax_type"}
                      label={"Tax Rates"}
                      labelKey={"name"}
                      onChange={(e) => {
                        let element = taxRates.find(item => item.tax_type === e.target.value);

                        const paymentItem = {
                          ...item,
                          tax_rate: e.target.value
                        };

                        if (element) {
                          handlePaymentItemChange('vat', element.rate, index, paymentItem);
                        } else {
                          handlePaymentItemChange('tax_rate', e.target.value, index, item);
                        }
                      }}
                      defaultOption={true}
                    />
                  </Col>
                  <Col md={2}>
                    <InputArraySelect
                      options={salesAccounts}
                      value={item.sales_account ?? ''}
                      valueKey={"code"}
                      placeholder={`Vat`}
                      label={"Sales Account"}
                      labelKey={"name"}
                      onChange={(e) => {
                        handlePaymentItemChange('sales_account', e.target.value, index, item);
                      }}
                      defaultOption={true}
                    />
                  </Col>
                  <Col md={2} sm={4}>
                    <InputCustom
                      type={`number`}
                      label={`Total`}
                      disabled={true}
                      placeholder={`Total`}
                      value={item.total_price}
                    />
                  </Col>
                  <Col md={1} sm={4}>
                    <Button
                      className="new-event--add"
                      color="primary"
                      type="button"
                      style={{
                        'marginBottom': '1.5rem'
                      }}
                      onClick={() => {
                        const payments = paymentItems.filter((t, key) => key !== index);
                        setPaymentItems(payments);
                      }}
                    >
                      -
                    </Button>
                  </Col>
                  {index == paymentItems.length - 1 ? (
                    <Col md={12}>
                      <Button
                        className="new-event--add"
                        color="primary"
                        type="button"
                        style={{
                          'marginBottom': '1.5rem'
                        }}
                        onClick={() => addNewPaymentItem({})}
                      >
                        +
                      </Button>
                    </Col>
                  ) : null}
                </Row>
                )
              }) : null}

              <Button
                className="mt-5"
                disabled={loading}
                color="primary"
                type="button"
                onClick={() => savePayment()}
              >
                {`Save`}
              </Button>
            </CardBody>}
          </Card>
        </Col>
      </Row>
    </>
  )
}