import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import NotificationAlert from 'react-notification-alert'
import axios from 'axios'
import Cookies from 'js-cookie'

import * as constants from 'constants/index.js'
import CardsHeader from 'layouts/Headers/CardsHeader.js'

import InputCustom from 'views/pages/components/InputCustom'
import InputSideCustom from 'views/pages/components/InputSideCustom'
import InputFile from 'views/pages/components/InputFile'
import {
  InputKeySelect,
  InputArraySelect,
  InputKeySideSelect,
  InputArraySideSelect,
} from 'views/pages/components/InputSelect'
import InputToggle from 'views/pages/components/InputToggle'
import TableCustom from 'views/pages/components/TableCustom'

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem, CardFooter,
} from 'reactstrap'
import {ButtonContainer} from "@pages/reusable-components/styled-components";

export default function NotificationsEdit() {
  const notificationAlert = useRef(null)
  const history = useHistory()
  const { role } = useParams()

  const [token, setToken] = useState('')
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([])

  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    }
    notificationAlert.current.notificationAlert(options)
  }

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/settings/notifications/${role}/edit`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data

        setItems(data.data.items)

        setTimeout(() => {
          setLoading(false)
        }, 300);
      } catch (error) {
        setLoading(false)
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const goList = () => {
    history.push({
      pathname: `/admin/settings/personalisation/notifications`
    })
  }
  
  const updateNotifications = async () => {
    const notifications = []

    const _items = items
    _items.map((item, index) => {
      if (item.children && item.children.length > 0) {

        const _children = item.children
        _children.map((children, index) => {
          notifications.push({
            id: children.id,
            is_notification_enabled: children.is_notification_enabled
          })
        })
      }
    })
    try {
      const response = await axios({
        method: 'POST',
        url: `${constants.API_URL}/settings/notifications/${role}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          notifications
        }
      })
      console.log('response :: ', response.data)
      const data = response.data

      notify(
        "success",
        "Success",
        "Successfully updated!"
      );
      
    } catch (error) {
      console.error('error :: ', error)
      notify(
        "warning",
        "Warning",
        error.response && error.response.data
          ? error.response.data.message
          : error.response
          ? error.response.statusText
          : "Error Occurred!"
      );
    }
  }

  return (
    <>
      <CardsHeader name="Settings" parentName="Notification Management" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Notifications</h1>
                  </Col>

                </Row>
              </CardHeader>
              <CardBody>
                {
                  items && items.length > 0 && items.map((item, index) => {
                    return (
                      <div key={index}>
                        <h2>{item.name}</h2>
                        {item.children && <TableCustom
                          loading={loading}
                          items={item.children}
                          columns={[
                            {
                              dataField: "name",
                              text: "Name",
                              sort: true,
                            },
                            {
                              dataField: "description",
                              text: "Description",
                              sort: true,
                            },
                            {
                              dataField: "is_notification_enabled",
                              text: "Notification enabled",
                              sort: true,
                              formatter: (rowContent, row) => {
                                return (
                                  <>
                                    <InputCustom 
                                      type={`checkbox`}
                                      checked={row && row.is_notification_enabled === 1 ? true : false }
                                      onChange={e => {
                                        console.log('e.target.value :: ', e.target.checked)
                                        const _items = items
                                        const _childrens = item.children

                                        const __childrens = []
                                        _childrens.map((children, index) => {
                                          if (children.id == row.id) {
                                            __childrens.push({
                                              ...children,
                                              is_notification_enabled: e.target.checked === true ? 1 : 0
                                            })
                                          } else {
                                            __childrens.push({
                                              ...children,
                                            })
                                          }
                                        })

                                        const __items = []
                                        _items.map((item, index) => {
                                          if (item.id == row.parent_id) {
                                            __items.push({
                                              ...item,
                                              children: __childrens
                                            })
                                          } else {
                                            __items.push({
                                              ...item,
                                            })
                                          }
                                        })

                                        setItems(__items)
                                      }}
                                    />
                                  </>
                                );
                              },
                            },
                          ]}
                        />}
                      </div>
                    );
                  })
                }

              </CardBody>
              <CardFooter className={'justify-content-end'}>
                <ButtonContainer reverse  onClick={() => updateNotifications()}>Save</ButtonContainer>
                <ButtonContainer  onClick={() => goList()}>Back to Roles</ButtonContainer>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}