import React, { useEffect, useState } from 'react';
import { Button, Col, FormGroup, Input, Row } from 'reactstrap';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import InputCustom from '@pages/components/InputCustom';
import moment from 'moment';
import CustomSearch from "@pages/components/CustomSearch";
import CalendarFilter from "@pages/components/CalendarFilter";

const Filter = ({ fetchData }) => {
  const paymentState = useSelector((state) => state.paymentState, shallowEqual);
  const [filter, setFilter] = useState({});
  const [dealers, setDealers] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    setFilter({
      ...paymentState.filter
    });
  }, [paymentState.getTasksStatus]);

 const findData = (key,val) => {
   let temp = {...filter, search: val,}
   fetchData(1, temp);
   setFilter(temp)
 }
 const selectDate =(date)=>{
   let temp = {...filter,...date}
   fetchData(1, temp);
   setFilter(temp)
 }
  return (
    <div >
      <Row className={'align-items-center'}>
        <Col md={4}>
          <CustomSearch
              onChange={(e) => {
                findData('search', e.target.value,)

              }}
              placeholder="Search..."
          />
        </Col>
        <Col md={4}>
          <CalendarFilter filterByDate={(data)=>{
            selectDate(data)
          }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default React.memo(Filter);

Filter.propTypes = {
  fetchData: PropTypes.func,
  resetLeads: PropTypes.func,
};
