import { put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from './actions';
import axios from '../../config/axios';
import * as constants from '../../constants';
import { toggleLoader } from 'actions/LoaderActions';

function* getCustomerSignatureDetails(action) {
  try {
    yield put (toggleLoader(true))
    const response = yield axios.post(`${constants.API_URL}/customer-experience/signing/show`, {
      ...action.payload,
    });

    yield put (toggleLoader(false))
    if (response?.status === 200) {
      const { data } = response;
      yield put({ type: actionTypes.GET_VALIDITY_SUCCESS, data });
    }
  } catch (error) {
    yield put (toggleLoader(false))
    let {data, status} = error.response;
    yield put({ type: actionTypes.GET_VALIDITY_ERROR, response: {
      data, status
    } });
  }
}

function* saveCustomer(action) {
  try {
    yield put (toggleLoader(true));

    const response = yield axios({
      method: 'POST',
      url: `${constants.API_URL}/customer-experience/signing`,
      data: action.payload
    })

    yield put (toggleLoader(false))
    console.log(response);
    if (response?.status === 200 && response.data.document) {
      const { data } = response;
      yield put({ type: actionTypes.SIGN_DOCUMENT_SUCCESS, data });
    }
  } catch (error) {
    yield put (toggleLoader(false))
    yield put({ type: actionTypes.SIGN_DOCUMENT_ERROR });
  }
}

export default function* watcherSaga() {
  yield takeLatest(actionTypes.GET_VALIDITY_REQUEST, getCustomerSignatureDetails);
  yield takeLatest(actionTypes.SIGN_DOCUMENT_REQUEST, saveCustomer);
}
