import React, { useContext } from 'react';

import { DealersContext } from '../context';

import InputCustom from 'views/pages/components/InputCustom';
import {
  InputArraySelect,
} from 'views/pages/components/InputSelect';
import InputToggle from 'views/pages/components/InputToggle';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";

export default function DetailsTab() {
  const dealersContext = useContext(DealersContext);
  const {
    dealer,
    setDealer,
    availableDealerAdminUser,
    updateDetail,
    detailErrors,
  } = dealersContext;
  return (
    <Card>
      <CardHeader>
        <h2 className="title">Dealer information</h2>
      </CardHeader>
      <CardBody>
        <InputCustom 
          label={`Identifier`}
          placeholder={`Identifier`}
          value={dealer && dealer.name ? dealer.name : ''}
          onChange={(e) => setDealer({
            ...dealer,
            name: e.target.value
          })}
          invalid={detailErrors && detailErrors.name? true: false}
          errorMessage={detailErrors.name}
        />
        <InputCustom 
          label={`Display name`}
          placeholder={`Display name`}
          value={dealer && dealer.display_name ? dealer.display_name : ''}
          onChange={(e) => setDealer({
            ...dealer,
            display_name: e.target.value
          })}
          invalid={detailErrors && detailErrors.display_name? true: false}
          errorMessage={detailErrors.display_name}
        />
        <InputCustom 
          type={`textarea`}
          label={`Description`}
          placeholder={`Description`}
          rows={5}
          value={dealer && dealer.description ? dealer.description : ''}
          onChange={(e) => setDealer({
            ...dealer,
            description: e.target.value
          })}
          invalid={detailErrors && detailErrors.description? true: false}
          errorMessage={detailErrors.description}
        />
        <InputArraySelect 
          label={`Team admin`}
          options={availableDealerAdminUser}
          value={dealer && dealer.user_id ? dealer.user_id : ''}
          onChange={(e) => setDealer({
            ...dealer,
            user_id: e.target.value
          })}
          valueKey={`id`}
          labelKey={`name`}
          defaultOption={true}
          invalid={detailErrors && detailErrors.user_id? true: false}
          errorMessage={detailErrors.user_id}
        />
        <InputToggle 
          label={`Is active`}
          checked={dealer && dealer.is_active == 1 ? true : false}
          onChange={() =>
            setDealer({
              ...dealer,
              is_active: dealer.is_active == 1 ? 0 : 1
            })
          }
          invalid={detailErrors && detailErrors.is_active? true: false}
          errorMessage={detailErrors.is_active}
        />
        <div className="d-flex flex-row justify-content-center">
          <Button className="w-100" color="success" onClick={() => updateDetail()}>Save</Button>
        </div>
      </CardBody>
    </Card>
  );
}