import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';
import moment from 'moment';

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import TableCustom from 'views/pages/components/TableCustom';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
  Input,
} from 'reactstrap';

const alerts = [
  {
    id: 1,
    reg_no: 'BC18 TTY',
    make: 'Citroen',
    model: 'Berlingo',
    violation: 'Speed Limit Exceeded'
  },
  {
    id: 2,
    reg_no: 'CT19 JJK',
    make: 'VW',
    model: 'Transporter',
    violation: 'Harsh braking'
  },
  {
    id: 3,
    reg_no: 'HT68 PPY',
    make: 'Ford',
    model: 'Ranger',
    violation: 'Harsh Acceleration'
  }
];

export default function AlertsAndNotification() {
  const notificationAlert = useRef(null);
  const history = useHistory();

  const [token, setToken] = useState('');
  const [notifications, setNotifications] = useState([])

  const [filter, setFilter] = useState({
    from: moment().subtract(6, 'days').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
  })

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    fetchData(source, grs_token, filter)

    return () => {
      source.cancel()
    }
  }, [])

  const fetchData = async (source, token, filter) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/tracking/alerts-and-notifications`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
        params: filter
      })
      console.log('response :: ', response.data)
      const data = response.data

      setNotifications(data.data.notifications)
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
      }
    }
  }

  const filterNotification = () => {
    const source = axios.CancelToken.source()
    fetchData(source, token, filter)
  }

  const resetNotification = () => {
    const source = axios.CancelToken.source()
    setFilter({
      from: moment().subtract(6, 'days').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
    })
    fetchData(source, token, {
      from: moment().subtract(6, 'days').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
    })
  }

  return (
    <>
      <CardsHeader name="Alert Notification" parentName="Tracking Management" currentName="Create" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <div>
                    <h1 className="title mb-0">{`Alerts & Notifications`}</h1>
                    <span className="text-sm">{`See all alerts & notifications`}</span>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <Row className="m-2 justify-content-md-center">
                  <Col md={5} sm={12}>
                    <FormGroup>
                      <Row className="d-flex align-items-center justify-content-md-center">
                        <Col md={3}>
                          <label className="form-control-label">From</label>
                        </Col>
                        <Col md={5}>
                          <Input
                            type="date"
                            value={filter && filter.from ? filter.from : ''}
                            onChange={e => {
                              setFilter({
                                ...filter,
                                from: e.target.value
                              })
                            }}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                  <Col md={5} sm={12}>
                    <FormGroup>
                      <Row className="d-flex align-items-center justify-content-md-center">
                        <Col md={3}>
                          <label className="form-control-label">To</label>
                        </Col>
                        <Col md={5}>
                          <Input
                            type="date"
                            value={filter && filter.to ? filter.to : ''}
                            onChange={e => {
                              setFilter({
                                ...filter,
                                to: e.target.value
                              })
                            }}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="m-2 justify-content-md-center">
                  <Col md={4} sm={6} style={{margin: 5}}>
                    <Button className="w-100" color="success" type="button" onClick={() => filterNotification()}>Filter</Button>
                  </Col>
                  <Col md={4} sm={6} style={{margin: 5}}>
                    <Button className="w-100" color="warning" type="button" onClick={() => resetNotification()}>Reset</Button>
                  </Col>
                </Row>
              </CardBody>
              <CardBody>
                <TableCustom 
                  loading={false}
                  items={notifications}
                  keyField={`id`}
                  searchField={false}
                  columns={[
                    {
                      dataField: "reg_no",
                      text: "Reg number:",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.vehicle ? row.vehicle.stock_no : ''
                      }
                    },
                    {
                      dataField: "make",
                      text: "Make",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.vehicleMake ? row.vehicleMake.name : ''
                      }
                    },
                    {
                      dataField: "model",
                      text: "Model",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.vehicleModel ? row.vehicleModel.name : ''
                      }
                    },
                    {
                      dataField: "title",
                      text: "Violation",
                      sort: true
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}