const viewAny = (permissions) => {
  return permissions.includes('create-messages')
}

const list = (permissions) => {
  return permissions.includes('create-messages')
}

const create = (permissions) => {
  return hasPermission(permissions, 'create-messages')
}

const update = (permissions, message) => {
  return hasPermission(permissions, 'update-messages')
}

const deletee = (permissions, message) => {
  return hasPermission(permissions, 'delete-messages')
}

const hasPermission = (permissions, permission) => {
  return permissions.includes(permission)
}

export {
  viewAny,
  list,
  create,
  update,
  deletee,
  hasPermission,
};