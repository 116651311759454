import axios from '../config/axios';

export const saveCustomPayment = (dealId, paymentData, paymentItems) => {
  return axios({
    method: 'POST',
    url: '/deal/' + dealId + '/add-payment',
    data: {
      ...paymentData,
      paymentItems
    }
  })
    .then(response => response.data);
}

export function *updatePayment({dealId, scheduleId, body: {customPayment, paymentItems}}) {
  return yield axios({
    method: 'POST',
    url: `/deal/${dealId}/invoice/${scheduleId}/edit`,
    data: {
      ...customPayment,
      paymentItems
    }
  })
}

export const saveCustomInvoicePayment = (dealId, scheduleId, paymentData) => {
  return axios({
    method: 'POST',
    url: '/deal/' + dealId + '/save-invoice-payment/' + scheduleId,
    data: paymentData
  })
    .then(response => response.data);
}

export const deletePayment = (dealId, scheduleId) => {
  return axios({
    method: 'DELETE',
    url: `/deal/${dealId}/delete/${scheduleId}`
  })
    .then(response => response.data);
}
