import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import NotificationAlert from "react-notification-alert"
import axios from 'axios'
import Cookies from 'js-cookie'
import moment from 'moment'

import L from 'leaflet'

import * as constants from 'constants/index.js'
import CardsHeader from 'layouts/Headers/CardsHeader.js'

import InputCustom from 'views/pages/components/InputCustom'
import Signal from 'views/pages/components/icons/Signal'

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from 'reactstrap'

var locationInterval

const strongColor = '#000000'
const weakColor = '#D5D5D5'

export default function TrackingEdit() {
  const { uuid } = useParams()
  const notificationAlert = useRef(null)

  const [tab, setTab] = useState('overview')
  const [token, setToken] = useState('')

  const [device, setDevice] = useState('')
  const [stats, setStats] = useState('')
  const [location, setLocation] = useState('')
  const [trips, setTrips] = useState([])
  const [selectedTrip, setSelectedTrip] = useState('')
  const [totalMileage, setTotalMileage] = useState('')

  const [filter, setFilter] = useState({
    begin: moment().subtract(6, 'days').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
  })

  const [signalValue, setSignalValue] = useState({
    val1: weakColor,
    val2: weakColor,
    val3: weakColor,
    val4: weakColor,
    val5: weakColor
  })

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    }
    notificationAlert.current.notificationAlert(options)
  }

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/tracking/device/${uuid}/stats/overview`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data
        
        setDevice(data.data.device)
        setStats(data.data.stats)
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
        }
      }
    }

    fetchData()

    return () => {
      clearInterval(locationInterval)
      source.cancel()
    }
  }, [])

  useEffect(() => {
    if (tab == 'location') {
      const fetchLocation = async () => {
        try {
          const response = await axios({
            method: 'GET',
            url: `${constants.API_URL}/tracking/device/${uuid}/location/show`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          console.log('response :: ', response.data)
          const data = response.data

          setTotalMileage(data.data.totalMileage)
          const _location = data.data.location

          const simSignal = _location.location && _location.location.simSignal ? _location.location.simSignal : 0

          const signal = Math.round(simSignal / 20)

          let currentSignalValue = signalValue
          for (let index = 1; index < signal + 1; index++) {
            currentSignalValue[`val${index}`] = strongColor
          }
          for (let index = signal + 1; index < 6; index++) {
            currentSignalValue[`val${index}`] = weakColor
          }
          setSignalValue(currentSignalValue)

          if (_location && _location.location) {
            setLocation(_location)

            const geocodeService = L.Control.Geocoder.nominatim()
            geocodeService.reverse({lat: _location.location.latitude, lng: _location.location.longitude}, 67108864, results => {
              let locationAddress = _location.name
              if (results[0]) {
                locationAddress = results[0].name
              }
  
              const marker = `"markername": { "popup": "${locationAddress}", "appendpoints": [[${_location.location.latitude}, ${_location.location.longitude}]], "label": "${_location.name}", "color": "#f0f" }`
  
              const namedmarker = JSON.parse(`{ ${marker} }`)
              const cmd = JSON.stringify({ "clear": "all", zoommap: 13, "centermap":[_location.location.latitude,_location.location.longitude], "namedmarkers": namedmarker })
    
              if (document.getElementById('mapLocation')) {
                document.getElementById('mapLocation').contentWindow.postMessage(`MapView|cmd: ${cmd}`, '*')
              }
            })
          }
        } catch (error) {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
        }
      }
      fetchLocation()
      var _interval = setInterval(() => {
        fetchLocation()
      }, 60000)
      locationInterval = _interval
    } else if (tab == 'trips') {
      fetchTrips(filter)
      clearInterval(locationInterval)
    } else {
      clearInterval(locationInterval)
    }
  }, [tab])
  
  const fetchTrips = async (filter) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/tracking/device/${uuid}/stats/trips-overview`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: filter
      })
      console.log('response :: ', response.data)
      const data = response.data

      setTrips(data.data.trips)

      if (selectedTrip) {            
        const cmd = JSON.stringify({ "clear": "all", ruler: true, "appendgroutes": [selectedTrip.route] })
        if (document.getElementById('mapTrip')) {
          document.getElementById('mapTrip').contentWindow.postMessage(`MapView|cmd: ${cmd}`, '*')
        }
      }
    } catch (error) {
      console.error('error :: ', error)
      notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
    }
  }

  const TripItem = ({trip}) => {
    return (
      <Row className={`trip-item ${trip.begin == selectedTrip.begin ? `selected-trip-item` : ``} `} onClick={() => selectTrip(trip)}>
        <Col md={4} style={{ color: '#FFFFFF', textAlign: 'center' }}>
          <i className="fa fa-calendar-minus" aria-hidden="true"></i>
          <div>
            {trip.begin ? moment.unix(trip.begin).format("DD/MM/YYYY") : ``}
          </div>
          <div>
            <small><i className="fa fa-hourglass-start" aria-hidden="true"></i> {trip.begin ? moment.unix(trip.begin).format("HH:mm:ss") : `<no start>`}</small>
          </div>
          <div>
            <small><i className="fa fa-hourglass-end" aria-hidden="true"></i> {trip.end ? moment.unix(trip.end).format("HH:mm:ss") : `<no end>`}</small>
          </div>
        </Col>
        <Col md={8} style={{ color: '#FFFFFF', }}>
          <div>
            {`Average speed : ${trip.averageSpeed ? constants.convertKmToMile(trip.averageSpeed) : ``}`}
          </div>
          <div>
            {`Max speed : ${trip.maxSpeed ? constants.convertKmToMile(trip.maxSpeed) : ``}`}
          </div>
          <div>
            {`Distance : ${trip.distance ? constants.convertKmToMile(trip.distance) : ``}`}
          </div>
          <div>
            {`Duration : ${trip.duration ? `${moment.duration(trip.duration * 1000).hours()}h ${moment.duration(trip.duration * 1000).minutes()}m ${moment.duration(trip.duration * 1000).seconds()}s` : ``}`}
          </div>
          <div>
            {`Driving Score : ${trip.drivingScore ? trip.drivingScore : ``}`}
          </div>
          <div>
            {`Overspeeding : ${trip.overspeedingEvents ? trip.overspeedingEvents : 0}`}
          </div>
          <div>
            {`Harsh Acc. : ${trip.harshAccelerationEvents ? trip.harshAccelerationEvents : 0}`}
          </div>
          <div>
            {`Harsh Braking : ${trip.harshBrakingEvents ? trip.harshBrakingEvents : 0}`}
          </div>
          <div>
            {`Harsh Cornering : ${trip.harshCorneringEvents ? trip.harshCorneringEvents : 0}`}
          </div>
        </Col>
      </Row>
    )
  }

  const selectTrip = (trip) => {
    setSelectedTrip(trip)
    const cmd = JSON.stringify({"clear": "all", ruler: true, "appendgroutes": [trip.route]})
    if (document.getElementById('mapTrip')) {
      document.getElementById('mapTrip').contentWindow.postMessage(`MapView|cmd: ${cmd}`, '*')
    }
  }

  const turnVehicle = async () => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/tracking/device/${device.uuid}/immobilise`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          status: device.is_not_immobilised === 1 ? 1 : 0,
        },
      })
      console.log('response :: ', response.data)
      const data = response.data

      let message = ''

      const status = data.data.status.data
      if (status.is_not_immobilised === false) {
        message = 'Vehicle successfully turned off'
        
        setDevice({
          ...device,
          is_not_immobilised: 0
        })
      } else if (status.is_not_immobilised === true) {
        message = 'Vehicle successfully turned on'
        
        setDevice({
          ...device,
          is_not_immobilised: 1
        })
      }

      if (message) {
        notify('success', 'Success', message)
      }
    } catch (error) {
      console.error('error :: ', error)
      notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
    }
  }

  return (
    <>
      <CardsHeader name="Tracking" parentName="Tracking Management" currentName="Edit" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md={3} sm={12} xs={12}>
                    <Button
                      className={`w-100 ${tab == 'overview' ? 'selectedButton' : 'releasedSelectedButton'}`}
                      onClick={() => setTab('overview')}
                      style={{margin: 5}}
                    >
                      Tracking Overview
                    </Button>
                  </Col>
                  <Col md={3} sm={12} xs={12}>
                    <Button
                      className={`w-100 ${tab == 'location' ? 'selectedButton' : 'releasedSelectedButton'}`}
                      onClick={() => setTab('location')}
                      style={{margin: 5}}
                    >
                      Location
                    </Button>
                  </Col>
                  <Col md={3} sm={12} xs={12}>
                    <Button
                      className={`w-100 ${tab == 'trips' ? 'selectedButton' : 'releasedSelectedButton'}`}
                      onClick={() => setTab('trips')}
                      style={{margin: 5}}
                    >
                      Driving Trips
                    </Button>
                  </Col>
                  {/* <Col md={3} sm={12} xs={12}>
                    <Button
                      className={`w-100 ${tab == 'reports' ? 'selectedButton' : 'releasedSelectedButton'}`}
                      onClick={() => setTab('reports')}
                      style={{margin: 5}}
                    >
                      Reports
                    </Button>
                  </Col> */}
                </Row>
              </CardHeader>
            </Card>
            {tab == 'overview' && <Card>
              <CardHeader>
                <h1>Tracking Stats</h1>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={6} sm={12} className="border border-2 border-danger p-3">
                    <h2>Last 24 Hour Stats:</h2>
                    <InputCustom 
                      label={`Total Mileage`}
                      value={stats && stats.distance ? constants.convertKmToMile(stats.distance) : 0}
                      readOnly={true}
                    />
                    <InputCustom 
                      label={`Total Driving Time`}
                      value={stats && stats.drivingTime ? `${moment.duration(stats.drivingTime * 1000).hours()}h ${moment.duration(stats.drivingTime * 1000).minutes()}m ${moment.duration(stats.drivingTime * 1000).seconds()}s` : 0}
                      readOnly={true}
                    />
                    <InputCustom 
                      label={`Max Speed(MPH)`}
                      value={stats && stats.maxSpeed ? constants.convertKmToMile(stats.maxSpeed) : 0}
                      readOnly={true}
                    />
                    <InputCustom 
                      label={`Average Speed(MPH)`}
                      value={stats && stats.averageSpeed ? constants.convertKmToMile(stats.averageSpeed) : 0}
                      readOnly={true}
                    />
                    <InputCustom 
                      label={`Total Idle Time`}
                      value={stats && stats.idleTime ? `${moment.duration(stats.idleTime * 1000).hours()}h ${moment.duration(stats.idleTime * 1000).minutes()}m ${moment.duration(stats.idleTime * 1000).seconds()}s` : 0}
                      readOnly={true}
                    />
                  </Col>
                  <Col md={6} sm={12} className="border border-2 border-danger p-3">
                    <h2>Subscription Info:</h2>
                    <InputCustom 
                      label={`Tracker Live`}
                      defaultValue={`Yes`}
                    />
                    <InputCustom 
                      label={`Subscription Ends in`}
                      defaultValue={`185 Days`}
                    />
                    <InputCustom 
                      label={`Date Subscription Ends`}
                      defaultValue={`27/07/2021`}
                    />
                    <InputCustom 
                      label={`Subscription Renewal Cost`}
                      defaultValue={`80 + VAT per year`}
                    />
                    <div className="d-flex flex-row justify-content-center pt-4">
                      <Button className="w-100" color="success">Renew Subscription</Button>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>}
            {tab == 'location' &&
              <Card>
                <CardBody>
                  <Row>
                    <Col md={6} sm={12} className="border border-2 border-danger p-3">
                      <h2>Vehicle Location:</h2>
                      <iframe id="mapLocation" src="https://flespi.io/mapview/" width="100%" height="600"></iframe>
                    </Col>
                    <Col md={6} sm={12} className="border border-2 border-danger p-3">
                      <h2>Vehicle Status:</h2>
                      <InputCustom
                        label={`Tracker Status`}
                        value={location && location.isNotImmobilised === true ? 'On' : 'Off'}
                        readOnly={true}
                      />
                      {/* <InputCustom
                        label={`Current Vehicel Location`}
                        defaultValue={`Poolman Court, London Road Industrial Estste Pembroke Dock SA72 4RZ`}
                      /> */}
                      <InputCustom
                        label={`Vehicle Status`}
                        value={location && location.location && location.location.movement ? 'Driving' : 'Stopped'}
                        readOnly={true}
                      />
                      <InputCustom
                        label={`Vehicle Speed`}
                        value={location && location.location && location.location.speed ? constants.convertKmToMile(location.location.speed) : 0}
                        readOnly={true}
                      />
                      <InputCustom
                        label={`Total Mileage`}
                        value={totalMileage ? Math.round(totalMileage) : 0}
                        readOnly={true}
                      />
                      <InputCustom
                        label={`Battery Level`}
                        value={location && location.location && location.location.batteryLevel ? location.location.batteryLevel : 0}
                        readOnly={true}
                      />
                      <p>
                        <label className={`form-control-label`}>{`Sim Signal`}</label><br />
                        <Signal signalValue={signalValue} />
                      </p>
                      <div className="d-flex flex-row justify-content-center pt-4">
                        <Button className="w-100" color="success" onClick={() => turnVehicle()}>{`Turn Vehicle ${device.is_not_immobilised === 0 ? 'On' : 'Off'} `}</Button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            }
            {tab == 'trips' && 
            <>
              {/* <Card>
                <CardHeader>
                  <h1>Driving trips:</h1>
                </CardHeader>
                <CardBody>
                  <Row className="m-2 justify-content-md-center">
                    <Col md={5} sm={12}>
                      <FormGroup>
                        <Row className="d-flex align-items-center justify-content-md-center">
                          <Col md={3}>
                            <label className="form-control-label">From</label>
                          </Col>
                          <Col md={5}>
                            <Input
                              type="date"
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                    <Col md={5} sm={12}>
                      <FormGroup>
                        <Row className="d-flex align-items-center justify-content-md-center">
                          <Col md={3}>
                            <label className="form-control-label">To</label>
                          </Col>
                          <Col md={5}>
                            <Input
                              type="date"
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="m-2 justify-content-md-center">
                    <Col md={4} sm={6} style={{margin: 5}}>
                      <Button className="w-100" color="success" type="button">Filter</Button>
                    </Col>
                    <Col md={4} sm={6} style={{margin: 5}}>
                      <Button className="w-100" color="warning" type="button">Reset</Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card> */}
              <Card>
                <CardHeader>
                  <h1>Trip Review:</h1>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={4} sm={6}>
                      <InputCustom 
                        label={`Average Speed`}
                        value={selectedTrip && selectedTrip.averageSpeed ? constants.convertKmToMile(selectedTrip.averageSpeed) : 0}
                        readOnly={true}
                      />
                    </Col>
                    <Col md={4} sm={6}>
                      <InputCustom 
                        label={`Max Speed`}
                        value={selectedTrip && selectedTrip.maxSpeed ? constants.convertKmToMile(selectedTrip.maxSpeed) : 0}
                        readOnly={true}
                      />
                    </Col>
                    <Col md={4} sm={6}>
                      <InputCustom 
                        label={`Distance Travelled`}
                        value={selectedTrip && selectedTrip.distance ? constants.convertKmToMile(selectedTrip.distance) : 0}
                        readOnly={true}
                      />
                    </Col>
                    <Col md={4} sm={6}>
                      <InputCustom 
                        label={`Driving Time`}
                        value={selectedTrip && selectedTrip.duration ? `${moment.duration(selectedTrip.duration * 1000).hours()}h ${moment.duration(selectedTrip.duration * 1000).minutes()}m ${moment.duration(selectedTrip.duration * 1000).seconds()}s` : 0}
                        readOnly={true}
                      />
                    </Col>
                    <Col md={4} sm={6}>
                      <InputCustom 
                        label={`Driving Score`}
                        value={selectedTrip && selectedTrip.drivingScore ? selectedTrip.drivingScore : 0}
                        readOnly={true}
                      />
                    </Col>
                    {/* <Col md={3} sm={6}>
                      <InputCustom 
                        label={`Total Idle Time`}
                        value={selectedTrip && selectedTrip.distance ? selectedTrip.distance : 0}
                        readOnly={true}
                      />
                    </Col> */}
                    {/* <Col md={3} sm={6}>
                      <InputCustom 
                        label={`Harsh Cornering Offences`}
                        defaultValue={`124`}
                      />
                    </Col>
                    <Col md={3} sm={6}>
                      <InputCustom 
                        label={`Harsh Acceleration Offences`}
                        defaultValue={`187`}
                      />
                    </Col>
                    <Col md={3} sm={6}>
                      <InputCustom 
                        label={`Harsh Braking Offences`}
                        defaultValue={`291`}
                      />
                    </Col> */}
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h1>Trips:</h1>
                </CardHeader>
                <CardBody>
                  <Row className="mt-3 pl-3">
                    <Col md={3} sm={12} className="pt-3" style={{ backgroundColor: '#632c28' }}>                      
                      <Row>
                        <Col md={6} sm={12}>
                          <InputCustom
                            type={`date`}
                            label={`From`}
                            labelClassName={`white-label`}
                            value={filter && filter.begin ? filter.begin : ''}
                            max={filter.end}
                            onChange={e => {
                              setFilter({
                                ...filter,
                                begin: e.target.value
                              })
                              fetchTrips({
                                ...filter,
                                begin: e.target.value
                              })
                            }}
                          />
                        </Col>
                        <Col md={6} sm={12}>
                          <InputCustom
                            type={`date`}
                            label={`To`}
                            labelClassName={`white-label`}
                            value={filter && filter.end ? filter.end : ''}
                            min={filter.begin}
                            onChange={e => {
                              setFilter({
                                ...filter,
                                end: e.target.value
                              })
                              fetchTrips({
                                ...filter,
                                end: e.target.value
                              })
                            }}
                          />
                        </Col>
                      </Row>
                      <div style={{ overflowX: 'hidden', overflowY: 'auto', height: 670 }}>
                        {trips.length > 0 &&
                          trips.map((trip, index) => {
                            return <TripItem trip={trip} key={index} />
                          })
                        }
                      </div>
                    </Col>
                    <Col md={9} sm={12}>
                      <iframe id="mapTrip" src="https://flespi.io/mapview/" width="100%" height="800"></iframe>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </>
            }
            {tab == 'reports' &&
            <>
              <Card>
                <CardHeader>
                  <h1>Reports:</h1>
                </CardHeader>
                <CardBody>
                  <Row className="m-2 justify-content-md-center">
                    <Col md={3} sm={6}>
                      <InputCustom 
                        label={`Report Type`}
                        defaultValue={`Select Report type`}
                      />
                    </Col>
                    <Col md={3} sm={6}>
                      <InputCustom 
                        type={`date`}
                        defaultValue={`From`}
                      />
                    </Col>
                    <Col md={3} sm={6}>
                      <InputCustom 
                        type={`date`}
                        defaultValue={`To`}
                      />
                    </Col>
                  </Row>
                  <Row className="m-2 justify-content-md-center">
                    <Col md={6} sm={12}>
                      <Button className="w-100" color="success" type="button">Generate Report</Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h1>Report:</h1>
                </CardHeader>
                <CardBody>
                  
                </CardBody>
              </Card>
            </>
            }
          </Col>
        </Row>
      </Container>
    </>
  )
}