import React, { useState, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas'
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import * as signatureActions from '@redux/Signature/actions';
import {
  Row,
  Col,
  CardBody,
  Button
} from "reactstrap";
import { FieldTitle } from './Process';

export function CustomSignatureCanvas({ field, confirm }) {
  const dispatch = useDispatch();
  const { trimmedDataURL } = useSelector(
    (state) => state.customerSignatureState,
    shallowEqual
  );

  let sigPad = useRef(null);

  const clear = () => {
    sigPad.clear();
    dispatch({
      type: signatureActions.CLEAN_SIGNATURE_URL
    });
  }

  const trim = () => {
    // setTrimmedDataURL(sigPad.getTrimmedCanvas().toDataURL('image/png'))
    confirm(sigPad.getTrimmedCanvas().toDataURL('image/png'));

    dispatch({
      type: signatureActions.SET_SIGNATURE_URL,
      payload: { trimmedDataURL: sigPad.getTrimmedCanvas().toDataURL('image/png') }
    });
  }

  return (
    <>
      <CardBody>
        <Row>
          <Col xs={10}>
            <FieldTitle className="mb-sm-3 mb-1">{field.frontend_name}</FieldTitle>
          </Col>
          <Col xs={12} md={8}>
            <Row>
              <Col xs={12}>
                <div className='signature-pad d-flex justify-content-center' style={{visibility: trimmedDataURL ? 'hidden': 'initial'}}>
                  <SignatureCanvas penColor='black'
                    ref={(ref) => { sigPad = ref }}
                    canvasProps={{ height: 200, className: 'sigCanvas' }} />
                </div>
              </Col>
              {trimmedDataURL
                ?
                <Col xs={12}>
                  <Row>
                    <Col xs={12} className=''>
                      <h2 className='text-center'>Confirmed Signature</h2>
                    </Col>
                    <Col xs={12} className="d-flex justify-content-center"><img
                      src={trimmedDataURL} />
                    </Col>
                  </Row>
                </Col>
                : null}
            </Row>
          </Col>
          <Col xs={12} md={4}>
            <div className='d-flex mt-5'>
              <Button
                className="my-4 w-50 h-25"
                color="info"
                type="button"
                onClick={clear}
              >
                Clear and re-sign
              </Button>
              {!trimmedDataURL && <Button
                className="my-4 w-50 h-25"
                type="button"
                color="success"
                onClick={trim}
              >
                Confirm Signature
              </Button>
              }
            </div>
          </Col>
        </Row>
      </CardBody>
    </>
  )
}