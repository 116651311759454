import React from 'react'

import {
  FormGroup
} from 'reactstrap'

export default function VerticalLabel({
  label,
  labelStyle = {
    fontSize: '16px',
    fontWeight: 400,
    borderBottom: '1px solid #c0c0c0',
    color: '#2d2d2d',
    lineHeight: '16px'
  },
  value,
  valueStyle = {
    fontWeight: '600',
    fontSize: '16px',
    color: '#414141'
  }
}) {
  return (
    <>
      <FormGroup>
        <label style={labelStyle}>
          {label}
        </label>
        <div
          style={valueStyle}
          dangerouslySetInnerHTML={{
            __html: value
          }}>
        </div>
      </FormGroup>
    </>
  )
  
}