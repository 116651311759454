import React, {useEffect, useState, useRef, Component} from 'react'
import {useHistory} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import NotificationAlert from 'react-notification-alert'
import Select, {components} from 'react-select'
import styled from "styled-components";
import axios from 'axios'
import Cookies from 'js-cookie'

import * as constants from 'constants/index.js'
import CardsHeader from 'layouts/Headers/CardsHeader.js'

import InputCustom from 'views/pages/components/InputCustom'
import {
    InputArraySelect,
} from 'views/pages/components/InputSelect'
import actions from 'actions/index.js';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Modal,
    FormGroup, Label, Input, ModalHeader, ModalBody
} from 'reactstrap'
import {TaskDetailsContainer, Text, TextContainer} from "@pages/reusable-components/styled-components";
import CreateEditDelivery from "@pages/job-booker/CreateEditDelivery";


export default function CreateNewDeliveryTask({setShowCreateNew, updateData}) {
    const notificationAlert = useRef(null)
    const history = useHistory()
    const [token, setToken] = useState('')
    const [jobData, setJobData] = useState({})
    const [allTasks, setAllTasks] = useState([])
    const [taskIndex, setTaskIndex] = useState(0)
    const [sendingData, setSendingData] = useState({})
    const [loading, setLoading] = useState(false)
    const [updateCreated, setUpdateCreated] = useState(false)
    const [disabled, setDisabled] = useState('')
    const [drivers, setDrivers] = useState([])
    const [regNos, setRegNos] = useState([])
    const [vehicle, setVehicle] = useState('')

    const notify = (type, header, message) => {
        const options = {
            place: "tc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
              {header}
          </span>
                    <span data-notify="message">
            <div dangerouslySetInnerHTML={{__html: message}}/>
          </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5
        }
        notificationAlert.current.notificationAlert(options)
    }

    useEffect(() => {
        const grs_token = Cookies.get('grs_token')
        setToken(grs_token)

        const source = axios.CancelToken.source()

        const fetchData = async () => {
            try {
                const response = await axios({
                    method: 'GET',
                    url: `${constants.FLEET_URL}/job-booker/vehicles-available`,
                    headers: {
                        Authorization: `Bearer ${grs_token}`,
                    },
                    cancelToken: source.token,
                })
                const _regNos = response.data.data
                let _regNos_array = [];
                _regNos.map(_regNo => {
                    return _regNos_array.push({
                        ..._regNo,
                        value: _regNo.id,
                        label: _regNo.stock_no,
                    })
                })
                setRegNos(_regNos_array)
            } catch (error) {
                if (axios.isCancel(error)) {
                    console.log('Request canceled :: ', error)
                } else {
                    console.error('error :: ', error)
                    notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
                }
            }
        }

        fetchData()

        return () => {
            source.cancel()
        }
    }, [])

    const searchVehicle = () => {
        if (vehicle) {
            fetchJobs(vehicle.id)
        } else {
            notify('info', 'Information', 'Please select a reg no.')
        }
    }

    const fetchJobs = async (id) => {
        try {
            setLoading(true)
            const response = await axios({
                method: 'GET',
                url: `${constants.FLEET_URL}/job-booker/vehicle-details/${id}`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            if (response?.data.data.length) {
                setJobData(response?.data.data[0])
            }
            const drivers = await axios({
                method: 'GET',
                url: `${constants.FLEET_URL}/job-booker/get-available-drivers`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            if (drivers?.data.data.length) {
                setDrivers(drivers?.data.data)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
        }
    }

    const ValueContainer = ({children, ...props}) => {
        return (
            components.ValueContainer && (
                <components.ValueContainer {...props}>
                    {!!children && (
                        <i
                            className="fa fa-search"
                            aria-hidden="true"
                            style={{position: 'absolute', left: 6,}}
                        />
                    )}
                    {children}
                </components.ValueContainer>
            )
        );
    };

    const styles = {
        valueContainer: base => ({
            ...base,
            paddingLeft: 24
        })
    }
    const updateCollectionData = (index) => {
        let tempTasks = allTasks
        tempTasks[index] = sendingData
        setUpdateCreated(false)
        setAllTasks(tempTasks)
    }
    const addToCollection = () => {
        let tempData = sendingData
        tempData.customer_id = jobData.customer_id
        tempData.customer_email = jobData.customer_email
        tempData.vehicle_id = vehicle.id
        if (tempData.task_type === 'grs_collection') {
            tempData.driver_getter_phone = '01646 603 872'
            tempData.driver_getter_address = 'Unit 12 & 13, Milford Haven SA73 2DA'
        }
        setTaskIndex(allTasks.length + 1)
        setAllTasks([
            ...allTasks,
            tempData
        ])
    }
    const selectItem = (index) => {
        setUpdateCreated(true)
        return allTasks[index]
    }

    const sendData = async () => {
        const response = await axios({
            method: 'POST',
            url: `${constants.FLEET_URL}/job-booker/create-delivery-task`,
            data: allTasks,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
        if (response.status === 200) {
            setShowCreateNew(false)
            updateData()
        }
        // history.push(`/admin/vehicle-details?id=${id}`)
    }

    const checkData = (data) => {
        let isDisabled = false
        setSendingData(data)
        let required = ['customer_address', 'customer_name', 'customer_phone', 'customer_postal_code']
        for (let key in data) {
            if (required.includes(key) && (data[key] === null || data[key] === "" || data[key] === undefined)) {
                isDisabled = true;
                break
            }
        }
        setDisabled(isDisabled)
    }
    return (
        <>
            {/*<CardsHeader name="Fleets" parentName="Job Management" currentName="Create" />*/}
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlert}/>
            </div>
            <Card>
                <CardBody>
                    <Container style={{minHeight: 'calc(100vh - 128px)'}}>
                        <Row>
                            <Col md={6} sm={12} xs={12}>
                                <FormGroup>
                                    <label className="form-control-label">Please enter the Vehicle Reg. Number for
                                        creating task</label>
                                    <Select
                                        options={regNos}
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isSearchable={true}
                                        name="Please enter the Vehicle Reg. Number for applying the checklist"
                                        components={{
                                            DropdownIndicator: () => null,
                                            IndicatorSeparator: () => null,
                                            ValueContainer
                                        }}
                                        placeholder={vehicle.stock_no ? vehicle.stock_no : 'Reg. Number'}
                                        styles={styles}
                                        onChange={value => {
                                            setVehicle(value);
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={2} sm={12} xs={12} className="pt-1-75">
                                <Button className="w-100" style={{backgroundColor: "#ec6409", color: 'white'}}
                                        type="button" onClick={() => searchVehicle()}>Search</Button>
                            </Col>
                        </Row>
                        {Object.values(jobData).length ?
                            <CreateEditDelivery loading={loading} setShowCreateNew={setShowCreateNew} jobData={jobData}
                                                drivers={drivers}
                                                selectItem={selectItem}
                                                updateCreated={updateCreated}
                                                updateCollectionData={updateCollectionData}
                                                allTasks={allTasks}
                                                index={taskIndex}
                                                disabled={disabled} save={sendData} addToCollection={addToCollection}
                                                checkData={checkData}>
                            </CreateEditDelivery> : <></>}
                    </Container>
                </CardBody>
            </Card>
        </>
    )
}