import React, {useEffect, useState, useRef} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import NotificationAlert from 'react-notification-alert'
import axios from 'axios'
import Cookies from 'js-cookie'

import * as constants from 'constants/index.js'
import CardsHeader from 'layouts/Headers/CardsHeader.js'

import FleetCalendar from 'views/pages/components/FleetCalendar';

import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
} from 'reactstrap'
import CreateEditDelivery from "@pages/job-booker/CreateEditDelivery";
import {useLocation} from "react-router";

const gradientColor = [
    'bg-gradient-yellow',
    'bg-gradient-purple',
    'bg-gradient-primary'
]

const fontColor = [
    'text-black',
    'text-white',
    'text-white'
]

export default function UpdateTask() {
    const history = useHistory()
    const location = useLocation()
    const [token, setToken] = useState('')
    const [loading, setLoading] = useState(true)
    const [jobData, setJobData] = useState({})
    const [sendingData, setSendingData] = useState({})
    const [disabled, setDisabled] = useState('')
    const [drivers, setDrivers] = useState([])
    const [id, setId] = useState(null)


    useEffect(() => {
        let id = location.search
        let numb = id.match(/\d/g);
        id = numb.join("");
        setId(id)
        const grs_token = Cookies.get('grs_token')
        setToken(grs_token)
        fetchJobs(id, grs_token)
    }, [])


    const fetchJobs = async (id, token) => {
        try {
            const response = await axios({
                method: 'GET',
                url: `${constants.FLEET_URL}/job-booker/task-details/${id}`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
          let tempData = response?.data.data
            if (tempData.length) {
             tempData[0].customer_name = tempData[0].customer_name.length === 0 ? tempData[0].full_name : tempData[0].customer_name
             tempData[0].customer_address = tempData[0].customer_address.length === 0 ? tempData[0].address : tempData[0].customer_address
             tempData[0].customer_phone = tempData[0].customer_phone.length === 0 ? tempData[0].mobile_number : tempData[0].customer_phone
                setJobData(tempData[0])
            }
            const drivers = await axios({
                method: 'GET',
                url: `${constants.FLEET_URL}/job-booker/get-available-drivers`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            if (drivers?.data.data.length) {
                setDrivers(drivers?.data.data)
            }

        } catch (error) {

        }
    }

    const checkData = (data) => {
        setSendingData(data)
        let isDisabled = false
        let required = ['customer_address','customer_name','customer_phone','customer_postal_code']
        for (let key in data) {
        if (required.includes(key) && (data[key] === null || data[key] === "" || data[key] === undefined)) {
            isDisabled = true;
            break
            }
        }
        setDisabled(isDisabled)
    }
    const sendData = async () => {
        let tempData = sendingData
        tempData.customer_id = jobData.customer_id
        tempData.vehicle_id = jobData.vehicle_id
        tempData.id = id
        setLoading(true)
        const response = await axios({
            method: 'POST',
            url: `${constants.FLEET_URL}/job-booker/update-delivery-task`,
            data: tempData,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
        if (response.status === 200) {
            history.push(`/admin/job-booker/schedule`)
        }
        // setLoading(false)
    }

    return (
        <>
            {Object.values(jobData).length ?
                <CreateEditDelivery creating={false} jobData={jobData} drivers={drivers}
                                    disabled={disabled} save={sendData}
                                    checkData={checkData} exit={() => history.push(`/admin/job-booker/schedule`)}>
                </CreateEditDelivery> : <></>}
        </>
    )
}