import React, { useEffect } from 'react';
import NotificationAlert from 'react-notification-alert';
import CardsHeader from 'layouts/Headers/CardsHeader.js';
import * as taskActions from '@redux/Report/actions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import useNotify from '../../../hooks/notify';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from 'reactstrap';
import {ButtonContainer} from "@pages/reusable-components/styled-components";

export default function FleetReport() {
  const dispatch = useDispatch();
  const { downloadReportError, downloadReportMessage } = useSelector(
    (state) => state.reportState,
    shallowEqual
  );
  const { ref, notify } = useNotify();

  useEffect(() => {
    if (downloadReportError) {
      notify(downloadReportMessage, 'warning', 'Warning');
    }
  }, [downloadReportError]);

  const downloadReport = () => {
    dispatch({ type: taskActions.DOWNLOAD_REPORT_REQUEST });
  };

  return (
    <>
      <CardsHeader
        name="Sales"
        parentName="Fleet management Report"
        currentName="List"
      />
      <div className="rna-wrapper">
        <NotificationAlert ref={ref} />
      </div>
      <Container className="mt--5 admin-main-body" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <h1 className="title">Fleet management report</h1>
              </CardHeader>
              <CardBody>
                <Row className="m-2 justify-content-md-center">
                    <ButtonContainer
                      reverse
                      onClick={() => downloadReport()}
                    >
                      Download Report
                    </ButtonContainer>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
