import React, {useState} from 'react';


import {Container} from "reactstrap";
import Draggable from "@pages/components/drag/Draggable";
import {CustomTd, DeliveryTableItem, Text} from "@pages/reusable-components/styled-components";
import {formatDate} from "../../../../constants";
import {DragOverlay, useDroppable} from "@dnd-kit/core";
import {
    SortableContext,
    verticalListSortingStrategy
} from "@dnd-kit/sortable";

export default function Drag({id, items, itemKey, dateIndex, date, activeId, droppingItem,deleteItem}) {
    const {setNodeRef} = useDroppable({
        id
    });

    return (

        <SortableContext
            id={id}
            items={items[itemKey]}
            strategy={verticalListSortingStrategy}
        >
            <CustomTd ref={setNodeRef}>
                {
                    items[itemKey].map((value, index) => {
                        if (date?.currentDate?.toString() === formatDate(value?.execution_date?.toString())) {
                            return (
                                `${id}_${value.delivery_id}` !== activeId ?
                                    <div>
                                        <Draggable
                                            key={value.delivery_id}
                                            deliveryId={value.delivery_id}
                                            type={value.task_type}
                                            text={value.reg_no}
                                            value={value}
                                            id={`${id}_${value.delivery_id}`}
                                            index={index}
                                            dateIndex={dateIndex}
                                            deleteItem={deleteItem}
                                        />
                                    </div> :
                                    <>
                                        <DragOverlay>
                                            <Draggable
                                                key={value.delivery_id}
                                                type={value.task_type}
                                                text={value.reg_no}
                                                id={`${id}_${value.delivery_id}`}
                                                index={index}
                                                dateIndex={dateIndex}
                                            />
                                        </DragOverlay>
                                    </>

                            )
                        }

                    })

                }
                {
                    items[itemKey].map((value, index) => {
                        return (
                            Object.values(droppingItem).length &&
                            droppingItem.containerId === itemKey &&
                            date?.currentDate?.toString() === formatDate(droppingItem?.execution_date?.toString()) && index === 0 ?
                                <DeliveryTableItem opacity='0.5' type={droppingItem.task_type}>
                                    {droppingItem.task_type === 'collect' ?
                                        <img height={'15px'} width={'16px'}
                                             src={require('assets/images/job/carbon_delivery.png')} alt=""/> :
                                        droppingItem.task_type === 'delivery' ?
                                        <img height={'15px'} width={'16px'}
                                             src={require('assets/images/job/carbon_delivery-add.png')} alt=""/> : null
                                    } <Text color='#ffffff'>{droppingItem.reg_no}</Text>
                                </DeliveryTableItem>
                            :
                        <></>
                    )
                    })
                }

            </CustomTd>


        </SortableContext>
    )
}