import * as actionTypes from './actions';
import produce from 'immer';
import moment from 'moment';

const initialState = {
  payments: [],
  paymentCrons: [],
  getTasksStatus: null,
  getPaymentsCronStatus: null,
  filter: {
    end: '',
    start: '',
    search: ''
  },
  paymentDescriptorsArray: {}
};

const paymentStore = produce((draft = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PAYMENT_LIST_REQUEST:
      draft.getTasksStatus = false;
      break;
    case actionTypes.GET_PAYMENT_LIST_SUCCESS:
      draft.payments = {...action.data.items};
      draft.paymentDescriptorsArray = {...action.data.paymentDescriptors};
      draft.filter = {
        from: action.data.filters?.start ? moment(action.data.filters.start).format('YYYY-MM-DD') : '',
        to: action.data.filters?.end ?  moment(action.data.filters.end).format('YYYY-MM-DD') : '',
        search: action.data.filters?.search ?? '',
      };
      draft.getTasksStatus = true;
      break;
    case actionTypes.GET_PAYMENT_LIST_ERROR:
      draft.updateCommentSuccess = false;
      draft.getTasksStatus = false;
      break;

    case actionTypes.GET_CRON_PAYMENT_LIST_REQUEST:
      draft.getPaymentsCronStatus = false;
      break;
    case actionTypes.GET_CRON_PAYMENT_LIST_SUCCESS:
      draft.paymentCrons = {...action.data.items};
      draft.filter = {
        from: action.data.filters?.start ? moment(action.data.filters.start).format('YYYY-MM-DD') : '',
        to: action.data.filters?.end ?  moment(action.data.filters.end).format('YYYY-MM-DD') : '',
        search: action.data.filters?.search ?? '',
      };
      draft.getPaymentsCronStatus = true;
      break;
    case actionTypes.GET_CRON_PAYMENT_LIST_ERROR:
      draft.getPaymentsCronStatus = false;
      break;
    default:
      return draft;
  }
});

export default paymentStore;
