import React, {useEffect, useRef, useState} from 'react'
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "react-modern-calendar-datepicker";
import {
    ButtonContainer,
    CalendarInput,
    FilterContainer
} from "@pages/reusable-components/styled-components"
import moment from 'moment'
export default function Picker({selectDate})
{
    const [selectedDay, setSelectedDay] = useState(null);
    const renderCustomInput = ({ref}) => (
        <CalendarInput
            readOnly
            ref={ref} // necessary
            placeholder="Select date"
            value={selectedDay ? moment({...selectedDay, month: selectedDay.month - 1,}).format('DD/MMM/YYYY') : ''}
        />
    )

    return (
        <DatePicker
            value={selectedDay}
            onChange={(e) => {
                selectDate(moment({...e, month: e.month - 1,}).format('YYYY-MM-DD'))
                setSelectedDay(e)
            }}
            renderInput={renderCustomInput}
            shouldHighlightWeekends
        />
    )
}