import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import * as constants from 'constants/index.js';
import Cookies from "js-cookie";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
  Input,
} from "reactstrap";

import CardsHeader from 'layouts/Headers/CardsHeader.js';

export default function NoteEdit() {
  const { customerId, noteId } = useParams();
  const location = useLocation();
  const parentPath = {
    pathnme: '/admin/customer/' + customerId + '/edit',
    state: {
      filter: location.state && location.state.filter? location.state.filter: ''
    }
  };
  const history = useHistory();
  const notificationAlert = useRef(null);

  const [token, setToken] = useState('');

  const [note, setNote] = useState('');
  const [errors, setErrors] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/customer/${customerId}/notes/${noteId}/edit`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data
        setNote(data.data);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const updateNote = () => {
    axios({
      method: 'PUT',
      url: constants.API_URL + '/customer/' + customerId + '/notes/' + noteId,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: note,
    })
      .then(response => {
        setErrors('');
        if (response.status == 200) {
          let data = response.data;
          console.log('response data :: ', data);
          notify('success', 'Success', response.data.message);
          setTimeout(() => {
            history.push({
              pathname: '/admin/customer/' + customerId + '/notes',
              state: {
                filter: location.state && location.state.filter? location.state.filter: ''
              }
            })
          }, 1500);
        }
      })
      .catch(error => {
        setErrors('');
        console.error('error :: ', error);
        if (error.response && error.response.status == 422) {
          let errors = error.response.data.errors;
          setErrors(errors);
          return;
        }
        notify('warning', 'Warning', error.response.statusText);
      })
  }

  const goList = () => {
    history.push({
      pathname: '/admin/customer/' + customerId + '/notes',
      state: {
        filter: location.state && location.state.filter? location.state.filter: ''
      }
    })
  }

  return (
    <>
      <CardsHeader name="Customers" parentName="Customer Management" currentName="Note Edit" parentPath={parentPath} />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Note Management</h1>
                  </Col>
                  <Col xs={4} className="text-right">
                    <Button className="w-100" color="success" type="button" onClick={() => goList()}>Back to list</Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <FormGroup>
                  <label className="form-control-label">Note</label>
                  <Input
                    className="form-control-alternative edit-event--title"
                    placeholder="Note"
                    type="textarea"
                    rows="8"
                    value={note.note}
                    onChange={(e) => setNote({
                      ...note,
                      note: e.target.value,
                    })}
                    invalid={errors && errors.note ? true : false}
                  />
                  <div className="invalid-feedback">
                    {errors.note}
                  </div>
                </FormGroup>
                <div className="d-flex flex-row justify-content-center">
                  <Button className="w-100" color="success" onClick={() => updateNote()}>Save</Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}