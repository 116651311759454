import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import InputCustom from 'views/pages/components/InputCustom';
import {
  InputKeySelect
} from 'views/pages/components/InputSelect';
import TableCustom from 'views/pages/components/TableCustom';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from 'reactstrap';

export default function EditReminder() {
  const { id } = useParams();
  const notificationAlert = useRef(null);
  const history = useHistory();

  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState('');
  const [statuses, setStatuses] = useState('');

  const [errors, setErrors] = useState('');

  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/automated-reminders/${id}/edit`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data
        setItem(data.data.item)
        setStatuses(data.data.statuses)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    };
  }, [])

  const goList = () => {
    history.push({
      pathname: `/admin/settings/automated-reminders`
    })
  }

  const editDeal = (reminderable) => {
    history.push({
      pathname: `/admin/deals/${reminderable.id}/edit`
    })
  }

  const editVehicle = (reminderable) => {
    history.push({
      pathname: `/admin/vehicle/${reminderable.id}/edit`
    })
  }

  const updateReminder = () => {
    axios({
      method: 'PUT',
      url: `${constants.API_URL}/automated-reminders/${item.id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        ...item,
        task_title: item.title
      }
    })
      .then(response => {
        console.log('response :: ', response.data)
        const data = response.data
        setItem(data.data.item)
        notify('success', 'success', data.message)
      })
      .catch(error => {
        setErrors('')
        console.error('error :: ', error)
        if (error.response && error.response.status === 422) {
          setErrors(error.respnose.data.errors)
          return
        }
        notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
      })
  }

  return (
    <>
      <CardsHeader name="Reminders" parentName="All tasks" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="title mb-0">Automated Reminder for <a className="btn btn-success" onClick={() => {
                      item.reminderable_entity_type === 'Deal'? editDeal(item.reminderable): editVehicle(item.reminderable)
                    }}>{`#${item.reminderable_entity_type}-${item.reminderable && item.reminderable.id}`}</a></h1>
                  </Col>
                  <Col xs={4} className="text-right mt-2">
                    <Button className="w-100" color="success" type="button" onClick={() => goList()}>Back to list</Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <p className="heading-small mb-4">{`Due Date ${constants.formatDate(item.due_date)}`}</p>
                <InputCustom 
                  label={`Task title`}
                  value={item && item.title? item.title: ''}
                  onChange={e => setItem({
                    ...item,
                    title: e.target.value,
                  })}
                  invalid={errors && errors.task_title? true: false}
                  errorMessage={errors.task_title}
                />
                <InputCustom 
                  type={`textarea`}
                  rows={8}
                  label={`Description`}
                  value={item && item.description? item.description: ''}
                  onChange={e => setItem({
                    ...item,
                    description: e.target.value,
                  })}
                  invalid={errors && errors.description? true: false}
                  errorMessage={errors.description}
                />
                <InputKeySelect 
                  label={`Status`}
                  options={statuses}
                  value={item && item.status? item.status: ''}
                  onChange={e => setItem({
                    ...item,
                    status: e.target.value,
                  })}
                />
                <div className="d-flex flex-row justify-content-center">
                  <Button className="w-100" color="success" onClick={() => updateReminder()}>Save</Button>
                </div>
              </CardBody>
            </Card>
            {item.logs && 
            <Card>
              <CardBody>
                <TableCustom 
                  loading={loading}
                  items={item.logs}
                  columns={[
                    {
                      dataField: "name",
                      text: "Author",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.user.name
                      }
                    },
                    {
                      dataField: "name",
                      text: "Notification",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.notification && row.notification.length > 0 ? row.notification.join(' ||| '): ''
                      }
                    },
                    {
                      dataField: "created_at",
                      text: "CreatedAt",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return constants.formatDateFullGB(row.created_at)
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>}
          </Col>
        </Row>
      </Container>
    </>
  )
}