import { put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from './actions';
import axios from '../../config/axios';
import * as constants from '../../constants';

function* saveTaskPersonalisation(action) {
  try {
    const response = yield axios.post(`${constants.API_URL}/settings/tasks/${action.payload.role}`, {
      tasks: action.payload.tasks,
    });

    if (response?.status === 200) {
      const { task } = response;
      yield put({ type: actionTypes.SAVE_TASK_PERSONALISATION_SUCCESS, task });
      yield put({
        type: actionTypes.TASK_PERSONALISATION_EDIT_ROLE_REQUEST, payload: {
          role: action.payload.role
        }
      });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.SAVE_TASK_PERSONALISATION_ERROR, payload });
  }
}

function* getTaskRolesList() {
  try {
    const response = yield axios.get(`${constants.API_URL}/settings/tasks`);

    if (response?.status === 200) {
      const {
        data: { data },
      } = response;
      const { items } = data;
      yield put({ type: actionTypes.TASK_PERSONALISATION_LIST_SUCCESS, items });
    }
  } catch (error) {
    yield put({ type: actionTypes.TASK_PERSONALISATION_LIST_ERROR, error });
  }
}

function* getTaskRole({payload}) {
  try {
    const response = yield axios.get(`${constants.API_URL}/settings/tasks/${payload.role}/edit`);

    if (response?.status === 200) {
      const {
        data: { data },
      } = response;
      const { items } = data;
      yield put({ type: actionTypes.TASK_PERSONALISATION_EDIT_ROLE_SUCCESS, items });
    }
  } catch (error) {
    yield put({ type: actionTypes.TASK_PERSONALISATION_EDIT_ROLE_ERROR, error });
  }
}

export default function* watcherSaga() {
  yield takeLatest(actionTypes.SAVE_TASK_PERSONALISATION_REQUEST, saveTaskPersonalisation);
  yield takeLatest(actionTypes.TASK_PERSONALISATION_LIST_REQUEST, getTaskRolesList);
  yield takeLatest(actionTypes.TASK_PERSONALISATION_EDIT_ROLE_REQUEST, getTaskRole);
}
