const viewAny = (permissions) => {
  return permissions.includes('read-dealers')
}

const create = (permissions, roles) => {
  return roles.includes('superadministrator') || hasPermission(permissions, 'create-dealers')
}

const update = (permissions, roles, dealerDealer) => {
  return roles.includes('superadministrator') || hasPermission(permissions, 'update-dealer-dealers')
}

const deletee = (permissions, auth_user_team, dealerDealer) => {
  return !auth_user_team && hasPermission(permissions, 'delete-dealer-dealers')
}

const hasPermission = (permissions, permission) => {
  return permissions.includes(permission)
}

export {
  viewAny,
  create,
  update,
  deletee,
  hasPermission,
};