const viewAny = (permissions) => {
  return permissions.includes('read-dealer-documents')
}

const list = (permissions) => {
  return permissions.includes('read-dealer-documents')
}

const create = (permissions) => {
  return hasPermission(permissions, 'create-dealer-documents')
}

const update = (permissions, document) => {
  return hasPermission(permissions, 'update-dealer-documents')
}

const deletee = (permissions, document) => {
  return hasPermission(permissions, 'delete-dealer-documents')
}

const hasPermission = (permissions, permission) => {
  return permissions.includes(permission)
}

export {
  viewAny,
  list,
  create,
  update,
  deletee,
  hasPermission,
};