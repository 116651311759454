import * as actionTypes from './actions';
import produce from 'immer';

const initialState = {
  rols: [],
  editedRole: [],
  taskUpdatedSuccessfully: false
};


const taskState = produce((draft = initialState, action) => {
  switch (action.type) {
    case actionTypes.TASK_PERSONALISATION_LIST_ERROR:
      draft.tasks.push(action.task);
      draft.taskCreatedSuccessfully = false;
      break;
    case actionTypes.TASK_PERSONALISATION_LIST_SUCCESS:
      draft.rols = action.items;
      break;
    case actionTypes.TASK_PERSONALISATION_EDIT_ROLE_SUCCESS:
      draft.editedRole = action.items;
      break;
    case actionTypes.SAVE_TASK_PERSONALISATION_SUCCESS:
        draft.taskUpdatedSuccessfully = true;
    default:
      return draft;
  }
});

export default taskState;
