import{REQUEST_SUCCEEDED, REQUEST_SUCCESS} from 'redux/action-types.js';

export const requestSucceeded = (actionType, payload) => ({
  type: REQUEST_SUCCEEDED,
  payload: { type: actionType, data: payload },
});

export const requestSuccess = (actionType, message) => ({
  type: REQUEST_SUCCESS,
  payload: { type: actionType, message: message },
});
