import React, {useEffect, useRef, useState} from 'react'
import axios from 'axios'
import Cookies from "js-cookie"
import * as constants from 'constants/index.js'
import {
    ModalBody,
    ModalHeader,
    Modal,
} from "reactstrap"

import {useSelector} from "react-redux";
import {
    ButtonContainer, DateContainer,
    LoaderContainer,
    override, Text,
} from "../reusable-components/styled-components";
import {ClipLoader} from "react-spinners";
import ScheduleComponent from "../components/ScheduleComponent";
import moment from "moment";
import {formatDate} from "constants/index.js";
import CreateNewDelivery from "../fleet/pages/components/CreateNewDeliveryTask";
import {put} from "redux-saga/effects";
import fe from "react-datepicker";

export default function Schedule() {
    const notificationAlert = useRef(null)
    const [modal, setModal] = useState(false);
    const [showCreateNew, setShowCreateNew] = useState(false);

    const [token, setToken] = useState('');
    const [backdrop] = useState(true);
    const [keyboard] = useState(true);

    const [loading, setLoading] = useState(true);
    const [tableData, setTableData] = useState({})
    const [today, setToday] = useState({})
    const [currentMoth, setCurrentMonth] = useState('')
    const [currentYear, setCurrentYear] = useState('')
    const roles = useSelector(state => state.getState).roles;

    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const [columns, setColumns] = useState([])

    useEffect(() => {
        if (roles.length) {
            const getData = async () => {
                const current = new Date();
                await updateDate(current)
                let grs_token = Cookies.get('grs_token')
                setToken(grs_token)
            }
            getData()
        }
    }, [roles])
    const updateDate = async (current) => {
        setCurrentMonth(monthNames[current.getMonth()])
        setCurrentYear(current.getFullYear())
        const date = dates(current)
        setColumns(date)
        const tempData = {
            from: date[0].currentDate,
            to: date.at(-1).currentDate,
        }
        setLoading(true)
        await fetchData(token, tempData)
    }
    const nextWeek = async () => {
        const lastDay = new Date(columns.at(-1).currentDate)
        const tomorrow = new Date(lastDay + 1)
        await updateDate(tomorrow)
    }

    const previousWeek = async () => {
        const firstDay = new Date(columns[0].currentDate)
        let today = new Date()
        today = new Date(formatDate(today))
        const diffTime = Math.abs(firstDay - today);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (diffDays > 6) {
            let yesterday = new Date()
            yesterday.setDate(firstDay.getDate() - 7)
            await updateDate(yesterday)
        } else if (diffDays) {
            await updateDate(today)
        }

    }
    const toggle = () => setModal(!modal);

    const fetchData = (token, data = {from: '', to: ''},) => {
        axios({
            method: 'GET',
            url: `${constants.FLEET_URL}/job-booker/get-schedule`,
            headers: {
                Authorization: 'Bearer ' + token
            },
            params: data
        })
            .then(response => {
                let objectOfNames = {}
                let tempData = response.data.data.data
                tempData.map(item => {
                    if (!objectOfNames.hasOwnProperty(item.driver_id)) {
                        objectOfNames[item.driver_id] = []
                    }
                })
                Object.keys(objectOfNames).map(key => {
                    objectOfNames[key] = tempData.filter(item => {
                        return item.driver_id.toString() === key.toString()
                    })
                })
                setTableData(objectOfNames)
                setLoading(false)
            })
            .catch(error => {

            })
    }

    function dates(current) {
        let tempDates = []
        for (let i = 0; i < 7; i++) {
            const curDay = formatDate(current)
            tempDates.push({
                day: curDay.split('-')[2],
                weekDay: days[new Date(curDay).getDay()],
                currentDate: curDay,
            })
            current.setDate(current.getDate() + 1);
        }
        return tempDates;
    }

    const updateTask = async (task) => {
        try {
            const source = axios.CancelToken.source()

            const response = await axios({
                method: 'POST',
                url: `${constants.FLEET_URL}/job-booker/set-delivery-task`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: task,
                cancelToken: source.token,
            })
            const data = response.data

        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled :: ', error)
            } else {
                console.error('error :: ', error)
            }
        }
    }
    const deleteDelivery = async (id) => {
              try {
            const response = await axios({
                method: 'DELETE',
                url: `${constants.FLEET_URL}/job-booker/delete-delivery/${id}`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            const data = response.data
                window.location.reload(true);
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled :: ', error)
            } else {
                console.error('error :: ', error)
            }
        }
    }

    const updateGrsCollectionDate = async (task) => {
              try {
            const source = axios.CancelToken.source()

            const response = await axios({
                method: 'POST',
                url: `${constants.FLEET_URL}/job-booker/set-grs-delivery-date`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: task,
                cancelToken: source.token,
            })
            const data = response.data

        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled :: ', error)
            } else {
                console.error('error :: ', error)
            }
        }
    }

    return (
        <>
            <Modal
                isOpen={modal}
                toggle={toggle}
                backdrop={backdrop}
                keyboard={keyboard}
            >
                <ModalHeader toggle={toggle}>Please select the type of document</ModalHeader>
                <ModalBody>

                </ModalBody>
            </Modal>

            {loading ?
                <LoaderContainer>
                    <ClipLoader
                        css={override}
                        size={40}
                        color={`#7B61E4`}
                        loading={loading}
                    />
                </LoaderContainer> : <>
                    {
                        showCreateNew ? <CreateNewDelivery updateData={() => fetchData(token)}
                                                           setShowCreateNew={setShowCreateNew}
                                                           setLoading={setLoading}/> :
                            <>
                                <div className={'d-flex justify-content-between mt-3'}>
                                    <DateContainer>
                                        <i onClick={previousWeek} className='fa fa-angle-left cursor-pointer'/>
                                        <i onClick={nextWeek} className='fa fa-angle-right cursor-pointer'/>
                                        <Text bold>{currentMoth}, </Text>
                                        <Text bold>{currentYear}</Text>
                                    </DateContainer>
                                    <ButtonContainer onClick={() => setShowCreateNew(true)}>
                                        <i className="fa fa-plus" aria-hidden="true"></i>
                                        New Task
                                    </ButtonContainer>
                                </div>
                                <ScheduleComponent updateTask={updateTask}
                                                   deleteDelivery={deleteDelivery}
                                                   updateGrsCollectionDate={updateGrsCollectionDate}
                                                   columns={columns} tableData={tableData}/>
                            </>


                    }
                </>
            }
        </>
    )
}