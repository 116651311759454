import React, { useRef, useState, useEffect } from 'react';
import { getKashflowCustomer, saveKashflowCustomer, syncKashflowCustomer } from 'api/modules.api';
import NotificationAlert from "react-notification-alert";
import XeroPaymentSettings from './form/XeroPaymentSettings';
import {
    Row,
    Col,
    Button
} from "reactstrap";

export default function XeroDetail({ deal }) {
    const [saveButtonDisable, setSaveButtonDisable] = useState(false);
    const [customer, setCustomer] = useState({});
    const [customerCode, setCustomerCode] = useState('');
    const [kashflowCustomer, setKashflowCustomer] = useState(false);
    const notificationAlert = useRef(null)

    const notify = (type, header, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
                    <span className="alert-title" data-notify="title">
                        {" "}
                        {header}
                    </span>
                    <span data-notify="message">
                        <div dangerouslySetInnerHTML={{ __html: message }} />
                    </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5
        };
        notificationAlert.current.notificationAlert(options);
    }

    useEffect(() => {
        if (deal.customer_id) {
            fetchKashflowCustomer(deal.customer_id)
        }
    }, [deal.customer_id]);

    const createCustomer = () => {
        saveKashflowCustomer(deal.customer_id).then(response => {
            fetchKashflowCustomer(deal.customer_id);
            notify('success', 'Success', response.message);
        })
    }

    const syncCustomer = () => {
        syncKashflowCustomer(deal.customer_id, customerCode).then(response => {
            fetchKashflowCustomer(deal.customer_id);
            notify('success', 'Success', response.message);
        }).catch((error) => {
            notify('error', 'Error', error.response.data.message);
            console.log(error.response.data.message);
        });
    }

    const fetchKashflowCustomer = (customerId) => {
        getKashflowCustomer(customerId).then(response => {
            setKashflowCustomer(response.data.kashflowCustomer)
            setCustomer(response.data.systemCustomer)
        })
    }

    return (
        <Row>
            <Col xl="12">
                <div className="rna-wrapper">
                    <NotificationAlert ref={notificationAlert} />
                </div>
            </Col>
            <Col>
                <XeroPaymentSettings deal={deal} />
            </Col>
        </Row>)
}