import axios from '../config/axios';

export const getDocument = (uuid, documentName = null) => {
  return axios({
    method: 'GET',
    url: `/upload/${uuid}`,
    responseType: 'blob'
  })
    .then(response => {
      let data = response.data;
      let disposition = response.headers['content-disposition'];
      let filename = documentName || null;

      if (!filename && disposition && disposition.indexOf('attachment') !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
      }

      if(filename) {
        const url = window.URL.createObjectURL(
          new Blob([data]),
        );
  
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          filename,
        );
  
        // Append to html link element page
        document.body.appendChild(link);
  
        // Start download
        link.click();
  
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      }
    });
}

export const deleteDocument = (uuid) => {
  return axios({
    method: 'DELETE',
    url: `/upload/${uuid}`
  })
    .then(response => response.data);
}

export const uploadDocuments = (documents) => {
  const formData = new FormData();

  for (let index = 0; index < documents.length; index++) {
    const element = documents[index]
    formData.append(`file[${index}]`, element, element.name)
  }

  return axios({
    method: 'POST',
    url: `/upload`,
    headers: {
      'content-type': 'multipart/form-data',
    },
    data: formData
  })
    .then(response => response.data);
}
