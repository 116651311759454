import React, { useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import NotificationAlert from 'react-notification-alert';
import { useHistory } from 'react-router-dom';

import CardsHeader from 'layouts/Headers/CardsHeader.js';

import TableCustom from 'views/pages/components/TableCustom';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import {
  update,
  deletee,
  hasPermission,
} from 'views/policies/EmailTemplatePolicy';
import useNotify from '../../../../hooks/notify';
import * as emailTemplateActions from '../../../../redux/Email/actions';
import usePrevious from '../../../../hooks/useprevious';
import { ClipLoader } from 'react-spinners';
import { css } from '@emotion/core';
import {ButtonContainer} from "@pages/reusable-components/styled-components";

const override = css`
  margin-right: 10px;
`;

export default function Emails() {
  // const notificationAlert = useRef(null);
  const history = useHistory();
  const { notify } = useNotify();

  const permissions = useSelector((state) => state.getState).permissions;

  /*
   * redux variables
   * */
  const emailTemplateState = useSelector(
    (state) => state.emailTemplateState,
    shallowEqual
  );
  const dispatch = useDispatch();

  const getTemplatesSuccessPrev = usePrevious(
    emailTemplateState.getTemplatesSuccess
  );

  const deleteTemplateSuccessPrev = usePrevious(
    emailTemplateState.deleteTemplateSuccess
  );

  const [loading, setLoading] = useState(true);

  const [deleteModal, setDeleteModal] = useState({
    show: false,
    header: '',
    body: '',
    onClick: null,
  });

  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    dispatch({
      type: emailTemplateActions.GET_EMAIL_TEMPLATES_REQUEST,
    });
  }, []);

  useEffect(() => {
    if (
      emailTemplateState.getTemplatesSuccess !== getTemplatesSuccessPrev &&
      emailTemplateState.getTemplatesSuccess === true
    ) {
      // setEmailTemplates(emailTemplateState.templates);
      setLoading(false);
    }
  }, [emailTemplateState.getTemplatesSuccess]);

  useEffect(() => {
    if (
      emailTemplateState.deleteTemplateSuccess !== deleteTemplateSuccessPrev &&
      emailTemplateState.deleteTemplateSuccess === true
    ) {
      setDeleteModal({ ...deleteModal, show: false });
      setDeleteLoading(false);
    }
  }, [emailTemplateState.deleteTemplateSuccess]);

  function deleteEmailTemplate(id) {
    setDeleteLoading(true);

    dispatch({
      type: emailTemplateActions.DELETE_EMAIL_TEMPLATE_REQUEST,
      id: id,
    });
  }

  function updateTemplate(id) {
    console.log("AICI")
    console.log(id)
    history.push(`/admin/settings/email-templates/${id}/edit`);
  }

  return (
    <>
      <CardsHeader
        name="Settings"
        parentName="Email Templates"
        currentName="List"
      />
      <div className="rna-wrapper">
        {/*<NotificationAlert ref={notificationAlert} />*/}
      </div>
      <Container className="mt--5 admin-main-body" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Emails</h1>
                    <p className="text-sm mb-0">
                      You can manage Email templates.
                    </p>
                  </Col>
                  <Col xs={4} className="text-right">
                    <ButtonContainer
                      reverse
                      onClick={() =>
                        history.push('/admin/settings/email-templates/create')
                      }
                    >
                      Add email template
                    </ButtonContainer>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <TableCustom
                  loading={loading}
                  keyField={`id`}
                  searchField={false}
                  items={emailTemplateState.templates}
                  columns={[
                    {
                      dataField: 'name',
                      text: 'Name',
                      sort: true,
                    },
                    {
                      dataField: '',
                      text: 'Action',
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            {(hasPermission(
                              permissions,
                              'update-email-templates'
                            ) ||
                              hasPermission(
                                permissions,
                                'delete-email-templates'
                              )) &&
                                  update(permissions, row) && (
                                      <img className={'cursor-pointer'}   onClick={() => updateTemplate(row.id)}
                                           src={require(`assets/images/tableIcons/edit.svg`)} alt=""/>

                                  )}
                                  {deletee(permissions, row) && (
                                      <img className={'cursor-pointer'}    onClick={() =>
                                          setDeleteModal({
                                            ...deleteModal,
                                            show: true,
                                            header: 'Delete email template',
                                            body:
                                                'Are you sure you want to delete this email template?',
                                            onClick: () =>
                                                deleteEmailTemplate(row.id),
                                          })
                                      }
                                           src={require(`assets/images/tableIcons/delete_outline.svg`)} alt=""/>

                                  )}

                          </>
                        );
                      },
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={deleteModal.show}
          toggle={() =>
            setDeleteModal({
              ...deleteModal,
              show: false,
            })
          }
          className="modal-dialog-centered"
        >
          <div className="modal-header  main-color">
            <h6 className="modal-title">{deleteModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() =>
                setDeleteModal({
                  ...deleteModal,
                  show: false,
                })
              }
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {`Are you sure you want to delete this email template?`}
          </div>
          <div className="modal-footer">
            <ButtonContainer
                onClick={() =>
                    setDeleteModal({
                      ...deleteModal,
                      show: false,
                    })
                }
            >
              {`Close`}
            </ButtonContainer>
            <ButtonContainer
              onClick={()=> !deleteLoading && deleteModal.onClick()}
              reverse
              disabled={deleteLoading}
            >
              <ClipLoader
                css={override}
                size={14}
                color={`#ffffff`}
                loading={deleteLoading}
              />
              Delete
            </ButtonContainer>

          </div>
        </Modal>
      </Container>
    </>
  );
}
