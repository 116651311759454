import { useState, useEffect } from 'react';
import { Col, FormGroup, Modal, Row } from 'reactstrap';
import InputCustom from '../components/InputCustom';
import { InputKeySelect } from '../components/InputSelect';
import Select from 'react-select';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { shallowEqual, useSelector } from 'react-redux';
import NotificationAlert from 'react-notification-alert';
import { formatDateFullGB } from '../../../constants';
import HistoryAndComments from './HistoryAndComments';
import useNotify from '../../../hooks/notify';

function Details({
  tasks,
  taskId,
  isVisible,
  header,
  toggleModal,
  titleEditMode,
  setEditMode,
  descriptionEditMode,
  statuses,
  customDealerMembers,
  inputDescriptionEl,
  inputTitleEl,
  updateTask,
  taskEdit,
}) {
  const {
    isUpdateTaskError,
    updateTaskErrorMessage,
    createCommentError,
    createCommentErrorMessage,
    deleteCommentError,
    deleteCommentErrorMessage,
    updateCommentError,
    updateCommentErrorMessage,
  } = useSelector((state) => state.taskState, shallowEqual);

  const [task, setTask] = useState({});
  const [title, setTitle] = useState('');
  const [comment, setComment] = useState('');
  const { ref, notify } = useNotify();

  const selectValue = task.task_assignee
    ? task.task_assignee.map((item) => ({ value: item.id, label: item.name }))
    : [];

  useEffect(() => {
    const selectedTask = tasks.find(({ id }) => id === parseInt(taskId));
    setTask(selectedTask);
  }, [taskId, tasks]);
  useEffect(() => {
    setTitle(task && task.title ? task.title : '');
    setComment(task && task.comments ? task.comments : '');
  }, [task]);

  useEffect(() => {
    if (isUpdateTaskError) {
      notify(updateTaskErrorMessage, 'Warning', 'warning');
    }
  }, [isUpdateTaskError]);

  useEffect(() => {
    if (createCommentError) {
      notify(createCommentErrorMessage, 'Warning', 'warning');
    }
  }, [createCommentError]);

  useEffect(() => {
    if (deleteCommentError) {
      notify(deleteCommentErrorMessage, 'Warning', 'warning');
    }
  }, [deleteCommentError]);

  useEffect(() => {
    if (updateCommentError) {
      notify(updateCommentErrorMessage, 'Warning', 'warning');
    }
  }, [updateCommentError]);

  return (
    <Modal
      isOpen={isVisible}
      toggle={() => toggleModal(false)}
      className="modal-dialog-centered"
      size="xl"
    >
      <div className="modal-header">
        <h6 className="modal-title">{header}</h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => toggleModal(false)}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Col>
          <div className="rna-wrapper">
            <NotificationAlert ref={ref} />
          </div>
          <Col >
            {!titleEditMode ? (
                <h2
                    onClick={() => setEditMode('title', true)}
                    className="grey-hover px-2"
                >
                  {title}
                </h2>
            ) : (
                <InputCustom
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    onBlur={(e) => {
                      setEditMode('title', false);
                      updateTask({
                        id: task.id,
                        title: e.target.value,
                      });
                    }}
                    innerRef={inputTitleEl}
                />
            )}

            <div>
              <div className="px-2">Description</div>
              <div>
                {!descriptionEditMode ? (
                    <h5
                        onClick={() => setEditMode('description', true)}
                        className="grey-hover px-2"
                    >
                      {comment}
                    </h5>
                ) : (
                    <InputCustom
                        type={`textarea`}
                        rows={5}
                        value={comment}
                        onBlur={(e) => {
                          setEditMode('description', false);
                          updateTask({
                            id: task.id,
                            comments: e.target.value,
                          });
                        }}
                        onChange={(e) => setComment(e.target.value)}
                        innerRef={inputDescriptionEl}
                    />
                )}
              </div>
            </div>

            <HistoryAndComments task={task} />
          </Col>
          <Col>
            <InputKeySelect
                newLabel={`Status`}
                value={task && task.status ? task.status : ''}
                options={statuses}
                onChange={(e) => {
                  updateTask({
                    id: task.id,
                    status: e.target.value,
                  });
                }}
            />
            <Row className={'mb-4'}>
              <Col md={4}>
                <label className="form-control-label">Assign to</label>
              </Col>
              <Col className={'pl-0'} md={8}>
                <Select
                    isMulti
                    defaultValue={selectValue}
                    options={customDealerMembers}
                    onChange={(e) => {
                      updateTask({
                        id: task.id,
                        assignee: e ? Array.from(e, (option) => option.value) : [],
                      });
                    }}
                />
              </Col>
            </Row>
            <InputCustom
              type={`date`}
              newLabel={`Due`}
              value={
                task.due_date
                  ? moment.utc(task.due_date).format('YYYY-MM-DD')
                  : ''
              }
              onChange={(e) =>
                updateTask({
                  id: task.id,
                  due_date: e.target.value,
                })
              }
            />
            <div>
              <a
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  taskEdit(task);
                }}
              >
                Edit Page
              </a>
            </div>
            <div>
              <div>Created {formatDateFullGB(task.created_at)}</div>
              <div>Updated {formatDateFullGB(task.updated_at)}</div>
            </div>
          </Col>

        </Col>
      </div>
    </Modal>
  );
}
export default Details;

Details.propTypes = {
  tasks: PropTypes.array,
  customDealerMembers: PropTypes.array,
  descriptionEditMode: PropTypes.bool,
  header: PropTypes.string,
  isVisible: PropTypes.bool,
  setEditMode: PropTypes.func,
  statuses: PropTypes.object,
  taskId: PropTypes.string,
  titleEditMode: PropTypes.bool,
  toggleModal: PropTypes.func,
  updateTask: PropTypes.func,
  inputDescriptionEl: PropTypes.object,
  inputTitleEl: PropTypes.object,
  taskEdit: PropTypes.func,
};
