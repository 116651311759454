import React, { useContext, useState } from 'react'
import { LiveVehicleContext } from "./context";

import InputCustom from 'views/pages/components/InputCustom'
import {
  InputArraySelect, InputKeySelect, SelectWithoutInput
} from 'views/pages/components/InputSelect'
import TableCustom from 'views/pages/components/TableCustom'
import * as constants from 'constants/index.js';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
} from 'reactstrap'

import { hasPermission } from 'views/policies/FleetPolicy';
import ChangeHandler from './ChangeHandler';
import CustomSearch from "@pages/components/CustomSearch";
import {parseToKey, parseToWord} from "constants/index.js";
import {ButtonContainer} from "@pages/reusable-components/styled-components";

export default function VehicleInStock() {
  const vehicleContext = useContext(LiveVehicleContext);
  const {
    notify,
    type,
    permissions,
    history,
    inputStyle, formGroupStyle,
    token, setToken,
    loading, setLoading,
    items, setItems,
    confirmShow, setConfirmShow,
    deliveryStatuses, setDeliveryStatuses,
    fundingMethods, fundingStatuses,
    vehicleStatuses, setVehicleStatuses,
    dealStatuses, setDealStatuses,
    soldTypes,
    filter, setFilter,
    pagination, setPagination,
    pageNumber, setPageNumber,
    editedItem, setEditedItem,
    editing, setEditing,
    updateData,
    updateDataChanges,
    fetchData,
    filterVehicle,
    resetVehicle,
    addVechile,
    editItemProcess,
    cleanEditItem,
    removeItem,
    submitItem,
    updateItemsValue,
    soldProcessTypes,
    goToVehicle
  } = vehicleContext;

  const [advanceSearch, setAdvanceSearch] = useState(false);
  return (
    <>

      <Row>
        <Col xl="12">
          <ChangeHandler notify={notify} updateData={(vehicle) => updateDataChanges(vehicle)} />
        </Col>
         <Col xl="12">
          <Card>
            <CardHeader>
              <Row>
                <Col xs={8}>
                  <h1 className="mb-0">Master GRS Vehicles</h1>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Container className={'ml-0'}>
                <Row className="m-2 justify-content-md-center">
                  <Col md={4} sm={12} xs={12}>
                    <CustomSearch
                        onChange={e => setFilter({
                          ...filter,
                          reg_no: e.target.value
                        })}
                        placeholder={'Search'}
                    />
                  </Col>
                  <Col md={4} sm={12}>
                    <SelectWithoutInput
                        label={filter && filter.funding_method? parseToWord(filter.funding_method) : `Deal Type`}
                        onSelect={(e) => {
                          setFilter({
                            ...filter,
                            funding_method: parseToKey(e)
                          })
                        }}
                        options={Object.values(fundingMethods)}
                        defaultOption={true}
                        imageLink={'loyalty.png'}
                    />
                  </Col>
                  <Col md={4} sm={12}>
                    <SelectWithoutInput
                        label={filter && filter.vehicle_status? parseToWord(filter.vehicle_status) : `Vehicle Status`}
                        onSelect={(e) => {
                          setFilter({
                            ...filter,
                            vehicle_status: parseToKey(e)
                          })
                        }}
                        options={Object.values(vehicleStatuses)}
                        defaultOption={true}
                        imageLink={'directions_car_grey.png'}
                    />
                  </Col>
                </Row>
                <Row className="m-2 justify-content-md-start">
                  <Col md={4} sm={12}>
                    <SelectWithoutInput
                        label={filter && filter.sold_process_type? parseToWord(filter.sold_process_type) : `Sold status`}
                        onSelect={(e) => {
                          setFilter({
                            ...filter,
                            sold_process_type: parseToKey(e)
                          })
                        }}
                        options={Object.values(soldProcessTypes)}
                        defaultOption={true}
                        imageLink={'loyalty.png'}
                    />
                  </Col>
                  <Col md={4} sm={12}>
                    <SelectWithoutInput
                        label={filter && filter.sold_type? parseToWord(filter.sold_type) : `Sold Type`}
                        onSelect={(e) => {
                          setFilter({
                            ...filter,
                            sold_type: parseToKey(e)
                          })
                        }}
                        options={Object.values(soldTypes)}
                        defaultOption={true}
                        imageLink={'loyalty.png'}
                    />
                  </Col>
                  <Col md={4} sm={12}>
                    <SelectWithoutInput
                        label={filter && filter.deal_status? parseToWord(filter.deal_status) : `Deal status`}
                        onSelect={(e) => {
                          setFilter({
                            ...filter,
                            deal_status: Object.keys(dealStatuses).find(key => dealStatuses[key] === e)
                          })
                        }}
                        options={Object.values(dealStatuses)}
                        defaultOption={true}
                        imageLink={'loyalty.png'}
                    />
                  </Col>
                </Row>
                <Row className={'justify-content-end mt-5'}>
                    <ButtonContainer  onClick={() => resetVehicle()}>Clear</ButtonContainer>
                    <ButtonContainer reverse onClick={() => filterVehicle()}>Apply</ButtonContainer>
                </Row>
              </Container>
              {items.length > 0 && <Row>
                <Col xs={8}>
                  <h2 className="mb-0">Number of vehicles: {items.length}</h2>
                </Col>
              </Row>
              }
              <TableCustom
                loading={loading}
                items={items}
                classes={'custom-table-striped'}
                pagination={pagination}
                onChange={pageNumber => {
                  setPageNumber(pageNumber)
                  fetchData(pageNumber, token, filter)
                }
                }
                columns={[
                  {
                    dataField: "stock_no",
                    text: "Stock No",
                    style: {
                      "fontWeight": 800
                    },
                    formatter: (rowContent, row) => {
                      return `${row.stock_no}`
                    }
                  },
                  {
                    dataField: "vehicle",
                    text: "Vehicle",
                    formatter: (rowContent, row) => {
                      let vehicle = `${row.vehicle}, ${row.derivative}`;
                      return <a href={`/admin/vehicle/${row.vehicle_id}/edit`} target='_blank'>{vehicle.length > 35 ? `${vehicle.substring(0, 35)}...` : vehicle}</a>;
                    }
                  },
                  {
                    dataField: "deal_id",
                    text: "Deal",
                    formatter: (rowContent, row) => {
                      return row.deal_id ? <a className="text-center" href={`/admin/deals/${row.deal_id}/edit`} target='_blank'>#{row.deal_id}</a> : '-';
                    }
                  },
                  {
                    dataField: "stock_no",
                    text: "Color",
                    formatter: (rowContent, row) => {
                      return `${row.colour}`
                    }
                  },
                  {
                    dataField: "mileage",
                    text: "Mileage",
                    formatter: (rowContent, row) => {
                      return (
                        <InputCustom
                          newLabel={false}
                          value={row.current_mileage ?? ''}
                          style={inputStyle(100)}
                          formGroupStyle={formGroupStyle()}
                          onChange={(e) => {
                            updateItemsValue(row, 'current_mileage', e.target.value)
                          }}
                        />
                      )
                    }
                  },
                  {
                    dataField: "sold_type",
                    text: "Deal type",
                    formatter: (rowContent, row) => {
                      return (
                        <InputKeySelect
                            newLabel={false}
                          options={fundingMethods}
                          value={row.funding_method ?? row.deal_type ?? ''}
                          formGroupStyle={formGroupStyle()}
                          style={inputStyle(100)}
                          onChange={(e) => {
                            updateItemsValue(row, 'funding_method', e.target.value)
                          }}
                          defaultOption={true}
                        // invalid={errors && errors.job_category ? true : false}
                        // errorMessage={errors.job_category}
                        />
                      )
                    }
                  },
                  {
                    dataField: "deal_status",
                    text: "Deal Status",
                    formatter: (rowContent, row) => {
                      return (
                        `${row.sold_process}`
                      )
                    }
                  },
                  {
                    dataField: "vehicle_status",
                    text: "Vehicle Status",
                    formatter: (rowContent, row) => {
                      let jobsInProgress = Number(row.in_progress_jobs);
                      let totalJobs = Number(row.total_jobs);
                      let label = jobsInProgress > 0 ? `Needs work` : (totalJobs > 0 ? 'Completed' : "No work recorded");
                      return <a href={`/admin/vehicle-details?id=${row.fleet_id}`} target='_blank'>{label}</a>;
                    }
                  },
                  {
                    dataField: "",
                    text: "Action",
                    formatter: (rowContent, row) => {
                      return (
                        <div className="flex-no-wrap">
                          <ButtonContainer onClick={() => {
                            history.push({
                              pathname: `/admin/live-vehicle/${row.live_id}/edit`
                            })
                          }}>View</ButtonContainer>
                        </div>
                      )
                    }
                  }
                ]}
              />
              {!loading && items && items.length > 0 && <Row>
                <Col sm={12}>
                  <ButtonContainer reverse type="button" onClick={() => submitItem()}>Save</ButtonContainer>
                </Col>
              </Row>}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal
        isOpen={confirmShow}
        toggle={() => setConfirmShow(false)}
        className="modal-dialog-centered modal-secondary"
      >
        <div className="modal-body">
          Are you sure you want to remove this vehicle from the list?
        </div>
        <div className="modal-footer justify-content-end">
          <ButtonContainer
              onClick={() => setConfirmShow(false)}
          >
            Cancel
          </ButtonContainer>
          <ButtonContainer
            reverse
            onClick={() => removeItem()}
          >
            Delete permanently
          </ButtonContainer>

        </div>
      </Modal>
    </>
  )
}