import React, { useContext } from 'react';

import { DealersContext } from '../context';

import * as constants from 'constants/index.js';

import InputSideCustom from 'views/pages/components/InputSideCustom';
import InputFile from 'views/pages/components/InputFile';
import {
  InputArraySideSelect,
} from 'views/pages/components/InputSelect';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Label,
} from "reactstrap";

export default function ProfileTab() {
  const dealersContext = useContext(DealersContext);
  const {
    handleChange,
    dealerProfile,
    setDealerProfile,
    countries,
    counties,
    updateProfile,
    formatDate,
    profileErrors,
  } = dealersContext;
  return (
    <Card>
      <CardBody>
        <InputFile
          label={`Company Logo`}
          placeholder={`Select file`}
          accept="image/*"
          onChange={(e) => handleChange(e.target.files)}
          invalid={profileErrors && profileErrors.logo ? true : false}
          errorMessage={profileErrors.logo}
        />
        {dealerProfile.logo && <Row>
          <div className="col-lg-4 order-lg-2 mb-2">
            <div className="dealer-profile-image-edit-section">
              <img src={constants.STORAGE_URL + dealerProfile.logo} className="img-thumbnail" />
            </div>
          </div>
        </Row>}
        <Card>
          <CardHeader className="text-center pb-0 pt-2" style={{ backgroundColor: '#CED4DA' }}>
            <h2 className="title" style={{ color: '#000000' }}>Personal Details</h2>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md={6} sm={12}>
                <InputSideCustom
                  label={`Name`}
                  value={dealerProfile && dealerProfile.contact_person ? dealerProfile.contact_person : ''}
                  onChange={(e) => setDealerProfile({
                    ...dealerProfile,
                    contact_person: e.target.value
                  })}
                  invalid={profileErrors && profileErrors.contact_person ? true : false}
                  errorMessage={profileErrors.contact_person}
                />
                <InputArraySideSelect
                  label={`Country of Birth`}
                  options={countries}
                  value={dealerProfile.country_of_birth_id ? dealerProfile.country_of_birth_id : ''}
                  onChange={(e) => setDealerProfile({
                    ...dealerProfile,
                    country_of_birth_id: e.target.value
                  })}
                  defaultOption={true}
                  valueKey={`id`}
                  labelKey={`name`}
                  invalid={profileErrors && profileErrors.country_of_birth_id ? true : false}
                  errorMessage={profileErrors.country_of_birth_id}
                />
                <InputSideCustom
                  label={`Mobile No`}
                  value={dealerProfile.phone_number ? dealerProfile.phone_number : ''}
                  onChange={(e) => setDealerProfile({
                    ...dealerProfile,
                    phone_number: e.target.value
                  })}
                  invalid={profileErrors && profileErrors.phone_number ? true : false}
                  errorMessage={profileErrors.phone_number}
                />
              </Col>
              <Col md={6} sm={12}>
                <InputSideCustom
                  type={`date`}
                  label={`Date of Birth`}
                  value={dealerProfile.date_of_birth ? dealerProfile.date_of_birth : undefined}
                  onChange={(e) => setDealerProfile({
                    ...dealerProfile,
                    date_of_birth: e.target.value
                  })}
                  invalid={profileErrors && profileErrors.date_of_birth ? true : false}
                  errorMessage={profileErrors.date_of_birth}
                />
                <InputSideCustom
                  label={`Place of Birth`}
                  value={dealerProfile.place_of_birth ? dealerProfile.place_of_birth : ''}
                  onChange={(e) => setDealerProfile({
                    ...dealerProfile,
                    place_of_birth: e.target.value
                  })}
                  invalid={profileErrors && profileErrors.place_of_birth ? true : false}
                  errorMessage={profileErrors.place_of_birth}
                />
                <InputSideCustom
                  label={`License No`}
                  value={dealerProfile.license_no ? dealerProfile.license_no : ''}
                  onChange={(e) => setDealerProfile({
                    ...dealerProfile,
                    license_no: e.target.value
                  })}
                  invalid={profileErrors && profileErrors.license_no ? true : false}
                  errorMessage={profileErrors.license_no}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardHeader className="text-center pb-0 pt-2" style={{ backgroundColor: '#CED4DA' }}>
            <h2 className="title" style={{ color: '#000000' }}>Admin Details</h2>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md={6} sm={12}>
                <InputSideCustom
                  label={`Dealer information`}
                  value={dealerProfile.name ? dealerProfile.name : ''}
                  onChange={(e) => setDealerProfile({
                    ...dealerProfile,
                    name: e.target.value
                  })}
                  invalid={profileErrors && profileErrors.name ? true : false}
                  errorMessage={profileErrors.name}
                />
                <InputSideCustom
                  label={`Business Name`}
                  value={dealerProfile.business_name ? dealerProfile.business_name : ''}
                  onChange={(e) => setDealerProfile({
                    ...dealerProfile,
                    business_name: e.target.value
                  })}
                  invalid={profileErrors && profileErrors.business_name ? true : false}
                  errorMessage={profileErrors.business_name}
                />
              </Col>
              <Col md={6} sm={12}>
                <InputSideCustom
                  label={`Company Ref Number`}
                  value={dealerProfile.company_ref_number ? dealerProfile.company_ref_number : ''}
                  onChange={(e) => setDealerProfile({
                    ...dealerProfile,
                    company_ref_number: e.target.value
                  })}
                  invalid={profileErrors && profileErrors.company_ref_number ? true : false}
                  errorMessage={profileErrors.company_ref_number}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardHeader className="text-center pb-0 pt-2" style={{ backgroundColor: '#CED4DA' }}>
            <h2 className="title" style={{ color: '#000000' }}>Office Details</h2>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md={6} sm={12}>
                <InputSideCustom
                  label={`Office Name`}
                  value={dealerProfile.office_name ? dealerProfile.office_name : ''}
                  onChange={(e) => setDealerProfile({
                    ...dealerProfile,
                    office_name: e.target.value
                  })}
                  invalid={profileErrors && profileErrors.office_name ? true : false}
                  errorMessage={profileErrors.office_name}
                />
                <InputSideCustom
                  label={`City`}
                  value={dealerProfile.office_city ? dealerProfile.office_city : ''}
                  onChange={(e) => setDealerProfile({
                    ...dealerProfile,
                    office_city: e.target.value
                  })}
                  invalid={profileErrors && profileErrors.office_city ? true : false}
                  errorMessage={profileErrors.office_city}
                />
                <InputSideCustom
                  label={`Location Map Url`}
                  value={dealerProfile.location_map_url ? dealerProfile.location_map_url : ''}
                  onChange={(e) => setDealerProfile({
                    ...dealerProfile,
                    location_map_url: e.target.value
                  })}
                  invalid={profileErrors && profileErrors.location_map_url ? true : false}
                  errorMessage={profileErrors.location_map_url}
                />
                <Row className="mt-4">
                  <Label
                    className="form-control-label"
                    htmlFor="example-text-input"
                    md="5"
                  >
                    Is Public Listed
                    </Label>
                  <Col md="7">
                    <label className="custom-toggle mr-1 mt-2">
                      <input
                        onChange={() =>
                          setDealerProfile({
                            ...dealerProfile,
                            is_public_listed: dealerProfile.is_public_listed == 1 ? 0 : 1
                          })
                        }
                        checked={dealerProfile.is_public_listed == 1 ? true : false}
                        type="checkbox"
                      />
                      <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off="No"
                        data-label-on="Yes"
                      />
                    </label>
                  </Col>
                </Row>
              </Col>
              <Col md={6} sm={12}>
                <InputSideCustom
                  label={`Address`}
                  value={dealerProfile.address ? dealerProfile.address : ''}
                  onChange={(e) => setDealerProfile({
                    ...dealerProfile,
                    address: e.target.value
                  })}
                  invalid={profileErrors && profileErrors.address ? true : false}
                  errorMessage={profileErrors.address}
                />
                <InputSideCustom
                  label={`Post Code`}
                  value={dealerProfile.office_post_code ? dealerProfile.office_post_code : ''}
                  onChange={(e) => setDealerProfile({
                    ...dealerProfile,
                    office_post_code: e.target.value
                  })}
                  invalid={profileErrors && profileErrors.office_post_code ? true : false}
                  errorMessage={profileErrors.office_post_code}
                />
                <InputArraySideSelect 
                  label={`State`}
                  options={counties}
                  value={dealerProfile.county_id ? dealerProfile.county_id : ''}
                  onChange={(e) => setDealerProfile({
                    ...dealerProfile,
                    county_id: e.target.value
                  })}
                  valueKey={`id`}
                  labelKey={`name`}
                  defaultOption={true}
                  invalid={profileErrors && profileErrors.county_id ? true : false}
                  errorMessage={profileErrors.county_id}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardHeader className="text-center pb-0 pt-2" style={{ backgroundColor: '#CED4DA' }}>
            <h2 className="title" style={{ color: '#000000' }}>Financial Information</h2>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md={6} sm={12}>
                <InputSideCustom
                  label={`VAT Reg`}
                  value={dealerProfile.fiscal_code ? dealerProfile.fiscal_code : ''}
                  onChange={(e) => setDealerProfile({
                    ...dealerProfile,
                    fiscal_code: e.target.value
                  })}
                  invalid={profileErrors && profileErrors.fiscal_code ? true : false}
                  errorMessage={profileErrors.fiscal_code}
                />
                <InputSideCustom
                  label={`Sort code`}
                  value={dealerProfile.bank_sort_code ? dealerProfile.bank_sort_code : ''}
                  onChange={(e) => setDealerProfile({
                    ...dealerProfile,
                    bank_sort_code: e.target.value
                  })}
                  invalid={profileErrors && profileErrors.bank_sort_code ? true : false}
                  errorMessage={profileErrors.bank_sort_code}
                />
                <InputSideCustom
                  label={`Account name`}
                  value={dealerProfile.account_name ? dealerProfile.account_name : ''}
                  onChange={(e) => setDealerProfile({
                    ...dealerProfile,
                    account_name: e.target.value
                  })}
                  invalid={profileErrors && profileErrors.account_name ? true : false}
                  errorMessage={profileErrors.account_name}
                />
              </Col>
              <Col md={6} sm={12}>
                <InputSideCustom
                  label={`Bank`}
                  value={dealerProfile.bank ? dealerProfile.bank : ''}
                  onChange={(e) => setDealerProfile({
                    ...dealerProfile,
                    bank: e.target.value
                  })}
                  invalid={profileErrors && profileErrors.bank ? true : false}
                  errorMessage={profileErrors.bank}
                />
                <InputSideCustom
                  label={`Account number`}
                  value={dealerProfile.account_number ? dealerProfile.account_number : ''}
                  onChange={(e) => setDealerProfile({
                    ...dealerProfile,
                    account_number: e.target.value
                  })}
                  invalid={profileErrors && profileErrors.account_number ? true : false}
                  errorMessage={profileErrors.account_number}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardHeader className="text-center pb-0 pt-2" style={{ backgroundColor: '#CED4DA' }}>
            <h2 className="title" style={{ color: '#000000' }}>Postal Address</h2>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md={6} sm={12}>
                <InputSideCustom
                  label={`Address`}
                  value={dealerProfile.postal_address ? dealerProfile.postal_address : ''}
                  onChange={(e) => setDealerProfile({
                    ...dealerProfile,
                    postal_address: e.target.value
                  })}
                  invalid={profileErrors && profileErrors.postal_address ? true : false}
                  errorMessage={profileErrors.postal_address}
                />
                <InputSideCustom
                  label={`State`}
                  value={dealerProfile.postal_state ? dealerProfile.postal_state : ''}
                  onChange={(e) => setDealerProfile({
                    ...dealerProfile,
                    postal_state: e.target.value
                  })}
                  invalid={profileErrors && profileErrors.postal_state ? true : false}
                  errorMessage={profileErrors.postal_state}
                />
              </Col>
              <Col md={6} sm={12}>
                <InputSideCustom
                  label={`City`}
                  value={dealerProfile.postal_city ? dealerProfile.postal_city : ''}
                  onChange={(e) => setDealerProfile({
                    ...dealerProfile,
                    postal_city: e.target.value
                  })}
                  invalid={profileErrors && profileErrors.postal_city ? true : false}
                  errorMessage={profileErrors.postal_city}
                />
                <InputSideCustom
                  label={`Post Code`}
                  value={dealerProfile.postal_post_code ? dealerProfile.postal_post_code : ''}
                  onChange={(e) => setDealerProfile({
                    ...dealerProfile,
                    postal_post_code: e.target.value
                  })}
                  invalid={profileErrors && profileErrors.postal_post_code ? true : false}
                  errorMessage={profileErrors.postal_post_code}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardHeader className="text-center pb-0 pt-2" style={{ backgroundColor: '#CED4DA' }}>
            <h2 className="title" style={{ color: '#000000' }}>Contact Address</h2>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md={6} sm={12}>
                <InputSideCustom
                  label={`Phone`}
                  value={dealerProfile.office_contact_phone ? dealerProfile.office_contact_phone : ''}
                  onChange={(e) => setDealerProfile({
                    ...dealerProfile,
                    office_contact_phone: e.target.value
                  })}
                  invalid={profileErrors && profileErrors.office_contact_phone ? true : false}
                  errorMessage={profileErrors.office_contact_phone}
                />
                <InputSideCustom
                  label={`Mobile`}
                  value={dealerProfile.office_mobile ? dealerProfile.office_mobile : ''}
                  onChange={(e) => setDealerProfile({
                    ...dealerProfile,
                    office_mobile: e.target.value
                  })}
                  invalid={profileErrors && profileErrors.office_mobile ? true : false}
                  errorMessage={profileErrors.office_mobile}
                />
              </Col>
              <Col md={6} sm={12}>
                <InputSideCustom
                  label={`Fax`}
                  value={dealerProfile.office_fax ? dealerProfile.office_fax : ''}
                  onChange={(e) => setDealerProfile({
                    ...dealerProfile,
                    office_fax: e.target.value
                  })}
                  invalid={profileErrors && profileErrors.office_fax ? true : false}
                  errorMessage={profileErrors.office_fax}
                />
                <InputSideCustom
                  label={`Email`}
                  value={dealerProfile.email ? dealerProfile.email : ''}
                  onChange={(e) => setDealerProfile({
                    ...dealerProfile,
                    email: e.target.value
                  })}
                  invalid={profileErrors && profileErrors.email ? true : false}
                  errorMessage={profileErrors.email}
                />
              </Col>
            </Row>
            <InputSideCustom
              label={`Email for Enquiries`}
              value={dealerProfile.office_email_for_enquiries ? dealerProfile.office_email_for_enquiries : ''}
              onChange={(e) => setDealerProfile({
                ...dealerProfile,
                office_email_for_enquiries: e.target.value
              })}
              invalid={profileErrors && profileErrors.office_email_for_enquiries ? true : false}
              errorMessage={profileErrors.office_email_for_enquiries}
            />
            <InputSideCustom
              label={`Email for Contract Expiry Notifications`}
              value={dealerProfile.expire_notification_email ? dealerProfile.expire_notification_email : ''}
              onChange={(e) => setDealerProfile({
                ...dealerProfile,
                expire_notification_email: e.target.value
              })}
              invalid={profileErrors && profileErrors.expire_notification_email ? true : false}
              errorMessage={profileErrors.expire_notification_email}
            />
          </CardBody>
        </Card>
        <div className="d-flex flex-row justify-content-center">
          <Button className="w-100" color="success" onClick={() => updateProfile()}>Save</Button>
        </div>
      </CardBody>
    </Card>
  )
}