export const CREATE_EMAIL_TEMPLATE_REQUEST = 'CREATE_EMAIL_TEMPLATE_REQUEST';
export const CREATE_EMAIL_TEMPLATE_SUCCESS = 'CREATE_EMAIL_TEMPLATE_SUCCESS';
export const CREATE_EMAIL_TEMPLATE_FAILURE = 'CREATE_EMAIL_TEMPLATE_FAILURE';

export const GET_EMAIL_TEMPLATES_REQUEST = 'GET_EMAIL_TEMPLATES_REQUEST';
export const GET_EMAIL_TEMPLATES_SUCCESS = 'GET_EMAIL_TEMPLATES_SUCCESS';
export const GET_EMAIL_TEMPLATES_FAILURE = 'GET_EMAIL_TEMPLATES_FAILURE';

export const DELETE_EMAIL_TEMPLATE_REQUEST = 'DELETE_EMAIL_TEMPLATE_REQUEST';
export const DELETE_EMAIL_TEMPLATE_SUCCESS = 'DELETE_EMAIL_TEMPLATE_SUCCESS';
export const DELETE_EMAIL_TEMPLATE_FAILURE = 'DELETE_EMAIL_TEMPLATE_FAILURE';

export const UPDATE_EMAIL_TEMPLATE_REQUEST = 'UPDATE_EMAIL_TEMPLATE_REQUEST';
export const UPDATE_EMAIL_TEMPLATE_SUCCESS = 'UPDATE_EMAIL_TEMPLATE_SUCCESS';
export const UPDATE_EMAIL_TEMPLATE_FAILURE = 'UPDATE_EMAIL_TEMPLATE_FAILURE';

export const SEND_EMAIL_REQUEST = 'SEND_EMAIL_REQUEST';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAILURE = 'SEND_EMAIL_FAILURE';

export const GET_SENT_EMAILS_REQUEST = 'GET_SENT_EMAILS_REQUEST';
export const GET_SENT_EMAILS_SUCCESS = 'GET_SENT_EMAILS_SUCCESS';
export const GET_SENT_EMAILS_FAILURE = 'GET_SENT_EMAILS_FAILURE';

export const GET_ROLES_REQUEST = 'GET_ROLES_REQUEST';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_FAILURE = 'GET_ROLES_FAILURE';

export const GET_EMAIL_DETAILS_BY_RESOURCE_REQUEST = 'GET_EMAIL_DETAILS_BY_RESOURCE_REQUEST';
export const GET_EMAIL_DETAILS_BY_RESOURCE_SUCCESS = 'GET_EMAIL_DETAILS_BY_RESOURCE_SUCCESS';
export const GET_EMAIL_DETAILS_BY_RESOURCE_FAILURE = 'GET_EMAIL_DETAILS_BY_RESOURCE_FAILURE';

export const GET_RESOURCE_EMAILS_REQUEST = 'GET_RESOURCE_EMAILS_REQUEST';
export const GET_RESOURCE_EMAILS_SUCCESS = 'GET_RESOURCE_EMAILS_SUCCESS';
export const GET_RESOURCE_EMAILS_FAILURE = 'GET_RESOURCE_EMAILS_FAILURE';

export const GET_SHORTCODES_REQUEST = 'GET_SHORTCODES_REQUEST';
export const GET_SHORTCODES_SUCCESS = 'GET_SHORTCODES_SUCCESS';
export const GET_SHORTCODES_FAILURE = 'GET_SHORTCODES_FAILURE';