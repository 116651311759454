import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Media,
} from "reactstrap";


const Text = styled.span`
    font-size: 12px;
    border: 1px solid #e6e6e7;
    padding: 5px;
    margin-left: 5px;
`
const TextInput = styled.input`
    font-size: 12px;
    border: 1px solid #e6e6e7;
    padding: 5px;
    margin-left: 5px;
`
const Line = styled.span`
border: 1px solid #e6e6e7;
 width: 100%;
 color: #e6e6e7;
`
const Container = styled.div`
    border-radius: 4px;
    padding: 20px;
    gap: 34px;
    display: flex;
    margin-bottom: 25px;
    @media (max-width: 833px) {
        flex-direction:column
      }
`
export default function VehicleInfo({vehicle, showStockNo=false,setMileage, mileage, showMileageInput = false}) {
    return (
        <Container>
            <Media className="align-items-center">
                <img height='25px' width='25px' src={require(`assets/images/directions_car_grey.png`)} alt=""/>
                <Media className="ml-2  d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                     {vehicle.vehicle_make ? vehicle.vehicle_make?.name ? vehicle.vehicle_make?.name : vehicle.vehicle_make : vehicle?.make.name}
                      </span>
                </Media>
            </Media>
            <Media className="align-items-center">
                <img height='25px' width='25px' src={require(`assets/images/loyalty.png`)} alt=""/>
                <Media className="ml-2  d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                          {vehicle.vehicle_model ? vehicle.vehicle_model?.name ? vehicle.vehicle_model?.name : vehicle.vehicle_model : vehicle?.model.name}
                      </span>
                </Media>
            </Media>
            {
                showStockNo &&
                <Media className="align-items-center">
                    <img height='25px' width='25px' src={require(`assets/images/loyalty.png`)} alt=""/>
                    <Media className="ml-2  d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                     {vehicle.stock_no}
                      </span>
                    </Media>
                </Media>
            }
            <Media className="align-items-center">
                <img height='25px' width='25px' src={require(`assets/images/add_road.png`)} alt=""/>
                <Media className="ml-2 d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                      Mileage    {
                              showMileageInput ?  <TextInput value={mileage} onChange={(e)=>setMileage(e.target.value)} /> :<Text>{vehicle.current_mileage || vehicle.mileage}</Text>
                          }

                      </span>
                </Media>
            </Media>
        </Container>
    )
}