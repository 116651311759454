import React from "react";
import { connect } from 'react-redux';

import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interaction from "@fullcalendar/interaction";
import NotificationAlert from "react-notification-alert";
import moment from 'moment'

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Modal,
  Container,
  Row,
  Col
} from "reactstrap";
import * as constants from 'constants/index.js';

let calendar;

class CalendarView extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    events: [],
    assignees: [],
    alert: null,
    token: '',
    errors: '',
    jobTypes: [],
    statusTypes: [],
  };
  componentDidMount() {
    this.setState({
      events: this.props.jobs,
      jobTypes: this.props.jobTypes,
      statusTypes: this.props.statusTypes,
    }, function() {
      this.createCalendar();
    });
  }
  createCalendar = () => {
    calendar = new Calendar(this.refs.calendar, {
      navLinks: true,
      plugins: [interaction, dayGridPlugin],
      defaultView: "dayGridMonth",
      selectable: true,
      selectHelper: true,
      editable: true,
      events: this.state.events,
      // Edit calendar event action
      eventClick: ({ event }) => {
        this.setState({
          modalChange: true,
          eventId: event.id,
          eventDuration: event.extendedProps.eta_hours,
          eventDescription: event.extendedProps.description,
          radios: event.classNames[0],
          event: event,
          errors: ''
        });
      }
    });
    calendar.render();
    this.setState({
      currentDate: calendar.view.title
    });
  };
  changeView = newView => {
    calendar.changeView(newView);
    this.setState({
      currentDate: calendar.view.title,
      gridMode: newView
    });
  };
  notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  render() {
    return (
      <>
        {this.state.alert}        
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <div className="header header-dark content__title content__title--calendar">
          <Container fluid className="px-0">
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6">
                  <h6 className="fullcalendar-title h2 text-black d-inline-block mb-0 mr-1">
                    {this.state.currentDate}
                  </h6>
                </Col>
                <Col className="mt-3 mt-md-0 text-md-right" lg="6">
                  <Button
                    className="fullcalendar-btn-prev btn-neutral"
                    color="default"
                    onClick={() => {
                      calendar.prev();
                    }}
                    size="sm"
                  >
                    <i className="fas fa-angle-left" />
                  </Button>
                  <Button
                    className="fullcalendar-btn-next btn-neutral"
                    color="default"
                    onClick={() => {
                      calendar.next();
                    }}
                    size="sm"
                  >
                    <i className="fas fa-angle-right" />
                  </Button>
                  <Button
                    className="btn-neutral"
                    color="default"
                    data-calendar-view="month"
                    onClick={() => this.changeView("dayGridMonth")}
                    size="sm"
                  >
                    Month
                  </Button>
                  <Button
                    className="btn-neutral"
                    color="default"
                    data-calendar-view="basicWeek"
                    onClick={() => this.changeView("dayGridWeek")}
                    size="sm"
                  >
                    Week
                  </Button>
                  <Button
                    className="btn-neutral"
                    color="default"
                    data-calendar-view="basicDay"
                    onClick={() => this.changeView("dayGridDay")}
                    size="sm"
                  >
                    Day
                  </Button>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container className="mt--6" fluid className="px-0">
          <Row>
            <div className="col">
              <Card className="card-calendar">
                <CardHeader>
                  <h5 className="h3 mb-0">Calendar</h5>
                </CardHeader>
                <CardBody className="p-0">
                  <div
                    className="calendar"
                    data-toggle="calendar"
                    id="calendar"
                    ref="calendar"
                  />
                </CardBody>
              </Card>
              <Modal
                isOpen={this.state.modalChange}
                toggle={() => this.setState({ modalChange: false })}
                className="modal-dialog-centered modal-secondary"
              >
                <div className="modal-body">
                  <h2 className="text-uppercase mb-5 text-center">Job information</h2>
                  <Row>
                    <Col md={5} sm={12}>
                      <b>Stock No: </b>
                    </Col>
                    <Col md={7} sm={12}>
                      {this.state.event? this.state.event.title: '-'}
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={5} sm={12}>
                      <b>Type of work: </b>
                    </Col>
                    <Col md={7} sm={12}>
                      {this.state.event && this.state.event.extendedProps.job_type ? this.state.event.extendedProps.job_type : ''}
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={5} sm={12}>
                      <b>Work needed: </b>
                    </Col>
                    <Col md={7} sm={12}>
                      {this.state.event? this.state.event.extendedProps.description: '-'}
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={5} sm={12}>
                      <b>ETA Hours: </b>
                    </Col>
                    <Col md={7} sm={12}>
                      {this.state.event? this.state.event.extendedProps.eta_hours: '-'}
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={5} sm={12}>
                      <b>Status: </b>
                    </Col>
                    <Col md={7} sm={12}>
                      {this.state.event && this.state.statusTypes? this.state.statusTypes[this.state.event.extendedProps.status]: '-'}
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={5} sm={12}>
                      <b>Started: </b>
                    </Col>
                    <Col md={7} sm={12}>
                      {this.state.event? moment(this.state.event.start).format('DD/MM/YYYY HH:mm:ss'): '-'}
                    </Col>
                  </Row>
                  {this.state.event && this.state.statusTypes && this.state.statusTypes[this.state.event.extendedProps.status] === 'Completed' && 
                  <Row className="mt-3">
                    <Col md={5} sm={12}>
                      <b>Ended: </b>
                    </Col>
                    <Col md={7} sm={12}>
                      {this.state.event? moment(this.state.event.end).format('DD/MM/YYYY HH:mm:ss'): '-'}
                    </Col>
                  </Row>}
                </div>
                <div className="modal-footer">
                  <Button
                    className="ml-auto"
                    color="link"
                    onClick={() => this.setState({ modalChange: false })}
                  >
                    Close
                  </Button>
                </div>
              </Modal>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    authUserTeam: state.getState.authUserTeam,
  }
}

export default connect(
  mapStateToProps
)(CalendarView)