import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";
import NotificationAlert from "react-notification-alert";
import Cookies from 'js-cookie';
import axios from 'axios';

import * as constants from 'constants/index.js';

import InputCustom from 'views/pages/components/InputCustom';
import InputFile from 'views/pages/components/InputFile';
import {
  InputKeySelect,
  InputArraySelect,
} from 'views/pages/components/InputSelect';
import TableCustom from 'views/pages/components/TableCustom';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Badge, FormGroup
} from "reactstrap";

import {
  create,
  update,
  deletee,
  manageUsers,
  hasPermission,
} from 'views/policies/UserPolicy';

import CardsHeader from 'layouts/Headers/CardsHeader.js';
import {ButtonContainer} from "@pages/reusable-components/styled-components";

export default function User() {
  const notificationAlert = useRef(null);
  const history = useHistory();

  const profileData = useSelector((state) => state.getState).profileData;
  const permissions = useSelector((state) => state.getState).permissions;

  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [jobCategories, setJobCategories] = useState('');
  const [userCategoryRoles, setUserCategoryRoles] = useState('');

  const [confirmShow, setConfirmShow] = useState(false);
  const [selectedId, setSelectedId] = useState('');

  const [addModal, setAddModal] = useState(false);
  const [user, setUser] = useState({
    name: '',
    email: '',
    role_id: '',
    photo: '',
    password: '',
    password_confirmation: '',
  });

  const [errors, setErrors] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    console.log('profileData :: ', profileData);
    if (Cookies.get('grs_token')) {
      let grs_token = Cookies.get('grs_token');
      setToken(grs_token);

      axios({
        method: 'GET',
        url: constants.API_URL + '/user',
        headers: {
          Authorization: 'Bearer ' + grs_token
        }
      })
        .then(response => {
          if (response.status === 200) {
            let data = response.data.data;
            console.log('response data :: ', data);
            setUsers(data.users);
            setRoles(data.roles);
            setJobCategories(data.jobCategories);
            setUserCategoryRoles(data.userCategoryRoles);
            setTimeout(() => {
              setLoading(false);
            }, 500);
          }
        })
        .catch(error => {
          setLoading(false);
          console.error('error :: ', error);
          notify('warning', 'Warning', error.response.statusText);
        })
    }
  }, [])

  const formatDate = (d) => {
    let options = {
      year: 'numeric', month: '2-digit', day: '2-digit',
      hour: 'numeric', minute: 'numeric', second: 'numeric',
      hour12: false
    }
    const date = new Date(d);
    const formatedDate = new Intl.DateTimeFormat('en-GB', options).format(date);
    return formatedDate;
  }

  const addUser = () => {
    let formData = new FormData();
    formData.append('name', user.name);
    formData.append('email', user.email);
    formData.append('role_id', user.role_id);
    formData.append('is_driver', user.is_driver);
    formData.append('photo', user.photo);
    formData.append('job_category', typeof user.job_category === 'undefined' || ['undefined', null, 'null', '-'].includes(user.job_category) ? '' : user.job_category);
    formData.append('role_type', typeof user.role_type === 'undefined' || ['undefined', null, 'null', '-'].includes(user.role_type) ? '' : user.role_type);
    formData.append('password', user.password);
    formData.append('password_confirmation', user.password_confirmation);

    axios({
      method: 'POST',
      url: constants.API_URL + '/user',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: formData
    })
      .then(response => {
        setAddModal(false);
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          notify('success', 'Success', data.message);
          setUsers(data.data);
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        if (error.response.status === 422) {
          let errors = error.response.data.errors;
          setErrors(errors);
          return
        }
        setAddModal(false);
        notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred.');
      })
  }

  const editUser = (row) => {
    history.push({
      pathname: profileData.id == row.id? '/admin/profile': '/admin/user/' + row.id + '/edit'
    });
  }

  const deleteUser = () => {
    axios({
      method: 'DELETE',
      url: constants.API_URL + '/user/' + selectedId,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(response => {
        setConfirmShow(false);
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          notify('success', 'Success', data.message);
          setUsers(data.data);
        }
      })
      .catch(error => {
        setConfirmShow(false);
        console.error('error :: ', error);
        notify('error', 'Error', error.response.statusText);
      })
  }

  const handleChange = (files) => {
    setUser({
      ...user,
      photo: files[0]
    });
  }

  const editHoliday = (user) => {
    history.push({
      pathname: `/admin/holiday/${user.id}/user`,
      state: {
        user_name: user.name
      }
    })
  }

  return (
    <>
      <CardsHeader name="Admin" parentName="User Management" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--6 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Users</h1>
                    <p className="text-sm mb-0">Users management section.</p>
                  </Col>
                  <Col xs={4} className="text-right">
                    {create(permissions) &&
                    <ButtonContainer onClick={() => setAddModal(true)}>
                      <i className="fa fa-plus" aria-hidden="true"></i>
                      Add user
                    </ButtonContainer>}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <TableCustom
                  loading={loading}
                  keyField={`name`}
                  searchField={true}
                  items={users}
                  columns={[
                    {
                      dataField: "photo",
                      text: "Photo",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return (
                          <span className="avatar avatar-sm rounded-circle">
                            {row.picture ? (<img
                              alt={row.name ? row.name.slice(0, 2) : ''}
                              src={constants.STORAGE_URL + row.picture}
                            />)
                              : (row.name ? row.name.slice(0, 2) : '')}
                          </span>
                        )
                      }
                    },
                    {
                      dataField: "name",
                      text: "Name",
                      sort: true
                    },
                    {
                      dataField: "email",
                      text: "Email",
                      sort: true
                    },
                    {
                      dataField: "is_active",
                      text: "Is active(Login)",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return (
                          <Badge color={row.is_active === 1? 'success': 'dark'}>{row.is_active === 1? `Yes`: 'No'}</Badge>
                        );
                      }
                    },
                    {
                      dataField: "role",
                      text: "Roles",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return(
                          <span>
                            {row.roles && row.roles.length > 0 && row.roles.map((item, index) => {
                              return item.name;
                            })}
                          </span>
                        )
                      }
                    },
                    {
                      dataField: "created_at",
                      text: "Creation date",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return formatDate(row.created_at);
                      }
                    },
                    {
                      dataField: "action",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            {manageUsers(permissions) &&
                            (hasPermission(permissions, 'update-users') || hasPermission(permissions, 'delete-users')) &&
                              <>
                                <img className={'cursor-pointer'}  onClick={e => editUser(row)} src={require(`assets/images/tableIcons/edit.svg`)} alt=""/>
                                <img className={'cursor-pointer'}     onClick={e => editHoliday(row)}  src={require(`assets/images/tableIcons/visibility.svg`)} alt=""/>
                                {deletee(permissions, profileData, row) && <img className={'cursor-pointer'}  onClick={e => {
                                  setConfirmShow(true);
                                  setSelectedId(row.id);
                                }}
                                      src={require(`assets/images/tableIcons/delete_outline.svg`)} alt=""/>
                                }
                              </>
                             }
                          </>
                        )
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={addModal}
          toggle={() => setAddModal(false)}
          size="lg"
          className="modal-dialog-centered modal-secondary"
        >
          <div className="modal-header">
            <h2 className="title">User Information</h2>
          </div>
          <div className="modal-body">
            <Row>
              <Col md={6}>
                <InputCustom
                  label={`Name`}
                  placeholder={`Name`}
                  onChange={(e) => setUser({
                    ...user,
                    name: e.target.value
                  })}
                  invalid={errors && errors.name? true: false}
                  errorMessage={errors.name}
                />
              </Col>
              <Col md={6}>
                <InputCustom
                  label={`Email`}
                  placeholder={`Email`}
                  onChange={(e) => setUser({
                    ...user,
                    email: e.target.value
                  })}
                  invalid={errors && errors.email? true: false}
                  errorMessage={errors.email}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <InputArraySelect
                  label={`Role`}
                  placeholder={`Role`}
                  options={roles}
                  onChange={(e) => setUser({
                    ...user,
                    role_id: e.target.value
                  })}
                  valueKey={`id`}
                  labelKey={`name`}
                  defaultOption={true}
                  invalid={errors && errors.role_id? true: false}
                  errorMessage={errors.role_id}
                />
              </Col>
              <Col md={6}>
                <InputFile
                  label={`Profile photo`}
                  placeholder={`Select file`}
                  onChange={(e) => handleChange(e.target.files)}
                  invalid={errors && errors.photo? true: false}
                  errorMessage={errors.photo}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <InputCustom
                  type={`password`}
                  label={`Password`}
                  placeholder={`Password`}
                  onChange={(e) => setUser({
                    ...user,
                    password: e.target.value
                  })}
                  invalid={errors && errors.password? true: false}
                  errorMessage={errors.password}
                />
              </Col>
              <Col md={6}>
                <InputCustom
                  type={`password`}
                  label={`Confirm password`}
                  placeholder={`Confirm password`}
                  onChange={(e) => setUser({
                    ...user,
                    password_confirmation: e.target.value
                  })}
                  invalid={errors && errors.password_confirmation? true: false}
                  errorMessage={errors.password_confirmation}
                />
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <InputKeySelect
                  label={`Job Category`}
                  options={jobCategories}
                  onChange={(e) => setUser({
                    ...user,
                    job_category: e.target.value
                  })}
                  defaultOption={true}
                  invalid={errors && errors.job_category ? true : false}
                  errorMessage={errors.job_category}
                />
              </Col>
              <Col md={6}>
                <div className={'d-flex flex-column'}>
                  <label className="form-control-label">Is driver</label>
                <label className="custom-toggle">
                  <input
                    type="checkbox"
                       onChange={(e) => {
                         setUser({
                    ...user,
                    is_driver: !!user?.is_driver === false ? 1 : 0
                  })
                       }}
                  />
                  <span
                    className="custom-toggle-slider rounded-circle"
                    data-label-off="No"
                    data-label-on="Yes"
                  />
                </label>
                </div>

              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <InputKeySelect
                  label={`Role Type`}
                  options={userCategoryRoles}
                  onChange={(e) => setUser({
                    ...user,
                    role_type: e.target.value
                  })}
                  defaultOption={true}
                  invalid={errors && errors.role_type ? true : false}
                  errorMessage={errors.role_type}
                />
              </Col>
            </Row>

          </div>
          <div className="modal-footer">
            <Button
              className="new-event--add"
              color="primary"
              type="button"
              onClick={() => addUser()}
            >
              Save
            </Button>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setAddModal(false)}
            >
              Cancel
            </Button>
          </div>
        </Modal>
        <Modal
          isOpen={confirmShow}
          toggle={() => setConfirmShow(false)}
          className="modal-dialog-centered modal-secondary"
        >
          <div className="modal-body text-center">
            {`Are you sure you want to delete this user?`}
          </div>
          <div className="modal-footer justify-content-center">
            <ButtonContainer
                onClick={() => setConfirmShow(false)}
            >
              Cancel
            </ButtonContainer>
            <ButtonContainer
              reverse
              onClick={() => deleteUser()}
            >
              {`Delete`}
            </ButtonContainer>

          </div>
        </Modal>
      </Container>
    </>
  )
}