import React, {useEffect, useState, useRef, Component} from 'react'
import {useHistory} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import NotificationAlert from 'react-notification-alert'
import Select, {components} from 'react-select'
import styled from "styled-components";
import axios from 'axios'
import Cookies from 'js-cookie'

import * as constants from 'constants/index.js'
import CardsHeader from 'layouts/Headers/CardsHeader.js'

import InputCustom from 'views/pages/components/InputCustom'
import {
    InputArraySelect,
} from 'views/pages/components/InputSelect'
import actions from 'actions/index.js';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Modal,
    FormGroup, Label, Input, ModalHeader, ModalBody
} from 'reactstrap'
import CustomChecklist from "../components/CustomChecklist";
import VehicleInfo from "../vehicles/VehicleInfo";
import {LoaderContainer, override} from "@pages/reusable-components/styled-components";
import {ClipLoader} from "react-spinners";

const RangeContainer = styled.div`
   border: 1px solid #e6e6e7;
   width: 30px;
   height: 27px;
   border-radius: 4px;
   text-align: -webkit-center;
`

const Text = styled.span`
 font-weight: ${props => props.bold ? '600' : '400'};
 color: ${props => props.bold ? '#35353E' : '#4E4E56'};
 font-size:  ${props => props.size ? props.size : '16px'}
`

export default function JobCreate() {
    const notificationAlert = useRef(null)
    const history = useHistory()
    const dispatch = useDispatch()
    const [token, setToken] = useState('')
    const [loading, setLoading] = useState(true)
    const [modal, setModal] = useState(false)
    const [regNos, setRegNos] = useState([])
    const [filter, setFilter] = useState('')
    const [vehicle, setVehicle] = useState('')
    const [mileage, setMileage] = useState('')
    const [categories, setCategories] = useState({})
    const [requiredData, setRequiredData] = useState(true)
    const [showRequired, setShowRequired] = useState(false)
    const [pendingRequest, setPendingRequest] = useState(false)
    const [jobTypes, setJobTypes] = useState([])
    const [job, setJob] = useState('')
    const [jobModal, setJobModal] = useState({
        show: false,
        header: '',
        division: ''
    })
    const [bodyShop, setBodyShop] = useState(1)
    const [valeting, setValeting] = useState(1)
    const [note, setNote] = useState('')
    const [errors, setErrors] = useState('')
    const generalCategories = useSelector((state) => state.getState).categories;
    useEffect(() => {
        setRequiredData(!!Object.values(generalCategories).find(item => Object.values(item).find(value => value.is_passing === null)))
    }, [generalCategories])
    const notify = (type, header, message) => {
        const options = {
            place: "tc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
              {header}
          </span>
                    <span data-notify="message">
            <div dangerouslySetInnerHTML={{__html: message}}/>
          </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5
        }
        notificationAlert.current.notificationAlert(options)
    }

    useEffect(() => {
        const grs_token = Cookies.get('grs_token')
        setToken(grs_token)

        const source = axios.CancelToken.source()

        const fetchData = async () => {
            try {
                const response = await axios({
                    method: 'GET',
                    url: `${constants.FLEET_URL}/fleet-management/vehicles/available`,
                    headers: {
                        Authorization: `Bearer ${grs_token}`,
                    },
                    cancelToken: source.token,
                })
                const data = response.data

                let _regNos = data.data.regNos;
                let _regNos_array = [];
                _regNos.map(_regNo => {
                    return _regNos_array.push({
                        ..._regNo,
                        value: _regNo.id,
                        label: _regNo.stock_no,
                    })
                })
                setRegNos(_regNos_array)
            } catch (error) {
                if (axios.isCancel(error)) {
                    console.log('Request canceled :: ', error)
                } else {
                    console.error('error :: ', error)
                    notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
                }
            }
        }

        fetchData()

        return () => {
            source.cancel()
        }
    }, [])

    const goList = () => {
        history.push({
            pathname: '/admin/vehicle-in-stock'
        })
    }

    const searchVehicle = () => {
        if (filter && filter.regNo) {
            fetchJobs(1)
        } else {
            notify('info', 'Information', 'Please select a reg no.')
        }
    }

    const fetchJobs = async (pageNumber) => {
        try {
            const categories = await axios({
                method: 'GET',
                url: `${constants.FLEET_URL}/fleet-management/category-options`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    page: pageNumber
                }
            })
            let tempCategories = {}
            categories.data.data.categories.map(item => {
                let categoryName = parseToKey(item.name)
                item.options.forEach(val => {
                    tempCategories[categoryName] = {
                        ...tempCategories[categoryName],
                        [parseToKey(val.name)]:
                            {
                                'is_passing': true,
                                'id': val.id
                            }
                    }
                })
            })
            dispatch(actions.setCategories(tempCategories))
            setCategories(tempCategories)
            setTimeout(() => {
                setLoading(false)
            }, 500);
        } catch (error) {
            notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
        }
    }
    const parseToKey = (name) => {
        return name.replace(/\s+/g, '_').toLowerCase();
    }
    const createJob = async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${constants.API_URL}/fleet-management/${filter.regNo}/vehicle-jobs`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: {
                    ...job,
                    status: 0,
                },
            })
            console.log('response :: ', response.data)
            const data = response.data

            notify('success', 'Success', data.message)

            setJobModal({
                ...jobModal,
                show: false
            })
        } catch (error) {
            console.error('error :: ', error)
            if (error.response && error.response.status === 422) {
                setErrors(error.response.data.errors)
                return;
            }
            setJobModal({
                ...jobModal,
                show: false
            })
            notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
        }
    }
    const ValueContainer = ({children, ...props}) => {
        return (
            components.ValueContainer && (
                <components.ValueContainer {...props}>
                    {!!children && (
                        <i
                            className="fa fa-search"
                            aria-hidden="true"
                            style={{position: 'absolute', left: 6,}}
                        />
                    )}
                    {children}
                </components.ValueContainer>
            )
        );
    };
    const changeCategories = (data) => {
        dispatch(actions.setCategories({...categories, [data.name]: data.value}))
    }
    const styles = {
        valueContainer: base => ({
            ...base,
            paddingLeft: 24
        })
    }
    const toggle = () => setModal(!modal);
    const sendData = async (value) => {
        setPendingRequest(true)
        let values = {}
        let arr = []
        Object.keys(generalCategories).map((item) => {
            arr.push(...Object.values(generalCategories[item]))
        })
        values.pdi_checklist = arr
        values.work_complexity_body_shop = bodyShop
        values.work_complexity_valeting = valeting
        values.note = note
        values.vehicle_id = vehicle.id
        values.mileage_in = mileage
        values.create_tasks_on_save = value
        const response = await axios({
            method: 'POST',
            url: `${constants.FLEET_URL}/fleet-management/fleet`,
            data: values,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
        if (response.status === 200) {
            if (value) {
                let id = response.data.data.fleet.id
                history.push(`/admin/vehicle-details?id=${id}`)
            }
        }
        setPendingRequest(false)
        setModal(false)
    }
    return (
        <>
            <Modal
                isOpen={modal}
                toggle={toggle}
            >
                <ModalHeader toggle={toggle}>
                    <Text
                        bold>{pendingRequest ? 'Generating tasks please wait' : 'Please select the type of document'}</Text>
                    <br/>
                </ModalHeader>
                <ModalBody>
                    {!pendingRequest && <Row>
                        <Col sm={6}>
                            <Button outline={true} className="w-100"
                                    style={{backgroundColor: "white", color: '#ec6409', border: '1px solid #ec6409'}}
                                    type="button" onClick={() => sendData(false)}>Save Vehicle</Button>
                        </Col>
                        <Col sm={6}>
                            <Button className="w-100"
                                    style={{backgroundColor: "#ec6409", color: 'white', border: 'none'}} type="button"
                                    onClick={() => sendData(true)}>Save & Assign Task</Button>
                        </Col>
                    </Row>}
                </ModalBody>

            </Modal>
            <CardsHeader name="Fleets" parentName="Job Management" currentName="Create"/>
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlert}/>
            </div>
            <Container className="mt--5 admin-main-body" fluid>
                <Row>
                    <Col xl="12">
                        <Card>
                            <CardBody>
                                <Container>
                                    <Row>
                                        <Col md={6} sm={12} xs={12}>
                                            <FormGroup>
                                                <label className="form-control-label">Please enter the Vehicle Reg.
                                                    Number for applying the checklist</label>
                                                <Select
                                                    options={regNos}
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    isSearchable={true}
                                                    name="Please enter the Vehicle Reg. Number for applying the checklist"
                                                    components={{
                                                        DropdownIndicator: () => null,
                                                        IndicatorSeparator: () => null,
                                                        ValueContainer
                                                    }}
                                                    placeholder={'Reg. Number'}
                                                    styles={styles}
                                                    value={filter && filter.regNo ? (regNos.find((element) => element.id == filter.regNo) || '') : ''}
                                                    onChange={value => {
                                                        setVehicle(value);
                                                        setMileage(value.current_mileage)
                                                        setFilter({
                                                            ...filter,
                                                            regNo: value.id
                                                        })
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2} sm={12} xs={12} className="pt-3-4">
                                            <Button className="w-100"
                                                    style={{backgroundColor: "#ec6409", color: 'white'}} type="button"
                                                    onClick={() => searchVehicle()}>Search</Button>
                                        </Col>
                                    </Row>
                                    {
                                        vehicle && !loading &&
                                        <VehicleInfo showMileageInput={true} setMileage={setMileage} mileage={mileage}
                                                     vehicle={vehicle}/>
                                    }
                                </Container>
                            </CardBody>
                        </Card>


                        <Row>
                            {vehicle && !loading && Object.keys(categories).map(item => {
                                return <Col md={6}>
                                    <CustomChecklist showRequired={showRequired} title={'Body work'} vehicle={vehicle}
                                                     categoryName={item} category={categories[item]}
                                                     setCategories={changeCategories}/>
                                </Col>
                            })
                            }

                        </Row>
                        {vehicle && !loading &&
                        <>
                            <Col sm={6}>
                                <label className="form-control-label">Note</label>
                                <Input
                                    id="exampleText"
                                    name="text"
                                    type="textarea"
                                    onChange={(e) => setNote(e.target.value)}
                                />
                            </Col>

                            <FormGroup>
                                <label className="form-control-label">Work complexity estimation</label>
                                <Input
                                    plaintext
                                    value=" *Estimate 1-10, from easier to harder"
                                />
                            </FormGroup>
                        </>
                        }
                    </Col>
                    {vehicle && !loading &&
                    <>
                        <Col className='form-group' sm={12}>
                            <Row>
                                <Col sm={1}>
                                    <Label
                                        for="bodyShop"
                                    >
                                        Body Shop
                                    </Label>
                                </Col>
                                <Col sm={2}>
                                    <input
                                        id="bodyShop"
                                        name="bodyShop"
                                        min={1}
                                        max={10}
                                        step={1}
                                        value={bodyShop}
                                        onChange={event => setBodyShop(event.target.value)}
                                        type="range"
                                    />
                                </Col>
                                <Col sm={1}>
                                    <RangeContainer>
                                        {bodyShop}
                                    </RangeContainer>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={12}>
                            <Row>
                                <Col sm={1}>
                                    <Label
                                        for="valeting"
                                    >
                                        Valeting
                                    </Label>
                                </Col>
                                <Col sm={2}>
                                    <input
                                        id="valeting"
                                        name="valeting"
                                        type="range"
                                        min={1}
                                        max={10}
                                        step={1}
                                        value={valeting}
                                        onChange={event => setValeting(event.target.value)}
                                    />
                                </Col>
                                <Col sm={1}>
                                    <RangeContainer>
                                        {valeting}
                                    </RangeContainer>
                                </Col>
                            </Row>

                        </Col>
                        <Col sm={12}>
                            <Col sm={6}>
                                {requiredData ?
                                    <Button className="w-50" style={{
                                        backgroundColor: "#ec6409",
                                        color: 'white',
                                        float: 'right',
                                        marginTop: '25px',
                                        opacity: '0.65'
                                    }} type="button" onClick={() => setShowRequired(true)}>Save</Button>
                                    :
                                    <Button className="w-50" style={{
                                        backgroundColor: "#ec6409",
                                        color: 'white',
                                        float: 'right',
                                        marginTop: '25px'
                                    }} type="button" onClick={() => setModal(true)}>Save</Button>
                                }
                            </Col>
                        </Col>
                    </>

                    }
                </Row>
                <Modal
                    isOpen={jobModal.show}
                    toggle={() => setJobModal({
                        ...jobModal,
                        show: false
                    })}
                    className="modal-dialog-centered"
                >
                    <div className="modal-header">
                        <h6 className="modal-title">{jobModal.header}</h6>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setJobModal({
                                ...jobModal,
                                show: false
                            })}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <InputArraySelect
                            label={`Type Of Work`}
                            options={jobTypes}
                            value={job && job.job_type_id ? job.job_type_id : ''}
                            onChange={e => setJob({
                                ...job,
                                job_type_id: e.target.value
                            })}
                            defaultOption={true}
                            valueKey={`id`}
                            labelKey={`name`}
                            invalid={errors && errors.job_type_id ? true : false}
                            errorMessage={errors.job_type_id}
                        />
                        <InputCustom
                            type={`textarea`}
                            rows={5}
                            label={`Work Needed`}
                            value={job && job.description ? job.description : ''}
                            onChange={e => setJob({
                                ...job,
                                description: e.target.value
                            })}
                            invalid={errors && errors.description ? true : false}
                            errorMessage={errors.description}
                        />
                    </div>
                    <div className="modal-footer">
                        <Button
                            className="new-event--add"
                            color="primary"
                            type="button"
                            onClick={() => createJob()}
                        >
                            {`Save`}
                        </Button>
                        <Button
                            className="ml-auto"
                            color="link"
                            type="button"
                            onClick={() => setJobModal({
                                ...jobModal,
                                show: false
                            })}
                        >
                            {`Close`}
                        </Button>
                    </div>
                </Modal>
            </Container>
        </>
    )
}