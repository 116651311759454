import React, { useEffect, useState, useRef, useContext } from 'react'
import { LiveVehicleContext } from "./context";

import InputCustom from 'views/pages/components/InputCustom'
import {
  InputArraySelect, InputKeySelect, SelectWithoutInput
} from 'views/pages/components/InputSelect'
import TableCustom from 'views/pages/components/TableCustom'

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from 'reactstrap'

import ChangeHandler from './ChangeHandler';
import CustomSearch from "@pages/components/CustomSearch";
import {parseToKey, parseToWord} from "../../../constants";
import {ButtonContainer} from "@pages/reusable-components/styled-components";

export default function ForSaleVehicleList() {
  const vehicleContext = useContext(LiveVehicleContext);
  const {
    notify,
    inputStyle, formGroupStyle,
    token,
    loading,
    items,
    vehicleStatuses,
    dealStatuses,
    filter, setFilter,
    pagination,
    soldTypes,
    soldProcessTypes,
    pageNumber, setPageNumber,
    updateDataChanges,
    fetchData,
    filterVehicle,
    resetVehicle,
    submitItem,
    updateItemsValue,
    history,
    setRefererUrl
  } = vehicleContext;

  Object.keys(dealStatuses)
    .filter(key => !['sold', 'available'].includes(key))
    .forEach(key => delete dealStatuses[key]);

  return (
    <>
      <Row>
        <Col xl="12">
          <ChangeHandler notify={notify} updateData={(vehicle) => updateDataChanges(vehicle)} />
        </Col>
        <Col xl="12">
          <Card>
            <CardHeader>
              <Row>
                <Col xs={8}>
                  <h2 className="mb-0">Vehicles For Sale</h2>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Container>
                <Row className="m-2 justify-content-md-center">
                  <Col md={3} sm={12} xs={12}>
                    <CustomSearch
                        onChange={e => setFilter({
                          ...filter,
                          reg_no: e.target.value
                        })}
                        placeholder={'Search'}
                    />
                  </Col>
                  <Col md={3} sm={12}>
                    <SelectWithoutInput
                        label={filter && filter.vehicle_status? parseToWord(filter.vehicle_status) : `Vehicle Status`}
                        onSelect={(e) => {
                          setFilter({
                            ...filter,
                            vehicle_status: parseToKey(e)
                          })
                        }}
                        options={Object.values(vehicleStatuses)}
                        defaultOption={true}
                        imageLink={'directions_car_grey.png'}
                    />

                  </Col>
                  <Col md={3} sm={12}>
                    <SelectWithoutInput
                        label={filter && filter.sold_type? parseToWord(filter.sold_type) : `Sold Type`}
                        onSelect={(e) => {
                          setFilter({
                            ...filter,
                            sold_type: parseToKey(e)
                          })
                        }}
                        options={Object.values(soldTypes)}
                        defaultOption={true}
                        imageLink={'loyalty.png'}
                    />

                  </Col>
                  <Col md={3} sm={12}>
                    <SelectWithoutInput
                        label={filter && filter.sold_process_type? parseToWord(filter.sold_process_type) : `Deal status`}
                        onSelect={(e) => {
                          setFilter({
                            ...filter,
                            sold_process_type: parseToKey(e)
                          })
                        }}
                        options={Object.values(soldProcessTypes)}
                        defaultOption={true}
                        imageLink={'loyalty.png'}
                    />
                  </Col>
                </Row>
                <Row className={'justify-content-end mt-5'}>
                  <ButtonContainer type="button" onClick={() => resetVehicle()}>Clear</ButtonContainer>
                  <ButtonContainer reverse type="button" onClick={() => filterVehicle()}>Apply</ButtonContainer>
                </Row>
              </Container>
              {items.length > 0 && <Row>
                <Col xs={8}>
                  <h2 className="mb-0">Number of vehicles: {items.length}</h2>
                </Col>
              </Row>
              }
              <TableCustom
                loading={loading}
                items={items}
                classes={'custom-table-striped'}
                // stickyContent={(
                //   !loading && items && items.length > 0 && <Row>
                //     <Col sm={12}>
                //       <Button className="w-100" color="success" style={{ margin: 5 }} type="button" onClick={() => submitItem()}>Save</Button>
                //     </Col>
                //   </Row>
                // )}
                pagination={pagination}
                onChange={pageNumber => {
                  setPageNumber(pageNumber)
                  fetchData(pageNumber, token, filter)
                }
                }
                columns={[
                  {
                    dataField: "vehicle_status",
                    text: "Vehicle Status",
                    formatter: (rowContent, row) => {
                      let jobsInProgress = Number(row.in_progress_jobs);
                      let totalJobs = Number(row.total_jobs);
                      let label = jobsInProgress > 0 ? `Needs work` : (totalJobs > 0 ? 'Completed' : "No work recorded");
                      return <a href={`/admin/vehicle-details?id=${row.fleet_id}`} target='_blank'>{label}</a>;
                    }
                  },
                  {
                    dataField: "stock_no",
                    text: "Stock No",
                    style: {
                      "fontWeight": 800
                    },
                    formatter: (rowContent, row) => {
                      return `${row.stock_no}`
                    }
                  },
                  {
                    dataField: "vehicle",
                    text: "Vehicle",
                    formatter: (rowContent, row) => {
                      let vehicle = `${row.vehicle}, ${row.derivative}`;
                      return <a href={`/admin/vehicle/${row.vehicle_id}/edit`} target='_blank'>{vehicle.length > 35 ? `${vehicle.substring(0, 35)}...` : vehicle}</a>;
                    }
                  },
                  {
                    dataField: "deal_id",
                    text: "Deal",
                    formatter: (rowContent, row) => {
                      return row.deal_id ? <a className="text-center" href={`/admin/deals/${row.deal_id}/edit`} target='_blank'>#{row.deal_id}</a> : '-';
                    }
                  },
                  {
                    dataField: "color",
                    text: "Color",
                    formatter: (rowContent, row) => {
                      return `${row.colour}`
                    }
                  },
                  {
                    dataField: "current_mileage",
                    text: "Mileage"
                  },
                  {
                    dataField: "deal_status",
                    text: "Deal Status",
                    formatter: (rowContent, row) => {
                      return (
                        `${row.sold_process}`
                      )
                    }
                  },
                  {
                    dataField: "sold_type",
                    text: "Sold Type",
                    formatter: (rowContent, row) => {
                      return (
                        `${row.sold_type ?? ''}`
                      )
                    }
                  },
                  {
                    dataField: "execution_date",
                    text: "Delivery Date",
                    formatter: (rowContent, row) => {
                      return row.execution_date ?? '-';
                    }
                  },
                  {
                    dataField: "",
                    text: "Action",
                    formatter: (rowContent, row) => {
                      return (
                        <div className="flex-no-wrap">
                          <ButtonContainer  onClick={() => {
                              setRefererUrl('for-sale');
                              console.log(row.live_id)
                              history.push({
                                pathname: `/admin/live-vehicle/${row.live_id}/edit`
                              })
                          }}>View</ButtonContainer>
                        </div>
                      )
                    }
                  }
                ]}
              />
              {!loading && items && items.length > 0 && <Row>
                <Col sm={12}>
                  <ButtonContainer reverse type="button" onClick={() => submitItem()}>Save</ButtonContainer>
                </Col>
              </Row>}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}