import * as actionTypes from './actions';
import produce from 'immer';

const initialState = {
  paymentDetails: null,
  paymentNotedCreateSuccessfully: null,
  paymentInvoiceEmail: null,
  salesAccounts: [],
  mappedSalesAccounts: [],
  taxRates: [],
  paymentUpdateStatus: null,
  paymentUpdateMessage: null
};

const dealData = produce((draft = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PAYMENT_DETAILS_SUCCESS:
      return { ...draft, paymentDetails: action.data };
    case actionTypes.CREATE_INVOICE_NOTE_SUCCESS:
      return { ...draft, paymentNotedCreateSuccessfully: true };
    case actionTypes.CREATE_INVOICE_NOTE_ERROR:
      draft.paymentNotedCreateSuccessfully = false;
      break;
    case actionTypes.SEND_PAYMENT_INVOICE_EMAIL_ERROR:
      draft.paymentInvoiceEmail = false;
      break;
    case actionTypes.SEND_PAYMENT_INVOICE_EMAIL_SUCCESS:
      draft.paymentInvoiceEmail = true;
      break;
    case actionTypes.GET_SALES_ACCOUNTS_SUCCESS:
      draft.salesAccounts = action.data.products;
      draft.mappedSalesAccounts = action.data.mappedProducts;
      break;
    case actionTypes.GET_TAXES_SUCCESS:
      draft.taxRates = action.data.taxes;
      break;
    case actionTypes.UPDATE_INVOICE_REQUEST:
      draft.paymentUpdateStatus = null;
      draft.paymentUpdateMessage = null;
      break;
    case actionTypes.UPDATE_INVOICE_SUCCESS:
      draft.paymentUpdateStatus = true;
      break;
    case actionTypes.UPDATE_INVOICE_ERROR:
      draft.paymentUpdateStatus = false;
      draft.paymentUpdateMessage = action.message;
      break;
    default:
      return draft;
  }
});

export default dealData;
