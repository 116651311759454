import React, { useContext } from 'react';
import { LeadsContext } from '../context';

import TableCustom from 'views/pages/components/TableCustom';

import {
  Button,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  Row,
  Col,
} from "reactstrap";
import {ButtonContainer} from "@pages/reusable-components/styled-components";

export default function DetailsTab() {
  const leadsContext = useContext(LeadsContext);
  const {
    noteModal, setNoteModal,
    lead,
    constants,
    deletee, hasPermission,
    profilePermissions,
    authUserTeam,
    deleteModal, setDeleteModal,
    deleteNote,
    loading,
    InputCustom,
    selectedNote, setSelectedNote,
    noteErrors,
    addNote,
  } = leadsContext;
  return (
    <Card>
      <CardBody>
        <Row>
          <Col xs={8}>
            <h3 className="mb-0 main-color">Add Notes</h3>
          </Col>
          <Col xs={4} className="text-right">
            <ButtonContainer onClick={() => setNoteModal({
              ...noteModal,
              show: true,
              header: 'Add a new note',
              division: 'create',
            })}>Add note</ButtonContainer>
          </Col>
        </Row>
        <TableCustom 
          loading={loading}
          items={lead.notes}
          keyField={`id`}
          searchField={false}
          columns={[
            {
              dataField: "note",
              text: "Note",
              sort: true
            },
            {
              dataField: "model",
              text: "Model",
              sort: true,
              formatter: (rowContent, row) => {
                return constants.formatDateShortGB(row.created_at)
              }
            },
            {
              dataField: "",
              text: "Action",
              formatter: (rowContent, row) => {
                return (
                  <>
                  {hasPermission(profilePermissions, 'delete-leads') && deletee(profilePermissions, authUserTeam, lead) &&
                  <img className={'cursor-pointer'}   onClick={() => {
                      setDeleteModal({
                          ...deleteModal,
                          show: true,
                          header: 'Delete lead note.',
                          body: 'Are you sure to delete this note?',
                          onClick: () => deleteNote(row.id)
                      })
                  }}
                           src={require(`assets/images/tableIcons/delete_outline.svg`)} alt=""/> }
                  </>
                )
              }
            }
          ]}
        />
      </CardBody>
      {/* Note Add Modal */}
      <Modal
        isOpen={noteModal.show}
        toggle={() => setNoteModal({
          ...noteModal,
          show: false,
        })}
        className="modal-dialog-centered"
      >
        <div className="modal-header">
          <h6 className="modal-title main-color">{noteModal.header}</h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setNoteModal({
              ...noteModal,
              show: false,
            })}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <InputCustom 
            type={`textarea`}
            newLabel={`Note`}
            rows={5}
            value={selectedNote && selectedNote.note? selectedNote.note: ''}
            onChange={(e) => setSelectedNote({
              ...selectedNote,
              note: e.target.value,
            })}
            invalid={noteErrors && noteErrors.note? true: false}
            errorMessage={noteErrors.note}
          />
        </div>
        <div className="modal-footer justify-content-end">
            <ButtonContainer
                onClick={() => setNoteModal({
                    ...noteModal,
                    show: false,
                })}
            >
                Close
            </ButtonContainer>
          <ButtonContainer
            reverse
            onClick={() => addNote()}
          >
            {`Save note`}
          </ButtonContainer>

        </div>
      </Modal>
      {/* Delete Modal */}
      <Modal
        isOpen={deleteModal.show}
        toggle={() => setDeleteModal({
          ...deleteModal,
          show: false,
        })}
        className="modal-dialog-centered"
      >
        <div className="modal-header">
          <h6 className="modal-title">{deleteModal.header}</h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setDeleteModal({
              ...deleteModal,
              show: false,
            })}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          {deleteModal.body}
        </div>
        <div className="modal-footer justify-content-end">
            <ButtonContainer
                onClick={() => setDeleteModal({
                    ...deleteModal,
                    show: false,
                })}
            >
                Close
            </ButtonContainer>
          <ButtonContainer
              reverse
            onClick={deleteModal.onClick}
          >
            {`Delete`}
          </ButtonContainer>
        </div>
      </Modal>
    </Card>
  );
}