import { put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from './actions';
import axios from '../../config/axios';
import * as constants from '../../constants';

function* getLeads(action) {
  try {
    const response = yield axios.get(`${constants.API_URL}/leads?`, {
      params: {
        ...action.data,
      },
    });
    if (response?.status === 200) {
      const {
        data: { data },
      } = response;
      const { authUserTeam, filter, leads, leadsStatuses, leadsTypes } = data;

      yield put({
        type: actionTypes.GET_LEADS_SUCCESS,
        authUserTeam,
        filter,
        leads,
        leadsStatuses,
        leadsTypes,
      });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.GET_LEADS_ERROR, payload });
  }
}

function* deleteLead(action) {
  try {
    const response = yield axios.delete(
      `${constants.API_URL}/leads/${action.uuid}`
    );
    if (response?.status === 200) {
      const { data } = response.data;
      yield put({ type: actionTypes.DELETE_LEAD_SUCCESS, data });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.DELETE_LEAD_ERROR, payload });
  }
}

function* markWonLead(action) {
  try {
    const response = yield axios.get(
      `${constants.API_URL}/leads/${action.id}/process`
    );
    if (response?.status === 200) {
      const { data } = response.data;
      yield put({ type: actionTypes.WON_LEAD_SUCCESS, data });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.WON_LEAD_ERROR, payload });
  }
}

function* markLostLead(action) {
  try {
    const response = yield axios.get(
      `${constants.API_URL}/leads/${action.id}/lost`
    );
    if (response?.status === 200) {
      const { data } = response.data;
      yield put({ type: actionTypes.LOST_LEAD_SUCCESS, data });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.LOST_LEAD_ERROR, payload });
  }
}

export default function* watcherSaga() {
  yield takeLatest(actionTypes.GET_LEADS_REQUEST, getLeads);
  yield takeLatest(actionTypes.DELETE_LEAD_REQUEST, deleteLead);
  yield takeLatest(actionTypes.WON_LEAD_REQUEST, markWonLead);
  yield takeLatest(actionTypes.LOST_LEAD_REQUEST, markLostLead);
}
