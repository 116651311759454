import React, {useEffect, useRef, useState} from 'react'
import styled from "styled-components";
import {
    Button,
    Col, Row
} from 'reactstrap'
import {
    CustomFilter,
    InputArraySideSelect,
    SelectCards,
    SelectWithoutInput,
    JobTaskCard
} from "../../../components/InputSelect";
import CustomSearch from "../../../components/CustomSearch";
import {formatDate, propertiesSumInArray} from "../../../../../constants";
import {
    Text,
    ReportContainer,
    TaskDetailsContainer,
    TextContainer, StatusWrapper, CalendarInput
} from '../../../reusable-components/styled-components'
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "react-modern-calendar-datepicker";
import moment from 'moment'
import Board, {moveCard} from '@asseinfo/react-kanban'
import Cookies from "js-cookie";
import axios from "axios";
import * as constants from "../../../../../constants";
import {useSelector} from "react-redux";
import FilterComponent from "@pages/fleet/pages/components/FilterComponent";
import DynamicTable from "@pages/components/DynamicTable";
import {useHistory} from "react-router-dom";


const ButtonContainer = styled.div`
  background-color: ${props => props.reverse ? '#EC6409' : 'white'};
  margin-right: 20px;
  width: 162px;
  height: 40px;
  border-radius: 4px;
  justify-content: center;
  display: flex;
  align-items: center;
  border: 1px solid ${props => props.reverse ? 'white' : '#EC6409'};
  gap: 10px;
  color: ${props => props.reverse ? 'white' : '#EC6409'};
  float: right;
  cursor: pointer;
`


const FilterContainer = styled.div`
 display: flex;
 flex-direction:row;
 justify-content:${props => props.isAdmin && 'space-around'};
 width: ${props => props.width}; 
`
const StatusContainer = styled.div`
 display: flex;
 align-items: center;
 flex-direction:row;
 border-radius: 10px 10px 10px 0px;
 background-color: #f8f8f9;
 justify-content: space-around;
 height: 50px;
 cursor: pointer;
 margin-bottom: 15px;
`
const TasksContainer = styled.div`
    height: calc(${props => props.height})
`
export default function TasksList({
                                      priorities,
                                      showMake = false,
                                      statuses,
                                      disableSaving = false,
                                      types = [],
                                      className = '',
                                      jobBooker = false,
                                      adminTableData=[],
                                      setData,
                                      keys=[],
                                      selectCar = false,
                                      disableTaskDetails = true,
                                      openSelectCar,
                                      setShowCreateNew,
                                      isAdmin = false,
                                      vehicle,
                                      tasks,
                                      saveCosts,
                                      selectDate,
                                      showChecklist, date,
                                      searchTasks = false,
                                      showVehicle = true,
                                      customFilter = false,
                                      assignees = [],
                                      departments = [],
                                      regNos = [],
                                      showDateFilter = true,
                                      showCarFilter,
                                      makeValue = null,
                                      modelValue = null,
                                      selectMake,
                                      selectModel,
                                      selectPriority,
                                      selectDepartment,
                                      priority,
                                      makes = [],
                                      models = [],
                                      showCreateNew,
                                      valueKey,
                                      labelKey,
                                      setSearchByName,
                                      selectDriver,
                                      submit,
                                      drivers = [],
                                      taskType,
                                      selectTaskType,
                                      showAddTaskComponent = false,
                                  }
) {
    const [selectedDay, setSelectedDay] = useState(null);
    const [initialData, setInitialData] = useState({});
    const [costs, setCosts] = useState([]);
    const history = useHistory();
    const [isWorker, setIsWorker] = useState(false)
    const [loading, setLoading] = useState(true);
    const roles = useSelector(state => state.getState).roles;

    useEffect(() => {
        groupTasksByStatus(tasks)
    }, [tasks])
    useEffect(() => {
        setIsWorker(roles.includes('Fleet'))
    }, [roles])
    const groupTasksByStatus = (tasks) => {
        let tempData = {columns: []}
        statuses.map((item, index) => {
            tempData.columns.push({
                id: index,
                title: item.text,
                value: item.value,
                cards: tasks.filter(task => task.status === item.value)
            })
        })
        setInitialData(tempData)
        setLoading(false)
    }

    const renderCustomInput = ({ref}) => (
        <CalendarInput
            readOnly
            ref={ref} // necessary
            placeholder="Select date"
            value={selectedDay ? moment({...selectedDay, month: selectedDay.month - 1,}).format('DD/MMM/YYYY') : ''}
        />
    )
    const createTask = () => {
        setShowCreateNew(true)
    }

    const searchByTaskName = (e) => {
        setSearchByName(e.target.value.toLowerCase())
    }
    const updateDeliveryTaskStatus = async (id, status) => {
        const grs_token = Cookies.get('grs_token')

        const source = axios.CancelToken.source()

        const response = await axios({
            method: 'POST',
            url: `${constants.FLEET_URL}/job-booker/delivery-task/${id}/status`,
            headers: {
                Authorization: `Bearer ${grs_token}`,
            },
            data: {status: status},
            cancelToken: source.token,
        })
        return response
    }
    const updateStatus = async (id, status) => {
        const grs_token = Cookies.get('grs_token')

        const source = axios.CancelToken.source()

        const response = await axios({
            method: 'POST',
            url: `${constants.FLEET_URL}/fleet-management/task/${id}/status`,
            headers: {
                Authorization: `Bearer ${grs_token}`,
            },
            data: {status: status},
            cancelToken: source.token,
        })
        return response
    }
    const updateCosts = (data) => {
        setData(data)
    }

    function ControlledBoard() {
        const [controlledBoard, setBoard] = useState(initialData);

        function handleCardMove(_card, source, destination) {
            let updatedBoard = moveCard(controlledBoard, source, destination);
            let status = updatedBoard.columns[destination.toColumnId].value
            if (isWorker) {
                if (status === 'to_do' || status === 'completed') {
                    return
                } else if (_card.status === 'completed') {
                    return;
                }
            }
            if (jobBooker) {
                updateDeliveryTaskStatus(_card.id, status).then(res => {
                    console.log(res.data)
                })
            } else {
                updateStatus(_card.id, status).then(res => {
                    console.log(res.data)
                })
            }


            updatedBoard.columns[destination.toColumnId].cards[destination.toPosition].status =
                status
            setBoard(updatedBoard);
        }

        return (
            <div className={className}>
                <Board
                    onCardDragEnd={handleCardMove}
                    className={'react-kanban-column__large'}
                    renderCard={(data) => {
                        return (
                            jobBooker ?
                                <JobTaskCard
                                    key={data.id}
                                    showMake={true}
                                    alwaysOpen={true}
                                    details={disableTaskDetails}
                                    item={data}
                                /> : <SelectCards
                                    key={data.id}
                                    showMake={showMake}
                                    alwaysOpen={true}
                                    details={disableTaskDetails}
                                    item={data}/>
                        )
                    }}
                    renderColumnHeader={(data) => {
                        return (
                            <StatusContainer>
                                <i className="ni ni-up rotate-down mr-1"/>
                                <StatusWrapper status={data.value}>{data.title}</StatusWrapper>
                                <Text size='12px'>{data.cards.length}</Text>
                            </StatusContainer>
                        )
                    }}
                >
                    {controlledBoard}
                </Board>
            </div>


        );
    }

    //
    return (
        !loading &&
        <>
            <div style={{backgroundColor: 'white', minHeight: 'calc(100vh - 128px)'}} className='pd-20'>
                {jobBooker ? <FilterComponent models={models} selectDate={selectDate} selectMake={selectMake}
                                              selectDriver={selectDriver}
                                              drivers={drivers}
                                              types={types}
                                              taskType={taskType}
                                              selectTaskType={selectTaskType}
                                              showCreateNew={showCreateNew}
                                              selectModel={selectModel} makes={makes} loading={loading}/> : <>
                    {
                        showVehicle && <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: "wrap"}}>
                            <ReportContainer marginBottom>
                                <img width={'219px'} height={'165px'}
                                     src={vehicle.image}
                                     alt=""/>
                                <TaskDetailsContainer>
                                    <TextContainer>
                                        <Text>No.</Text>
                                        <img height='25px' width='25px'
                                             src={require(`assets/images/taskEdit/road_grey.png`)}
                                             alt=""/>
                                        <img height='25px' width='25px'
                                             src={require(`assets/images/taskEdit/date_grey.png`)}
                                             alt=""/>
                                    </TextContainer>
                                    <TextContainer>
                                        <Text bold>Reg. Num.:</Text>
                                        <Text bold>Mileage:</Text>
                                        <Text bold>Created Date:</Text>
                                    </TextContainer>
                                    <TextContainer>
                                        <Text>{vehicle.stock_no}</Text>
                                        <Text>{vehicle.mileage}</Text>
                                        <Text>{formatDate(date)}</Text>
                                    </TextContainer>

                                </TaskDetailsContainer>
                            </ReportContainer>
                            {isAdmin && <TaskDetailsContainer>

                                <ButtonContainer onClick={() => selectCar ? openSelectCar(true) : createTask()}>
                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                    New Task
                                </ButtonContainer>
                                <ButtonContainer reverse onClick={() => showChecklist(true)}>
                                    <img height='25px' width='25px' src={require(`assets/images/sticky_note_2.svg`)}
                                         alt=""/>
                                    <span>PDI Checklist</span>
                                </ButtonContainer>
                            </TaskDetailsContainer>}
                        </div>
                    }

                    {!isAdmin && <FilterContainer>


                        <div className={'mr-3 w-25'}>
                            <CustomSearch
                                onChange={searchByTaskName}
                                placeholder={'Search'}
                            />
                        </div>
                        {
                            !searchTasks && departments?.length && isAdmin ?
                                <Col md={2} className='align-self-center'>
                                    <SelectWithoutInput
                                        options={departments}
                                        label={'Department'}
                                        onSelect={selectDepartment}
                                        valueKey={`value`}
                                        labelKey={`text`}
                                        defaultOption={true}
                                        imageLink={'group-p.png'}
                                    />
                                </Col> : <></>
                        }
                        <FilterContainer width={showCarFilter ? '55%' : customFilter ? '55%' : '40%'} isAdmin={true}>

                            {
                                !searchTasks ? <>

                                        {showCarFilter &&
                                        <>
                                            <div className='align-self-center'>
                                                <SelectWithoutInput
                                                    label={makeValue ? makeValue : `Make`}
                                                    onSelect={e => {
                                                        selectMake(e)
                                                    }}
                                                    options={makes}
                                                    defaultOption={true}
                                                    valueKey={valueKey}
                                                    labelKey={labelKey}
                                                    imageLink={'directions_car_grey.png'}
                                                />
                                            </div>
                                            <div className='align-self-center'>
                                                <SelectWithoutInput
                                                    label={modelValue ? modelValue : `Model`}
                                                    onSelect={e => {
                                                        selectModel(e)
                                                    }}
                                                    options={models}
                                                    defaultOption={true}
                                                    valueKey={valueKey}
                                                    labelKey={labelKey}
                                                    imageLink={'loyalty.png'}
                                                />
                                            </div>
                                        </>
                                        }
                                        <div className='align-self-center'>
                                            <SelectWithoutInput
                                                label={priority ? priority : `Priority`}
                                                options={priorities}
                                                defaultOption={true}
                                                onSelect={e => {
                                                    selectPriority(e)
                                                }}
                                                valueKey={`value`}
                                                labelKey={`label`}
                                                imageLink={'label_important_outline.png'}
                                            />
                                        </div>
                                    </> :
                                    <>  {showDateFilter &&
                                    <Col className='align-self-center' md={2}>
                                        <DatePicker
                                            value={selectedDay}
                                            onChange={(e) => {
                                                selectDate(moment({...e, month: e.month - 1,}).format('YYYY-MM-DD'))
                                                setSelectedDay(e)
                                            }}
                                            renderInput={renderCustomInput}
                                            shouldHighlightWeekends
                                        />
                                    </Col>
                                    }
                                    </>
                            }
                            {
                                customFilter &&
                                <div className='align-self-center'>
                                    <CustomFilter
                                        priorities={priorities}
                                        assignees={assignees}
                                        departments={departments}
                                        regNos={regNos}
                                        onChange={submit}
                                    />
                                </div>
                            }

                        </FilterContainer>
                    </FilterContainer>}
                </>}
                {isAdmin ?
                    <>
                        <DynamicTable goToDetails={(id)=>{
                            history.push(`/admin/task-details?id=${id}`)

                        }} updateCosts={updateCosts} disableSaving tableData={adminTableData} showAnyFilter={false} keys={keys}/>

                        <Row className={'justify-content-between p-3 pt-0'}>
                            {adminTableData.length ? <>
                                <h3>
                                    {adminTableData.length } Total Tasks
                                </h3>
                                <h3>
                                   Total cost of jobs {' '} &#163; {' '}{
                                    propertiesSumInArray(adminTableData,'price')?.toFixed(2) || ''

                                    }
                                </h3>
                            </> : null}
                            <Button disabled={disableSaving} onClick={saveCosts} color={'success'}>
                                Save
                            </Button>
                        </Row>
                    </>
                    :
                    <TasksContainer height={tasks.length > 0 ? '' : '100vh - 222px'}>
                    <ControlledBoard/>
                    </TasksContainer>
                }

            </div>
        </>
    )
}