import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';

import CardsHeader from 'layouts/Headers/CardsHeader.js';
import * as constants from 'constants/index.js';

import TableCustom from 'views/pages/components/TableCustom';
import InputCustom from 'views/pages/components/InputCustom';
import InputToggle from 'views/pages/components/InputToggle';
import {
  InputArraySelect,
} from 'views/pages/components/InputSelect';

import {
  Button,
  Container,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Modal,
} from "reactstrap";

import {
  deletee
} from 'views/policies/DealerDealerPolicy';

export default function Dealer() {
  const notificationAlert = useRef(null);
  const history = useHistory();

  const permissions = useSelector(state => state.getState).permissions;
  const authUserTeam = useSelector(state => state.getState).authUserTeam;

  const [token, setToken] = useState('');  
  const [loading, setLoading] = useState(true);
  const [dealers, setDealers] = useState([]);
  const [availableDealerAdminUser, setAvailableDealerAdminUser] = useState([]);
  const [addModal, setAddModal] = useState(false);

  const [identifier, setIdentifier] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [description, setDescription] = useState('');
  const [teamAdmin, setTeamAdmin] = useState(undefined);
  const [isActive, setIsActive] = useState(undefined);

  const [selectedId, setSelectedId] = useState('');
  const [confirmShow, setConfirmShow] = useState(false);
  const [actionType, setActionType] = useState('');

  const [errors, setErrors] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{__html: message}} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/dealer-dealers`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data;
        setDealers(data.data.dealers);
        setAvailableDealerAdminUser(data.data.availableDealerAdminUser);

        setTimeout(() => {
          setLoading(false);
        }, 500);
      } catch (error) {
        setLoading(false);
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const addDealer = () => {
    let body = {
      name: identifier,
      description: description,
      display_name: displayName,
      is_active: isActive,
      user_id: teamAdmin
    };

    axios({
      method: 'POST',
      url: constants.API_URL + '/dealer-dealers',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: body
    })
      .then(response => {
        if (response.status == 200) {
          setAddModal(false);
          let data = response.data;
          console.log('response data :: ', data);
          setDealers(data.data);
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        if (error.response && error.response.status == 422) {
          setErrors(error.response.data.errors);
          return
        }
        setAddModal(false);
        notify('error', 'Error', error.response? error.response.statusText: 'Error occurred.');
      })
  }

  const editDealer = (row) => {
    history.push({
      pathname: '/admin/dealer/' + row.id + '/edit',
      state: {
        dealer: row
      }
    });
  }

  const trigger = (row, action) => {
    setSelectedId(row.id);
    setActionType(action);
    setConfirmShow(true);
  }

  const actionDo = () => {
    if (actionType == 'trash') {
      axios({
        method: 'DELETE',
        url: constants.API_URL + '/dealer-dealers/' + selectedId,
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
        .then(response => {
          if (response.status == 200) {
            let data = response.data;
            console.log('response data :: ', data);
            setDealers(data.data);
            setConfirmShow(false);
            notify('success', 'Success', data.message);
          }
        })
        .catch(error => {
          console.error('error :: ', error);
          notify('error', 'Error', error.response.statusText);
        })
    } else if (actionType == 'delete') {
      axios({
        method: 'DELETE',
        url: constants.API_URL + '/dealer/' + selectedId + '/force-destroy',
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
        .then(response => {
          if (response.status == 200) {
            let data = response.data;
            console.log('response data :: ', data);
            setDealers(data.data);
            setConfirmShow(false);
            notify('success', 'Success', data.message);
          }
        })
        .catch(error => {
          console.error('error :: ', error);
          notify('error', 'Error', error.response.statusText);
        })
    } else if (actionType == 'restore') {
      axios({
        method: 'POST',
        url: constants.API_URL + '/dealer/' + selectedId + '/restore',
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
        .then(response => {
          if (response.status == 200) {
            let data = response.data;
            console.log('response data :: ', data);
            setDealers(data.data);
            setConfirmShow(false);
            notify('success', 'Success', data.message);
          }
        })
        .catch(error => {
          console.error('error :: ', error);
          notify('error', 'Error', error.response.statusText);
        })
    }
  }

  return (
    <>
      <CardsHeader name="Admin" parentName="Dealer Management" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--6 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Dealers</h1>
                    <p className="text-sm mb-0">This section is only for superadministrator. You can manage dealers list.</p>
                  </Col>
                  <Col xs={4} className="text-right">
                    <Button className="w-100" color="success" type="button" onClick={() => setAddModal(true)}>Add new</Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <TableCustom 
                  loading={loading}
                  keyField={`name`}
                  searchField={true}
                  items={dealers}
                  columns={[
                    {
                      dataField: "name",
                      text: "Dealer name",
                      sort: true
                    },
                    {
                      dataField: "created_at_formatted",
                      text: "Join date",
                      sort: true
                    },
                    {
                      dataField: "is_deleted",
                      text: "Is trashed",
                      sort: true
                    },
                    {
                      dataField: "action",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                color=""
                                role="button"
                                size="sm"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow" right>
                                <DropdownItem
                                  onClick={e => editDealer(row)}
                                >
                                  Edit
                                </DropdownItem>
                                {row.is_deleted == 'No' && deletee(permissions, authUserTeam, row) && 
                                <DropdownItem
                                  onClick={e => trigger(row, 'trash')}
                                >
                                  Trash
                                </DropdownItem>}
                                {row.is_deleted == 'Yes' && 
                                <DropdownItem
                                  onClick={e => trigger(row, 'restore')}
                                >
                                  Restore
                                </DropdownItem>}
                                {deletee(permissions, authUserTeam, row) && 
                                <DropdownItem
                                  onClick={e => trigger(row, 'delete')}
                                >
                                  Delete Permanently
                                </DropdownItem>}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </>
                        )
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={addModal}
          toggle={() => setAddModal(false)}
          className="modal-dialog-centered modal-secondary"
        >
          <div className="modal-body">
            <form className="new-event--form">
              <InputCustom 
                label={`Identifier`}
                placeholder={`Identifier`}
                onChange={(e) => setIdentifier(e.target.value)}
                invalid={errors && errors.name? true: false}
                errorMessage={errors.name}
              />
              <InputCustom 
                label={`Display name`}
                placeholder={`Display name`}
                onChange={(e) => setDisplayName(e.target.value)}
                invalid={errors && errors.display_name? true: false}
                errorMessage={errors.display_name}
              />
              <InputCustom 
                type={`textarea`}
                label={`Description`}
                placeholder={`Description`}
                rows={8}
                onChange={(e) => setDescription(e.target.value)}
                invalid={errors && errors.description? true: false}
                errorMessage={errors.description}
              />
              <InputArraySelect 
                label={`Team admin`}
                options={availableDealerAdminUser}
                onChange={(e) => setTeamAdmin(e.target.value)}
                defaultOption={true}
                valueKey={`id`}
                labelKey={`name`}
                invalid={errors && errors.user_id? true: false}
                errorMessage={errors.user_id}
              />
              <InputToggle 
                label={`Is active`}
                checked={isActive == 1? true: false}
                onChange={() => setIsActive(isActive == 1? 0: 1)}
              />
            </form>
          </div>
          <div className="modal-footer">
            <Button
              className="new-event--add"
              color="primary"
              type="button"
              onClick={() => addDealer()}
            >
              Save
            </Button>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setAddModal(false)}
            >
              Cancel
            </Button>
          </div>
        </Modal>
        <Modal
          isOpen={confirmShow}
          toggle={() => setConfirmShow(false)}
          className="modal-dialog-centered modal-secondary"
        >
          <div className="modal-body">
            {actionType == 'trash' && `Are you sure you want to trash this dealer?`}
            {actionType == 'delete' && `Are you sure you want to delete this dealer permanently?`}
            {actionType == 'restore' && `Are you sure you want to restore this dealer?`}
          </div>
          <div className="modal-footer">
            <Button
              className="new-event--add"
              color={actionType == 'trash' || actionType == 'delete'? 'danger': 'primary'}
              type="button"
              onClick={() => actionDo()}
            >
              {actionType == 'trash' && `Trash`}
              {actionType == 'delete' && `Delete permanently`}
              {actionType == 'restore' && `Restore`}
            </Button>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setConfirmShow(false)}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      </Container>
    </>
  );
}