import React, {useEffect, useState, useRef} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {useSelector} from 'react-redux';
import NotificationAlert from 'react-notification-alert'
import axios from 'axios'
import Cookies from 'js-cookie'

import * as constants from 'constants/index.js'


import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
} from 'reactstrap'

import DynamicTable from "../components/DynamicTable";
import styled from "styled-components";
import {FLEET_URL, formatDate} from "constants/index.js";
import {LoaderContainer, override} from "../reusable-components/styled-components";
import {ClipLoader} from "react-spinners";


export default function Vehicles() {
    const {category} = useParams()
    const notificationAlert = useRef(null)

    const [token, setToken] = useState('')
    const [loading, setLoading] = useState(true)
    const [makesAndModels, setMakesAndModels] = useState('')
    const [models, setModels] = useState([])
    const [makes, setMakes] = useState([])
    const [drivers, setDrivers] = useState([])
    const [modelsCopy, setModelsCopy] = useState([])
    const [boardData, setBoardData] = useState([])
    const [makeValue, setMakeValue] = useState('')
    const [modelValue, setModelValue] = useState('')
    const [taskType, setTaskType] = useState('')
    const [driver, setDriver] = useState('')
    const [filter, setFilter] = useState({})
    const [keys, setKeys] = useState(
        [
            {
                key: 'vehicle',
                text: 'Vehicle'
            },
            {
                key: 'reg_no',
                text: 'Reg.Number'
            },
            {
                key: 'task_type',
                text: 'Task'
            },
            {
                key: 'fleet_title',
                text: 'Fleet Status'
            },
            {
                key: 'status',
                text: 'Deal Status'
            },

            {
                key: 'driver_name',
                text: 'Driver'
            },
            {
                key: 'execution_date',
                text: 'Execution Date'
            },

        ]
    )

    const [types] = useState([
        {
            value: 'delivery',
            label: 'Delivery'
        },
        {
            value: 'collect',
            label: 'Collect'
        },

    ])
    useEffect(() => {
        const grs_token = Cookies.get('grs_token')
        setToken(grs_token)
        const date = formatDate(new Date())
        const getData = async () => {
            await fetchData(grs_token, date)
            const makeRequest = await axios({
                method: 'GET',
                url: `${constants.FLEET_URL}/job-booker/get-makes-models`,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
            let makesTemp = []
            makeRequest.data.data.map(item => {
                if (!makesTemp.find(make => make.id === item.vehicle_make_id)) {
                    makesTemp.push({id: item.vehicle_make_id, name: item.vehicle_make})
                }
            })
            setMakes(makesTemp)
            setModelsCopy(makeRequest.data.data)
            const driversRequest = await axios({
                method: 'GET',
                url: `${constants.FLEET_URL}/job-booker/get-available-drivers`,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
            setDrivers(driversRequest.data.data)
        }
        getData()
    }, [])
    const fetchData = async (token, data = {}) => {
        try {
            const source = axios.CancelToken.source()

            const response = await axios({
                method: 'GET',
                url: `${constants.FLEET_URL}/job-booker/get-vehicles`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: filter,
                cancelToken: source.token,
            })
            const data = response.data
            setLoading(false)
            // setImgSrc(arr => [...arr, reader.result]);
            setBoardData(data.data)
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled :: ', error)
            } else {
                console.error('error :: ', error)
            }
        }
    }

    const filterVehicle = async (data = null) => {
        const source = axios.CancelToken.source()
        await fetchData(source, 1, token, filter, true)
    }
    const setMakeFilter = async (e) => {
        setMakeValue(e.name)
        setModels(modelsCopy.filter(item => item.vehicle_make_id === e.id))
        setModelValue('')
        let tempFilter = filter
        tempFilter.make_id = e.id
        tempFilter.model_id = null
        setFilter(tempFilter)
        await fetchData(token, tempFilter)
    }
    const setModelFilter = async (e) => {
        setModelValue(e.name)
        let tempFilter = filter
        tempFilter.model_id = e.vehicle_model_id
        setFilter(tempFilter)
        await fetchData(token, tempFilter)
    }

    const selectDriver = async (e) => {
        let tempFilter = filter
        setDriver(e.name)
        tempFilter.driver = e.id
        setFilter(tempFilter)
        await fetchData(token, tempFilter)
    }
    const selectTaskType = async (e) => {
        let tempFilter = filter
        setTaskType(e.value)
        tempFilter.type = e.value
        setFilter(tempFilter)
        await fetchData(token, tempFilter)
    }
    const searchByText = async (e) => {
        let tempFilter = filter
        tempFilter['search'] = e.target.value
        setFilter(tempFilter)
        await filterVehicle(tempFilter)
    }
    const clearFilters = async () => {
        setFilter({})
        setTaskType(null)
        setDriver(null)
        setModelValue(null)
        setModels([])
        setMakeValue(null)
        await filterVehicle({})
    }

    return (
        <>
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlert}/>
            </div>
            {loading ?
                <LoaderContainer>
                    <ClipLoader
                        css={override}
                        size={40}
                        color={`#7B61E4`}
                        loading={loading}
                    />
                </LoaderContainer> :
                <Container className="admin-main-body" fluid>
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <DynamicTable
                                        make={makes}
                                        model={models}
                                        showClearButton
                                        clearFilers={clearFilters}
                                        showAnyFilter={false}
                                        showJobBookerFilter={true}
                                        onSearchChange={e => searchByText(e)}
                                        selectMake={setMakeFilter}
                                        selectModel={setModelFilter}
                                        selectDriver={selectDriver}
                                        selectTaskType={selectTaskType}
                                        driverValue={driver ? driver : ''}
                                        goToDetails={()=>{}}
                                        makeValue={makeValue ? makeValue : ''}
                                        modelValue={modelValue ? modelValue : ''}
                                        tableData={boardData}
                                        keys={keys}
                                        drivers={drivers}
                                        taskType={taskType}
                                        types={types}/>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            }
        </>
    )
}