import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import NotificationAlert from 'react-notification-alert'
import axios from 'axios'
import Cookies from 'js-cookie'

import * as constants from 'constants/index.js'
import CardsHeader from 'layouts/Headers/CardsHeader.js'

import InputCustom from 'views/pages/components/InputCustom'
import {
  InputArraySelect,
} from 'views/pages/components/InputSelect'
import TableCustom from 'views/pages/components/TableCustom'

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
} from 'reactstrap'

import {
  view,
  create,
  update,
  deletee,
  hasPermission,
} from 'views/policies/ResourcePolicy'

export default function Resource() {
  const { slug } = useParams()
  const notificationAlert = useRef(null)
  const history = useHistory()

  const permissions = useSelector(state => state.getState).permissions

  const [token, setToken] = useState('')
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([])
  const [category, setCategory] = useState('')
  const [availableCategories, setAvailableCategories] = useState([])

  const [resource, setResource] = useState('')
  const [resourceModal, setResourceModal] = useState({
    show: false,
    header: '',
    division: '',
  })
  const [showModal, setShowModal] = useState({
    show: false,
    header: '',
  })
  const [errors, setErrors] = useState('')
  const [deleteModal, setDeleteModal] = useState({
    show: false,
    header: '',
    body: '',
    onClick: null,
  })

  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    }
    notificationAlert.current.notificationAlert(options)
  }

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/resource-categories/${slug}/docs`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data
        setCategory(data.data.category)
        setItems(data.data.items)
        setAvailableCategories(data.data.availableCategories)

        setLoading(false)
      } catch (error) {
        setLoading(false)
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const createResource = async () => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${constants.API_URL}/resource-categories/${slug}/docs`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: resource
      })
      console.log('response :: ', response.data)
      const data = response.data
      setItems(data.data.items)
      notify('success', 'Success', data.message)

      setResourceModal({
        show: false
      })
    } catch (error) {
      console.error('error :: ', error)
      if (error.response.status === 422) {
        setErrors(error.response.data.errors)
        return
      }
      setResourceModal({
        show: false
      })
      notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
    }
  }

  const updateResource = async () => {
    try {
      const response = await axios({
        method: 'PUT',
        url: `${constants.API_URL}/resource-categories/${slug}/docs/${resource.id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: resource
      })
      console.log('response :: ', response.data)
      const data = response.data
      setItems(data.data.items)
      notify('success', 'Success', data.message)

      setResourceModal({
        show: false
      })
    } catch (error) {
      console.error('error :: ', error)
      if (error.response.status === 422) {
        setErrors(error.response.data.errors)
        return
      }
      setResourceModal({
        show: false
      })
      notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
    }
  }

  const deleteResource = async (row) => {
    try {
      const response = await axios({
        method: 'DELETE',
        url: `${constants.API_URL}/resource-categories/${slug}/docs/${row.id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: resource
      })
      console.log('response :: ', response.data)
      const data = response.data
      setItems(data.data.items)
      notify('success', 'Success', data.message)

      setDeleteModal({
        show: false
      })
    } catch (error) {
      console.error('error :: ', error)
      setDeleteModal({
        show: false
      })
      notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
    }
  }

  const download = async (row) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/resource-categories/${slug}/docs/${row.id}/download`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      })
      console.log('response :: ', response.data)
      const data = response.data
      const disposition = response.request.getResponseHeader('Content-Disposition')
      let fileName = ""
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      const matches = filenameRegex.exec(disposition)
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '')
      }
      const blob = new Blob([data])
      const downloadUrl = URL.createObjectURL(blob)
      let a = document.createElement("a")
      a.href = downloadUrl
      a.download = fileName
      document.body.appendChild(a)
      a.click()
    } catch (error) {
      console.error('error :: ', error)
      notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
    }
  }

  return (
    <>
      <CardsHeader name="Resources" parentName="Resource Management" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">{category && category.resource_type && `${category.resource_type.name} - ${category.name}`}</h1>
                  </Col>
                  <Col xs={4} className="text-right">
                    {create(permissions) &&
                      <Button className="w-100" color="success" type="button" onClick={() => {
                        setResource({
                          ...resource,
                          resource_category_id: category? category.id: ''
                        })
                        setErrors('')
                        setResourceModal({
                          ...resourceModal,
                          show: true,
                          header: 'Create a new resource',
                          division: 'create'
                        })
                      }}>Add resource</Button>
                    }
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <TableCustom 
                  loading={loading}
                  items={items}
                  columns={[
                    {
                      dataField: "description",
                      text: "Description"
                    },
                    {
                      dataField: "created_at",
                      text: "Upload Date",
                      formatter: (rowContent, row) => {
                        return constants.formatDateShortGB(row.created_at)
                      }
                    },
                    {
                      dataField: "owner",
                      text: "Upload by",
                      formatter: (rowContent, row) => {
                        return row.owner && row.owner.name? row.owner.name: ''
                      }
                    },
                    {
                      dataField: "",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            {(hasPermission(permissions, 'read-resource') || hasPermission(permissions, 'update-resource') || hasPermission(permissions, 'delete-resource')) &&
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  color=""
                                  role="button"
                                  size="sm"
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-arrow" right>
                                  {row.path && view(permissions) &&
                                    <DropdownItem
                                      onClick={e => download(row)}
                                    >
                                      Download
                                  </DropdownItem>
                                  }
                                  {view(permissions) &&
                                    <DropdownItem
                                      onClick={e => {
                                        setResource(row)
                                        setShowModal({
                                          ...showModal,
                                          show: true,
                                          header: 'Show resource information'
                                        })
                                      }}
                                    >
                                      Show
                                    </DropdownItem>}
                                  {update(permissions, row) &&
                                    <DropdownItem
                                      onClick={e => {
                                        setResource(row)
                                        setErrors('')
                                        setResourceModal({
                                          ...resourceModal,
                                          show: true,
                                          header: 'Edit Resource',
                                          division: 'update'
                                        })
                                      }}
                                    >
                                      Edit
                                    </DropdownItem>}
                                    {deletee(permissions, row) &&
                                    <DropdownItem
                                      onClick={e => {
                                        setDeleteModal({
                                          ...deleteModal,
                                          show: true,
                                          header: 'Delete Resource',
                                          body: 'Are you sure you want to delete this resource?',
                                          onClick: () => deleteResource(row)
                                        })
                                      }}
                                    >
                                      Delete
                                    </DropdownItem>}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            }
                          </>
                        )
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={resourceModal.show}
          toggle={() => setResourceModal({
            ...resourceModal,
            show: false,
          })}
          size={resourceModal.division === 'create'? '': 'lg'}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title">{resourceModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setResourceModal({
                ...resourceModal,
                show: false,
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <Col md={resourceModal.division === 'create'? 12: 6} sm={12}>
                <h6 className="heading-small text-muted mb-4">{`Resource Category information`}</h6>
                <InputArraySelect
                  label={`Resource Category`}
                  options={availableCategories}
                  value={resource && resource.resource_category_id ? resource.resource_category_id : ''}
                  onChange={e => setResource({
                    ...resource,
                    resource_category_id: e.target.value
                  })}
                  defaultOption={true}
                  valueKey={`id`}
                  labelKey={`name`}
                  invalid={errors && errors.resource_category_id ? true : false}
                  errorMessage={errors.resource_category_id}
                />
                <InputCustom
                  label={`Description`}
                  value={resource && resource.description ? resource.description : ''}
                  onChange={e => setResource({
                    ...resource,
                    description: e.target.value
                  })}
                  invalid={errors && errors.description ? true : false}
                  errorMessage={errors.description}
                />
                <InputCustom
                  label={`Url (external url)`}
                  value={resource && resource.url ? resource.url : ''}
                  onChange={e => setResource({
                    ...resource,
                    url: e.target.value
                  })}
                  invalid={errors && errors.url ? true : false}
                  errorMessage={errors.url}
                />
                <Row>
                  <Col md={12}>
                    <h6 className="heading-small text-muted mb-4">{`You can only have one document. Selecting a new document will replace the current one`}</h6>
                    <div className="custom-file">
                      <input
                        className="custom-file-input profile-file-input"
                        type="file"
                        onChange={e => setResource({
                          ...resource,
                          doc: e.target.files[0]
                        })}
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="customFileLang"
                      >
                        File Resource
                  </label>
                    </div>
                  </Col>
                </Row>
              </Col>
              {resourceModal.division === 'update' && 
              <Col md={6} sm={12}>
                <h6 className="heading-small text-muted mb-4">{`Current Options`}</h6>
                {resource.url && 
                <Row>
                  <Col md={3} sm={12}>
                    <small className="text-uppercase text-muted font-weight-bold">{`External URL`}</small>
                  </Col>
                  <Col md={9} sm={12}>
                    <h3 className="heading mb-0">{resource.url}</h3>
                  </Col>
                </Row>}
                {resource.path && 
                <Row>
                  <Col md={3} sm={12}>
                    <small className="text-uppercase text-muted font-weight-bold">{`Document`}</small>
                  </Col>
                  <Col md={9} sm={12}>
                    {resource.file_type && resource.file_path && resource.file_mime_type && resource.file_type === 'video' && 
                      <video width="460" height="320" controls>
                        <source src={`/${resource.file_path}`} type={resource.file_mime_type}>
                          Your browser does not support the video tag.
                        </source>
                      </video>
                    }
                    <h3 className="heading mb-0 cursor-pointer" onClick={() => download(resource)}>{resource.original_name}</h3>
                  </Col>
                </Row>}
              </Col>}
            </Row>
          </div>
          <div className="modal-footer">
            <Button
              className="new-event--add"
              color="primary"
              type="button"
              onClick={() => resourceModal.division === 'create'? createResource() : updateResource()}
            >
              {`Save`}
            </Button>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setResourceModal({
                ...resourceModal,
                show: false,
              })}
            >
              {`Close`}
            </Button>
          </div>
        </Modal>
        <Modal
          isOpen={deleteModal.show}
          toggle={() => setDeleteModal({
            ...deleteModal,
            show: false,
          })}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title">{deleteModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setDeleteModal({
                ...deleteModal,
                show: false,
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {deleteModal.body}
          </div>
          <div className="modal-footer">
            <Button
              className="new-event--add"
              color="danger"
              type="button"
              onClick={deleteModal.onClick}
            >
              {`Delete`}
            </Button>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setDeleteModal({
                ...resourceModal,
                show: false,
              })}
            >
              {`Close`}
            </Button>
          </div>
        </Modal>
        <Modal
          isOpen={showModal.show}
          toggle={() => setShowModal({
            ...showModal,
            show: false,
          })}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title">{showModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setShowModal({
                ...showModal,
                show: false,
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <h6 className="heading-small text-muted mb-4">{`Current Options`}</h6>
            <Row>
              <Col md={3} sm={12}>
                <small className="text-uppercase text-muted font-weight-bold">{`Description`}</small>
              </Col>
              <Col md={9} sm={12}>
                <h3 className="heading mb-0">{resource.description}</h3>
              </Col>
            </Row>
            {resource.url &&
              <Row>
                <Col md={3} sm={12}>
                  <small className="text-uppercase text-muted font-weight-bold">{`External URL`}</small>
                </Col>
                <Col md={9} sm={12}>
                  <h3 className="heading mb-0">{resource.url}</h3>
                </Col>
              </Row>}
            {resource.path &&
              <Row>
                <Col md={3} sm={12}>
                  <small className="text-uppercase text-muted font-weight-bold">{`Document`}</small>
                </Col>
                <Col md={9} sm={12}>
                  {resource.file_type && resource.file_path && resource.file_mime_type && resource.file_type === 'video' &&
                    <video width="460" height="320" controls>
                      <source src={`/${resource.file_path}`} type={resource.file_mime_type}>
                        Your browser does not support the video tag.
                        </source>
                    </video>
                  }
                  <h3 className="heading mb-0 cursor-pointer" onClick={() => download(resource)}>{resource.original_name}</h3>
                </Col>
              </Row>}
          </div>
          <div className="modal-footer">
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setShowModal({
                ...showModal,
                show: false,
              })}
            >
              {`Close`}
            </Button>
          </div>
        </Modal>
      </Container>
    </>
  )
}