import { put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from './actions';
import axios from '../../config/axios';
import * as constants from '../../constants';
import {requestSuccess} from '../../actions/general.actions';

function* getVehicle(action) {
  try {
    const response = yield axios.get(`${constants.API_URL}/live-vehicles-detail/${action.payload.id}`);
    if (response?.status === 200) {
      const { vehicle, dealStatuses, soldTypes } = response.data.data;
      yield put({ type: actionTypes.LIVE_VEHICLE_EDIT_SUCCESS, vehicle, dealStatuses, soldTypes });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.LIVE_VEHICLE_EDIT_ERROR, payload });
  }
}

function* getVehicleJobs(action) {
  try {
    const response = yield axios.get(`${constants.API_URL}/fleet-management/${action.payload.vehicleId}/jobs`);
    if (response?.status === 200) {
      const { data } = response.data;
      yield put({ type: actionTypes.FLEET_MANAGEMENT_VEHICLE_JOB_SUCCESS, data });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.FLEET_MANAGEMENT_VEHICLE_JOB_ERROR, payload });
  }
}

function* fetchIntervalStats(action) {
  try {
    const response = yield axios.get(`${constants.API_URL}/live-vehicles-stats?min=${action.payload.min}&max=${action.payload.max}`);
    if (response?.status === 200) {
      const { data } = response.data;
      yield put({ type: actionTypes.LIVE_VEHICLE_STATS_SUCCESS, data });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.LIVE_VEHICLE_STATS_ERROR, payload });
  }
}

function* fetchMonthlyTargets(action) {
  try {
    const response = yield axios.get(`${constants.API_URL}/live-vehicles-stats/target?month=${action.payload.month}&year=${action.payload.year}`);
    if (response?.status === 200) {
      const { data } = response.data;
      yield put({ type: actionTypes.MONTHLY_TARGETS_SUCCESS, data });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.MONTHLY_TARGETS_ERROR, payload });
  }
}

function* fetchMonthSummary(action) {
  try {
    const response = yield axios.get(`${constants.API_URL}/live-vehicles-stats/summary?month=${action.payload.month}&year=${action.payload.year}`);
    if (response?.status === 200) {
      const { data } = response.data;
      yield put({ type: actionTypes.FETCH_MONTHLY_SUMMARY_SUCCESS, data });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.FETCH_MONTHLY_SUMMARY_ERROR, payload });
  }
}

function* fetchVehicleReport(action) {
  try {
    const response = yield axios.get(`${constants.API_URL}/live-vehicles-stats/vehicle-report?month=${action.payload.month}&year=${action.payload.year}`);
    if (response?.status === 200) {
      const { data } = response.data;
      yield put({ type: actionTypes.FETCH_VEHICLE_REPORT_SUCCESS, data: data.report });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.FETCH_VEHICLE_REPORT_ERROR, payload });
  }
}

function* saveIntervalStats(action) {
  try {
    const response = yield axios.post(`${constants.API_URL}/live-vehicles-stats`, {
      stats: action.payload.stats
    });
    if (response?.status === 200) {
      const { data } = response.data;
      yield put({ type: actionTypes.LIVE_VEHICLE_SAVE__STATS_SUCCESS, data });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.LIVE_VEHICLE_STATS_ERROR, payload });
  }
}


function* updateVehicle(action) {
  try {
    let data = { ...action.payload.data };
    Object.keys(data).forEach(key => {
      if (data[key] === '-') data[key] = "";
    });
    const response = yield axios.post(`${constants.API_URL}/live-vehicles-detail/${action.payload.vehicleId}`, {
      ...data,
    });

    if (response?.status === 200) {
      const { vehicle } = response.data.data;
      let message = response.data.message;
      yield put({ type: actionTypes.UPDATE_VEHICLE_SUCCESS, vehicle, message });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.UPDATE_VEHICLE_ERROR, payload });
  }
}

function* saveMonthlyTargets(action) {
  try {
    const response = yield axios.post(`${constants.API_URL}/live-vehicles-stats/target`, {
      ...action.payload.inputs,
      month: action.payload.month,
      year: action.payload.year,

    });
    if (response?.status === 200) {
      const { data } = response.data;
      yield put(requestSuccess(action.type, "Successfully saved data"));
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.LIVE_VEHICLE_STATS_ERROR, payload });
  }
}

export default function* watcherSaga() {
  yield takeLatest(actionTypes.LIVE_VEHICLE_EDIT_REQUEST, getVehicle);
  yield takeLatest(actionTypes.FLEET_MANAGEMENT_VEHICLE_JOB_REQUEST, getVehicleJobs);
  yield takeLatest(actionTypes.UPDATE_VEHICLE_REQUEST, updateVehicle);
  yield takeLatest(actionTypes.LIVE_VEHICLE_STATS_REQUEST, fetchIntervalStats);
  yield takeLatest(actionTypes.LIVE_VEHICLE_SAVE_STATS_REQUEST, saveIntervalStats);
  yield takeLatest(actionTypes.MONTHLY_TARGETS_REQUEST, fetchMonthlyTargets);
  yield takeLatest(actionTypes.SAVE_MONTHLY_TARGETS_REQUEST, saveMonthlyTargets);
  yield takeLatest(actionTypes.FETCH_VEHICLE_REPORT_REQUEST, fetchVehicleReport);
  yield takeLatest(actionTypes.FETCH_MONTHLY_SUMMARY_REQUEST, fetchMonthSummary);
}
