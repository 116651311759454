import React, { useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";

import {
  Button,
  Container,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";

import CardsHeader from 'layouts/Headers/CardsHeader.js';

import { DealersContextProvider } from './context';
import ProfileTab from './tabs/ProfileTab';
import DetailsTab from './tabs/DetailsTab';
import ModulesTab from './tabs/ModulesTab';
import FinancialTab from './tabs/FinancialTab';
import FrontendTab from './tabs/FrontendTab';

export default function DealerEdit() {
  const { id } = useParams();
  const notificationAlert = useRef(null);
  const history = useHistory();
  const [tab, setTab] = useState('profile');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{__html: message}} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  return (
    <DealersContextProvider id={id} notify={notify}>
      <CardsHeader name="Admin" parentName="Dealer Management" currentName="Edit Dealer" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--6 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Dealer Management</h1>
                  </Col>
                  <Col xs={4} className="text-right">
                    <Button className="w-100" color="success" type="button" onClick={() => history.push('/admin/dealer')}>Back to list</Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={3} className="d-flex flex-column">
                    <Row>
                      <Button 
                        className={`w-100 ${tab == 'details'? 
                            'selectedButton': 
                            'releasedSelectedButton'
                        }`}
                        onClick={() => setTab('details')}
                      >
                        Dealer Details
                      </Button>
                    </Row>
                    <Row>
                      <Button 
                        className={`w-100 mt-2 ${tab == 'modules'? 
                            'selectedButton': 
                            'releasedSelectedButton'
                        }`}
                        onClick={() => setTab('modules')}
                      >
                        Dealer modules
                      </Button>
                    </Row>
                    <Row>
                      <Button 
                        className={`w-100 mt-2 ${tab == 'profile'? 
                            'selectedButton': 
                            'releasedSelectedButton'
                        }`}
                        onClick={() => setTab('profile')}
                      >
                        Dealer profile
                      </Button>
                    </Row>
                    <Row>
                      <Button 
                        className={`w-100 mt-2 ${tab == 'financial'? 
                            'selectedButton': 
                            'releasedSelectedButton'
                        }`}
                        onClick={() => setTab('financial')}
                      >
                        Dealer Financial
                      </Button>
                    </Row>
                    <Row>
                      <Button 
                        className={`w-100 mt-2 ${tab == 'frontend'? 
                            'selectedButton': 
                            'releasedSelectedButton'
                        }`}
                        onClick={() => setTab('frontend')}
                      >
                        Frontend Management
                      </Button>
                    </Row>
                  </Col>
                  <Col md={9}>
                    {tab === 'profile' && <ProfileTab />}
                    {tab === 'details' && <DetailsTab />}
                    {tab === 'modules' && <ModulesTab />}
                    {tab === 'financial' && <FinancialTab />}
                    {tab === 'frontend' && <FrontendTab />}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </DealersContextProvider>
  );
}