import { TOGGLE_LOADING_SCREEN, SET_CONTENT_LOADING_SCREEN } from 'redux/action-types.js';

const initialState = {
  isOpen: false,
  message: "",
  loaderIsOpen:  [],
};

export function loader(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case TOGGLE_LOADING_SCREEN: {
      const loaderIsOpen = [...(state && state.loaderIsOpen)];

      if (!Array.isArray(payload) && payload && payload.isOpen) loaderIsOpen.push(payload.isOpen);
      else loaderIsOpen.pop();

      return { ...state, ...payload, loaderIsOpen };
    }
    case SET_CONTENT_LOADING_SCREEN: {
      return { ...state, ...payload };
    }
    default:
      return state;
  }
}
