const list = (permissions) => {
  return permissions.includes('read-deals')
}

const create = (permissions, auth_user_team) => {
  if (!auth_user_team) {
    return false
  }

  return permissions.includes('create-deals')
}

const update = (permissions, auth_user_team, deal) => {
  if (auth_user_team && auth_user_team.id != deal.dealer_id) {
    return false
  }
  
  return permissions.includes('update-deals')
}

const deletee = (permissions, auth_user_team, deal, site_code) => {
  if (site_code == 'PaulAu' && auth_user_team) {
    return false
  }

  if (auth_user_team && auth_user_team.id != deal.dealer_id) {
    return false
  }
  
  return permissions.includes('delete-deals')
}

const hasPermission = (permissions, permission) => {
  return permissions.includes(permission)
}

export {
  list,
  create,
  update,
  deletee,
  hasPermission,
};