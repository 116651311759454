import React, {useEffect, useState, useRef} from 'react'
import Pagination from 'react-js-pagination';
import styled from "styled-components";
import {css} from '@emotion/core';
import {ClipLoader} from 'react-spinners';

import {
    CustomSort, SelectWithoutInput
} from 'views/pages/components/InputSelect'

import {
    Container,
    Row,
    Col,
    Card, Modal, ModalHeader, ModalBody, Button,
} from 'reactstrap'
import CustomSearch from "./CustomSearch";

import {FirstLetterContainer} from '../reusable-components/styled-components'
import {parseToWord} from "../../../constants";
import FilterComponent from "@pages/fleet/pages/components/FilterComponent";
import {Calendar} from "react-multi-date-picker";
import CalendarFilter from "@pages/components/CalendarFilter";

const CustomTd = styled.td`
 color: ${props => props.color} !important;
 font-size: 14px !important;
 font-weight: 600;

`
const CustomContainer = styled.div`
 background-color: ${props => props.color};
 text-align: center;
 display: inline-block;
 padding: 3px 5px;
 border-radius: 3px;
`

const override = css`
  display: block;
  margin: 0 auto;
  border-color: blue;
`;

const ButtonContainer = styled.div`
  background-color: #EC6409;
  margin-right: 20px;
  width: 180px;
  height: 40px;
  display:flex;
  align-items: center;
  padding: 15px 25px;
  border-radius: 5px;
  gap: 10px;
  color: white;
  float: right;
  cursor: pointer;
`
export const CalendarInput = styled.input`
  cursor: pointer;
  text-align: center;
  align-items: center;
  border: 1px solid #F8F8F9;
  gap: 10px;
  font-size: 1rem;
  padding: 10px;
  color: #808087;
  outline: none;
`
const CalendarSelect = styled.div`
 border: 1px solid #e6e6e7;
 justify-content: space-around;
 border-radius: 4px;
 display: flex;
 padding: 10px;
 font-size: 14px;
 cursor: pointer;
 align-items: center;
 gap: 10px;
`
export default function DynamicTable({
                                         valueKey,
                                         showClear = false,
                                         updateCosts,
                                         labelKey,
                                         statuses,
                                         fleetStatuses,
                                         selectedFleetStatus,
                                         showFleetStatus = false,
                                         selectFleetStatus,
                                         showRangeFilter=false,
                                         selectDateRange,
                                         noKeyForFilter = false,
                                         selectedStatus,
                                         creatorName,
                                         makeValue = null,
                                         modelValue = null,
                                         driverValue = null,
                                         showHalf,
                                         selectMake,
                                         selectModel,
                                         pagination,
                                         tableData,
                                         priorities,
                                         priorityValue,
                                         make,
                                         model,
                                         keys,
                                         editEvent,
                                         deleteEvent,
                                         selectPriority,
                                         selectStatus,
                                         showCarFilter = true,
                                         showStatus = false,
                                         showSearchButton = false,
                                         showClearButton = false,
                                         makeSearch,
                                         clearFilers,
                                         selectCreator,
                                         creators = [],
                                         showCreatedByFilter = false,
                                         showWorkerTasksFilter = false,
                                         onChange,
                                         paginate,
                                         noPriority = true,
                                         showButton = false,
                                         addButtonText='Add New',
                                         addNew,
                                         searchPlaceHolder = 'Search',
                                         onSearchChange,
                                         showAlphabet = false,
                                         showDepartment = false,
                                         sortValue,
                                         sortParams,
                                         selectSort,
                                         departments,
                                         showAnyFilter = true,
                                         showJobBookerFilter = false,
                                         drivers = [],
                                         selectDate,
                                         selectDriver,
                                         taskType,
                                         selectTaskType,
                                         types,
                                         searchValue,
                                         goToDetails,
                                         disableDetailsPage = false,
                                         selectDepartment,
                                         filterByDate
                                     }) {
    const [funderId, setFunderId] = useState('')
    const [modal, setModal] = useState(false)
    const [clearInput, setClearInput] = useState(false);
    const [values, setValues] = useState([])
    const [selectedDays, setSelectedDays] = useState(null);
    const toggle = () => setModal((prevState) => !prevState);
    const searchData = (event) => {
        onSearchChange({target: {value: event}})
    }

    const selectFilter = (data) => {
        if (data === 'Clear') {
            return ''
        }
        return data
    }
    const returnFirstLetter = (word) => {
        return word?.charAt(0).toUpperCase();
    }
    const generateBookerItem = (value, key, id) => {
        if (key === 'status') {
            value = parseToWord(value?.toString())
            if (value.toLowerCase() === 'to do') {
                return <CustomTd key={key + '' + id + value} tabIndex="0" color="#4e4e56">
                    <CustomContainer color='#f5f5f5'>
                        {value}
                    </CustomContainer>
                </CustomTd>
            } else if (value.toLowerCase() === 'in progress') {
                return <CustomTd key={key + '' + id} tabIndex="0" color="#ffbb31">
                    <CustomContainer color='#fef7ea'>
                        {value}
                    </CustomContainer>
                </CustomTd>
            } else if (value.toLowerCase() === 'completed') {
                return <CustomTd key={key + '' + id} tabIndex="0" color="#e4f8f0">
                    <CustomContainer color='#247d5f'>
                        {value}
                    </CustomContainer>
                </CustomTd>
            }
        } else if (key === 'workers') {
            let names = <div style={{display: "flex"}}>
                {
                    value.length ? value.map((item) => {
                        return <FirstLetterContainer>{returnFirstLetter(item.name ? item.name : item)}</FirstLetterContainer>
                    }) : ''
                }
            </div>

            return <CustomTd key={key + '' + id} tabIndex="0" color="grey">{names}</CustomTd>
        } else if (key === 'fleet_title') {
            if (value) {
                return <CustomTd key={key + '' + id + value} tabIndex="0" color="#f44336">
                    Needs work
                </CustomTd>
            } else {
                return <CustomTd key={key + '' + id} tabIndex="0" color="#34c759">

                    Ready
                </CustomTd>
            }
        } else if (key === 'reg_no') {
            value = value.toUpperCase()
            return <th key={key + '' + id} tabIndex="0"
                       onClick={() => !disableDetailsPage && goToDetails(id)}>{value}</th>
        }

        value = parseToWord(value?.toString())
        return <th key={key + '' + id} tabIndex="0"
                   onClick={() => !disableDetailsPage && goToDetails(id)}>{value}</th>
    }
    const generateCustomItem = (value, key, id,keyIndex) => {
        if (key === 'status') {
            value = parseToWord(value?.toString())
            if (value.toLowerCase() === 'in review') {
                return <CustomTd tabIndex="0" color="#007aff">
                    <CustomContainer color='rgb(85,214,255,0.2)'>
                        {value}
                    </CustomContainer>
                </CustomTd>
            } else if (value.toLowerCase() === 'to do') {
                return <CustomTd key={key + '' + id + value} tabIndex="0" color="#4e4e56">
                    <CustomContainer color='#f5f5f5'>
                        {value}
                    </CustomContainer>
                </CustomTd>
            } else if (value.toLowerCase() === 'in progress') {
                return <CustomTd key={key + '' + id} tabIndex="0" color="#ffbb31">
                    <CustomContainer color='#fef7ea'>
                        {value}
                    </CustomContainer>
                </CustomTd>
            }
        } else if (key === 'workers') {
            let names = <div style={{display: "flex"}}>
                {
                    value.length ? value.map((item) => {
                        return <FirstLetterContainer>{returnFirstLetter(item.name ? item.name : item)}</FirstLetterContainer>
                    }) : ''
                }
            </div>

            return <CustomTd key={key + '' + id} tabIndex="0" color="grey">{names}</CustomTd>
        } else if (key === 'priority') {
            value = parseToWord(value?.toString())
            if (value.toLowerCase() === 'low') {
                return <CustomTd key={key + '' + id} tabIndex="0" color="#247d5f">
                    <img height='25px' width='25px' src={require(`assets/images/ep_arrow-down.png`)} alt=""/>
                    <span> {value}</span>
                </CustomTd>
            } else if (value.toLowerCase() === 'high') {
                return <CustomTd tabIndex="0" color="#ec6409">
                    <img height='25px' width='25px' src={require(`assets/images/ep_d-arrow-up-double.png`)} alt=""/>
                    <span> {value}</span>
                </CustomTd>
            } else if (value.toLowerCase() === 'medium') {
                return <CustomTd tabIndex="0" color="#ffbb31">
                    <img height='25px' width='25px' src={require(`assets/images/fluent_line-equal.png`)} alt=""/>
                    <span> {value}</span></CustomTd>
            }
        } else if (key === 'reg_no' || key === 'stock_no') {
            return <th key={key + '' + id} tabIndex="0"
                       onClick={() => !disableDetailsPage && goToDetails(id)}>{value?.toUpperCase()}</th>
        } else if (key === 'actions') {
            return <th className={'cursor-pointer'}>
                <img onClick={()=>editEvent(id)} src={require(`assets/images/tableIcons/edit.svg`)} alt=""/>
                <img onClick={()=>deleteEvent(id)} src={require(`assets/images/tableIcons/delete_outline.svg`)} alt=""/>
            </th>
        } else if (key === 'toDetails') {
            return <th  style={{padding: '5px'}}> <Button outline color="success" onClick={() => !disableDetailsPage && goToDetails(id)}>View</Button></th>
        } else if (key === 'price') {
            if (keys[keyIndex].hasOwnProperty('input')) {
                return <th style={{padding: '5px'}}>
                    <div className={'table-input-container'}>
                     &#163; <input type="number" step="0.01" value={value ? value : ''} onChange={(e)=>{
                        updateCosts({
                            id: id,
                            price: e.target.value
                        })
                     }
                    } className={'table-input'}/>
                </div></th>
            }
        } else if (key === 'owner') {
          return  <th key={key + '' + id} tabIndex="0" >{value?.name}</th>
        }
        value = parseToWord(value?.toString())
        return <th key={key + '' + id} tabIndex="0" onClick={() => !disableDetailsPage && goToDetails(id)}>{value}</th>
    }
    return (
        <>
            <Modal className={'custom-styles'} isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}/>
                <ModalBody>
                    <div className={'d-flex justify-content-center mb-3'}>
                        <div>
                            <span>From</span>
                            <CalendarInput placeholder="Filter by date"
                                           value={values?.length ? values[0].format('DD MMM YYYY') : ''}/>
                        </div>
                        <div><span>To</span>

                            <CalendarInput placeholder="Filter by date"
                                           value={values?.length > 1 ? values[values.length - 1].format('DD MMM YYYY') : ''}/>
                        </div>

                    </div>

                    <div className={'d-flex justify-content-center mb-3'}>
                        <Calendar
                            className={'custom-calendar'}
                            value={values}
                            onChange={(e) => {
                                setValues(e)
                            }}
                            range
                            numberOfMonths={2}
                            showOtherDays
                        />
                    </div>
                    <Button outline={true} style={{
                        backgroundColor: '#ec6409',
                        color: 'white',
                        border: '1px solid #ec6409',
                        float: 'right',
                        marginRight: '15px'
                    }} disabled={values.length < 2} onClick={() => {
                        setSelectedDays([values[0].format('YYYY MMM DD'), values[values.length - 1].format('DD MMM YYYY')])
                        filterByDate({
                            from: values[0].format('YYYY-MM-DD'),
                            to: values[values.length - 1].format('YYYY-MM-DD')
                        })
                        toggle()
                    }}
                    >Save</Button>

                </ModalBody>
            </Modal>
            <Card>
                {showAnyFilter &&
                <Row className='pd-20'>
                    <Col >
                        <CustomSearch
                            clear={clearInput}
                            setClear={()=>setClearInput(false)}
                            defaultValue={searchValue}
                            onChange={(e) => searchData(e.target.value)}
                            placeholder={searchPlaceHolder}
                        />
                    </Col>
                    {showDepartment &&
                    <Col >
                        <SelectWithoutInput
                            showClear={showClear}
                            label={funderId ? funderId : `Department`}
                            onSelect={e => {
                                setFunderId(selectFilter(e))
                                selectDepartment(e)
                            }}
                            labelKey={labelKey}
                            valueKey={valueKey}
                            options={departments}
                            defaultOption={true}
                            imageLink={'group-p.png'}
                        />
                    </Col>
                    }
                    {
                        showRangeFilter && <CalendarFilter filterByDate={(data)=>{
                            selectDateRange(data)
                        }}/>
                    }
                    {showAlphabet &&
                    <Col >
                        <CustomSort imageLink={'sort.png'} label={sortValue === 0 ? 'A - Z' : 'Z - A'}
                                    options={sortParams} value={sortValue} onSelect={selectSort}/>
                    </Col>
                    }

                    {noPriority &&
                    <Col className='align-self-center' md={2}>
                        <SelectWithoutInput
                            showClear={showClear}
                            label={priorityValue ? priorityValue : `Priority`}
                            onSelect={e => {
                                setFunderId(selectFilter(e))
                                selectPriority(e)
                                // fetchData(axios.CancelToken.source(), 1, token, e.target.value)
                            }}
                            options={priorities}
                            defaultOption={true}
                            valueKey={`value`}
                            labelKey={`label`}
                            imageLink={'label_important_outline.png'}
                        />
                    </Col>
                    }
                    {showCarFilter &&
                    <>
                        <Col className='align-self-center' md={2}>
                            <SelectWithoutInput
                                showClear={showClear}
                                label={makeValue ? makeValue : `Make`}
                                onSelect={e => {
                                    selectMake(e)
                                }}
                                options={make}
                                defaultOption={true}
                                valueKey={valueKey}
                                labelKey={labelKey}
                                imageLink={'directions_car_grey.png'}
                            />
                        </Col>
                        <Col className='align-self-center' md={2}>
                            <SelectWithoutInput
                                showClear={showClear}
                                label={modelValue ? modelValue : `Model`}
                                onSelect={e => {
                                    selectModel(e)
                                }}
                                options={model}
                                defaultOption={true}
                                valueKey={valueKey}
                                labelKey={labelKey}
                                imageLink={'loyalty.png'}
                            />
                        </Col>
                        {
                            showFleetStatus &&
                            <Col className='align-self-center' md={2}>
                            <SelectWithoutInput
                                showClear={showClear}
                                label={selectedFleetStatus ? selectedFleetStatus : `Fleet Status`}
                                onSelect={selectFleetStatus}
                                options={fleetStatuses}
                                noKeyForFilter={noKeyForFilter}
                                defaultOption={true}
                                valueKey={`value`}
                                labelKey={`text`}
                                imageLink={'loyalty.png'}
                            />
                            </Col>
                        }

                    </>
                    }
                    <div className={'d-flex phone-dropdown'}
                         style={{gap: showCreatedByFilter && '40px', marginLeft: showCreatedByFilter && '20px'}}>


                        {
                            showCreatedByFilter &&
                            <SelectWithoutInput
                                showClear={showClear}
                                label={creatorName ? creatorName : `Created By`}
                                onSelect={e => {
                                    selectCreator(e)
                                }}
                                options={creators}
                                imageLink={'loyalty.png'}
                            />

                        }
                        {
                            showStatus &&
                            <SelectWithoutInput
                                showClear={showClear}
                                label={selectedStatus ? selectedStatus : `Status`}
                                onSelect={e => {
                                    selectStatus(e)
                                }}
                                options={statuses}
                                noKeyForFilter={noKeyForFilter}
                                defaultOption={true}
                                valueKey={noKeyForFilter ? null : `value`}
                                labelKey={noKeyForFilter ? null : `text`}
                                imageLink={'loyalty.png'}
                            />
                        }

                    </div>
                    {
                        showClearButton &&
                        <Col className={'d-flex align-items-center ml-3 mr-3'}>
                            <Button className="w-75" color="success" type="button"
                                    onClick={() => {
                                        clearFilers()
                                        setClearInput(true)
                                    }}>Clear</Button>
                        </Col>
                    }
                    {
                        showSearchButton &&
                        <div className={'d-flex align-items-center ml-5'}>
                            <Button className="w-100" color="warning" type="button"
                                    onClick={() => makeSearch()}>Filter</Button>
                        </div>
                    }
                    {
                        showButton && <Col className='align-self-center'>
                            <ButtonContainer onClick={addNew}>
                                <i className="fa fa-plus" aria-hidden="true"></i>
                                {addButtonText}
                            </ButtonContainer>
                        </Col>
                    }

                </Row>

                }
                {
                    showWorkerTasksFilter &&
                    <Row className='pd-20'>
                        <CalendarSelect>
                            <img onClick={toggle} width='20px' height='20px'
                                 src={require("assets/images/date_range.png")}
                                 alt=""/>
                            <span
                                onClick={toggle}>{selectedDays?.length ? selectedDays[0] + ' - ' + selectedDays[1] : 'Filter by date'}</span>
                                  {selectedDays?.length ? <i className="fa fa-times" onClick={(e) => {
                                    e.preventDefault()
                                    setSelectedDays([])
                                    setValues([])
                                    filterByDate({
                                        from: '',
                                        to: ''
                                    })
                                }}
                                />
                                :
                                <></>
                            }
                            <i onClick={toggle} className="ni ni-up rotate-down"/>
                        </CalendarSelect>
                    </Row>
                }
                {showJobBookerFilter && <FilterComponent models={model}
                                                         selectDate={selectDate}
                                                         selectMake={selectMake}
                                                         modelValue={modelValue}
                                                         makeValue={makeValue}
                                                         showHalf={showHalf}
                                                         driverValue={driverValue}
                                                         selectDriver={selectDriver}
                                                         searchData={(e) => searchData(e.target.value)}
                                                         drivers={drivers}
                                                         isVehicles={true}
                                                         types={types}
                                                         taskType={taskType}
                                                         selectTaskType={selectTaskType}
                                                         selectModel={selectModel} makes={make}/>}
                <div className="table-responsive" style={{maxHeight: 'calc(100vh - 250px)'}}>
                    <div className="react-bootstrap-table">
                        <table className="table">
                            <thead>
                            <tr>
                                {
                                    keys.map((column, index) => {
                                        return <th key={index + ''} tabIndex="0">{column?.text}</th>
                                    })

                                }
                            </tr>
                            </thead>
                            <tbody>
                            {
                                tableData && tableData.length > 0 &&
                                tableData.map((device, index) => {
                                    return <tr key={index}>
                                        {
                                            keys.map((item,keyIndex) => {
                                                return showJobBookerFilter ? generateBookerItem(item.key === 'vehicle' ? device.vehicle_make + ' ' + device.vehicle_model : device[item.key], item.key, device?.id)
                                                    : generateCustomItem(device[item.key], item.key, device?.id,keyIndex)
                                            })
                                        }

                                    </tr>
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
                {pagination && tableData.length > 0 &&
                <Row className="react-bootstrap-table-pagination">
                    <Col lg={6}>
                        <p className="text-sm text-black">
                            {`Showing rows ${(pagination.current_page - 1) * pagination.per_page + 1} to
                                         ${pagination.per_page * pagination.current_page > pagination.total ? pagination.total : pagination.per_page * pagination.current_page} of ${pagination.total}`}
                        </p>
                    </Col>
                    <Col lg={6} className="react-bootstrap-table-pagination-list">
                        <Pagination
                            activePage={pagination.current_page}
                            totalItemsCount={pagination.total}
                            itemsCountPerPage={pagination.per_page}
                            onChange={paginate}
                            itemClass="page-item"
                            linkClass="page-link"
                            innerClass="pagination react-bootstrap-table-page-btns-ul"
                        />
                    </Col>
                </Row>}
            </Card>

        </>
    )
}