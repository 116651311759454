import { TOGGLE_LOADING_SCREEN, SET_CONTENT_LOADING_SCREEN } from 'redux/action-types.js';

export function toggleLoader(isOpen = false) {
  return {
    type: TOGGLE_LOADING_SCREEN,
    payload: { isOpen }
  };
}

export function setLoaderMessage(message = '') {
  return {
    type: SET_CONTENT_LOADING_SCREEN,
    payload: { message }
  };
}
