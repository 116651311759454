import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
  Input
} from 'reactstrap';
import CalendarFilter from "@pages/components/CalendarFilter";
import moment from "moment";
import {ButtonContainer} from "@pages/reusable-components/styled-components";

export default function BusinessActivity() {
  const notificationAlert = useRef(null);
  const history = useHistory();

  const [token, setToken] = useState('');
  const [filter, setFilter] = useState('');
  const [items, setItems]  = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    fetchData(source, grs_token, '')

    return () => {
      source.cancel()
    }
  }, [])

  const fetchData = async (source, token, filter) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/report/deals/business-activity`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: filter,
        cancelToken: source.token,
      })
      console.log('response :: ', response.data)
      const data = response.data

      setFilter({
        ...data.data.filter,
        from: data.data.filter.from ? constants.formatDate(data.data.filter.from) : '',
        to: data.data.filter.to ? constants.formatDate(data.data.filter.to) : '',
      })
      setItems(data.data.items)
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
      }
    }
  }

  const filterStatistics = () => {
    const source = axios.CancelToken.source()
    fetchData(source, token, {
      ...filter,
      from: constants.formatDate(filter.from),
      to: constants.formatDate(filter.to),
    })
  }

  const resetStatistics = () => {
    setFilter('')
    const source = axios.CancelToken.source()
    fetchData(source, token, '')
  }

  const renderTotalData = () => {
    if (items && items.totals) {
      return Object.keys(items.totals).map(key => {
        return (
          <tr key={key}>
            <td scope="col">{key}</td>
            <td scope="col">{items.totals[key].total}</td>
            <td scope="col">{items.totals[key].percent}</td>
          </tr>
        )
      })
    }
  }

  const renderMonthData = () => {
    if (items && items.totals) {
      return Object.keys(items.totals).map(key => {
        return (
          <tbody key={key}>
            <tr>
              <td>{key}</td>
              {items && items.group &&
                Object.keys(items.group).map(key2 => {
                  return Object.keys(items.group[key2]).map(key3 => {
                    return <td key={key3}>{items.group[key2][key3][key]}</td>
                  })
                })
              }
            </tr>
          </tbody>
        )
      })
    }                  
  }

  const renderMonthDataHeader = () => {
    if (items && items.totals) {
      return Object.keys(items.totals).map((key, index) => {
        if (index == 0) {
          return (
            <thead key={key} className="thead-light">
              <tr key={index}>
                <th scope="col">{`Activity Type`}</th>
                {items && items.group &&
                  Object.keys(items.group).map(key2 => {
                    return Object.keys(items.group[key2]).map(key3 => {
                      return <th scope="col" key={key3}>{`${key2} - ${key3}`}</th>
                    })
                  })
                }
              </tr>
            </thead>
          )
        }
      })
    }                  
  }

  return (
    <>
      <CardsHeader name="Sales" parentName="Business Activities Report" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <h1 className="title">Sales Business Activities Statistics</h1>
                <p className="text-sm mb-0">Thorough sales analysis</p>
              </CardHeader>
              <CardBody>
                <Row className={'align-items-center justify-content-between'}>
                  <CalendarFilter initial={true} filterByDate={(data)=>{
                    setFilter({
                      ...filter,
                      ...data
                    })
                  }}/>
                  <Row >
                    <ButtonContainer  onClick={() => resetStatistics()}>Clear</ButtonContainer>
                    <ButtonContainer reverse  onClick={() => filterStatistics()}>Apply</ButtonContainer>
                  </Row>
                </Row>


                <Row className="mt-5">
                  <Col md={12}>
                    <div className="table-responsive">
                      <table className="table align-items-center table-flush">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">{`Activity Type`}</th>
                            <th scope="col">{`Total Count`}</th>
                            <th scope="col">{`Percent from total`}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {renderTotalData()}
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col md={12}>
                    <div className="table-responsive">
                      <table className="table align-items-center table-flush">
                        {renderMonthDataHeader()}
                        {renderMonthData()}
                      </table>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}