import { CalendarInput } from '@pages/reusable-components/styled-components';
import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardBody, Button, Row, Col } from 'reactstrap';
import axios from 'axios'
import Cookies from 'js-cookie'
import { Calendar } from "react-multi-date-picker";
import { CalendarSelect } from '@pages/reusable-components/styled-components';
import * as constants from 'constants/index.js'
import TableCustom from "../../../components/TableCustom";
import moment from 'moment';

function Score({ deal, deliveryDate }) {
    const [values, setValues] = useState([]);
    const [showCalendar, setShowCalendar] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDays, setSelectedDays] = useState(null);
    const [token, setToken] = useState('');
    const [scores, setScores] = useState('');

    const toggle = () => setShowCalendar((prevState) => !prevState);

    useEffect(() => {
        const grs_token = Cookies.get('grs_token')
        setToken(grs_token)
        const source = axios.CancelToken.source();

        return () => {
            source.cancel()
        }
    }, [])

    const fetchData = async (source, filter) => {
        try {
            setIsLoading(true);
            const response = await axios({
                method: 'GET',
                url: `${constants.API_URL}/deal/${deal}/score`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    ...filter
                },
                cancelToken: source.token
            })
            setIsLoading(false);
            const data = response.data
            let items = data.data.scores.map(item => {
                return {
                    ...item,
                    mileage: (item.mileage * 0.621371).toFixed(2)
                }
            })
            setScores(items);
        } catch (error) {
            setIsLoading(false);
            if (axios.isCancel(error)) {
                console.log('Request canceled :: ', error)
            } else {
                console.error('error :: ', error)
                // notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
            }
        }
    }

    return (
        <>
            <CardHeader>
                <h2>Customer score</h2>
            </CardHeader>
            <CardBody>
                {showCalendar ? (<React.Fragment><div className={'d-flex justify-content-center mb-3'}>
                    <div>
                        <span>From</span>
                        <CalendarInput placeholder="Filter by date"
                            onChange={() => { }}
                            value={values && values?.length ? values[0].format('DD MMM YYYY') : ''}
                        />
                    </div>
                    <div>
                        <span>To</span>
                        <CalendarInput placeholder="Filter by date"
                            onChange={() => { }}
                            value={values && values?.length > 1 ? values[values.length - 1].format('DD MMM YYYY') : ''}
                        />
                    </div>
                </div>
                    <div className={'d-flex justify-content-center mb-3'}>
                        <Calendar
                            className={'custom-calendar'}
                            value={values}
                            onChange={(e) => {
                                setValues(e)
                            }}
                            range
                            numberOfMonths={2}
                            showOtherDays
                            maxDate={new Date()}
                            minDate={moment(deliveryDate).toDate()}
                        />
                    </div>
                    <Button outline={true} style={{
                        backgroundColor: '#ec6409',
                        color: 'white',
                        border: '1px solid #ec6409',
                        float: 'right',
                        marginRight: '15px'
                    }} disabled={values.length < 2} onClick={() => {
                        setSelectedDays([values[0].format('YYYY MMM DD'), values[values.length - 1].format('DD MMM YYYY')])
                        toggle()
                    }}
                    >Save</Button>
                </React.Fragment>) :
                    <CalendarSelect>
                        <img onClick={toggle} width='20px' height='20px' src={require("assets/images/date_range.png")}
                            alt="" />
                        <span
                            onClick={toggle}>{selectedDays?.length ? selectedDays[0] + ' - ' + selectedDays[1] : 'Filter by date'}</span>
                        {selectedDays?.length ? <i className="fa fa-times" onClick={(e) => {
                            e.preventDefault()
                            setSelectedDays([])
                            setValues([])
                        }} /> : <></>}
                        <i onClick={toggle} className="ni ni-up rotate-down" />
                        <Button outline={true} style={{
                            backgroundColor: '#ec6409',
                            color: 'white',
                            border: '1px solid #ec6409',
                            float: 'right',
                            marginRight: '15px'
                        }} disabled={isLoading || values.length < 2} onClick={() => {
                            const source = axios.CancelToken.source();
                            fetchData(source, {
                                from: values[0].format('YYYY-MM-DD'),
                                to: values[1].format('YYYY-MM-DD')
                            });
                        }}
                        >Filter</Button>
                    </CalendarSelect>
                }
            </CardBody>
            {scores && <React.Fragment>
                <Row>
                    <Col>
                        <p>Total score is: {(scores.reduce((prev, curr) => {
                            return prev + curr.score;
                        }, 0
                        ) / scores.length).toFixed(2)}</p>
                    </Col>
                </Row>
                <TableCustom
                    loading={isLoading}
                    items={scores}
                    columns={[
                        {
                            dataField: "date",
                            text: "Date"
                        },
                        {
                            dataField: "score",
                            text: "Score"
                        },
                        {
                            dataField: "harsh_acceleration_events",
                            text: "Harsh Acceleration"
                        },
                        {
                            dataField: "harsh_braking_events",
                            text: "Harsh Braking"
                        },
                        {
                            dataField: "harsh_cornering_events",
                            text: "Harsh Cornering"
                        },
                        {
                            dataField: "overspeeding_events",
                            text: "Overspeeding"
                        },
                        {
                            dataField: "mileage",
                            text: "Mileage"
                        },
                    ]}
                />
            </React.Fragment>}
        </>
    );
}

export default React.memo(Score);