import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {
    Col, Row,
} from "reactstrap";
import {Text} from '../reusable-components/styled-components'
import {parseToWord} from "../../../constants";

const Container = styled.div`
 border: 1px solid #e6e6e7;
 border-radius: 4px;
 padding: 20px;
 margin-bottom: 25px;
 cursor: ${props =>props.pointer && 'pointer'}
`
const RadioButtonWrapper = styled.div`
  border: 1px solid #e6e6e7;
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;
`
const RadioButton = styled.div`
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  width: 15px;
  height: 15px;
  background-color: ${props => props.selected && '#ec6409'}
`

export default function PdiChecklist({option,setChecklist}) {

    const [fields, setFields] = useState([])
    const [isOpen, setIsOpen] = useState(true)

    const selectField = (index, id, value) => {
        let data = [...fields];
        data[index].is_passing = value
        data[index].id = id
        setChecklist(data)
        setFields([...data])
    }
    useEffect(() => {
        setFields([...option.options])
    }, [])


    return (
        <div style={{marginTop: '10px'}}>
      <Container pointer>
          <Row onClick={() => setIsOpen(!isOpen)}>
              <Col md={3}>
                  <Text bold={true}>{option.name}</Text>
              </Col>

              <Col md={9}>
                  <img
                      style={{float: 'right'}}
                      height={'17px'}
                      width={'17px'}
                      src={require("assets/images/arrow_forward_ios.png")}
                  />
              </Col>
          </Row>
      </Container>
            {
                isOpen &&
            <Container>
                {
                fields.map((item, index) => {
                    return (
                        <>
                            <Row style={{height: '55px', alignContent: 'center'}}>

                                <Col md={1} onClick={() => selectField(index, item.id, !item.is_passing)}>
                                    <RadioButtonWrapper>
                                        <RadioButton

                                            selected={item.is_passing === true}
                                        >
                                            {
                                                item.is_passing === true &&
                                                <img  src={require(`assets/images/taskEdit/checked.svg`)} alt=""/>

                                            }
                                        </RadioButton>
                                    </RadioButtonWrapper>
                                </Col>

                                <Col md={8}>
                                    <Text>{parseToWord(item.name)}</Text>
                                </Col>

                            </Row>
                        </>
                    )
                })
                }

            </Container>
            }

        </div>

    )
}