import React, { useContext } from 'react';

import { DealContext } from '../context';

import InfoTab from 'views/pages/deals/tabs/InfoTab'
import DocumentTab from 'views/pages/deals/tabs/DocumentTab'
import UploadTab from 'views/pages/deals/tabs/UploadTab'
import NoteTab from 'views/pages/deals/tabs/NoteTab'
import TaskTab from 'views/pages/deals/tabs/TaskTab'

export default function Content() {
  const dealContext = useContext(DealContext);
  const {
    tab
  } = dealContext;
  return (
    <>
      {tab == 'info' && <InfoTab />}
      {tab == 'documents' && <DocumentTab />}
      {tab == 'uploads' && <UploadTab />}
      {tab == 'notes' && <NoteTab />}
      {tab == 'tasks' && <TaskTab />}
    </>
  );
}