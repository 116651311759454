/**
 * Login
 */
export const LOGIN            = "LOGIN";
export const SET_LOGIN_DATA   = 'SET_LOGIN_DATA';

/**
 * Logout
 */
export const EMPTY_STATE      = 'EMPTY_STATE';
export const SET_EMPTY_STATE  = 'SET_EMPTY_STATE';

/**
 * Profile
 */
export const GET_PROFILE_DATA     = 'GET_PROFILE_DATA';
export const GET_MODULES          = 'GET_MODULES';
export const SET_PROFILE_DATA     = 'SET_PROFILE_DATA';
export const SET_ROLES_DATA       = 'SET_ROLES_DATA';
export const SET_PERMISSIONS_DATA = 'SET_PERMISSIONS_DATA';
export const SET_AUTH_USER_TEAM   = 'SET_AUTH_USER_TEAM';
export const SET_ROUTE_CAN        = 'SET_ROUTE_CAN';
export const SET_CSRF_TOKEN       = 'SET_CSRF_TOKEN';

export const SET_MENUS_LIST = 'SET_MENUS_LIST'
export const SET_ACTIVE_MENU = 'SET_ACTIVE_MENU'
export const SET_IS_SUBMENU_ACTIVE = 'SET_IS_SUBMENU_ACTIVE'

/**
 * Notifications
 */
export const GET_NOTIFICATIONS         = 'GET_NOTIFICATIONS';
export const SET_ACTION_NOTIFICATIONS  = 'SET_ACTION_NOTIFICATIONS';
export const SET_GENERAL_NOTIFICATIONS = 'SET_GENERAL_NOTIFICATIONS';

/**
 * Modules
 */
 export const REQUEST_SAVE_KASHFLOW_LOGINS  = 'REQUEST_SAVE_KASHFLOW_LOGINS';


 /**
  * General
  */
 export const REQUEST_SUCCEEDED = 'REQUEST_SUCCEEDED';
 export const REQUEST_SUCCESS = 'REQUEST_SUCCESS';

 
/* Used in loader */
export const TOGGLE_LOADING_SCREEN = 'TOGGLE_LOADING_SCREEN';
export const SET_CONTENT_LOADING_SCREEN = 'SET_CONTENT_LOADING_SCREEN';
export const SET_CATEGORIES       = 'SET_CATEGORIES';
