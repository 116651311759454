import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import TableCustom from 'views/pages/components/TableCustom';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
  Input
} from 'reactstrap';
import CalendarFilter from "@pages/components/CalendarFilter";
import {ButtonContainer} from "@pages/reusable-components/styled-components";

export default function VehiclesCounty() {
  const notificationAlert = useRef(null);
  const history = useHistory();

  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('');
  const [dealers, setDealers] = useState([]);
  const [items, setItems] = useState([]);

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    fetchData(source, grs_token, '')

    return () => {
      source.cancel()
    }
  }, [])

  const fetchData = async (source, token, filter) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/report/vehicles/county`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: filter,
        cancelToken: source.token,
      })
      console.log('response :: ', response.data)
      const data = response.data
      
      setFilter({
        ...data.data.filter,
        from: data.data.filter.from ? constants.formatDate(data.data.filter.from) : '',
        to: data.data.filter.to ? constants.formatDate(data.data.filter.to) : '',
      })
      setItems(data.data.items)
      setDealers(data.data.dealers)

      setLoading(false)
    } catch (error) {
      setLoading(false)
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
      }
    }
  }

  const filterStatistics = () => {
    const source = axios.CancelToken.source()
    fetchData(source, token, {
      ...filter,
      from: constants.formatDate(filter.from),
      to: constants.formatDate(filter.to),
    })
  }

  const resetStatistics = () => {
    setFilter('')
    const source = axios.CancelToken.source()
    fetchData(source, token, '')
  }

  return (
    <>
      <CardsHeader name="Sales" parentName="Business Activities Report" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <h1 className="title">Sales Business Activities Statistics</h1>
                <p className="text-sm mb-0">Thorough sales analysis</p>
              </CardHeader>
              <CardBody>
                <Row className={'align-items-center justify-content-between'}>
                  <CalendarFilter initial={true} filterByDate={(data)=>{
                    setFilter({
                      ...filter,
                      ...data
                    })
                  }}/>
                  {filter && filter.isAllowedToFilterByDealer && <Col md={4} sm={12}>
                    <div>
                      <Row className="d-flex align-items-center justify-content-md-center">
                        <Col md={4}>
                          <label className="form-control-label">Offices</label>
                        </Col>
                        <Col md={8}>
                          <Input
                              type="select"
                              value={filter && filter.office? filter.office: ''}
                              onChange={e => setFilter({
                                ...filter,
                                office: e.target.value,
                              })}
                          >
                            <option>All</option>
                            { dealers && dealers.length > 0 &&
                            dealers.map((dealer, index) => {
                              return <option value={dealer.id} key={index}>{dealer.name}</option>
                            })
                            }
                          </Input>
                        </Col>
                      </Row>
                    </div>
                  </Col>}
                  <Row>
                    <ButtonContainer  onClick={() => resetStatistics()}>Clear</ButtonContainer>
                    <ButtonContainer reverse  onClick={() => filterStatistics()}>Apply</ButtonContainer>
                  </Row>
                </Row>


                <Row className="mt-5">
                  <TableCustom 
                    loading={loading}
                    items={items}
                    keyField={`county_id`}
                    searchField={false}
                    columns={[
                      {
                        dataField: "name",
                        text: "County",
                        sort: true
                      },
                      {
                        dataField: "total_vehicle_count",
                        text: "Total Sales",
                        sort: true
                      },
                      {
                        dataField: "total_percent",
                        text: "Percent from total",
                        sort: true
                      }
                    ]}
                  />
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}