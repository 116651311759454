import React from 'react'

import InputCustom from 'views/pages/components/InputCustom'
import {
  InputArraySelect,
} from 'views/pages/components/InputSelect'

import {
  Row,
  Col,
  Button,
  Modal,
} from 'reactstrap'

export default function BookModal(props) {
  const {
    bookModal, setBookModal,
    jobTypes,
    job, setJob,
    errors,
    statusTypes,
    dealerMembers,
    bookJob
  } = props
  return (
    <Modal
      isOpen={bookModal.show}
      toggle={() => setBookModal({
        ...bookModal,
        show: false
      })}
      className="modal-dialog-centered"
    >
      <div className="modal-header">
        <h6 className="modal-title">{bookModal.header}</h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => setBookModal({
            ...bookModal,
            show: false
          })}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Row className="mb-3">
          <Col md={5} sm={12}>
            <b>Type of work: </b>
          </Col>
          <Col md={7} sm={12}>
            {jobTypes && job && job.job_type_id ? jobTypes[job.job_type_id - 1].name : ''}
          </Col>
        </Row>
        <InputCustom
          type={`textarea`}
          rows={5}
          label={`Work Needed`}
          value={job && job.description ? job.description : ''}
          onChange={e => setJob({
            ...job,
            description: e.target.value
          })}
          invalid={errors && errors.description ? true : false}
          errorMessage={errors.description}
        />
        <InputCustom
          type={`number`}
          label={`ETA Hours`}
          value={job && job.eta_hours ? job.eta_hours : ''}
          onChange={e => setJob({
            ...job,
            eta_hours: e.target.value
          })}
          invalid={errors && errors.eta_hours ? true : false}
          errorMessage={errors.eta_hours}
        />
        <InputCustom
          type={`datetime-local`}
          label={`Estimated completion date`}
          value={job && job.estimated_completion_at ? job.estimated_completion_at : ''}
          onChange={e => setJob({
            ...job,
            estimated_completion_at: e.target.value
          })}
          invalid={errors && errors.estimated_completion_at ? true : false}
          errorMessage={errors.estimated_completion_at}
        />
        <InputArraySelect
          label={`Status`}
          options={statusTypes}
          value={job ? job.status : ''}
          onChange={e => setJob({
            ...job,
            status: e.target.value
          })}
          defaultOption={true}
          valueKey={`id`}
          labelKey={`name`}
          invalid={errors && errors.status ? true : false}
          errorMessage={errors.status}
        />
        <InputCustom
          type={`datetime-local`}
          label={`Start date`}
          value={job && job.start_date ? job.start_date : ''}
          onChange={e => {
            setJob({
              ...job,
              start_date: e.target.value
            })
          }}
          invalid={errors && errors.start_date ? true : false}
          errorMessage={errors.start_date}
        />
        {(job.status == 1 || job.status == 2) &&
          <InputArraySelect
            label={`Assignee`}
            options={dealerMembers}
            value={job && job.booked_by ? job.booked_by : ''}
            onChange={e => {
              setJob({
                ...job,
                booked_by: e.target.value
              })
            }}
            defaultOption={true}
            valueKey={`id`}
            labelKey={`name`}
            invalid={errors && errors.booked_by ? true : false}
            errorMessage={errors.booked_by}
          />}
      </div>
      <div className="modal-footer">
        <Button
          className="new-event--add"
          color="primary"
          type="button"
          onClick={bookJob}
        >
          {`Save`}
        </Button>
        <Button
          className="ml-auto"
          color="link"
          type="button"
          onClick={() => setBookModal({
            ...bookModal,
            show: false
          })}
        >
          {`Close`}
        </Button>
      </div>
    </Modal>
  )
}