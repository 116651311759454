import React, { useContext } from 'react';
import { Editor } from '@tinymce/tinymce-react';

import * as constants from 'constants/index.js'

import { DealersContext } from '../context';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
  FormGroup,
} from "reactstrap";

export default function FrontendTab() {
  const dealersContext = useContext(DealersContext);
  const {
    dealerLanding,
    setDealerLanding,
    updateFront,
  } = dealersContext;
  return (
    <Card>
      <CardHeader>
        <h2 className="title">Frontend Layout Management</h2>
      </CardHeader>
      <CardBody>
        <FormGroup>
          <label
            className="form-control-label"
            htmlFor="example3cols2Input"
          >
            About us description
                          </label>
          <Editor
            value={dealerLanding.description ? dealerLanding.description : ''}
            apiKey={constants.TINYMCE_KEY}
            init={{
              height: 500,
              menubar: true,
              plugins: ['code', 'preview', 'pagebreak', 'image'],
              toolbar:
                'undo redo | formatselect | bold italic | \
                                alignleft aligncenter alignright alignjustify | \
                                outdent indent'
            }}
            onEditorChange={(content, editor) => setDealerLanding({
              ...dealerLanding,
              description: content
            })}
          />
        </FormGroup>
        <FormGroup>
          <label
            className="form-control-label"
            htmlFor="example3cols2Input"
          >
            <h2>Testimonials</h2>
            <span>Please write every testimonial link comma separated</span>
          </label>
          <Input
            id="example-text-input"
            type="textarea"
            rows="4"
            value={dealerLanding.testimonials ? dealerLanding.testimonials : ''}
            onChange={(e) => setDealerLanding({
              ...dealerLanding,
              testimonials: e.target.value
            })}
          />
        </FormGroup>
        <div className="d-flex flex-row justify-content-center">
          <Button className="w-100" color="success" onClick={() => updateFront()}>Save</Button>
        </div>
      </CardBody>
    </Card>
  );
}