import React, { useRef } from 'react';

import CardsHeader from 'layouts/Headers/CardsHeader.js';
import NotificationAlert from "react-notification-alert";

import {
  Container,
  Alert,
  Col,
} from "reactstrap";

export default function Supplier() {
  const notificationAlert = useRef(null);

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  return (
    <>
      <CardsHeader name="Admin" parentName="Suppliers" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt-2 admin-main-body" fluid >
        <Col md={4}>
          <Alert color="info">
            <strong>Info!</strong> This page was deprecated!
          </Alert>
        </Col>
      </Container>
    </>
  )
}