import React, { useRef, useState, useEffect } from 'react';
import { getXeroCustomer, saveXeroCustomer, syncKashflowCustomer } from 'api/modules.api';

import {
    Row,
    Col,
    Button
} from "reactstrap";

import InputCustom from '@pages/components/InputCustom';
import NotificationAlert from "react-notification-alert";

import Spinner from './../../Spinner';

export default function XeroPaymentSettings({ deal }) {
    const [customerCode, setCustomerCode] = useState('');
    const [customer, setCustomer] = useState({});
    const [systemCustomer, setSystemCustomer] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const notificationAlert = useRef(null);
    const notify = (type, header, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
                    <span className="alert-title" data-notify="title">
                        {" "}
                        {header}
                    </span>
                    <span data-notify="message">
                        <div dangerouslySetInnerHTML={{ __html: message }} />
                    </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5
        };
        notificationAlert.current.notificationAlert(options);
    };

    useEffect(() => {
        if (deal.customer_id) {
            fetchXeroCustomer(deal.customer_id)
        }
    }, [deal.customer_id]);

    const createCustomer = () => {
        saveXeroCustomer(deal.customer_id, customer).then(response => {
            fetchXeroCustomer(deal.customer_id);
            notify('success', 'Success', response.message);
        })
    }

    const syncCustomer = () => {
        syncKashflowCustomer(deal.customer_id, customerCode).then(response => {
            fetchXeroCustomer(deal.customer_id);
            notify('success', 'Success', response.message);
        }).catch((error) => {
            notify('error', 'Error', error.response.data.message);
            console.log(error.response.data.message);
        });
    }

    const fetchXeroCustomer = (customerId) => {
        setIsLoading(true);
        getXeroCustomer(customerId).then(response => {
            setCustomer(response.data.xeroCustomer)
            setSystemCustomer(response.data.systemCustomer)
            setIsLoading(false);
        })
    }

    return (
        <Row className="position-relative">
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlert} />
            </div>
            {isLoading ? <Spinner animation="border" /> :
                <div className='w-100 d-flex flex-wrap align-items-center'>
                    <h2>Customer Settings!</h2>
                    {customer?.contact_id ?
                        <p className='w-100 text-center'><strong>Details below are pulled from XERO customer configuration.</strong></p>
                        : <p className='w-100 text-center'><strong>This customer does not exist in your XERO list yet!</strong></p>
                    }
                    <Col md="12">
                        <InputCustom
                            type={`text`}
                            label={`Contact Name`}
                            value={customer?.name ?? ""}
                            onChange={(e) =>
                                setCustomer({
                                    ...customer,
                                    name: e.target.value,
                                })
                            }
                        />
                    </Col>
                    <Col md="6">
                        <InputCustom
                            type={`text`}
                            label={`First Name`}
                            value={customer?.first_name ?? ""}
                            onChange={(e) =>
                                setCustomer({
                                    ...customer,
                                    first_name: e.target.value,
                                })
                            }
                        />
                    </Col>
                    <Col md="6">
                        <InputCustom
                            type={`text`}
                            label={`Last name`}
                            value={customer?.last_name ?? ""}
                            onChange={(e) =>
                                setCustomer({
                                    ...customer,
                                    last_name: e.target.value,
                                })
                            }
                        />
                    </Col>
                    <Col md="12">
                        <InputCustom
                            type={`email`}
                            label={`Email`}
                            value={customer?.email_address ?? ""}
                            onChange={(e) =>
                                setCustomer({
                                    ...customer,
                                    email_address: e.target.value,
                                })
                            }
                        />
                    </Col>
                    {/* <Col md="12">
                        <p className='w-100 text-center'><strong>Other contact persons</strong></p>
                    </Col>
                    <Col md="6">
                        <InputCustom
                            type={`text`}
                            label={`First Name`}
                            value={customer && customer.contact_persons && customer.contact_persons[0] ? customer.contact_persons[0].first_name : ""}
                            onChange={(e) => {

                                let contactPersons = customer.contact_persons;

                                let contactPerson = {
                                    ...customer.contact_persons[0],
                                    first_name: e.target.value
                                };
                                console.log(contactPerson)
                                contactPersons[0] = contactPerson;

                                setCustomer({
                                    ...customer,
                                    contact_persons: contactPersons,
                                })
                            }
                            }
                        />
                    </Col>
                    <Col md="6">
                        <InputCustom
                            type={`text`}
                            label={`Last Name`}
                            value={customer && customer.contact_persons && customer.contact_persons[0] ? customer.contact_persons[0].last_name : ""}
                            onChange={(e) => {
                                let contactPersons = customer.contact_persons;

                                let contactPerson = {
                                    ...customer.contact_persons[0],
                                    last_name: e.target.value
                                };
                                console.log(contactPerson)
                                contactPersons[0] = contactPerson;

                                setCustomer({
                                    ...customer,
                                    contact_persons: contactPersons,
                                })
                            }}
                        />
                    </Col>
                    <Col md="12">
                        <InputCustom
                            type={`email`}
                            label={`Email`}
                            value={customer && customer.contact_persons && customer.contact_persons[0] ? customer.contact_persons[0].email_address : ""}
                            onChange={(e) => {
                                let contactPersons = customer.contact_persons;

                                let contactPerson = {
                                    ...customer.contact_persons[0],
                                    email_address: e.target.value
                                };
                                console.log(contactPerson)
                                contactPersons[0] = contactPerson;

                                setCustomer({
                                    ...customer,
                                    contact_persons: contactPersons,
                                })
                            }}
                        />
                    </Col>
                    <Col md="12">
                        <InputCustom
                            type={`email`}
                            label={`Phone Number`}
                            value={customer?.contact_name ?? ""}
                            onChange={(e) =>
                                setCustomer({
                                    ...deal,
                                    deal_date: e.target.value,
                                })
                            }
                        />
                    </Col> */}

                    <Col md="12 mt-4">
                        <p className='w-100 text-center'><strong>Address Details</strong></p>
                    </Col>
                    <Col md="3">
                        <InputCustom
                            type={`text`}
                            label={`Postal Code`}
                            value={customer?.postal_code ?? ""}
                            onChange={(e) =>
                                setCustomer({
                                    ...customer,
                                    postal_code: e.target.value,
                                })
                            }
                        />
                    </Col>
                    <Col md="9">
                        <InputCustom
                            type={`text`}
                            label={`Building and Street`}
                            value={customer?.address ?? ""}
                            onChange={(e) =>
                                setCustomer({
                                    ...customer,
                                    address: e.target.value,
                                })
                            }
                        />
                    </Col>
                    <Col md="4">
                        <InputCustom
                            type={`text`}
                            label={`City/Town`}
                            value={customer?.city ?? ""}
                            onChange={(e) =>
                                setCustomer({
                                    ...customer,
                                    city: e.target.value,
                                })
                            }
                        />
                    </Col>
                    <Col md="4">
                        <InputCustom
                            type={`text`}
                            label={`Region`}
                            value={customer?.county ?? ""}
                            onChange={(e) =>
                                setCustomer({
                                    ...customer,
                                    county: e.target.value,
                                })
                            }
                        />
                    </Col>
                    <Col md="4">
                        <InputCustom
                            type={`text`}
                            label={`Country`}
                            value={customer?.country ?? ""}
                            onChange={(e) =>
                                setCustomer({
                                    ...customer,
                                    country: e.target.value,
                                })
                            }
                        />
                    </Col>
                    <Button
                        className="w-100"
                        color="success"
                        type="button"
                        onClick={() => createCustomer()}
                    >
                        {(customer?.contact_id ?? null) ? `Update` : `Save`}
                    </Button>
                </div>
            }
        </Row >
    )
}