import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from 'redux/index.js';

import createSagaMiddleware from 'redux-saga';
import rootSaga from './rootSaga';

const initialiseSagaMiddleware = createSagaMiddleware();

// TODO enable dev tool only for development env
const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  storeEnhancers(applyMiddleware(initialiseSagaMiddleware))
);

initialiseSagaMiddleware.run(rootSaga);

export default store;
