import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux';
import NotificationAlert from "react-notification-alert"
import axios from 'axios'
import Cookies from 'js-cookie'

import * as constants from 'constants/index.js'
import CardsHeader from 'layouts/Headers/CardsHeader.js'

import InputCustom from 'views/pages/components/InputCustom'
import InputSideCustom from 'views/pages/components/InputSideCustom'
import InputFile from 'views/pages/components/InputFile'
import {
  InputKeySelect,
  InputArraySelect,
  InputKeySideSelect,
  InputArraySideSelect,
} from 'views/pages/components/InputSelect'
import InputToggle from 'views/pages/components/InputToggle'
import TableCustom from 'views/pages/components/TableCustom'

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'

export default function Invoices() {
  const notificationAlert = useRef(null)
  const history = useHistory()

  const permissions = useSelector(state => state.getState).permissions

  const [token, setToken] = useState('')
  const [loading, setLoading] = useState(true)
  const [filter, setFilter] = useState('')
  const [isAllowedToFilterByDealer, setIsAllowedToFilterByDealer] = useState(false)
  const [items, setItems] = useState('')
  const [types, setTypes] = useState('')
  const [dealers, setDealers] = useState('')

  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    }
    notificationAlert.current.notificationAlert(options)
  }

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()
    fetchData(1, source, grs_token, '')

    return () => {
      source.cancel()
    }
  }, [])

  const fetchData = async (pageNumber, source, token, filter) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/invoices`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ...filter,
          page: pageNumber
        },
        cancelToken: source.token,
      })
      console.log('response :: ', response.data)
      const data = response.data

      setFilter(data.data.filter)
      setIsAllowedToFilterByDealer(data.data.isAllowedToFilterByDealer)
      setItems(data.data.items)
      setTypes(data.data.types)

      if (data.data.isAllowedToFilterByDealer) {
        setDealers(data.data.dealers)
      }

      setTimeout(() => {
        setLoading(false)
      }, 500);
    } catch (error) {
      setLoading(false)
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
      }
    }
  }

  const filterItems = () => {
    const source = axios.CancelToken.source()
    fetchData(1, source, token, {
      ...filter,
      from: constants.formatDate(filter.from),
      to: constants.formatDate(filter.to),
    })
  }

  const resetItems = () => {
    setFilter('')
    const source = axios.CancelToken.source()
    fetchData(1, source, token, '')
  }

  const showInvoice = () => {
    history.push({
      pathname: '/admin/invoice/:id/show'
    })
  }

  return (
    <>
      <CardsHeader name="Invoicing" parentName="Invoices Management" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <h1 className="title">Invoices list</h1>
                <p className="text-sm mb-0">Manage the invoices generated by system.</p>
              </CardHeader>
              <CardBody>
                {isAllowedToFilterByDealer &&
                  <Container>
                    <Row>
                      <Col md={6} sm={12}>
                        <InputKeySideSelect
                          label={`Offices`}
                          options={dealers}
                          value={filter && filter.office ? filter.office : ''}
                          onChange={e => setFilter({
                            ...filter,
                            office: e.target.value
                          })}
                          defaultOption={true}
                        />
                      </Col>
                    </Row>
                    <div className="d-flex flex-row justify-content-center">
                      <Button className="w-50" color="success" onClick={() => filterItems()}>Filter</Button>
                      <Button className="w-50" color="danger" onClick={() => resetItems()}>Reset</Button>
                    </div>
                  </Container>
                }
                <TableCustom 
                  loading={loading}
                  items={items.data}
                  pagination={items}
                  onChange={(pageNumber) => fetchData(pageNumber, axios.CancelToken.source(), token, filter)}
                  columns={[
                    {
                      dataField: "name",
                      text: "Dealer",
                      formatter: (rowContent, row) => {
                        return row.dealer? row.dealer.name: ''
                      }
                    },
                    {
                      dataField: "from",
                      text: "From",
                      formatter: (rowContent, row) => {
                        return constants.formatDateFullGB(row.from)
                      }
                    },
                    {
                      dataField: "to",
                      text: "To",
                      formatter: (rowContent, row) => {
                        return constants.formatDateFullGB(row.to)
                      }
                    },
                    {
                      dataField: "status",
                      text: "Status",
                      formatter: (rowContent, row) => {
                        return types[row.type]
                      }
                    },
                    {
                      dataField: "sub_total_value",
                      text: "Sub Total"
                    },
                    {
                      dataField: "gst_value",
                      text: "GST"
                    },
                    {
                      dataField: "total_value",
                      text: "Total"
                    },
                    {
                      dataField: "",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            {(permissions.includes('read-invoice') || permissions.includes('update-invoice')) && 
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                color=""
                                role="button"
                                size="sm"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow" right>
                                {permissions.includes('read-invoice') && 
                                <DropdownItem
                                  onClick={() => showInvoice(row)}
                                >
                                  Show
                                </DropdownItem>}
                                {permissions.includes('update-invoice') && 
                                <DropdownItem
                                >
                                  Edit
                                </DropdownItem>}
                                {!row.paid_at && 
                                <DropdownItem
                                >
                                  Paid
                                </DropdownItem>}
                                {permissions.includes('read-invoice') && 
                                <DropdownItem
                                >
                                  Download
                                </DropdownItem>}
                                {permissions.includes('delete-invoice') && 
                                <DropdownItem
                                >
                                  Delete
                                </DropdownItem>}
                              </DropdownMenu>
                            </UncontrolledDropdown>}
                          </>
                        )
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}