import React, { useRef, useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory, useParams } from 'react-router-dom';
import { css } from '@emotion/core';
import NotificationAlert from "react-notification-alert";
import CardsHeader from 'layouts/Headers/CardsHeader.js';
import * as actions from '../../../../redux/Deal/actions';
import * as constants from 'constants/index.js';
import InputCustom from 'views/pages/components/InputCustom';
import { InputKeySelect, InputArraySelect } from 'views/pages/components/InputSelect';
import axios from 'axios';
import TableCustom from 'views/pages/components/TableCustom';
import { saveCustomInvoicePayment } from 'api/payments.api';

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  CardHeader,
  FormGroup
} from "reactstrap";
import { AddNote } from './modals/AddNote';
import { ClipLoader } from 'react-spinners';

export default function PaymentDetails() {
  const { id, scheduleId } = useParams();
  const history = useHistory();

  const { salesAccounts, ...dealState } = useSelector((state) => state.dealState, shallowEqual);
  const modules = useSelector((state) => state.getState).modules.modules || [];
  const modulesType = useSelector((state) => state.getState).modules.types || [];
  const dispatch = useDispatch();

  const [deal, setDeal] = useState('');
  const [selectedPayment, setSelectedPayment] = useState('');
  const [paymentStatusPaidOut, setPaymentStatusPaidOut] = useState('');
  const [paymentStatusFailed, setPaymentStatusFailed] = useState('');
  const [paymentStatusNew, setPaymentStatusNew] = useState('');
  const [paymentStatusCreated, setPaymentStatusCreated] = useState('');
  const [processTypeDirectDebit, setProcessTypeDirectDebit] = useState('');
  const [paymentOnlineProcessLink, setPaymentOnlineProcessLink] = useState(false);

  const [addInvoicePaymentModal, setAddInvoicePaymentModal] = useState(false);
  const [customPaymentElement, setCustomPaymentElement] = useState({})
  const [customPaymentElementError, setCustomPaymentElementError] = useState(null)
  const [paymentMethods, setPaymentMethods] = useState('');
  const [paymentEdit, setPaymentEdit] = useState('');
  const [customPayment, setCustomPayment] = useState('');
  const [errors, setErrors] = useState('');
  const [loading, setLoading] = useState(true);
  const [delay, setDelay] = useState(false);
  const [paymentDetailModal, setPaymentDetailModal] = useState(false);
  const [paymentProcessTypes, setPaymentProcessTypes] = useState('');
  const [payments, setPayments] = useState([]);
  const [actualInvoice, setActualInvoice] = useState(false);
  // note modal
  const [addNoteModal, setAddNoteModal] = useState(false);
  // send email
  const openPaymentLink = (link) => {
    var win = window.open(link, '_blank');
    win.focus();
  }

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: blue;
  `;

  useEffect(() => {
    getSalesAccounts();
  }, [id])

  const getSalesAccounts = () => {
    dispatch({
      type: actions.GET_SALES_ACCOUNTS_REQUEST
    });
  };

  const showInvoice = (row) => {
    let token = 1;
    axios({
      method: 'GET',
      url: constants.API_URL + '/deal/' + id + '/invoice/' + row.id,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then(response => {
        console.log(response)
        setErrors('');
        if (response.status == 200) {
          let data = response.data;
          console.log('response data :: ', data);
          let invoice = data.data.invoice;
          window.open(invoice, "_blank");
        }
      })
      .catch(error => {
        setErrors('');
        console.error('error :: ', error.response);
        if (error.response.status == 422) {
          setErrors(error.response.data.errors);
        }
        notify('warning', 'Warning', error.response.statusText);
      })
  }

  const addCustomInvoicePayment = () => {
    setCustomPaymentElementError(null);
    saveCustomInvoicePayment(deal.id, selectedPayment.id, customPaymentElement)
      .then(response => {
        if (response.data.payment) {
          let data = response.data;
          console.log('response data :: ', data);
          setAddInvoicePaymentModal(false);
          notify('success', 'Success', data.message);
          setCustomPaymentElement({});
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        // notify('warning', 'Warning', error?.response?.data?.message);
        setCustomPaymentElementError(error?.response?.data?.message ?? 'Error occurred during processing of the payment transaction');
      })
  }

  const goDeal = () => {
    history.push({
      pathname: '/admin/deals/' + id + '/edit',
    })
  }

  const goToEditInvoice = () => {
    history.push({
      pathname: `/admin/deals/${id}/payment/${scheduleId}/edit`,
    })
  }

  const goTopayments = () => {
    history.push({
      pathname: `/admin/deals/${id}/payment`,
    })
  }

  const getPaymentRequest = (scheduleId) => {
    dispatch({
      type: actions.GET_PAYMENT_DETAILS_REQUEST,
      id: id,
      scheduleId: scheduleId,
    });
  };

  const sendEmail = () => {
    setLoading(true);
    dispatch({
      type: actions.SEND_PAYMENT_INVOICE_EMAIL_REQUEST,
      payload: {
        scheduleId: selectedPayment.id,
        dealId: deal.id,
      }
    });
  };

  useEffect(() => {
    getPaymentRequest(scheduleId)
  }, [scheduleId, id])

  useEffect(() => {
    if (dealState.paymentDetails) {
      let data = dealState.paymentDetails;
      console.log('response data :: ', data);
      setDeal(data.deal);
      setSelectedPayment(data.item);
      setPaymentProcessTypes(data.paymentProcessTypes);
      setPaymentStatusPaidOut(data.paymentStatusPaidOut);
      setPaymentStatusFailed(data.paymentStatusFailed);
      setPaymentStatusNew(data.paymentStatusNew);
      setPaymentStatusCreated(data.paymentStatusCreated);
      setProcessTypeDirectDebit(data.processTypeDirectDebit);
      setPaymentOnlineProcessLink(data.addOnlinePayment);
      setPaymentMethods(data.paymentMethods);
      setPaymentDetailModal(true);
      setActualInvoice(data.actualInvoice);
      setLoading(false);
      setPaymentEdit({
        ...paymentEdit,
        scheduled_at: data.item && data.item.reference ? constants.formatDate(data.item.reference.charge_date) : constants.formatDate(data.item.scheduled_at),
        amount: data.item && data.item.reference ? data.item.reference.amount : data.item.amount,
        process_type: data.item && data.item.reference ? data.item.reference.process_type : '',
        payment_reference_id: data.item ? data.item.id : '',
        selected_provider: data.item && data.item.reference ? data.item.reference.selected_provider : '',
        collect_now: 0,
      })
    }
  }, [dealState.paymentDetails])

  useEffect(() => {
    if (dealState.paymentInvoiceEmail === true) {
      notify('success', 'Success', "Email succesfully sent!");
    } else if (dealState.paymentInvoiceEmail === false) {
      notify('warning', 'Warning', "Error sending the email!");
    }

    setLoading(false);
  }, [dealState.paymentInvoiceEmail]);


  const notificationAlert = useRef(null);
  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  const savePayment = () => {
    axios({
      method: 'POST',
      url: constants.API_URL + '/deal/' + id + '/save-reference',

      data: paymentEdit,
    })
      .then(response => {
        if (response.status == 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setPaymentEdit('');
          setSelectedPayment('');
          setPaymentDetailModal(false);
          setPayments(data.data);
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        if (error.response && error.response.status == 422) {
          let errors = error.response.data.errors;
          let err = '';
          Object.keys(errors).map(key => {
            err += errors[key];
          })
        } else if (error.response && error.response.status == 406) {
          let errors = error.response.data.errors;
          let err = errors.message;
        }
      })
  }

  return (
    <>
      <CardsHeader name="Deal Management" parentName="Deals Management" currentName="Create" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <div>
                    <h1 className="title mb-0">{`Payment Details`}, {selectedPayment.payment_descriptor}</h1>
                  </div>
                  <div className="h-50">
                    <Button className="h-50" color="success" type="button" onClick={() => goDeal()}>Deal</Button>
                    <Button className="h-50" color="success" type="button" onClick={() => goTopayments()}>Payments</Button>
                  </div>
                </div>
              </CardHeader>

              {actualInvoice ?
                <CardBody>
                  <Row>
                    <Col md={9}>
                      <h2>{actualInvoice.invoice_number} - <span className="text-danger">{actualInvoice.status}</span></h2>
                    </Col>
                    <Col md={3}>
                      <Button
                        color="success"
                        type="button"
                        onClick={() => goToEditInvoice()}
                      >
                        {`Edit invoice`}
                      </Button>
                    </Col>
                    <Col md={3}>
                      <Button
                        className="new-event--add mt-2"
                        color="primary"
                        type="button"
                        onClick={() => setAddInvoicePaymentModal(true)}
                      >
                        {`Add new payment`}
                      </Button>
                    </Col>
                    <Col md={3}>
                      <Button
                        className="new-event--add mt-2"
                        color="primary"
                        type="button"
                        onClick={() => showInvoice(selectedPayment)}
                      >
                        {`Download Invoice`}
                      </Button>
                    </Col>
                    <Col md={3}>
                      <Button
                        className="new-event--add mt-2"
                        color="primary"
                        type="button"
                        onClick={() => setAddNoteModal(true)}
                      >
                        {`Add note`}
                      </Button>
                    </Col>
                    {['PAID', 'SUBMITTED', 'AUTHORISED'].includes(actualInvoice.status) &&
                      <Col md={3}>
                        <Button
                          className="new-event--add mt-2"
                          color="primary"
                          disabled={loading}
                          type="button"
                          onClick={() => sendEmail()}
                        >
                          {`Email`}
                        </Button>
                      </Col>
                    }
                  </Row>
                  <Row>
                    <ClipLoader
                      css={override}
                      size={40}
                      color={`#7B61E4`}
                      loading={loading}
                    />
                    <TableCustom
                      loading={false}
                      delay={true}
                      items={actualInvoice.line_items}
                      searchField={false}
                      columns={[
                        {
                          dataField: "item_code",
                          text: "Code"
                        },
                        {
                          dataField: "item_name",
                          text: "Name"
                        },
                        {
                          dataField: "description",
                          text: "Description"
                        },
                        {
                          dataField: "unit_amount",
                          text: "Amount"
                        },
                        {
                          dataField: "quantity",
                          text: "Qty"
                        },
                        {
                          dataField: "tax_amount",
                          text: "Tax"
                        },
                        {
                          dataField: "line_amount",
                          text: "Total"
                        }
                      ]}
                    />
                  </Row>
                  <Row className="py-3">
                    <Col md={3} sm={6}>
                      <h2>Sub Total</h2>
                    </Col>
                    <Col md={3} sm={6}>
                      <h3 className="text-danger">
                        {actualInvoice.sub_total}
                      </h3>
                    </Col>
                  </Row>
                  <Row className="py-3">
                    <Col md={3}>
                      <h2>Total Taxes</h2>
                    </Col>
                    <Col md={9}>
                      <h3 className="text-danger">
                        {actualInvoice.total_tax}
                      </h3>
                    </Col>
                  </Row>
                  <Row className="py-3">
                    <Col md={3}>
                      <h2>Total</h2>
                    </Col>
                    <Col md={9}>
                      <h3 className="text-danger">
                        {actualInvoice.total}
                      </h3>
                    </Col>
                  </Row>
                  <Row className="py-3">
                    <Col md={3}>
                      <h2>Payment Due</h2>
                    </Col>
                    <Col md={9}>
                      <h3 className="text-danger">
                        {constants.formatDate(actualInvoice.due_date)}
                      </h3>
                    </Col>
                  </Row>
                  <Row className="py-3">
                    <Col md={3} sm={6}>
                      <h2>Amount Paid</h2>
                    </Col>
                    <Col md={3} sm={6}>
                      <h3 className="text-danger">
                        {actualInvoice.amount_paid}
                      </h3>
                    </Col>
                  </Row>
                  <Row className="py-3">
                    <Col md={3} sm={6}>
                      <h2>Amount Due</h2>
                    </Col>
                    <Col md={3} sm={6}>
                      <h3 className="text-danger">
                        {actualInvoice.amount_due}
                      </h3>
                    </Col>
                  </Row>
                </CardBody>
                :

                <CardBody>
                  <Row className="py-3">
                    <Col md={3}>
                      <h2>Payment Due</h2>
                    </Col>
                    <Col md={9}>
                      <h3 className="text-danger">
                        {constants.formatDate(selectedPayment.scheduled_at)}
                      </h3>
                    </Col>
                  </Row>
                  <Row className="py-3">
                    <Col md={3}>
                      <h2>Amount to be charged</h2>
                    </Col>
                    <Col md={9}>
                      <h3 className="text-danger">
                        {paymentEdit.amount}
                      </h3>
                    </Col>
                  </Row>
                </CardBody>
              }
              {actualInvoice === null ?
                <CardBody>
                  {selectedPayment && selectedPayment.reference && selectedPayment.reference.process_type == processTypeDirectDebit && <Row>
                    <Col sm={3}>
                      <small className="text-uppercase font-weight-bold">Payment Reference</small>
                    </Col>
                    <Col sm={9}>
                      <h3 className="heading mb-0">{selectedPayment.reference.payment_gateway_reference_id}</h3>
                    </Col>
                  </Row>}
                  {selectedPayment && selectedPayment.reference && selectedPayment.reference.payment_status == paymentStatusFailed && <Row>
                    <Col sm={12}>
                      <h3 className="text-danger heading mb-0">{`Payment failed! You can take future actions by retrying the payment!`}</h3>
                    </Col>
                  </Row>}
                  <InputCustom
                    type={`date`}
                    label={`Charge At`}
                    value={paymentEdit && paymentEdit.scheduled_at ? paymentEdit.scheduled_at : ''}
                    onChange={(e) => setPaymentEdit({
                      ...paymentEdit,
                      scheduled_at: e.target.value,
                    })}
                  />
                  <InputCustom
                    type={`number`}
                    label={`Amount`}
                    value={paymentEdit && paymentEdit.amount ? paymentEdit.amount : ''}
                    onChange={(e) => setPaymentEdit({
                      ...paymentEdit,
                      amount: e.target.value,
                    })}
                  />

                  <>
                    <InputArraySelect
                      label={`Module`}
                      options={modules.filter(item => item.module_type == 'accounting')}
                      value={paymentEdit && paymentEdit.selected_provider ? paymentEdit.selected_provider : ''}
                      valueKey={`provider`}
                      labelKey={`provider`}
                      onChange={(e) =>
                        setPaymentEdit({
                          ...paymentEdit,
                          selected_provider: e.target.value
                        })
                      }
                      defaultOption={true}
                      invalid={errors && errors['customer.company.business_activity_id'] ? true : false}
                      errorMessage={errors['customer.company.business_activity_id']}
                    />
                    <FormGroup>
                      <label className="form-control-label">{`Submit invoice now`}</label>
                      <div>
                        <label className="custom-toggle">
                          <input
                            type="checkbox"
                            checked={paymentEdit.generate_now == 1 ? true : false}
                            onChange={() =>
                              setPaymentEdit({
                                ...paymentEdit,
                                generate_now: paymentEdit.generate_now == 1 ? 0 : 1
                              })
                            }
                          />
                          <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="No"
                            data-label-on="Yes"
                          />
                        </label>
                      </div>
                    </FormGroup>
                  </>

                  {selectedPayment.invoice && paymentEdit.process_type == 'invoice' && paymentOnlineProcessLink &&
                    <Row className="mt-2">
                      <Col md={12}>
                        <i
                          className="fas fa-credit-card"
                          style={{ cursor: 'pointer', marginLeft: "10px" }}
                          onClick={() => openPaymentLink(paymentOnlineProcessLink)}
                        > Go to online payment provider</i>
                      </Col>
                    </Row>
                  }
                  {!(selectedPayment.reference
                    && selectedPayment.reference.process_type === processTypeDirectDebit
                    && selectedPayment.reference.payment_status != paymentStatusNew)
                    && <Button
                      className="new-event--add mt-2"
                      color="primary"
                      type="button"
                      onClick={() => savePayment()}
                    >
                      {`Save`}
                    </Button>}
                </CardBody> :
                (
                  <CardBody>
                    <Row>
                      <Col md={12}>
                        <p>We have invoice</p>
                      </Col>
                    </Row>
                  </CardBody>
                )
              }
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={addInvoicePaymentModal}
          toggle={() => setAddInvoicePaymentModal(false)}
          className="modal-dialog-centered modal-lg"
        >
          <div className="modal-header">
            <h2>{`Add payment`}</h2>
          </div>
          <div className="modal-body">
            {customPaymentElementError && <Row>
              <Col>
                <p className="alert alert-warning">{customPaymentElementError}</p>
              </Col>
            </Row>}
            <InputCustom
              type={`date`}
              label={`Date`}
              value={customPaymentElement && customPaymentElement.paid_on ? customPayment.paid_on : ''}
              onChange={(e) => setCustomPaymentElement({
                ...customPaymentElement,
                paid_on: e.target.value,
              })}
            />
            <InputCustom
              type={`number`}
              label={`Amount`}
              value={customPaymentElement && customPaymentElement.amount ? customPaymentElement.amount : ''}
              onChange={(e) => setCustomPaymentElement({
                ...customPaymentElement,
                amount: e.target.value,
              })}
            />
            <InputArraySelect
              options={salesAccounts}
              value={customPaymentElement.sales_account ?? ''}
              valueKey={"code"}
              placeholder={`Sales Account`}
              label={"Sales Account"}
              labelKey={"name"}
              onChange={(e) => setCustomPaymentElement({
                ...customPaymentElement,
                sales_account: e.target.value,
              })}
              defaultOption={true}
            />
            <InputCustom
              type={`text`}
              label={`Reference`}
              value={customPaymentElement && customPaymentElement.note ? customPaymentElement.note : ''}
              onChange={(e) => setCustomPaymentElement({
                ...customPaymentElement,
                note: e.target.value,
              })}
            />
          </div>
          <div className="modal-footer mb-4">
            <Button
              className="new-event--add"
              color="primary"
              type="button"
              onClick={() => addCustomInvoicePayment()}
            >
              {`Save`}
            </Button>
            <Button
              className="ml-auto"
              color="danger"
              type="button"
              onClick={() => {
                setAddInvoicePaymentModal(false)
              }}
            >
              {`Close`}
            </Button>
          </div>
        </Modal>
        <AddNote isNoteModalVisible={addNoteModal} notify={notify} toggleModal={() => {
          setAddNoteModal(!addNoteModal);
        }} />
      </Container>
    </>
  )
}