import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import Cookies from 'js-cookie';
import * as constants from 'constants/index.js';
import axios from 'axios';

import InputCustom from 'views/pages/components/InputCustom';
import TableCustom from 'views/pages/components/TableCustom';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

import {
  create,
  update,
} from 'views/policies/PermissionPolicy';

import CardsHeader from 'layouts/Headers/CardsHeader.js';

export default function Permission() {
  const notificationAlert = useRef(null);
  const history = useHistory();

  const roles = useSelector(state => state.getState).roles;

  const [token, setToken] = useState('');
  const [permissions, setPermissions] = useState([]);

  const [addModal, setAddModal] = useState(false);
  const [permission, setPermission] = useState({
    name: '',
    display_name: '',
    description: ''
  });
  
  const [loading, setLoading] = useState(true);

  const [errors, setErrors] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{__html: message}} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/permission`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data
        setPermissions(data.data)

        setTimeout(() => {
          setLoading(false)
        }, 500);
      } catch (error) {
        setLoading(false)
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const formatDate = (d) => {
    let options = {
      year: 'numeric', month: '2-digit', day: '2-digit',
      hour: 'numeric', minute: 'numeric', second: 'numeric',
      hour12: false
    }
    const date = new Date(d);
    const formatedDate = new Intl.DateTimeFormat('en-GB', options).format(date);
    return formatedDate;
  }

  const addPermission = () => {
    axios({
      method: 'POST',
      url: constants.API_URL + '/permission',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: permission
    })
      .then(response => {
        setLoading(false);
        if (response.status == 200) {
          let data = response.data;
          console.log('response data :: ', data);
          notify('success', 'Success', data.message);
          setTimeout(() => {
            history.push({
              pathname: '/admin/permission/' + data.data.id + '/edit',
              state: {
                permission: data.data
              }
            });
          }, 1500);
        }
      })
      .catch(error => {
        setErrors('');
        setLoading(false);
        console.error('error :: ', error);
        if (error.response.status == 422) {
          let errors = error.response.data.errors;
          setErrors(errors);
          return
        }
        notify('error', 'Error', error.response.statusText);
      })
  }

  const editPermission = (row) => {
    history.push({
      pathname: '/admin/permission/' + row.id + '/edit',
      state: {
        permission: row
      }
    });
  }

  return (
    <>
      <CardsHeader name="Admin" parentName="Permission Management" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--6 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Permissions</h1>
                    <p className="text-sm mb-0">Permission management. List of permission.</p>
                  </Col>
                  <Col xs={4} className="text-right">
                    {create(roles) &&
                      <Button className="w-100" color="success" type="button" onClick={() => setAddModal(true)}>Add permission</Button>}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <TableCustom 
                  loading={loading}
                  keyField={`name`}
                  searchField={true}
                  items={permissions}
                  columns={[
                    {
                      dataField: "name",
                      text: "Name",
                      sort: true
                    },
                    {
                      dataField: "description",
                      text: "Description",
                      sort: true
                    },
                    {
                      dataField: "created_at",
                      text: "Creation date",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return formatDate(row.created_at);
                      }
                    },
                    {
                      dataField: "action",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            {update(roles, row) && 
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                color=""
                                role="button"
                                size="sm"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow" right>
                                <DropdownItem
                                  onClick={e => editPermission(row)}
                                >
                                  Edit
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>}
                          </>
                        )
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={addModal}
          toggle={() => setAddModal(false)}
          className="modal-dialog-centered modal-secondary"
        >
          <div className="modal-header">
            <h2 className="title">Permission Information</h2>
          </div>
          <div className="modal-body">
            <form className="new-event--form">
              <InputCustom 
                label={`Name`}
                placeholder={`Name`}
                onChange={(e) => setPermission({
                  ...permission,
                  name: e.target.value
                })}
                invalid={errors && errors.name? true: false}
                errorMessage={errors.name}
              />
              <InputCustom 
                label={`Display name`}
                placeholder={`Display name`}
                onChange={(e) => setPermission({
                  ...permission,
                  display_name: e.target.value
                })}
                invalid={errors && errors.display_name? true: false}
                errorMessage={errors.display_name}
              />
              <InputCustom 
                type={`textarea`}
                rows={8}
                label={`Description`}
                placeholder={`Description`}
                onChange={(e) => setPermission({
                  ...permission,
                  description: e.target.value
                })}
                invalid={errors && errors.description? true: false}
                errorMessage={errors.description}
              />
            </form>
          </div>
          <div className="modal-footer">
            <Button
              className="new-event--add"
              color="primary"
              type="button"
              onClick={() => addPermission()}
            >
              Save
            </Button>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setAddModal(false)}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      </Container>
    </>
  )
}