import React, { useEffect, useState, useRef, createContext } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import * as constants from 'constants/index.js';
import Cookies from "js-cookie";

import {
  Row,
  Col,
  FormGroup,  
  Input,
} from "reactstrap";

export const Context = createContext({});

export const Provider = props => {
  const { id, notify, children } = props;
  
  const image = useRef(null);
  const history = useHistory();

  const [token, setToken] = useState('');
  const [dealer, setDealer] = useState('');
  const [tab, setTab] = useState('profile');
  const [imageFile, setImageFile] = useState(null);

  const [profileErrors, setProfileErrors] = useState('');
  const [detailErrors, setDetailErrors] = useState('');
  const [modulesErrors, setModulesErrors] = useState('');
  const [financialErrors, setFinancialErrors] = useState('');
  const [frontendErrors, setFrontendErrors] = useState('');

  const [dealerProfile, setDealerProfile] = useState('');
  const [dealerLanding, setDealerLanding] = useState('');
  const [dealerFinancial, setDealerFinancial] = useState('');
  const [countries, setCountries] = useState([]);
  const [counties, setCounties] = useState([]);
  const [availableDealerAdminUser, setAvailableDealerAdminUser] = useState([]);
  const [assignedUser, setAssignedUser] = useState('');
  const [modules, setModules] = useState('');
  const [assignedModules, setAssignedModules] = useState('');

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/dealer-dealers/${id}/edit`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data.data

        setDealer({
          ...data.dealer,
          user_id: data.assignedUser ? data.assignedUser.id : ''
        });
        setDealerProfile({
          ...data.dealerProfile,
          date_of_birth: data.dealerProfile.date_of_birth ? formatDate(data.dealerProfile.date_of_birth) : '',
        });
        setDealerLanding(data.dealerLanding);
        setDealerFinancial({
          ...data.dealerFinancial,
          contract_deposit_at: data.dealerFinancial.contract_deposit_at ? formatDate(data.dealerFinancial.contract_deposit_at) : '',
          contract_starts_at: data.dealerFinancial.contract_starts_at ? formatDate(data.dealerFinancial.contract_starts_at) : '',
          contract_signed_at: data.dealerFinancial.contract_signed_at ? formatDate(data.dealerFinancial.contract_signed_at) : '',
        });
        setCountries(data.countries);
        setCounties(data.counties);
        setAvailableDealerAdminUser(data.availableDealerAdminUser);
        setAssignedUser(data.assignedUser);
        setModules(data.modules);
        setAssignedModules(data.assignedModules);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
        }
        setTimeout(() => {
          history.push('/admin/dealer');
        }, 1500);
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const handleChange = (files) => {
    setImageFile(files[0]);
  }

  const band = [null, 'null', undefined];

  const updateProfile = () => {
    let formData = new FormData();
    formData.append('dealer_id',                  id);
    formData.append('name',                       band.includes(dealerProfile.name)                      ? '': dealerProfile.name);
    formData.append('address',                    band.includes(dealerProfile.address)                   ? '': dealerProfile.address);
    formData.append('contact_person',             band.includes(dealerProfile.contact_person)            ? '': dealerProfile.contact_person);
    formData.append('place_of_birth',             band.includes(dealerProfile.place_of_birth)            ? '': dealerProfile.place_of_birth);
    formData.append('license_no',                 band.includes(dealerProfile.license_no)                ? '': dealerProfile.license_no);
    formData.append('company_ref_number',         band.includes(dealerProfile.company_ref_number)        ? '': dealerProfile.company_ref_number);
    formData.append('business_name',              band.includes(dealerProfile.business_name)             ? '': dealerProfile.business_name);
    formData.append('office_name',                band.includes(dealerProfile.office_name)               ? '': dealerProfile.office_name);
    formData.append('office_city',                band.includes(dealerProfile.office_city)               ? '': dealerProfile.office_city);
    formData.append('location_map_url',           band.includes(dealerProfile.location_map_url)          ? '': dealerProfile.location_map_url);
    formData.append('office_post_code',           band.includes(dealerProfile.office_post_code)          ? '': dealerProfile.office_post_code);
    formData.append('postal_address',             band.includes(dealerProfile.postal_address)            ? '': dealerProfile.postal_address);
    formData.append('postal_city',                band.includes(dealerProfile.postal_city)               ? '': dealerProfile.postal_city);
    formData.append('postal_state',               band.includes(dealerProfile.postal_state)              ? '': dealerProfile.postal_state);
    formData.append('postal_post_code',           band.includes(dealerProfile.postal_post_code)          ? '': dealerProfile.postal_post_code);
    formData.append('office_contact_phone',       band.includes(dealerProfile.office_contact_phone)      ? '': dealerProfile.office_contact_phone);
    formData.append('office_fax',                 band.includes(dealerProfile.office_fax)                ? '': dealerProfile.office_fax);
    formData.append('office_mobile',              band.includes(dealerProfile.office_mobile)             ? '': dealerProfile.office_mobile);
    formData.append('office_email_for_enquiries', band.includes(dealerProfile.office_email_for_enquiries)? '': dealerProfile.office_email_for_enquiries);
    formData.append('expire_notification_email',  band.includes(dealerProfile.expire_notification_email) ? '': dealerProfile.expire_notification_email);
    formData.append('phone_number',               band.includes(dealerProfile.phone_number)              ? '': dealerProfile.phone_number);
    formData.append('email',                      band.includes(dealerProfile.email)                     ? '': dealerProfile.email);
    formData.append('fiscal_code',                band.includes(dealerProfile.fiscal_code)               ? '': dealerProfile.fiscal_code);
    formData.append('bank',                       band.includes(dealerProfile.bank)                      ? '': dealerProfile.bank);
    formData.append('bank_sort_code',             band.includes(dealerProfile.bank_sort_code)            ? '': dealerProfile.bank_sort_code);
    formData.append('account_number',             band.includes(dealerProfile.account_number)            ? '': dealerProfile.account_number);
    formData.append('account_name',               band.includes(dealerProfile.account_name)              ? '': dealerProfile.account_name);
    if (imageFile) {
      formData.append('logo', imageFile);
    }
    if (dealerProfile.date_of_birth) {
      formData.append('date_of_birth', dealerProfile.date_of_birth === 'null'? '': dealerProfile.date_of_birth);
    }
    if (dealerProfile.country_of_birth_id) {
      formData.append('country_of_birth_id', dealerProfile.country_of_birth_id === 'null'? '': dealerProfile.country_of_birth_id);
    }
    if (dealerProfile.county_id) {
      formData.append('county_id', dealerProfile.county_id === 'null'? '': dealerProfile.county_id);
    }
    if (dealerProfile.is_public_listed) {
      formData.append('is_public_listed', dealerProfile.is_public_listed === true? 1: 0);
    }

    axios({
      method: 'POST',
      url: constants.API_URL + '/dealer/profile',
      headers: {
        'accept': 'application/json',
        Authorization: 'Bearer ' + token,
        'Content-Type': `multipart/form-data`,
      },
      data: formData
    })
      .then(response => {
        if (response.status == 200) {
          setProfileErrors('');
          let data = response.data.data;
          setDealerProfile({
            ...data,
            date_of_birth: data.date_of_birth? formatDate(data.date_of_birth): '',
          });
          notify('success', 'Success', response.data.message);
        }
      })
      .catch(error => {
        console.error('error :: ', error.response);
        if (error.response.status === 422) {
          let errors = error.response.data.errors;
          setProfileErrors(errors);
          return
        }
        notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred.');
      })
  }

  const updateDetail = () => {
    axios({
      method: 'PUT',
      url: constants.API_URL + '/dealer-dealers/' + id,
      headers: {
        Authorization: 'Bearer ' + token,
      },
      data: dealer
    })
      .then(response => {
        if (response.status === 200) {
          setDetailErrors('');
          let data = response.data;
          console.log('response detail data :: ', data);
          setDealer({
            ...data.data.dealer,
            user_id: data.data.assignedUser? data.data.assignedUser.id: ''
          });
          setAssignedUser(data.data.assignedUser);
          notify('success', 'Success', response.data.message);
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        if (error.response && error.response.status === 422) {
          let errors = error.response.data.errors;
          setDetailErrors(errors);
          return
        }
        notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred.');
      })
  }

  const updateModules = () => {
    let modules = [];
    Object.keys(assignedModules).map((key, index) => {
      let element = {
        module_type: key,
        value: assignedModules[key] && assignedModules[key].value? assignedModules[key].value: '',
        is_enabled: assignedModules[key] && assignedModules[key].is_enabled? assignedModules[key].is_enabled: ''
      }
      modules.push(element);
    })

    if (modules.length > 0) {
      let body = {
        modules: modules
      }
      axios({
        method: 'POST',
        url: constants.API_URL + '/dealer/' + dealer.slug + '/modules',
        headers: {
          Authorization: 'Bearer ' + token,
        },
        data: body
      })
        .then(response => {
          if (response.status === 200) {
            console.log('response module data :: ', response.data);
            notify('success', 'Success', response.data.message);
          }
        })
        .catch(error => {
          console.error('error :: ', error);
          if (error.response.status === 422) {
            let errors = error.response.data.errors;
            setModulesErrors(errors);
  
            let notification = '';
            let count = 0;
            for (var key in errors) {
              count ++;
              notification += '<b>' + count + '. </b>' + errors[key] + '<br />';
            }    
            notify('error', 'Error', notification);
          }
        })
    }
  }

  const updateFinancial = () => {
    axios({
      method: 'PUT',
      url: constants.API_URL + '/dealer/financial',
      headers: {
        Authorization: 'Bearer ' + token,
      },
      data: dealerFinancial
    })
      .then(response => {
        if (response.status === 200) {
          setFinancialErrors('');
          let data = response.data.data;
          console.log('response financial data :: ', response.data);
          setDealerFinancial({
            ...data,
            contract_deposit_at: data.contract_deposit_at? formatDate(data.contract_deposit_at): '',
            contract_starts_at: data.contract_starts_at? formatDate(data.contract_starts_at): '',
            contract_signed_at: data.contract_signed_at? formatDate(data.contract_signed_at): '',
          });
          notify('success', 'Success', response.data.message);
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        if (error.response.status === 422) {
          let errors = error.response.data.errors;
          setFinancialErrors(errors);
          return
        }
        notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred.');
      })
  }

  const updateFront = () => {
    axios({
      method: 'PUT',
      url: constants.API_URL + '/dealer/landing',
      headers: {
        Authorization: 'Bearer ' + token,
      },
      data: dealerLanding
    })
      .then(response => {
        if (response.status === 200) {
          let data = response.data.data;
          console.log('response financial data :: ', response.data);
          setDealerLanding(data);
          notify('success', 'Success', response.data.message);
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        if (error.response.status === 422) {
          let errors = error.response.data.errors;
          setFrontendErrors(errors);

          let notification = '';
          let count = 0;
          for (var key in errors) {
            count ++;
            notification += '<b>' + count + '. </b>' + errors[key] + '<br />';
          }    
          notify('error', 'Error', notification);
        }
      })
  }

  const formatDate = (date) => {
    const d = new Date(date);
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    return ye + '-' + mo + '-' + da;
  }

  const OptionTag = (options) => {
    return Object.keys(options).map((key) => {
      return (
        <option value={key} key={key}>{options[key]}</option>
      )
    })
  }

  const AllTag = (modules) => {
    return Object.keys(modules).map((key) => {
      return (
        <div key={key}>
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="module_accounting"
            >
              {key.charAt(0).toUpperCase() + key.slice(1)}
              </label>
            <Input
              id="module_accounting"
              type="select"
              value={assignedModules[key] && assignedModules[key].value ? assignedModules[key].value : ''}
              onChange={(e) => setAssignedModules({
                ...assignedModules,
                [key]: {
                  ...assignedModules[key],
                  value: e.target.value
                }
              })}
            >
              <option value="">-</option>
              { OptionTag(modules[key]) }
            </Input>
          </FormGroup>
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="detail_is_active"
            >
              Is enabled
              </label>
            <Row>
              <Col>
                <label className="custom-toggle">
                  <input
                    type="checkbox"
                    checked={assignedModules[key] && assignedModules[key].is_enabled? true: false}
                    onChange={(e) => setAssignedModules({
                      ...assignedModules,
                      [key]: {
                        ...assignedModules[key],
                        is_enabled: assignedModules[key] && assignedModules[key].is_enabled? false: true
                      }
                    })}
                  />
                  <span
                    className="custom-toggle-slider rounded-circle"
                    data-label-off="No"
                    data-label-on="Yes"
                  />
                </label>
              </Col>
            </Row>
          </FormGroup>
        </div>
      );
    });
  }

  const dealersContext = {
    id,
    image,
    history,
    notify,
    token, setToken,
    dealer, setDealer,
    tab, setTab,
    imageFile, setImageFile,  
    profileErrors, setProfileErrors,
    detailErrors, setDetailErrors,
    modulesErrors, setModulesErrors,
    financialErrors, setFinancialErrors,
    frontendErrors, setFrontendErrors,  
    dealerProfile, setDealerProfile,
    dealerLanding, setDealerLanding,
    dealerFinancial, setDealerFinancial,
    countries, setCountries,
    counties, setCounties,
    availableDealerAdminUser, setAvailableDealerAdminUser,
    assignedUser, setAssignedUser,
    modules, setModules,
    assignedModules, setAssignedModules,
    handleChange,
    updateProfile,
    updateDetail,
    updateModules,
    updateFinancial,
    updateFront,
    formatDate,
    OptionTag,
    AllTag
  };

  return <Context.Provider value={dealersContext}>{children}</Context.Provider>
}

export const { Consumer } = Context;