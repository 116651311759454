import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import axios from 'axios'
import Cookies from 'js-cookie'

import styled from "styled-components";
import * as constants from 'constants/index.js'


import {
    Container,
    Row,
    Col
} from 'reactstrap'
import DynamicTable from "../../components/DynamicTable";
import moment from "moment";
import CalendarFilter from "@pages/components/CalendarFilter";
const ParentContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 25px;
`
const WorkerDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const WorkerCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  background-color: white;
  border: none;
  box-shadow: 0 8px 36px rgba(112,144,176,0.00);
  padding: 30px;
  gap: 20px;
`
const TaskReportContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  background-color: white;
  border: none;
  box-shadow: 0 8px 36px rgba(112,144,176,0.00);
  padding: 30px;
  gap: 20px;
`

const Text = styled.span`
 font-weight: ${props => props.bold ? '600' : '400'};
 color: ${props => props.bold ? '#35353E' : props.color ? props.color : '#4E4E56'};
 font-size:  ${props => props.size ? props.size : '16px'}
`
const Line = styled.span`
 border: 1px solid #e6e6e7;
 width: 100%;
 color: #e6e6e7;
`
const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 45px;
`
const ReportContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export default function WorkerDetails() {
    const history = useHistory()
    const location = useLocation();

    const [token, setToken] = useState('')
    const [workerData, setWorkerData] = useState({})
    const [workerDataTasks, setWorkerDataTasks] = useState({})
    const [workerReport, setWorkerReport] = useState({})
    const [loading, setLoading] = useState(true)
    const [priorities, setPriorities] = useState([
        {
            label: 'High',
            color: 'red',
            icon: 'ep_d-arrow-up-double.png'
        },
        {
            label: 'Medium',
            color: 'green',
            icon: 'fluent_line-equal.png'
        },
        {
            label: 'Low',
            color: 'Blue',
            icon: 'ep_arrow-down.png'
        },
        {
            label: 'Clear',
            color: 'Grey',
            icon: 'minus.png'
        }
    ])
    const [keys, setKeys] = useState(
        [
            {
                key: 'title',
                text: 'Task Name'
            },
            {
                key: 'status',
                text: 'status'
            },
            {
                key: 'stock_no',
                text: 'Stock No'
            },
            {
                key: 'priority',
                text: 'priority'
            },
            {
                key: 'timeline',
                text: 'timeline'
            },
            {
                key: 'started_at',
                text: 'Started at'
            },
        ]
    )
    const [workerId, setId] = useState(null)

    useEffect(() => {
        let id = location.search
        let numb = id.match(/\d/g);
        id = numb.join("");
        setId(id)
        const grs_token = Cookies.get('grs_token')
        setToken(grs_token)

        const source = axios.CancelToken.source()

        const fetchData = async () => {
            try {
                let startDate = moment().startOf('month').format('YYYY-MM-DD');
                let endDate = moment().endOf('month').format('YYYY-MM-DD');
                await getTasks({from:startDate,to:endDate}, grs_token, source, id)

            } catch (error) {
                if (axios.isCancel(error)) {
                    console.log('Request canceled :: ', error)
                } else {
                    console.error('error :: ', error)
                }
            }
        }

        fetchData()

        return () => {
            source.cancel()
        }
    }, [])
    const getTasks = async (date = {}, token, source, id) => {
        const response = await axios({
            method: 'GET',
            url: `${constants.FLEET_URL}/fleet-management/worker/${id}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: date,
            cancelToken: source.token,
        })
        const report = await axios({
            method: 'GET',
            url: `${constants.FLEET_URL}/fleet-management/worker/${id}/report`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: date,
            cancelToken: source.token,
        })
        setWorkerReport(report.data.data)
        setLoading(false)
        const data = response.data
        setWorkerData(data.data);

        let groupedTasks = {};
        if (data.data && data.data.tasks) {
            data.data.tasks.forEach(task => {
                if (!groupedTasks[task.started_at_group]) {
                    groupedTasks[task.started_at_group] = [];
                }

                groupedTasks[task.started_at_group].push(task);
            })
        }
        setWorkerDataTasks(groupedTasks);
    }

    const capitalizeFirstLetter = (text) => {
        if (text) {
            return text?.charAt(0).toUpperCase() + text?.slice(1)
        }
    }
    const filterByDate = async (date) => {
        let tempDate = {}
        if (Object.values(date).find(item=>item !== '')) {
            tempDate = date
        }
        const source = axios.CancelToken.source()
        await getTasks(tempDate, token, source, workerId)
    }

    return (
        <>
            {!loading &&
                <Container>
                    <ParentContainer>
                        <WorkerDetailsContainer>
                            <WorkerCardContainer>
                                <Text bold={true}>{capitalizeFirstLetter(workerData?.worker?.role_type)} </Text>
                                <Text>{capitalizeFirstLetter(workerData?.worker?.department)} </Text>
                                <Line />
                                <TextContainer>
                                    <img height='25px' width='25px' src={require(`assets/images/call.png`)} alt="" />
                                    <Text>{workerData?.worker?.phone_number}</Text>
                                </TextContainer>
                                <TextContainer>
                                    <img height='25px' width='25px' src={require(`assets/images/mail.png`)} alt="" />
                                    <Text>{workerData?.worker?.email}</Text>
                                </TextContainer>
                                <TextContainer>
                                    <img height='25px' width='25px' src={require(`assets/images/location_on.png`)} alt="" />
                                    <Text>{workerData?.worker?.address}</Text>
                                </TextContainer>
                            </WorkerCardContainer>
                            <WorkerCardContainer>
                                <Text color={'#ec6409'}>Task report </Text>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Text bold={true}>General </Text>
                                    <Line />
                                </div>
                                <ReportContainer>
                                    <Text>Completed</Text>
                                    <Text >{workerReport.completed} </Text>
                                </ReportContainer>
                                <ReportContainer>
                                    <Text>Failed</Text>
                                    <Text >{workerReport.failed} </Text>
                                </ReportContainer>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Text bold={true}>Current </Text>
                                    <Line />
                                </div>
                                <ReportContainer>
                                    <Text>To Do</Text>
                                    <Text >{workerReport.to_do} </Text>
                                </ReportContainer>
                                <ReportContainer>
                                    <Text>In Progress</Text>
                                    <Text >{workerReport.in_progress} </Text>
                                </ReportContainer>
                                <ReportContainer>
                                    <Text>Done</Text>
                                    <Text >{workerReport.done} </Text>
                                </ReportContainer>
                            </WorkerCardContainer>
                        </WorkerDetailsContainer>
                            <Row>
                                <Col>
                                    <CalendarFilter filterByDate={filterByDate}/>
                                {Object.keys(workerDataTasks).map((key, index) => {
                                    return <Col key={index}>
                                        <TaskReportContainer>
                                            {index === 0 ?
                                                <Text color={'#ec6409'}>Not started Tasks</Text> :
                                                <Text color={'#ec6409'}>Started on {key}</Text>
                                            }
                                            <DynamicTable goToDetails={(e) => history.push(`/admin/task-details?id=${e}`)}
                                                showAnyFilter={false}
                                                showCarFilter={false}
                                                showWorkerTasksFilter={false}
                                                tableData={workerDataTasks[key]}
                                                          priorities={priorities}
                                                          keys={keys} />
                                        </TaskReportContainer>
                                    </Col>
                                })}
                                </Col>

                            </Row>
                    </ParentContainer>


                </Container>
            }

        </>
    )
}