import React, {useEffect, useState} from 'react'
import styled from "styled-components";
import {
    SelectWithoutInput
} from 'views/pages/components/InputSelect'


import CustomSearch from "../../../components/CustomSearch";
import {Calendar, DateObject} from "react-multi-date-picker"

import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import {CalendarSelect} from "@pages/reusable-components/styled-components";
import {parseToWord} from "../../../../../constants";

const FilterContainer = styled.div`
  display:flex;
  align-items: center;
  justify-content: space-between;
  width: ${props => props.width || '100%'};
  @media (max-width: 833px) {
     flex-direction:column;
     width: '100%';
  }
`

const CalendarInput = styled.input`
  cursor: pointer;
  text-align: center;
  align-items: center;
  border: 1px solid #F8F8F9;
  gap: 10px;
  font-size: 1rem;
  padding: 10px;
  color: #808087;
  outline: none;
  @media (max-width: 833px) {
          width: 110px;
          font-size: 12px;
   }
`

export default function ReportsWorkersTableFilter({
                                                      onSearchChange,
                                                      initialFilters = {},
                                                      departments,
                                                      clearData,
                                                      tabName,
                                                      apply,
                                                      showClearButton,
                                                      makes,
                                                      models
                                                  }) {

    const [department, setDepartment] = useState((initialFilters && Object.values(initialFilters).length && initialFilters.hasOwnProperty('department') )
        ? parseToWord(initialFilters?.department) : '' )
    const [modal, setModal] = useState(false)
    const [make, setMake] = useState('')
    const [model, setModel] = useState('')
    const [filteredModels, setFilteredModels] = useState([])
    const [filters, setFilters] = useState((initialFilters && Object.values(initialFilters)?.length) ? initialFilters : {})
    const toggle = () => setModal((prevState) => !prevState);

    const [clearInput, setClearInput] = useState(false);
    const [selectedDays, setSelectedDays] = useState(null);

    const [values, setValues] = useState([])
    const filterModels = (makeId) => {
        setFilteredModels(models.filter(item => item.vehicle_make_id === makeId))
    }
    const addFilter = (text, id) => {
        let tempFilter = filters
        tempFilter[text] = id
        setFilters(tempFilter)
    }
    const clearFilers = ()=> {
        setMake(null)
        setClearInput(true)
        setSelectedDays(null)
        setDepartment(null)
        setModel(null)
        setFilters({})
        clearData({})
    }
    useEffect(()=>{
        if (initialFilters && Object.values(initialFilters).length &&
            makes.length && !make && models.length) {
            let tempDays = []
            if (initialFilters?.from) {
                tempDays.push(initialFilters?.from)
            }
            if (initialFilters?.to) {
                tempDays.push(initialFilters?.to)
            }
            setSelectedDays(tempDays)
            if (initialFilters?.hasOwnProperty('make_id') && initialFilters.make_id) {
                setMake(makes.find(item=>initialFilters.make_id === item.id))
                const modelsTemp = models.filter(item => item.vehicle_make_id === initialFilters.make_id)
                setFilteredModels(modelsTemp)
                if (modelsTemp.length) {
                    if (initialFilters?.hasOwnProperty('model_id') && initialFilters.model_id) {
                        setModel(modelsTemp.find(item=>initialFilters.model_id === item.id))
                    }
                }
            }
        }

    },[initialFilters,makes,models])
    const searchData = (event) => {
        onSearchChange(event)
    }

    return (
        <>
            <Modal className={'custom-styles'} isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}/>
                <ModalBody>
                    <div className={'d-flex justify-content-center mb-3'}>
                        <div>
                            <span style={{fontSize: window.innerWidth < 833 && '12px'}}>From</span>
                            <CalendarInput placeholder="Filter by date"
                                           value={values?.length ? values[0].format('DD MMM YYYY') : ''}/>
                        </div>
                        <div><span style={{fontSize: window.innerWidth < 833 && '12px'}}>To</span>

                            <CalendarInput placeholder="Filter by date"
                                           value={values?.length > 1 ? values[values.length - 1].format('DD MMM YYYY') : ''}/>
                        </div>

                    </div>

                    <div className={'d-flex justify-content-center mb-3'}>
                        <Calendar
                            className={'custom-calendar'}
                            value={values}
                            onChange={(e) => {
                                setValues(e)
                            }}
                            range
                            numberOfMonths={2}
                            showOtherDays
                        />
                    </div>
                    <Button outline={true} style={{
                        backgroundColor: '#ec6409',
                        color: 'white',
                        border: '1px solid #ec6409',
                        float: 'right',
                        marginRight: '15px'
                    }} disabled={values.length < 2} onClick={() => {
                        setSelectedDays([values[0].format('DD/MM/YYYY'), values[values.length - 1].format('DD/MM/YYYY')])
                        addFilter('from', values[0].format('YYYY-MM-DD'))
                        addFilter('to', values[values.length - 1].format('YYYY-MM-DD'))
                        toggle()
                    }}
                    >Save</Button>

                </ModalBody>
            </Modal>
            <FilterContainer>
                <FilterContainer width='65%'>

                    <CustomSearch
                        clear={clearInput}
                        setClear={()=>setClearInput(false)}
                        defaultValue={filters.hasOwnProperty('search') ? filters['search'] : ''}
                        onChange={(e) => searchData(e.target.value)}
                        placeholder={'Search'}
                    />
                    <SelectWithoutInput
                        label={department ? department : `Department`}
                        onSelect={({key, value}) => {
                            setDepartment(value)
                            addFilter('department', key)
                        }}
                        fullWidth={window.innerWidth < 833}
                        valueKey={'key'}
                        labelKey={'value'}
                        options={departments}
                        defaultOption={true}
                        imageLink={'group-p.png'}
                    />

                    {tabName === 'tasks' ?
                        <>
                            <SelectWithoutInput
                                label={(make && make.name) ? make.name : `Make`}
                                onSelect={e => {
                                    setMake(e)
                                    filterModels(e.id)
                                    addFilter('make_id', e.id)
                                }}
                                fullWidth={window.innerWidth < 833}
                                options={makes}
                                defaultOption={true}
                                valueKey={'id'}
                                labelKey={'name'}
                                imageLink={'directions_car_grey.png'}
                            />
                            <SelectWithoutInput
                                label={(model && model.name) ? model.name : `Model`}
                                onSelect={e => {
                                    setModel(e)
                                    addFilter('model_id', e.id)
                                }}
                                disabled={!filteredModels.length}
                                fullWidth={window.innerWidth < 833}
                                options={filteredModels}
                                defaultOption={true}
                                valueKey={'id'}
                                labelKey={'name'}
                                imageLink={'loyalty.png'}
                            />

                        </> :
                        <SelectWithoutInput
                            label={(model && model.name) ? model.name : `Role`}
                            onSelect={e => {
                                setModel(e)
                                addFilter('model_id', e.id)
                            }}
                            disabled={!filteredModels.length}
                            options={filteredModels}
                            fullWidth={window.innerWidth < 833}
                            valueKey={'id'}
                            labelKey={'name'}
                            imageLink={'role_type.png'}
                        />
                    }

                </FilterContainer>

                <CalendarSelect>
                    <img onClick={toggle} width='20px' height='20px' src={require("assets/images/date_range.png")}
                         alt=""/>
                    <span
                        onClick={toggle}>{selectedDays?.length ? selectedDays[0] + ' - ' + selectedDays[1] : 'Filter by date'}</span>
                    {selectedDays?.length ? <i className="fa fa-times" onClick={(e) => {
                        e.preventDefault()
                        setSelectedDays([])
                        setValues([])
                        addFilter('from','')
                        addFilter('to','')
                    }}/> : <></>}
                    <i onClick={toggle} className="ni ni-up rotate-down"/>
                </CalendarSelect>
                {
                    showClearButton &&
                    <div className={'d-flex align-items-center ml-3 mr-3'}>
                        <Button className="w-100" color="warning" type="button"
                                onClick={() => clearFilers()}>Clear</Button>
                    </div>
                }
                <Button outline={true} style={{
                    backgroundColor: '#ec6409',
                    color: 'white',
                    border: '1px solid #ec6409',
                }} disabled={!Object.keys(filters).length} onClick={() => {
                    apply(filters)
                }}
                >Apply</Button>

            </FilterContainer>
        </>
    )
}