import React from 'react'

export default function Signal({signalValue}) {
  return (
    <>
      <svg viewBox="0 0 100 100" y="0" x="0" version="1.1" width="40px" height="40px" style={{height:"100%",backgroundSize:"initial",backgroundRepeatY:"initial",backgroundRepeatX:"initial",backgroundPositionY:"initial",backgroundPositionX:"initial",backgroundOrigin:"initial",backgroundImage:"initial",backgroundColor:"rgb(255, 255, 255)",backgroundClip:"initial",backgroundAttachment:"initial",animationPlayState:"paused"}} >
        <g style={{transformOrigin:"50% 50%",transform:"rotate(0deg) scale(0.8, 0.8)",animationPlayState:"paused"}} />
        <path d="M7.5 75h12.143v10H7.5z" fill={signalValue.val1} style={{fill:signalValue.val1,animationPlayState:"paused"}} ></path>
        <path d="M25.714 60h12.143v25H25.714z" fill={signalValue.val2} style={{fill:signalValue.val2,animationPlayState:"paused"}} ></path>
        <path d="M43.929 45h12.143v40H43.929z" fill={signalValue.val3} style={{fill:signalValue.val3,animationPlayState:"paused"}} ></path>
        <path d="M62.143 30h12.143v55H62.143z" fill={signalValue.val4} style={{fill:signalValue.val4,animationPlayState:"paused"}} ></path>
        <path d="M80.357 15H92.5v70H80.357z" fill={signalValue.val5} style={{fill:signalValue.val5,animationPlayState:"paused"}} ></path>
      </svg>
    </>
  )
}