import React from "react";
import { useHistory } from 'react-router-dom';
import PropTypes from "prop-types";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col
} from "reactstrap";
import {ButtonContainer} from "@pages/reusable-components/styled-components";

export default function CardsHeader({children, name, backButtonText='', showBackButton = false,
                                      redirect ,parentName, currentName, parentPath, breadcrumb = true}) {
  const history = useHistory();
  return (
    <>
      <div className="header pb-6" style={{height: '130px'}}>
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4 justify-content-between">
              <Col lg="6" xs="7">
                <h6 className="h2 d-inline-block mb-0">
                  {name}
                </h6>{" "}
                {breadcrumb && 
                <Breadcrumb
                  className="d-none d-md-inline-block ml-md-4"
                  listClassName="breadcrumb-links breadcrumb-black"
                >
                  <BreadcrumbItem>
                    <a href="" onClick={e => {
                      e.preventDefault();
                      history.push('/admin/dashboard');
                    }}>
                      <i className="fas fa-home" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href="" onClick={e => {
                      e.preventDefault();
                      history.push(parentPath);
                    }}>
                      {parentName}
                    </a>
                  </BreadcrumbItem>
                  {currentName && <BreadcrumbItem aria-current="page" className="active">
                    {currentName}
                  </BreadcrumbItem>}
                </Breadcrumb>}
              </Col>
              {showBackButton && <ButtonContainer  onClick={() => {
                redirect()
              }}>{backButtonText}</ButtonContainer>}
            </Row>

            <Row>
              {children}
            </Row>

          </div>

        </Container>
      </div>
    </>
  );
}

CardsHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string
};