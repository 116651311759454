import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as loaderActionsCreators from 'actions/LoaderActions';

export class Loader extends React.Component {
  GetBall = () => [1, 2, 3].map((number) => <div key={number} className={`bounce-${number}`} />);

  render() {
    const { isOpen, message } = this.props.loader;

    if (!isOpen) return null;

    return (
      <div className="d-flex flex-column justify-content-center loader-custom">
        <div className="spinner">{this.GetBall()}</div>
        {message ? <p className="mx-auto eon-title eon-white">{message}</p> : ''}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ loader: state.loader || {} });

const mapDispatchToProps = (dispatch) => ({ ...bindActionCreators({ ...loaderActionsCreators }, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(Loader);
