const viewAny = (permissions) => {
  return permissions.includes('read-resource-types')
}

const create = (permissions) => {
  return hasPermission(permissions, 'create-resource-types')
}

const update = (permissions, resourceType) => {
  return hasPermission(permissions, 'update-resource-types')
}

const deletee = (permissions, resourceType) => {
  return hasPermission(permissions, 'delete-resource-types')
}

const hasPermission = (permissions, permission) => {
  return permissions.includes(permission)
}

export {
  viewAny,
  create,
  update,
  deletee,
  hasPermission,
};