export const GET_LEADS_REQUEST = 'GET_LEADS_REQUEST';
export const GET_LEADS_SUCCESS = 'GET_LEADS_SUCCESS';
export const GET_LEADS_ERROR = 'GET_LEADS_ERROR';
export const DELETE_LEAD_REQUEST = 'DELETE_LEAD_REQUEST';
export const DELETE_LEAD_SUCCESS = 'DELETE_LEAD_SUCCESS';
export const DELETE_LEAD_ERROR = 'DELETE_LEAD_ERROR';
export const WON_LEAD_REQUEST = 'WON_LEAD_REQUEST';
export const WON_LEAD_SUCCESS = 'WON_LEAD_SUCCESS';
export const WON_LEAD_ERROR = 'WON_LEAD_ERROR';
export const LOST_LEAD_REQUEST = 'LOST_LEAD_REQUEST';
export const LOST_LEAD_SUCCESS = 'LOST_LEAD_SUCCESS';
export const LOST_LEAD_ERROR = 'LOST_LEAD_ERROR';
