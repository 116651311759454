import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';

import actions from 'actions/index.js';
import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import InputCustom from 'views/pages/components/InputCustom'
import TableCustom from 'views/pages/components/TableCustom';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from 'reactstrap';

import {
  update,
  hasPermission,
} from 'views/policies/TrackingPolicy';
import { InputKeySelect } from '../components/InputSelect';

export default function Tracking() {
  const notificationAlert = useRef(null);
  const history = useHistory();

  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.getState).permissions;
  const authUserTeam = useSelector((state) => state.getState).authUserTeam;

  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState('');
  const [devices, setDevices] = useState([]);
  const [vehicleStatuses, setVehicleStatuses] = useState('');
  const [dealers, setDealers] = useState('');
  const [filter, setFilter] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
      
    window.addEventListener('message', function (event) {
      if (typeof event.data === 'string' && event.data.indexOf('MapView|') === 0) {
        console.log(event.data)
      }
    })

    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)
    dispatch(actions.getProfileData(grs_token));

    const source = axios.CancelToken.source()

    fetchData(source, 1, grs_token, '')

    return () => {
      source.cancel()
    }
  }, [])

  const fetchData = async (source, pageNumber, token, filter) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/tracking/device`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ...filter,
          page: pageNumber
        },
        cancelToken: source.token,
      })
      console.log('response :: ', response.data)
      const data = response.data

      setItems(data.data.items)
      setDevices(data.data.items.data)
      setVehicleStatuses(data.data.vehicleStatuses)
      setDealers(data.data.dealers)

      setTimeout(() => {
        setLoading(false)
      }, 500);
    } catch (error) {
      setLoading(false)
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
      }
    }
  }

  const filterTracking = () => {
    const source = axios.CancelToken.source()
    fetchData(source, 1, token, {
      ...filter,
      search: filter.searchValue? filter.searchValue: undefined
    })
  }

  const resetTracking = () => {
    setFilter('')
    const source = axios.CancelToken.source()
    fetchData(source, 1, token, '')
  }

  const editTracking = (row) => {
    history.push({
      pathname: `/admin/tracking/device/${row.uuid}`
    })
  }

  const turnVehicle = async (row) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/tracking/device/${row.uuid}/immobilise`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          status: row.is_not_immobilised === 1 ? 1 : 0,
        },
      })
      console.log('response :: ', response.data)
      const data = response.data

      let message = ''

      const status = data.data.status.data
      if (status.is_not_immobilised === false) {
        message = 'Vehicle successfully turned off'
        const _devices = devices.map(device => {
          const returnValue = {...device}

          if (device.id == row.id) {
            returnValue.is_not_immobilised = 0
          }

          return returnValue
        })

        setDevices(_devices)
      } else if (status.is_not_immobilised === true) {
        message = 'Vehicle successfully turned on'
        const _devices = devices.map(device => {
          const returnValue = {...device}

          if (device.id == row.id) {
            returnValue.is_not_immobilised = 1
          }

          return returnValue
        })

        setDevices(_devices)
      }

      if (message) {
        notify('success', 'Success', message)
      }
    } catch (error) {
      console.error('error :: ', error)
      notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
    }
  }

  return (
    <>
      <CardsHeader name="Tracking Management" parentName="Tracking Management" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <div>
                    <h1 className="title">Tracking Management</h1>
                    <span className="text-sm mb-0">Manage all your Tracked vehicles here.</span>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <Container className="mt-3">
                  {!authUserTeam &&
                  <InputKeySelect 
                    label={`Dealer`}
                    options={dealers}
                    value={filter && filter.dealer_id ? filter.dealer_id : '' }
                    onChange={e => setFilter({
                      ...filter,
                      dealer_id: e.target.value
                    })}
                    defaultOption={true}
                  />}
                  <InputCustom 
                    label={`Search`}
                    value={filter && filter.searchValue ? filter.searchValue : ''}
                    onChange={e => setFilter({
                      ...filter,
                      searchValue: e.target.value
                    })}
                    onKeyPress={e => {
                      if (e.charCode === 13) {
                        filterTracking()
                      }
                    }}
                  />
                  <Row>
                    <Col md={6} sm={12} xs={12}>
                      <Button className="w-100" color="success" type="button" style={{margin: 5}} onClick={() => filterTracking()}>Filter</Button>
                    </Col>
                    <Col md={6} sm={12} xs={12}>
                      <Button className="w-100" color="danger" type="button" style={{margin: 5}} onClick={() => resetTracking()}>Reset</Button>
                    </Col>
                  </Row>
                </Container>
              </CardBody>
              <CardBody>
                <TableCustom
                  loading={loading}
                  items={devices}
                  pagination={items}
                  onChange={(pageNumber) => fetchData(axios.CancelToken.source(), pageNumber, token, filter)}
                  columns={[
                    {
                      dataField: "vehicle",
                      text: "Vehicle",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return `${row.vehicle.vehicle_make.name} ${row.vehicle.vehicle_model.name}`
                      }
                    },
                    {
                      dataField: "stock_no",
                      text: "Stock No",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.vehicle.stock_no ? row.vehicle.stock_no : ''
                      }
                    },
                    {
                      dataField: "current_customer",
                      text: "Current Customer",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.customer ? row.customer.full_name : '-'
                      }
                    },
                    {
                      dataField: "contact_number",
                      text: "Contact Number",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.customer ? row.customer.mobile_number : '-'
                      }
                    },
                    {
                      dataField: "tracker_status",
                      text: "Tracker Status",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.is_not_immobilised === 1 ? 'On' : 'Off'
                      }
                    },
                    {
                      dataField: "",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                color=""
                                role="button"
                                size="sm"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow" right>
                                {/* {(hasPermission(permissions, 'update-devices') || hasPermission(permissions, 'delete-devices'))
                                  && update(permissions, authUserTeam, row)
                                  &&  */}
                                  <DropdownItem
                                    onClick={e => editTracking(row)}
                                  >
                                    View
                                  </DropdownItem>
                                {/* } */}
                                <DropdownItem
                                  onClick={e => turnVehicle(row)}
                                >
                                  {`Turn vehicle ${row.is_not_immobilised === 0 ? `On` : `Off`}`}
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </>
                        )
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <iframe src="https://flespi.io/mapview/" width="0" height="0"></iframe>
      </Container>
    </>
  )
}