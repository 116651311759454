import * as actionTypes from './actio-types';
import produce from 'immer';

const initialState = {
  data: [],
};

const dealerState = produce((draft = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_DEALER_DATA_SUCCESS:
      return { ...draft, data: action.data };

    default:
      return draft;
  }
});

export default dealerState;
