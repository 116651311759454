import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {
    Col, Row,
} from "reactstrap";
import {parseToWord} from "../../../constants";

const RadioButtonWrapper = styled.div`
  border: 1px solid #e6e6e7;
  position: relative;
  border-radius: 50%;
  width: 20px;
  height: 20px;
`
const RadioButton = styled.div`
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background-color: ${props => props.selected && '#ec6409'}
`
const Text = styled.span`
 font-weight: ${props => props.bold ? '600' : '400'};
 color: ${props => props.bold ? '#35353E' : '#4E4E56'};
 font-size:  ${props => props.size ? props.size : '16px'}
`
const Line = styled.span`
border: 1px solid #e6e6e7;
 width: 100%;
 color: #e6e6e7;
`
const Container = styled.div`
 border: 1px solid #e6e6e7;
 border-radius: 4px;
 padding: 20px;
 margin-bottom: 25px;
`
export default function CustomChecklist({category,setCategories,categoryName,showRequired
}) {
    const [fields, setFields] = useState({})
    const [innerWidth,setInnerWidth]= useState(window.innerWidth < 833)
    const [checkRequired, setCheckRequired] = useState(false)
    const selectField = (itemName,value) =>{
        let data = {...fields};
        data[itemName].is_passing = value
        setFields({...data})
        setCategories({name: categoryName,value:data})
    }
   useEffect(()=>{
       setInnerWidth(window.innerWidth < 833)
   },[window.innerWidth])
    useEffect(()=>{
        if (showRequired) {
            setCheckRequired(true)
        }
    },[showRequired])
    useEffect(()=>{
        setFields({...category})
    },[])
    return (
        <Container>
           <Row>
              <div className={'col  col-md-8'} >
                  <Text bold={true}>{}</Text>
              </div>
               <div className={'col  col-md-1'}>
                   <Text bold={true}>Fail</Text>
               </div>
               <div className={'col col-md-1'}>
                   <Text bold={true}>Pass</Text>
               </div>
           </Row>
            {
                Object.keys(fields).map(item=>{
                 return   (
                       <>
                           <div className={innerWidth ? 'd-flex align-items-center' : 'row'}
                                style={{height: innerWidth ? '65px' : '55px', alignContent: 'center'}}>
                               <Col md={8}>
                                   <Text size={innerWidth && '12px'}>{parseToWord(item)}</Text>
                                   {checkRequired && fields[item].is_passing === null && <div className={'invalid-message'}>
                                       Please select an option
                                   </div>}
                               </Col>
                               <Col className={innerWidth && 'w-25'} md={1}>
                                   <RadioButtonWrapper>
                                       <RadioButton
                                           onClick={() => selectField(item,false)}
                                           selected={fields[item].is_passing === false}
                                       >  </RadioButton>
                                   </RadioButtonWrapper>
                               </Col>
                               <Col className={innerWidth && 'w-25'} md={1}>
                                   <RadioButtonWrapper>
                                       <RadioButton
                                           onClick={() => selectField(item,true)}
                                           selected={fields[item].is_passing === true}
                                       ></RadioButton>
                                   </RadioButtonWrapper>

                               </Col>

                           </div>
                           <Row>
                               <Line/>
                           </Row>
                       </>
                    )
                })
            }

        </Container>
    )
}