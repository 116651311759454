import React, { useEffect, useState, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import NotificationAlert from 'react-notification-alert'
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { fetchMonthlyTargets, saveMonthlyTargets } from '@redux/Vehicle/actions';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
  Input,
} from 'reactstrap';
import {
  InputArraySelect,
} from 'views/pages/components/InputSelect'
import InputCustom from 'views/pages/components/InputCustom';

import CardsHeader from 'layouts/Headers/CardsHeader.js';
import useNotify from 'hooks/notify';
import MonthlyTargetForm from './form/MonthlyTargetForm';
import {ButtonContainer} from "@pages/reusable-components/styled-components";

const months = [
  { id: 1, name: "January" },
  { id: 2, name: "February" },
  { id: 3, name: "March" },
  { id: 4, name: "April" },
  { id: 5, name: "May" },
  { id: 6, name: "June" },
  { id: 7, name: "July" },
  { id: 8, name: "August" },
  { id: 9, name: "September" },
  { id: 10, name: "October" },
  { id: 11, name: "November" },
  { id: 12, name: "December" },
]
export default function DailyStatistics() {
  const { ref, notify } = useNotify();
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [inputs, setInputs] = useState({});
  const { targets, successAction } = useSelector((state) => state.vehicle);

  const goList = () => {
    history.push({
      pathname: '/admin/live-vehicle/master'
    })
  }

  const yearsOptions = useMemo(() => {
    let options = [];
    let year = moment().toDate().getFullYear();
    if (year) {
      for (let i = year - 2; i <= year; i++) {
        options.push({
          id: i,
          name: i
        })
      }
    }

    return options;
  }, []);

  useEffect(() => {
    let month = moment().toDate().getMonth() + 1;
    setSelectedMonth(month);
    let year = moment().toDate().getFullYear();
    setSelectedYear(year);
    dispatch(fetchMonthlyTargets(month, year));
  }, []);

  useEffect(() => {
    setInputs({ ...targets });
  }, [targets]);

  useEffect(() => {
    if(successAction) {
      notify("Targets successfully saved!", 'Success','success')
    }
  }, [successAction]);

  return (
    <>
      <CardsHeader name="Fleets" parentName="Live vehicles" currentName="Statistics" />
      <div className="rna-wrapper">
        <NotificationAlert ref={ref} />
      </div>
      <Container className="mt--5 admin-main-body" id="liveVehiclesSection" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Sales Targets {selectedMonth ? `${months.find((month) => month.id === selectedMonth)?.name}` : ''} {selectedYear ?? ''}</h1>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Container>
                  <Row>
                    <Col xs={12} sm={6}>
                      <InputArraySelect
                        newLabel={`Target Month`}
                        options={months}
                        value={selectedMonth}
                        onChange={(e) => setSelectedMonth(e.target.value)}
                        valueKey={`id`}
                        labelKey={`name`}
                      />
                    </Col>
                    <Col xs={12} sm={6}>
                      <InputArraySelect
                        newLabel={`Target Year`}
                        options={yearsOptions}
                        value={selectedYear}
                        onChange={(e) => setSelectedYear(e.target.value)}
                        valueKey={`id`}
                        labelKey={`name`}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-5">
                    <Col xs={12}>
                      <ButtonContainer reverse onClick={() => {
                        dispatch(fetchMonthlyTargets(selectedMonth, selectedYear));
                      }}>Fetch</ButtonContainer>
                    </Col>
                  </Row>
                </Container>
              </CardBody>
              {targets &&
                <CardBody>
                  <MonthlyTargetForm
                    inputs={inputs}
                    onChange={(e) => {
                      setInputs({
                        ...inputs,
                        [e.target.name]: e.target.value
                      })
                    }}
                    onSave={() => {
                      dispatch(saveMonthlyTargets(inputs, selectedMonth, selectedYear));
                    }}
                  />
                </CardBody>
              }
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}