import React from 'react';

export default function Shortcodes({ items }) {
    console.log('items',items)
    const showCategories = () => {
        if (!items || Object.keys(items).length == 0) {
            return null;
        }

        let objects = Object.keys(items).map((key) => {
            let name = key[0].toUpperCase() + key.slice(1);
            return (
                <div>
                    <h2>{name}</h2>
                    {
                        items[key].map((item, index) => {
                            return(<span key={index}>
                                    {item}, 
                                </span>
                            )
                        })
                    }
                </div>
            )
        })

        return objects;
    }

    return (
        <>
            {items && showCategories()}
        </>
    )
}