import React, { useEffect, useState, useRef } from "react";
import { useSelector } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';

import Chart from 'chart.js';
import { Line, Pie, Bar } from "react-chartjs-2";
import {
  chartOptions,
  parseOptions,
} from "constants/charts.js";

import * as constants from 'constants/index.js';

import {
  Container,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  ListGroup,
  ListGroupItem
} from "reactstrap";

import CardsHeader from 'layouts/Headers/CardsHeader.js';
import Calendar from 'views/pages/components/Calendar.js';
import CardHeader from "reactstrap/lib/CardHeader";

export default function PaulAuDashboard(props) {
  const notificationAlert = useRef(null);

  const authUserTeam = useSelector(state => state.getState).authUserTeam;
  const roles = useSelector(state => state.getState).roles;

  const [token, setToken] = useState('');
  const [authTeamUsers, setAuthTeamUsers] = useState([]);
  const [customerStatistics, setCustomerStatistics] = useState('');
  const [dealsStatistics, setDealsStatistics] = useState('');
  const [vehiclesStatistics, setVehiclesStatistics] = useState('');

  // In case of auth_user_team
  const [currentTasks, setCurrentTasks] = useState([]);
  const [messages, setMessages] = useState([]);
  const [reminderTasks, setReminderTasks] = useState([]);

  const [deliveredDealsChartData, setDeliveredDealsChartData] = useState('');
  const [allLeadsChartData, setAllLeadsChartData] = useState('');
  const [monthLeadsChartData, setMonthLeadsChartData] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    if (window.Chart) {
      parseOptions(Chart, chartOptions())
    }

    if (Cookies.get('grs_token')) {
      let grs_token = Cookies.get('grs_token')
      setToken(grs_token)

      axios({
        method: 'GET',
        url: constants.API_URL + '/dashboard',
        headers: {
          Authorization: 'Bearer ' + grs_token
        },
      })
        .then(response => {
          let data = response.data
          setAuthTeamUsers(data.data.auth_team_users)
          setCustomerStatistics(data.data.customer_statistics)
          setDealsStatistics(data.data.deals_statistics)
          setVehiclesStatistics(data.data.vehicles_statistics)

          if (authUserTeam) {
            setCurrentTasks(data.data.current_tasks)
            setMessages(data.data.messages)
            setReminderTasks(data.data.reminder_tasks)
          }
        })
        .catch(error => {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
        })
        
      axios({
        method: 'GET',
        url: constants.API_URL + '/report/sales/leads',
        headers: {
          Authorization: 'Bearer ' + grs_token
        },
      })
        .then(response => {
          let data = response.data

          /**
           * all leads by source
           */
          let labels = data.data.datasetGroupByLeadsType.map((item) => {
            return item.lead_source;
          });

          let datas = data.data.datasetGroupByLeadsType.map((item) => {
            return item.total;
          });

          let colors = constants.getColorsForSources(labels);

          setAllLeadsChartData({
            labels: labels,
            datasets: [
              {
                data: datas,
                backgroundColor: colors,
                label: "Label"
              }
            ]
          })

          /**
           * leads sources grouped by month
           */
          let _labels = data.data.datasetGroupByInterval.labels;

          let datasets = [];

          Object.keys(data.data.datasetGroupByInterval.set).forEach((item) => {
            datasets.push({
              label: item,
              backgroundColor: constants.getColorsForSources(item),
              data: data.data.datasetGroupByInterval.set[item],
              maxBarThickness: 10
            });
          });

          setMonthLeadsChartData({
            labels: _labels,
            datasets: datasets
          })
        })
        .catch(error => {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
        })
        
      axios({
        method: 'GET',
        url: constants.API_URL + '/report/sales/deals',
        headers: {
          Authorization: 'Bearer ' + grs_token
        },
      })
        .then(response => {
          let data = response.data
          let labels = data.data.dataset.map((item) => {
            return item.label;
          });

          let datas = data.data.dataset.map((item) => {
            return item.count;
          });

          let datasets = [{
            label: 'Performance',
            data: datas
          }];

          setDeliveredDealsChartData({
            labels: labels,
            datasets: datasets
          })
        })
        .catch(error => {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
        })
    }
  }, [])

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <CardsHeader name="Home" parentName="Dashboards">
        <Col xl={authUserTeam ? 4 : 3}>
          <Card className="card-stats bg-gradient-info">
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle
                    tag="h5"
                    className="text-uppercase text-muted mb-0 text-white"
                  >
                    Delivered deals(Current month)
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0 text-white">
                    {dealsStatistics ? dealsStatistics.deliveredDeals : 0}
                  </span>
                </div>
              </Row>
              <p className="mt-3 mb-0 text-sm text-white">
                <span className="text-nowrap mr-2">All time delivered deals</span>{" "}
                <span>
                  {dealsStatistics ? dealsStatistics.allTimeDeliveredDeals : 0}
                </span>
              </p>
            </CardBody>
          </Card>
        </Col>
        <Col xl={authUserTeam ? 4 : 3}>
          <Card className="card-stats bg-gradient-gray">
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle
                    tag="h5"
                    className="text-uppercase text-muted mb-0 text-white"
                  >
                    Waiting for signing
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0 text-white">
                    {dealsStatistics ? dealsStatistics.awaitingForSigningDeals : 0}
                  </span>
                </div>
              </Row>
              <p className="mt-3 mb-0 text-sm text-white">
                <span className="text-nowrap mr-2">Deal in progress</span>{" "}
                <span>
                  {dealsStatistics ? dealsStatistics.inProgressDeals : 0}
                </span>
              </p>
            </CardBody>
          </Card>
        </Col>
        <Col xl={authUserTeam ? 4 : 3}>
          <Card className="card-stats bg-gradient-danger">
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle
                    tag="h5"
                    className="text-uppercase text-muted mb-0 text-white"
                  >
                    Total vehicles
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0 text-white">
                    {vehiclesStatistics ? vehiclesStatistics.totalVehicles : 0}
                  </span>
                </div>
              </Row>
              <p className="mt-3 mb-0 text-sm text-white">
                <span className="text-nowrap mr-2">Since last month</span>{" "}
                <span>
                  {vehiclesStatistics ? vehiclesStatistics.availableVehicles : 0}
                </span>
              </p>
            </CardBody>
          </Card>
        </Col>
        {!authUserTeam &&
          <Col xl={authUserTeam ? 4 : 3}>
            <Card className="card-stats bg-gradient-green">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0 text-white"
                    >
                      Customers(current month)
                  </CardTitle>
                    <span className="h2 font-weight-bold mb-0 text-white">
                      {customerStatistics ? customerStatistics.currentMonthCustomers : 0}
                    </span>
                  </div>
                </Row>
                <p className="mt-3 mb-0 text-sm text-white">
                  <span className="text-nowrap mr-2">Since last month</span>{" "}
                  <span>
                    {customerStatistics ? customerStatistics.allCustomers : 0}
                  </span>
                </p>
              </CardBody>
            </Card>
          </Col>}
      </CardsHeader>
      <Container fluid className="mt-7" style={{ minHeight: '60vh' }}>
        {authUserTeam && 
        <Row>
          <Col xl={12}>
            <Card>
              <CardHeader>
                <h1 className="text-danger mb-0">Messages</h1>
              </CardHeader>
              <CardBody>
                <ListGroup data-toggle="checklist" flush>
                  { messages && messages.length > 0 && 
                    messages.map((message, index) => {
                      return (
                        <ListGroupItem className="checklist-entry flex-column align-items-start py-4 px-4" key={index}>
                          <div className="checklist-item checklist-item-success">
                            <div className="checklist-info">
                              <h5 className="checklist-title mb-0">{message.title}</h5>
                              <small>{message.message}</small>
                            </div>
                          </div>
                        </ListGroupItem>
                      )
                    })
                  }
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>}
        {roles && roles.includes('superadministrator') && 
        <Row>
          <Col xl={6}>
            <Card>
              <CardHeader>
                <h6 className="surtitle">Leads</h6>
                <h1 className="title mb-0">All leads by source</h1>
              </CardHeader>
              <CardBody>
                {allLeadsChartData && 
                <div className="chart">
                  <Pie
                    data={allLeadsChartData}
                    options={
                      {
                        responsive: true,
                        legend: {
                          display: true,
                          position: "top"
                        },
                        animation: {
                          animateScale: true,
                          animateRotate: true
                        }
                      }
                    }
                    className="chart-canvas"
                  />
                </div>}
              </CardBody>
            </Card>
          </Col>
          <Col xl={6}>
            <Card>
              <CardHeader>
                <h6 className="surtitle">Leads</h6>
                <h1 className="title mb-0">Leads Sources grouped by month</h1>
              </CardHeader>
              <CardBody>
                {monthLeadsChartData &&
                <div className="chart">
                  <Bar
                    data={monthLeadsChartData}
                    options={
                      {
                        responsive: true,
                        legend: {
                          display: true,
                          position: 'top',
                        },
                        title: {
                          display: true,
                          text: ''
                        },
                        tooltips: {
                          mode: 'index',
                          intersect: true,
                          callbacks: {
                            footer: (tooltipItems, data) => {
                              let total = tooltipItems.reduce((a, e) => a + parseInt(e.yLabel), 0);
                              return 'Total: ' + total;
                            }
                          }
                        },
                        scales: {
                          yAxes: [{
                            type: 'linear',
                            display: true,
                            position: 'left',
                            id: 'y-axis-1',
                          }, {
                            type: 'linear',
                            display: true,
                            position: 'right',
                            id: 'y-axis-2',
                            gridLines: {
                              drawOnChartArea: false
                            }
                          }],
                        }
                      }
                    }
                    className="chart-canvas"
                  />
                </div>}
              </CardBody>
            </Card>
          </Col>
        </Row>}
        <Row>
          {roles && roles.includes('superadministrator') && 
          <Col xl={6}>
            <Card>
              <CardHeader>
                <h6 className="text-light text-uppercase ls-1 mb-1">Overview</h6>
                <h1 className="title mb-0">Total number of delivered deals</h1>
              </CardHeader>
              <CardBody>
                {deliveredDealsChartData && 
                <div className="chart">
                  <Line
                    data={deliveredDealsChartData}
                    options={
                      {
                        responsive: true,
                        title: {
                          display: false,
                          text: ''
                        },
                        tooltips: {
                          mode: 'index',
                        },
                        hover: {
                          mode: 'index'
                        },

                        scales: {
                          yAxes: [{
                            gridLines: {
                              color: "#525f7f",
                              zeroLineColor: "#525f7f"
                            },
                            ticks: {
                              callback: function (label, index, labels) {
                                return label;
                              }
                            }
                          }]
                        }
                      }
                    }
                    className="chart-canvas"
                  />
                </div>}
              </CardBody>
            </Card>
          </Col>}
          <Col xl={roles && roles.includes('superadministrator')? 6: 12}>
            <Calendar {...props} />
          </Col>
        </Row>
      </Container>
    </>
  );
}