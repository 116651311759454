import React, { useContext } from 'react';
import { LeadsContext } from '../context';

import TableCustom from 'views/pages/components/TableCustom';

import {
  Button,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  FormGroup,
  Row,
  Col,
} from "reactstrap";

import {
  InputArraySelect
} from 'views/pages/components/InputSelect';
import {ButtonContainer} from "@pages/reusable-components/styled-components";

export default function DetailsTab() {
  const leadsContext = useContext(LeadsContext);
  const {
    taskModal, setTaskModal,
    lead,
    constants,
    deletee, hasPermission,
    profilePermissions,
    authUserTeam,
    selectedTask, setSelectedTask,
    deleteModal, setDeleteModal,
    loading,
    InputCustom,
    taskErrors,
    deleteTask,
    Select2,
    customDealerMembers,
    addTask,
    updateTask,
  } = leadsContext;
  return (
    <Card>
      <CardBody>
        <Row>
          <Col xs={8}>
            <h3 className="mb-0 main-color">Add Tasks</h3>
          </Col>
          <Col xs={4} className="text-right">
            <ButtonContainer  onClick={() => setTaskModal({
              ...taskModal,
              show: true,
              header: 'Add a new task',
              division: 'create',
            })}>Add task</ButtonContainer>
          </Col>
        </Row>
        <TableCustom 
          loading={loading}
          items={lead.tasks}
          keyField={`id`}
          searchField={false}
          columns={[
            {
              dataField: "title",
              text: "Title",
              sort: true
            },
            {
              dataField: "comments",
              text: "Description",
              sort: true
            },
            {
              dataField: "created_at",
              text: "Created",
              sort: true,
              formatter: (rowContent, row) => {
                return constants.formatDateShortGB(row.created_at)
              }
            },
            {
              dataField: "owner",
              text: "Created By",
              sort: true,
              formatter: (rowContent, row) => {
                return row.owner.name
              }
            },
            {
              dataField: "updated_at",
              text: "Updated At",
              sort: true,
              formatter: (rowContent, row) => {
                return constants.formatDateShortGB(row.updated_at)
              }
            },
            {
              dataField: "",
              text: "Action",
              formatter: (rowContent, row) => {
                return (
                    hasPermission(profilePermissions, 'read-leads')  &&
                    <>
                    <img className={'cursor-pointer'}   onClick={() => {
                        setSelectedTask({
                            ...row,
                            due_date: row.due_date? constants.formatDate(row.due_date): '',
                        })
                        setTaskModal({
                            ...taskModal,
                            show: true,
                            header: 'Edit task',
                            division: 'update',
                        })
                    }} src={require(`assets/images/tableIcons/edit.svg`)} alt=""/>
                    {deletee(profilePermissions, authUserTeam, lead) &&
                        <img className={'cursor-pointer'}   onClick={() => {
                        setDeleteModal({
                        ...deleteModal,
                        show: true,
                        header: 'Delete lead task.',
                        body: 'Are you sure to delete this task?',
                        onClick: () => deleteTask(row.uuid)
                    })
                    }} src={require(`assets/images/tableIcons/delete_outline.svg`)} alt=""/>
                        }
                  </>
                )
              }
            }
          ]}
        />
      </CardBody>
      <Modal
        isOpen={taskModal.show}
        toggle={() => setTaskModal({
          ...taskModal,
          show: false,
        })}
        className="modal-dialog-centered"
      >
        <div className="modal-header">
          <h6 className="modal-title">{taskModal.header}</h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setTaskModal({
              ...taskModal,
              show: false,
            })}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <InputCustom
            newLabel={`Title`}
            value={selectedTask && selectedTask.title ? selectedTask.title : ''}
            onChange={(e) => setSelectedTask({
              ...selectedTask,
              title: e.target.value,
            })}
            invalid={taskErrors && taskErrors.title ? true : false}
            errorMessage={taskErrors.title}
          />
          <InputCustom
            type={`textarea`}
            newLabel={`Description`}
            rows={5}
            value={selectedTask && selectedTask.comments ? selectedTask.comments : ''}
            onChange={(e) => setSelectedTask({
              ...selectedTask,
              comments: e.target.value,
            })}
            invalid={taskErrors && taskErrors.comments ? true : false}
            errorMessage={taskErrors.comments}
          />
          <InputCustom
            type={`date`}
            newLabel={`Due`}
            value={selectedTask && selectedTask.due_date ? selectedTask.due_date : ''}
            onChange={(e) => setSelectedTask({
              ...selectedTask,
              due_date: e.target.value,
            })}
            invalid={taskErrors && taskErrors.due_date ? true : false}
            errorMessage={taskErrors.due_date}
          />
          <FormGroup className={'d-flex align-items-center'}>
            <label className="form-control-label w-50">Assign to</label>
            <Select2
              className="form-control"
              multiple
              value={selectedTask && selectedTask.taskAssignee ? selectedTask.taskAssignee : ''}
              data={customDealerMembers}
              onChange={(e) => setSelectedTask({
                ...selectedTask,
                taskAssignee: Array.from(e.target.selectedOptions, option => option.value),
              })}
            />
            {taskErrors && taskErrors.task_assignee && <div className="invalid-feedback" style={{ display: 'block' }}>
              {taskErrors.task_assignee}
            </div>}
          </FormGroup>
        </div>
        <div className="modal-footer justify-content-end">
            <ButtonContainer
                onClick={() => setTaskModal({
                    ...taskModal,
                    show: false,
                })}
            >
                {`Close`}
            </ButtonContainer>
          <ButtonContainer
              reverse
            onClick={() => taskModal.division == 'create' ? addTask() : updateTask()}
          >
            {`Save task`}
          </ButtonContainer>
        </div>
      </Modal>
      {/* Delete Modal */}
      <Modal
        isOpen={deleteModal.show}
        toggle={() => setDeleteModal({
          ...deleteModal,
          show: false,
        })}
        className="modal-dialog-centered"
      >
        <div className="modal-header">
          <h6 className="modal-title">{deleteModal.header}</h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setDeleteModal({
              ...deleteModal,
              show: false,
            })}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          {deleteModal.body}
        </div>
        <div className="modal-footer">
          <Button
            className="new-event--add"
            color="warning"
            type="button"
            onClick={deleteModal.onClick}
          >
            {`Delete`}
          </Button>
          <Button
            className="ml-auto"
            color="link"
            type="button"
            onClick={() => setDeleteModal({
              ...deleteModal,
              show: false,
            })}
          >
            Close
            </Button>
        </div>
      </Modal>
    </Card>
  );
}