import React, { useEffect, useState, useRef } from 'react';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';

export default function ResourceTasks() {
  const notificationAlert = useRef(null);
  
  const [dealStatus, setDealStatus] = useState('');
  const [items, setItems] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/resources/tasks`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data
        
        setDealStatus(data.data.dealStatus)
        setItems(data.data.items)
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  return (
    <>
      <CardsHeader name="Vehicles" parentName="Tasks Management" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="6">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <div>
                    <h1 className="title mb-0">Due date expired and not completed</h1>
                  </div>
                </div>
              </CardHeader>			  
              <CardBody>
                <div className="timeline timeline-one-side" data-timeline-content="axis"
                  data-timeline-axis-style="dashed">
                  {items && items.urgent && items.urgent.data.length > 0 &&
                    items.urgent.data.map((task, index) => {
                      return (
                        <div className="timeline-block" key={index}>
                          <span className="timeline-step badge-success">
                            <i className="ni ni-bell-55"></i>
                          </span>
                          <div className="timeline-content">
                            <small className="text-muted font-weight-bold">{constants.formatDateShortGB(task.due_date)}</small>
                            <h5 className=" mt-3 mb-0"><a
                              href="{{$task->getTaskableRoute()}}">{task.title}</a>
                            </h5>
                            <p className=" text-sm mt-1 mb-0">{task.comments}</p>
                            <div className="mt-3">
                              <span className="badge badge-pill badge-success">Created by: {task.owner.name}</span>
                              { task.task_assignee && task.task_assignee.length > 0 &&
                                task.task_assignee.map((assignee, index2) => {
                                  return (
                                    <span className="badge badge-pill badge-success" key={index2}>Assigned to: {assignee.name}</span>
                                  )
                                })
                              }
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="6">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <div>
                    <h1 className="title mb-0">Upcoming due date</h1>
                  </div>
                </div>
              </CardHeader>			  
              <CardBody>
                <div className="timeline timeline-one-side" data-timeline-content="axis"
                  data-timeline-axis-style="dashed">
                  { items.active && items.active.length > 0 &&
                    items.active.map((task, index) => {
                      return (
                        <div className="timeline-block" key={index}>
                          <span className="timeline-step badge-success">
                            <i className="ni ni-bell-55"></i>
                          </span>
                          <div className="timeline-content">
                            <small className="text-muted font-weight-bold">{constants.formatDateShortGB(task.due_date)}</small>
                            <h5 className=" mt-3 mb-0"><a
                              href="{{$task->getTaskableRoute()}}">{task.title}</a>
                            </h5>
                            <p className=" text-sm mt-1 mb-0">{task.comments}</p>
                            <div className="mt-3">
                              <span className="badge badge-pill badge-success">Created by: {task.owner.name}</span>
                              {task.task_assignee && task.task_assignee.length > 0 &&
                                task.task_assignee.map((assignee, index2) => {
                                  return (
                                    <span className="badge badge-pill badge-success" key={index2}>Assigned to: {assignee.name}</span>
                                  )
                                })
                              }
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}