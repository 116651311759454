import React from 'react';
import { Button, Modal } from 'reactstrap';
import PropTypes from 'prop-types';

const ConfirmModal = ({ confirmModal, setConfirmModal, deleteLead }) => {
  return (
    <Modal
      isOpen={confirmModal}
      toggle={() => setConfirmModal(false)}
      className="modal-dialog-centered"
    >
      <div className="modal-header">
        <h6 className="modal-title">{`Delete lead`}</h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => setConfirmModal(false)}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">{`Are you sure to delete this lead?`}</div>
      <div className="modal-footer">
        <Button
          className="new-event--add"
          color="danger"
          type="button"
          onClick={() => deleteLead()}
        >
          {`Delete`}
        </Button>
        <Button
          className="ml-auto"
          color="link"
          type="button"
          onClick={() => setConfirmModal(false)}
        >
          {`Close`}
        </Button>
      </div>
    </Modal>
  );
};

export default React.memo(ConfirmModal);

ConfirmModal.propTypes = {
  confirmModal: PropTypes.bool,
  setConfirmModal: PropTypes.func,
  deleteLead: PropTypes.func,
};
