import React, { useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Button,
} from "reactstrap";
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import { LeadsContextProvider } from './context';
import DetailsSection from './sections/DetailsSection';
import QuotesSection from './sections/QuotesSection';
import NotesSection from './sections/NotesSection';
import TasksSection from './sections/TasksSection';
import {ButtonContainer} from "@pages/reusable-components/styled-components";

export default function LeadEdit() {
  const { id } = useParams();
  const notificationAlert = useRef(null);
  const history = useHistory();

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  const goList = () => {
    history.push({
      pathname: '/admin/leads'
    })
  }

  return (
    <LeadsContextProvider id={id} notify={notify}>
      <CardsHeader name="Leads" parentName="Leads Management" currentName="Edit" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            {/* Header */}
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Leads Management</h1>
                  </Col>
                  <Col xs={4} className="text-right">
                    <ButtonContainer onClick={() => goList()}>Back</ButtonContainer>
                  </Col>
                </Row>
              </CardHeader>
            </Card>
            {/* Details */}
            <DetailsSection />
            {/* Quotes */}
            <QuotesSection />
            {/* Notes */}
            <NotesSection />
            {/* Tasks */}
            <TasksSection />
          </Col>
        </Row>
        </Container>
    </LeadsContextProvider>
  )
}