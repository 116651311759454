import React from 'react';
import {
    Container,
    Row,
    Col,
    Button,
} from 'reactstrap';

import InputCustom from 'views/pages/components/InputCustom';
import {ButtonContainer} from "@pages/reusable-components/styled-components";


export default function MonthlyTargetForm({ onChange, inputs, onSave }) {
    return (
        <>
            <Container>
                <Row>
                    <Col md={12}>
                        <h2>Rental</h2>
                    </Col>
                    <Col md={6}>
                        <InputCustom
                            type="number"
                            min={0}
                            name={'rental_new_deals'}
                            newLabel={"New Deals"}
                            value={inputs['rental_new_deals'] ?? ''}
                            onChange={onChange}
                        />
                    </Col>
                    {/* <Col md={6}>
                        <InputCustom
                            type="number"
                            min={0}
                            label={"Renewals"}
                            name={'rental_renewals'}
                            value={inputs['rental_renewals'] ?? ''}
                            onChange={onChange}
                        />
                    </Col> */}
                    {/* <Col md={6}>
                        <InputCustom
                            type="number"
                            min={0}
                            name={'rental_companies'}
                            label={"Rental Companies"}
                            value={inputs['rental_companies'] ?? ''}
                            onChange={onChange}
                        />
                    </Col> */}
                    <Col md={6}>
                        <InputCustom
                            type="number"
                            min={0}
                            name={'rental_target'}
                            newLabel={"Rental Target Amount"}
                            value={inputs['rental_target'] ?? ''}
                            onChange={onChange}
                        />
                    </Col>
                </Row>

                <div>
                   <h2>Used deals</h2>
                   <Row>
                       <Col md={6}>
                           <InputCustom
                               type="number"
                               min={0}
                               name={'used_trade_sales'}
                               newLabel={"Trade Sales"}
                               value={inputs['used_trade_sales'] ?? ''}
                               onChange={onChange}
                           />
                           <InputCustom
                               type="number"
                               min={0}
                               name={'used_retail_sales'}
                               newLabel={"Retail Sales"}
                               value={inputs['used_retail_sales'] ?? ''}
                               onChange={onChange}
                           />
                       </Col>
                       <Col md={6}>
                           <InputCustom
                               type="number"
                               min={0}
                               name={'used_auction_sales'}
                               newLabel={"Auction Sales"}
                               value={inputs['used_auction_sales'] ?? ''}
                               onChange={onChange}
                           />
                           <InputCustom
                               type="number"
                               min={0}
                               name={'expected_sales_profit'}
                               newLabel={"Sales Target Amount"}
                               value={inputs['expected_sales_profit'] ?? ''}
                               onChange={onChange}
                           />
                       </Col>
                   </Row>

                </div>
                <Row>
                    <Col xs={12}>
                        <ButtonContainer reverse onClick={() => {
                            onSave();
                        }}>Save</ButtonContainer>
                    </Col>
                </Row>
            </Container>
        </>
    )
}