export const CREATE_TASK_REQUEST = 'CREATE_TASK_REQUEST';
export const CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS';
export const CREATE_TASK_ERROR = 'CREATE_TASK_ERROR';

export const GET_TASK_CATEGORIES_REQUEST = 'GET_TASK_CATEGORIES_REQUEST';
export const GET_TASK_CATEGORIES_SUCCESS = 'GET_TASK_CATEGORIES_SUCCESS';
export const GET_TASK_CATEGORIES_ERROR = 'GET_TASK_CATEGORIES_ERROR';

export const GET_TASK_RESOURCES_REQUEST = 'GET_TASK_RESOURCES_REQUEST';
export const GET_TASK_RESOURCES_SUCCESS = 'GET_TASK_RESOURCES_SUCCESS';
export const GET_TASK_RESOURCES_ERROR = 'GET_TASK_RESOURCES_ERROR';

export const GET_DEALS_REQUEST = 'GET_DEALS_REQUEST';
export const GET_DEALS_SUCCESS = 'GET_DEALS_SUCCESS';
export const GET_DEALS_ERROR = 'GET_DEALS_ERROR';

export const GET_CUSTOMERS_REQUEST = 'GET_CUSTOMERS_REQUEST';
export const GET_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS';
export const GET_CUSTOMERS_ERROR = 'GET_CUSTOMERS_ERROR';

export const GET_VEHICLES_REQUEST = 'GET_VEHICLES_REQUEST';
export const GET_VEHICLES_SUCCESS = 'GET_VEHICLES_SUCCESS';
export const GET_VEHICLES_ERROR = 'GET_VEHICLES_ERROR';

export const GET_TASKS_REQUEST = 'GET_TASKS_REQUEST';
export const GET_TASKS_SUCCESS = 'GET_TASKS_SUCCESS';
export const GET_TASKS_ERROR = 'GET_TASKS_ERROR';

export const UPDATE_TASK_REQUEST = 'UPDATE_TASK_REQUEST';
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS';
export const UPDATE_TASK_ERROR = 'UPDATE_TASK_ERROR';

export const CREATE_COMMENT_REQUEST = 'CREATE_COMMENT_REQUEST';
export const CREATE_COMMENT_SUCCESS = 'CREATE_COMMENT_SUCCESS';
export const CREATE_COMMENT_ERROR = 'CREATE_COMMENT_ERROR';

export const UPDATE_COMMENT_REQUEST = 'UPDATE_COMMENT_REQUEST';
export const UPDATE_COMMENT_SUCCESS = 'UPDATE_COMMENT_SUCCESS';
export const UPDATE_COMMENT_ERROR = 'UPDATE_COMMENT_ERROR';

export const DELETE_COMMENT_REQUEST = 'DELETE_COMMENT_REQUEST';
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS';
export const DELETE_COMMENT_ERROR = 'DELETE_COMMENT_ERROR';

export const SEARCH_TASK_REQUEST = 'SEARCH_TASK_REQUEST';
export const SEARCH_TASK_SUCCESS = 'SEARCH_TASK_SUCCESS';
export const SEARCH_TASK_ERROR = 'SEARCH_TASK_ERROR';
