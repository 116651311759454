import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {
    Col, Row,
} from "reactstrap";
import {parseToWord} from "../../../../../constants";

const RadioButtonWrapper = styled.div`
  border: 1px solid #e6e6e7;
  position: relative;
  border-radius: 50%;
  width: 20px;
  height: 20px;
`
const RadioButton = styled.div`
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background-color: ${props => props.selected && '#ec6409'}
`
const Text = styled.span`
 font-weight: ${props => props.bold ? '600' : '400'};
 color: ${props => props.bold ? '#35353E' : '#4E4E56'};
 font-size:  ${props => props.size ? props.size : '16px'}
`
const Line = styled.span`
border: 1px solid #e6e6e7;
 width: 100%;
 color: #e6e6e7;
`
const Container = styled.div`
 border: 1px solid #e6e6e7;
 border-radius: 4px;
 padding: 20px;
 margin-bottom: 25px;
`
export default function InspectionForm({task,setCategories,categoryName,showRequired
}) {
    const [fields, setFields] = useState([])
    const [name, setName] = useState('')
    const [checkRequired, setCheckRequired] = useState(false)
    const selectField = (itemName,value) =>{
        let data = {...fields};
        data[itemName].is_passing = value
        setFields({...data})
        setCategories({name: categoryName,value:data})
    }

    useEffect(()=>{
     if (task) {
         setName(task.name)
        setFields([...task.tasks])
         setCheckRequired(true)
     }
    },[task])
    return (
        <Container>
           <Row>
              <Col md={8}>
                  <Text bold={true}>{parseToWord(name)}</Text>
              </Col>
               <Col md={1}>
                   <Text bold={true}>Yes</Text>
               </Col>
               <Col md={1}>
                   <Text bold={true}>No</Text>
               </Col>
           </Row>
            {
             checkRequired ? fields.map(item=>{

                 return   (
                       <>
                           <Row style={{height: '55px', alignContent: 'center'}}>
                               <Col md={8}>
                                   <Text>{parseToWord(item?.option_name?.name)}</Text>
                                   {item.is_passing === null && <div className={'invalid-message'}>
                                       Please select an option
                                   </div>}
                               </Col>
                               <Col md={1}>
                                   <RadioButtonWrapper>
                                       <RadioButton
                                           onClick={() => selectField(item,false)}
                                           selected={item.is_passing === false}
                                       >  </RadioButton>
                                   </RadioButtonWrapper>
                               </Col>
                               <Col md={1}>
                                   <RadioButtonWrapper>
                                       <RadioButton
                                           onClick={() => selectField(item,true)}
                                           selected={item.is_passing === true}
                                       ></RadioButton>
                                   </RadioButtonWrapper>
                               </Col>
                           </Row>
                           <Row>
                               <Line/>
                           </Row>
                       </>
                    )
                }) : <></>
            }

        </Container>
    )
}