import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useDropzone } from "react-dropzone"
import NotificationAlert from "react-notification-alert"
import Select2 from "react-select2-wrapper";
import axios from 'axios'
import Cookies from 'js-cookie'
import moment from 'moment'

import actions from 'actions'
import * as constants from 'constants/index.js'

import CardsHeader from 'layouts/Headers/CardsHeader.js'
import InputCustom from 'views/pages/components/InputCustom'
import {
  InputKeySelect,
  InputArraySelect,
} from 'views/pages/components/InputSelect'
import TableCustom from 'views/pages/components/TableCustom'
import VerticalLabel from 'views/pages/components/VerticalLabel'

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Carousel,
  CarouselItem,
  CarouselControl,
  Table,
  Input,
  Modal,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
} from 'reactstrap'
import {ButtonContainer, Line} from "@pages/reusable-components/styled-components";
import {getDocument} from "@api/document.api";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out"
}

const activeStyle = {
  borderColor: "#2196f3"
}

const acceptStyle = {
  borderColor: "#00e676"
}

const rejectStyle = {
  borderColor: "#ff1744"
}

export default function VehicleEdit() {
  const { id } = useParams()
  const notificationAlert = useRef(null)
  const history = useHistory()
  const dispatch = useDispatch()

  const [tab, setTab] = useState('details')

  const [token, setToken] = useState('')
  const [delay, setDelay] = useState(false)
  const [contract, setContract] = useState('')
  const [intervals, setIntervals] = useState('')
  const [vehicle, setVehicle] = useState('')
  const [makes, setMakes] = useState([])
  const [models, setModels] = useState([])
  const [filteredModels, setFilteredModels] = useState([])
  const [vehicleImages, setVehicleImages] = useState('')
  const [vehicleStatuses, setVehicleStatuses] = useState('')
  const [vehicleTransmissions, setVehicleTransmissions] = useState('')
  const [fundingStyles, setFundingStyles] = useState('')
  const [vehicleTypes, setVehicleTypes] = useState('')
  const [vehicleRepository, setVehicleRepository] = useState('')
  const [settlementCost, setSettlementCost] = useState('')
  const [dealerMembers, setDealerMembers] = useState([])
  const [inspectionDocuments, setInspectionDocuments] = useState([])
  const [vehicleTaskStatuses, setVehicleTaskStatuses] = useState('')

  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)

  const [slides, setSlides] = useState('')

  const [errors, setErrors] = useState('')
  const [soldErrors, setSoldErrors] = useState('')
  const [documentErrors, setDocumentErrors] = useState('')

  const [selectedImage, setSelectedImage] = useState({
    id: '',
    index: ''
  })
  const [confirmModal, setConfirmModal] = useState(false)

  const [deleteModal, setDeleteModal] = useState({
    show: false,
    header: '',
    body: '',
    onClick: null,
  })

  const [fileLists, setFileLists] = useState([])

  const [updateVehicleEnable, setUpdateVehicleEnable] = useState(false)
  const [updateValuationEnable, setUpdateValuationEnable] = useState(false)

  const [addTaskModal, setAddTaskModal] = useState({
    show: false,
    header: '',
    division: '',
  })
  const [task, setTask] = useState('')
  const [taskErrors, setTaskErrors] = useState('')

  const [updateVehicleModal, setUpdateVehicleModal] = useState({
    show: false,
    header: '',
    key: ''
  })

  const [updateMakeModelModal, setUpdateMakeModelModal] = useState({
    show: false,
  })

  const onDrop = useCallback(acceptedFiles => {
    setFileLists(prev => [...prev, ...acceptedFiles])
  })

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  )

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    }
    notificationAlert.current.notificationAlert(options)
  }

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/vehicle/${id}/edit`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        const data = response.data
        setContract(data.data.contract)
        setIntervals(data.data.intervals)
        setVehicle({
          ...data.data.item,
          date_of_reg: data.data.item.date_of_reg ? constants.formatDate(data.data.item.date_of_reg) : '',
          mot_date: data.data.item.mot_date ? moment(data.data.item.mot_date).format('DD/MM/YYYY') : '',
          delivered_to_us: data.data.item.delivered_to_us ? constants.formatDate(data.data.item.delivered_to_us) : '',
          end_date: data.data.item.end_date ? constants.formatDate(data.data.item.end_date) : '',
          rfl_taxed_date: data.data.item.rfl_taxed_date ? moment(data.data.item.rfl_taxed_date).format('DD/MM/YYYY') : '',
          sold_at: data.data.item.sold_at ? constants.formatDate(data.data.item.sold_at) : '',
          valuation_time: data.data.item.valuation_time ? moment(data.data.item.valuation_time).format('DD/MM/YYYY') : '',
        })
        setMakes(data.data.makes)
        setModels(data.data.models)
        setFilteredModels(data.data.models)
        setVehicleImages(data.data.vehicleImages)
        setVehicleStatuses(data.data.vehicleStatuses)
        setVehicleTransmissions(data.data.vehicleTransmissions)
        setFundingStyles(data.data.fundingStyles)
        setVehicleTypes(data.data.vehicleTypes)
        setVehicleRepository(data.data.vehicleRepository)
        setSettlementCost(data.data.settlementCost)
        setInspectionDocuments(data.data.inspectionDocuments)
        let dealer_members = data.data.dealerMembers
        dealer_members.map(dealer => {
          dealer.text = dealer.name
        })
        setDealerMembers(dealer_members)
        setVehicleTaskStatuses(data.data.vehicleTaskStatuses)

        let _images = data.data.vehicleImages.images_collection
        const slides = _images.map((item) => {
          return (
            <CarouselItem
              onExiting={() => setAnimating(true)}
              onExited={() => setAnimating(false)}
              key={item.link}
            >
              <img src={`${item.link}`} width={900} height={405} />
            </CarouselItem>
          )
        })
        setSlides(slides)

        setTimeout(() => {
          setDelay(true)
        }, 500)
        dispatch(actions.getNotifications(grs_token))
      } catch (error) {
        setDelay(true)
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const updateVehicle = () => {
    delete vehicle['vehicleGear']
    delete vehicle['vehicle_gear']
    delete vehicle['vehicleFuel']
    delete vehicle['vehicle_fuel']
    delete vehicle['vehicleMake']
    delete vehicle['vehicle_make']
    delete vehicle['vehicleModel']
    delete vehicle['vehicle_model']
    delete vehicle['document_files']
    delete vehicle['documents']
    delete vehicle['tasks']

    let formData = new FormData()

    if (vehicle.images_collection && vehicle.images_collection.length > 0) {
      for (let index = 0; index < vehicle.images_collection.length; index++) {
        const element = vehicle.images_collection[index]
        if (typeof element == 'object' && element.type) {
          formData.append(`images_collection[${index}]`, element)
        }
      }
    }

    Object.keys(vehicle).map(key => {
      if (key != 'images_collection' && key != 'ownerable' && ![null, undefined].includes(vehicle[key])) {
        if (key == 'featured_image') {
          if (typeof vehicle[key] == 'object') {
            formData.append(key, vehicle[key])
          }
        } else if (key == 'mot_date' || key == 'rfl_taxed_date' || key == 'valuation_time') {
          formData.append(key, vehicle[key] ? moment(vehicle[key], 'DD/MM/YYYY').format('YYYY-MM-DD') : '')
        } else {
          if (key == 'tracker' || key == 'enabled') {
            if (vehicle[key] != 0 && vehicle[key] != false) {
              formData.append(key, vehicle[key])
            }
          } else if (key == 'maintenance_included') {
            formData.append(key, (vehicle[key] === true || vehicle[key] === 1) ? 1 : 0)
          } else {
            formData.append(key, vehicle[key])
          }
        }
      }
    })
    formData.append('vehicle_make_selector', vehicle && vehicle.vehicle_make_selector ? vehicle.vehicle_make_selector : vehicle.redundant_vehicle_make_id)
    formData.append('_method', 'PUT')

    axios({
      method: 'POST',
      url: `${constants.API_URL}/vehicle/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: formData,
    })
      .then(response => {
        setErrors('')
        setDelay(false)
        console.log('response :: ', response.data)
        const data = response.data
        notify('success', 'Success', data.message)

        setVehicle({
          ...data.data.item,
          date_of_reg: data.data.item.date_of_reg ? constants.formatDate(data.data.item.date_of_reg) : '',
          mot_date: data.data.item.mot_date ? moment(data.data.item.mot_date).format('DD/MM/YYYY') : '',
          delivered_to_us: data.data.item.delivered_to_us ? constants.formatDate(data.data.item.delivered_to_us) : '',
          end_date: data.data.item.end_date ? constants.formatDate(data.data.item.end_date) : '',
          rfl_taxed_date: data.data.item.rfl_taxed_date ? moment(data.data.item.rfl_taxed_date).format('DD/MM/YYYY') : '',
          sold_at: data.data.item.sold_at ? constants.formatDate(data.data.item.sold_at) : '',
          valuation_time: data.data.item.valuation_time ? moment(data.data.item.valuation_time).format('DD/MM/YYYY') : '',
        })
        setVehicleImages(data.data.vehicleImages)

        let _images = data.data.vehicleImages.images_collection;
        const slides = _images.map((item) => {
          return (
            <CarouselItem
              onExiting={() => setAnimating(true)}
              onExited={() => setAnimating(false)}
              key={item.link}
            >
              <img src={`${item.link}`} width={900} height={405} />
            </CarouselItem>
          );
        });
        setSlides(slides)

        setTimeout(() => {
          setDelay(true)
        }, 500)

        setUpdateVehicleModal({
          ...updateVehicleModal,
          show: false
        })

        setUpdateMakeModelModal({
          ...updateMakeModelModal,
          show: false
        })
      })
      .catch(error => {
        setErrors('')
        setDelay(true)
        console.error('error :: ', error)
        if (error.response && error.response.status == 422) {
          setErrors(error.response.data.errors)
        }
        notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
      })
  }

  const next = (items) => {
    if (animating) return
    if (items.length > 0) {
      const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1
      setActiveIndex(nextIndex)
    }
  }

  const previous = (items) => {
    if (animating) return
    if (items.length > 0) {
      const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1
      setActiveIndex(nextIndex)
    }
  }

  const goToIndex = (newIndex) => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  const duplicate = () => {
    history.push({
      pathname: `/admin/vehicle/${id}/duplicate`,
    })
  }

  const financialDetails = () => {
    history.push({
      pathname: `/admin/vehicle/${id}/financial`,
    })
  }

  const financialHistory = () => {
    history.push({
      pathname: `/admin/vehicle/${id}/financial-history`,
    })
  }

  const sold = () => {
    axios({
      method: 'POST',
      url: `${constants.API_URL}/vehicle/${id}/action/sold`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        settlement_cost: settlementCost.settlementCost,
        sold_amount: vehicle.sold_amount,
        sold_at: vehicle.sold_at,
        sold_to: vehicle.sold_to,
      },
    })
      .then(response => {
        setSoldErrors('')
        console.log('response :: ', response.data)
        const data = response.data
        notify('success', 'Success', data.message)
      })
      .catch(error => {
        setSoldErrors('')
        console.error('error :: ', error)
        if (error.response && error.response.status == 422) {
          setSoldErrors(error.response.data.errors)
        }
        notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
      })
  }

  const deleteImage = () => {
    axios({
      method: 'DELETE',
      url: `${constants.API_URL}/vehicle/${id}/remove/${selectedImage.id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
    })
      .then(response => {
        setConfirmModal(false)
        console.log('response :: ', response.data)
        const data = response.data
        if (vehicleImages.images_collection) {

          const collection_images = vehicleImages.images_collection
          let _new_1 = []
          let _new_2 = []
          let new_collection_images = []

          _new_1 = collection_images.slice(0, selectedImage.index)
          _new_2 = collection_images.slice(selectedImage.index + 1, collection_images.length)
          new_collection_images = _new_1.concat(_new_2)

          const slides = new_collection_images.map((item) => {
            return (
              <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={item.link}
              >
                <img src={`${item.link}`} width={900} height={405} />
              </CarouselItem>
            )
          })
          setSlides(slides)
          setVehicleImages({
            ...vehicleImages,
            images_collection: new_collection_images,
          })
        }
        notify('success', 'Success', data.message)
      })
      .catch(error => {
        setConfirmModal(false)
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
      })
  }

  const returnedToFunder = () => {
    axios({
      method: 'GET',
      url: `${constants.API_URL}/vehicle/${id}/action/returned`,
      headers: {
        Authorization: `Bearer ${token}`
      },
    })
      .then(response => {
        console.log('response :: ', response.data)
        const data = response.data
        notify('success', 'Success', data.message)
      })
      .catch(error => {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
      })
  }

  const uploadDocument = () => {
    let formData = new FormData()
    for (let index = 0; index < fileLists.length; index++) {
      const element = fileLists[index]
      formData.append(`file[${index}]`, element)
    }

    axios({
      method: 'POST',
      url: `${constants.API_URL}/vehicle/${id}/general-documents`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: formData
    })
      .then(response => {
        if (response.status == 200) {
          const data = response.data
          console.log('response data :: ', data)

          setVehicle({
            ...vehicle,
            documents: data.data,
          })
          setFileLists([])
          notify('success', 'Success', data.message)
        }
      })
      .catch(error => {
        setDocumentErrors('')
        console.error('error :: ', error)
        if (error.response && error.response.status == 422) {
          const errors = error.response.data.errors
          setDocumentErrors(errors)
        }
        notify('warning', 'Warning', error.response && error.response.statusText ? error.response.statusText : 'Error occur.')
      })
  }

  const viewDocument = (row) => {
    axios({
      method: 'GET',
      url: `${constants.API_URL}/vehicle/${id}/document/${row.id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      responseType: 'blob'
    })
      .then(response => {
        if (response.status == 200) {
          let data = response.data
          console.log('response data :: ', data)
          const disposition = response.request.getResponseHeader('Content-Disposition')
          let fileName = ""
          const filenameRegex = /filename[^=\n]*=((['"]).*?\2|[^\n]*)/
          const matches = filenameRegex.exec(disposition)
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '')
          }
          const blob = new Blob([data])
          const downloadUrl = URL.createObjectURL(blob)
          let a = window.document.createElement("a")
          a.href = downloadUrl
          a.download = fileName
          window.document.body.appendChild(a)
          a.click()
        }
      })
      .catch(error => {
        console.error('error :: ', error.response)
        notify('warning', 'Warning', error.response && error.response.data.message ? error.response.data.message : error.response.statusText)
      })
  }

  const deleteDocument = (documentId) => {
    axios({
      method: 'GET',
      url: `${constants.API_URL}/vehicle/${id}/remove/${documentId}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        setDeleteModal({
          ...deleteModal,
          show: false,
        })
        if (response.status == 200) {
          let data = response.data
          console.log('response data :: ', data)
          setVehicle({
            ...vehicle,
            documents: data.data,
          })
          notify('success', 'Success', data.message)
        }
      })
      .catch(error => {
        setDeleteModal({
          ...deleteModal,
          show: false,
        })
        console.error('error :: ', error.response)
        notify('warning', 'Warning', error.response && error.response.data.message ? error.response.data.message : error.response.statusText)
      })
  }

  /**
   * New
   */
  const getValuation = () => {
    axios({
      method: 'GET',
      url: `${constants.UK_VEHICLE_DATA_API_URL}/ValuationData?v=2&api_nullitems=1&auth_apikey=${constants.UK_VEHICLE_DATA_API_KEY}&key_VRM=${vehicle.stock_no}`,
    })
      .then(response => {
        const _valuation_data = response.data
        console.log('_valuation_data :: ', _valuation_data)

        if (_valuation_data.Response) {

          if (_valuation_data.Response.StatusCode === 'Success') {
            const valuationList = _valuation_data.Response.DataItems

            setVehicle({
              ...vehicle,
              dealer_forecourt: valuationList.ValuationList.DealerForecourt ? valuationList.ValuationList.DealerForecourt : 0,
              part_exchange: valuationList.ValuationList.PartExchange ? valuationList.ValuationList.PartExchange : 0,
              trade_poor: valuationList.ValuationList.TradePoor ? valuationList.ValuationList.TradePoor : 0,
              trade_retail: valuationList.ValuationList.TradeRetail ? valuationList.ValuationList.TradeRetail : 0,
              trade_average: valuationList.ValuationList.TradeAverage ? valuationList.ValuationList.TradeAverage : 0,
              auction: valuationList.ValuationList.Auction ? valuationList.ValuationList.Auction : 0,
              valuation_time: valuationList.ValuationTime ? constants.formatDateShortGB(valuationList.ValuationTime) : ''
            })

            notify('success', 'Success', 'Got valuation data successfully, please click Save button if you need to save them')
            setUpdateValuationEnable(true)
          } else {
            notify('warning', 'Getting valuation warning', _valuation_data.Response.StatusMessage)
          }
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  const getVehicleData = () => {
    /**
     * Get vehicle data
     */
    const _vehicle_data_request = axios({
      method: 'GET',
      url: `${constants.UK_VEHICLE_DATA_API_URL}/VehicleData?v=2&api_nullitems=1&auth_apikey=${constants.UK_VEHICLE_DATA_API_KEY}&key_VRM=${vehicle.stock_no}`,
    })

    /**
     * Get vehicle valuation data
     */
    const _mot_tax_data_request = axios({
      method: 'GET',
      url: `${constants.UK_VEHICLE_DATA_API_URL}/MotHistoryAndTaxStatusData?v=2&api_nullitems=1&auth_apikey=${constants.UK_VEHICLE_DATA_API_KEY}&key_VRM=${vehicle.stock_no}`,
    })

    axios.all([_vehicle_data_request, _mot_tax_data_request])
      .then(axios.spread((...responses) => {

        const _vehicle_data = responses[0].data
        const _mot_tax_data = responses[1].data

        if (_vehicle_data.Response && _mot_tax_data.Response) {

          if (_vehicle_data.Response.StatusCode === 'Success') {

            const dataItems = _vehicle_data.Response.DataItems
            const motTaxData = _mot_tax_data.Response.StatusCode === 'Success' ? _mot_tax_data.Response.DataItems.VehicleStatus : []

            setVehicle({
              ...vehicle,
              full_vin: dataItems.VehicleRegistration.Vin ? dataItems.VehicleRegistration.Vin : '',
              engine_number: dataItems.VehicleRegistration.EngineNumber ? dataItems.VehicleRegistration.EngineNumber : '',
              first_registered: dataItems.VehicleRegistration.DateFirstRegistered ? dataItems.VehicleRegistration.DateFirstRegistered : '',
              first_registered_uk: dataItems.VehicleRegistration.DateFirstRegisteredUk ? dataItems.VehicleRegistration.DateFirstRegisteredUk : '',
              year_manufacture: dataItems.VehicleRegistration.YearOfManufacture ? dataItems.VehicleRegistration.YearOfManufacture : '',
              gearbox_type: dataItems.VehicleRegistration.Transmission ? dataItems.VehicleRegistration.Transmission : '',
              colour: dataItems.VehicleRegistration.Colour ? dataItems.VehicleRegistration.Colour : '',
              gears_number: dataItems.SmmtDetails.NumberOfGears ? dataItems.SmmtDetails.NumberOfGears : 0,
              fuel_type: dataItems.VehicleRegistration.FuelType,
              engine_capacity: dataItems.VehicleRegistration.EngineCapacity,
              door_plan: dataItems.VehicleRegistration.DoorPlanLiteral,
              seating_capacity: dataItems.VehicleRegistration.SeatingCapacity ? dataItems.VehicleRegistration.SeatingCapacity : 0,
              co2: dataItems.VehicleRegistration.Co2Emissions ? dataItems.VehicleRegistration.Co2Emissions : 0,
              kerb_weight: dataItems.TechnicalDetails.Dimensions.KerbWeight ? dataItems.TechnicalDetails.Dimensions.KerbWeight : 0,
              vin_last_5: dataItems.VehicleRegistration.VinLast5 ? dataItems.VehicleRegistration.VinLast5 : 0,
              scrapped: dataItems.VehicleRegistration.Scrapped,
              date_scrapped: dataItems.VehicleRegistration.DateScrapped,
              exported: dataItems.VehicleRegistration.Exported,
              date_exported: dataItems.VehicleRegistration.DateExported,
              imported: dataItems.VehicleRegistration.Imported,
              imported_non_eu: dataItems.VehicleRegistration.ImportNonEu,
              euro_status: dataItems.TechnicalDetails.General.EuroStatus ? dataItems.TechnicalDetails.General.EuroStatus : '',
              driven_axle: dataItems.TechnicalDetails.General.DrivingAxle ? dataItems.TechnicalDetails.General.DrivingAxle : '',
              type_approval: dataItems.TechnicalDetails.General.TypeApprovalCategory ? dataItems.TechnicalDetails.General.TypeApprovalCategory : '',
              vehicle_class: dataItems.VehicleRegistration.VehicleClass,
              gross_weight: dataItems.TechnicalDetails.Dimensions.GrossVehicleWeight ? dataItems.TechnicalDetails.Dimensions.GrossVehicleWeight : 0,
              length: dataItems.TechnicalDetails.Dimensions.CarLength ? dataItems.TechnicalDetails.Dimensions.CarLength : 0,
              height: dataItems.TechnicalDetails.Dimensions.Height ? dataItems.TechnicalDetails.Dimensions.Height : 0,
              width: dataItems.TechnicalDetails.Dimensions.Width ? dataItems.TechnicalDetails.Dimensions.Width : 0,
              power_bhp: dataItems.TechnicalDetails.Performance.Power.Bhp ? dataItems.TechnicalDetails.Performance.Power.Bhp : 0,
              power_kw: dataItems.TechnicalDetails.Performance.Power.Kw ? dataItems.TechnicalDetails.Performance.Power.Kw : 0,
              power_rpm: dataItems.TechnicalDetails.Performance.Power.Rpm ? dataItems.TechnicalDetails.Performance.Power.Rpm : 0,
              torque_ftlb: dataItems.TechnicalDetails.Performance.Torque.FtLb ? dataItems.TechnicalDetails.Performance.Torque.FtLb : 0,
              torque_nm: dataItems.TechnicalDetails.Performance.Torque.Nm ? dataItems.TechnicalDetails.Performance.Torque.Nm : 0,
              torque_rpm: dataItems.TechnicalDetails.Performance.Torque.Rpm ? dataItems.TechnicalDetails.Performance.Torque.Rpm : 0,
              max_speed_mph: dataItems.TechnicalDetails.Performance.MaxSpeed.Mph ? dataItems.TechnicalDetails.Performance.MaxSpeed.Mph : 0,
              max_speed_kph: dataItems.TechnicalDetails.Performance.MaxSpeed.Kph ? dataItems.TechnicalDetails.Performance.MaxSpeed.Kph : 0,
              cylinders: dataItems.TechnicalDetails.General.Engine.NumberOfCylinders ? dataItems.TechnicalDetails.General.Engine.NumberOfCylinders : 0,
              valves_per_cyl: dataItems.TechnicalDetails.General.Engine.ValvesPerCylinder ? dataItems.TechnicalDetails.General.Engine.ValvesPerCylinder : 0,
              stroke: dataItems.TechnicalDetails.General.Engine.Stroke ? dataItems.TechnicalDetails.General.Engine.Stroke : '',
              bore: dataItems.TechnicalDetails.General.Engine.Bore ? dataItems.TechnicalDetails.General.Engine.Bore : '',
              arrangement: dataItems.TechnicalDetails.General.Engine.CylinderArrangement ? dataItems.TechnicalDetails.General.Engine.CylinderArrangement : 0,
              cam_type: dataItems.TechnicalDetails.General.Engine.ValveGear ? dataItems.TechnicalDetails.General.Engine.ValveGear : '',
              engine_location: dataItems.TechnicalDetails.General.Engine.Location ? dataItems.TechnicalDetails.General.Engine.Location : '',
              aspiration: dataItems.TechnicalDetails.General.Engine.Aspiration ? dataItems.TechnicalDetails.General.Engine.Aspiration : '',
              total_keepers: dataItems.VehicleHistory.NumberOfPreviousKeepers ? Number(dataItems.VehicleHistory.NumberOfPreviousKeepers) + 1 : 0,
              colour_changes: dataItems.VehicleHistory.ColourChangeCount ? dataItems.VehicleHistory.ColourChangeCount : 0,
              v5_certificates: dataItems.VehicleHistory.V5CCertificateCount ? dataItems.VehicleHistory.V5CCertificateCount : 0,
              mot_date: motTaxData.NextMotDueDate ? motTaxData.NextMotDueDate : null,
              rfl_taxed_date: motTaxData.MotVed.VedExpiryDate ? moment(motTaxData.MotVed.VedExpiryDate).format('DD/MM/YYYY') : null,
              vehicleGear: {
                name: dataItems.VehicleRegistration.Transmission
              },
              vehicleFuel: {
                name: dataItems.VehicleRegistration.FuelType
              }
            })
            notify('success', 'Success', 'Got vehicle data successfully, please click below Save button if you need to save them')
            setUpdateVehicleEnable(true)
          } else {
            notify('warning', 'Getting vehicle data warning', _vehicle_data.Response.StatusMessage)
          }
        }
      }))
      .catch(error => {
        console.error('error :: ', error)
      })
  }

  const addTask = () => {
    axios({
      method: 'POST',
      url: `${constants.API_URL}/vehicle/${id}/task`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: { ...task, task_category: 'vehicle-manually' },
    })
      .then(response => {
        setAddTaskModal({
          ...addTaskModal,
          show: false
        })
        if (response.status == 200) {
          const data = response.data;
          console.log('response data :: ', data);
          setVehicle({
            ...vehicle,
            tasks: data.data.tasks,
          })
          setTask('');
          dispatch(actions.getNotifications(token));
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setTaskErrors('');
        console.error('error :: ', error.response);
        if (error.response && error.response.status == 422) {
          setTaskErrors(error.response.data.errors)
          return
        }
        setAddTaskModal({
          ...addTaskModal,
          show: false
        });
        notify('warning', 'Warning', error.response && error.response.statusText ? error.response.statusText : 'Error occur.');
      })
  }

  const updateTask = () => {
    axios({
      method: 'PUT',
      url: `${constants.API_URL}/vehicle/${id}/task/${task.uuid}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        ...task,
        task_assignee: task.taskAssignee,
      },
    })
      .then(response => {
        setAddTaskModal({
          ...addTaskModal,
          show: false
        })
        if (response.status == 200) {
          const data = response.data;
          console.log('response data :: ', data);
          setVehicle({
            ...vehicle,
            tasks: data.data.tasks,
          })
          setTask('');
          dispatch(actions.getNotifications(token));
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setTaskErrors('');
        console.error('error :: ', error.response);
        if (error.response && error.response.status == 422) {
          setTaskErrors(error.response.data.errors)
          return
        }
        setAddTaskModal({
          ...addTaskModal,
          show: false
        });
        notify('warning', 'Warning', error.response && error.response.statusText ? error.response.statusText : 'Error occur.');
      })
  }

  const deleteTask = (taskId) => {
    axios({
      method: 'DELETE',
      url: `${constants.API_URL}/vehicle/${id}/task/${taskId}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
    })
      .then(response => {
        setDeleteModal({
          ...deleteModal,
          show: false,
        });
        if (response.status == 200) {
          const data = response.data;
          console.log('response data :: ', data);
          setVehicle({
            ...vehicle,
            tasks: data.data.tasks,
          })
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setDeleteModal({
          ...deleteModal,
          show: false,
        });
        console.error('error :: ', error.response);
        notify('warning', 'Warning', error.response && error.response.statusText ? error.response.statusText : 'Error occur.');
      })
  }

  const selectMake = (makeId) => {
    let _models = []
    if (makeId === '-') {
      setFilteredModels(models)
      return
    }
    if (models.length > 0) {
      _models = models.filter((model) => {
        if (model.vehicle_make && model.vehicle_make.id == makeId) {
          return model
        }
      })

      setFilteredModels(_models)
    }
  }
   const downloadDocument =(url)=>{
     const link = document.createElement("a");
     link.setAttribute('target', '_blank');
     link.href = url;
     link.download = 'file.pdf';
     link.click();
     // fetch(url, { method: 'get', referrerPolicy: 'no-referrer' })
     //     .then(res => res.blob())
     //     .then(res => {
     //
     //       const aElement = document.createElement('a');
     //       aElement.setAttribute('download', url.split('/').pop());
     //       const href = URL.createObjectURL(res);
     //       aElement.href = href;
     //       aElement.setAttribute('target', '_blank');
     //       aElement.click();
     //       URL.revokeObjectURL(href);
     //     });
   };

  return (
    <>
      <CardsHeader name="Vehicle" backButtonText={'Back to Vehicles'}  showBackButton={true} parentName="Vehicle Management" redirect={()=> history.push(`/admin/vehicle`,)} currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Card>
          <CardHeader>
            <Row>
                <div className={'navbar-nav cursor-pointer flex-column mr-2'}>
                       <span
                           style={{color: tab === 'details' && '#ec6409', margin: 5}}
                           onClick={() => setTab('details')}>
                        Vehicle Details
                       </span>
                  {tab === 'details' &&
                  <Line color={'#ec6409'}/>
                  }
                </div>
                <div className={'navbar-nav cursor-pointer flex-column mr-2'}>
                       <span
                           style={{color: tab === 'financial' && '#ec6409', margin: 5}}
                           onClick={() => setTab('financial')}>
                        Financial
                       </span>
                  {tab === 'financial' &&
                  <Line color={'#ec6409'}/>
                  }
                </div>
                <div className={'navbar-nav cursor-pointer flex-column mr-2'}>
                       <span
                           style={{color: tab === 'frontend' && '#ec6409', margin: 5}}
                           onClick={() => setTab('frontend')}>
                        Front End
                       </span>
                  {tab === 'frontend' &&
                  <Line color={'#ec6409'}/>
                  }
                </div>
                <div className={'navbar-nav cursor-pointer flex-column mr-2'}>
                       <span
                           style={{color: tab === 'documents' && '#ec6409', margin: 5}}
                           onClick={() => setTab('documents')}>
                       Documents
                       </span>
                  {tab === 'documents' &&
                  <Line color={'#ec6409'}/>
                  }
                </div>
                <div className={'navbar-nav cursor-pointer flex-column mr-2'}>
                       <span
                           style={{color: tab === 'tasks' && '#ec6409', margin: 5}}
                           onClick={() => setTab('tasks')}>
                       Tasks
                       </span>
                  {tab === 'tasks' &&
                  <Line color={'#ec6409'}/>
                  }
                </div>
            </Row>
          </CardHeader>
          {tab === 'details' &&
            <CardBody>
              <Row>
                <Col md={9} sm={12}>

                </Col>
                <Col md={3} sm={12}>
                  <ButtonContainer  onClick={() => getValuation()}>Get Valuation</ButtonContainer>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <h2>{`Valuations ${vehicle && vehicle.valuation_time ? `(${vehicle.valuation_time})` : ''}`}</h2>
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel label={`Dealer Forecourt`} value={vehicle && vehicle.dealer_forecourt ? `${constants.CURRENCY} ${vehicle.dealer_forecourt}` : ` `} />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel label={`Part Exchange`} value={vehicle && vehicle.part_exchange ? `${constants.CURRENCY} ${vehicle.part_exchange}` : ` `} />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel label={`Trade Poor`} value={vehicle && vehicle.trade_poor ? `${constants.CURRENCY} ${vehicle.trade_poor}` : ` `} />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel label={`Trade Retail`} value={vehicle && vehicle.trade_retail ? `${constants.CURRENCY} ${vehicle.trade_retail}` : ` `} />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel label={`Trade Average`} value={vehicle && vehicle.trade_average ? `${constants.CURRENCY} ${vehicle.trade_average}` : ` `} />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel label={`Auction`} value={vehicle && vehicle.auction ? `${constants.CURRENCY} ${vehicle.auction}` : ` `} />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel label={`Settlement figure`} value={settlementCost && settlementCost.settlementCost ? `${constants.CURRENCY} ${settlementCost.settlementCost}` : ` `} />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <ButtonContainer reverse onClick={() => updateVehicle()} disabled={!updateValuationEnable}>Save</ButtonContainer>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md={9} sm={12}>

                </Col>
                <Col md={3} sm={12}>
                  <ButtonContainer  onClick={() => getVehicleData()}>Get Vehicle Data</ButtonContainer>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md={12}>
                  <h2>{`Vehicle registration`}</h2>
                </Col>
                <Col md={3} sm={12}>
                  <FormGroup>
                    <label style={{
                      fontSize: '16px',
                      fontWeight: 400,
                      borderBottom: '1px solid #c0c0c0',
                      color: '#2d2d2d',
                      lineHeight: '16px'
                    }}>
                      {`Make`}
                    </label>
                    <div
                      style={{
                        fontWeight: '600',
                        fontSize: '16px',
                        color: '#414141',
                        cursor: 'pointer',
                        textDecorationLine: 'underline',
                        textDecorationStyle: 'dotted'
                      }}
                      dangerouslySetInnerHTML={{
                        __html: vehicle && vehicle.vehicleMake && vehicle.vehicleMake.name ? `${vehicle.vehicleMake.name.toUpperCase()}` : ` `
                      }}
                      onClick={() => {
                        setUpdateMakeModelModal({
                          ...updateMakeModelModal,
                          show: true,
                        })
                        selectMake(vehicle.redundant_vehicle_make_id ? vehicle.redundant_vehicle_make_id : '-')
                      }}
                    >
                    </div>
                  </FormGroup>
                </Col>
                <Col md={3} sm={12}>
                  <FormGroup>
                    <label style={{
                      fontSize: '16px',
                      fontWeight: 400,
                      borderBottom: '1px solid #c0c0c0',
                      color: '#2d2d2d',
                      lineHeight: '16px'
                    }}>
                      {`Model`}
                    </label>
                    <div
                      style={{
                        fontWeight: '600',
                        fontSize: '16px',
                        color: '#414141',
                        cursor: 'pointer',
                        textDecorationLine: 'underline',
                        textDecorationStyle: 'dotted'
                      }}
                      dangerouslySetInnerHTML={{
                        __html: vehicle && vehicle.vehicleModel && vehicle.vehicleModel.name ? `${vehicle.vehicleModel.name.toUpperCase()}` : ` `
                      }}
                      onClick={() => {
                        setUpdateMakeModelModal({
                          ...updateMakeModelModal,
                          show: true,
                        })
                        selectMake(vehicle.redundant_vehicle_make_id ? vehicle.redundant_vehicle_make_id : '-')
                      }}
                    >
                    </div>
                  </FormGroup>
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel label={`Status`} value={vehicle && vehicle.status ? `${vehicle.status.toUpperCase()}` : ` `} />
                </Col>
                <Col md={3} sm={12}>
                  <FormGroup>
                    <label style={{
                      fontSize: '16px',
                      fontWeight: 400,
                      borderBottom: '1px solid #c0c0c0',
                      color: '#2d2d2d',
                      lineHeight: '16px'
                    }}>
                      {`Derivative`}
                    </label>
                    <div
                      style={{
                        fontWeight: '600',
                        fontSize: '16px',
                        color: '#414141',
                        cursor: 'pointer',
                        textDecorationLine: 'underline',
                        textDecorationStyle: 'dotted'
                      }}
                      dangerouslySetInnerHTML={{
                        __html: vehicle && vehicle.derivative ? `${vehicle.derivative.toUpperCase()}` : `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;`
                      }}
                      onClick={() => {
                        setUpdateVehicleModal({
                          ...updateVehicleModal,
                          show: true,
                          header: 'Derivative',
                          key: 'derivative'
                        })
                      }}
                    >
                    </div>
                  </FormGroup>
                </Col>
                <Col md={3} sm={12}>
                  <FormGroup>
                    <label style={{
                      fontSize: '16px',
                      fontWeight: 400,
                      borderBottom: '1px solid #c0c0c0',
                      color: '#2d2d2d',
                      lineHeight: '16px'
                    }}>
                      {`Mileage`}
                    </label>
                    <div
                      style={{
                        fontWeight: '600',
                        fontSize: '16px',
                        color: '#414141',
                        cursor: 'pointer',
                        textDecorationLine: 'underline',
                        textDecorationStyle: 'dotted'
                      }}
                      dangerouslySetInnerHTML={{
                        __html: vehicle && vehicle.current_mileage ? `${vehicle.current_mileage}` : ` `
                      }}
                      onClick={() => {
                        setUpdateVehicleModal({
                          ...updateVehicleModal,
                          show: true,
                          header: 'Mileage',
                          key: 'current_mileage'
                        })
                      }}
                    >
                    </div>
                  </FormGroup>
                </Col>
                <Col md={3} sm={12}>
                  <FormGroup>
                    <label style={{
                      fontSize: '16px',
                      fontWeight: 400,
                      borderBottom: '1px solid #c0c0c0',
                      color: '#2d2d2d',
                      lineHeight: '16px'
                    }}>
                      {`VRM`}
                    </label>
                    <div
                      style={{
                        fontWeight: '600',
                        fontSize: '16px',
                        color: '#414141',
                        cursor: 'pointer',
                        textDecorationLine: 'underline',
                        textDecorationStyle: 'dotted'
                      }}
                      dangerouslySetInnerHTML={{
                        __html: vehicle && vehicle.stock_no ? `${vehicle.stock_no.toUpperCase()}` : ` `
                      }}
                      onClick={() => {
                        setUpdateVehicleModal({
                          ...updateVehicleModal,
                          show: true,
                          header: 'VRM',
                          key: 'stock_no'
                        })
                      }}
                    >
                    </div>
                  </FormGroup>
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel label={`Full VIN`} value={vehicle && vehicle.full_vin ? `${vehicle.full_vin.toUpperCase()}` : ` `} />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel label={`Engin number`} value={vehicle && vehicle.engine_number ? `${vehicle.engine_number.toUpperCase()}` : ` `} />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel label={`First registered`} value={vehicle && vehicle.first_registered ? constants.formatDateShortGB(vehicle.first_registered) : ` `} />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel label={`First registered UK`} value={vehicle && vehicle.first_registered_uk ? constants.formatDateShortGB(vehicle.first_registered_uk) : ` `} />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel label={`Year manufacture`} value={vehicle && vehicle.year_manufacture ? vehicle.year_manufacture : ` `} />
                </Col>
                <Col md={3} sm={12}>
                  <FormGroup>
                    <label style={{
                      fontSize: '16px',
                      fontWeight: 400,
                      borderBottom: '1px solid #c0c0c0',
                      color: '#2d2d2d',
                      lineHeight: '16px'
                    }}>
                      {`Color`}
                    </label>
                    <div
                      style={{
                        fontWeight: '600',
                        fontSize: '16px',
                        color: '#414141',
                        cursor: 'pointer',
                        textDecorationLine: 'underline',
                        textDecorationStyle: 'dotted'
                      }}
                      dangerouslySetInnerHTML={{
                        __html: vehicle && vehicle.colour ? `${vehicle.colour.toUpperCase()}` : `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;`
                      }}
                      onClick={() => {
                        setUpdateVehicleModal({
                          ...updateVehicleModal,
                          show: true,
                          header: 'Color',
                          key: 'colour'
                        })
                      }}
                    >
                    </div>
                  </FormGroup>
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel label={`Gearbox`} value={vehicle && vehicle.vehicleGear && vehicle.vehicleGear.name ? vehicle.vehicleGear.name.toUpperCase() : ` `} />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel label={`Forward gears`} value={vehicle && vehicle.gears_number ? `${vehicle.gears_number} gears` : ` `} />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel label={`Fuel`} value={vehicle && vehicle.vehicleFuel && vehicle.vehicleFuel.name ? vehicle.vehicleFuel.name.toUpperCase() : ` `} />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel label={`Engine capacity`} value={vehicle && vehicle.engine_capacity ? `${vehicle.engine_capacity}cc` : ` `} />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel label={`Door plan`} value={vehicle && vehicle.door_plan ? `${vehicle.door_plan.toUpperCase()}` : ` `} />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel label={`Seating capacity`} value={vehicle && vehicle.seating_capacity ? `${vehicle.seating_capacity} seats` : ` `} />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel label={`CO2`} value={vehicle && vehicle.co2 ? `${vehicle.co2}g/km` : ` `} />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel label={`Kerb weight`} value={vehicle && vehicle.kerb_weight ? `${vehicle.kerb_weight}kg` : ` `} />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel label={`VIN last 5`} value={vehicle && vehicle.vin_last_5 ? `${vehicle.vin_last_5}` : ` `} />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel label={`Scrapped`} value={vehicle && vehicle.scrapped ? `${vehicle.scrapped}` : `NO`} />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel label={`Date scrapped`} value={vehicle && vehicle.date_scrapped ? `${vehicle.date_scrapped}` : `Not scrapped`} />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel label={`Exported`} value={vehicle && vehicle.exported ? `${vehicle.exported}` : `NO`} />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel label={`Date exported`} value={vehicle && vehicle.date_exported ? `${vehicle.date_exported}` : `Not exported`} />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel label={`Imported`} value={vehicle && vehicle.imported ? `${vehicle.imported}` : `NO`} />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel label={`Imported Non EU`} value={vehicle && vehicle.imported_non_eu ? `${vehicle.imported_non_eu}` : `NO`} />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel label={`Euro status`} value={vehicle && vehicle.euro_status ? `${vehicle.euro_status}` : ` `} />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel label={`Driven Axle`} value={vehicle && vehicle.driven_axle ? `${vehicle.driven_axle}` : ` `} />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel label={`Type approval`} value={vehicle && vehicle.type_approval ? `${vehicle.type_approval}` : ` `} />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel label={`Vehicle Class`} value={vehicle && vehicle.vehicle_class ? `${vehicle.vehicle_class}` : ` `} />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel label={`Total keepers`} value={vehicle && vehicle.total_keepers ? `${vehicle.total_keepers}` : ` `} />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel label={`Colour changes`} value={vehicle && vehicle.colour_changes ? `${vehicle.colour_changes}` : ` `} />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel label={`V5 certificates`} value={vehicle && vehicle.v5_certificates ? `${vehicle.v5_certificates}` : ` `} />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md={12}>
                  <h2>{`Technical Data`}</h2>
                </Col>
                <Col md={6} sm={12}>
                  <Card>
                    <CardHeader>
                      <h2 className="mb-0 main-color">{`Weights & Dimensions`}</h2>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md={3} sm={12}>
                          <VerticalLabel label={`Kerb weight`} value={vehicle && vehicle.kerb_weight ? `${vehicle.kerb_weight}<sup>kg</sup>` : ` `} />
                        </Col>
                        <Col md={3} sm={12}>
                          <VerticalLabel label={`Gross weight`} value={vehicle && vehicle.gross_weight ? `${vehicle.gross_weight}<sup>kg</sup>` : ` `} />
                        </Col>
                        <Col md={3} sm={12}>
                          <VerticalLabel label={`Length`} value={vehicle && vehicle.length ? `${vehicle.length}<sup>mm</sup>` : ` `} />
                        </Col>
                        <Col md={3} sm={12}>
                          <VerticalLabel label={`Height`} value={vehicle && vehicle.height ? `${vehicle.height}<sup>mm</sup>` : ` `} />
                        </Col>
                        <Col md={3} sm={12}>
                          <VerticalLabel label={`Width`} value={vehicle && vehicle.width ? `${vehicle.width}<sup>mm</sup>` : ` `} />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6} sm={12}>
                  <Card>
                    <CardHeader>
                      <h2 className="mb-0 main-color">{`Performance`}</h2>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md={3} sm={12}>
                          <VerticalLabel label={`Power Bhp`} value={vehicle && vehicle.power_bhp ? `${vehicle.power_bhp}` : ` `} />
                        </Col>
                        <Col md={3} sm={12}>
                          <VerticalLabel label={`Power Kw`} value={vehicle && vehicle.power_kw ? `${vehicle.power_kw}` : ` `} />
                        </Col>
                        <Col md={3} sm={12}>
                          <VerticalLabel label={`Peak power`} value={vehicle && vehicle.power_rpm ? `${vehicle.power_rpm}rpm` : ` `} />
                        </Col>
                        <Col md={3} sm={12}>
                          <VerticalLabel label={`Torque FtLb`} value={vehicle && vehicle.torque_ftlb ? `${vehicle.torque_ftlb}` : ` `} />
                        </Col>
                        <Col md={3} sm={12}>
                          <VerticalLabel label={`Torque Nm`} value={vehicle && vehicle.torque_nm ? `${vehicle.torque_nm}` : ` `} />
                        </Col>
                        <Col md={3} sm={12}>
                          <VerticalLabel label={`Peak Torque`} value={vehicle && vehicle.torque_rpm ? `${vehicle.torque_rpm}rpm` : ` `} />
                        </Col>
                        <Col md={3} sm={12}>
                          <VerticalLabel label={`Max speed`} value={vehicle && vehicle.max_speed_mph ? `${vehicle.max_speed_mph}mph` : ` `} />
                        </Col>
                        <Col md={3} sm={12}>
                          <VerticalLabel label={`Max speed`} value={vehicle && vehicle.max_speed_kph ? `${vehicle.max_speed_kph}kph` : ` `} />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6} sm={12}>
                  <Card>
                    <CardHeader>
                      <h2 className="mb-0 main-color">{`Engine technical`}</h2>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md={3} sm={12}>
                          <VerticalLabel label={`Cylinders`} value={vehicle && vehicle.cylinders ? `${vehicle.cylinders}` : ` `} />
                        </Col>
                        <Col md={3} sm={12}>
                          <VerticalLabel label={`Valves/cyl`} value={vehicle && vehicle.valves_per_cyl ? `${vehicle.valves_per_cyl}` : ` `} />
                        </Col>
                        <Col md={3} sm={12}>
                          <VerticalLabel label={`Stroke`} value={vehicle && vehicle.stroke ? `${vehicle.stroke}mm` : ` `} />
                        </Col>
                        <Col md={3} sm={12}>
                          <VerticalLabel label={`Bore`} value={vehicle && vehicle.bore ? `${vehicle.bore}mm` : ` `} />
                        </Col>
                        <Col md={3} sm={12}>
                          <VerticalLabel label={`Arrangement`} value={vehicle && vehicle.arrangement ? `${vehicle.arrangement}` : ` `} />
                        </Col>
                        <Col md={3} sm={12}>
                          <VerticalLabel label={`Cam type`} value={vehicle && vehicle.cam_type ? `${vehicle.cam_type}` : ` `} />
                        </Col>
                        <Col md={3} sm={12}>
                          <VerticalLabel label={`Engine location`} value={vehicle && vehicle.engine_location ? `${vehicle.engine_location}` : ` `} />
                        </Col>
                        <Col md={3} sm={12}>
                          <VerticalLabel label={`Aspiration`} value={vehicle && vehicle.aspiration ? `${vehicle.aspiration}` : ` `} />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6} sm={12}>
                  <Card>
                    <CardHeader>
                      <h2 className="mb-0 main-color">{`Mot & Tax data`}</h2>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md={6} sm={12}>
                          <VerticalLabel label={`Next mot due date`} value={vehicle && vehicle.mot_date ? vehicle.mot_date : ` `} />
                        </Col>
                        <Col md={6} sm={12}>
                          <VerticalLabel label={`Days until next mot is due`} value={vehicle && vehicle.mot_date ? moment(vehicle.mot_date, 'DD-MM-YYYY').diff(moment(), 'days') : ` `} />
                        </Col>
                        <Col md={6} sm={12}>
                          <VerticalLabel label={`Ved expiry date`} value={vehicle && vehicle.rfl_taxed_date ? vehicle.rfl_taxed_date : ` `} />
                        </Col>
                        <Col md={6} sm={12}>
                          <VerticalLabel label={`Ved days remaining`} value={vehicle && vehicle.rfl_taxed_date ? moment(vehicle.rfl_taxed_date, 'DD-MM-YYYY').diff(moment(), 'days') : ` `} />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <ButtonContainer reverse onClick={() => updateVehicle()} disabled={!updateVehicleEnable}>Save</ButtonContainer>
                </Col>
              </Row>
            </CardBody>
          }
          {tab === 'financial' &&
            <CardBody>
              <Card>
                <CardHeader>
                  <h2 className="title mb-0">Financial information</h2>
                  <Row className="mt-3">
                    <Col md={12}>
                      <ButtonContainer onClick={() => financialDetails()}>Financial details</ButtonContainer>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={6} sm={12}>
                      <InputCustom
                        type={`date`}
                        newLabel={`Delivered to us`}
                        value={vehicle && vehicle.delivered_to_us ? vehicle.delivered_to_us : ''}
                        onChange={e => setVehicle({
                          ...vehicle,
                          delivered_to_us: e.target.value,
                        })}
                        invalid={soldErrors && soldErrors.delivered_to_us ? true : false}
                        errorMessage={soldErrors.delivered_to_us}
                      />
                      <InputArraySelect
                          newLabel={`Funding styles`}
                        value={vehicle && vehicle.funding_style_id ? vehicle.funding_style_id : ''}
                        options={fundingStyles}
                        onChange={e => setVehicle({
                          ...vehicle,
                          funding_style_id: e.target.value,
                        })}
                        valueKey={`id`}
                        labelKey={`name`}
                        defaultOption={true}
                        invalid={errors && errors.funding_style_id ? true : false}
                        errorMessage={errors.funding_style_id}
                      />
                      <InputCustom
                        type={`number`}
                        newLabel={`Purchase Price`}
                        value={vehicle && vehicle.otr ? vehicle.otr : ''}
                        onChange={e => setVehicle({
                          ...vehicle,
                          otr: e.target.value,
                        })}
                        invalid={soldErrors && soldErrors.otr ? true : false}
                        errorMessage={soldErrors.otr}
                      />
                      <InputCustom
                        type={`number`}
                        newLabel={`Total`}
                        value={vehicle && vehicle.total ? vehicle.total : ''}
                        onChange={e => setVehicle({
                          ...vehicle,
                          total: e.target.value,
                        })}
                        invalid={soldErrors && soldErrors.total ? true : false}
                        errorMessage={soldErrors.total}
                      />
                      <InputCustom
                        type={`number`}
                        newLabel={`Balance financed`}
                        value={vehicle && vehicle.balance_financed ? vehicle.balance_financed : ''}
                        onChange={e => setVehicle({
                          ...vehicle,
                          balance_financed: e.target.value,
                        })}
                        invalid={soldErrors && soldErrors.balance_financed ? true : false}
                        errorMessage={soldErrors.balance_financed}
                      />
                      <InputCustom
                        type={`number`}
                        newLabel={`Term`}
                        value={vehicle && vehicle.term ? vehicle.term : ''}
                        onChange={e => setVehicle({
                          ...vehicle,
                          term: e.target.value,
                        })}
                        invalid={soldErrors && soldErrors.term ? true : false}
                        errorMessage={soldErrors.term}
                      />
                      <InputCustom
                        type={`text`}
                        newLabel={`Profile`}
                        value={vehicle && vehicle.profile ? vehicle.profile : ''}
                        onChange={e => setVehicle({
                          ...vehicle,
                          profile: e.target.value,
                        })}
                        invalid={soldErrors && soldErrors.profile ? true : false}
                        errorMessage={soldErrors.profile}
                      />
                      <InputCustom
                        type={`number`}
                        newLabel={`Option to purchase`}
                        value={vehicle && vehicle.end_payment ? vehicle.end_payment : ''}
                        onChange={e => setVehicle({
                          ...vehicle,
                          end_payment: e.target.value,
                        })}
                        invalid={soldErrors && soldErrors.end_payment ? true : false}
                        errorMessage={soldErrors.end_payment}
                      />
                    </Col>
                    <Col md={6} sm={12}>
                      <InputCustom
                        type={`date`}
                        newLabel={`End Date`}
                        value={vehicle && vehicle.end_date ? vehicle.end_date : ''}
                        onChange={e => setVehicle({
                          ...vehicle,
                          end_date: e.target.value,
                        })}
                        invalid={soldErrors && soldErrors.end_date ? true : false}
                        errorMessage={soldErrors.end_date}
                      />
                      <InputCustom
                          newLabel={`Funder`}
                        value={vehicle && vehicle.supplying_dealer ? vehicle.supplying_dealer : ''}
                        onChange={e => setVehicle({
                          ...vehicle,
                          supplying_dealer: e.target.value,
                        })}
                        invalid={soldErrors && soldErrors.supplying_dealer ? true : false}
                        errorMessage={soldErrors.supplying_dealer}
                      />
                      <InputCustom
                        type={`number`}
                        newLabel={`VAT`}
                        value={vehicle && vehicle.total_deposit ? vehicle.total_deposit : ''}
                        onChange={e => setVehicle({
                          ...vehicle,
                          total_deposit: e.target.value,
                        })}
                        invalid={soldErrors && soldErrors.total_deposit ? true : false}
                        errorMessage={soldErrors.total_deposit}
                      />
                      <InputCustom
                        type={`number`}
                        newLabel={`Deposit`}
                        value={vehicle && vehicle.non_refundable_deposit ? vehicle.non_refundable_deposit : ''}
                        onChange={e => setVehicle({
                          ...vehicle,
                          non_refundable_deposit: e.target.value,
                        })}
                        invalid={soldErrors && soldErrors.non_refundable_deposit ? true : false}
                        errorMessage={soldErrors.non_refundable_deposit}
                      />
                      <InputCustom
                        type={`number`}
                        newLabel={`Monthly Cost`}
                        value={vehicle && vehicle.monthly_cost ? vehicle.monthly_cost : ''}
                        onChange={e => setVehicle({
                          ...vehicle,
                          monthly_cost: e.target.value,
                        })}
                        invalid={soldErrors && soldErrors.monthly_cost ? true : false}
                        errorMessage={soldErrors.monthly_cost}
                      />
                      <InputKeySelect
                          newLabel={`Interval`}
                        value={vehicle && vehicle.interval ? vehicle.interval : ''}
                        options={intervals}
                        onChange={e => setVehicle({
                          ...vehicle,
                          interval: e.target.value,
                        })}
                        invalid={soldErrors && soldErrors.interval ? true : false}
                        errorMessage={soldErrors.interval}
                      />
                      <InputKeySelect
                          newLabel={`VAT Deferral`}
                        value={vehicle && vehicle.vat_deferral ? vehicle.vat_deferral : ''}
                        options={{
                          no: 'No',
                          yes: 'Yes',
                        }}
                        onChange={e => setVehicle({
                          ...vehicle,
                          vat_deferral: e.target.value,
                        })}
                        invalid={soldErrors && soldErrors.vat_deferral ? true : false}
                        errorMessage={soldErrors.vat_deferral}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <ButtonContainer reverse onClick={() => updateVehicle()}>Save</ButtonContainer>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h2>Sold process</h2>
                  <Row>
                    <Col md={4} sm={12}>
                      <InputCustom
                        type={`number`}
                        newLabel={`Sold amount`}
                        value={vehicle && vehicle.sold_amount ? vehicle.sold_amount : ''}
                        onChange={e => setVehicle({
                          ...vehicle,
                          sold_amount: e.target.value,
                        })}
                        invalid={soldErrors && soldErrors.sold_amount ? true : false}
                        errorMessage={soldErrors.sold_amount}
                      />
                      <InputCustom
                          type={`date`}
                          newLabel={`Sold at`}
                          value={vehicle && vehicle.sold_at ? vehicle.sold_at : ''}
                          onChange={e => setVehicle({
                            ...vehicle,
                            sold_at: e.target.value,
                          })}
                          invalid={soldErrors && soldErrors.sold_at ? true : false}
                          errorMessage={soldErrors.sold_at}
                      />
                      <InputCustom
                          type={`text`}
                          newLabel={`Sold To`}
                          value={vehicle && vehicle.sold_to ? vehicle.sold_to : ''}
                          onChange={e => setVehicle({
                            ...vehicle,
                            sold_to: e.target.value,
                          })}
                          invalid={soldErrors && soldErrors.sold_to ? true : false}
                          errorMessage={soldErrors.sold_to}
                      />
                    </Col>

                  </Row>
                </CardHeader>
              </Card>
              <Card>
                <CardHeader>
                  <h2>Settlement cost</h2>
                  {vehicle.status == vehicleRepository.sold
                    && vehicle.delivered_to_us
                    && vehicle.sold_at
                    && <h3 className="mt-3 text-muted">{`From ${constants.formatDate(vehicle.delivered_to_us)} To ${constants.formatDate(vehicle.sold_at)}`}</h3>}
                </CardHeader>
                <CardBody>
                  {delay && <Table className="align-items-center" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Amount Paid</th>
                        <th scope="col">Paid / Remained months</th>
                        <th scope="col">Monthly cost</th>
                        <th scope="col">Settlement cost</th>
                        <th scope="col">Total cost</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {settlementCost.totalPaid}
                        </td>
                        <td>
                          {`${settlementCost.paidMonths} / ${settlementCost.remainedMonths}`}
                        </td>
                        <td>
                          {settlementCost.monthlyCost}
                        </td>
                        <td>
                          <Input
                            type={`number`}
                            value={settlementCost && settlementCost.settlementCost ? settlementCost.settlementCost : ''}
                            onChange={e => setSettlementCost({
                              ...settlementCost,
                              settlementCost: e.target.value,
                            })}
                            readOnly={vehicleRepository.sold == vehicle.status ? true : false}
                          />
                        </td>
                        <td>
                          {settlementCost.totalCost}
                        </td>
                      </tr>
                    </tbody>
                  </Table>}
                  {vehicle.status != vehicleRepository.sold && <Row className={'justify-content-end'}>
                      <ButtonContainer  onClick={() => returnedToFunder()}>Returned to Funder</ButtonContainer>
                      <ButtonContainer reverse onClick={() => sold()}>Sold</ButtonContainer>
                  </Row>}
                </CardBody>
              </Card>
            </CardBody>
          }
          {tab === 'frontend' &&
            <CardBody>
              <Card>
                <CardHeader>
                  <h2>Financial details available for front end ( customer section )</h2>
                </CardHeader>
                <CardHeader>
                  <Row>
                    <div className={'navbar-nav cursor-pointer flex-column mr-2'}>
                       <span
                           style={{color: vehicle && vehicle.vehicle_division === 0 && '#ec6409', margin: 5}}
                           onClick={() => setVehicle({
                             ...vehicle,
                             vehicle_division: 0
                           })}>
                       Rental Deal
                       </span>
                      {vehicle && vehicle.vehicle_division === 0 &&
                      <Line color={'#ec6409'}/>
                      }
                    </div>
                    <div className={'navbar-nav cursor-pointer flex-column mr-2'}>
                       <span
                           style={{color: vehicle && vehicle.vehicle_division === 1 && '#ec6409', margin: 5}}
                           onClick={() => setVehicle({
                             ...vehicle,
                             vehicle_division: 1
                           })}>
                       Purchase Deal
                       </span>
                      {vehicle && vehicle.vehicle_division === 1 &&
                      <Line color={'#ec6409'}/>
                      }
                    </div>
                  </Row>

                </CardHeader>
                <CardBody>
                  {vehicle && vehicle.vehicle_division === 0 &&
                    <Row>
                      <Col md={4} sm={12}>
                        <InputCustom
                          type={`number`}
                          newLabel={`Estimated Price`}
                          value={vehicle && vehicle.estimated_term_price ? vehicle.estimated_term_price : ''}
                          onChange={e => setVehicle({
                            ...vehicle,
                            estimated_term_price: e.target.value,
                          })}
                          invalid={errors && errors.estimated_term_price ? true : false}
                          errorMessage={errors.estimated_term_price}
                        />
                      </Col>
                      <Col md={4} sm={12}>
                        <InputCustom
                          type={`number`}
                          newLabel={`Estimated Term`}
                          value={vehicle && vehicle.estimated_term ? vehicle.estimated_term : ''}
                          onChange={e => setVehicle({
                            ...vehicle,
                            estimated_term: e.target.value,
                          })}
                          invalid={errors && errors.estimated_term ? true : false}
                          errorMessage={errors.estimated_term}
                        />
                      </Col>
                      <Col md={4} sm={12}>
                        <InputCustom
                          type={`number`}
                          newLabel={`Initial Payment`}
                          value={vehicle && vehicle.estimated_initial_payment ? vehicle.estimated_initial_payment : ''}
                          onChange={e => setVehicle({
                            ...vehicle,
                            estimated_initial_payment: e.target.value,
                          })}
                          invalid={errors && errors.estimated_initial_payment ? true : false}
                          errorMessage={errors.estimated_initial_payment}
                        />
                      </Col>
                      <Col md={4} sm={12}>
                        <InputKeySelect
                            newLabel={`Interval`}
                          value={vehicle && vehicle.estimated_term_interval ? vehicle.estimated_term_interval : ''}
                          options={intervals}
                          onChange={e => setVehicle({
                            ...vehicle,
                            estimated_term_interval: e.target.value,
                          })}
                          defaultOption={true}
                          invalid={errors && errors.estimated_term_interval ? true : false}
                          errorMessage={errors.estimated_term_interval}
                        />
                      </Col>
                      <Col md={4} sm={12}>
                        <InputCustom
                          type={`number`}
                          newLabel={`28 days mileage`}
                          value={vehicle && vehicle.annual_mileage ? vehicle.annual_mileage : ''}
                          onChange={e => setVehicle({
                            ...vehicle,
                            annual_mileage: e.target.value,
                          })}
                          defaultOption={true}
                          invalid={errors && errors.annual_mileage ? true : false}
                          errorMessage={errors.annual_mileage}
                        />
                      </Col>
                    </Row>
                  }
                  {vehicle && vehicle.vehicle_division === 1 &&
                    <Row>
                      <Col md={3} sm={12}>
                        <InputCustom
                          type={`number`}
                          newLabel={`Purchase Price`}
                          value={vehicle && vehicle.purchase_price ? vehicle.purchase_price : ''}
                          onChange={e => setVehicle({
                            ...vehicle,
                            purchase_price: e.target.value,
                          })}
                          invalid={errors && errors.purchase_price ? true : false}
                          errorMessage={errors.purchase_price}
                        />
                      </Col>
                    </Row>
                  }
                  <Row>
                    <Col md={12}>
                      <ButtonContainer reverse onClick={() => updateVehicle()}>Save</ButtonContainer>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h2>Image Upload</h2>
                </CardHeader>
                <CardBody>
                  <div className="custom-file">
                    <input
                      className="custom-file-input profile-file-input"
                      id="featured_image"
                      lang="en"
                      type="file"
                      onChange={e => setVehicle({
                        ...vehicle,
                        featured_image: e.target.files[0]
                      })}
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="customFileLang"
                    >
                      Featured image
                    </label>
                  </div>
                  <div className="custom-file mt-3">
                    <input
                      className="custom-file-input profile-file-input"
                      id="images_collection"
                      lang="en"
                      type="file"
                      multiple
                      onChange={e => setVehicle({
                        ...vehicle,
                        images_collection: e.target.files
                      })}
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="customFileLang"
                    >
                      Images collection
                    </label>
                  </div>
                  <Row className="mt-3">
                    <Col md={12}>
                      <ButtonContainer reverse onClick={() => updateVehicle()}>Save</ButtonContainer>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md={12}>
                      <h2>Vehicle uploaded photos</h2>
                    </Col>
                  </Row>
                  <CardBody>
                    {vehicleImages.featured_image && <Row>
                      <Col md={12}>
                        <h2 className="text-center mb-3">Featured image</h2>
                        <div className="col-lg-8 offset-lg-2">
                          <img src={`${vehicleImages.featured_image}`} className="img-fluid" />
                        </div>
                      </Col>
                    </Row>}
                    {delay && vehicleImages.images_collection && vehicleImages.images_collection.length > 0 && <Row className="justify-content-md-center">
                      <Col md={7}>
                        <h2 className="text-center my-4">Images collection</h2>
                        <Carousel
                          activeIndex={activeIndex}
                          next={() => next(vehicleImages.images_collection)}
                          previous={() => previous(vehicleImages.images_collection)}
                        >
                          {
                            slides
                          }
                          <CarouselControl direction="prev" directionText="Previous" onClickHandler={() => previous(vehicleImages.images_collection)} />
                          <CarouselControl direction="next" directionText="Next" onClickHandler={() => next(vehicleImages.images_collection)} />

                        </Carousel>
                        <ol
                          className="carousel-indicators"
                          style={{
                            position: 'relative',
                            width: '100%',
                            margin: 0,
                            overflowX: 'scroll',
                            display: '-webkit-box',
                          }}>
                          {vehicleImages.images_collection.map((item, index) => {
                            return (
                              <li
                                key={index}
                                className={`${activeIndex === index ? 'active' : ''}`}
                                style={{
                                  position: 'relative',
                                  width: 220,
                                  height: 130,
                                  textIndent: 0,
                                }}
                              >
                                <img
                                  src={`${item.link}`}
                                  width={220}
                                  height={100}
                                  onClick={() => goToIndex(index)}
                                />
                                <span className="text-center carousel-trash">
                                  <i className="fas fa-trash text-danger" onClick={() => {
                                    setSelectedImage({
                                      ...selectedImage,
                                      id: item._id,
                                      index: index,
                                    })
                                    setConfirmModal(true)
                                  }}></i>
                                </span>
                              </li>
                            );
                          })}
                        </ol>
                      </Col>
                    </Row>}
                  </CardBody>
                </CardBody>
              </Card>
            </CardBody>
          }
          {tab === 'documents' &&
            <CardBody>
              <Card>
                <CardHeader>
                  <h1>Documents</h1>
                </CardHeader>
                <CardBody>
                  <div className="container">
                    <div {...getRootProps({ style })}>
                      <input {...getInputProps()} />
                      <p>Select or drop the documents, then match each of them to the right type</p>
                    </div>
                    <aside>
                      {fileLists.map((item, index) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              borderBottom: '1px solid #CCC',
                              alignItems: 'center',
                              padding: 20,
                            }}
                            key={index}
                          >
                            <Col md={3} sm={6}>
                              <span>{item.name}</span>
                            </Col>
                            <Col md={3} sm={6}>
                              <span>{`${item.size} bytes`}</span>
                            </Col>
                          </div>
                        )
                      })}
                    </aside>
                  </div>
                  <div className="d-flex flex-row justify-content-center mt-3">
                    <ButtonContainer className="w-25" color="success" onClick={() => uploadDocument()}>Save</ButtonContainer>
                  </div>
                  <h2 className="text-center mt-5">Uploaded documents</h2>
                  {vehicle.documents &&
                    <TableCustom
                      loading={false}
                      items={vehicle.documents}
                      keyField={`name`}
                      searchField={true}
                      columns={[
                        {
                          dataField: "name",
                          text: "Name",
                          sort: true,
                          formatter: (rowContent, row) => {
                            return `Document - ${row.document_name}`
                          }
                        },
                        {
                          dataField: "",
                          text: "Action",
                          formatter: (rowContent, row) => {
                            return (
                              <>
                                    <img className={'cursor-pointer'}
                                         onClick={e => viewDocument(row)}
                                         src={require(`assets/images/tableIcons/visibility.svg`)}
                                         alt=""/>
                                    <img className={'cursor-pointer'}
                                         onClick={e => setDeleteModal({
                                           ...deleteModal,
                                           show: true,
                                           header: 'Delete document',
                                           body: 'Are you sure to delete this document?',
                                           onClick: () => deleteDocument(row.id)
                                         })}
                                         src={require(`assets/images/tableIcons/delete_outline.svg`)}
                                         alt=""/>
                              </>
                            )
                          }
                        }
                      ]}
                    />}

                  {inspectionDocuments.length &&
                 <>
                  <h2 className="text-center mt-5">Uploaded pdfs</h2>
                  <TableCustom
                      loading={false}
                      items={inspectionDocuments}
                      keyField={`name`}
                      searchField={true}
                      columns={[
                        {
                          dataField: "name",
                          text: "Name",
                          sort: true,
                          formatter: (rowContent, row) => {
                            return `Document - ${row.document.split('/').pop()}`
                          }
                        },
                        {
                          dataField: "",
                          text: "Action",
                          formatter: (rowContent, row) => {
                            return (
                                <>
                                  <img className={'cursor-pointer'}
                                       height={'25px'}
                                       width={'25px'}
                                       onClick={e => downloadDocument(row.document)}
                                       src={require(`assets/images/tableIcons/visibility.svg`)}
                                       alt=""/>
                                </>
                            )
                          }
                        }
                      ]}
                  />
                      </>
                  }
                </CardBody>
              </Card>
            </CardBody>
          }
          {tab === 'tasks' &&
            <CardBody>
              <Row>
                <Col md={12}>
                  <div className="d-flex justify-content-between">
                    <div>
                      <h2 className="title mb-0">Tasks</h2>
                    </div>
                    {/* {hasPermission(profilePermissions, 'create-deals-tasks') && allowEdit && */}
                    <ButtonContainer reverse
                      onClick={() => {
                        setTask('')
                        setAddTaskModal({
                          ...addTaskModal,
                          show: true,
                          header: 'Add Task',
                          division: 'create'
                        })
                      }}
                    >Add task</ButtonContainer>
                    {/* } */}
                  </div>
                </Col>
              </Row>
              <TableCustom
                loading={false}
                delay={true}
                items={vehicle.tasks}
                keyField={`title`}
                searchField={true}
                columns={[
                  {
                    dataField: "title",
                    text: "Task",
                    sort: true
                  },
                  {
                    dataField: "due_date",
                    text: "Due at",
                    sort: true,
                    formatter: (rowContent, row) => {
                      return row.due_date ? constants.formatDateShortGB(row.due_date) : ''
                    }
                  },
                  {
                    dataField: "created_by",
                    text: "Created by",
                    sort: true,
                    formatter: (rowContent, row) => {
                      return row.owner.name
                    }
                  },
                  {
                    dataField: "status",
                    text: "Status",
                    sort: true,
                    formatter: (rowContent, row) => {
                      return vehicleTaskStatuses[row.status]
                    }
                  },
                  {
                    dataField: "",
                    text: "Action",
                    formatter: (rowContent, row) => {
                      return (
                        <>
                          {/* {hasPermission(profilePermissions, 'update-deals-tasks') && hasPermission(profilePermissions, 'delete-deals-tasks') && */}
                              {/* {update(profilePermissions, authUserTeam, deal) && */}
                              <img className={'cursor-pointer'}
                                   onClick={e => {
                                     setTask({
                                       ...row,
                                       due_date: constants.formatDate(row.due_date)
                                     })
                                     setTaskErrors('')
                                     setAddTaskModal({
                                       ...addTaskModal,
                                       show: true,
                                       header: 'Edit task',
                                       division: 'update'
                                     })
                                   }}
                                   src={require(`assets/images/tableIcons/edit.svg`)}
                                   alt=""/>
                              {/* } */}

                              <img className={'cursor-pointer'}
                                   onClick={e => {
                                     setDeleteModal({
                                       ...deleteModal,
                                       show: true,
                                       header: 'Delete task',
                                       body: 'Are you sure to delete this task?',
                                       onClick: () => deleteTask(row.uuid)
                                     })
                                   }}
                                   src={require(`assets/images/tableIcons/delete_outline.svg`)}
                                   alt=""/>
                          {/* } */}
                        </>
                      )
                    }
                  }
                ]}
              />
            </CardBody>
          }
        </Card>
        <Modal
          isOpen={confirmModal}
          toggle={() => setConfirmModal(false)}
          className="modal-dialog-centered"
        >
          <div className="modal-header main-color">
            <h6 className="modal-title">{`Delete Image`}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setConfirmModal(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body ">
            {`Are you sure you want to delete this image?`}
          </div>
          <div className="modal-footer justify-content-end">

            <ButtonContainer
              onClick={() => setConfirmModal(false)}
            >
              {`Close`}
            </ButtonContainer>
            <ButtonContainer
                reverse
                onClick={() => deleteImage()}
            >
              {`Delete`}
            </ButtonContainer>
          </div>
        </Modal>
        <Modal
          isOpen={deleteModal.show}
          toggle={() => setDeleteModal({
            ...deleteModal,
            show: false
          })}
          className="modal-dialog-centered"
        >
          <div className="modal-header main-color">
            <h6 className="modal-title">{deleteModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setDeleteModal({
                ...deleteModal,
                show: false
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body ">
            <span>{deleteModal.body}</span>
          </div>
          <div className="modal-footer justify-content-end">
            <ButtonContainer
              reverse
              onClick={deleteModal.onClick}
            >
              {`Delete`}
            </ButtonContainer>
          </div>
        </Modal>
        <Modal
          isOpen={addTaskModal.show}
          toggle={() => setAddTaskModal({
            ...addTaskModal,
            show: false
          })}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title">{addTaskModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setAddTaskModal({
                ...addTaskModal,
                show: false
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <InputCustom
              newLabel={`Task title`}
              value={task && task.title ? task.title : ''}
              onChange={(e) => setTask({
                ...task,
                title: e.target.value,
              })}
              invalid={taskErrors && taskErrors.title ? true : false}
              errorMessage={taskErrors && taskErrors.title ? taskErrors.title : ''}
            />
            <InputCustom
              type={`textarea`}
              newLabel={`Description`}
              rows={5}
              value={task && task.comments ? task.comments : ''}
              onChange={(e) => setTask({
                ...task,
                comments: e.target.value,
              })}
            />
            <FormGroup className={'d-flex align-items-center'}>
              <label className="form-control-label w-50">Assign to</label>
              <Select2
                className="form-control"
                multiple
                value={task && task.taskAssignee ? task.taskAssignee : ''}
                data={dealerMembers}
                onChange={(e) => setTask({
                  ...task,
                  taskAssignee: Array.from(e.target.selectedOptions, option => option.value),
                })}
              />
              {taskErrors && taskErrors.task_assignee && <div className="invalid-feedback" style={{ display: 'block' }}>
                {taskErrors.task_assignee}
              </div>}
            </FormGroup>
            <InputKeySelect
              newLabel={`Task status`}
              value={task && task.status ? task.status : ''}
              options={vehicleTaskStatuses}
              onChange={(e) => setTask({
                ...task,
                status: e.target.value,
              })}
              defaultOption={true}
              invalid={taskErrors && taskErrors.status ? true : false}
              errorMessage={taskErrors && taskErrors.status ? taskErrors.status : ''}
            />
            <InputCustom
              type={`date`}
              newLabel={`Due date`}
              value={task && task.due_date ? task.due_date : ''}
              onChange={(e) => setTask({
                ...task,
                due_date: e.target.value,
              })}
              invalid={taskErrors && taskErrors.due_date ? true : false}
              errorMessage={taskErrors && taskErrors.due_date ? taskErrors.due_date : ''}
            />
          </div>
          <div className="modal-footer justify-content-end">
            <ButtonContainer
              reverse
              onClick={() => addTaskModal.division === 'create' ? addTask() : updateTask()}
            >
              {`Save`}
            </ButtonContainer>
          </div>
        </Modal>
        <Modal
          isOpen={deleteModal.show}
          toggle={() => setDeleteModal({
            ...deleteModal,
            show: false
          })}
          className="modal-dialog-centered"
        >
          <div className="modal-header main-color">
            <h6 className="modal-title">{deleteModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setDeleteModal({
                ...deleteModal,
                show: false
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <span>{deleteModal.body}</span>
          </div>
          <div className="modal-footer">
            <ButtonContainer
              reverse
              onClick={deleteModal.onClick}
            >
              {`Delete`}
            </ButtonContainer>
          </div>
        </Modal>
        <Modal
          isOpen={updateVehicleModal.show}
          toggle={() => setUpdateVehicleModal({
            ...updateVehicleModal,
            show: false
          })}
          className="modal-dialog-centered"
        >
          <div className="modal-header ">
            <h6 className="modal-title">{`${updateVehicleModal.header} update`}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setUpdateVehicleModal({
                ...updateVehicleModal,
                show: false
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body" style={{ paddingBottom: 0 }}>
            <InputCustom
              newLabel={updateVehicleModal.header}
              value={vehicle && vehicle[updateVehicleModal.key] ? vehicle[updateVehicleModal.key] : ''}
              onChange={e => {
                setVehicle({
                  ...vehicle,
                  [updateVehicleModal.key]: e.target.value
                })
              }}
            />
          </div>
          <div className="modal-footer justify-content-end" style={{ paddingTop: 0 }}>
            <ButtonContainer
              reverse
              onClick={() => updateVehicle()}
            >
              {`Save`}
            </ButtonContainer>
          </div>
        </Modal>
        <Modal
          isOpen={updateMakeModelModal.show}
          toggle={() => setUpdateMakeModelModal({
            ...updateMakeModelModal,
            show: false
          })}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title">{`Make/Model update`}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setUpdateMakeModelModal({
                ...updateMakeModelModal,
                show: false
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body" style={{ paddingBottom: 0 }}>
            <InputArraySelect
              newLabel={`Make`}
              value={vehicle && vehicle.redundant_vehicle_make_id ? vehicle.redundant_vehicle_make_id : ''}
              options={makes}
              onChange={e => {
                setVehicle({
                  ...vehicle,
                  redundant_vehicle_make_id: e.target.value,
                })
                selectMake(e.target.value)
              }}
              valueKey={`id`}
              labelKey={`name`}
              defaultOption={true}
            />
            <InputArraySelect
              newLabel={`Vehicle model`}
              value={vehicle && vehicle.vehicle_model_id ? vehicle.vehicle_model_id : ''}
              options={filteredModels}
              onChange={e => setVehicle({
                ...vehicle,
                vehicle_model_id: e.target.value,
              })}
              valueKey={`id`}
              labelKey={`name`}
              defaultOption={true}
              invalid={errors && errors.vehicle_model_id ? true : false}
              errorMessage={errors.vehicle_model_id}
            />
          </div>
          <div className="modal-footer  justify-content-end" style={{ paddingTop: 0 }}>
            <ButtonContainer
              reverse
              onClick={() => updateVehicle()}
            >
              {`Save`}
            </ButtonContainer>
          </div>
        </Modal>
      </Container>
    </>
  )
}