import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Pusher from 'pusher-js'
import Cookies from 'js-cookie';
import * as constants from 'constants/index.js';

export default function ChangeHandler(props) {
    const profileData = useSelector((state) => state.getState).profileData;
    const token = Cookies.get('grs_token');
    const notify = props.notify;
    const updateData = props.updateData;

    /**
   * PUSHER
   */
    useEffect(() => {
        if (profileData && profileData.id) {
            const pusher = new Pusher(constants.PUSHER_APP_KEY, {
                cluster: constants.PUSHER_APP_CLUSTER,
                authEndpoint: `${constants.API_URL}/broadcast/auth`,
                auth: {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                },
            })

            // Document signed
            const channelDealEvents = pusher.subscribe(`app.live-vehicle`)
            channelDealEvents.bind(`live.vehicle.update`, function (data) {
                updateData(data.vehicle);
                notify('info', 'Information', data.message);

            })

            return function cleanup() {
                channelDealEvents.unsubscribe()
            }
        }
    }, [profileData, token])

    return (
        <>
        </>
    )
}