import React, { useCallback, useEffect, useRef, useState } from 'react';

const useNotify = () => {
  const [type, setType] = useState('info');
  const [header, setHeader] = useState('Require');
  const [message, setMessage] = useState(null);
  const ref = useRef(null);

  const notify = useCallback((msg, header, type) => {
    setHeader(header);
    setType(type);
    setMessage(msg);
  });

  useEffect(() => {
    if (message) {
      ref.current.notificationAlert(options);
    }
    setMessage(null);
  });

  let options = {
    place: 'tc',
    message: (
      <div className="alert-text">
        <span className="alert-title" data-notify="title">
          {header}
        </span>
        <span data-notify="message">
          <div dangerouslySetInnerHTML={{ __html: message }} />
        </span>
      </div>
    ),
    type,
    icon: 'ni ni-bell-55',
    autoDismiss: 5,
  };
  return { ref, notify };
};

export default useNotify;
