import React, { useEffect, useCallback } from 'react';
import NotificationAlert from 'react-notification-alert';
import Select from 'react-select';
import * as taskActions from '../../../redux/Task/actions';
import * as dealerActions from '../../../redux/Dealer/actio-types';

import CardsHeader from 'layouts/Headers/CardsHeader.js';

import { Container } from 'reactstrap';
import { useDispatch } from 'react-redux';

import CreateForm from './Form';
import useNotify from '../../../hooks/notify';

export default function TaskCreate() {
  const dispatch = useDispatch();
  const { ref, notify } = useNotify();

  useEffect(() => {
    dispatch({ type: taskActions.GET_TASK_CATEGORIES_REQUEST });
    dispatch({ type: taskActions.GET_TASK_RESOURCES_REQUEST });
    dispatch({ type: dealerActions.GET_DEALER_DATA_REQUEST });
  }, []);

  return (
    <>
      <CardsHeader
        name="Leads"
        parentName="Leads Management"
        currentName="Create"
      />
      <div className="rna-wrapper">
        <NotificationAlert ref={ref} />
      </div>
      <Container className="mt--5 admin-main-body" fluid>
        <CreateForm notify={notify} />
      </Container>
    </>
  );
}
